import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import { getCallsData, postQualityScore } from "../actions/TechSupportActions";
import {
  TableContainer,
  Table,
  Chip,
  TableHead,
  Switch,
  TableRow,
  TableCell,
  Button,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import moment from "moment";
let width = window.innerWidth;
let isMobile = width <= 500;

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "40%",
    left: "50%",
    width: "80%",
    transform: "translate(-50%, -50%)",
  },
  //   paper: {
  //     marginTop: "2rem",
  //     padding: "0.5rem 2rem",
  //     width: "90%",
  //   },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  tableContainer: {
    // position: "sticky",
    top: "200px",
  },
  centerIt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fontWeight: {
    fontWeight: "bold",
  },
  tableHead: {
    background: "rgb(146, 74, 145)!important",
  },
  tableHeadCell: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  openModal: any;
  setOpenModal: any;
  dataForModal: any;
  setDataForModal: any;
  statusForEditButton: any;
  setStatusForEditButton: any;
  getCallsData: any;
  get_calls_data: any;
  postQualityScore: any;
  post_quality_score: any;
  loading: boolean;
}

const DispositionsModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  dataForModal,
  setDataForModal,
  statusForEditButton,
  setStatusForEditButton,
  getCallsData,
  get_calls_data,
  postQualityScore,
  post_quality_score,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [scriptOpen, setScriptOpen] = useState<any>("");
  const [scriptClose, setScriptClose] = useState<any>("");
  const [holdUnhold, setHoldUnhold] = useState<any>("");
  const [analysingProfile, setAnalysingProfile] = useState<any>("");
  const [cusomerHandling, setCustomerHanding] = useState<any>("");
  const [informationShared, setInformationShared] = useState<any>("");
  const [dataCapture, setDataCapture] = useState<any>("");
  const [callTagging, setCallTagging] = useState<any>("");
  const [summarization, setSummarization] = useState<any>("");
  const [zt, setZT] = useState<any>("");
  const [discount, setDiscount] = useState<any>("");
  const [upSelling, setUpSelling] = useState<any>("");
  const [closingEfforts, setClosingEfforts] = useState<any>("");
  const [presentation, setPresentation] = useState<any>("");
  const [apoloziged, setApologized] = useState<any>("");
  const [speech, setSpeech] = useState<any>("");
  const [attentiveness, setAttentiveness] = useState<any>("");
  const [professionalism, setProfessionalism] = useState<any>("");
  const [conversation, setConversation] = useState<any>("");
  const [tone, setTone] = useState<any>("");
  const [scriptOpenReason, setScriptOpenReason] = useState<any>("");
  const [scriptOpenComment, setScriptOpenComment] = useState<any>("");
  const [scriptCloseReasons, setScriptCloseReasons] = useState<any>("");
  const [scriptCloseComment, setScriptCloseComment] = useState<any>("");
  const [holdUnholdReason, setHoldUnholdReason] = useState<any>("");
  const [holdUnholdComment, setHoldUnholdComment] = useState<any>("");
  const [analysingProfileReason, setAnalysingProfileReason] = useState<any>("");
  const [analysingProfileComment, setAnalysingProfileComment] =
    useState<any>("");
  const [cusomerHandlingReason, setCusomerHandlingReason] = useState<any>("");
  const [cusomerHandlingComment, setCusomerHandlingComment] = useState<any>("");
  const [informationSharedReason, setInformationSharedReason] =
    useState<any>("");
  const [informationSharedComment, setInformationSharedComment] =
    useState<any>("");
  const [dataCaptureReason, setDataCaptureReason] = useState<any>("");
  const [dataCaptureComment, setDataCaptureComment] = useState<any>("");
  const [callTaggingReason, setCallTaggingReason] = useState<any>("");
  const [callTagginComment, setCallTaggingComment] = useState<any>("");
  const [summarzationReason, setSummarizationReason] = useState<any>("");
  const [summarizationComment, setSummarizationComment] = useState<any>("");
  const [ztReason, setZTReason] = useState<any>("");
  const [ztComment, setZTComment] = useState<any>("");
  const [discountReason, setDiscountReason] = useState<any>("");
  const [discountComment, setDiscountComment] = useState<any>("");
  const [upSellingReason, setUpSellingReason] = useState<any>("");
  const [upSellingComment, setUpSellingComment] = useState<any>("");
  const [closingEffortsReason, setClosingEffortsReason] = useState<any>("");
  const [closingEffortsComment, setClosingEffortsComment] = useState<any>("");
  const [presentationReason, setPresentationReason] = useState<any>("");
  const [presentationComment, setPresentationComment] = useState<any>("");
  const [apolozigedReasons, setApolozedReasons] = useState<any>("");
  const [apolozigedComment, setApolozigedComment] = useState<any>("");
  const [speechReasons, setSpeechReasons] = useState<any>("");
  const [speechComment, setSpeechComment] = useState<any>("");
  const [attentivenessReason, setAttentivenessReason] = useState<any>("");
  const [attentivenessComment, setAttentivenessComment] = useState<any>("");
  const [professionalismReason, setProfessionalismReson] = useState<any>("");
  const [professionalismComment, setProfessionalismComment] = useState<any>("");
  const [conversationReason, setConversationReason] = useState<any>("");
  const [conversationComment, setConverstationComment] = useState<any>("");
  const [toneReason, setToneReason] = useState<any>("");
  const [toneComment, setToneComment] = useState<any>("");
  const [uCID, setUCID] = useState<any>("");
  const [leadId, setLeadId] = useState<any>("");
  const [loginUserOrQA, setLoginUserOrQA] = useState<any>("");
  const [callDate, setCallDate] = useState<any>();
  const [agentName, setAgentName] = useState<any>("");
  const [tl, setTL] = useState<any>("");
  const [auditDate, setAuditDate] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const [audit_score, setAuditScore] = useState<any>(100);
  const [remark, setRemark] = useState<any>("");

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleFilterForSingleCallId = () => {
    getCallsData(`${uCID}`);
  };

  useEffect(() => {
    if (uCID === "") {
      setLeadId("");
      setLoginUserOrQA("");
      setCallDate("");
      setAgentName("");
      setTL("");
    } else if (get_calls_data?.tl) {
      setLeadId(get_calls_data?.lead_details?.id);
      setLoginUserOrQA(get_calls_data?.quality_username);
      setCallDate(moment(get_calls_data?.created_at).format("YYYY-MM-DD"));
      setAgentName(get_calls_data?.agent_name);
      setTL(get_calls_data?.tl);
    }
  }, [get_calls_data]);

  useEffect(() => {
    if (dataForModal) {
      setUCID(dataForModal?.call_sid);
      setLeadId(dataForModal?.lead_details?.id);
      setLoginUserOrQA(dataForModal?.quality_username);
      setCallDate(moment(dataForModal?.created_at).format("YYYY-MM-DD"));
      setAgentName(dataForModal?.agent_name);
      setTL(dataForModal?.tl);
      setRemark(dataForModal?.remark);
      if (dataForModal?.quality_details) {
        dataForModal?.quality_details?.map((list: any) => {
          if (list?.subparameter === "script_opening") {
            if (list?.score === "0") {
              setScriptOpen("False");
            } else if (list?.score === "NA") {
              setScriptOpen("NA");
            } else if (list?.score > "0") {
              setScriptOpen("True");
            }
            setScriptOpenReason(list?.remark);
            setScriptOpenComment(list?.comment);
          }
          if (list?.subparameter === "script_closing") {
            if (list?.score === "0") {
              setScriptClose("False");
            } else if (list?.score === "NA") {
              setScriptClose("NA");
            } else if (list?.score > "0") {
              setScriptClose("True");
            }
            setScriptCloseReasons(list?.remark);
            setScriptCloseComment(list?.comment);
          }
          if (list?.subparameter === "hold_unhold_protocol") {
            if (list?.score === "0") {
              setHoldUnhold("False");
            } else if (list?.score === "NA") {
              setHoldUnhold("NA");
            } else if (list?.score > "0") {
              setHoldUnhold("True");
            }
            setHoldUnholdReason(list?.remark);
            setHoldUnholdComment(list?.comment);
          }
          if (list?.subparameter === "analysis_profiling") {
            if (list?.score === "0") {
              setAnalysingProfile("False");
            } else if (list?.score === "NA") {
              setAnalysingProfile("NA");
            } else if (list?.score > "0") {
              setAnalysingProfile("True");
            }
            setAnalysingProfileReason(list?.remark);
            setAnalysingProfileComment(list?.comment);
          }
          if (list?.subparameter === "customer_handeling") {
            if (list?.score === "0") {
              setCustomerHanding("False");
            } else if (list?.score === "NA") {
              setCustomerHanding("NA");
            } else if (list?.score > "0") {
              setCustomerHanding("True");
            }
            setCusomerHandlingReason(list?.remark);
            setCusomerHandlingComment(list?.comment);
          }
          if (list?.subparameter === "closing_efforts") {
            if (list?.score === "0") {
              setClosingEfforts("False");
            } else if (list?.score === "NA") {
              setClosingEfforts("NA");
            } else if (list?.score > "0") {
              setClosingEfforts("True");
            }
            setClosingEffortsReason(list?.remark);
            setClosingEffortsComment(list?.comment);
          }
          if (list?.subparameter === "discount_given") {
            if (list?.score === "0") {
              setDiscount("False");
            } else if (list?.score === "NA") {
              setDiscount("NA");
            } else if (list?.score > "0") {
              setDiscount("True");
            }
            setDiscountReason(list?.remark);
            setDiscountComment(list?.comment);
          }
          if (list?.subparameter === "upselling") {
            if (list?.score === "0") {
              setUpSelling("False");
            } else if (list?.score === "NA") {
              setUpSelling("NA");
            } else if (list?.score > "0") {
              setUpSelling("True");
            }
            setUpSellingReason(list?.remark);
            setUpSellingComment(list?.comment);
          }
          if (list?.subparameter === "presentation") {
            if (list?.score === "0") {
              setPresentation("False");
            } else if (list?.score === "NA") {
              setPresentation("NA");
            } else if (list?.score > "0") {
              setPresentation("True");
            }
            setPresentationReason(list?.remark);
            setPresentationComment(list?.comment);
          }
          if (list?.subparameter === "summarization") {
            if (list?.score === "0") {
              setSummarization("False");
            } else if (list?.score === "NA") {
              setSummarization("NA");
            } else if (list?.score > "0") {
              setSummarization("True");
            }
            setSummarizationReason(list?.remark);
            setSummarizationComment(list?.comment);
          }
          if (list?.subparameter === "apologized") {
            if (list?.score === "0") {
              setApologized("False");
            } else if (list?.score === "NA") {
              setApologized("NA");
            } else if (list?.score > "0") {
              setApologized("True");
            }
            setApolozedReasons(list?.remark);
            setApolozigedComment(list?.comment);
          }
          if (list?.subparameter === "speech") {
            if (list?.score === "0") {
              setSpeech("False");
            } else if (list?.score === "NA") {
              setSpeech("NA");
            } else if (list?.score > "0") {
              setSpeech("True");
            }
            setSpeechReasons(list?.remark);
            setSpeechComment(list?.comment);
          }
          if (list?.subparameter === "attentiveness") {
            if (list?.score === "0") {
              setAttentiveness("False");
            } else if (list?.score === "NA") {
              setAttentiveness("NA");
            } else if (list?.score > "0") {
              setAttentiveness("True");
            }
            setAttentivenessReason(list?.remark);
            setAttentivenessComment(list?.comment);
          }
          if (list?.subparameter === "professionalism") {
            if (list?.score === "0") {
              setProfessionalism("False");
            } else if (list?.score === "NA") {
              setProfessionalism("NA");
            } else if (list?.score > "0") {
              setProfessionalism("True");
            }
            setProfessionalismReson(list?.remark);
            setProfessionalismComment(list?.comment);
          }
          if (list?.subparameter === "conversation") {
            if (list?.score === "0") {
              setConversation("False");
            } else if (list?.score === "NA") {
              setConversation("NA");
            } else if (list?.score > "0") {
              setConversation("True");
            }
            setConversationReason(list?.remark);
            setConverstationComment(list?.comment);
          }
          if (list?.subparameter === "tone") {
            if (list?.score === "0") {
              setTone("False");
            } else if (list?.score === "NA") {
              setTone("NA");
            } else if (list?.score > "0") {
              setTone("True");
            }
            setToneReason(list?.remark);
            setToneComment(list?.comment);
          }
          if (list?.subparameter === "information_shared") {
            if (list?.score === "0") {
              setInformationShared("False");
            } else if (list?.score === "NA") {
              setInformationShared("NA");
            } else if (list?.score > "0") {
              setInformationShared("True");
            }
            setInformationSharedReason(list?.remark);
            setInformationSharedComment(list?.comment);
          }
          if (list?.subparameter === "zt") {
            if (list?.score === "0") {
              setZT("False");
            } else if (list?.score === "NA") {
              setZT("NA");
            } else if (list?.score > "0") {
              setZT("True");
            }
            setZTReason(list?.remark);
            setZTComment(list?.comment);
          }
          if (list?.subparameter === "information_shared") {
            if (list?.score === "0") {
              setInformationShared("False");
            } else if (list?.score === "NA") {
              setInformationShared("NA");
            } else if (list?.score > "0") {
              setInformationShared("True");
            }
            setInformationSharedReason(list?.remark);
            setInformationSharedComment(list?.comment);
          }
          if (list?.subparameter === "data_capture") {
            if (list?.score === "0") {
              setDataCapture("False");
            } else if (list?.score === "NA") {
              setDataCapture("NA");
            } else if (list?.score > "0") {
              setDataCapture("True");
            }
            setDataCaptureReason(list?.remark);
            setDataCaptureComment(list?.comment);
          }
          if (list?.subparameter === "call_tagging") {
            if (list?.score === "0") {
              setCallTagging("False");
            } else if (list?.score === "NA") {
              setCallTagging("NA");
            } else if (list?.score > "0") {
              setCallTagging("True");
            }
            setCallTaggingReason(list?.remark);
            setCallTaggingComment(list?.comment);
          }
        });
      }
    }
  }, [dataForModal]);

  const handleReset = () => {
    setScriptOpen("");
    setScriptClose("");
    setHoldUnhold("");
    setAnalysingProfile("");
    setCustomerHanding("");
    setInformationShared("");
    setDataCapture("");
    setCallTagging("");
    setSummarization("");
    setZT("");
    setDiscount("");
    setUpSelling("");
    setClosingEfforts("");
    setPresentation("");
    setApologized("");
    setSpeech("");
    setAttentiveness("");
    setProfessionalism("");
    setConversation("");
    setTone("");
    setScriptOpenReason("");
    setScriptOpenComment("");
    setScriptCloseReasons("");
    setScriptCloseComment("");
    setHoldUnholdReason("");
    setHoldUnholdComment("");
    setAnalysingProfileReason("");
    setAnalysingProfileComment("");
    setCusomerHandlingReason("");
    setCusomerHandlingComment("");
    setInformationSharedReason("");
    setInformationSharedComment("");
    setDataCaptureReason("");
    setDataCaptureComment("");
    setCallTaggingReason("");
    setCallTaggingComment("");
    setSummarizationReason("");
    setSummarizationComment("");
    setZTReason("");
    setZTComment("");
    setDiscountReason("");
    setDiscountComment("");
    setUpSellingReason("");
    setUpSellingComment("");
    setClosingEffortsReason("");
    setClosingEffortsComment("");
    setPresentationReason("");
    setPresentationComment("");
    setApolozedReasons("");
    setApolozigedComment("");
    setSpeechReasons("");
    setSpeechComment("");
    setAttentivenessReason("");
    setAttentivenessComment("");
    setProfessionalismReson("");
    setProfessionalismComment("");
    setConversationReason("");
    setConverstationComment("");
    setToneReason("");
    setToneComment("");
    setRemark("");
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    let data = {
      score: {
        script_opening: [scriptOpen, scriptOpenReason, scriptOpenComment],
        script_closing: [scriptClose, scriptCloseReasons, scriptCloseComment],
        hold_unhold_protocol: [holdUnhold, holdUnholdReason, holdUnholdComment],
        analysis_profiling: [
          analysingProfile,
          analysingProfileReason,
          analysingProfileComment,
        ],
        customer_handeling: [
          cusomerHandling,
          analysingProfileReason,
          analysingProfileComment,
        ],
        information_shared: [
          informationShared,
          informationSharedReason,
          informationSharedComment,
        ],
        data_capture: [dataCapture, dataCaptureReason, dataCaptureComment],
        call_tagging: [callTagging, callTaggingReason, callTagginComment],
        summarization: [
          summarization,
          summarzationReason,
          summarizationComment,
        ],
        zt: [zt, ztReason, ztComment],
        discount_given: [discount, discountReason, discountComment],
        upselling: [upSelling, upSellingReason, upSellingComment],
        closing_efforts: [
          closingEfforts,
          closingEffortsReason,
          closingEffortsComment,
        ],
        presentation: [presentation, presentationReason, presentationComment],
        apologized: [apoloziged, apolozigedReasons, apolozigedComment],
        speech: [speech, speechReasons, speechComment],
        attentiveness: [
          attentiveness,
          attentivenessReason,
          attentivenessComment,
        ],
        professionalism: [
          professionalism,
          professionalismReason,
          professionalismComment,
        ],
        conversation: [conversation, conversationReason, conversationComment],
        tone: [tone, toneReason, toneComment],
      },
      remark: remark,
    };

    await postQualityScore(data, uCID);
    history.push("/dashboard/ts/auditscore");
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose}>
        <div className={classes.modal} style={{ marginTop: "80px" }}>
          <form
            autoComplete="off"
            style={{ width: "100%" }}
            onSubmit={submitForm}
          >
            <Paper elevation={5}>
              <TableContainer style={{ height: "700px", padding: "30px 15px" }}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      component="h1"
                      align="left"
                      className={classes.fontWeight}
                    >
                      Search UCID
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    style={{ margin: "0", alignItems: "left" }}
                  >
                    <Grid item xs={12} sm={4} md={2}>
                      <TextField
                        disabled={statusForEditButton === true ? false : true}
                        required
                        name="call-id"
                        className="input"
                        label="UCID"
                        variant="outlined"
                        style={{ width: "100%" }}
                        value={uCID}
                        onChange={(e) => setUCID(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <Button
                        disabled={statusForEditButton === true ? false : true}
                        variant="contained"
                        color="secondary"
                        // disabled={loading}
                        fullWidth
                        onClick={handleFilterForSingleCallId}
                      >
                        Filter
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}></Grid>
                    <Grid item xs={12} sm={4} md={2}></Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      Audit Score
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <TextField
                        disabled={true}
                        required
                        name="audit_score"
                        className="input"
                        variant="outlined"
                        style={{ width: "100%" }}
                        value={
                          zt === "True" ||
                          summarization === "False" ||
                          informationShared === "False" ||
                          dataCapture === "False" ||
                          callTagging === "False"
                            ? 0
                            : audit_score
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Lead Id"
                      variant="outlined"
                      style={{ width: "100%" }}
                      size="small"
                      value={leadId}
                      onChange={(e) => setLeadId(e.target.value)}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Username"
                      variant="outlined"
                      style={{ width: "100%" }}
                      size="small"
                      value={loginUserOrQA}
                      onChange={(e) => setLoginUserOrQA(e.target.value)}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      type="date"
                      label="Call Date"
                      variant="outlined"
                      style={{ width: "100%" }}
                      size="small"
                      value={callDate}
                      onChange={(e) => setCallDate(e.target.value)}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      type="date"
                      label="Audit Date"
                      variant="outlined"
                      style={{ width: "100%" }}
                      size="small"
                      value={auditDate}
                      onChange={(e) => setAuditDate(e.target.value)}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Agent Name"
                      variant="outlined"
                      style={{ width: "100%" }}
                      size="small"
                      value={agentName}
                      onChange={(e) => setAgentName(e.target.value)}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="TL"
                      variant="outlined"
                      style={{ width: "100%" }}
                      size="small"
                      value={tl}
                      onChange={(e) => setTL(e.target.value)}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <TableContainer
                    className={classes.tableContainer}
                    component={Paper}
                    style={{ marginLeft: "12px" }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead className={classes.tableHead}>
                        <StyledTableRow>
                          <StyledTableCell className={classes.tableHeadCell}>
                            <b style={{ marginLeft: "-18px" }}>Parameters</b>
                          </StyledTableCell>
                          <StyledTableCell className={classes.tableHeadCell}>
                            <b style={{ marginLeft: "-38px" }}>
                              Sub-Parameters
                            </b>
                          </StyledTableCell>
                          <StyledTableCell className={classes.tableHeadCell}>
                            <b style={{ marginLeft: "-20px" }}>Weightage</b>
                            {/* Main */}
                          </StyledTableCell>
                          <StyledTableCell className={classes.tableHeadCell}>
                            <b>Options</b>
                          </StyledTableCell>
                          <StyledTableCell className={classes.tableHeadCell}>
                            <b>Reasons</b>
                          </StyledTableCell>
                          <StyledTableCell className={classes.tableHeadCell}>
                            <b>Comments</b>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>

                  {/* <Grid
                    item
                    xs={12}
                    sm={4}
                    md={12}
                    style={{ textAlign: "center" }}
                  >
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Parameter: Script Adherence
                    </Typography>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    style={{ textAlign: "center" }}
                  >
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Script Adherence
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    style={{ textAlign: "center" }}
                  >
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Opening
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    style={{ textAlign: "center" }}
                  >
                    <Chip label={"3.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      required
                      name="scripting-opening"
                      className="input"
                      // label="Scripting Opening"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={scriptOpen}
                      onChange={(e) => {
                        if (scriptOpen === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 3);
                            setScriptOpenReason("");
                          }
                        }
                        setScriptOpen(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setScriptOpenReason("");
                        } else {
                          setAuditScore(audit_score - 3);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={scriptOpen === "False"}
                      disabled={
                        scriptOpen === "" ||
                        scriptOpen === "True" ||
                        scriptOpen === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={scriptOpenReason}
                      size="small"
                      onChange={(e) => setScriptOpenReason(e.target.value)}
                    >
                      <MenuItem
                        value={"Opened the call within first 5 seconds"}
                      >
                        Opened the call within first 5 seconds
                      </MenuItem>
                      <MenuItem value={"Branded the call"}>
                        Branded the call
                      </MenuItem>
                      <MenuItem value={"Name Exchange"}>Name Exchange</MenuItem>
                      <MenuItem value={"Script not Adhered(Eg : Hello)"}>
                        Script not Adhered(Eg : Hello)
                      </MenuItem>
                      <MenuItem
                        value={"Voice clarity missing and ROS was high"}
                      >
                        Voice clarity missing and ROS was high
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      size="small"
                      value={scriptOpenComment}
                      onChange={(e) => setScriptOpenComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Call Control
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Need analysis/ Customer Profiling & Effective Probing
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"4.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required
                      select
                      name="analysing-profile"
                      className="input"
                      // label="Analysing Profile"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={analysingProfile}
                      onChange={(e) => {
                        if (analysingProfile === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 4);
                            setAnalysingProfileReason("");
                          }
                        }
                        setAnalysingProfile(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setAnalysingProfileReason("");
                        } else {
                          setAuditScore(audit_score - 4);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={analysingProfile === "False"}
                      disabled={
                        analysingProfile === "" ||
                        analysingProfile === "True" ||
                        analysingProfile === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={analysingProfileReason}
                      onChange={(e) =>
                        setAnalysingProfileReason(e.target.value)
                      }
                    >
                      <MenuItem
                        value={
                          "Asked enough & complete probing questions to understand the needs of the customer"
                        }
                      >
                        Asked enough & complete probing questions to understand
                        the needs of the customer
                      </MenuItem>
                      <MenuItem value={"Asked Irrelevant probing question"}>
                        Asked Irrelevant probing question
                      </MenuItem>
                      <MenuItem
                        value={"Probed for daily routine (Customer Profiling)"}
                      >
                        Probed for daily routine (Customer Profiling)
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={analysingProfileComment}
                      onChange={(e) =>
                        setAnalysingProfileComment(e.target.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Selling Efforts
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Handled Customer's Queries/Objections
                      appropriately/Retention efforts( Verification
                      cancellation)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"4.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="customer-handling"
                      className="input"
                      // label="Customer Handling"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={cusomerHandling}
                      onChange={(e) => {
                        if (cusomerHandling === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 4);
                            setCusomerHandlingReason("");
                          }
                        }
                        setCustomerHanding(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setCusomerHandlingReason("");
                        } else {
                          setAuditScore(audit_score - 4);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={cusomerHandling === "False"}
                      disabled={
                        cusomerHandling === "" ||
                        cusomerHandling === "True" ||
                        cusomerHandling === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={cusomerHandlingReason}
                      onChange={(e) => setCusomerHandlingReason(e.target.value)}
                    >
                      <MenuItem
                        value={
                          "Agent was able to handle the customer objection effectively"
                        }
                      >
                        Agent was able to handle the customer objection
                        effectively
                      </MenuItem>
                      <MenuItem
                        value={
                          "Verification agent tried to retain the customer"
                        }
                      >
                        Verification agent tried to retain the customer
                      </MenuItem>
                      {/* <MenuItem value={"Agent handle the customer effectively"}>
                        Agent handle the customer effectively
                      </MenuItem> */}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={cusomerHandlingComment}
                      onChange={(e) =>
                        setCusomerHandlingComment(e.target.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Selling Efforts
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Effort to close sale & Rebuttals provided
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"4.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label="Closing Efforts"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={closingEfforts}
                      required
                      onChange={(e) => {
                        if (closingEfforts === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 4);
                            setClosingEffortsReason("");
                          }
                        }
                        setClosingEfforts(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setClosingEffortsReason("");
                        } else {
                          setAuditScore(audit_score - 4);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={closingEfforts === "False"}
                      disabled={
                        closingEfforts === "" ||
                        closingEfforts === "True" ||
                        closingEfforts === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={closingEffortsReason}
                      onChange={(e) => setClosingEffortsReason(e.target.value)}
                    >
                      <MenuItem
                        value={
                          "Agent tried to convice the customer for sales closure"
                        }
                      >
                        Agent tried to convice the customer for sales closure
                      </MenuItem>
                      <MenuItem value={"Rebuttals provided to close sale"}>
                        Rebuttals provided to close sale
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={closingEffortsComment}
                      onChange={(e) => setClosingEffortsComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Selling Efforts
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Discount Offered/Given
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"3.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label="Discount"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={discount}
                      onChange={(e) => {
                        if (discount === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 3);
                            setDiscountReason("");
                          }
                        }
                        setDiscount(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setDiscountReason("");
                        } else {
                          setAuditScore(audit_score - 3);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={discount === "False"}
                      disabled={
                        discount === "" ||
                        discount === "True" ||
                        discount === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={discountReason}
                      onChange={(e) => setDiscountReason(e.target.value)}
                    >
                      <MenuItem
                        value={"Agent offered the discount for sale closure"}
                      >
                        Agent offered the discount for sale closure
                      </MenuItem>
                      <MenuItem
                        value={"Correct discount given as per the process"}
                      >
                        Correct discount given as per the process
                      </MenuItem>
                      <MenuItem
                        value={
                          "No proactive discount given (customer did not ask for the discount)"
                        }
                      >
                        No proactive discount given (customer did not ask for
                        the discount)
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={discountComment}
                      onChange={(e) => setDiscountComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Selling Efforts
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Upselling & pitch for pack upgrade
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"3.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label=" Upselling"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={upSelling}
                      onChange={(e) => {
                        if (upSelling === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 3);
                            setUpSellingReason("");
                          }
                        }
                        setUpSelling(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setUpSellingReason("");
                        } else {
                          setAuditScore(audit_score - 3);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={upSelling === "False"}
                      disabled={
                        upSelling === "" ||
                        upSelling === "True" ||
                        upSelling === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={upSellingReason}
                      onChange={(e) => setUpSellingReason(e.target.value)}
                    >
                      <MenuItem
                        value={"Agent pitched for the plan upgradation"}
                      >
                        Agent pitched for the plan upgradation
                      </MenuItem>
                      <MenuItem value={"Upsell the pack (1+1)"}>
                        Upsell the pack (1+1)
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={upSellingComment === "False"}
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={upSellingComment}
                      onChange={(e) => setUpSellingComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Presentation
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Product Presentation
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"3.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label="Presentation"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={presentation}
                      onChange={(e) => {
                        if (presentation === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 3);
                            setPresentationReason("");
                          }
                        }
                        setPresentation(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setPresentationReason("");
                        } else {
                          setAuditScore(audit_score - 3);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={presentation === "False"}
                      disabled={
                        presentation === "" ||
                        presentation === "True" ||
                        presentation === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={presentationReason}
                      onChange={(e) => setPresentationReason(e.target.value)}
                    >
                      <MenuItem value={"Informed basic details of package"}>
                        Informed basic details of package
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={presentationComment}
                      onChange={(e) => setPresentationComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Summarization
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Orders Details Summarized before placing Order
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"10.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="call-tagging"
                      className="input"
                      // label="Summariation"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={summarization}
                      onChange={(e) => {
                        setSummarization(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setSummarizationReason("");
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>Fatal</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={summarization === "False"}
                      disabled={
                        summarization === "" ||
                        summarization === "True" ||
                        summarization === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={summarzationReason}
                      onChange={(e) => setSummarizationReason(e.target.value)}
                    >
                      <MenuItem
                        value={
                          "Agent failed to summarize required booking details"
                        }
                      >
                        Agent failed to summarize required booking details
                      </MenuItem>
                      <MenuItem value={"Incomplete booking details summarized"}>
                        Incomplete booking details summarized
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={summarizationComment}
                      onChange={(e) => setSummarizationComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Script Adherence
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    style={{ fontWeight: "bold" }}
                  >
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Hold/Unhold Protocol/Dead air
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    className={classes.centerIt}
                    style={{ fontWeight: "bold" }}
                  >
                    <Chip label={"3.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required
                      select
                      name="hold-unhold"
                      className="input"
                      // label="Hold/Unhold"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={holdUnhold}
                      onChange={(e) => {
                        if (holdUnhold === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 3);
                            setHoldUnholdReason("");
                          }
                        }
                        setHoldUnhold(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setHoldUnholdReason("");
                        } else {
                          setAuditScore(audit_score - 3);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={holdUnhold === "False"}
                      disabled={
                        holdUnhold === "" ||
                        holdUnhold === "True" ||
                        holdUnhold === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={holdUnholdReason}
                      onChange={(e) => setHoldUnholdReason(e.target.value)}
                    >
                      <MenuItem value={"Followed Hold/Unhold Script"}>
                        Followed Hold/Unhold Script
                      </MenuItem>
                      <MenuItem
                        value={
                          "Sought customer's permission before placing the call on hold"
                        }
                      >
                        Sought customer's permission before placing the call on
                        hold
                      </MenuItem>
                      <MenuItem
                        value={
                          "Informed  the reason and TAT for placing on hold"
                        }
                      >
                        Informed the reason and TAT for placing on hold
                      </MenuItem>
                      <MenuItem value={"Followed hold refresh protocol"}>
                        Followed hold refresh protocol
                      </MenuItem>
                      <MenuItem
                        value={"Thanked the customer for staying on hold"}
                      >
                        Thanked the customer for staying on hold
                      </MenuItem>
                      <MenuItem
                        value={"Placed the customer on hold when required"}
                      >
                        Placed the customer on hold when required
                      </MenuItem>
                      <MenuItem value={"No dead air for more than 10 secs"}>
                        No dead air for more than 10 secs
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      value={holdUnholdComment}
                      onChange={(e) => setHoldUnholdComment(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Soft Skills
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Offered Apologized/Empathy(When required)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"5.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label="Apologized"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={apoloziged}
                      onChange={(e) => {
                        if (apoloziged === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 5);
                            setApolozedReasons("");
                          }
                        }
                        setApologized(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setApolozedReasons("");
                        } else {
                          setAuditScore(audit_score - 5);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={apoloziged === "False"}
                      disabled={
                        apoloziged === "" ||
                        apoloziged === "True" ||
                        apoloziged === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={apolozigedReasons}
                      onChange={(e) => setApolozedReasons(e.target.value)}
                    >
                      <MenuItem
                        value={"Agent used apology statement when required"}
                      >
                        Agent used apology statement when required
                      </MenuItem>
                      <MenuItem
                        value={"Agent used Emapthy Statement when required"}
                      >
                        Agent used Emapthy Statement when required
                      </MenuItem>
                      <MenuItem value={"Instant apology offered"}>
                        Instant apology offered
                      </MenuItem>
                      <MenuItem value={"Instant Empathy Offered"}>
                        Instant Empathy Offered
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={apolozigedComment}
                      onChange={(e) => setApolozigedComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Soft Skills
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Clarity in Speech(Appropriate ROS/Without Smuddging)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"3.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label=" Speech"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={speech}
                      onChange={(e) => {
                        if (speech === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 3);
                            setSpeechReasons("");
                          }
                        }
                        setSpeech(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setSpeechReasons("");
                        } else {
                          setAuditScore(audit_score - 3);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={speech === "False"}
                      disabled={
                        speech === "" || speech === "True" || speech === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={speechReasons}
                      onChange={(e) => setSpeechReasons(e.target.value)}
                    >
                      <MenuItem
                        value={
                          "Agent Avoided words smuddging without words smuddging"
                        }
                      >
                        Agent maintain moderate ros without Words Smuddging
                      </MenuItem>
                      <MenuItem value={"Clarity of voice"}>
                        Clarity of voice
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={speechComment}
                      onChange={(e) => setSpeechComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Soft Skills
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      ToleranceAttentiveness on call with verbal acknowledgment
                      & Active listening
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"5.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label="Attentiveness"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={attentiveness}
                      onChange={(e) => {
                        if (attentiveness === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 5);
                            setAttentivenessReason("");
                          }
                        }
                        setAttentiveness(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setAttentivenessReason("");
                        } else {
                          setAuditScore(audit_score - 5);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={attentiveness === "False"}
                      disabled={
                        attentiveness === "" ||
                        attentiveness === "True" ||
                        attentiveness === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={attentivenessReason}
                      onChange={(e) => setAttentivenessReason(e.target.value)}
                    >
                      <MenuItem value={"Not Attentive on call"}>
                        Not Attentive on call
                      </MenuItem>
                      <MenuItem value={"Agent was not distracted"}>
                        Agent was not distracted
                      </MenuItem>
                      <MenuItem
                        value={
                          "Customer was not made repeat information that was already provided"
                        }
                      >
                        Customer was not made repeat information that was
                        already provided
                      </MenuItem>
                      <MenuItem
                        value={"Instant Acknowledgement with Verbal Nods"}
                      >
                        Instant Acknowledgement with Verbal Nods
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={attentivenessComment}
                      onChange={(e) => setAttentivenessComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Soft Skills
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Sound courteous & professional
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"6.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label="Professionalism"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={professionalism}
                      onChange={(e) => {
                        if (professionalism === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 6);
                            setProfessionalismReson("");
                          }
                        }
                        setProfessionalism(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setProfessionalismReson("");
                        } else {
                          setAuditScore(audit_score - 6);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={professionalism === "False"}
                      disabled={
                        professionalism === "" ||
                        professionalism === "True" ||
                        professionalism === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={professionalismReason}
                      onChange={(e) => setProfessionalismReson(e.target.value)}
                    >
                      <MenuItem
                        value={
                          "No Interruption Or TalkOver during the conversation "
                        }
                      >
                        No Interruption Or TalkOver during the conversation{" "}
                      </MenuItem>
                      <MenuItem
                        value={"Did not sound defensive or argumentative"}
                      >
                        Did not sound defensive or argumentative
                      </MenuItem>
                      <MenuItem
                        value={
                          "Used pleasantaries like May I, Please, Thank you, when required"
                        }
                      >
                        Used pleasantaries like "May" , "I", "Please", "Thank
                        you" when required
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={professionalismComment}
                      onChange={(e) =>
                        setProfessionalismComment(e.target.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Soft Skills
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Conversational Ability
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"4.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label="Conversation"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={conversation}
                      onChange={(e) => {
                        if (conversation === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 4);
                            setConversationReason("");
                          }
                        }
                        setConversation(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setConversationReason("");
                        } else {
                          setAuditScore(audit_score - 4);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={conversation === "False"}
                      disabled={
                        conversation === "" ||
                        conversation === "True" ||
                        conversation === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={conversationReason}
                      onChange={(e) => setConversationReason(e.target.value)}
                    >
                      <MenuItem
                        value={
                          "Complete/Correct structuring of Sentences with appropriate Choice of words"
                        }
                      >
                        Complete/Correct structuring of Sentences with
                        appropriate Choice of words
                      </MenuItem>
                      <MenuItem
                        value={
                          "There were no multiple repetitions of words,sentences or statements"
                        }
                      >
                        There were no multiple repetitions of words,sentences or
                        statements
                      </MenuItem>
                      <MenuItem
                        value={"Avoided Jargons/Slangs/Fillers/Abbreviation"}
                      >
                        Avoided Jargons/Slangs/Fillers/Abbreviation
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={conversationComment}
                      onChange={(e) => setConverstationComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Soft Skills
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Tone
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"3.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="discount"
                      className="input"
                      // label="Tone"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={tone}
                      onChange={(e) => {
                        if (tone === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 3);
                            setToneReason("");
                          }
                        }
                        setTone(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setToneReason("");
                        } else {
                          setAuditScore(audit_score - 3);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={tone === "False"}
                      disabled={tone === "" || tone === "True" || tone === "NA"}
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={toneReason}
                      onChange={(e) => setToneReason(e.target.value)}
                    >
                      <MenuItem
                        value={
                          "Polite, Energetic, Confident, Professional & Assisting"
                        }
                      >
                        Polite, Energetic, Confident, Professional & Assisting
                      </MenuItem>
                      <MenuItem value={"Avoid Fumbling on call"}>
                        Avoid Fumbling on call
                      </MenuItem>
                      <MenuItem
                        value={"Appropriate Pronounciation used/MTI Observed"}
                      >
                        Appropriate Pronounciation used/MTI Observed
                      </MenuItem>
                      <MenuItem value={"Switching to customer's language"}>
                        Switching to customer's language
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={toneComment}
                      onChange={(e) => setToneComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Query & Resolution
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      professionalComplete and correct Information shared
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"12.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="customer-handling"
                      className="input"
                      // label="Information Shared"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={informationShared}
                      onChange={(e) => {
                        setInformationShared(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setInformationSharedReason("");
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>Fatal</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={informationShared === "False"}
                      disabled={
                        informationShared === "" ||
                        informationShared === "True" ||
                        informationShared === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={informationSharedReason}
                      onChange={(e) =>
                        setInformationSharedReason(e.target.value)
                      }
                    >
                      <MenuItem value={"Mis Commitment made"}>
                        Mis Commitment made
                      </MenuItem>
                      <MenuItem value={"In-correct/Wrong information shared"}>
                        In-correct/Wrong information shared
                      </MenuItem>
                      <MenuItem value={"Incomplete information shared"}>
                        Incomplete information shared
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      value={informationSharedComment}
                      onChange={(e) =>
                        setInformationSharedComment(e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      CRM
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Captured all required fields
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"12.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="customer-handling"
                      className="input"
                      // label="Data Capture"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={dataCapture}
                      onChange={(e) => {
                        setDataCapture(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setDataCaptureReason("");
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>Fatal</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={dataCapture === "False"}
                      disabled={
                        dataCapture === "" ||
                        dataCapture === "True" ||
                        dataCapture === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={dataCaptureReason}
                      onChange={(e) => setDataCaptureReason(e.target.value)}
                    >
                      <MenuItem value={"Wrong/Incomplete Data Capturing"}>
                        Wrong/Incomplete Data Capturing
                      </MenuItem>
                      <MenuItem value={"Wrong/Duplicate Order "}>
                        Wrong/Duplicate Order
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      value={dataCaptureComment}
                      onChange={(e) => setDataCaptureComment(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      CRM
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      [CRM] Call Disposed appropriately
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"10.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="call-tagging"
                      className="input"
                      // label="Call Tagging"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={callTagging}
                      onChange={(e) => {
                        setCallTagging(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setCallTaggingReason("");
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>Fatal</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={callTagging === "False"}
                      disabled={
                        callTagging === "" ||
                        callTagging === "True" ||
                        callTagging === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={callTaggingReason}
                      onChange={(e) => setCallTaggingReason(e.target.value)}
                    >
                      <MenuItem value={"Tagged under correct disposition"}>
                        Tagged under correct disposition
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={callTagginComment}
                      onChange={(e) => setCallTaggingComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Script Adherence
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Closing
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"3.00%"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      required
                      name="scripting-closing"
                      className="input"
                      // label="Scripting Closing"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={scriptClose}
                      onChange={(e) => {
                        if (scriptClose === "False") {
                          if (
                            e.target.value === "True" ||
                            e.target.value === "NA"
                          ) {
                            setAuditScore(audit_score + 3);
                            setScriptCloseReasons("");
                          }
                        }
                        setScriptClose(e.target.value);
                        if (
                          e.target.value === "True" ||
                          e.target.value === "NA"
                        ) {
                          setScriptCloseReasons("");
                        } else {
                          setAuditScore(audit_score - 3);
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                      <MenuItem value={"NA"}>NA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={scriptClose === "False"}
                      disabled={
                        scriptClose === "" ||
                        scriptClose === "True" ||
                        scriptClose === "NA"
                      }
                      select
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={scriptCloseReasons}
                      onChange={(e) => setScriptCloseReasons(e.target.value)}
                    >
                      <MenuItem value={"Branded the call (company name)"}>
                        Branded the call (company name)
                      </MenuItem>
                      <MenuItem value={"Thanked the customer for their time"}>
                        Thanked the customer for their time
                      </MenuItem>
                      <MenuItem
                        value={"Offered further assistance (if applicable)"}
                      >
                        Offered further assistance (if applicable)
                      </MenuItem>
                      <MenuItem value={"Script not Adhered"}>
                        Script not Adhered
                      </MenuItem>
                      <MenuItem
                        value={"Voice clarity missing and ROS was high"}
                      >
                        Voice clarity missing and ROS was high
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={scriptCloseComment}
                      onChange={(e) => setScriptCloseComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      Zero Tolerance
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography align="center" style={{ fontWeight: "bold" }}>
                      ZT (Rude, abusive, Malpractice)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className={classes.centerIt}>
                    <Chip label={"NA"} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      select
                      name="call-tagging"
                      className="input"
                      // label="Zero Tolerance"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={zt}
                      onChange={(e) => {
                        setZT(e.target.value);
                        if (
                          e.target.value === "False" ||
                          e.target.value === "NA"
                        ) {
                          setZTReason("");
                        }
                      }}
                    >
                      <MenuItem value={"True"}>Yes</MenuItem>
                      <MenuItem value={"False"}>No</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      required={zt === "True"}
                      disabled={zt === "" || zt === "False" || zt === "NA"}
                      name="reasons"
                      className="input"
                      label="Reasons"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={ztReason}
                      onChange={(e) => setZTReason(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="comment"
                      className="input"
                      label="Comment"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={ztComment}
                      onChange={(e) => setZTComment(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <TextField
                      name="Call Observeration"
                      className="input"
                      label="Call Observeration"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </TableContainer>
            </Paper>
          </form>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  get_calls_data: state.TechSupportReducer.get_calls_data,
  post_quality_score: state.TechSupportReducer.post_quality_score,
});

export default connect(mapStateToProps, { getCallsData, postQualityScore })(
  DispositionsModal
);
