import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  TablePagination,
  TableFooter,
  withStyles,
  Switch,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import "./index.sass";
import { MessageRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  getCities,
  getArea,
  updateArea,
  getAreaComments,
} from "../../actions/TechSupportActions";
import Loader from "../loader";
import CreatePincodeModal from "./createPincodeModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { useHistory } from "react-router";
import { generatePincodeFilterUrl } from "../../../helpers/generateUrl";
import AreaComments from "../Comments/areaComments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    paper: {
      padding: "1rem",
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.2rem",
    },
    actionButton: {
      margin: "0 0.5rem",
      cursor: "pointer",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getArea: any;
  areaList: any;
  updateArea: any;
  getCities: any;
  cities: any;
  getAreaComments: any;
  areaComments: any;
  arealoading: Boolean;
}

const PinCodePage: React.FC<Props> = ({
  getArea,
  areaList,
  updateArea,
  getCities,
  cities,
  getAreaComments,
  areaComments,
  arealoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [editPincode, setEditPincode] = useState("");
  const [pincode, setPincode] = useState<string | null>(null);
  const [zone, setZone] = useState<string | null>(null);
  const [city, setCity] = useState<any>([]);
  const [cityName, setCityName] = useState("");
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const timer = useRef<any>(0);
  const [pin, setPin] = useState("");
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openModal, setopenModal] = useState(false);

  // useEffect(() => {
  //   getArea("show=true");
  //   getCities();
  // }, [getArea, getCities]);

  useEffect(() => {
    open === false && getArea("show=true");
  }, [open]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = areaList?.links && areaList?.links?.next.split("?")[1];
      getArea(url ? `${url}` : "");
    } else if (newPage < page) {
      let url = areaList?.links && areaList?.links?.previous.split("?")[1];
      getArea(url ? `${url}` : "");
    }
    setPage(newPage as number);
  };

  const handleEditArea = (code: any) => {
    setEditPincode(code.id);
    setCityName(code.city_name);
    setCity(code.city);
    setPincode(code.pincode);
    setZone(code.area);
  };

  const handleSubmit = async (code: any) => {
    setEditPincode("");
    const body: any = {
      city,
      city_name: cityName,
      area: zone,
      pincode,
    };
    await updateArea(code.id, body);
    getArea("show=true");
  };

  const disablePincode = async (pack: any) => {
    const body: any = {
      is_active: !pack.is_active,
    };
    await updateArea(pack.id, body);
    getArea("show=true");
  };

  const filterBookings = () => {
    setDisableFilterBtn(true);
    const body: any = {
      city: city,
      pincode: pin,
    };
    const url = generatePincodeFilterUrl(body).substring(2);
    getArea(`${url}&show=true`);
    // setPage(0)
  };

  const handleClick = (id: number) => {
    setopenModal(true);
    getAreaComments(id);
  };

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={1} direction="row" style={{ margin: "0" }}>
          <Grid item xs={12} sm={6} md={9}>
            <h2>Manage Pin Codes</h2>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "150px" }}
              onClick={() => setOpen(true)}
            >
              Add Pincode
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="pincode"
              type="number"
              label="Pin Code"
              value={pin}
              variant="outlined"
              onChange={(e) => setPin(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              disabled={disableFilterBtn}
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/ts/pincodes")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        {/* <Paper elevation={15} className={classes.paper}> */}
        {arealoading ? (
          <Loader />
        ) : (
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "625px" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Pincode</StyledTableCell>
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  <StyledTableCell align="center">Area</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Active</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {areaList &&
                  areaList?.results &&
                  areaList?.results?.length > 0 &&
                  areaList?.results?.map((code: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {editPincode === code?.id ? (
                            <TextField
                              name="pincode"
                              type="text"
                              value={pincode !== null ? pincode : code?.pincode}
                              className="input"
                              variant="outlined"
                              placeholder="Pincode"
                              // disabled
                              onChange={(e) =>
                                setPincode(e.target.value as string)
                              }
                              style={{
                                width: "100%",
                                padding: "10px",
                                minWidth: "200px",
                              }}
                            />
                          ) : (
                            code?.pincode
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageRounded
                              onClick={() => handleClick(code?.id)}
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ paddingLeft: "10px" }}
                        >
                          {editPincode === code?.id ? (
                            <TextField
                              name="area"
                              type="text"
                              value={zone !== null ? zone : code?.area}
                              className="input"
                              variant="outlined"
                              // disabled
                              placeholder="Area"
                              onChange={(e) =>
                                setZone(e.target.value as string)
                              }
                              style={{
                                width: "100%",
                                padding: "10px",
                                minWidth: "200px",
                              }}
                            />
                          ) : (
                            code?.area
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ paddingLeft: "10px" }}
                        >
                          {editPincode === code?.id ? (
                            <Autocomplete
                              id="city"
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  let obj = JSON.parse(
                                    JSON.stringify(newValue, null, " ")
                                  );
                                  setCity(obj?.id);
                                  setCityName(obj?.name);
                                }
                              }}
                              options={cities || []}
                              freeSolo
                              blurOnSelect
                              aria-required
                              // disabled
                              getOptionLabel={(option: any) => option?.name}
                              defaultValue={{name: cityName}}
                              onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                setCityName(newInputValue);
                                timer.current = setTimeout(() => {
                                  getCities(newInputValue);
                                }, 1000);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  className="input"
                                  {...params}
                                  placeholder="City"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    minWidth: "200px",
                                  }}
                                />
                              )}
                            />
                          ) : (
                            code.city_name
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DefaultSwitch
                            checked={code?.is_active}
                            name="checkedA"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            disableRipple
                            onClick={() => disablePincode(code)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {editPincode === code?.id ? (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => handleSubmit(code)}
                              disabled={cityName == ""}
                            >
                              <CheckCircleOutlineIcon />
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => handleEditArea(code)}
                            >
                              <EditIcon />
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={areaList?.count}
                    rowsPerPage={areaList?.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
        {areaList && areaList?.length === 0 && (
          <h3 className={classes.heading} style={{ textAlign: "center" }}>
            No Data Found
          </h3>
        )}
        {/* </Paper> */}
        <AreaComments
          openModal={openModal}
          setopenModal={setopenModal}
          areaComments={areaComments}
          arealoading={arealoading}
        />
        <CreatePincodeModal open={open} setOpen={setOpen} />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  areaList: state.TechSupportReducer.areaList,
  cities: state.TechSupportReducer.cities,
  areaComments: state.TechSupportReducer.areaComments,
  arealoading: state.TechSupportReducer.arealoading,
});

export default connect(mapStateToProps, {
  getArea,
  getCities,
  updateArea,
  getAreaComments,
})(PinCodePage);