import React, { useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import Loader from "../../components/loader";
import { useEffect } from "react";
import { getPartners, getPartnersAuto } from "../../actions/TechSupportActions";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, TextField, Switch } from "@material-ui/core";
import { genratePartnerFilter } from "../../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);
interface Props {
  partnerLoading: any;
  getPartners: any;
  partnerList: any;
  getPartnersAuto: any;
  partnerData: any;
}

const TechManagePartner: React.FC<Props> = ({
  partnerLoading,
  getPartners,
  partnerList,
  getPartnersAuto,
  partnerData,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [partner, setPartner] = useState<any>([]);

  useEffect(() => {
    getPartners();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = partnerList?.links && partnerList?.links?.next.split("?")[1];
      getPartners(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url = partnerList?.links && partnerList?.links?.previous.split("?")[1];
      getPartners(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };

  const filterPartnerData = (e: any) => {
    const body: any = {
      // partner,
    };
    const url = genratePartnerFilter(body).substring(2);
    getPartners(`?code=${partner}`)
    setPage(0);
  };

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={10}>
          <h2>Manage Partner</h2>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Link
            to="/dashboard/ts/partner/create"
            style={{ textDecoration: "none" }}
          >
            <Button color="success" variant="contained" fullWidth>
              Add Partner
            </Button>
          </Link>
        </Grid>
      </Grid>

      <br />
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "left" }}
      >
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="partner_name"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPartner(obj.partner_name);
              }
            }}
            options={partnerData?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option?.user && option?.user?.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPartnersAuto(`?code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPartner([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Partner Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterPartnerData}
            disabled={disableFilterBtn}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/ts/partner/index")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div style={{ width: "100%" }} className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          {partnerLoading && !openModal ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Partner Name</StyledTableCell>
                  <StyledTableCell align="center">Partner Code</StyledTableCell>
                  <StyledTableCell align="center">
                    Active/InActive
                  </StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Zone</StyledTableCell>
                  <StyledTableCell align="center">User Type</StyledTableCell>
                  <StyledTableCell align="center">Org Type</StyledTableCell>
                  <StyledTableCell align="center">Assigned</StyledTableCell>
                  <StyledTableCell align="center">
                    Assign Package
                  </StyledTableCell>
                  <StyledTableCell align="center">Edit Partner</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {partnerList?.results &&
                  partnerList?.results.length > 0 &&
                  partnerList?.results?.map((data: any) => {
                    return (
                      <StyledTableRow key={data.username}>
                        <StyledTableCell align="center">
                          {data?.partner_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.partner_code}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DefaultSwitch
                            checked={data.is_active}
                            name="checkedA"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            disableRipple
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.city}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.zone}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.usertype}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.org_type}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.asigned}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.name_without_space}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() =>
                              history.push(
                                `/dashboard/ts/partner/edit/${data?.id}`
                              )
                            }
                          >
                            Edit
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                   <TablePagination
                    count={partnerList?.count || 0}
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    rowsPerPage={partnerList?.page_size}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    />
                </StyledTableRow>
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </div>
      {/* </Paper> */}
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  partnerLoading: state.TechSupportReducer.partnerLoading,
  partnerList: state.TechSupportReducer.partnerList,
  partnerData: state.TechSupportReducer.partnerData,
});

export default connect(mapStateToProps, {
  getPartners,
  getPartnersAuto,
})(TechManagePartner);
