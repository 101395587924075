import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../components/loader";
import "./index.sass";
import Button from "@mui/material/Button";
import { genrateUnregisterdBookingFilter } from "../../helpers/generateUrl";
import { getUnregisterdBooking } from "../actions/TechSupportActions";
import Bs from "../components/Comments/unregisterdBookingStatusComment";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

interface Props {
  loading: boolean;
  getUnregisterdBooking: any;
  unregisterBooking: any;
}

const LeadsTable: React.FC<Props> = ({
  getUnregisterdBooking,
  unregisterBooking,
  loading,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;
  const [multiple_booking, setMultipleBooking] = useState<any>("");
  const history = useHistory();

  useEffect(() => {
    getUnregisterdBooking();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        unregisterBooking?.links && unregisterBooking?.links?.next.split("?")[1];
      getUnregisterdBooking(`?${url}`);
    } else if (newPage < page) {
      let url =
        unregisterBooking?.links &&
        unregisterBooking?.links?.previous.split("?")[1];
      getUnregisterdBooking(`?${url}`);
    }
    setPage(newPage as number);
  };

  const filterBookings = () => {
    const body: any = {
      multiple_booking,
    };
    const url = genrateUnregisterdBookingFilter(body).substring(2);
    getUnregisterdBooking(`?${url}`);
    setPage(0);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2>Unregister Booking</h2>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              className="input"
              name="booking"
              type="number"
              label="Booking Id"
              value={multiple_booking}
              variant="outlined"
              onChange={(e) => setMultipleBooking(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.go(0)}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "580px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                    <StyledTableCell align="center">Zone</StyledTableCell>
                    <StyledTableCell align="center">
                      Booking Status
                    </StyledTableCell>
                    <StyledTableCell align="center">Comment</StyledTableCell>
                    <StyledTableCell align="center">Created At</StyledTableCell>
                    <StyledTableCell align="center">Updated At</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {unregisterBooking &&
                    unregisterBooking?.results &&
                    unregisterBooking?.results?.length > 0 &&
                    unregisterBooking?.results.map((item: any) => {
                      return (
                        <StyledTableRow key={item.id}>
                          <StyledTableCell align="center">
                            {item?.booking?.pk || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.zone || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.booking_status || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.comment}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {new Date(item?.created_at).toLocaleDateString()}{" "}
                            <br />
                            {new Date(item?.created_at).toLocaleTimeString()}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {new Date(item?.updated_at).toLocaleDateString()}
                            <br />
                            {new Date(item?.updated_at).toLocaleTimeString()}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Bs bookingId={item?.id} />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    colSpan={6}
                    count={unregisterBooking?.count || 0}
                    rowsPerPageOptions={[]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              </Table>
            )}
          </TableContainer>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  unregisterBooking: state.TechSupportReducer.unregisterBooking,
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, { getUnregisterdBooking })(LeadsTable);
