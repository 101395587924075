import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  createNewPhlebo,
  updatePhlebo,
  getCities,
} from "../../actions/TechSupportActions";
import { useHistory } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../loader";
import { genrateManagePhlebos } from "../../../helpers/generateUrl";
import moment from "moment";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  openEditPhlebo: boolean;
  editPhleboUser: any;
  updatePhlebo: any;
  setOpenEditPhlebo: Function;
  zoneList: any;
  loading: Boolean;
  setZoneName: any;
  zoneName: any;
  getAutoCompleteZone: any;
  getPhlebos: any;
  filterPhlebo: any;
  filterZone: any;
  getCities: any;
  cities: any;
  filterBaseCity: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  openEditPhlebo,
  setOpenEditPhlebo,
  editPhleboUser,
  updatePhlebo,
  zoneList,
  setZoneName,
  zoneName,
  loading,
  getAutoCompleteZone,
  getPhlebos,
  filterPhlebo,
  filterZone,
  filterBaseCity,
  getCities,
  cities,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [phoneNumber, setPhoneNumber] = useState<any>(
    editPhleboUser && editPhleboUser.user && editPhleboUser.user.phonenumber
  );
  const [phleboName, setPhleboName] = useState<any>(
    editPhleboUser && editPhleboUser.user && editPhleboUser.user.name
  );
  const [phleboUserName, setPhleboUserName] = useState<any>(
    editPhleboUser && editPhleboUser.user && editPhleboUser.user.username
  );
  const [phleboSource, setPhleboSource] = useState<string>(
    editPhleboUser?.u_type
  );
  const [employeId, setEmployeId] = useState<string>(editPhleboUser?.empid);
  const [baseCity, setBaseCity] = useState<any>(editPhleboUser?.base_city);
  const [phleboOrRider, setPhleboOrRider] = useState<any>(
    editPhleboUser?.phlebo_or_rider
  );
  const [mapMyIndiaId, setmapMyIndiaId] = useState<any>(
    editPhleboUser?.mapmyindiaid
  );
  const [base_city, setBase_City] = useState<any>(
    editPhleboUser?.base_city?.id
  );
  const [baseCityDef, setBaseCityDef] = useState<any>(
    editPhleboUser?.base_city && editPhleboUser?.base_city?.name
  );
  const [UserType, setUserType] = useState<any>();
  const [isactive, setIsActive] = useState<boolean>(editPhleboUser?.is_active);
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });
  let underscoreCount = 0;
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const [startTime, setStartTime] = useState(
    moment().format("hh:mm:ss") && editPhleboUser?.start_time
  );
  const [endTime, setEndTime] = useState<any>(
    moment().format("hh:mm:ss") && editPhleboUser?.end_time
  );

  const handleClose = () => {
    setOpenEditPhlebo(false);
  };

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (submitBtnClicked) {
      getPhlebos();
    }
    setSubmitBtnClicked(false);
  }, [submitBtnClicked]);

  const UpdatePhlebo = async () => {
    const body: any = {
      empid: employeId,
      fullname: phleboName,
      username: phleboUserName,
      phonenumber: phoneNumber,
      u_type: phleboSource,
      zone: zoneName || "",
      start_time: startTime,
      end_time: endTime,
      phlebo_or_rider: phleboOrRider,
      mapmyindiaid: mapMyIndiaId,
      is_active: isactive,
      base_city: base_city,
    };
    await updatePhlebo(
      body,
      editPhleboUser && editPhleboUser.user && editPhleboUser.user.phonenumber
    );
    if (filterPhlebo.length > 0 || filterZone !== 0 || filterBaseCity !== "") {
      const body: any = {
        phlebo: filterPhlebo,
        zone: filterZone,
        base_city: filterBaseCity,
      };
      const url = genrateManagePhlebos(body).substring(2);
      // phlebo !== "" ? getPhlebos(`${url}`) : getZone1(`${url}`);
      getPhlebos(`?${url}`);
    } else {
      getPhlebos();
    }
    setOpenEditPhlebo(false);
  };

  const inputAlphaNumeric = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^ a-zA-Z_ ]+/gi, "")
      : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };

  const inputAlphaNumericEmpId = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, "")
      : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };

  const inputAlphaNumericPhleboNum = (e: any) => {
    const value = e.target.value ? e.target.value.replace(/[^0-9]+/gi, "") : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEditPhlebo}
        onClose={handleClose}
        closeAfterTransition
       
      >
        {loading ? (
          <Loader />
        ) : (
          <Fade in={openEditPhlebo}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Edit Phlebo
                </h4>
                <CloseIcon onClick={() => handleClose()} />
              </div>

              <Grid
                container
                direction="row"
                alignItems="flex-start"
                
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Employe Id</h6>
                  <TextField
                    name="empId"
                    type="text"
                    defaultValue={editPhleboUser?.empid}
                    value={employeId}
                    inputProps={{ maxLength: 10 }}
                    className="input"
                    variant="outlined"
                    placeholder="Enter Employee Id"
                    onChange={(e) => {
                      inputAlphaNumericEmpId(e);
                      setEmployeId(e.target.value as string);
                    }}
                    style={{ width: "100%", minWidth: "200px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Name</h6>
                  <TextField
                    name="name"
                    type="text"
                    defaultValue={
                      editPhleboUser &&
                      editPhleboUser?.user &&
                      editPhleboUser?.user?.name
                    }
                    value={phleboName}
                    className="input"
                    variant="outlined"
                    placeholder="Enter Name"
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      inputAlphaNumeric(e);
                      setPhleboName(e.target.value as string);
                    }}
                    style={{ width: "100%", minWidth: "200px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Username</h6>
                  <TextField
                    name="username"
                    type="text"
                    defaultValue={
                      editPhleboUser &&
                      editPhleboUser?.user &&
                      editPhleboUser?.user?.username
                    }
                    value={phleboUserName}
                    className="input"
                    variant="outlined"
                    placeholder="Enter User Name"
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      inputAlphaNumeric(e);
                      setPhleboUserName(e.target.value as string);
                    }}
                    style={{ width: "100%", minWidth: "200px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Phlebo Number</h6>
                  <TextField
                    name="number"
                    type="text"
                    defaultValue={
                      editPhleboUser &&
                      editPhleboUser?.user &&
                      editPhleboUser?.user?.phonenumber
                    }
                    value={phoneNumber}
                    className="input"
                    variant="outlined"
                    placeholder="Enter Phone Number"
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      inputAlphaNumericPhleboNum(e);
                      setPhoneNumber(e.target.value as string);
                    }}
                    style={{ width: "100%", minWidth: "200px" }}
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        phoneNumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.phoneNumber && "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Phlebo Source</h6>
                  <Select
                    className="input"
                    name="booking_status"
                    variant="outlined"
                    value={phleboSource}
                    style={{ width: "100%", height: "40px", padding: "0" }}
                    onChange={(e) => setPhleboSource(e.target.value as string)}
                    required
                  >
                    <MenuItem disabled value={"none"}>
                      Select Source
                    </MenuItem>
                    <MenuItem value={"rcl"}>RCL</MenuItem>
                    <MenuItem value={"kartvya"}>Kartvya</MenuItem>
                    <MenuItem value={"cc"}>CC</MenuItem>
                    <MenuItem value={"others"}>Others</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Select Zone</h6>
                  <Autocomplete
                    id="zone"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setZoneName(obj.id);
                      }
                    }}
                    value={
                      zoneList &&
                      zoneList?.results &&
                      zoneList?.results.length > 0 &&
                      zoneList?.results.find((code: any) => {
                        return code?.city?.name === baseCity;
                      })
                    }
                    options={zoneList?.results || []}
                    freeSolo
                    blurOnSelect
                    getOptionLabel={(option: any) =>
                      `${option?.id}, ${option?.city?.name}`
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getAutoCompleteZone(newInputValue.split(",")[0]);
                      }, 2000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Zone"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <h6>Base City</h6>
                  <Autocomplete
                    id="base_city"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setBase_City(obj?.id);
                      }
                    }}
                    options={cities || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    inputValue={baseCityDef || ""}
                    getOptionLabel={(option: any) => String(option?.name)}
                    onInputChange={(event, newInputValue) => {
                      setBaseCityDef(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        placeholder="Select Base City"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <h6>Phlebo or DRM</h6>
                  <Select
                    className="input"
                    name="phlebo_rider"
                    variant="outlined"
                    value={phleboOrRider}
                    style={{ width: "100%", height: "40px", padding: "0" }}
                    onChange={(e) => setPhleboOrRider(e.target.value as string)}
                    required
                  >
                    <MenuItem disabled value={"none"}>
                      Select Phlebo or DRM
                    </MenuItem>
                    <MenuItem value={"phlebo"}>Phlebo</MenuItem>
                    <MenuItem value={"DRM"}>DRM</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <h6>MAP MY INDIA ID</h6>
                  <TextField
                    name="mapMyIndiaId"
                    type="text"
                    value={mapMyIndiaId}
                    inputProps={{ maxLength: 10 }}
                    className="input"
                    variant="outlined"
                    placeholder="Enter Map India Id"
                    onChange={(e) => {
                      inputAlphaNumericEmpId(e);
                      setmapMyIndiaId(e.target.value as string);
                    }}
                    style={{ width: "100%", minWidth: "200px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <h6>Start Time</h6>
                  <TextField
                    id="time"
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    // sx={{ width: 150 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <h6>End Time</h6>
                  <TextField
                    id="time"
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    // sx={{ width: 150 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <h6>Phlebo Status</h6>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={isactive}
                    label="IsActive"
                    onChange={(e) => setIsActive(!isactive)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}></Grid>
                <Grid item xs={12} sm={4} md={2}></Grid>
                <Grid item xs={12} sm={4} md={2}></Grid>
                <Grid
                  item
                  xs={12}
                  style={{ padding: "20px", textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!mobileValidation.phoneNumber}
                    style={{ width: 300 }}
                    onClick={() => {
                      UpdatePhlebo();
                      setSubmitBtnClicked(true);
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  phleboPhone: state.TechSupportReducer.phleboPhone,

  cities: state.TechSupportReducer.cities,
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  createNewPhlebo,
  updatePhlebo,
  getCities,
})(CreatePhlebo);