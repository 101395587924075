import React, { useState } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import {
  Button,
  Paper,
  TextField,
  Grid,
  Chip,
  TableContainer,
  Dialog,
} from "@mui/material";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import moment from "moment";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect } from "react";
import {
  createLab,
  getLabCredential,
  updateLab,
  getCities,
  getRiderList,
  getLabLogs,
  resetLogs,
} from "../../actions/TechSupportActions";
import Autocomplete from "@mui/material/Autocomplete";
import { useHistory } from "react-router-dom";
import Loader from "../loader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter, TablePagination } from "@material-ui/core";
import { genrateManageLabs } from "../../../helpers/generateUrl";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    fontWeight: {
      fontWeight: "bold",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "1rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
      marginTop: "28px",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -9,
      marginLeft: -9,
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
  })
);

interface Props {
  createLab: any;
  loading: boolean;
  getLabCredential: any;
  labCredential: any;
  updateLab: any;
  getCities: any;
  cities: any;
  getRiderList: any;
  riderList: any;
  getLabLogs: any;
  labLogs: any;
  resetLogs: any;
}
const LabMaster: React.FC<Props> = ({
  createLab,
  loading,
  getLabCredential,
  labCredential,
  updateLab,
  getCities,
  cities,
  getRiderList,
  riderList,
  getLabLogs,
  labLogs,
  resetLogs,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [name, setName] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [publicKey, setPublicKey] = useState<any>("");
  const [nablNo, setNablNo] = useState<any>("");
  const [latitude, setLatitude] = useState<any>("");
  const [longitude, setLongitude] = useState<any>("");
  const [labId, setLabId] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [number, setNumber] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [associatedCities, setAssociatedCities] = useState<any>([]);
  const [associatedCitiesDef, setAssociatedCitiesDef] = useState<any>([]);
  const [cityName, setCityName] = useState<any>("");
  const [riders, setRiders] = useState<any>([]);
  const [ridersDef, setRidersDef] = useState<any>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [dataId, setDataId] = useState<any>("");
  const [isEdit, setIsEdit] = useState<any>(false);
  const [openLogs, setOpenLogs] = useState<any>(false);
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const [names, setNames] = useState<any>("");

  /* This funciton  is used for Form_Submit */
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      lab_address: address,
      riders: riders,
      contact_number: number,
      public_key: publicKey,
      nabl_certification_number: nablNo,
    };
    if (!riders) {
      delete data.riders;
    }
    if (!address) {
      delete data.lab_address;
    }
    if (!labId) {
      delete data.labid;
    }
    if (!publicKey) {
      delete data.public_key;
    }
    if (!nablNo) {
      delete data.nabl_certification_number;
    }
    if (isEdit) {
      await updateLab(data, dataId);
    } else {
      await createLab(data);
    }
    getLabCredential();
    handleClear();
  };

  /* This funciton  is used for filter */
  const filterPanellabs = async () => {
    setDisableFilterBtn(true);
    const body: any = {
      city_name: city,
      lab_name: names,
    };
    const url = genrateManageLabs(body).substring(2);
    getLabCredential(`${url}`);
  };

  /* This funciton  is used for Edit */
  const handleEdit = (list: any) => {
    setName(list?.name);
    setEmail(list?.email);
    setNumber(list?.contact_number ? Number(list?.contact_number) : "");
    setAddress(list?.lab_address || "");
    setLabId(list?.labid || "");
    setLatitude(list?.latitude || "");
    setLongitude(list?.longitude || "");
    setCity(list?.city?.id);
    setPublicKey(list?.public_key);
    setNablNo(list?.nabl_certification_number);
    setCityName(list?.city?.name || "");
    setAssociatedCitiesDef(list?.associated_cities);
    setRidersDef(list?.riders || []);
    setRiders(
      list?.riders?.length > 0 && list?.riders?.map((val: any) => val?.id)
    );
    setAssociatedCities(
      list?.associated_cities?.length > 0 &&
        list?.associated_cities?.map((val: any) => val?.id)
    );
    setIsActive(list?.is_active);
    setDataId(list?.id);
    setIsEdit(true);
  };
  const handleClear = () => {
    history.push("/dashboard/ts/lab_master");
  };

  /* This funciton  is used for Pagination */
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        labCredential?.links && labCredential?.links?.next.split("?")[1];
      getLabCredential(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        labCredential?.links && labCredential?.links?.previous.split("?")[1];
      getLabCredential(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };

  const handleLog = (id: any) => {
    getLabLogs(`?lab_cre=${id}`);
    setOpenLogs(true);
  };

  const handleLogClose = () => {
    resetLogs();
    setOpenLogs(false);
  };

  useEffect(() => {
    getLabCredential();
    getRiderList(`?usergroup=Rider`);
    // getCities();
  }, []);

  
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2>Labs Credentials</h2>
      <div className={classes.contentTable}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              fullWidth
              className="input"
              name="title"
              type="text"
              label="Enter Name"
              value={names}
              variant="outlined"
              style={{ margin: "0", width: "100%", padding: "1px" }}
              onChange={(e) => setNames(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCity(obj.id);
                }
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity(0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City *"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={filterPanellabs}
              disabled={disableFilterBtn}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => handleClear()}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12}>
        {isEdit ? (
          <form onSubmit={submitForm}>
            <div className={classes.contentHeader}>
              <div className={classes.leftContentHeader}>
                <h2>Update Labs Credentials</h2>
              </div>
            </div>
            <div className={classes.contentTable}>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    size="small"
                    label="Contact Number"
                    type="number"
                    variant="outlined"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    size="small"
                    label="Lab Address"
                    variant="outlined"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Autocomplete
                    id="rider"
                    key={dataId}
                    onChange={(event, newValue) => {
                      let sourceIds: any = [];
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        sourceIds = obj.map((item: any) => item?.id);
                      }
                      setRiders(sourceIds);
                    }}
                    options={riderList?.results || []}
                    freeSolo
                    aria-required
                    limitTags={1}
                    multiple
                    defaultValue={ridersDef}
                    getOptionLabel={(option: any) =>
                      option?.user?.username
                        ? option?.user?.username
                        : option?.user?.name === undefined
                        ? option?.user
                        : ""
                    } 
                    getOptionDisabled={(option: any) => {
                      return ridersDef.includes(option?.id);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getRiderList(
                          `?usergroup=Rider&user__username__icontains=${newInputValue}`
                        );
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setRiders([]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Riders"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    size="small"
                    label="Public Key"
                    variant="outlined"
                    value={publicKey}
                    onChange={(e) => setPublicKey(e.target.value)}
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    size="small"
                    label="NABL Certification No"
                    variant="outlined"
                    value={nablNo}
                    onChange={(e) => setNablNo(e.target.value)}
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    {isEdit ? "Update" : "Create"}
                    {loading && <CircularProgress size={24} />}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        ) : (
          ""
        )}
      </Grid>
      <Dialog
        open={openLogs}
        onClose={() => handleLogClose()}
        fullWidth
        maxWidth="md"
      >
        <TableContainer style={{ height: 400, padding: 20 }}>
          {loading ? (
            <Loader />
          ) : (
            <Table size="small" stickyHeader aria-label="simple">
              <TableBody>
                {labLogs && labLogs?.results && labLogs?.results?.length > 0
                  ? labLogs.results.map((val: any, index: any) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{ width: 190 }}>
                          {moment(val?.created_at).format(
                            "DD-MM-YYYY, hh:mm A"
                          )}
                          :
                        </StyledTableCell>
                        <StyledTableCell>{val?.comment}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  : "NO LOGS FOUND!"}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Dialog>
      <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "560px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              {labCredential &&
                labCredential?.results &&
                labCredential?.results?.length === 0 && (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <p style={{ fontSize: "20px", fontWeight: "600" }}>
                      No data available!
                    </p>
                  </div>
                )}
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    S. No.
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Logs
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Lab Id
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    NABL Certification Number
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Name
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Pubilc key
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Email
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Contact No.
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    City
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Associated Cities
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Address
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Longitude
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Latitude
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Riders
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Is Active
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    className={classes.fontWeight}
                  >
                    Edit
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {labCredential.results &&
                  labCredential.results.length > 0 &&
                  labCredential.results.map((list: any, index: any) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <MessageTwoToneIcon
                          onClick={() => handleLog(list.id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.labid}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.nabl_certification_number}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.public_key}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.email}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.contact_number}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.city?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.associated_cities?.length > 0 &&
                          list?.associated_cities?.map((val: any) => {
                            return <Chip size="small" label={val?.name} />;
                          })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.lab_address}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.longitude}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.latitude}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.riders?.length > 0 &&
                          list?.riders?.map((val: any) => {
                            return <Chip size="small" label={val?.user} />;
                          })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.is_active === true ? "Yes" : "No"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          onClick={() => handleEdit(list)}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={2}
                    count={labCredential?.count || 0}
                    rowsPerPage={labCredential?.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  labCredential: state.TechSupportReducer.labCredential,
  cities: state.TechSupportReducer.cities,
  riderList: state.TechSupportReducer.riderList,
  labLogs: state.TechSupportReducer.labLogs,
  loading: state.TechSupportReducer.loading,
});
export default connect(mapStateToProps, {
  createLab,
  getLabCredential,
  updateLab,
  getCities,
  getRiderList,
  resetLogs,
  getLabLogs,
})(LabMaster);
