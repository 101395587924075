import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TextField, Grid, Select, MenuItem } from "@material-ui/core";
import Button from "@mui/material/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Chip } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import {
  updatePanelUser,
  getLeadSource,
  createPanelUser,
  getPanelUserListTeamLead,
  getLanguage,
  getOzontelSkillsData,
  getDialer,
  getOrgTypes,
  getChatSkillsList,
  getAllUsers,
} from "../../actions/TechSupportActions";
import { useHistory } from "react-router-dom";
import Loader from "../loader";
import { ADDUSER_EXECUTIVE_TYPES } from "../constant";
import user from "pusher-js/types/src/core/user";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  contentTable: {
    width: "100%",
    padding: "10px 0px 10px 0px",
  },
}));
type ModalProps = {
  setOpenUserModal: Function;
  openUserModal: boolean;
  updatePanelUser: any;
  dialer: any;
  loading: any;
  getLeadSource: any;
  lead_source: any;
  createPanelUser: any;
  teamLeadList: any;
  chatSkillsList: any;
  language: any;
  orgTypesList: any;
  getPanelUserListTeamLead: any;
  getLanguage: any;
  getOzontelSkillsData: any;
  getDialer: any;
  getOrgTypes: any;
  getChatSkillsList: any;
  ozontelskilldata: any;
  userDetails: any;
  usersList: any;
  getAllUsers: any;
  userData: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  userDetails,
  setOpenUserModal,
  openUserModal,
  loading,
  dialer,
  getLeadSource,
  lead_source,
  createPanelUser,
  teamLeadList,
  chatSkillsList,
  language,
  orgTypesList,
  getPanelUserListTeamLead,
  getLanguage,
  getOzontelSkillsData,
  ozontelskilldata,
  getDialer,
  getOrgTypes,
  getChatSkillsList,
  updatePanelUser,
  usersList,
  getAllUsers,
  userData
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [name, setName] = useState(userDetails && userDetails?.fullname);
  const [username, setUserName] = useState(
    userDetails && userDetails?.username
  );
  const [phoneNumber, setPhoneNumber] = useState<String>(
    userDetails && userDetails?.phonenumber
  );
  const [email, setEmail] = useState<String>(userDetails && userDetails?.email);
  const [userGroup, setUserGroup] = useState<any>(
    userDetails && userDetails?.usergroup
  );
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });
  const [password, setPassword] = useState(
    userDetails && userDetails?.password
  );
  const [dialers, setDialers] = useState(userDetails && userDetails?.dailer);
  const [orgType, setOrgType] = useState<any>(
    userDetails && userDetails?.org_types
  );
  const [reportingLocation, setReportingLocation] = useState<any>(
    userDetails && userDetails?.reporting_location
  );
  const [userType, setUserType] = useState<any>(
    userDetails && userDetails?.usertype
  );
  const [clicktoCall, setClickToCall] = useState<any>(
    userDetails && userDetails?.clicktoCall
  );
  const [ameyoUsername, setAmeyoUsername] = useState<any>(userDetails?.amyeo_user);
  const [amyeoPassword, setAmeyoPassword] = useState<any>(userDetails?.amyeo_password);
  const [isactive, setIsActive] = useState<boolean>(false);
  const [employeeId, setEmployeeId] = useState<String>("");
  const [teamLead, setTeamLead] = useState<any>();
  const [disaplayTeamLead, setDisplayTeamLead] = useState<any>("");
  const [chatskills, setChatSkills] = useState<any>("");
  const [executiveType, setExecutiveType] = useState(userDetails && userDetails?.executive_type);
  const [languages, setLanguages] = useState<any>([]);
  const [ozontelName, setozontel] = useState<any>([]);
  const [ozontelId, setOzontelId] = useState<any>([]);
  const timer = useRef<any>(0);
  const inputAlphaNumeric = (e: any) => {
    const value = (e.target.value) ? e.target.value.replace(/[^ a-zA-Z_ ]+/ig, "") : '';
    if (e.target.value !== value) {
      e.target.value = value;
    }
  }

  useEffect(() => {
    if (userDetails?.asigned && userDetails?.asigned?.length > 0) {
      setDisplayTeamLead(userDetails?.asigned[0]?.user__username);
    }
  }, [userDetails])

  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-0987654321`".includes(e.key) &&
      e.preventDefault()
    );
  };
  const handleClose = () => {
    setOpenUserModal(false);
    history.push("/dashboard/ts/users");
  };

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };
  const submitHandler = async () => {
    const body: any = {
      username: username,
      email,
      fullname: name,
      phonenumber: phoneNumber,
      password: password,
      usergroup: userGroup,
      empid:employeeId,
      ctc_from: clicktoCall,
      dailer: dialers,
      asigned: [teamLead],
      usertype: userType,
      org_types: orgType,
      languages: languages,
      reporting_location: reportingLocation,
      executive_type: executiveType,
      ozontel_skills: ozontelId,
      chat_skills: chatskills,
      is_active: isactive,
      amyeo_user: ameyoUsername,
      amyeo_password: amyeoPassword,
    };
    await updatePanelUser(body, userDetails.phonenumber);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openUserModal}
        onClose={handleClose}
        closeAfterTransition
       
      >
        {loading ? (
          <Loader />
        ) : (
          <Fade in={openUserModal}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Edit Panel User
                </h4>
                <CloseIcon onClick={() => handleClose()} />
              </div>
              <div className={classes.contentTable}>
                <Grid container spacing={4} style={{ margin: "1rem auto" }}>
                  <h4> Login Information -</h4>
                  <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                    <Grid item xs={12} md={3}>
                      <h6>
                        User Name <sup>*</sup>{" "}
                      </h6>
                      <TextField
                        className="input"
                        name="title"
                        type="text"
                        placeholder="Enter Username"
                        value={username}
                        defaultValue={userDetails?.username}
                        variant="outlined"
                        style={{ margin: "0", width: "100%", padding: "1px" }}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>
                        Email <sup>*</sup>{" "}
                      </h6>
                      <TextField
                        className="input"
                        name="customer_email"
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        variant="outlined"
                        defaultValue={userDetails.email}
                        style={{ margin: "0", width: "100%", padding: "1px" }}
                        onChange={(e) => setEmail(e.target.value as String)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>
                        User Group<sup>*</sup>{" "}
                      </h6>
                      <Autocomplete
                        id="users_list"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setUserGroup(obj.name);
                          }
                        }}
                        options={usersList?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) =>
                          option?.id && option?.name
                        }
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getAllUsers(`?name=${newInputValue}`);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setUserGroup("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Enter User Group"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>
                        Password <sup>*</sup>{" "}
                      </h6>
                      <TextField
                        className="input"
                        name="title"
                        type="text"
                        placeholder="Enter Password"
                        value={password}
                        variant="outlined"
                        style={{ margin: "0", width: "100%", padding: "1px" }}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                  <h4>Employee Information -</h4>
                  <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                    <Grid item xs={12} md={3}>
                      <h6>
                        Full Name <sup>*</sup>{" "}
                      </h6>
                      <TextField
                        className="input"
                        name="title"
                        type="text"
                        placeholder="Enter FullName"
                        value={name}
                        defaultValue={userDetails?.fullname}
                        variant="outlined"
                        style={{ margin: "0", width: "100%", padding: "1px" }}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>Phone Number</h6>
                      <TextField
                        className="input"
                        name="customer_phonenumber"
                        type="text"
                        // onWheel={(e: any) => e.target.blur()}
                        // onInput={(e: any) => {
                        //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                        // }}
                        value={phoneNumber}
                        placeholder="Enter Mobile Number"
                        defaultValue={userDetails?.phonenumber}
                        variant="outlined"
                        style={{ margin: "0", width: "100%", padding: "1px" }}
                        onChange={(e) =>
                          setPhoneNumber(e.target.value as String)
                        }
                        onBlur={() => verifyMobile("mobile")}
                        onFocus={() => {
                          setMobileValidation((prev: any) => ({
                            ...prev,
                            phoneNumber: true,
                          }));
                        }}
                        helperText={
                          !mobileValidation.phoneNumber &&
                          "Incorrect Phone Number"
                        }
                        
                        // required
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>
                        Employee Id <sup>*</sup>{" "}
                      </h6>
                      <TextField
                        className="input"
                        name="employee_id"
                        type="text"
                        placeholder="Enter Employee Id"
                        value={employeeId}
                        variant="outlined"
                        style={{ margin: "0", width: "100%", padding: "1px" }}
                        onChange={(e) => setEmployeeId(e.target.value)}
                        // required
                      />
                    </Grid>
                  </Grid>
                  <h4>Panel User Information -</h4>
                  <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                    <Grid item xs={12} md={3}>
                      <h6>
                        User Type <sup>*</sup>{" "}
                      </h6>
                      <Select
                        className="input"
                        name="usertype"
                        variant="outlined"
                        value={userType}
                        style={{
                          width: "100%",
                          margin: "0",
                          height: "40px",
                          padding: "1px",
                        }}
                        onChange={(e) => setUserType(e.target.value as String)}
                        required
                      >
                        <MenuItem disabled value={"none"}>
                          User Type
                        </MenuItem>
                        <MenuItem value={"b2c"}>B2C</MenuItem>
                        <MenuItem value={"b2b"}>B2B</MenuItem>
                        <MenuItem value={"both"}>BOTH</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>
                        Org Type<sup>*</sup>{" "}
                      </h6>
                      <Autocomplete
                        id="Org_Type"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            // sourceIds = obj.map((item: any) => item.id);
                            let storeData: any = [];
                            obj?.map((list: any) => {
                              storeData.push(list?.id);
                            });
                            setOrgType(storeData);
                          }
                        }}
                        options={orgTypesList?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        multiple
                        // onKeyDown={onKeyDown}
                        getOptionLabel={(option: any) => {
                          return option?.name;
                        }}
                        getOptionDisabled={(option: any) => {
                          return languages.includes(option?.id);
                        }}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getOrgTypes(newInputValue);
                          }, 1000);
                          if (newInputValue?.length === 0) {
                            setOrgType([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Org Types"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                            required
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                            onKeyDown={(e) => {
                              blockCharInAutocompleteField(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>
                        Assigned <sup>*</sup>{" "}
                      </h6>
                      <Autocomplete
                        id="team_lead"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setTeamLead(obj.id);
                          }
                        }}
                        freeSolo
                        blurOnSelect
                        limitTags={1}
                        options={teamLeadList?.results || []}
                        disableClearable
                        disableCloseOnSelect
                        inputValue={disaplayTeamLead}
                        // defaultValue={userDetails?.user__username}
                        getOptionLabel={(option: any) =>
                          option?.id && option?.user?.username
                        }
                        onInputChange={(event, newInputValue) => {
                          // clearTimeout(timer.current);
                          // timer.current = setTimeout(() => {
                          //   getPanelUserListTeamLead(
                          //     `?code=${newInputValue}&usergroup=TechSupport`
                          //   );
                          // }, 1000);
                          // if (newInputValue.length === 0) {
                          // setDisplayTeamLead(newInputValue)
                            
                          // }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Team Lead"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                            required
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <h6>
                        Reporting Location<sup>*</sup>{" "}
                      </h6>
                      <Select
                        className="input"
                        name="ReportingLocation"
                        variant="outlined"
                        value={reportingLocation}

                        style={{
                          width: "100%",
                          margin: "0",
                          height: "40px",
                          padding: "1px",
                        }}
                        required
                        onChange={(e) =>
                          setReportingLocation(e.target.value as String)
                        }
                      >
                        <MenuItem disabled value={"none"}>
                          ReportingLocation
                        </MenuItem>
                        <MenuItem value={"Gurugram"}>Gurugram</MenuItem>
                        <MenuItem value={"Noida"}>Noida</MenuItem>
                        <MenuItem value={"GGN2"}>GGN2</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>Executive Type</h6>
                      <Select
                        id="ExecutiveTypeFilter"
                        className="ExecutiveTypes"
                        variant="outlined"
                        value={executiveType}
                        defaultValue={userDetails?.executive_type}
                        style={{
                          width: "100%",
                          minWidth: "150px",
                          textAlign: "left",
                          height: "40px",
                          padding: "1px",
                        }}
                        onChange={(e) =>
                          setExecutiveType(e.target.value as string)
                        }
                      >
                        <MenuItem value={"none"} disabled>
                          Executive Type
                        </MenuItem>
                        {ADDUSER_EXECUTIVE_TYPES &&
                          ADDUSER_EXECUTIVE_TYPES.map((exe_type: any) => {
                            return (
                              <MenuItem value={exe_type.value}>
                                {exe_type.value}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>Chat Skills</h6>
                      <Autocomplete
                        id="chat_skills_list"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            // sourceIds = obj.map((item: any) => item.id);
                            let storeData: any = [];
                            obj?.map((list: any) => {
                              storeData.push(list?.id);
                            });
                            setChatSkills(storeData);
                          }
                        }}
                        options={chatSkillsList?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        multiple
                        // onKeyDown={onKeyDown}
                        getOptionLabel={(option: any) => {
                          return option?.name;
                        }}
                        getOptionDisabled={(option: any) => {
                          return languages.includes(option?.id);
                        }}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getChatSkillsList(newInputValue);
                          }, 1000);
                          if (newInputValue?.length === 0) {
                            setChatSkills([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Chat Skills"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                            onKeyDown={(e) => {
                              blockCharInAutocompleteField(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>Ozontel Skill</h6>
                      <Autocomplete
                        id="skill"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let userARR: any = [];
                            let sourceId: any = [];
                            newValue.map((val: any) => {
                              let obj = JSON.parse(
                                JSON.stringify(val, null, " ")
                              );
                              userARR.push(obj);
                              sourceId.push(obj.id);
                            });
                            setozontel(userARR);
                            setOzontelId(sourceId);
                          }
                        }}
                        value={ozontelName}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getOzontelSkillsData(`?search=${newInputValue}`);
                          }, 500);
                        }}
                        multiple
                        disableClearable
                        disableCloseOnSelect
                        limitTags={1}
                        options={ozontelskilldata?.results || []}
                        freeSolo
                        blurOnSelect
                        getOptionDisabled={(option: any) => {
                          return ozontelId.includes(option.id);
                        }}
                        getOptionLabel={(option: any) =>
                          String(option?.id) && String(option?.name)
                        }
                        renderTags={(value: string[], getTagProps) =>
                          value.map((option: any, index: number) => (
                            <Chip
                              key={index}
                              variant="default"
                              color="primary"
                              label={String(option?.id) && String(option?.name)}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Ozontel Skill"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                            className="input"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>Dialer</h6>
                      <Autocomplete
                        id="dialer"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setDialers(obj.id);
                          }
                        }}
                        options={dialer?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) =>
                          option?.did && option?.campaign_name
                        }
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getDialer(`?campaign_name=${newInputValue}`);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setDialers("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Dialer"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                            required
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <h6>
                        Click To Call<sup>*</sup>{" "}
                      </h6>
                      <Select
                        className="input"
                        name="sampleregisterd"
                        variant="outlined"
                        value={clicktoCall}
                        style={{
                          width: "100%",
                          margin: "0",
                          height: "40px",
                          padding: "1px",
                        }}
                        defaultValue={userDetails.ctc_from}
                        onChange={(e) =>
                          setClickToCall(e.target.value as String)
                          //handleshowing(e)
                        }
                        required
                      >
                        <MenuItem disabled value={"none"}>
                          Click To Call
                        </MenuItem>
                        <MenuItem value={"TATATELE"}>TATA TELE</MenuItem>
                        <MenuItem value={"TATATELEOFFLINE"}>
                          TATATELE OFFLINE
                        </MenuItem>
                        <MenuItem value={"KNO"}>KNOWLARITY</MenuItem>
                        <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                        <MenuItem value={"CZENTRIX"}>C-Zentrix</MenuItem>
                        <MenuItem value={"AMEYOCALL"}>AMEYOCALL</MenuItem>
                      </Select>
                    </Grid>

                    {
                      clicktoCall === "AMEYOCALL" && (
                        <>
                          <Grid item xs={12} md={3} >
                            <h6>
                              Ameyo Username
                            </h6>
                            <TextField
                              className="input"
                              name="title"
                              type="text"
                              placeholder="Enter AMEYOCALL"
                              value={ameyoUsername}
                              // defaultValue={userDetails?.username}
                              variant="outlined"
                              inputProps={{ maxLength: 35 }}
                              style={{
                                width: "100%",
                                margin: "0",
                                padding: "1px",
                              }}
                            onChange={
                              (e) => {
                                setAmeyoUsername(e.target.value)
                                inputAlphaNumeric(e);
                              }
                            }
                            />
                          </Grid>
                          <Grid item xs={12} md={3} >
                            <h6>
                              Password
                            </h6>
                            <TextField
                              className="input"
                              name="title"
                              type="text"
                              placeholder="Enter Password"
                              value={amyeoPassword}
                              variant="outlined"
                              inputProps={{ maxLength: 35 }}
                              style={{
                                width: "100%",
                                margin: "0",
                                padding: "1px",
                              }}
                              onChange={(e) => {
                                setAmeyoPassword(e.target.value)

                              }
                              }
                              required
                            />
                          </Grid>
                        </>
                      )}
                    <Grid item xs={12} md={3}>
                      <h6>Language</h6>
                      <Autocomplete
                        id="language"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            // sourceIds = obj.map((item: any) => item.id);
                            let storeData: any = [];
                            obj?.map((list: any) => {
                              storeData.push(list?.iso_639_1);
                            });
                            setLanguages(storeData);
                          }
                        }}
                        options={language?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        multiple
                        // onKeyDown={onKeyDown}
                        getOptionLabel={(option: any) => {
                          return option?.name_en;
                        }}
                        getOptionDisabled={(option: any) => {
                          return languages.includes(option?.iso_639_1);
                        }}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getLanguage(newInputValue);
                          }, 1000);
                          if (newInputValue?.length === 0) {
                            setLanguages([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Language"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                            onKeyDown={(e) => {
                              blockCharInAutocompleteField(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <h6>Is Active</h6>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={isactive}
                        label="IsActive"
                        onChange={(e) => setIsActive(!isactive)}
                      />
                    </Grid>

                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={3}
                    style={{
                      margin: "1rem 0",
                      width: "100%",
                      padding: "1rem",
                    }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        disabled={!mobileValidation?.phoneNumber}
                        onClick={submitHandler}
                      >
                        Update User
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Fade>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  lead_source: state.TechSupportReducer.lead_source,
  teamLeadList: state.TechSupportReducer.teamLeadList,
  chatSkillsList: state.TechSupportReducer.chatSkillsList,
  language: state.TechSupportReducer.language,
  orgTypesList: state.TechSupportReducer.orgTypesList,
  dialer: state.TechSupportReducer.dialer,
  ozontelskilldata: state.TechSupportReducer.ozontelskilldata,
  usersList: state.TechSupportReducer.usersList,
  userData: state.TechSupportReducer.userData,
});

export default connect(mapStateToProps, {
  updatePanelUser,
  getLeadSource,
  createPanelUser,
  getPanelUserListTeamLead,
  getLanguage,
  getOzontelSkillsData,
  getDialer,
  getOrgTypes,
  getChatSkillsList,
  getAllUsers,
})(CreatePhlebo);
