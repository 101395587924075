import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { addRemarkOnCall } from "../../actions/TechSupportActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  open: boolean;
  leadId: number;
  setOpen: Function;
  addRemarkOnCall: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  leadId,
  setOpen,
  addRemarkOnCall,
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState<any>("");
  const [score, setScore] = useState<any>(0);

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = async (e: any) => {
    let body = {
      score,
      remark: comment,
    };
    await addRemarkOnCall(leadId, body);
    setComment("");
    setScore(0);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <p
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Select an option
              </p>
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <TextField
              name="comment"
              type="number"
              value={score}
              className="input"
              label="Score"
              variant="outlined"
              placeholder="Score"
              onInput={(e: any) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 20);
              }}
              onChange={(e) => setScore(e.target.value)}
              style={{ width: "100%", marginBottom: "1rem" }}
              required
            />
            <TextField
              name="comment"
              type="text"
              multiline
              rows={4}
              value={comment}
              className="input"
              label="Remark"
              variant="outlined"
              placeholder="Remark"
              onChange={(e) => setComment(e.target.value as string)}
              style={{ width: "100%", marginBottom: "1rem" }}
              required
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              style={{ height: "50px" }}
              onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  addRemarkOnCall,
})(CommentsModal2);
