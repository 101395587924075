import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TextField, Grid } from "@material-ui/core";
import Button from '@mui/material/Button';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { postCustomerSpecificCoupon } from '../../actions/TechSupportActions';

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    postCustomerSpecificCoupon: any;
    open: boolean;
    setOpen: Function;
    customerSpecificCouponData: any;
    bookingId: any;
    leadId: any;
    bookingAmount: any;
    booking: any;
};

const CreateCouponModal: React.FC<ModalProps> = ({
    open,
    setOpen,
    postCustomerSpecificCoupon,
    customerSpecificCouponData,
    bookingId,
    leadId,
    booking,
    bookingAmount,
}) => {
    const classes = useStyles();

    const [couponCode, setCouponCode] = useState<string | null>("")
    const [couponName, setCouponName] = useState<string | null>("")
    const [discountType, setDiscountType] = useState<string>("none")
    const [amount, setAmount] = useState<any>()
    const [minPrice, setMinPrice] = useState<any>()
    const [validOnce, setValidOnce] = useState<boolean>(false)
    const [key, setKey] = useState<any>(false)

    const [userGroup, setUserGroup] = useState<boolean | any>("none")

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        setCouponName(booking?.customer_name?.slice(0, 3)+booking?.pk)
        setCouponCode(booking?.customer_name?.slice(0, 3)+booking?.pk)
    }, [booking])
    
    const createNewCoupon = async () => {
        const body: any = {
            created_on_booking: bookingId,
            lead_id: leadId,
            name: couponName,
            code: couponCode,
            discount: amount,
            minimum_price: minPrice,
            discount_type: discountType,
            valid_once_per_user: true,
            is_active: true,
            org_type: "homedx",
            upper_limit_percentage: 100
        }
        await postCustomerSpecificCoupon(body);
        handleClose()
    };

    const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    {customerSpecificCouponData?.length === 0 ?
                        <div className={classes.paper}>
                            <div className={classes.head}>
                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    Create Coupon
                                </h4>
                                <CloseIcon onClick={() => setOpen(false)} />
                            </div>
                            <Grid container direction="row" alignItems="flex-start" spacing={3} style={{ margin: "1rem auto" }}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Coupon Name</h6>
                                    <TextField
                                        name="coupon"
                                        type="text"
                                        value={couponName}
                                        className="input"
                                        variant="outlined"
                                        placeholder="Coupon Name"
                                        disabled
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        onChange={(e) => setCouponName(e.target.value as string)}
                                        style={{ width: "100%", minWidth: "200px" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Coupon Code</h6>
                                    <TextField
                                        name="coupon"
                                        type="text"
                                        value={couponCode}
                                        className="input"
                                        variant="outlined"
                                        disabled
                                        placeholder="Coupon Code"
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        onChange={(e) => setCouponCode(e.target.value as string)}
                                        style={{ width: "100%", minWidth: "200px" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Discount Type</h6>
                                    <Autocomplete
                                        id="discount"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                setKey(!key);
                                                if (obj.value !== discountType) {
                                                    setMinPrice(null);
                                                    setAmount(null);
                                                }
                                                setDiscountType(obj.value);
                                            }
                                        }}
                                        options={[
                                            {
                                                text: "Amount",
                                                value: "amount"
                                            },
                                            {
                                                text: "Percentage",
                                                value: "percentage"
                                            }
                                        ]}
                                        freeSolo
                                        blurOnSelect
                                        aria-required
                                        limitTags={1}
                                        // multiple
                                        getOptionLabel={(option: any) => option.text}
                                        getOptionDisabled={(option: any) => {
                                            return userGroup.includes(option.value)
                                        }}
                                        disableClearable
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                            <TextField className="input"
                                                {...params}
                                                placeholder="Discount Type"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Min Price</h6>
                                    <TextField
                                        key={key}
                                        name="minprice"
                                        type="number"
                                        value={minPrice}
                                        className="input"
                                        variant="outlined"
                                        placeholder="Min Price"
                                        onKeyDown={blockInvalidChar}
                                        onChange={(e) => {
                                            let minprice = parseInt(e.target.value);
                                            if (discountType === "amount" && minprice > Number(bookingAmount)) {
                                                minprice = Number(bookingAmount)
                                            } else if (discountType === "percentage" && minprice > 100) {
                                                minprice = 100
                                            }
                                            if (minprice < 0) minprice = 0;
                                            e.target.value = minprice.toString();
                                            setMinPrice(e.target.value)
                                        }}
                                        style={{ width: "100%", minWidth: "200px" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Discount</h6>
                                    <TextField
                                        key={key}
                                        name="discount"
                                        type="number"
                                        value={amount}
                                        className="input"
                                        variant="outlined"
                                        placeholder="Discount"
                                        onKeyDown={blockInvalidChar}
                                        onChange={(e) => {
                                            let dis = parseInt(e.target.value);
                                            if (discountType === "amount" && dis > Number(bookingAmount)) {
                                                dis = Number(bookingAmount)
                                            } else if (discountType === "percentage" && dis > 100) {
                                                dis = 100
                                            }
                                            if (dis < 0) dis = 0;
                                            e.target.value = dis.toString();
                                            setAmount(e.target.value)
                                        }}
                                        style={{ width: "100%", minWidth: "200px" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        style={{ height: "36px" }}
                                        disabled={discountType === "none" || couponName === "" || couponCode === "" || amount === null}
                                        onClick={createNewCoupon}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </div> :
                        <div className={classes.paper}>
                            <div className={classes.head}>
                                <h4
                                    style={{
                                        paddingTop: "10px",
                                    }}
                                    id="transition-modal-title"
                                >
                                    Coupon has been already created on this booking.
                                </h4>
                                <CloseIcon onClick={() => setOpen(false)} />
                            </div>
                            <Grid container direction="row" alignItems="flex-start" spacing={3} style={{ margin: "1rem auto" }}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Coupon Name: <span style={{ fontWeight: "300", color: "#0816bf" }}>{customerSpecificCouponData[0]?.name?.toUpperCase()}</span></h6>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Coupon Code: <span style={{ fontWeight: "300", color: "#0816bf" }}>{customerSpecificCouponData[0]?.code}</span></h6>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Min Price: <span style={{ fontWeight: "300", color: "#0816bf" }}>{customerSpecificCouponData[0]?.minimum_price}</span></h6>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Discount Type: <span style={{ fontWeight: "300", color: "#0816bf" }}>{customerSpecificCouponData[0]?.discount_type?.toUpperCase()}</span></h6>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h6>Discount: <span style={{ fontWeight: "300", color: "#0816bf" }}>{customerSpecificCouponData[0]?.discount}</span></h6>
                                </Grid>
                            </Grid>
                        </div>
                    }
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
    postCustomerSpecificCoupon,
})(CreateCouponModal);