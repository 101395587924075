import React, { useState, useRef, useEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  updateUsersPassword,
  getUserData,
  getUserList,
  updatePanelUser,
} from "../actions/TechSupportActions";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Loader from "../components/loader";
import { TableFooter, TablePagination } from "@material-ui/core";
import { genratePanelUserFiltr } from "../../helpers/generateUrl";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: 600,
    },
    body: {
      fontSize: 12,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      // marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
interface Props {
  loading: boolean;
  getUserData: any;
  userTableData: any;
  updateUsersPassword: any;
  getUserList: any;
  users: any;
  updatePanelUser: any;
}

const UserResetPassword: React.FC<Props> = ({
  loading,
  getUserData,
  userTableData,
  updateUsersPassword,
  getUserList,
  users,
  updatePanelUser,
}) => {
  const history = useHistory();
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [userName, setUserName] = useState<any>("");
  const [updatePassword, setUpdatePassword] = useState<any>([]);
  const [userId, setUserid] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [emailId, setEmailId] = useState<any>("");
  const [editUser, setEditUser] = useState<any>({});
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = users.links.next;
      getUserData(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = users.links.previous;
      getUserData(url.includes("?") ? url.substring(url.indexOf("?")) : "");
    }
    setPage(newPage);
  };

  useEffect(() => {
    getUserData();
    // getUserList();
  }, []);

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);

  const filterPanelUser = () => {
    setDisableFilterBtn(true);
    setVisible(true);

    const body: any = {
      phonenumber: phone,
      email: email,
    };
    const url = genratePanelUserFiltr(body).substring(2);
    getUserData(`?${url}`);
  };

  const handleEditUserDeatils = (user: any) => {
    setUserName(user?.username);
    setEditUser(user);
  };
  const handleUser = async () => {
    let body: any = {
      phonenumber: phoneNumber,
      email: emailId,
    };
    await updateUsersPassword(body, userName);
    setEditUser({});
    getUserData();
  };

  useEffect(() => {
    setPhoneNumber(editUser?.phonenumber);
    setEmailId(editUser?.email);
  }, [editUser]);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="reset-user-password">
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <h2>USERS DATA LIST</h2>
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{ backgroundColor: "#15717d" }}
                variant="contained"
                onClick={() => history.push(`/dashboard/ts/reset_pass`)}
              >
                Go to Reset Password
              </Button>
            </Grid>
          </Grid>
          <div className={classes.contentTable}>
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ alignItems: "left", marginBottom: "15px" }}
            >
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className="input"
                  name="phone"
                  type="number"
                  label="Phone"
                  value={phone}
                  variant="outlined"
                  onChange={(e) => setPhone(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className="input"
                  name="email"
                  type="email"
                  label="Email"
                  value={email}
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={filterPanelUser}
                  disabled={phone.length === 0 && email.length === 0}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ts/resetpassword")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <div className="data-table" style={{ width: "100%" }}>
        {visible && (
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            // style={{ maxHeight: "700px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">UserName</StyledTableCell>
                    <StyledTableCell align="center">
                      Mobile Number
                    </StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">Edit</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {userTableData &&
                    userTableData.results &&
                    userTableData.results.length > 0 &&
                    userTableData.results.map((user: any) => {
                      return (
                        <StyledTableRow key={user.username}>
                          <StyledTableCell align="center">
                            {user.username}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <TextField
                                className="input"
                                name="phone"
                                type="number"
                                label="Phone"
                                value={phoneNumber || ""}
                                variant="outlined"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                style={{ width: "100%" }}
                              />
                            ) : (
                              user?.phonenumber
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <TextField
                                className="input"
                                name="email"
                                type="email"
                                label="Email"
                                value={emailId || ""}
                                variant="outlined"
                                onChange={(e) =>
                                  setEmailId(e.target.value as string)
                                }
                                style={{ width: "100%" }}
                              />
                            ) : (
                              user?.email
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser.username === user.username ? (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleUser()}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleEditUserDeatils(user)}
                              >
                                Edit
                              </Button>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                {/* <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={2}
                    count={users.count || 0}
                    rowsPerPage={users.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableFooter> */}
              </Table>
            )}
          </TableContainer>
        )}
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  userTableData: state.TechSupportReducer.userTableData,
  users: state.TechSupportReducer.users,
});

export default connect(mapStateToProps, {
  getUserData,
  updateUsersPassword,
  getUserList,
  updatePanelUser,
})(UserResetPassword);
