import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Slider,
  Switch,
  TableContainer,
  TableFooter,
  TablePagination,
  TextField,
  withStyles,
  DialogContentText,
  Container,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Edit, Height, MessageRounded } from "@material-ui/icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ImagePreviewModal } from "./ImagePreviewModal";
import TicketCommentsModal from "../Center-Creation/CenterComments";
import "./index.sass";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { genrateCenterCreationFilter } from "../../../helpers/generateUrl";
import Loader from "../../components/loader";
import {
  ccToInv,
  createNewCenter,
  disableUser2fOtp,
  getAgentList,
  getBankName,
  getBusinessLocation,
  getCCConsumptionItems,
  getCenterInfo,
  getCenters,
  getCentreCities,
  getCentreCode,
  getCities,
  getIdentityProof,
  getIfscData,
  getMapMyIndiaAccessToken,
  getMrrsales,
  getpaymentMoodList,
  getPinCode,
  getRateListFilter,
  getSalesExecutive,
  getSalesManager,
  getTagProcessingLab,
  getTerritoryData,
  resetCCToInventoryRes,
  updateCenter,
  uploadCentres,
} from "../../actions/TechSupportActions";

const style1 = {
  position: "absolute" as "absolute",
  maxHeight: "500px",
  overflow: "scroll",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "1rem",
      padding: "1rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 35px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dialog: {
      zIndex: 1,
    },
    modalWhileSubmit: {
      position: "absolute",
      top: "42%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    startIt: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  })
);

const PrettoSlider = withStyles({
  root: {
    color: "rgb(146, 74, 145)",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "violet",
    border: "2px solid purple",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

interface Props {
  uploadCentres: any;
  uploadCentresData: any;
  getCentreCode: any;
  centreCode: any;
  getCenterInfo: any;
  getPinCode: any;
  centerInfo: any;
  pin_code: any;
  createNewCenter: any;
  getAgentList: any;
  agentBYUserGroup: any;
  getSalesExecutive: any;
  getSalesManager: any;
  salesManager: any;
  salesExecutive: any;
  updateCenter: any;
  getIdentityProof: any;
  identityProofData: any;
  getCentreCities: any;
  getCities: any;
  cities: any;
  centre_cities: any;
  getpaymentMoodList: any;
  paymentModeList: any;
  getRateListFilter: any;
  rateListFilter: any;
  getTagProcessingLab: any;
  tagProccessingLab: any;
  getBankName: any;
  bankName: any;
  loading: Boolean;
  userDetails: any;
  getCenters: any;
  centers: any;
  getBusinessLocation: any;
  get_business_location: any;
  // getCCConsumptionItems: any;
  // get_cc_consumption: any;
  ccToInv: any;
  cc_to_inv: any;
  resetCCToInventoryRes: any;
  getIfscData: any;
  ifscData: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
  disableUser2fOtp: any;
  getMrrsales: any;
  get_mrr_sales: any;
  getTerritoryData: any;
  territoryData: any;
  center_loading: any;
}
const CreateCentrePage: React.FC<Props> = ({
  uploadCentres,
  uploadCentresData,
  getCentreCode,
  centreCode,
  getCenterInfo,
  getPinCode,
  centerInfo,
  pin_code,
  createNewCenter,
  getAgentList,
  agentBYUserGroup,
  getSalesExecutive,
  getSalesManager,
  salesManager,
  salesExecutive,
  identityProofData,
  updateCenter,
  getIdentityProof,
  getCentreCities,
  getCities,
  cities,
  getpaymentMoodList,
  paymentModeList,
  getRateListFilter,
  rateListFilter,
  getTagProcessingLab,
  tagProccessingLab,
  centre_cities,
  getBankName,
  bankName,
  loading,
  userDetails,
  getCenters,
  centers,
  getBusinessLocation,
  get_business_location,
  // getCCConsumptionItems,
  // get_cc_consumption,
  ccToInv,
  cc_to_inv,
  resetCCToInventoryRes,
  getIfscData,
  ifscData,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  disableUser2fOtp,
  getMrrsales,
  get_mrr_sales,
  getTerritoryData,
  territoryData,
  center_loading,
}) => {
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = (e: any) => (
    e.preventDefault(), setOpen1(false), handleReset()
  );
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [center, setCentre] = useState<any>("");
  const [centerInp, setCentreInp] = useState<any>("");
  const [centerId, setCenterId] = useState<any>("");
  const [centerTypeFilter, setCentreTypeFilter] = useState<any>("");
  const [docLinks, setDocLinks] = useState<any>("");
  const [isActive, setIsActive] = useState<any>(true);
  const [centerName, setCentreName] = useState<any>("");
  const [centerType, setCentreType] = useState<any>("");
  const [territoryName, setTerritoryName] = useState<any>("");
  const [territoryId, setTerritoryId] = useState<any>("");
  const [roleL0, setRoleL0] = useState<any>("");
  const [roleL1, setRoleL1] = useState<any>("");
  const [roleL2, setRoleL2] = useState<any>("");
  const [roleL3, setRoleL3] = useState<any>("");
  const [roleL4, setRoleL4] = useState<any>("");
  const [identityProof, setIdentityProof] = useState<any>(1);
  const [sampleTransfer_type, setSampleTransfer_type] = useState<any>("");
  const [centerCode, setCentreCode] = useState<any>(
    centreCode?.centre_code || ""
  );
  const [expiryDate, setExpiryDate] = useState<any>(
    moment().add(5, "years").format("YYYY-MM-DD")
  );
  const [contractDate, setContractDate] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [tds, setTds] = useState<any>(1);
  const [mobileNumberFilter, setMobileNumberFilter] = useState<any>("");
  const [centerCodeFilter, setCenterCodeFilter] = useState<any>("");

  const [altNumber, setaltNumber] = useState<any>("");
  // const [sales_Manager, setSales_Manager] = useState<any>("");
  const [sales_ManagerDef, setSales_ManagerDef] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [managerName, setManagerName] = useState<any>({
    user: { username: "" },
  });
  const [sales_Executive, setSales_Executive] = useState<any>([]);
  const [sales_ExecutiveDef, setSales_ExecutiveDef] = useState<any>([]);
  const [ownerName, setOwnerName] = useState<any>("");
  const [images, setImages] = useState<any>([]);
  const [lastName, setLastName] = useState("");
  const [selectedAgeType, setSelectedAgeType] = React.useState("age");
  const [centerAddress, setCentreAddress] = React.useState("");
  const [cityName, setCityName] = useState<any>("");
  const [cityId, setCityId] = React.useState<any>();
  const [stateName, setStateName] = React.useState<any>();
  const [nationality, setNationality] = React.useState("Indian");
  const [centerHeadalterNateNumber, setCentreHeadAlternateNumber] =
    useState<any>("");
  const [centerHeadalContactNumber, setCentreHeadContactNumber] =
    useState<any>("");
  const [state, setState] = useState<any>("");
  const [email, setEmail] = React.useState("");
  const [busyId, setBusyId] = React.useState("");
  const [adharNumber, setAdharNumber] = React.useState<any>("");
  const [nameOnInvoice, setNameOnInvoice] = React.useState("");
  const [tanNumber, setTanNumber] = React.useState("");
  const [paymentTermsInDays, setPaymentTermsInDays] = React.useState("");
  const [area, setArea] = React.useState<String>("");
  const [securityAmount, setSecurityAmount] = React.useState("");
  const [serviceRateCategory, setServiceRateCategory] = React.useState<any>("");
  const [serviceRateCategoryDef, setServiceRateCategoryDef] =
    React.useState<any>("");
  const [barCodeType, setBarCodeType] = useState<any>("preprainted");
  const [tag_ProccessingLab, setTag_ProccessingLab] = React.useState("");
  const [categoryName, setCategoryName] = useState<any>("none");
  const [paymentMode, setPaymentMode] = React.useState("");
  const [bankAccountHolder, setbankAccountHolder] = React.useState("");
  const [bank, setBank] = React.useState("");
  const [bankInput, setBankInput] = React.useState("");
  const [gstinNumber, setGstinNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [ifscCode, setIfscCode] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [minAmountForNotification, setMinNotication] = React.useState("");
  const [minAmountForBill, setMinAmountForBill] = React.useState<any>(0);
  const [center_longitude, setCentreLongitude] = useState<any>("");
  const [center_latitude, setCentreLatitude] = useState<any>("");
  const [designation, setDesignation] = useState<any>("");
  const [creditLimit, setCreditLimit] = useState<any>("");
  const [invoiceCycle, setInvoiceCycle] = useState<any>("");
  const [reportWhatsappSentTo, setReportWhatsappSentTo] = useState<any>("");
  const [reportEmailSentTo, setReportEmailSentTo] = useState<any>("");
  const [reportSmsSentTo, setReportSmsSentTo] = useState<any>("");
  const [autosettle, setAutosettle] = useState<any>("manual_settlement");
  const [accountActive, setAccountActive] = useState<any>("true");
  const [serviceCharge, setServiceCharge] = useState<any>("false");
  const [packageRights, setPackageRights] = useState<any>("redcliffe");
  const [pinCodeId, setPincodeId] = useState<any>(null);
  const [sampleRegisterTo, setSampleRegisterTo] = useState<any>("itdose");
  const [file, setFile] = React.useState<any>([]);
  const [file1, setFile1] = React.useState<any>("");
  const [fileUpload, setFileUpload] = React.useState<any>([]);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [centerdata, setCenterdata] = useState<any>("");
  const [virtualNumber, setVirtualNumber] = useState<any>("");
  const [businessLocation, setBusinessLocation] = useState<any>({
    name: "",
    id: "",
  });
  const [rclAccNo, setRclAccNo] = useState<string>("");
  const [rclIfsc, setRclIfsc] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [rclBank, setRclBank] = useState<string>("");
  const [rclBranch, setRclBranch] = useState<string>("");
  const [rclBranchAddr, setRclBranchAddr] = useState<string>("");
  const [emailFilter, setEmailFilter] = React.useState("");
  const [cityNameFilter, setCityNameFilter] = useState<any>({
    id: "",
    name: "",
  });
  const [cityIdFilter, setCityIdFilter] = React.useState<any>();
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const [support_mobile, setSupportMobile] = React.useState<any>("9289090609");
  const [support_email, setSupportEmail] = React.useState<any>(
    "ccsupport@redcliffelabs.com"
  );
  const [support_Website, setSupportWebsite] = React.useState<any>(
    "www.redcliffelabs.com"
  );

  const [displayBusinessLocation, setDisplayBusinessLocation] =
    useState<any>("");
  // const[orgtype,setorgType]= useState<any>("");
  const [smsType, setSmsType] = React.useState({
    mail: false,
    sms: false,
    screen: false,
    isbillinglock: true,
    isapproved: false,
    is_booking_payment_sms: false,
    is_booking_sms: false,
    is_report_sms: false,
    is_report_whatsapp: false,
    is_report_email: false,
    is_shown_to_pha: false,
    is_shown_to_sales: false,
    is_auto_verify: false,
    is_price_hide: false,
    shownalltrue: false,
  });
  const [mobileValidation, setMobileValidation] = useState({
    mobileNumber: true,
    centerHeadalterNateNumber: true,
    centerHeadalContactNumber: true,
    altNumber: true,
    vno: true,
  });
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [centerShow, setCentreShow] = useState<boolean>(false);
  const [centerHeadShow, setCentreHeadShow] = useState<boolean>(false);
  const [centerBankShow, setCentreBankShow] = useState<boolean>(false);
  const [centerDocumnetShow, setCenterDocumnetShow] = useState<boolean>(false);
  const [centerControlingShow, setCentreControllingShow] =
    useState<boolean>(false);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  const [assignLabId, setAssignLabId] = useState<any>(5);
  const [editButton, setEditButton] = useState<any>(false);
  const [tdsDeduction, setTdsDeduction] = useState<any>(false);
  const [tdsRefund, setTdsRefund] = useState<any>(false);
  const [isNabl, setIsNabl] = useState<any>(true);
  const [isSpecialPackage, setIsSpecialPackage] = useState<any>(false);
  const [subCenterType, setSubCentreType] = useState<any>("");
  const [documentType, setDocumentType] = useState<any>("");
  const [isInventroy, setIsInventory] = useState<any>(false);
  const [isWebsite, setIsWebsite] = useState<any>(false);
  const [isPhysicalClosing, setIsPhysicalClosing] = useState<boolean>(false);

  const DefaultSwitch: any = withStyles({
    switchBase: {
      color: "red",
      "&$checked": {
        color: "green",
      },
      "&$checked + $track": {
        backgroundColor: "green",
      },
    },
    checked: {},
    track: {
      backgroundColor: "red",
    },
  })(Switch);

  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "mob" &&
      mobileNumber &&
      !mobileRegEx.test(mobileNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        mobileNumber: false,
      }));
    }
    if (
      type === "vno" &&
      virtualNumber &&
      !mobileRegEx.test(virtualNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        vno: false,
      }));
    }
    if (
      type === "centAlt" &&
      altNumber &&
      !mobileRegEx.test(altNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        altNumber: false,
      }));
    }
    if (
      type === "contAlt" &&
      centerHeadalterNateNumber &&
      !mobileRegEx.test(centerHeadalterNateNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        centerHeadalterNateNumber: false,
      }));
    }
    if (
      type === "contMob" &&
      centerHeadalContactNumber &&
      !mobileRegEx.test(centerHeadalContactNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        centerHeadalContactNumber: false,
      }));
    }
  };
  const CenterTypeData = [
    {
      value: "collection_center",
      text: "Collection Center",
    },
    {
      value: "direct_client",
      text: "Direct Client",
    },
    {
      value: "GE_Home_Collection",
      text: "GE Home Collection",
    },
    {
      value: "GE Lab",
      text: "GE Lab",
    },
    {
      value: "GE CC",
      text: "GE CC",
    },
    {
      value: "research",
      text: "Research",
    },
  ];

  const salutationData = [
    {
      value: "Mr",
      text: "Mr",
    },
    {
      value: "Master",
      text: "Master",
    },
    {
      value: "Miss",
      text: "Miss",
    },
    {
      value: "Smt",
      text: "Smt",
    },
    {
      value: "Dr.",
      text: "Dr.",
    },
  ];

  const SubCenterTypeData = {
    dc: [
      {
        value: "cep",
        text: "CEP",
      },
      {
        value: "walk_in",
        text: "Walk In",
      },
      {
        value: "qa",
        text: "QA",
      },
      {
        value: "sis",
        text: "SIS",
      },
      {
        value: "coco",
        text: "COCO",
      },
      {
        text: "DC-Credit",
        value: "direct_credit",
      },
      {
        text: "DC-Cash",
        value: "direct_cash",
      },
      {
        text: "HLM-SIS",
        value: "hlm_sis",
      },
      {
        text: "HLM-Credit",
        value: "hlm_credit",
      },
      {
        text: "Credit",
        value: "credit",
      },
      {
        text: "HLM Client Cash",
        value: "hlm_client_cash",
      },
      {
        text: "HLM Client Credit",
        value: "hlm_client_credit",
      },
      {
        text: "Rolling Advance",
        value: "rolling_advance",
      },
      {
        text: "International",
        value: "international",
      },
      {
        text: "DC Under CC",
        value: "dc_under_cc",
      },
    ],
    cc: [
      {
        value: "phlebo",
        text: "Phlebo",
      },
      {
        value: "ccp",
        text: "CCP",
      },
      {
        value: "cc",
        text: "CC",
      },
      {
        value: "olc",
        text: "OLC",
      },
      {
        text: "Rolling Advance",
        value: "rolling_advance",
      },
    ],
    ge: [
      {
        value: "cash",
        text: "Cash",
      },
      {
        value: "credit",
        text: "Credit",
      },
      {
        text: "Rolling Advance",
        value: "rolling_advance",
      },
      {
        text: "GE Walkin",
        value: "ge_walkin",
      },
      {
        text: "SIS",
        value: "sis",
      },
    ],
    res: [
      {
        value: "cash",
        text: "Cash",
      },
      {
        value: "credit",
        text: "Credit",
      },
      {
        text: "SIS",
        value: "sis",
      },
    ],
  };

  const fileSelectedHandler = (e: React.ChangeEvent<{ files: any }>) => {
    setFile(e.target.files[0]);
  };

  //Document Upload
  // const fileSelectedHandler1 = (e: React.ChangeEvent<{ files: any }>) => {
  //   setFileUpload(e.target.files);
  //   setFileType(e.target.files[0].name.split(".")[1]);
  //   setFileToShow(URL.createObjectURL(e.target.files[0]));
  // };

  const fileSelectedHandler1 = (
    index: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const selectedFile = selectedFiles[0];
      const allowedTypes = ["application/pdf", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(selectedFile.type)) {
        toBase64(selectedFile).then((data: any) => {
          docUploadAction(index, "document", data);
          // const fileURL = URL.createObjectURL(selectedFile);
          // setFileToShow(fileURL);
        });
      } else {
        alert("Please select a valid PDF or JPG/JPEG file.");
      }
    }
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const autoFillBank = () => {
    if (ifscData["BANK"]) {
      setRclBank(ifscData["BANK"] || "");
      setRclBranch(ifscData["BRANCH"] || "");
      setRclBranchAddr(ifscData["ADDRESS"] || "");
    }
  };

  useEffect(() => {
    if (rclIfsc?.length === 11) {
      getIfscData(`ifsc_code=${rclIfsc}`);
    }
  }, [rclIfsc]);

  useEffect(() => {
    if (centreCode && editButton === false) {
      setCentreCode(centreCode.centre_code);
    }
  }, [centreCode]);

  useEffect(() => {
    getCenterInfo("?show_all=true");
    getPinCode();
    getBankName();
    getSalesExecutive();
    getSalesManager();
    getIdentityProof();
    getpaymentMoodList();
    // getRateListFilter();
    getTagProcessingLab();
    getBusinessLocation();
    resetCCToInventoryRes();
    // getCCConsumptionItems();
    getMapMyIndiaAccessToken();
    getMrrsales();
  }, []);

  useEffect(() => {
    const users =
      get_mrr_sales &&
      get_mrr_sales?.results?.find(
        (data: any) =>
          data?.id === territoryId || data?.territory?.name === territoryName
      );

    setRoleL0(users?.L0);
    setRoleL1(users?.L1);
    setRoleL2(users?.L2);
    setRoleL3(users?.L3);
    setRoleL4(users?.L4);
  }, [territoryId, territoryName]);

  useEffect(() => {
    if (centerType !== "") {
      getCentreCode(centerType);
    }
    if (centerType === "collection_center") {
      setCategoryName("prepaid");
      if (!editButton) {
        smsType.isbillinglock = true;
      }
    }
    if (centerType === "GE_Home_Collection") {
      smsType.is_shown_to_pha = true;
    }
    if (centerType === "direct_client" && !editButton) {
      setAutosettle("auto_settlement");
    }
  }, [centerType]);
  useEffect(() => {
    if (centerType !== "" && !editButton && subCenterType === "olc") {
      getCentreCode(centerType, subCenterType);
    }
  }, [centerType, subCenterType]);
  const handleSmsControl = (event: any) => {
    setSmsType({ ...smsType, [event.target.name]: event.target.checked });
  };

  const handleUploadCentre = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("centre_file", fileUpload);
    await uploadCentres(formData);
    getCenterInfo("?show_all=true");
  };
  const handleSubmitCentre = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (!editButton) {
      formData.append("name", centerName);
    }

    formData.append("module_type", moduleType);
    formData.append("support_mobile", support_mobile);

    formData.append("support_email", support_email);
    formData.append("support_website", support_Website);
    formData.append("display_name", centerName);
    formData.append("center_type", centerType);
    formData.append("territory", territoryId);

    if (!editButton) {
      formData.append("center_code", centerCode);
    }
    // formData.append("center_code", centerCode);
    formData.append("contract_start_date", contractDate);
    formData.append("contract_end_date", expiryDate);
    formData.append("mobile", mobileNumber);
    if (altNumber) {
      formData.append("alternative_phone", altNumber);
    }

    formData.append("email", email);
    if (centerType === "collection_center") {
      formData.append("website", website);
    }
    formData.append("identity_proof", identityProof);
    formData.append("identity_proof_file", file);
    // formData.append("documents", JSON.stringify(docUpload));
    if (sales_Executive.length !== 0) {
      formData.append("multiple_users", String(sales_Executive));
    }
    // if (manager !== null) {
    //   formData.append("sales_manager", manager);
    // }
    // if (sales_Manager !== null) {
    //   formData.append("sales_manager", sales_Manager);
    // }
    formData.append("address", centerAddress);

    formData.append("city", cityId);
    if (securityAmount) {
      formData.append("security_amount", securityAmount);
    }

    formData.append("is_active", isActive);
    formData.append("barcode_type", barCodeType);
    if (busyId) {
      formData.append("busyID", busyId);
    }

    formData.append("type", categoryName);
    formData.append("owner_salutation", designation);
    formData.append("owner_name", ownerName);
    formData.append("transfer_type", sampleTransfer_type);

    if (centerHeadalterNateNumber) {
      formData.append("owner_alt_phone", centerHeadalterNateNumber);
    }
    if (centerHeadalContactNumber) {
      formData.append("owner_phone", centerHeadalContactNumber);
    }
    formData.append("owner_nationality", nationality);
    if (adharNumber) {
      formData.append("aadhar_no", adharNumber);
    }
    if (panNumber) {
      formData.append("pan", panNumber);
    }

    if (bankAccountHolder) {
      formData.append("account_holder_name", bankAccountHolder);
    }
    if (ifscCode) {
      formData.append("bank_ifsc", ifscCode);
    }
    formData.append("packages_rights", packageRights);

    if (gstinNumber) {
      formData.append("gstin", gstinNumber);
    }
    if (accountNumber) {
      formData.append("bank_account_no", accountNumber);
    }
    if (bank) {
      formData.append("bank", bank);
    }
    formData.append("is_billing_lock", JSON.stringify(smsType.isbillinglock));
    if (minAmountForNotification) {
      formData.append("min_amnt_for_notification", minAmountForNotification);
    }
    formData.append("min_amnt_for_billing_lock", String(minAmountForBill));
    if (serviceRateCategory !== "") {
      formData.append("multiple_ratelist", String(serviceRateCategory));
    }
    if (invoiceCycle) {
      formData.append("invoice_cycle", invoiceCycle);
    }
    if (reportEmailSentTo) {
      formData.append("report_email_sent_to", reportEmailSentTo);
    }
    if (reportSmsSentTo) {
      formData.append("report_sms_sent_to", reportSmsSentTo);
    }
    if (reportWhatsappSentTo) {
      formData.append("report_whatsapp_sent_to", reportWhatsappSentTo);
    }
    if (nameOnInvoice) {
      formData.append("name_on_invoice", nameOnInvoice);
    }
    if (tanNumber) {
      formData.append("tan_number", tanNumber);
    }
    if (paymentTermsInDays) {
      formData.append("payment_terms_in_days", paymentTermsInDays);
    }
    if (virtualNumber) {
      formData.append("virtual_number", virtualNumber);
    }
    formData.append("is_mail", JSON.stringify(smsType.mail));
    formData.append("is_sms", JSON.stringify(smsType.sms));
    formData.append("is_screen", JSON.stringify(smsType.screen));
    formData.append("shown_to_all", JSON.stringify(smsType.shownalltrue));
    if (centerType !== "collection_center" && autosettle) {
      formData.append("patient_type", autosettle);
    }
    formData.append("is_account_active", accountActive);
    formData.append("is_special_package", String(isSpecialPackage));
    formData.append("tds_deduction", String(tdsDeduction));
    formData.append("tds_refund", String(tdsRefund));
    formData.append("is_nabl_logo", String(isNabl));
    formData.append("is_inventory_order", String(isInventroy));
    formData.append("is_inventory_closing", String(isPhysicalClosing));
    formData.append("is_website", String(isWebsite));

    // if(orgtype){
    //   formData.append("org_type", orgtype);
    // }

    formData.append(
      "is_booking_payment_sms",
      JSON.stringify(smsType.is_booking_payment_sms)
    );
    formData.append("is_booking_sms", JSON.stringify(smsType.is_booking_sms));
    if (centerType === "GE_Home_Collection") {
      formData.append(
        "is_shown_to_pha",
        JSON.stringify(smsType.is_shown_to_pha)
      );
    }

    formData.append(
      "is_shown_to_sales",
      JSON.stringify(smsType.is_shown_to_sales)
    );
    formData.append("is_auto_verify", JSON.stringify(smsType.is_auto_verify));
    formData.append("is_price_hide", JSON.stringify(smsType.is_price_hide));
    formData.append("paymentmode", paymentMode);
    if (
      (centerType === "direct_client" ||
        centerType === "GE Lab" ||
        centerType === "research") &&
      categoryName === "postpaid"
    ) {
      formData.append("rcl_bank_number", rclAccNo);
      formData.append("rcl_bank_name", rclBank);
      formData.append("rcl_bank_ifsc", rclIfsc);
      formData.append("rcl_bank_branch", rclBranch);
      formData.append("rcl_bank_address", rclBranchAddr);
    }

    formData.append("country", "1");
    if (creditLimit !== "") {
      formData.append("credit_limit", creditLimit);
    }
    if (center_latitude !== "") {
      formData.append("latitude", center_latitude);
    }
    if (tds !== "") {
      formData.append("tds_percentage", tds);
    }
    if (center_longitude !== "") {
      formData.append("logitude", center_longitude);
    }
    formData.append("sample_register_to", sampleRegisterTo);
    if (subCenterType) {
      formData.append("subtype", subCenterType);
    }
    if (images.length > 0) {
      [...images].map((val: any, index: any) => {
        formData.append(`image[${index}]`, val);
      });
    }
    if (centerType === "direct_client" && center) {
      formData.append("cc_center", center);
      formData.append("is_service_charge", serviceCharge);
    }
    if (businessLocation.id) {
      formData.append("business_location", String(businessLocation.id));
    }

    if (docUploadDetails.length > 0) {
      let payloadUploadDetails: any = [];
      docUploadDetails.map((list: any) => {
        payloadUploadDetails.push({
          document_type: list.docType,
          document: `data:image/${list.docExt};base64,${list.base64}`,
        });
      });

      formData.append("documents", JSON.stringify(payloadUploadDetails));
    }

    if (editButton) {
      await updateCenter(formData, centerId);
    } else {
      await createNewCenter(formData);
    }

    getCenterInfo("?show_all=true");
    setEditButton(false);
    setCentreShow(false);
    setCentreHeadShow(false);
    setCentreBankShow(false);
    setCenterDocumnetShow(false);
    setCentreControllingShow(false);
    setOpen1(false);

    // window.location.reload()
    // history.push('/dashboard/ts/centerCreationModal');
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = centerInfo.links && centerInfo.links.next.split("?")[1];
      getCenterInfo(`?${url}`);
    } else if (newPage < page) {
      let url = centerInfo.links && centerInfo.links.previous.split("?")[1];
      getCenterInfo(`${url == undefined ? "" : "?" + url}`);
    }
    setPage(newPage);
  };

  const handleEditCentre = (data: any) => {
    setModuleType(data?.module_type);
    setBusinessLocation(data?.business_location);
    setPackageRights(data?.packages_rights || "");
    setCentreLatitude(data?.latitude || "");
    setCentreLongitude(data?.logitude || "");
    setSampleRegisterTo(data?.sample_register_to || "");
    setCityId(data?.city || "");
    setArea((data?.area?.area as String) || "");
    setCityName(data?.city_name);
    setBarCodeType(data?.barcode_type || "");
    setPaymentMode(data?.paymentmode || "");
    setBankInput(data?.bank?.name || "");
    setBank(data?.bank?.id || "");
    setCategoryName(data?.type || "");
    setIdentityProof(data?.identity_proof || "");
    setCentreShow(true);
    setCentreHeadShow(true);
    setCentreBankShow(true);
    setCenterDocumnetShow(true);
    setCentreControllingShow(true);
    setCentreName(data?.display_name || "");
    setCentreType(data?.center_type || "");
    setTerritoryName(data?.territory || "");
    setSubCentreType(data?.subtype || "");
    setCentreCode(data?.center_code || "");
    setIsActive(data?.is_active);
    setVirtualNumber(data?.virtual_number || "");
    setIsSpecialPackage(data?.is_special_package || false);
    setTdsDeduction(data?.tds_deduction || false);
    setTdsRefund(data?.tds_refund || false);
    setIsNabl(data?.is_nabl_logo || false);
    setIsInventory(data?.is_inventory_order || "");
    setIsPhysicalClosing(data?.is_inventory_closing);
    setIsWebsite(data.is_website || "");
    setWebsite(data?.website || "");
    setTds(data?.tds_percentage || "");
    setSampleTransfer_type(data?.transfer_type || "");
    setOpen1(true);
    // setorgType(data?.org_type);
    setContractDate(
      data?.contract_start_date
        ? data?.contract_start_date
        : moment().format("YYYY-MM-DD")
    );
    setExpiryDate(
      data?.contract_end_date
        ? data?.contract_end_date
        : moment().add(5, "years").format("YYYY-MM-DD")
    );
    setMobileNumber(data?.mobile || "");
    setaltNumber(data?.alternative_phone || "");
    setCentreHeadContactNumber(data?.center_head_phone || "");
    setCentreHeadAlternateNumber(data?.owner_alt_phone || "");
    setDesignation(data?.owner_salutation || "");
    setCentreHeadContactNumber(data?.owner_phone || "");
    setEmail(data?.email || "");
    // setPaitentType(data.patient_type)
    // setSales_Manager(data?.sales_manager?.id || "");
    // setSales_ManagerDef({
    //   id: data?.sales_manager?.id,
    //   username: data?.sales_manager?.name,
    // });
    setManagerName(data?.sales_manager);
    setManager(data?.sales_manager?.id);
    // salesManager.results &&
    //   salesManager.results.find((code: any) => {
    //     if (code.uuid === data.user) {
    //       setSales_ManagerDef(code);
    //     }
    //   });
    setOwnerName(data?.owner_name || "");
    setSales_Executive(data?.users || []);
    setNameOnInvoice(data?.name_on_invoice || "");
    setTanNumber(data?.tan_number || "");
    setPaymentTermsInDays(data?.payment_terms_in_days || "");

    salesExecutive &&
      salesExecutive.results &&
      salesExecutive.results.map((code: any) => {
        data?.users?.map((dat: any) => {
          if (code.uuid === dat) {
            sales_ExecutiveDef.push(code);
          }
        });
      });

    setCentreAddress(data?.address || "");
    setSecurityAmount(data?.security_amount || "");
    setServiceRateCategoryDef(data?.ratelist[0] || "");
    setServiceRateCategory([data?.ratelist[0]?.id] || "");
    setBusyId(
      data?.busyID == null || data?.busyID == "null" ? "" : data?.busyID
    );
    setbankAccountHolder(data?.account_holder_name || "");
    setCreditLimit(data?.credit_limit || "");
    setGstinNumber(data?.gstin || "");
    setPanNumber(data?.pan || "");
    setInvoiceCycle(data?.invoice_cycle || "");
    setIfscCode(data?.bank_ifsc || "");
    setAccountNumber(data?.bank_account_no || "");
    setMinNotication(data?.min_amnt_for_notification || "");
    setMinAmountForBill(Number(data?.min_amnt_for_billing_lock) || 0);
    setAdharNumber(data?.aadhar_no || "");
    setReportWhatsappSentTo(data?.report_whatsapp_sent_to || "");
    setReportEmailSentTo(data?.report_email_sent_to || "");
    setReportSmsSentTo(data?.report_sms_sent_to || "");
    setAutosettle(data?.patient_type);
    setAccountActive(data?.is_account_active);
    setCentre(data?.cc_center?.id || "");
    setRclAccNo(data?.rcl_bank_number || "");
    setRclBank(data?.rcl_bank_name || "");
    setRclIfsc(data?.rcl_bank_ifsc || "");
    setRclBranch(data?.rcl_bank_branch || "");
    setRclBranchAddr(data?.rcl_bank_address || "");
    setCentreInp(
      (data?.cc_center?.display_name
        ? data?.cc_center?.display_name
        : data?.cc_center?.name) || ""
    );
    setServiceCharge(String(data?.is_service_charge) || "");
    setSupportEmail(data?.support_email);
    setSupportMobile(data?.support_mobile);
    setSupportWebsite(data?.support_website);
    // setDocumentType(data?.document_type || "");
    setCenterId(data?.id || "");
    const smType = {
      mail: data.is_mail,
      sms: data.is_sms,
      screen: data.is_screen,
      isbillinglock: data.is_billing_lock,
      isapproved: data.is_active,
      is_booking_payment_sms: data.is_booking_payment_sms,
      is_booking_sms: data.is_booking_sms,
      is_report_sms: data.is_report_sms,
      is_report_whatsapp: data.is_report_whatsapp,
      is_report_email: data.is_report_email,
      is_shown_to_pha: data.is_shown_to_pha,
      is_shown_to_sales: data.is_shown_to_sales,
      is_auto_verify: data.is_auto_verify,
      is_price_hide: data.is_price_hide,
      shownalltrue: data.shown_to_all,
    };
    let storeDocs: any[] = [];
    data?.center_documents?.length > 0 &&
      data?.center_documents?.map((list: any) => {
        storeDocs.push({
          document_type: list?.doc_type,
          document: list?.file,
        });
      });
    setDocUpload(storeDocs);
    setSmsType(smType);
    setDisableEdit(true);
    setEditButton(true);
  };
  useEffect(() => {
    getRateListFilter(tag_ProccessingLab, "");
  }, [tag_ProccessingLab]);
  useEffect(() => {
    if (categoryName !== "") {
      {
        paymentModeList.map((val: any) => {
          if (categoryName === "postpaid" && val.name === "Credit") {
            setPaymentMode(val.id);
          } else if (categoryName === "prepaid" && val.name === "Cash") {
            setPaymentMode(val.id);
          }
        });
      }
    }
    if (categoryName === "postpaid") {
      setMinAmountForBill(0);
    }
  }, [categoryName]);

  const disableCenter = async (data: any) => {
    // const body: any = {
    //   is_active: !val.is_active,
    // };
    // await updateCenter(body, val.id);
    // getCenterInfo("?show_all=true");
    openModalFun(data);
  };

  const disableCenter2factOtp = async (data: any) => {
    const body: any = {
      user_id: data?.panel_user_id,
      "otp-required": !data?.is_otp_required,
    };
    await disableUser2fOtp(body);
    history.push(`/dashboard/ts/centerCreationModal`);
  };

  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [centerActive, setCenterActive] = useState<boolean>(false);
  const [dataCenterId, setDataCenterId] = useState<number>(0);
  const [disposition, setDisposition] = useState<string>("");
  const [dispositionOther, setDispositionOther] = useState<string>("");

  const openModalFun = (val: any) => {
    setDisposition("");
    setDataCenterId(val?.id);
    setCenterActive(val?.is_active);
    setModalStatus(true);
  };

  const closeModalFun = () => {
    setDisposition("");
    setDataCenterId(0);
    setModalStatus(false);
  };

  const submitDisposition = async () => {
    const body: any = {
      is_active: !centerActive,
      comment: disposition === "Other" ? dispositionOther : disposition,
    };
    await updateCenter(body, dataCenterId);
    closeModalFun();
    setDispositionOther("");
    getCenterInfo("?show_all=true");
  };

  const handleClick = (data: any) => {
    setopenModal(true);
    setCenterdata(data);
  };

  // const [itemData, setItemData] = useState<any>([]);

  const openPreviewInNewTab = (url: string) => () => {
    window.open(url, "_blank");
  };

  // useEffect(() => {
  //   let storeData: any = [];
  //   if (get_cc_consumption && get_cc_consumption?.length > 0) {
  //     get_cc_consumption?.map((list: any) => {
  //       if (
  //         list?.code != "BRA00005" &&
  //         list?.code != "C&D00906" &&
  //         list?.code != "C&D00905"
  //       ) {
  //         storeData.push({
  //           itemId: list?.id,
  //           ...list,
  //           userQty:
  //             list?.code === "PRI00001"
  //               ? 2
  //               : list?.code === "P&S00038"
  //                 ? 1
  //                 : list?.code === "P&S00035"
  //                   ? 50
  //                   : list?.code === "P&S00034"
  //                     ? 300
  //                     : list?.code === "OTH00028"
  //                       ? 50
  //                       : list?.code === "C&D00942"
  //                         ? 2
  //                         : list?.code === "C&D00941"
  //                           ? 30
  //                           : list?.code === "OTH00023"
  //                             ? 30
  //                             : list?.code === "C&D00938"
  //                               ? 1
  //                               : // : list?.code === "C&D00906"
  //                               // ? 0.2
  //                               // : list?.code === "C&D00905"
  //                               // ? 1
  //                               list?.code === "C&D00879"
  //                                 ? 5
  //                                 : list?.code === "C&D00878"
  //                                   ? 5
  //                                   : list?.code === "C&D00877"
  //                                     ? 2
  //                                     : list?.code === "C&D00876"
  //                                       ? 2
  //                                       : list?.code === "C&D00875"
  //                                         ? 50
  //                                         : list?.code === "C&D00874"
  //                                           ? 2
  //                                           : list?.code === "C&D00873"
  //                                             ? 5
  //                                             : list?.code === "C&D00872"
  //                                               ? 50
  //                                               : list?.code === "C&D00871"
  //                                                 ? 50
  //                                                 : list?.code === "C&D00870"
  //                                                   ? 50
  //                                                   : list?.code === "C&D00860"
  //                                                     ? 50
  //                                                     : list?.code === "C&D00853"
  //                                                       ? 1
  //                                                       : list?.code === "OTH00001"
  //                                                         ? 1
  //                                                         : list?.code === "OTH00012"
  //                                                           ? 1
  //                                                           : list?.code === "P&S00044"
  //                                                             ? 1
  //                                                             : list?.code === "OTH00024"
  //                                                               ? 1
  //                                                               : list?.code === "OTH00208"
  //                                                                 ? 2
  //                                                                 : list?.code === "C&D00945"
  //                                                                   ? 1
  //                                                                   : 0,
  //         });
  //       }
  //     });
  //   }
  //   setItemData(storeData);
  // }, [get_cc_consumption]);

  // timer

  const [disableConsReq, setDisableConsReq] = useState<boolean>(true);

  useEffect(() => {
    if (disableConsReq) {
      setTimeout(() => {
        setDisableConsReq(false);
      }, 10000);
    }
  }, [disableConsReq === true]);

  // Consumables Requistion Modal

  // const [submitFormStatus, setSubmitFormStatus] = useState<boolean>(false);
  // const [param, setParam] = useState<number>(0);

  // const consuReqModal = (paramData: number) => {
  //   setParam(paramData);
  //   paramData ? setSubmitFormStatus(true) : setSubmitFormStatus(false);
  // };

  // const modalCloseWithNo = () => {
  //   setSubmitFormStatus(false);
  //   setParam(0);
  // };

  // const modalCloseWithYes = () => {
  //   setDisableConsReq(true);
  //   setSubmitFormStatus(false);
  //   postCCToIv(param);
  //   setParam(0);
  // };

  const addItems1lakh = [
    {
      item: process.env.REACT_APP_ENV === "production" ? 3516 : "4444",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2842 : "4443",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 1996 : "4437",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2012 : "4438",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2094 : "4442",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 1989 : "4441",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2138 : "4440",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2122 : "4439",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2135 : "4438",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2134 : "4437",
      store_item: null,
      qty: 500,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 1999 : "44436",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2117 : "4435",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2015 : "44434",
      store_item: null,
      qty: 5,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2013 : "4433",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2101 : "4432",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2014 : "4431",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2107 : "4430",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 3654 : "4429",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2021 : "4428",
      store_item: null,
      qty: 5,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2104 : "4427",
      store_item: null,
      qty: 30,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2018 : "4426",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2019 : "4425",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2017 : "4424",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2020 : "4423",
      store_item: null,
      qty: 5,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2016 : "4422",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2111 : "4421",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 9432 : "4420",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 5027 : "4419",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 9504 : "4418",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 8693 : "4417",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 3520 : "4416",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 9487 : "4415",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 3523 : "4414",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 3524 : "4413",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 6325 : "4412",
      store_item: null,
      qty: 2000,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
  ];

  const addItems50K = [
    {
      item: process.env.REACT_APP_ENV === "production" ? 3516 : "4444",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2842 : "4439",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 1996 : "4442",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2012 : "4441",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2094 : "4440",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 1989 : "4439",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2138 : "4438",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2122 : "4437",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2135 : "4436",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2134 : "4435",
      store_item: null,
      qty: 500,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 1999 : "4434",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2117 : "4433",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2015 : "4432",
      store_item: null,
      qty: 5,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2013 : "4431",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2101 : "4430",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2014 : "4429",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2107 : "4428",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 3654 : "4442",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2021 : "4427",
      store_item: null,
      qty: 5,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },

    {
      item: process.env.REACT_APP_ENV === "production" ? 2104 : "4426",
      store_item: null,
      qty: 30,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2018 : "4425",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2019 : "4424",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2017 : "4423",
      store_item: null,
      qty: 100,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2020 : "4422",
      store_item: null,
      qty: 5,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2016 : "4421",
      store_item: null,
      qty: 2,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
    {
      item: process.env.REACT_APP_ENV === "production" ? 2111 : "4420",
      store_item: null,
      qty: 1,
      date: moment().format("YYYY-MM-DD"),
      is_exceed: false,
      item_wise_remark: "",
    },
  ];

  // const postCCToIv = async (centreCcToInv: any) => {
  //   let storeData: any = [];
  //   // itemData?.length > 0 &&
  //   //   itemData?.map((list: any) => {
  //   //     storeData.push({
  //   //       item: list?.itemId,
  //   //       store_item: null,
  //   //       qty: list?.userQty,
  //   //       date: moment().format("YYYY-MM-DD"),
  //   //       is_exceed: false,
  //   //       item_wise_remark: "",
  //   //     });
  //   //   });
  //   if (moduleType === "1L") {
  //     addItems1lakh.map((item: any) => storeData.push(item))
  //   } else {
  //     addItems50K.map((item: any) => storeData.push(item))
  //   }
  //   let data: any = {
  //     cc_code: centreCcToInv,
  //     to_location: 1,
  //     date: moment().format("YYYY-MM-DD"),
  //     remark: "",
  //     si_pi: "SI",
  //     items: storeData,
  //     cc_order_type: "partner",
  //   };
  //   ccToInv(data);
  //   handleReset();
  //   setItemData([]);
  //   setParam(0);
  // };

  useEffect(() => {
    if (cc_to_inv && cc_to_inv?.status) {
      resetCCToInventoryRes();
      history.push("/dashboard/ts/centerCreationModal");
    }
  }, [cc_to_inv]);

  const filterCenter = async () => {
    setDisableFilterBtn(true);
    const body: any = {
      mobile: mobileNumberFilter,
      email: emailFilter,
      center_type: centerTypeFilter,
      city: cityIdFilter,
      center_code: centerCodeFilter,
    };

    const url = genrateCenterCreationFilter(body).substring(2);
    getCenterInfo(`?${url}&show_all=true`);
  };

  const handleReset = () => {
    history.push(`/dashboard/ts/centerCreationModal`);
  };

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);
  // mapmy ind
  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);
  useEffect(() => {
    if (mapAddressObject?.addressTokens?.city) {
      getCentreCities(mapAddressObject?.addressTokens?.city);
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (centre_cities && centre_cities.length > 0) {
      if (String(centre_cities[0].name)) {
        setCityId(centre_cities[0]?.id);
        setCityName(centre_cities[0].name);
      }
    }
  }, [centre_cities]);
  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName: any = "jsonp_callback_" + "data" + id;
        (window as any)[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };

  const documentTypeCounts = {
    pan: 0,
    aadhar: 0,
    agreement: 0,
    rent_agreement: 0,
    other: 0,
  };

  const [docUpload, setDocUpload] = useState<any>([
    {
      document_type: documentType,
      document: [],
    },
  ]);
  const docUploadAction = (index: any, key: any, value: any) => {
    const docUpload1: any = [...docUpload];
    if (key) {
      docUpload1[index][key] = value;
    } else if (index === -1) {
      docUpload1.push({
        document_type: documentType,
        document: [],
      });
    } else {
      docUpload1.splice(index, 1);
    }
    setDocUpload(docUpload1);
  };

  const [docUploadDetails, setDocUploadDetails] = useState<any>([
    {
      seq: 1,
      docType: "",
      doc: "",
      docExt: "",
      docToShow: "",
      isView: false,
      base64: "",
    },
  ]);
  const [docTypeArray, setDocTypeArray] = useState<Array<any>>([]);

  const addRemove = (actionType: number, aR: string) => {
    let tempData = [...docUploadDetails],
      tempDocTypeArr: any = [];
    if (aR === "add") {
      tempData.push({
        seq: docUploadDetails.length + 1,
        docType: "",
        doc: "",
        docExt: "",
        docToShow: "",
        isView: false,
        base64: "",
      });
    } else {
      tempData.splice(actionType, 1);
    }

    tempDocTypeArr = tempData
      .map((list: any) => list.docType)
      .filter((list: any) => list);
    setDocTypeArray(tempDocTypeArr);
    setDocUploadDetails(tempData);
  };

  const MAX_FILE_SIZE_MB = 10;
  const updateDocUploadDetails = (
    index: number,
    key: string,
    value: any,
    item: any
  ) => {
    let tempData: any = [...docUploadDetails];
    let newItem: any = item;
    let tempDocTypeArr: any = [];

    switch (key) {
      case "update_document":
        const file = value[0];
        if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
          alert(
            `File size exceeds the maximum limit of ${MAX_FILE_SIZE_MB}MB. Please choose a smaller file.`
          );
          return;
        }
        newItem.doc = value;
        const match = file.name.match(/\.([^.]+)$/);
        const fileExtension = match ? match[1] : "";

        newItem.docExt = fileExtension;
        newItem.docToShow = URL.createObjectURL(file);

        const reader = new FileReader();
        reader.onload = function (event: any) {
          const base64String = event.target.result.split(",")[1];
          newItem.base64 = base64String;
        };
        reader.readAsDataURL(value[0]);
        break;
      case "udpate_doc_type":
        newItem.docType = value;
        break;
    }

    tempData[index] = newItem;
    tempDocTypeArr = tempData
      .map((list: any) => list.docType)
      .filter((list: any) => list);
    setDocTypeArray(tempDocTypeArr);
    setDocUploadDetails(tempData);
  };
  const [open, setOpen] = useState<boolean>(false);
  const [fileToShow, setFileToShow] = useState("");
  const [fileType, setFileType] = useState<string>("");

  const previewIt = (data: any) => {
    setOpen(true);
    setFileToShow(data.docToShow);
    setFileType(data.docExt);
  };

  const handleKeyDown = (event: any) => {
    if (
      event.keyCode === 32 &&
      (website[website.length - 1] === " " || website.trim() === "")
    ) {
      event.preventDefault();
    }
  };

  const moduleTypeArr = ["10K", "25K", "50K", "1L"];

  const [moduleType, setModuleType] = useState<any>("50K");

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      {open && (
        <ImagePreviewModal
          open={open}
          setOpen={setOpen}
          fileToShow={fileToShow}
          setFileToShow={setFileToShow}
          fileType={fileType}
          setFileType={setFileType}
        />
      )}
      {/* <Dialog
          open={submitFormStatus}
          // onClose={modalCloseWithNo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContent
              style={{ width: "250px", flexDirection: "column" }}
              className={classes.centerIt}
            >
              <DialogContentText
                id="alert-dialog-description"
                style={{ textAlign: "center", color: "#000" }}
              >
                Are you sure to send requisition to store? 🧐
              </DialogContentText>
  
              <DialogActions>
                <Button variant="contained" onClick={modalCloseWithNo}>
                  No
                </Button>
                <Button variant="contained" onClick={modalCloseWithYes}>
                  Yes
                </Button>
              </DialogActions>
            </DialogContent>
          </DialogContent>
        </Dialog> */}

      <Dialog
        open={modalStatus}
        onClose={closeModalFun}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        {/* <DialogTitle id="form-dialog-title">Disposition Form</DialogTitle> */}
        <DialogContent>
          <Grid
            container
            spacing={2}
            style={
              {
                // margin: "0px 10px 10px 10px",
                // minWidth: "500px",
                // width: "70%",
              }
            }
          >
            {/* <Grid item xs={12} md={12} style={{ height: "435px" }}></Grid> */}
            <Grid item xs={12} md={6}>
              <p>Disposition</p>
              {centerActive ? (
                <TextField
                  // className="input"
                  className="inputField"
                  value={disposition}
                  onChange={(e: any) => setDisposition(e.target.value)}
                  variant="outlined"
                  fullWidth
                  required
                  select
                  style={{ width: "250px" }}
                >
                  <MenuItem value="No Business within 2 months">
                    No Business within 2 months
                  </MenuItem>
                  <MenuItem value="MOU cancelled by Owner">
                    MOU cancelled by Owner
                  </MenuItem>
                  <MenuItem value="Center closed">Center closed</MenuItem>
                  <MenuItem value="Incorrect documents">
                    Incorrect documents
                  </MenuItem>
                  <MenuItem value="Sales person request">
                    Sales person request
                  </MenuItem>
                  <MenuItem value="Payment is not received">
                    Payment is not received
                  </MenuItem>
                  <MenuItem value="Misuse of center code">
                    Misuse of center code
                  </MenuItem>
                  <MenuItem value="Incorrect given address">
                    Incorrect given address
                  </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              ) : (
                <TextField
                  className="input"
                  value={disposition}
                  onChange={(e: any) => setDisposition(e.target.value)}
                  variant="outlined"
                  required
                  style={{ width: "250px" }}
                />
              )}
              {disposition === "Other" ? (
                <>
                  <Grid item xs={12} md={6}>
                    <textarea
                      aria-label="minimum height"
                      minLength={4}
                      placeholder="Add Other Disposition.."
                      value={dispositionOther}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                        setDispositionOther(e.target.value as string)
                      }
                      style={{ width: "460%", marginTop: "10px" }}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={submitDisposition} color="primary">
            Save
          </Button>
          <Button onClick={closeModalFun} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* <form onSubmit={handleSubmitCentre}> */}
      <Paper elevation={5} className={classes.paper}>
        <Grid container spacing={3}>
          {userDetails?.profile?.executive_type === "Auditor" ? (
            ""
          ) : (
            <Grid
              container
              spacing={3}
              className={classes.centerIt}
              style={{
                margin: "0rem auto",
              }}
            >
              <Grid item xs={12} sm={9} md={9}>
                <h5>Search Option</h5>
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                {/* <Button
                    variant="contained"
                    size="small"
                    onClick={() => setCentreShow(centerShow ? false : true)}
                    // disabled={centerShow}
                  >
                    {centerShow ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    {editButton
                      ? centerShow
                        ? "Close"
                        : "Update Centre"
                      : centerShow
                      ? "Close"
                      : "Create New Center"}
                  </Button> */}
                <Button
                  color="success"
                  variant="contained"
                  fullWidth
                  onClick={handleOpen}
                >
                  Create New Center
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Modal
          open={open1}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1}>
            <HighlightOffIcon
              onClick={handleClose}
              style={{
                position: "relative",
                left: "98%",
                height: "40px",
                width: "30px",
              }}
            />
            <form onSubmit={handleSubmitCentre}>
              <Paper elevation={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      label="Centre Code"
                      value={centerType !== "" ? centerCode : ""}
                      required
                      disabled
                      variant="outlined"
                      style={{
                        margin: "0",
                        width: "100%",
                        backgroundColor: "#f0f8ff",
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="center_name"
                      type="text"
                      label="Centre Name"
                      value={centerName}
                      required
                      inputProps={{ maxLength: 50 }}
                      variant="outlined"
                      onChange={(e) => setCentreName(e.target.value)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="centre_type"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setCentreType(obj?.value);
                        }
                      }}
                      defaultValue={
                        CenterTypeData &&
                        CenterTypeData.find((code: any) => {
                          return code?.value === centerType;
                        })
                      }
                      options={CenterTypeData}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => `${option?.text}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Centre Type"
                          variant="outlined"
                          fullWidth
                          className="input"
                          required
                          style={{ backgroundColor: "#f0f8ff" }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {centerType === "direct_client" ? (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                          id="Sub centre_type"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              let obj = JSON.parse(
                                JSON.stringify(newValue, null, " ")
                              );
                              setSubCentreType(obj.value);
                            }
                          }}
                          defaultValue={
                            SubCenterTypeData.dc &&
                            SubCenterTypeData.dc.find((code: any) => {
                              return code.value === subCenterType;
                            })
                          }
                          options={SubCenterTypeData.dc}
                          freeSolo
                          blurOnSelect
                          getOptionLabel={(option: any) => `${option.text}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Partner Type"
                              variant="outlined"
                              style={{
                                width: "100%",
                                backgroundColor: "#f0f8ff",
                              }}
                              className="input"
                              InputLabelProps={{
                                style: { color: "#082687" },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                          id="center"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              let obj = JSON.parse(
                                JSON.stringify(newValue, null, " ")
                              );
                              setCentre(obj.id);
                            }
                          }}
                          freeSolo
                          blurOnSelect
                          options={centers?.results || []}
                          disableCloseOnSelect
                          getOptionLabel={(option: any) =>
                            (option?.display_name
                              ? option?.display_name
                              : option?.name) +
                            (option?.center_code
                              ? "(" + option?.center_code + ")"
                              : "")
                          }
                          defaultValue={{ name: centerInp }}
                          onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                              getCenters(
                                `?optimizev2=true&name_or_code=${newInputValue}&center_type=collection_center`
                              );
                            }, 1000);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="input"
                              {...params}
                              label="Select DC under CC"
                              variant="outlined"
                              fullWidth
                              required
                              style={{ backgroundColor: "#f0f8ff" }}
                              InputLabelProps={{
                                style: { color: "#082687" },
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  ) : centerType === "collection_center" ? (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                          id="Sub centre_type"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              let obj = JSON.parse(
                                JSON.stringify(newValue, null, " ")
                              );
                              setSubCentreType(obj.value);
                            }
                          }}
                          defaultValue={
                            SubCenterTypeData.cc &&
                            SubCenterTypeData.cc.find((code: any) => {
                              return code.value === subCenterType;
                            })
                          }
                          options={SubCenterTypeData.cc}
                          freeSolo
                          blurOnSelect
                          getOptionLabel={(option: any) => `${option.text}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Partner Type"
                              variant="outlined"
                              style={{
                                width: "100%",
                                backgroundColor: "#f0f8ff",
                              }}
                              className="input"
                              InputLabelProps={{
                                style: { color: "#082687" },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                          // style={{ width: "156%", display: "inline-block", marginRight: "75px" }}
                          id="Module Type"
                          // value={moduleType}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setModuleType(newValue);
                            }
                          }}
                          defaultValue={moduleType ? moduleType : ""}
                          options={moduleTypeArr}
                          freeSolo
                          blurOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Module Type"
                              variant="outlined"
                              style={{
                                width: "100%",
                                backgroundColor: "#f0f8ff",
                              }}
                              className="input"
                              InputLabelProps={{
                                style: { color: "#082687" },
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  ) : centerType === "GE_Home_Collection" ||
                    centerType === "GE Lab" ||
                    centerType === "GE CC" ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="Sub centre_type"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setSubCentreType(obj.value);
                          }
                        }}
                        defaultValue={
                          SubCenterTypeData.ge &&
                          SubCenterTypeData.ge.find((code: any) => {
                            return code.value === subCenterType;
                          })
                        }
                        options={SubCenterTypeData.ge}
                        freeSolo
                        blurOnSelect
                        getOptionLabel={(option: any) => `${option.text}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Partner Type"
                            variant="outlined"
                            style={{
                              width: "100%",
                              backgroundColor: "#f0f8ff",
                            }}
                            className="input"
                            InputLabelProps={{
                              style: { color: "#082687" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  ) : centerType === "research" ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="Sub centre_type"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setSubCentreType(obj.value);
                          }
                        }}
                        defaultValue={
                          SubCenterTypeData.res &&
                          SubCenterTypeData.res.find((code: any) => {
                            return code.value === subCenterType;
                          })
                        }
                        options={SubCenterTypeData.res}
                        freeSolo
                        blurOnSelect
                        getOptionLabel={(option: any) => `${option.text}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Partner Type"
                            variant="outlined"
                            style={{
                              width: "100%",
                              backgroundColor: "#f0f8ff",
                            }}
                            className="input"
                            InputLabelProps={{
                              style: { color: "#082687" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="securityAmount"
                      type="text"
                      value={securityAmount}
                      className="input"
                      label="Security Amount "
                      variant="outlined"
                      onChange={(e) =>
                        setSecurityAmount(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="contract_date"
                      type="date"
                      value={contractDate}
                      className="input"
                      label="Contract Date"
                      variant="outlined"
                      required
                      inputProps={{
                        min: editButton
                          ? moment(contractDate).format("YYYY-MM-DD")
                          : moment().format("YYYY-MM-DD"),
                      }}
                      // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                      onChange={(e) =>
                        setContractDate(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="expiry_date"
                      type="date"
                      disabled={contractDate === ""}
                      value={expiryDate}
                      className="input"
                      label="Expiry Date"
                      inputProps={{
                        min: moment(contractDate).format("YYYY-MM-DD"),
                      }}
                      helperText={
                        moment(expiryDate).isAfter(contractDate)
                          ? ""
                          : "Expiry Date Should Greater Than Contract Date"
                      }
                      variant="outlined"
                      onChange={(e) => setExpiryDate(e.target.value as string)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      required
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="mobile_number"
                      type="number"
                      label="Mobile Number"
                      value={mobileNumber}
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      style={{
                        margin: "0",
                        width: "100%",
                        backgroundColor: "#f0f8ff",
                      }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                      onChange={(e) =>
                        setMobileNumber(e.target.value as String)
                      }
                      required
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onBlur={() => verifyMobile("mob")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          mobileNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.mobileNumber &&
                        "Incorrect Mobile Number"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="alt_number"
                      type="number"
                      label="Alternate Number"
                      value={altNumber}
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      style={{
                        margin: "0",
                        width: "100%",
                        backgroundColor: "#f0f8ff",
                      }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                      onChange={(e) => setaltNumber(e.target.value as String)}
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onBlur={() => verifyMobile("centAlt")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          altNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.altNumber &&
                        "Incorrect Alternate Number"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="email"
                      type="email"
                      value={email}
                      className="input"
                      label="Email "
                      variant="outlined"
                      onChange={(e) => setEmail(e.target.value as string)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="identityproof"
                      className="input"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setIdentityProof(obj?.id);
                        }
                      }}
                      defaultValue={
                        identityProofData &&
                        identityProofData.find((code: any) => {
                          return code?.id === Number(identityProof);
                        })
                      }
                      options={identityProofData || []}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => `${option?.name}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Identity Proof"
                          variant="outlined"
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          className={classes.input}
                          required
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Aadhar_Number"
                      type="number"
                      value={adharNumber}
                      className="input"
                      label="Aadhaar Number "
                      placeholder={
                        adharNumber === null
                          ? ""
                          : "Enter a valid 12 Digit Aadhaar No."
                      }
                      variant="outlined"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      helperText={
                        adharNumber?.length < 12
                          ? ""
                          : "Enter a valid 12 Digit Aadhaar No."
                      }
                      onChange={(e) => setAdharNumber(e.target.value as string)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3}>
                    <input
                      className="input"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "5px 5px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                        height: "35px",
                      }}
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      onChange={fileSelectedHandler}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={3}>
                    {/* <Autocomplete
                      id="sales_manager"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setSales_Manager(obj?.panel_user_id);
                        }
                      }}
                      defaultValue={
                        sales_ManagerDef || { panel_user_id: "", username: "" }
                      }
                      options={salesManager?.results || []}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => `${option?.username}`}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getSalesManager(newInputValue);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sales Manager"
                          variant="outlined"
                          style={{ width: "100%" }}
                          className="input"
                          required
                        />
                      )}
                    /> */}
                    {/* <Autocomplete
                          id="manager"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              let obj = JSON.parse(
                                JSON.stringify(newValue, null, " ")
                              );
                              setManager(obj.id);
                              setManagerName(obj);
                            }
                          }}
                          value={managerName}
                          options={agentBYUserGroup.results || []}
                          onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                              getAgentList(newInputValue, `MEDICOS`);
                            }, 1000);
                          }}
                          blurOnSelect
                          aria-required
                          getOptionLabel={(option: any) =>
                            `${option?.name ? option?.name : option?.user?.username
                            }`
                          }
                          renderInput={(params) => (
                            <TextField
                              className="input"
                              {...params}
                              label="Channel/salesManager"
                              variant="outlined"
                              fullWidth
                              required
                              style={{ backgroundColor: "#f0f8ff" }}
                              InputLabelProps={{
                                style: { color: "#082687" },
                              }}
                            />
                          )}
                        /> */}
                    <Autocomplete
                      id="territory"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setTerritoryName(
                            obj && obj.territory && obj.territory.name
                          );
                          setTerritoryId(
                            obj && obj.territory && obj.territory.id
                          );
                        }
                      }}
                      options={get_mrr_sales?.results || []}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getMrrsales(`?name=${newInputValue}`);
                        }, 1000);
                      }}
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) =>
                        `${option?.territory?.name}`
                      }
                      defaultValue={{ territory: { name: territoryName } }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Territory"
                          variant="outlined"
                          fullWidth
                          required
                          style={{ backgroundColor: "#f0f8ff" }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {territoryName && (
                    // <Grid container spacing={3}>
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          className="input"
                          name="center_name"
                          type="text"
                          label="L0"
                          disabled
                          // value={get_mrr_sales?.results[0]?.L0 || "-"}
                          value={roleL0}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined"
                          onChange={(e) => setRoleL0(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          className="input"
                          name="center_name"
                          type="text"
                          label="L1"
                          // value={get_mrr_sales?.results[0]?.L1 || "-"}
                          value={roleL1}
                          variant="outlined"
                          disabled
                          onChange={(e) => setRoleL1(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          className="input"
                          name="center_name"
                          type="text"
                          label="L2"
                          disabled
                          // value={get_mrr_sales?.results[0]?.L2 || "-"}
                          value={roleL2}
                          variant="outlined"
                          onChange={(e) => setRoleL2(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          className="input"
                          name="center_name"
                          type="text"
                          label="L3"
                          disabled
                          // value={get_mrr_sales?.results[0]?.L3 || "-"}
                          value={roleL3}
                          variant="outlined"
                          onChange={(e) => setRoleL3(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          className="input"
                          name="center_name"
                          type="text"
                          label="L4"
                          disabled
                          // value={get_mrr_sales?.results[0]?.L4 || "-"}
                          value={roleL4}
                          variant="outlined"
                          onChange={(e) => setRoleL4(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  {/* <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                          id="sales_executive"
                          onChange={(event, newValue) => {
                            let sourceIds: any = [];
                            if (newValue) {
                              let obj = JSON.parse(
                                JSON.stringify(newValue, null, " ")
                              );
                              sourceIds = obj.map((item: any) => item?.uuid);
                            }
                            setSales_Executive(sourceIds);
                          }}
                          defaultValue={sales_ExecutiveDef}
                          multiple
                          freeSolo
                          blurOnSelect
                          limitTags={2}
                          options={salesExecutive?.results || []}
                          getOptionDisabled={(option: any) => {
                            return sales_Executive.includes(option?.id);
                          }}
                          disableClearable
                          disableCloseOnSelect
                          getOptionLabel={(option: any) => `${option?.username}`}
                          onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                              getSalesExecutive(newInputValue);
                            }, 1000);
  
                            if (!newInputValue) {
                              setSales_Executive([]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="input"
                              {...params}
                              label="Sales Executive"
                              variant="outlined"
                              style={{
                                width: "100%",
                                margin: "0",
                                padding: "0",
                                backgroundColor: "#f0f8ff",
                              }}
                              InputLabelProps={{
                                style: { color: "#082687" },
                              }}
                            />
                          )}
                        />
                      </Grid> */}

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="centerAddress"
                      type="text"
                      value={centerAddress}
                      className="input"
                      label="Centre Address"
                      variant="outlined"
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) =>
                        setCentreAddress(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      required
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="tag_processinglab"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setTag_ProccessingLab(obj?.id);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getTagProcessingLab(`?name=${newInputValue}`);
                          if (!newInputValue) {
                            getRateListFilter("", "");
                            setTag_ProccessingLab("");
                          }
                        }, 1000);
                      }}
                      defaultValue={
                        tagProccessingLab.results &&
                        tagProccessingLab.results.find((code: any) => {
                          return code.id === tag_ProccessingLab;
                        })
                      }
                      options={tagProccessingLab.results || []}
                      freeSolo
                      disableClearable
                      blurOnSelect
                      getOptionLabel={(option: any) => `${option?.name}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Lab to filter Service Rate Category"
                          variant="outlined"
                          fullWidth
                          className="input"
                          style={{ backgroundColor: "#f0f8ff" }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="serviceRateCate"
                      key={tag_ProccessingLab ? tag_ProccessingLab : editButton}
                      onChange={(event, newValue) => {
                        // let sourceIds: any = [];
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setServiceRateCategory([obj?.id]);
                        }
                      }}
                      filterOptions={(options, state) => options}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getRateListFilter(
                            tag_ProccessingLab,
                            String(newInputValue).trimStart()
                          );
                        }, 1000);
                      }}
                      options={rateListFilter?.results || []}
                      freeSolo
                      defaultValue={
                        serviceRateCategoryDef || {
                          id: "",
                          name: "",
                          assigned_lab: "",
                        }
                      }
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) =>
                        `${option?.name} ${
                          (option?.assigned_lab?.name ||
                            option?.assigned_lab) &&
                          "(" +
                            (option?.assigned_lab?.name ||
                              option?.assigned_lab) +
                            ")"
                        }`
                      }
                      renderOption={(option, { selected }) => (
                        <div>
                          {option?.name}
                          <b> ({option?.assigned_lab?.name})</b>
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Service Rate Category"
                          variant="outlined"
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          required
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      key={editButton}
                      fullWidth
                      aria-required
                      variant="outlined"
                      className="inputField"
                      value={categoryName}
                      disabled={centerType === "collection_center"}
                      required
                      select
                      size="small"
                      label="Category Name"
                      onChange={(e) => setCategoryName(e.target.value)}
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="prepaid">Prepaid</MenuItem>
                      <MenuItem value="postpaid">Postpaid</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      label="Payment Mode"
                      variant="outlined"
                      fullWidth
                      value={paymentMode}
                      disabled
                      className="inputField"
                      select
                      size="small"
                      required
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      {paymentModeList.map((val: any) => (
                        <MenuItem value={val?.id}>{val?.name}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      aria-required
                      variant="outlined"
                      label="Barcode Type"
                      className="inputField"
                      value={barCodeType}
                      required
                      select
                      size="small"
                      onChange={(e) => setBarCodeType(e.target.value)}
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="preprainted">Pre Printed</MenuItem>
                      <MenuItem value="systemgenerated">
                        System Genrated
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="busyId"
                      type="text"
                      value={busyId}
                      className="input"
                      label="Busy Id "
                      variant="outlined"
                      onChange={(e) => setBusyId(e.target.value as string)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="colony-area-sector"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );

                          setMapAddressObject(obj);
                        }
                      }}
                      options={suggestedAddress ? suggestedAddress : []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) =>
                        `${option?.placeName}, ${option?.placeAddress}`
                      }
                      filterOptions={(option: any) => option}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          locationAutoCompleteSearch(newInputValue, event);
                        }, 500);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Search Address(for city)"
                          variant="outlined"
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "0",
                            backgroundColor: "#f0f8ff",
                          }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      key={editButton}
                      className="input"
                      label="City"
                      variant="outlined"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "0",
                        backgroundColor: "#f0f8ff",
                      }}
                      value={cityName}
                      disabled
                      onChange={(e) => setCityId(e.target.value as string)}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      label="State"
                      variant="outlined"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "0",
                        backgroundColor: "#f0f8ff",
                      }}
                      value={stateName}
                      disabled
                      //onChange={(e) => setCityId(e.target.value as string)}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="lat"
                      type="text"
                      value={center_latitude}
                      className="input"
                      label="Latitude"
                      variant="outlined"
                      onChange={(e) =>
                        setCentreLatitude(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="lon"
                      type="text"
                      value={center_longitude}
                      className="input"
                      label="Longitude"
                      variant="outlined"
                      onChange={(e) =>
                        setCentreLongitude(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      key={editButton}
                      fullWidth
                      variant="outlined"
                      select
                      size="small"
                      label="Sample Register To"
                      className="inputField"
                      value={sampleRegisterTo}
                      required
                      style={{ backgroundColor: "#f0f8ff" }}
                      onChange={(e) => setSampleRegisterTo(e.target.value)}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      {/* <MenuItem value="livehealth">LiveHealth</MenuItem> */}
                      <MenuItem value="itdose">Itdose</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="bussinessId"
                      key={centerId}
                      onChange={(event, newValue) => {
                        let sourceIds;
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          sourceIds = obj;
                          if (
                            (centerType === "direct_client" ||
                              centerType === "GE Lab" ||
                              centerType === "research") &&
                            categoryName === "postpaid"
                          ) {
                            setRclAccNo(obj?.rcl_bank_number || "");
                            setRclBank(obj?.rcl_bank_name || "");
                            setRclIfsc(obj?.rcl_bank_ifsc || "");
                            setRclBranch(obj?.rcl_bank_branch || "");
                            setRclBranchAddr(obj?.rcl_bank_address || "");
                          }
                        }
                        setBusinessLocation(sourceIds);
                      }}
                      options={get_business_location.results || []}
                      getOptionLabel={(option: any) => option?.name}
                      value={businessLocation}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getBusinessLocation(`?name=${newInputValue}`);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="input"
                          required
                          label="Business Location"
                          variant="outlined"
                          style={{ backgroundColor: "#f0f8ff" }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3}>
                    <label
                      htmlFor="fileUp"
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        margin: "0px",
                        width: "100%",
                        padding: "5px 5px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                        height: 40,
                      }}
                    >
                      <div style={{ width: "20%", textAlign: "left" }}>
                        Upload
                      </div>
                      <div style={{ width: "60%", textAlign: "left" }}>
                        <input
                          // style={{ display: "none" }}
                          id={"fileUp"}
                          multiple
                          type="file"
                          accept="image/png, image/jpg, image/jpeg, application/pdf"
                          onChange={(e) =>
                            setImages(
                              e.target.files && e.target.files.length > 0
                                ? e.target.files
                                : []
                            )
                          }
                        />
                      </div>
                    </label>
                  </Grid> */}
                  {centerType === "collection_center" ? (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="lon"
                          type="text"
                          value={website}
                          className="input"
                          label="Website"
                          variant="outlined"
                          onChange={(e) => setWebsite(e.target.value as string)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          onKeyDown={handleKeyDown}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          aria-required
                          variant="outlined"
                          label="Sample Transfer Type"
                          className="inputField"
                          value={sampleTransfer_type}
                          select
                          size="small"
                          onChange={(e) =>
                            setSampleTransfer_type(e.target.value)
                          }
                          style={{ backgroundColor: "#f0f8ff" }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        >
                          <MenuItem value="rider">Rider</MenuItem>
                          <MenuItem value="courier">Courier</MenuItem>
                          <MenuItem value="drop_off">Drop Off</MenuItem>
                          <MenuItem value="drop_off,rider">
                            Drop Off, Rider
                          </MenuItem>
                          <MenuItem value="drop_off,courier">
                            Drop Off, Courier
                          </MenuItem>
                          <MenuItem value="courier,rider">
                            Courier, Rider
                          </MenuItem>
                          <MenuItem value="drop_off,courier,rider">
                            Drop Off, Courier, Rider
                          </MenuItem>
                        </TextField>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      aria-required
                      variant="outlined"
                      label="TDS Percentage"
                      className="inputField"
                      value={tds || "1"}
                      select
                      size="small"
                      onChange={(e) => setTds(e.target.value)}
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="1">1%</MenuItem>
                      <MenuItem value="2">2%</MenuItem>
                      <MenuItem value="3">3%</MenuItem>
                      <MenuItem value="4">4%</MenuItem>
                      <MenuItem value="10">10%</MenuItem>
                      <MenuItem value="15">15%</MenuItem>
                      <MenuItem value="20">20%</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      aria-required
                      variant="outlined"
                      label="Sample Transfer Type"
                      value={sampleTransfer_type || "Drop Off, Courier, Rider"}
                      className="inputField"
                      select
                      size="small"
                      onChange={(e) => setSampleTransfer_type(e.target.value)}
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="rider">Rider</MenuItem>
                      <MenuItem value="courier">Courier</MenuItem>
                      <MenuItem value="drop_off">Drop Off</MenuItem>
                      <MenuItem value="drop_off,rider">
                        Drop Off, Rider
                      </MenuItem>
                      <MenuItem value="drop_off,courier">
                        Drop Off, Courier
                      </MenuItem>
                      <MenuItem value="courier,rider">Courier, Rider</MenuItem>
                      <MenuItem value="drop_off,courier,rider">
                        Drop Off, Courier, Rider
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="lon"
                      type="text"
                      value={support_email}
                      className="input"
                      label="Support Email"
                      variant="outlined"
                      onChange={(e) =>
                        setSupportEmail(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="lon"
                      type="text"
                      value={support_mobile}
                      className="input"
                      label="Support Mobile"
                      variant="outlined"
                      onChange={(e) =>
                        setSupportMobile(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="lon"
                      type="text"
                      value={support_Website}
                      className="input"
                      label="Support Website"
                      variant="outlined"
                      onChange={(e) =>
                        setSupportWebsite(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <h4
                    className={classes.hoveItem}
                    style={{
                      fontWeight: "bold",
                      marginTop: "1rem",
                      background: "#924A91",
                      color: "#fff",
                      textAlign: "center",
                      padding: "4px",
                      height: "35px",
                    }}
                  >
                    CENTRE HEAD DETAILS
                  </h4>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  style={{ alignItems: "left", marginTop: "10px" }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="salutation"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setDesignation(obj.value);
                        }
                      }}
                      defaultValue={
                        salutationData &&
                        salutationData.find((code: any) => {
                          return code.value === designation;
                        })
                      }
                      options={salutationData}
                      disableClearable
                      // freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => option.text}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Salutation"
                          variant="outlined"
                          required
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Ownername"
                      type="text"
                      value={ownerName}
                      className="input"
                      label="Owner Name"
                      required
                      variant="outlined"
                      onChange={(e) => setOwnerName(e.target.value)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="nationality"
                      type="text"
                      value={nationality}
                      className="input"
                      label="Nationality"
                      variant="outlined"
                      onChange={(e) => setNationality(e.target.value as string)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="contact"
                      type="number"
                      label="Centre Head Mobile Number"
                      value={centerHeadalContactNumber}
                      variant="outlined"
                      style={{
                        margin: "0",
                        width: "100%",
                        backgroundColor: "#f0f8ff",
                      }}
                      onChange={(e) =>
                        setCentreHeadContactNumber(e.target.value as String)
                      }
                      onBlur={() => verifyMobile("contMob")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          centerHeadalContactNumber: true,
                        }));
                      }}
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      helperText={
                        !mobileValidation.centerHeadalContactNumber &&
                        "Incorrect Mobile Number"
                      }
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="contact"
                      type="number"
                      label="Centre Head Alternate Number"
                      value={centerHeadalterNateNumber}
                      variant="outlined"
                      style={{
                        margin: "0",
                        width: "100%",
                        backgroundColor: "#f0f8ff",
                      }}
                      onChange={(e) =>
                        setCentreHeadAlternateNumber(e.target.value as String)
                      }
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onBlur={() => verifyMobile("contAlt")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          centerHeadalterNateNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.centerHeadalterNateNumber &&
                        "Incorrect Alternate Number"
                      }
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                </Grid>
                <h4
                  className={classes.hoveItem}
                  onClick={() =>
                    setCentreBankShow(centerBankShow ? false : true)
                  }
                  style={{
                    fontWeight: "bold",
                    marginTop: "1rem",
                    background: "#924A91",
                    color: "#fff",
                    textAlign: "center",
                    padding: "4px",
                    height: "35px",
                  }}
                >
                  CENTRE BANK DETAILS
                </h4>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  style={{ alignItems: "left", marginTop: "10px" }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="acountholder"
                      type="text"
                      value={bankAccountHolder}
                      className="input"
                      label="Account Holder Name"
                      variant="outlined"
                      onChange={(e) => setbankAccountHolder(e.target.value)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="bank"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setBank(obj.id);
                        }
                      }}
                      defaultValue={
                        bankName.count > 0 &&
                        bankName.results.find((code: any) => {
                          return code.id === bank;
                        })
                      }
                      freeSolo
                      blurOnSelect
                      options={bankName?.results}
                      disableCloseOnSelect
                      getOptionLabel={(option: any) => option?.name}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getBankName(`?name=${newInputValue}`);
                        }, 1000);

                        setBankInput(newInputValue);
                      }}
                      inputValue={bankInput}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Bank Name"
                          className="input"
                          variant="outlined"
                          fullWidth
                          style={{ backgroundColor: "#f0f8ff" }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="GSTINNumber"
                      type="text"
                      value={gstinNumber}
                      className="input"
                      label="GSTIN"
                      variant="outlined"
                      onChange={(e) => setGstinNumber(e.target.value)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className={classes.input}>
                    <TextField
                      name="panNumber"
                      type="text"
                      value={panNumber}
                      className="input"
                      label="PAN"
                      variant="outlined"
                      placeholder="Enter Valid Format PAN Ex-ABCDE9999F"
                      // inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={(e) =>
                        setPanNumber(String(e.target.value).toUpperCase())
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      // helperText="Ex:- ABCDE9999F"
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="IFSC"
                      type="text"
                      value={ifscCode}
                      className="input"
                      label="IFSC Code"
                      variant="outlined"
                      onChange={(e) => setIfscCode(e.target.value)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="accountNumber"
                      type="number"
                      className="input"
                      value={accountNumber}
                      // className={classes.input}
                      label="Account Number"
                      variant="outlined"
                      onChange={(e) => setAccountNumber(e.target.value)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                </Grid>
                {(centerType === "direct_client" ||
                  centerType === "GE Lab" ||
                  centerType === "research") &&
                categoryName === "postpaid" ? (
                  <>
                    <h4
                      className={classes.hoveItem}
                      onClick={() =>
                        setCentreBankShow(centerBankShow ? false : true)
                      }
                      style={{
                        fontWeight: "bold",
                        marginTop: "1rem",
                        background: "#924A91",
                        color: "#fff",
                        textAlign: "center",
                        padding: "4px",
                        height: "35px",
                      }}
                    >
                      INVOICE BANK DETAILS
                    </h4>

                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      style={{ alignItems: "left", marginTop: "10px" }}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="accountNumber"
                          type="number"
                          value={rclAccNo}
                          className="input"
                          label="Account Number"
                          variant="outlined"
                          onChange={(e) => setRclAccNo(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          required
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="IFSC"
                          type="text"
                          value={rclIfsc}
                          className="input"
                          label="IFSC Code"
                          variant="outlined"
                          onChange={(e) =>
                            String(e.target.value).length < 12
                              ? setRclIfsc(e.target.value)
                              : ""
                          }
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          required
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Button
                          disabled={
                            String(rclIfsc).length !== 11 || !ifscData["BANK"]
                          }
                          variant="contained"
                          onClick={() => autoFillBank()}
                        >
                          Autofill bank details
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="bank name"
                          type="text"
                          value={rclBank}
                          className="input"
                          label="Bank name"
                          variant="outlined"
                          onChange={(e) => setRclBank(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          required
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="branch name"
                          type="text"
                          value={rclBranch}
                          className="input"
                          label="Branch name"
                          variant="outlined"
                          onChange={(e) => setRclBranch(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          required
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          name="branch address"
                          type="text"
                          value={rclBranchAddr}
                          className="input"
                          label="Branch address"
                          variant="outlined"
                          onChange={(e) => setRclBranchAddr(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          required
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}

                <h4
                  className={classes.hoveItem}
                  onClick={() =>
                    setCentreControllingShow(
                      centerControlingShow ? false : true
                    )
                  }
                  style={{
                    fontWeight: "bold",
                    background: "#924A91",
                    color: "#fff",
                    marginTop: "1rem",
                    textAlign: "center",
                    padding: "4px",
                    height: "35px",
                  }}
                >
                  ACCOUNT CONTROLLING DETAILS
                </h4>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  style={{ alignItems: "left", marginTop: "10px" }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="minamountfornoti"
                      type="number"
                      value={minAmountForNotification}
                      className="input"
                      label="Min Amt. For Notification *"
                      variant="outlined"
                      onChange={(e) =>
                        setMinNotication(e.target.value as string)
                      }
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="minamountforbill"
                      type="number"
                      value={minAmountForBill}
                      disabled={categoryName === "postpaid"}
                      className="input"
                      label="Min Amt For Bill *"
                      variant="outlined"
                      onChange={(e) => setMinAmountForBill(e.target.value)}
                      style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Package Rights"
                      value={packageRights}
                      select
                      className="inputField"
                      size="small"
                      required
                      label="Package Rights"
                      variant="outlined"
                      onChange={(e) => setPackageRights(e.target.value)}
                      fullWidth
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="redcliffe">Redcliffe</MenuItem>
                      <MenuItem value="self">Self</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                      <MenuItem value="all">All</MenuItem>
                    </TextField>
                  </Grid>
                  {categoryName === "postpaid" ? (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="Credit Limit"
                          type="number"
                          value={creditLimit}
                          className="input"
                          required
                          label="Credit Limit"
                          variant="outlined"
                          onChange={(e) => setCreditLimit(e.target.value)}
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f8ff",
                          }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="Credit Limit"
                          type="number"
                          value={invoiceCycle}
                          select
                          className="inputField"
                          size="small"
                          required
                          label="Invoice Cycle"
                          variant="outlined"
                          onChange={(e) => setInvoiceCycle(e.target.value)}
                          fullWidth
                          style={{ backgroundColor: "#f0f8ff" }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        >
                          <MenuItem value="2">2 Days</MenuItem>
                          <MenuItem value="15">15 Days</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                        </TextField>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Report Whatsapp Sent To"
                      type="number"
                      value={reportWhatsappSentTo}
                      label="Report Whatsapp Sent To"
                      variant="outlined"
                      select
                      className="inputField"
                      size="small"
                      onChange={(e) => setReportWhatsappSentTo(e.target.value)}
                      fullWidth
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="client">Client</MenuItem>
                      <MenuItem value="customer">Customer</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                      <MenuItem value="not_send">Not Send</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      type="number"
                      value={reportEmailSentTo}
                      select
                      className="inputField"
                      size="small"
                      label="Report Email Sent To"
                      variant="outlined"
                      onChange={(e) => setReportEmailSentTo(e.target.value)}
                      fullWidth
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="client">Client</MenuItem>
                      <MenuItem value="customer">Customer</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                      <MenuItem value="not_send">Not Send</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      type="number"
                      value={reportSmsSentTo}
                      select
                      className="inputField"
                      size="small"
                      label="Report Sms Sent To"
                      variant="outlined"
                      onChange={(e) => setReportSmsSentTo(e.target.value)}
                      fullWidth
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="client">Client</MenuItem>
                      <MenuItem value="customer">Customer</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                      <MenuItem value="not_send">Not Send</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      value={accountActive}
                      select
                      className="inputField"
                      size="small"
                      label="Billing on/off"
                      variant="outlined"
                      onChange={(e) => setAccountActive(e.target.value)}
                      fullWidth
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    >
                      <MenuItem value="true">on</MenuItem>
                      <MenuItem value="false">off</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      value={nameOnInvoice}
                      size="small"
                      label="Name On Invoice"
                      className="input"
                      variant="outlined"
                      onChange={(e) => setNameOnInvoice(e.target.value)}
                      fullWidth
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      value={tanNumber}
                      size="small"
                      label="Tan Number"
                      variant="outlined"
                      className="input"
                      onChange={(e) => setTanNumber(e.target.value)}
                      fullWidth
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      value={paymentTermsInDays}
                      size="small"
                      label="Payment Terms in days"
                      variant="outlined"
                      className="input"
                      onChange={(e) => setPaymentTermsInDays(e.target.value)}
                      fullWidth
                      style={{ backgroundColor: "#f0f8ff" }}
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="mobile_number"
                      type="number"
                      label="Virtual Number"
                      value={virtualNumber}
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      style={{
                        margin: "0",
                        width: "100%",
                        backgroundColor: "#f0f8ff",
                      }}
                      onChange={(e) =>
                        setVirtualNumber(e.target.value as String)
                      }
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onBlur={() => verifyMobile("vno")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          vno: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.vno && "Incorrect Mobile Number"
                      }
                      InputLabelProps={{
                        style: { color: "#082687" },
                      }}
                    />
                  </Grid>
                  {centerType === "direct_client" ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        value={serviceCharge}
                        select
                        className="inputField"
                        size="small"
                        label="Is service charge applicable"
                        variant="outlined"
                        onChange={(e) => setServiceCharge(e.target.value)}
                        fullWidth
                        style={{ backgroundColor: "#f0f8ff" }}
                        InputLabelProps={{
                          style: { color: "#082687" },
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </TextField>
                    </Grid>
                  ) : (
                    ""
                  )}

                  {centerType === "direct_client" ||
                  centerType === "GE Lab" ||
                  centerType === "research" ||
                  centerType === "GE CC" ||
                  centerType === "GE_Home_Collection" ? (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          value={autosettle}
                          select
                          className="inputField"
                          size="small"
                          label="Autosettle"
                          variant="outlined"
                          onChange={(e) => setAutosettle(e.target.value)}
                          fullWidth
                          style={{ backgroundColor: "#f0f8ff" }}
                          InputLabelProps={{
                            style: { color: "#082687" },
                          }}
                        >
                          <MenuItem value="auto_settlement">
                            Auto Settlement
                          </MenuItem>
                          <MenuItem value="manual_settlement">
                            Manual Settlement
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_booking_payment_sms"
                                checked={smsType.is_booking_payment_sms}
                              />
                            }
                            onChange={handleSmsControl}
                            label="Booking payment sms"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_booking_sms"
                                checked={smsType.is_booking_sms}
                              />
                            }
                            onChange={handleSmsControl}
                            label="booking sms"
                          />

                          {centerType === "GE_Home_Collection" && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="is_shown_to_pha"
                                  checked={smsType.is_shown_to_pha}
                                />
                              }
                              onChange={handleSmsControl}
                              label="shown to pha"
                            />
                          )}
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_shown_to_sales"
                                checked={smsType.is_shown_to_sales}
                              />
                            }
                            onChange={handleSmsControl}
                            label="shown to sales"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_auto_verify"
                                checked={smsType.is_auto_verify}
                              />
                            }
                            onChange={handleSmsControl}
                            label="auto verify"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_price_hide"
                                checked={smsType.is_price_hide}
                              />
                            }
                            onChange={handleSmsControl}
                            label="price hide"
                          />
                        </FormGroup>
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isapproved"
                              checked={smsType.isapproved}
                            />
                          }
                          onChange={handleSmsControl}
                          label="Is Approved"
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  {/* Document */}
                  <h4
                    className={classes.hoveItem}
                    onClick={() =>
                      setCenterDocumnetShow(centerDocumnetShow ? false : true)
                    }
                    style={{
                      fontWeight: "bold",
                      marginTop: "1rem",
                      background: "#924A91",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      padding: "4px",
                      height: "35px",
                      marginLeft: "10px",
                    }}
                  >
                    DOCUMENT UPLOAD DETAILS
                  </h4>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    style={{ alignItems: "left" }}
                  >
                    {docUploadDetails.map((val: any, index: any) => {
                      return (
                        <Container key={val.seq}>
                          <Grid
                            container
                            spacing={3}
                            direction="row"
                            style={{ marginLeft: "20px", marginTop: "20px" }}
                          >
                            <Grid item xs={12} sm={6} md={1}>
                              {docUploadDetails.length !== 1 && (
                                <HighlightOffIcon
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => addRemove(index, "remove")}
                                />
                              )}
                              {docUploadDetails.length - 1 === index &&
                                index < 4 && (
                                  <AddCircleIcon
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      borderRadius: "50%",
                                    }}
                                    onClick={() => addRemove(-1, "add")}
                                  />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                value={val.docType}
                                select
                                className="inputField"
                                size="small"
                                label="Document Type"
                                variant="outlined"
                                style={{ backgroundColor: "#f0f8ff" }}
                                InputLabelProps={{
                                  style: { color: "#082687" },
                                }}
                                onChange={(e) => {
                                  updateDocUploadDetails(
                                    index,
                                    "udpate_doc_type",
                                    e.target.value,
                                    val
                                  );
                                }}
                                fullWidth
                              >
                                <MenuItem
                                  value="pan"
                                  disabled={docTypeArray.includes("pan")}
                                >
                                  PAN
                                </MenuItem>
                                <MenuItem
                                  value="aadhar"
                                  disabled={docTypeArray.includes("aadhar")}
                                >
                                  Aadhar
                                </MenuItem>
                                <MenuItem
                                  value="agreement"
                                  disabled={docTypeArray.includes("agreement")}
                                >
                                  Agreement
                                </MenuItem>
                                <MenuItem
                                  value="rent_agreement"
                                  disabled={docTypeArray.includes(
                                    "rent_agreement"
                                  )}
                                >
                                  Rent Agreement
                                </MenuItem>
                                <MenuItem
                                  value="other"
                                  disabled={docTypeArray.includes("other")}
                                >
                                  Other
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              {val.docType ? (
                                <input
                                  className="input"
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                    padding: "5px 5px",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderRadius: "5px",
                                    height: "40px",
                                    backgroundColor: "#f0f8ff",
                                  }}
                                  type="file"
                                  name="file"
                                  id="file"
                                  accept=".pdf,.jpg,.jpeg"
                                  onChange={(e) => {
                                    updateDocUploadDetails(
                                      index,
                                      "update_document",
                                      e.target.files,
                                      val
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  className="input"
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                    padding: "5px 5px",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderRadius: "5px",
                                    height: "40px",
                                    backgroundColor: "#f0f8ff",
                                  }}
                                  type="file"
                                  name="file"
                                  id="file"
                                  accept=".pdf,.jpg,.jpeg"
                                  onChange={(e) => {
                                    updateDocUploadDetails(
                                      index,
                                      "update_document",
                                      e.target.files,
                                      val
                                    );
                                  }}
                                  disabled
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <Button
                                color="secondary"
                                variant="contained"
                                disabled={val.docToShow === ""}
                                onClick={() => previewIt(val)}
                              >
                                View
                              </Button>
                            </Grid>
                          </Grid>
                        </Container>
                      );
                    })}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ marginLeft: "20px" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="shownalltrue"
                            checked={smsType.shownalltrue}
                          />
                        }
                        onChange={handleSmsControl}
                        label="Shown to all"
                      />
                    </Grid>
                  </Grid>

                  <Grid item>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isbillinglock"
                            checked={smsType.isbillinglock}
                            disabled={centerType === "collection_center"}
                          />
                        }
                        onChange={handleSmsControl}
                        label="Is Billing Lock"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox name="mail" checked={smsType.mail} />
                        }
                        onChange={handleSmsControl}
                        label="Is HLM Client"
                      />
                      <FormControlLabel
                        control={<Checkbox name="sms" checked={smsType.sms} />}
                        onChange={handleSmsControl}
                        label="Phlebo Required"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox name="screen" checked={smsType.screen} />
                        }
                        onChange={handleSmsControl}
                        label="Is Special Price Billing"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox name="screen" checked={isSpecialPackage} />
                        }
                        onChange={() => setIsSpecialPackage(!isSpecialPackage)}
                        label="Only show special price packages"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox name="screen" checked={tdsDeduction} />
                        }
                        onChange={() => setTdsDeduction(!tdsDeduction)}
                        label="TDS deduction"
                      />
                      <FormControlLabel
                        control={<Checkbox name="screen" checked={tdsRefund} />}
                        onChange={() => setTdsRefund(!tdsRefund)}
                        label="TDS refund"
                      />
                      <FormControlLabel
                        control={<Checkbox name="screen" checked={isNabl} />}
                        onChange={() => setIsNabl(!isNabl)}
                        label="Is NABL"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox name="screen" checked={isInventroy} />
                        }
                        onChange={() => setIsInventory(!isInventroy)}
                        label="Is Inventory Order"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox name="screen" checked={isPhysicalClosing} />
                        }
                        onChange={() =>
                          setIsPhysicalClosing(!isPhysicalClosing)
                        }
                        label="Is Physical Closing"
                      />
                      {centerType === "collection_center" ? (
                        <FormControlLabel
                          control={
                            <Checkbox name="screen" checked={isWebsite} />
                          }
                          onChange={() => setIsWebsite(!isWebsite)}
                          label="Is Website Display"
                        />
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Grid>

                  {userDetails?.profile?.executive_type === "Auditor" ? (
                    ""
                  ) : (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={
                            !mobileValidation.mobileNumber ||
                            !mobileValidation.altNumber ||
                            !mobileValidation.centerHeadalContactNumber ||
                            !mobileValidation.vno ||
                            !mobileValidation.centerHeadalterNateNumber
                          }
                          type="submit"
                        >
                          {editButton ? "Update Centre" : "Create Center"}
                        </Button>
                      </Grid>
                      {!editButton ? (
                        <Grid item xs={12} sm={6} md={3}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() =>
                              history.push("/dashboard/ts/centerCreationModal")
                            }
                          >
                            Reset
                          </Button>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={3}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() =>
                              history.push("/dashboard/ts/centerCreationModal")
                            }
                          >
                            Cancel Update
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Paper>
            </form>
          </Box>
        </Modal>

        {centerShow ? (
          ""
        ) : (
          <div>
            {/* <Grid
                  container
                  spacing={1}
                  direction="row"
                  className={classes.centerIt}
                  style={{
                    margin: "1rem auto",
                  }}
                >
                  <Grid item xs={12} sm={10}>
                    <h5>Search Option</h5>
                  </Grid>
                </Grid> */}
            <Grid
              container
              spacing={1}
              direction="row"
              className={classes.centerIt}
              style={{
                margin: "1rem auto",
                alignItems: "right",
              }}
            >
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  label="Centre/Code"
                  variant="outlined"
                  fullWidth
                  value={centerCodeFilter}
                  onChange={(e) => {
                    setCenterCodeFilter(e.target.value as String);
                  }}
                  style={{ backgroundColor: "#f0f8ff" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="mobile_number"
                  type="number"
                  label="Mobile Number"
                  value={mobileNumberFilter}
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  style={{
                    margin: "0",
                    width: "100%",
                    backgroundColor: "#f0f8ff",
                  }}
                  onChange={(e) =>
                    setMobileNumberFilter(e.target.value as String)
                  }
                  required
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onBlur={() => verifyMobile("mob")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      mobileNumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.mobileNumber && "Incorrect Mobile Number"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="email"
                  type="text"
                  label="Email"
                  value={emailFilter}
                  variant="outlined"
                  onChange={(e) => setEmailFilter(e.target.value)}
                  style={{ width: "100%", backgroundColor: "#f0f8ff" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="centre_type"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCentreTypeFilter(obj?.value);
                    }
                  }}
                  defaultValue={
                    CenterTypeData &&
                    CenterTypeData.find((code: any) => {
                      return code?.value === centerTypeFilter;
                    })
                  }
                  options={CenterTypeData}
                  freeSolo
                  blurOnSelect
                  getOptionLabel={(option: any) => `${option?.text}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Centre Type"
                      variant="outlined"
                      fullWidth
                      className="input"
                      required
                      style={{ backgroundColor: "#f0f8ff" }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} md={2}>
                  <Autocomplete
                    id="city"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                        setCityIdFilter(obj.id);
                        setCityNameFilter(obj);
                      }
                    }}
                    options={centre_cities || []}
                    value={cityNameFilter}
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => `${option.name}`}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getCentreCities(`${newInputValue}&territory=true`);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Select City"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid> */}
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  // disabled={!mobileNumber.length ===10||disableFilterBtn}
                  disabled={
                    disableFilterBtn ||
                    !(
                      mobileNumberFilter?.length === 10 ||
                      emailFilter ||
                      centerTypeFilter ||
                      cityIdFilter ||
                      centerCodeFilter
                    )
                  }
                  onClick={filterCenter}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            <div
              className="data-table"
              style={{ width: "100%", marginTop: "1rem" }}
            >
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "515px" }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      {userDetails?.profile?.executive_type === "Auditor" ? (
                        <StyledTableCell align="center">
                          view Centre
                        </StyledTableCell>
                      ) : (
                        <>
                          <StyledTableCell align="center">
                            Edit Centre
                          </StyledTableCell>
                        </>
                      )}
                      {/* {userDetails?.profile?.executive_type ===
                          "Auditor" ? (
                          ""
                        ) : (
                          <StyledTableCell align="center">
                            Consumable Requistion
                          </StyledTableCell>
                        )} */}

                      <StyledTableCell align="center">
                        Centre Name
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Centre Code
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Disable 2F Otp
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Centre Comment
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                              Virtual Number
                            </StyledTableCell> */}
                      <StyledTableCell align="center">
                        Centre Sub Type
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                              Centre Owner
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Centre Email
                            </StyledTableCell> */}
                      <StyledTableCell align="center">Number</StyledTableCell>
                      <StyledTableCell align="center">
                        City Name
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                              Uploaded files
                            </StyledTableCell> */}
                      <StyledTableCell align="center">
                        Created Date
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                              Expiry Date
                            </StyledTableCell> */}
                      <StyledTableCell align="center">
                        Rate List Lab
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Rate List Name
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Centre Type
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        DC under CC
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Autosettle
                      </StyledTableCell>
                      <StyledTableCell align="center">Billing</StyledTableCell>
                      <StyledTableCell align="center">
                        Documents
                      </StyledTableCell>
                      {userDetails?.profile?.executive_type === "Auditor" ? (
                        ""
                      ) : (
                        <>
                          <StyledTableCell align="center">
                            Centre Status
                          </StyledTableCell>
                        </>
                      )}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {center_loading ? (
                      <Loader />
                    ) : (
                      centerInfo &&
                      centerInfo.results &&
                      centerInfo.results.length > 0 &&
                      centerInfo.results.map((data: any, index: any) => {
                        return (
                          <StyledTableRow
                            key={index}
                            style={{
                              background:
                                data?.is_active === true ? "" : "#ef9a9a",
                            }}
                          >
                            {userDetails?.profile?.executive_type ===
                            "Auditor" ? (
                              <StyledTableCell align="center">
                                <Button
                                  disabled={disableEdit}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleEditCentre(data)}
                                >
                                  view
                                </Button>
                              </StyledTableCell>
                            ) : (
                              <>
                                <StyledTableCell align="center">
                                  {data.center_type !== "corporate" ? (
                                    <Button
                                      disabled={disableEdit}
                                      color="primary"
                                      variant="contained"
                                    >
                                      <Edit
                                        onClick={() => {
                                          // getTerritoryData(`?center=${data.id}`)
                                          handleEditCentre(data);
                                        }}
                                      />
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                              </>
                            )}
                            {/* {userDetails?.profile?.executive_type ===
                                  "Auditor" ? (
                                  ""
                                ) : (
                                  <StyledTableCell align="center">
                                    {data?.center_type ===
                                      "collection_center" ? (
                                      <Button
                                        variant="contained"
                                        disabled={
                                         
                                          loading === true ||
                                          data?.subtype === "olc" ||
                                          data?.subtype === "OLC" ||
                                          disableConsReq === true ||
                                          data?.is_inventory_order === true
                                        }
                                        onClick={() => {
                                          consuReqModal(data?.center_code);
                                        }}
                                      >
                                       
                                        {data?.is_inventory_order === true
                                          ? "Sent"
                                          : "Send"}
                                      </Button>
                                    ) : (
                                      "N/A"
                                    )}
                                  </StyledTableCell>
                                )} */}

                            <StyledTableCell align="center">
                              {data?.display_name === null
                                ? data?.name
                                : data?.display_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {" "}
                              {data?.center_code}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <DefaultSwitch
                                checked={data?.is_otp_required}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                                onClick={() => disableCenter2factOtp(data)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <div>
                                <MessageRounded
                                  onClick={() => handleClick(data?.id)}
                                />
                              </div>
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                                      {data?.virtual_number === null
                                        ? "NA"
                                        : data?.virtual_number}
                                    </StyledTableCell> */}
                            <StyledTableCell align="center">
                              {" "}
                              {data?.subtype === "dc_under_cc"
                                ? "DC Under CC"
                                : data?.subtype}
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                                      {" "}
                                      {data?.owner_name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {" "}
                                      {data?.email}
                                    </StyledTableCell> */}
                            <StyledTableCell align="center">
                              {" "}
                              {data?.mobile}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {" "}
                              {data?.city_name}
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                                      {" "}
                                      {data?.images &&
                                        data?.images.length > 0 &&
                                        data?.images.map((val: any) => {
                                          return (
                                            <a href={val.file} target="blank">
                                              {val.file}
                                            </a>
                                          );
                                        })}
                                    </StyledTableCell> */}
                            <StyledTableCell align="center">
                              {data?.contract_start_date}{" "}
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                                      {data?.contract_end_date}
                                    </StyledTableCell> */}
                            {/* <StyledTableCell align="center">
                                    {" "}
                                    {data?.area?.state}{" "}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {" "}
                                    {data?.area?.city_name}{" "}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {" "}
                                    {data.address}
                                  </StyledTableCell> */}
                            <StyledTableCell align="center">
                              {" "}
                              {data.ratelist
                                ? data?.ratelist[0]?.assigned_lab
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {" "}
                              {data.ratelist ? data?.ratelist[0]?.name : ""}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {" "}
                              {data.center_type}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {" "}
                              {data?.cc_center?.display_name
                                ? data?.cc_center?.display_name
                                : data?.cc_center?.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.patient_type}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.is_account_active ? "on" : "off"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Button
                                disabled={data?.center_documents?.length === 0}
                                onClick={() =>
                                  setDocLinks(data?.center_documents)
                                }
                              >
                                View
                              </Button>
                            </StyledTableCell>
                            {userDetails?.profile?.executive_type ===
                            "Auditor" ? (
                              ""
                            ) : (
                              <>
                                <StyledTableCell align="center">
                                  {data.center_type !== "corporate" ? (
                                    <DefaultSwitch
                                      checked={data.is_active}
                                      name="checkedA"
                                      inputProps={{
                                        "aria-label": "secondary checkbox",
                                      }}
                                      disableRipple
                                      onClick={() => disableCenter(data)}
                                    />
                                  ) : data.is_active ? (
                                    "Active"
                                  ) : (
                                    "Inactive"
                                  )}
                                </StyledTableCell>
                              </>
                            )}
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                  <TableFooter>
                    <TablePagination
                      colSpan={6}
                      count={centerInfo.count || 0}
                      rowsPerPageOptions={[]}
                      rowsPerPage={centerInfo.page_size}
                      // onChangeRowsPerPage={handleChangeRowsPerPage}
                      page={page}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                    />
                  </TableFooter>
                </Table>
              </TableContainer>
              <TicketCommentsModal
                openModal={openModal}
                setopenModal={setopenModal}
                centerdata={centerdata}
              />
            </div>
            {/* </Paper> */}
          </div>
        )}

        {/* <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "right" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    !mobileValidation.mobileNumber ||
                    !mobileValidation.altNumber ||
                    !mobileValidation.centerHeadalContactNumber ||
                    !mobileValidation.centerHeadalterNateNumber
                  }
                  type="submit"
                >
                  {editButton ? "Update Centre" : "Create Center"}
                </Button>
              </Grid>
              {!editButton ? (
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() =>
                      history.push("/dashboard/ts/centerCreationModal")
                    }
                  >
                    Reset
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() =>
                      history.push("/dashboard/ts/centerCreationModal")
                    }
                  >
                    Cancel Update
                  </Button>
                </Grid>
              )}
            </Grid> */}
      </Paper>
      {/* </form> */}

      {/* <form onSubmit={handleUploadCentre}>
          <Paper elevation={5} className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <input
                  className="input"
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "5px 5px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "5px",
                    height: "35px",
                  }}
                  type="file"
                  name="fileUp"
                  id="fileUp"
                  multiple
                  onChange={fileUploadHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled
                  startIcon={<CloudUploadIcon />}
                >
                  Bulk Upload
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={7}>
                <sub>*File Format should be CSV(comma delimited)</sub>
                <br />
                <sub>
                  Columns (Centre Code | Centre Name | Centre Type | contract date
                  | expiry date | identity proof | mobile number | email id |
                  centre address | salutation | name | mobile number | Min Amount
                  for Notification | service rate category | sales manager | type)
                </sub>
              </Grid>
            </Grid>
          </Paper>
        </form> */}
      <Dialog
        open={docLinks !== "" ? true : false}
        onClose={() => setDocLinks("")}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <b>Download files</b>
          <ol>
            {docLinks.length > 0 &&
              docLinks.map((val: any, index: any) => {
                return (
                  <li>
                    <a download={true} href={val.file}>
                      {val.file}
                    </a>
                  </li>
                );
              })}
          </ol>
        </DialogContent>
      </Dialog>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  centerInfo: state.TechSupportReducer.centerInfo,
  centreCode: state.TechSupportReducer.centreCode,
  pin_code: state.TechSupportReducer.pin_code,
  agentBYUserGroup: state.TechSupportReducer.agentBYUserGroup,
  salesManager: state.TechSupportReducer.salesManager,
  salesExecutive: state.TechSupportReducer.salesExecutive,
  identityProofData: state.TechSupportReducer.identityProofData,
  centre_cities: state.TechSupportReducer.centre_cities,
  cities: state.TechSupportReducer.cities,
  paymentModeList: state.TechSupportReducer.paymentModeList,
  rateListFilter: state.TechSupportReducer.rateListFilter,
  tagProccessingLab: state.TechSupportReducer.tagProccessingLab,
  uploadCentresData: state.TechSupportReducer.uploadCentresData,
  userDetails: state.TechSupportReducer.userDetails,
  bankName: state.TechSupportReducer.bankName,
  centers: state.TechSupportReducer.centers,
  get_business_location: state.TechSupportReducer.get_business_location,
  // get_cc_consumption: state.TechSupportReducer.get_cc_consumption,
  cc_to_inv: state.TechSupportReducer.cc_to_inv,
  ifscData: state.TechSupportReducer.ifscData,
  mapmyindiaAccessToken: state.TechSupportReducer.mapmyindiaAccessToken,
  get_mrr_sales: state.TechSupportReducer.get_mrr_sales,
  territoryData: state.TechSupportReducer.territoryData,
  center_loading: state.TechSupportReducer.center_loading,
});
export default connect(mapStateToProps, {
  getCenterInfo,
  getPinCode,
  createNewCenter,
  getBankName,
  getAgentList,
  getSalesExecutive,
  getSalesManager,
  updateCenter,
  getIdentityProof,
  getCentreCities,
  getCities,
  getpaymentMoodList,
  getRateListFilter,
  getTagProcessingLab,
  getCentreCode,
  uploadCentres,
  getCenters,
  getBusinessLocation,
  // getCCConsumptionItems,
  ccToInv,
  resetCCToInventoryRes,
  getIfscData,
  getMapMyIndiaAccessToken,
  disableUser2fOtp,
  getMrrsales,
  getTerritoryData,
})(CreateCentrePage);
