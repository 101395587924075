import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid, Select, MenuItem, Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import {
  updatePanelUser,
  getLeadSource,
  createPanelUser,
  getPanelUserListTeamLead,
  getLanguage,
  getOzontelSkillsData,
  getDialer,
  getOrgTypes,
  getChatSkillsList,
  getAllUsers,
  getUserEdit,
  getCentre,
  getUserTypes,
} from "../../actions/TechSupportActions";
import { useHistory, useParams } from "react-router-dom";
import { ADDUSER_EXECUTIVE_TYPES } from "../constant";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  contentTable: {
    width: "100%",
    padding: "10px 0px 10px 0px",
  },
}));
type ModalProps = {
  updatePanelUser: any;
  dialer: any;
  loading: any;
  getLeadSource: any;
  lead_source: any;
  createPanelUser: any;
  teamLeadList: any;
  chatSkillsList: any;
  language: any;
  orgTypesList: any;
  getPanelUserListTeamLead: any;
  getLanguage: any;
  getOzontelSkillsData: any;
  getDialer: any;
  getOrgTypes: any;
  getChatSkillsList: any;
  ozontelskilldata: any;
  userDetails: any;
  usersList: any;
  getAllUsers: any;
  userData: any;
  getUserList: any;
  getUserEdit: any;
  userEditData: any;
  getCentre: any;
  centreData: any;
  userTypesList: any;
  getUserTypes: any;
};

const CreateUser: React.FC<ModalProps> = ({
  userDetails,
  loading,
  dialer,
  getLeadSource,
  lead_source,
  createPanelUser,
  teamLeadList,
  chatSkillsList,
  language,
  orgTypesList,
  getPanelUserListTeamLead,
  getLanguage,
  getOzontelSkillsData,
  ozontelskilldata,
  getDialer,
  getOrgTypes,
  getChatSkillsList,
  updatePanelUser,
  usersList,
  getAllUsers,
  userData,
  getUserEdit,
  userEditData,
  getCentre,
  centreData,
  userTypesList,
  getUserTypes,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [username, setUserName] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState<String>("");
  const [email, setEmail] = useState<String>();
  const [userGroup, setUserGroup] = useState<any>("");
  const [password, setPassword] = useState("");
  const [dialers, setDialers] = useState("");
  const [orgType, setOrgType] = useState<any>("");
  const [centreDef, setCentreDef] = useState<any>("");
  const [reportingLocation, setReportingLocation] = useState<any>("");
  const [userType, setUserType] = useState<any>("");
  const [clicktoCall, setClickToCall] = useState<any>("");
  const [ameyoUsername, setAmeyoUsername] = useState<any>("");
  const [ameyoPassword, setAmeyoPassword] = useState<any>();
  const [isactive, setIsActive] = useState<boolean>(false);
  const [employeeId, setEmployeeId] = useState<String>("");
  const [teamLead, setTeamLead] = useState<any>([]);
  const [assignDef, setAssignDef] = useState<any>([]);
  const { id, namePath } = useParams<{ id: string; namePath: string }>();
  const [isEdit, setIsEdit] = useState<any>(false);
  const [defOrgType, setDefOrgType] = useState<any>([]);
  const [dailerNameDef, setDailerNameDef] = useState<any>("");
  const [languagesDef, setLanguagesDef] = useState<any>("");
  const [key, setKey] = useState<any>(false);
  const [chatskills, setChatSkills] = useState<any>([]);
  const [chatSkilldef, setChatSkilldef] = useState<any>([]);
  const [executiveType, setExecutiveType] = useState<any>("");
  const [languages, setLanguages] = useState<any>([]);
  const [ozontelName, setozontel] = useState<any>([]);
  const [ozontelId, setOzontelId] = useState<any>([]);
  const [centre, setCentre] = useState<any>("");
  const [userTypes, setUserTypes] = useState<any>("");

  const timer = useRef<any>(0);
  const inputAlphaNumeric = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^ a-zA-Z_ ]+/gi, "")
      : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });
  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-0987654321`".includes(e.key) &&
      e.preventDefault()
    );
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };
  const submitHandler = async (e: any) => {
    e.preventDefault();
    const body: any = {
      username: username,
      email,
      fullname: name,
      phonenumber: phoneNumber,
      password: password,
      usergroup: userGroup,
      empid: employeeId,
      ctc_from: clicktoCall,
      dailer: dialers,
      asigned: teamLead,
      usertype: userType,
      org_types: orgType,
      languages: languages,
      reporting_location: reportingLocation,
      executive_type: executiveType,
      ozontel_skills: ozontelId,
      chat_skills: chatskills,
      is_active_user: isactive,
      amyeo_user: ameyoUsername,
      amyeo_password: ameyoPassword,
      center: centre,
    };
    if (password === "" || password === "none") {
      delete body.password;
    }
    if (executiveType === "" || executiveType === "none") {
      delete body.executive_type;
    }
    if (email === "") {
      delete body.email;
    }
    if (phoneNumber === "") {
      delete body.phonenumber;
    }
    if (employeeId === "" || employeeId === "none") {
      delete body.empid;
    }
    if (
      userGroup === "CustomerLead" ||
      userGroup === "ServiceLead" ||
      userGroup === "SalesLead" ||
      userGroup === "Logistics" ||
      userGroup === "Phlebo" ||
      userGroup === "RouteManager"
    ) {
      await updatePanelUser(body, namePath);
      history.push("/dashboard/ts/manageHierarchy");
    } else if (
      userGroup === "CustomerExecutive" ||
      userGroup === "SupportExecutive" ||
      userGroup === "VerificationExecutive" ||
      userGroup === "Doctor"
    ) {
      await updatePanelUser(body, namePath);
      history.push("/dashboard/ts/users");
    } else if (
      userGroup === "Phlebos"
    ) {
      await updatePanelUser(body, namePath);
      history.push("/dashboard/ts/managephlebos");
    } else {
      await updatePanelUser(body, namePath);
      history.push(`/dashboard/ts/manageOtherUsers`);
    }
  };
  useEffect(() => {
    if (id) {
      getUserEdit(`${id}/`);
    }
  }, [id]);

  useEffect(() => {
    if (userEditData) {
      const list = userEditData;
      setClickToCall(list?.ctc_from);
      setIsActive(list?.user?.is_active);
      setReportingLocation(list?.reporting_location);
      setUserType(list?.usertype);
      setTeamLead(list?.asigned?.map((list: any) => list?.id));
      setAssignDef(list?.asigned);
      setUserName(list?.user?.username);
      setEmail(list?.user?.email || "");
      setUserGroup(list?.user?.usergroup);
      setName(list?.user?.name);
      setPhoneNumber(list?.user?.phonenumber);
      setEmployeeId(list?.empid);
      setExecutiveType(list?.type);
      setAmeyoUsername(list?.extension_2 !== null ? list?.extension_2 : "");
      setAmeyoPassword(list?.extension_3 !== null ? list?.extension_3 : "");
      setOzontelId(list?.skills?.map((val: any) => val?.id));
      setozontel(list?.skills);
      setChatSkills(list?.chat_skills?.map((val: any) => val?.id));
      setChatSkilldef(list?.chat_skills);
      setOrgType(list && list?.org_types?.map((val: any) => val?.id));
      setDefOrgType(list?.org_types);
      setDailerNameDef(list?.dailer?.campaign_name);
      setDialers(list?.dailer?.id);
      setLanguages(list?.languages?.map((val: any) => val?.iso_639_1));
      setLanguagesDef(list?.languages);
      setCentre(list?.center?.id || "");
      setCentreDef(list?.center?.name || "");
      setIsEdit(true);
      setKey(!key);
      setUserTypes(list && list?.user_types?.map((val: any) => val?.id));
    }
  }, [userEditData]);
  return (
    <div>
      <form onSubmit={submitHandler}>
        <Paper style={{ marginTop: "60px" }}>
          <div className={classes.contentTable}>
            <Grid container spacing={4} style={{ margin: "1rem auto" }}>
              <h4 style={{ marginTop: "20px" }}> Login Information -</h4>
              <Grid
                container
                spacing={1}
                style={{ margin: "1rem auto", marginTop: "2px" }}
              >
                <Grid item xs={12} md={3}>
                  <h6>
                    Username <sup>*</sup>{" "}
                  </h6>
                  <TextField
                    className="input"
                    name="title"
                    type="text"
                    placeholder="Enter Username"
                    value={username}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Email</h6>
                  <TextField
                    className="input"
                    name="customer_email"
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setEmail(e.target.value as String)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    User Group<sup>*</sup>{" "}
                  </h6>
                  <Autocomplete
                    id="users_list"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setUserGroup(obj.name);
                      }
                    }}
                    options={usersList?.results || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    inputValue={userGroup || ""}
                    getOptionLabel={(option: any) => option?.id && option?.name}
                    onInputChange={(event, newInputValue) => {
                      setUserGroup(newInputValue);
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getAllUsers(`?name=${newInputValue}`);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Enter User Group"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "1px" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    Password <sup>*</sup>{" "}
                  </h6>
                  <TextField
                    className="input"
                    name="title"
                    type="text"
                    placeholder="Enter Password"
                    value={password}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
              <h5>Employee Information -</h5>
              <Grid
                container
                spacing={2}
                style={{ margin: "1rem auto", marginTop: "2px" }}
              >
                <Grid item xs={12} md={3}>
                  <h6>
                    Full Name <sup>*</sup>{" "}
                  </h6>
                  <TextField
                    className="input"
                    name="title"
                    type="text"
                    placeholder="Enter FullName"
                    value={name}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Phone Number</h6>
                  <TextField
                    className="input"
                    name="customer_phonenumber"
                    type="text"
                    value={phoneNumber}
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setPhoneNumber(e.target.value as String)}
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        phoneNumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.phoneNumber && "Incorrect Phone Number"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Employee Id</h6>
                  <TextField
                    className="input"
                    name="employee_id"
                    type="text"
                    placeholder="Enter Employee Id"
                    value={employeeId || ""}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setEmployeeId(e.target.value)}
                  />
                </Grid>
              </Grid>
              <h5>Panel User Information -</h5>
              <Grid
                container
                spacing={3}
                style={{ margin: "1rem auto", marginTop: "2px" }}
              >
                <Grid item xs={12} md={3}>
                  <h6>User Types </h6>
                  <Autocomplete
                    id="User_Types"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        // sourceIds = obj.map((item: any) => item.id);
                        let storeData: any = [];
                        obj?.map((list: any) => {
                          storeData.push(list?.id);
                        });
                        setUserTypes(storeData);
                      }
                    }}
                    options={userTypesList?.results || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    multiple
                    // onKeyDown={onKeyDown}
                    getOptionLabel={(option: any) => {
                      return option?.name;
                    }}
                    getOptionDisabled={(option: any) => {
                      return userTypes?.includes(option?.id);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getUserTypes(newInputValue);
                      }, 1000);
                      if (newInputValue?.length === 0) {
                        setUserTypes([]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="User Types"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "1px",
                        }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        onKeyDown={(e) => {
                          blockCharInAutocompleteField(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    User Type <sup>*</sup>{" "}
                  </h6>
                  <Select
                    className="input"
                    name="usertype"
                    variant="outlined"
                    value={userType || ""}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setUserType(e.target.value as String)}
                  >
                    <MenuItem disabled value={"none"}>
                      User Type
                    </MenuItem>
                    <MenuItem value={"b2c"}>B2C</MenuItem>
                    <MenuItem value={"b2b"}>B2B</MenuItem>
                    <MenuItem value={"both"}>BOTH</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    Org Type<sup>*</sup>{" "}
                  </h6>
                  {id ? (
                    <Autocomplete
                      id="Org_Type"
                      key={key}
                      multiple
                      limitTags={1}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let sourceIds: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            sourceIds.push(obj.id);
                          });
                          setOrgType(sourceIds);
                        }
                      }}
                      defaultValue={defOrgType}
                      options={orgTypesList?.results || []}
                      getOptionLabel={(option: any) => {
                        return option?.name;
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getOrgTypes(newInputValue);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Org Types"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                          onKeyDown={(e) => {
                            blockCharInAutocompleteField(e);
                          }}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Assigned</h6>
                  {id ? (
                    <Autocomplete
                      id="team lead"
                      key={key}
                      multiple
                      limitTags={1}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let sourceIds: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            sourceIds.push(obj.id);
                          });
                          setTeamLead(sourceIds);
                        }
                      }}
                      defaultValue={assignDef}
                      options={teamLeadList?.results || []}
                      getOptionLabel={(option: any) => option?.user?.username}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPanelUserListTeamLead(`?code=${newInputValue}`);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Team Lead"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Center</h6>
                  <Autocomplete
                    id="center"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCentre(obj.id);
                      }
                    }}
                    freeSolo
                    blurOnSelect
                    options={centreData?.results || []}
                    inputValue={centreDef || ""}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) =>
                      (option?.display_name === null
                        ? option?.name
                        : option?.display_name) +
                      (option?.center_code
                        ? "(" + option?.center_code + ")"
                        : "")
                    }
                    onInputChange={(event, newInputValue) => {
                      setCentreDef(newInputValue);
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getCentre(`?name_or_code=${newInputValue}`);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Center"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <h6>
                    Reporting Location<sup>*</sup>{" "}
                  </h6>
                  <Select
                    className="input"
                    name="ReportingLocation"
                    variant="outlined"
                    value={reportingLocation}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) =>
                      setReportingLocation(e.target.value as String)
                    }
                  >
                    <MenuItem disabled value={"none"}>
                      ReportingLocation
                    </MenuItem>
                    <MenuItem value={"Gurugram"}>Gurugram</MenuItem>
                    <MenuItem value={"Noida"}>Noida</MenuItem>
                    <MenuItem value={"GGN2"}>GGN2</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                   <h6>Executive Type</h6>
            <Autocomplete
              id="ExecutiveTypeFilter"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setExecutiveType(obj.value);
                }
              }}
              options={[
                {
                  text: "RCA",
                  value: "RCA",
                },
                {
                  text: "Resampling",
                  value: "Resampling",
                },
                {
                  text: "Report",
                  value: "Report",
                },
                {
                  text: "Refund",
                  value: "Refund",
                },
                {
                  text: "Admin",
                  value: "Admin",
                },
                {
                  text: "Discount",
                  value: "Discount",
                },
                {
                  text: "Ticketing",
                  value: "Ticketing",
                },
                {
                  text: "Consultation",
                  value: "Consultation",
                },
                {
                  text: "North Zone",
                  value: "North Zone",
                },
                {
                  text: "South Zone",
                  value: "South Zone",
                },
                {
                  text: "West Zone",
                  value: "West Zone",
                },
                {
                  text: "East Zone",
                  value: "East Zone",
                },
                {
                  text: "CEGenomics",
                  value: "CEGenomics",
                },
                {
                  text: "Inbound",
                  value: "Inbound",
                },
                {
                  text: "Outbound",
                  value: "Outbound",
                },
                {
                  text: "LTV",
                  value: "LTV",
                },
                {
                  text: "CoCal",
                  value: "CoCal",
                },
                {
                  text: "Dialer",
                  value: "Dialer",
                },
                {
                  text: "OBH1",
                  value: "OBH1",
                },
                {
                  text: "OBH2",
                  value: "OBH2",
                },
                {
                  text: "Lab",
                  value: "Lab",
                },
                {
                  text: "CentralLab",
                  value: "CentralLab",
                },
                {
                  text: "Escalation",
                  value: "Escalation",
                },
                {
                  text: "ChatAndEmail",
                  value: "ChatAndEmail",
                },
                {
                  text: "CASH",
                  value: "CASH",
                },
                {
                  text: "NONCASH",
                  value: "NONCASH",
                },
                {
                  text: "ABM/ASM",
                  value: "ABM/ASM",
                },
                {
                  text: "SE/TM",
                  value: "SE/TM",
                },
                {
                  text: "RBM/RSM",
                  value: "RBM/RSM",
                },
                {
                  text: "ZSM",
                  value: "ZSM",
                },
                {
                  text: "AGM SALES",
                  value: "AGM SALES",
                },
                {
                  text: "GM SALES",
                  value: "GM SALES",
                },
                {
                  text: "UpgradedCE",
                  value: "UpgradedCE",
                },
                {
                  text: "B2B_CX",
                  value: "B2B CX",
                },
                {
                  text: "InvLabDr",
                  value: "Inventory Lab Dr.",
                },
                {
                  text: "InvLabService",
                  value: "Inventory Lab Service",
                },
                {
                  text: "InvLabHead",
                  value: "Inventory Lab Head",
                },
                {
                  text: "SuperAdmin",
                  value: "SuperAdmin",
                },
                {
                  text: "BML",
                  value: "BML",
                },
                {
                  text: "standard",
                  value: "Standard",
                },
                {
                  text: "corporate_app",
                  value: "Corporate App",
                },
                {
                  text: "FINANCE",
                  value: "Finance",
                },
                {
                  text: "OLCP",
                  value: "Olc Partner",
                },
                {
                  text: "olc",
                  value: "olc",
                },
                {
                  text: "DSA",
                  value: "DSA",
                },
                {
                  text: "Manager",
                  value: "Manager",
                },
                {
                  text: "AreaManager",
                  value: "AreaManager",
                },
                {
                  text: "PPMC",
                  value: "PPMC",
                },
                {
                  text: "DRM",
                  value: "DRM",
                },
                {
                  text: "Contract",
                  value: "Contract",
                },
                {
                  text: "Auditor",
                  value: "Auditor",
                },
                {
                  text: "Genomics",
                  value: "Genomics",
                },
                {
                  text: "GenomicsAdmin",
                  value: "GenomicsAdmin",
                },
                {
                  text: "Routine",
                  value: "Routine",
                },
                {
                  text: "RoutineAdmin",
                  value: "RoutineAdmin",
                },
                {
                  text: "billing",
                  value: "billing",
                },
                {
                  text: "phlebos",
                  value: "phlebos",
                },
                {
                  text: "SampleAccessioning",
                  value: "SampleAccessioning",
                },
                {
                  text: "Others",
                  value: "Others",
                },
              ]}
              // freeSolo
              blurOnSelect
              aria-required
              inputValue={executiveType || ""} 
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  setExecutiveType(newInputValue);
                },);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Executive Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
                {/* <Grid item xs={12} md={3}>
                  <h6>Executive Type</h6>
                  <Select
                    id="ExecutiveTypeFilter"
                    className="ExecutiveTypes"
                    variant="outlined"
                    value={executiveType || ""}
                    style={{
                      width: "100%",
                      minWidth: "150px",
                      textAlign: "left",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setExecutiveType(e.target.value as string)}
                  >
                    <MenuItem value={"none"} disabled>
                      Executive Type
                    </MenuItem>
                    {ADDUSER_EXECUTIVE_TYPES &&
                      ADDUSER_EXECUTIVE_TYPES.map((exe_type: any) => {
                        return (
                          <MenuItem value={exe_type.value}>
                            {exe_type.value}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid> */}

                <Grid item xs={12} md={3}>
                  <h6>Chat Skills</h6>
                  {id ? (
                    <Autocomplete
                      id="team lead"
                      key={key}
                      multiple
                      limitTags={1}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let sourceIds: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            sourceIds.push(obj.id);
                          });
                          setChatSkills(sourceIds);
                        }
                      }}
                      defaultValue={chatSkilldef || []}
                      options={chatSkillsList?.results || []}
                      getOptionLabel={(option: any) => {
                        return option?.name;
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getChatSkillsList(newInputValue);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Chat Skills"
                          variant="outlined"
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "1px",
                          }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                          onKeyDown={(e) => {
                            blockCharInAutocompleteField(e);
                          }}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Ozontel Skill</h6>
                  {id ? (
                    <Autocomplete
                      id="team lead"
                      key={key}
                      multiple
                      limitTags={1}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let sourceIds: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            sourceIds.push(obj.id);
                          });
                          setOzontelId(sourceIds);
                        }
                      }}
                      defaultValue={ozontelName}
                      options={ozontelskilldata?.results || []}
                      getOptionLabel={(option: any) =>
                        String(option?.id) && String(option?.name)
                      }
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getOzontelSkillsData(`?search=${newInputValue}`);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Ozontel Skill"
                          variant="outlined"
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "1px",
                          }}
                          className="input"
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Dialer</h6>
                  <Autocomplete
                    id="dialer"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setDialers(obj.id);
                      }
                    }}
                    options={dialer?.results || []}
                    inputValue={dailerNameDef || ""}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      option?.did && option?.campaign_name
                    }
                    onInputChange={(event, newInputValue) => {
                      setDailerNameDef(newInputValue);
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getDialer(`?campaign_name=${newInputValue}`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setDialers("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Dialer"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "1px",
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <h6>
                    Click To Call<sup>*</sup>{" "}
                  </h6>
                  <Select
                    className="input"
                    name="sampleregisterd"
                    variant="outlined"
                    value={clicktoCall || ""}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={
                      (e) => setClickToCall(e.target.value as String)
                      //handleshowing(e)
                    }
                  >
                    <MenuItem disabled value={"none"}>
                      Click To Call
                    </MenuItem>
                    <MenuItem value={"ELISION_BML"}>ELISION_BML</MenuItem>
                    <MenuItem value={"ELISION_GSM"}>ELISION_GSM</MenuItem>
                    <MenuItem value={"AMEYOCALL"}>AMEYOCALL</MenuItem>
                    <MenuItem value={"TATATELEOFFLINE"}>
                      TATATELE OFFLINE
                    </MenuItem>
                    <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                  </Select>
                </Grid>
                {clicktoCall === "AMEYOCALL" ? (
                  <>
                    <Grid item xs={12} md={3}>
                      <h6>Ameyo Username</h6>
                      <TextField
                        className="input"
                        name="title"
                        type="text"
                        placeholder="Enter AMEYOCALL"
                        value={ameyoUsername}
                        variant="outlined"
                        inputProps={{ maxLength: 35 }}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "1px",
                        }}
                        onChange={(e) => {
                          setAmeyoUsername(e.target.value);
                          inputAlphaNumeric(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h6>
                        Password <sup>*</sup>{" "}
                      </h6>
                      <TextField
                        className="input"
                        name="title"
                        type="text"
                        placeholder="Enter Password"
                        value={ameyoPassword}
                        variant="outlined"
                        inputProps={{ maxLength: 35 }}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "1px",
                        }}
                        onChange={(e) => {
                          setAmeyoPassword(e.target.value);
                        }}
                        required
                      />
                    </Grid>
                  </>
                ) : clicktoCall == "ELISION_BML" ||
                  clicktoCall === "ELISION_GSM" ? (
                  <Grid item xs={12} md={3}>
                    <h6>Ameyo Username</h6>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter AMEYOCALL"
                      value={ameyoUsername}
                      variant="outlined"
                      inputProps={{ maxLength: 35 }}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "1px",
                      }}
                      onChange={(e) => {
                        setAmeyoUsername(e.target.value);
                        inputAlphaNumeric(e);
                      }}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12} md={3}>
                  <h6>Language</h6>
                  {id ? (
                    <Autocomplete
                      id="language"
                      key={key}
                      multiple
                      limitTags={1}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let sourceIds: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            sourceIds.push(obj.iso_639_1);
                          });
                          setLanguages(sourceIds);
                        }
                      }}
                      defaultValue={languagesDef}
                      options={language?.results || []}
                      getOptionLabel={(option: any) => {
                        return option?.name_en;
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getLanguage(newInputValue);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Language"
                          variant="outlined"
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "1px",
                          }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                          onKeyDown={(e) => {
                            blockCharInAutocompleteField(e);
                          }}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <h6>Is Active</h6>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={isactive}
                    label="IsActive"
                    onChange={(e) => setIsActive(!isactive)}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ padding: "20px", textAlign: "center" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ width: 300 }}
                  disabled={loading || !mobileValidation.phoneNumber}
                >
                  Update User
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  lead_source: state.TechSupportReducer.lead_source,
  teamLeadList: state.TechSupportReducer.teamLeadList,
  chatSkillsList: state.TechSupportReducer.chatSkillsList,
  language: state.TechSupportReducer.language,
  orgTypesList: state.TechSupportReducer.orgTypesList,
  dialer: state.TechSupportReducer.dialer,
  ozontelskilldata: state.TechSupportReducer.ozontelskilldata,
  usersList: state.TechSupportReducer.usersList,
  userData: state.TechSupportReducer.userData,
  userEditData: state.TechSupportReducer.userEditData,
  centreData: state.TechSupportReducer.centreData,
  userTypesList: state.TechSupportReducer.userTypesList,
});

export default connect(mapStateToProps, {
  updatePanelUser,
  getLeadSource,
  createPanelUser,
  getPanelUserListTeamLead,
  getLanguage,
  getOzontelSkillsData,
  getDialer,
  getOrgTypes,
  getChatSkillsList,
  getAllUsers,
  getUserEdit,
  getCentre,
  getUserTypes,
})(CreateUser);
