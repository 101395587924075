import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Relevent from "../components/Fill-Info";
import OtherUpdates from "../components/Other-Info";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MessageRounded } from "@material-ui/icons";
import {
  getLeadDetails,
  getReportDownload,
  getReportStatus,
  getBookingQueryTickets,
  getBookingById,
} from "../actions/TechSupportActions";
import ReportModal from "../components/Comments/reportStatusModal";
import Loader from "../components/loader";
import CommentHistory from "../components/comment-history";
import BookingComments from "../components/Comments/bookingComments";
import moment from "moment";
import AdditonalFamilyModal from "../tables/AdditionalFamily/AdditionFamilyDetails";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RaiseComplaintModal from "../components/Comments/raiseTicketModal";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import CleanHandsIcon from "@mui/icons-material/CleanHands";
import BookingDisposition from "../components/Comments/bookingDisposition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
     
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      overflow: "scroll",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  children: any;
  getLeadDetails: Function;
  lead_details: any;
  reportDownload: any;
  loading: boolean;
  getReportDownload: any;
  reportDownloadingLoader: any;
  getReportStatus: any;
  bookingComplaints: any;
  reportStatus: any;
  getBookingQueryTickets: any;
  ticketLoader: boolean;
  getBookingById: any;
  booking: any;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TechSupportFillInfoPage: React.FC<Props> = ({
  getLeadDetails,
  lead_details,
  reportDownload,
  loading,
  getReportDownload,
  getReportStatus,
  reportDownloadingLoader,
  bookingComplaints,
  reportStatus,
  getBookingQueryTickets,
  getBookingById,
  ticketLoader,
  booking,
}) => {
  const classes = useStyles();
  let query = useQuery();
  const [id, setId] = React.useState<any>(query.get("id"));
  const [bookingId, setBookingId] = useState<any>("");
  const [openModal, setopenModal] = useState<boolean>(false);
  const [additionalMember, setAdditionalMember] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  const [personId, setPersonId] = React.useState("none");
  const [memberType, setMemberType] = useState<any>("none");
  const [memberDetails, setMemberDetails] = useState<any>();
  const [billId, setBillId] = useState<any>("");
  const [additionalBillId, setAdditionalBillId] = useState<any>("");
  const [bookingIdReport, setBookingIdReport] = useState<any>("");
  const [openReportModal, setReportModal] = useState(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );

  useEffect(() => {
    if (personId !== "none") {
      if (bookingIdReport === personId) {
        if (billId === null || billId === "") {
          getReportDownload(`${bookingIdReport}/`);
        } else {
          alert("Bill Id is Not Generated.");
          setBillId("");
        }
      } else if (bookingIdReport !== personId) {
        if (additionalBillId === null || additionalBillId === "") {
          getReportDownload(`${personId}/?additional_id=true`);
        } else {
          alert("Family Member Bill Id is Not Generated.");
          setAdditionalBillId("");
        }
      }
    }
  }, [personId, bookingIdReport]);

  useEffect(() => {
    if (booking?.pk == bookingId && booking?.cityid) {
      setTotalPrice(booking.discounted_price?.final_total_price);
    }
  }, [booking]);

  const handleReportStatus = () => {
    if (personId !== "none") {
      if (bookingIdReport === personId) {
        if (billId !== null && billId !== "") {
          getReportStatus(`?booking_id=${bookingIdReport}`);
          setReportModal(true);
        } else {
          alert("Bill Id is Not Generated.");
        }
      } else if (bookingIdReport !== personId) {
        if (additionalBillId !== null && additionalBillId !== "") {
          getReportStatus(`?add_member_booking=${personId}`);
          setReportModal(true);
        } else {
          alert("Family Member Bill Id is Not Generated.");
        }
      }
    }
  };
  useEffect(() => {
    if (
      Number(query.get("id")!) !== 0 &&
      query.get("id")! !== null &&
      query.get("id")! !== undefined &&
      query.get("id")! !== "undefined"
    ) {
      getLeadDetails(query.get("id")!);
      setId(query.get("id")!);
    }
  }, []);

  const handleClick = (bookingid: number) => {
    setopenModal(true);
    setBookingId(bookingid);
  };

  const handleAdditionalView = (member: any) => {
    setAdditionalMember(member);
    setAdditonalModal(true);
  };

  const handleChange = (event: any, data: any) => {
    const additionDetails = data?.additional_members.find(
      (bId: any) => bId.id === event.target.value
    );
    additionDetails?.id === event.target.value
      ? setBookingIdReport("")
      : setBookingIdReport(event.target.value);
    setMemberType("main");
    setBillId(data?.bill_id);
    setMemberType("additional");
    setAdditionalBillId(additionDetails?.bill_id);
    setMemberDetails(additionDetails);
    setPersonId(event.target.value);
  };

  const handleDownloadReport = (report: any) => {
    if (report.message === "Your report is not ready yet") {
      alert(report.message);
    } else {
      var b64 = report?.allReportDetails?.reportDetails;
      var bin = window.atob(b64);
      var link = document.createElement("a");
      link.download = `${report.allReportDetails.Patient_Name}-${report.allReportDetails.Age}.pdf`;
      link.href = "data:application/octet-stream;base64," + b64;
      link.click();
    }
  };
  useEffect(() => {
    if (raiseComplaint) {
      getBookingQueryTickets(`?booking_id=${bookingId}`);
      getBookingById(`${bookingId}`);
    }
  }, [raiseComplaint]);

  const handleRaiseTicket = (bookingid: number) => {
    setRaiseComplaint(!raiseComplaint);
    setBookingId(bookingid);
  };
  const handleOpenDisposition = (bookingid: number) => {
    setOpen(!open);
    setBookingId(bookingid);
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box>
          <br />
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              {!lead_details ? (
                <Loader />
              ) : Object.keys(lead_details).length > 0 ? (
                <>
                  <Relevent id={id} data={lead_details} />
                  <OtherUpdates id={id} data={lead_details} />
                  <div style={{ width: "100%" }}>
                    <Paper elevation={15} className={classes.paper}>
                      <TableContainer className={classes.table}>
                        {loading ? (
                          <Loader />
                        ) : (
                          <Table aria-label="simple table">
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell align="center">
                                  Action
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Booking Id
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Booking Status
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Verification Status
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Comments
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Raise A Ticket
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Dispose Booking
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Booking Date
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Name
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Agent
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Age
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Gender
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Email
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Report Status
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Report Modal
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Select Patient Name
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Report Download
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Collection Date
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Collection Time
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Zone Name
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Mobile Number
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {lead_details.bookings &&
                                lead_details.bookings.length > 0 &&
                                lead_details.bookings.map(
                                  (booking: any, index: any) => {
                                    const pendingData =
                                      booking?.additional_members?.filter(
                                        (data: any) =>
                                          data?.pickup_status === "pending"
                                      );
                                    const confirmedData =
                                      booking?.additional_members?.filter(
                                        (data: any) =>
                                          data?.pickup_status === "confirmed"
                                      );
                                    let addId =
                                      booking?.additional_members?.filter(
                                        (data: any) => data?.id === personId
                                      );
                                    return (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell align="center">
                                          <Link
                                            to={`/dashboard/ts/booking-view/${booking.pk}`}
                                            style={{ textDecoration: "none" }}
                                          >
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                            >
                                              View
                                            </Button>
                                          </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking?.additional_members?.length >
                                          0 ? (
                                            <div>
                                              {booking?.pk}+
                                              <Chip
                                                label={
                                                  "T" +
                                                  booking?.additional_members
                                                    ?.length +
                                                  `${
                                                    confirmedData?.length > 0
                                                      ? "C" +
                                                        confirmedData.length
                                                      : ""
                                                  }` +
                                                  `${
                                                    pendingData?.length > 0
                                                      ? "P" +
                                                        pendingData?.length
                                                      : ""
                                                  }`
                                                }
                                                style={{
                                                  background:
                                                    pendingData?.length > 0
                                                      ? "#e91e63"
                                                      : "#1b5e20",
                                                }}
                                                onClick={() =>
                                                  handleAdditionalView(
                                                    booking?.additional_members
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : (
                                            booking?.pk
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <Chip
                                            label={`${booking.booking_status}`}
                                            style={{
                                              background:
                                                booking.booking_status ===
                                                "pending"
                                                  ? "#ffee58"
                                                  : booking.booking_status ===
                                                    "confirmed"
                                                  ? "#00e676"
                                                  : booking.booking_status ===
                                                    "cancelled"
                                                  ? "#ff3d00"
                                                  : "#d500f9",
                                            }}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <Chip
                                            label={`${booking.verification_status}`}
                                            style={{
                                              background:
                                                booking.verification_status ===
                                                "pending"
                                                  ? "#ffee58"
                                                  : booking.verification_status ===
                                                    "confirmed"
                                                  ? "#00e676"
                                                  : booking.verification_status ===
                                                    "cancelled"
                                                  ? "#ff3d00"
                                                  : "#d500f9",
                                            }}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <div>
                                            <MessageRounded
                                              onClick={() =>
                                                handleClick(booking.pk)
                                              }
                                              style={{ color: "#6a1b9a" }}
                                            />
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <div>
                                            <LocalActivityIcon
                                              onClick={() =>
                                                handleRaiseTicket(booking.pk)
                                              }
                                              style={{ color: "#3f51b5" }}
                                            />
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <div>
                                            <CleanHandsIcon
                                              onClick={() =>
                                                handleOpenDisposition(
                                                  booking.pk
                                                )
                                              }
                                              style={{ color: "#004d40" }}
                                            />
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.booking_date}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.customer_name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {(booking.agent &&
                                            booking.agent.name) ||
                                            "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.customer_age}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.customer_gender}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.customer_email}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.report_status}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <Button
                                            onClick={handleReportStatus}
                                            variant="contained"
                                            // disabled={personId === "none"}
                                            disabled={
                                              (addId[0]?.id !== personId &&
                                                personId !== booking?.pk) ||
                                              personId === "none"
                                            }
                                            style={{
                                              marginLeft: "1rem",
                                              marginBottom: "1rem",
                                              backgroundColor: "#ffbf00",
                                            }}
                                          >
                                            STATUS
                                          </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <Select
                                            className="input"
                                            id={`${booking?.pk}+${booking?.created_at}`}
                                            name="priority"
                                            variant="outlined"
                                            style={{
                                              width: "100%",
                                              marginLeft: "1rem",
                                              marginBottom: "1rem",
                                              alignItems: "flex-start",
                                            }}
                                            value={
                                              personId === booking?.pk
                                                ? personId
                                                : addId[0]?.id === personId
                                                ? personId
                                                : "none"
                                            }
                                            onChange={(e: any) => {
                                              // setBookingIdReport(e.target.value)
                                              handleChange(e, booking);
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              selected
                                              value={"none"}
                                            >
                                              Customer Name
                                            </MenuItem>
                                            <MenuItem value={booking?.pk}>
                                              {booking?.customer_name}
                                            </MenuItem>
                                            {booking.additional_members &&
                                              booking.additional_members
                                                .length > 0 &&
                                              booking.additional_members.map(
                                                (data: any) => {
                                                  return (
                                                    <MenuItem value={data?.id}>
                                                      {data?.customer_name}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <Button
                                            disabled={
                                              (addId[0]?.id !== personId &&
                                                personId !== booking?.pk) ||
                                              personId === "none" ||
                                              booking.report_status === "na" ||
                                              booking.report_status ==
                                                "pending" ||
                                              booking.report_status == "none" ||
                                              booking.report_status == null ||
                                              reportDownloadingLoader
                                            }
                                            onClick={() =>
                                              handleDownloadReport(
                                                reportDownload
                                              )
                                            }
                                            // loading={loadingButton}
                                            style={{
                                              marginLeft: "1rem",
                                              marginBottom: "1rem",
                                              background: "#90caf9",
                                            }}
                                            // loadingPosition="start"
                                            startIcon={
                                              <DownloadForOfflineIcon />
                                            }
                                            variant="outlined"
                                          >
                                            Download
                                          </Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.collection_date}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.collection_slot &&
                                            moment(
                                              `${
                                                booking.collection_slot.slot.split(
                                                  "-"
                                                )[0]
                                              }`,
                                              ["HH.mm.ss"]
                                            ).format("hh:mm A")}
                                          {" - "}
                                          {booking.collection_slot &&
                                            moment(
                                              `${
                                                booking.collection_slot.slot.split(
                                                  "-"
                                                )[1]
                                              }`,
                                              ["HH.mm.ss"]
                                            ).format("hh:mm A")}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.customer_areapincode.area}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking?.customer_phonenumber
                                            ? `XXXXXX${booking?.customer_phonenumber.slice(
                                                -4
                                              )}`
                                            : ""}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    );
                                  }
                                )}
                            </TableBody>
                          </Table>
                        )}
                      </TableContainer>
                      {lead_details.bookings &&
                        lead_details.bookings.length === 0 && (
                          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                        )}
                    </Paper>
                   
                  </div>
                </>
              ) : (
                "NA"
              )}
            </Grid>
          </Container>
          <br />
          <CommentHistory />
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "500px" }}
          >
            {ticketLoader ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                    <StyledTableCell align="center">Child Id</StyledTableCell>
                    <StyledTableCell align="center">Agent</StyledTableCell>
                    <StyledTableCell align="center">Created At</StyledTableCell>
                    <StyledTableCell align="center">Query Type</StyledTableCell>
                    <StyledTableCell align="center">
                      Query Description
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Receiver Remarks
                    </StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">View</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {bookingComplaints?.results &&
                    bookingComplaints?.results.length > 0 &&
                    bookingComplaints?.results.map(
                      (ticket: any, index: any) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">
                              {ticket?.booking !== null
                                ? ticket?.booking?.id || ticket?.booking?.pk
                                : ticket?.add_booking?.mainbooking}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ticket?.booking === null
                                ? ticket?.add_booking.id
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ticket.panel_user && ticket.panel_user.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {new Date(ticket.created_at).toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ticket.querytype}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ticket.query}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ticket.receicer_remarks}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ticket.status}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Link
                                to={`/dashboard/ts/booking-view/${
                                  ticket?.booking !== null
                                    ? ticket?.booking?.id || ticket?.booking?.pk
                                    : ticket?.add_booking?.mainbooking
                                }`}
                              >
                                <Button color="secondary" variant="contained">
                                  View
                                </Button>
                              </Link>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Box>

        <AdditonalFamilyModal
          additionalMemebr={additionalMember}
          additionalModal={additionalModal}
          setAdditonalModal={setAdditonalModal}
          loading={loading}
        />
        <ReportModal
          loading={loading}
          openReportModal={openReportModal}
          setReportModal={setReportModal}
          reportStatus={reportStatus}
        />
        <RaiseComplaintModal
          open={raiseComplaint}
          setOpen={setRaiseComplaint}
          bookingId={bookingId}
          getBookingQueryTickets={getBookingQueryTickets}
          bookingComplaints={bookingComplaints}
          leadId={0}
          totalPrice={totalPrice}
          booking={booking}
          complaints={undefined}
        />
        <BookingDisposition
          open={open}
          setOpen={setOpen}
          bookingId={bookingId}
        />
      </main>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  lead_details: state.TechSupportReducer.lead_details,
  reportDownload: state.TechSupportReducer.reportDownload,
  loading: state.TechSupportReducer.loading,
  reportDownloadingLoader: state.TechSupportReducer.reportDownloadingLoader,
  bookingComplaints: state.TechSupportReducer.bookingComplaints,
  reportStatus: state.TechSupportReducer.reportStatus,
  ticketLoader: state.TechSupportReducer.ticketLoader,
  booking: state.TechSupportReducer.booking,
});

export default connect(mapStateToProps, {
  getLeadDetails,
  getReportDownload,
  getReportStatus,
  getBookingQueryTickets,
  getBookingById,
})(TechSupportFillInfoPage);
