import React, { useState } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import {
  Button,
  Paper,
  TextField,
  Grid,
  Chip,
  TableContainer,
} from "@mui/material";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  TableFooter,
  TablePagination,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Loader from "../../components/loader";
import { createLabUser, getLabCredential, getLabUser, getRiderList, updateLabUser } from "../../actions/TechSupportActions";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    fontWeight: {
      fontWeight: "bold",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "1rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -9,
      marginLeft: -9,
    },
  })
);

interface Props {
  createLabUser: any;
  loading: boolean;
  getRiderList: any;
  riderList: any;
  updateLabUser: any;
  getLabCredential: any;
  labCredential: any;
  getLabUser: any;
  labUser: any;
}
const UserLabRights: React.FC<Props> = ({
  createLabUser,
  loading,
  getRiderList,
  riderList,
  updateLabUser,
  getLabCredential,
  labCredential,
  getLabUser,
  labUser,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getRiderList();
    getLabCredential();
    getLabUser();
  }, []);
  const timer = useRef<any>(0);
  const history = useHistory();
  const [lab, setLab] = useState<any>("");
  const [labName, setLabName] = useState<any>("");
  const [user, setUser] = useState<any>([]);
  const [userDef, setUserDef] = useState<any>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [dataId, setDataId] = useState<any>("");
  const [isEdit, setIsEdit] = useState<any>(false);

  const submitForm = async (e: any) => {
    e.preventDefault();

    const data: any = {
      lab_credentials: lab,
      panel_user: user,
      is_active: isActive,
    };
    if (!lab) {
      delete data.lab_credentials;
    }
    if (!user) {
      delete data.panel_user;
    }
    if (isEdit) {
      await updateLabUser(data, dataId);
    } else {
      await createLabUser(data);
    }
    getRiderList();
    handleClear();
  };
  const handleEdit = (list: any) => {
    setLabName(list?.lab_credentials.name);
    setUserDef(list?.panel_user);
    setUser(list?.panel_user.length>0&&list?.panel_user.map((val:any)=>val.id));
    setIsActive(list?.is_active);
    setDataId(list?.id);
    setIsEdit(true);
  };
  const handleClear = () => {
    history.push("/dashboard/ts/user_lab_rights");
  };

  const [pagePackageMaster, setpagePackageMaster] = useState<any>(0);
  const handlePackageMasterPagination = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pagePackageMaster) {
      let url = labUser.links && labUser.links.next.split("?")[1];
      getLabUser(`?${url}`);
    } else if (newPage < pagePackageMaster) {
      let url = labUser.links && labUser.links.previous.split("?")[1];
      getLabUser(`?${url}`);
    }
    setpagePackageMaster(newPage as number);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <form onSubmit={submitForm}>
        <Paper elevation={5} className={classes.paper}>
          <h4>User Lab Rights</h4>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                id="lab"
                key={dataId}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setLab(obj.id);
                  }
                }}
                freeSolo
                inputValue={labName}
                loading={loading}
                options={labCredential.results || []}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getLabCredential(`?name=${newInputValue}`);
                  }, 1000);

                  setLabName(newInputValue);
                  if (newInputValue === "") {
                    setLab("");
                  }
                }}
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => `${option?.name}`}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Lab"
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                id="user"
                key={dataId}
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.id);
                  }
                  setUser(sourceIds);
                }}
                options={riderList.results || []}
                multiple
                limitTags={1}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) =>
                  option.user.username
                    ? option?.user?.username
                    : option.user.name === undefined
                    ? option?.user
                    : ""
                }
                disableClearable
                disableCloseOnSelect
                defaultValue={userDef}
                getOptionDisabled={(option: any) => {
                  return user.length>0&& user.includes(option.id);
                }}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getRiderList(`?user__username__icontains=${newInputValue}`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setUser([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Users"
                    variant="outlined"
                    required={user.lenght===0?true:false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={2}>
              <FormControlLabel
                control={<Checkbox />}
                label="Is Active"
                labelPlacement="start"
                checked={isActive}
                onChange={(e) => setIsActive(!isActive)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                {isEdit ? "Update" : "Create"}
                {loading && <CircularProgress size={24} />}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClear}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      <Grid item xs={12}>
        <Paper style={{ marginTop: 15 }} elevation={5}>
          <Grid
            container
            spacing={3}
            className={classes.centerIt}
            style={{
              margin: "1rem auto",
            }}
          >
            <Grid item xs={12} sm={6} md={2}>
              <h5 style={{ marginTop: 15 }}>Search Option </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                className="input"
                label="Lab Name"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getLabUser(`?lab_name=${e.target.value}`);
                  }, 1000);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => handleClear()}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          <TableContainer style={{ height: 400 }}>
            {loading ? (
              <Loader />
            ) : (
              <Table size="small" stickyHeader aria-label="simple">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      S. No.
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                     Lab
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                     Users
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Is Active
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Edit
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {labUser.results &&
                    labUser.results.length > 0 &&
                    labUser.results.map((list: any, index: any) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.lab_credentials?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                        {list?.panel_user?.length > 0 &&
                            list?.panel_user?.map((val: any) => {
                              return <Chip size="small" label={val.user} />;
                            })}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {list?.is_active === true ? "Yes" : "No"}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            onClick={() => handleEdit(list)}
                          >
                            Edit
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            )}
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={labUser?.count}
                rowsPerPage={labUser?.page_size}
                page={pagePackageMaster}
                onPageChange={handlePackageMasterPagination}
              />
            </TableFooter>
          </TableContainer>
        </Paper>
      </Grid>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  riderList: state.TechSupportReducer.riderList,
  labCredential: state.TechSupportReducer.labCredential,
  labUser: state.TechSupportReducer.labUser,
  loading: state.TechSupportReducer.loading,
});
export default connect(mapStateToProps, {
  createLabUser,
  getRiderList,
  updateLabUser,
  getLabCredential,
  getLabUser,
})(UserLabRights);
