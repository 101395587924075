import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Fade, Backdrop } from "@mui/material";
import Loader from "../loader";
import "./index.sass";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "100%",
    zIndex: 1,
  },
  table: {
    maxWidth: "100%",
    margin: "auto",
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  listStyle: {
    marginLeft: "20px",
  },
}));

type ModalProps = {
  openModalP: boolean;
  setOpenModalP: Function;
  paymentInformation: any;
  loading: boolean;
};

const BrakModal: React.FC<ModalProps> = ({
  openModalP,
  setOpenModalP,
  paymentInformation,
  loading,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModalP(false);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalP}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={openModalP}>
          <div className={classes.paper}>
            <Paper elevation={15} className={classes.paper}>
              <TableContainer className={classes.table} component={Paper}>
                <h2>Payment Information</h2>
                {loading ? (
                  <Loader />
                ) : (
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">booking Id</TableCell>
                        <TableCell align="center">Payment Type</TableCell>
                        <TableCell align="center">Payment Status</TableCell>
                        <TableCell align="center">Payment Id</TableCell>
                        <TableCell align="center">Payment URL</TableCell>
                        <TableCell align="center">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentInformation &&
                        paymentInformation?.length > 0 &&
                        paymentInformation?.map((data: any, index: any) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="center">{data.id}</TableCell>
                              <TableCell align="center">
                                {data.link_type}
                              </TableCell>
                              <TableCell align="center">
                                {data.payment_status}
                              </TableCell>
                              <TableCell align="center">
                                {data.paymentid}
                              </TableCell>
                              <TableCell align="center">
                                <a
                                  href={data.payment_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {data.payment_url}
                                </a>
                              </TableCell>
                              <TableCell align="center">
                                {data.amount}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {})(BrakModal);
