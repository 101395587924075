import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid, Select, MenuItem, Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import {
  getUserData,
  getZone,
  getCities,
  getPackage,
  updatePartner,
  getPanelUserListTeamLead,
  getOrgTypes,
  getUserEdit,
  getCentre,
  getPartners,
} from "../../actions/TechSupportActions";
import { useHistory, useParams } from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  contentTable: {
    width: "100%",
    padding: "10px 0px 10px 0px",
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    width: "80%",
    flexGrow: 21,
  },
}));
type ModalProps = {
  userTableData: any;
  getUserData: any;
  zoneList: any;
  getZone: any;
  getCities: any;
  cities: any;
  getPackage: any;
  packageList: any;
  updatePartner: any;
  dialer: any;
  loading: any;
  teamLeadList: any;
  chatSkillsList: any;
  orgTypesList: any;
  getPanelUserListTeamLead: any;
  getOrgTypes: any;
  getUserEdit: any;
  getCentre: any;
  centreData: any;
  getPartners: any;
  partnerList: any;
};

const EditPartner: React.FC<ModalProps> = ({
  userTableData,
  getUserData,
  zoneList,
  getZone,
  getCities,
  cities,
  getPackage,
  packageList,
  loading,
  dialer,
  teamLeadList,
  chatSkillsList,
  orgTypesList,
  getPanelUserListTeamLead,
  getOrgTypes,
  updatePartner,
  getUserEdit,
  getCentre,
  centreData,
  getPartners,
  partnerList,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [userName, setUserName] = useState<any>("");
  const [partnerName, setPartnerName] = useState<String>("");
  const [partnerCode, setPartnerCode] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState<String>("");
  const [email, setEmail] = useState<String>();
  const [zoneName, setZoneName] = useState<any>(0);
  const [city, setCity] = useState<any>([]);
  const [userType, setUserType] = useState<any>("");
  const [orgType, setOrgType] = useState<any>("");
  const [teamLead, setTeamLead] = useState<any>([]);
  const [discount, setDiscount] = useState<String>("");
  const [centre, setCentre] = useState<any>("");
  const [cityId, setCityId] = useState<any>("");
  const [partnerKey, setPartnerKey] = useState<any>("");
  const [codeB65, setCodeB65] = useState<any>("");
  const [commission, setCommission] = useState<String>("");
  const [url, setUrl] = useState<String>("");
  const [smsSent, setSmsSent] = useState<any>("not_send");
  const [whatsappSent, setWhatsappSent] = useState<any>("not_send");
  const [emailSent, setEmailSent] = useState<any>("not_send");
  const [videoSent, setVideoSent] = useState<any>("not_send");
  const [isactive, setIsActive] = useState<boolean>(false);
  const [paymentSms, setPaymentSms] = useState<boolean>(false);
  const [bookingSms, setBookingSms] = useState<boolean>(false);
  const [reportSms, setReportSms] = useState<boolean>(false);
  const [isReportEmail, setIsReportEmail] = useState<boolean>(false);
  const [isReportWhatsapp, setIsReportWhatsapp] = useState<boolean>(false);
  const [agentCall, setAgentCall] = useState<boolean>(false);
  const [upgradePermitted, setUpgradePermitted] = useState<boolean>(false);
  const [isCreditButton, setIsCreditButton] = useState<boolean>(false);
  const [isPrice, setIsPrice] = useState<boolean>(false);
  const [isVerify, setIsVerify] = useState<boolean>(false);
  const [isSales, setIsSales] = useState<boolean>(false);
  const [isCredit, setIsCredit] = useState<boolean>(false);
  const [isInvoice, setIsInvoice] = useState<boolean>(false);
  const [isPha, setIsPha] = useState<boolean>(false);

  const [name, setName] = useState("");
  const [userGroup, setUserGroup] = useState<any>("");
  const [centreDef, setCentreDef] = useState<any>("");
  const [reportingLocation, setReportingLocation] = useState<any>("");
  const [employeeId, setEmployeeId] = useState<String>("");
  const [assignDef, setAssignDef] = useState<any>([]);
  const { id, namePath } = useParams<{ id: string; namePath: string }>();
  const [isEdit, setIsEdit] = useState<any>(false);
  const [defOrgType, setDefOrgType] = useState<any>("");
  const [key, setKey] = useState<any>(false);

  const timer = useRef<any>(0);
  const inputAlphaNumeric = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^ a-zA-Z_ ]+/gi, "")
      : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });
  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-0987654321`".includes(e.key) &&
      e.preventDefault()
    );
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };
  const submitHandler = async (e: any) => {
    e.preventDefault();
    const body: any = {
      username: userName,
      partner_name: partnerName,
      partner_code: partnerCode,
      partner_mobile: phoneNumber,
      partner_email: email,
      zone: zoneName,
      city: city,
      usertype: userType,
      org_type: orgType,
      asigned: teamLead,
      discount: discount,
      partner_key: partnerKey,
      center: centre,
      report_sms_sent_to: smsSent,
      report_whatsapp_sent_to: whatsappSent,
      report_email_sent_to: emailSent,
      video_sms_sent_to: videoSent,
      b65_code: codeB65,
      commission: commission,
      video_url: url,
      // is_active: isactive,
      is_report_whatsapp: isReportWhatsapp,
      is_report_email: isReportEmail,
      // is_shown_to_pha: isPha,
      is_invoice_email: isInvoice,
      // is_credit: isCredit,
      // is_shown_to_sales: isSales,
      // is_auto_verify: isVerify,
      // is_price_hide: isPrice,
      // is_credit_button_display: isCreditButton,
      // agent_can_call: agentCall,
      // upgrade_permitted: upgradePermitted,
      // is_booking_payment_sms: paymentSms,
      // is_booking_sms: bookingSms,
      is_report_sms: reportSms,
    };
    if (teamLead === "" || teamLead === "none") {
      delete body.asigned;
    }
    {
      await updatePartner(body, partnerName);
      history.push(`/dashboard/ts/partner/index`);
    }
  };

  useEffect(() => {
    getPartners(`${id}/`);
  }, []);

  useEffect(() => {
    if (partnerList) {
      const data = partnerList;
      setIsEdit(true);
      setKey(!key);
      setUserName(data?.user?.name);
      setPartnerName(data?.partner_name);
      setPartnerCode(data?.partner_code);
      setPhoneNumber(data?.partner_mobile);
      setEmail(data?.partner_email);
      setZoneName(data?.zone);
      setCity(data?.city);
      setUserType(data?.userType);
      setOrgType(data?.org_type);
      setTeamLead(data?.asigned);
      setDiscount(data?.discount);
      setPartnerKey(data?.partner_key);
      setSmsSent(data?.report_sms_sent_to);
      setWhatsappSent(data?.report_whatsapp_sent_to);
      setEmailSent(data?.report_email_sent_to);
      setVideoSent(data?.video_sms_sent_to);
      setCodeB65(data?.b65_code);
      setCommission(data?.commission);
      setUrl(data?.video_url);
      // setIsActive(data?.is_active);
      setIsReportWhatsapp(data?.is_report_whatsapp);
      setIsReportEmail(data?.is_report_email);
      // setIsPha(data?.is_shown_to_pha);
      setIsInvoice(data?.is_invoice_email);
      // setIsCredit(data?.is_credit);
      // setIsSales(data?.is_shown_to_sales);
      // setIsVerify(data?.is_auto_verify);
      // setIsPrice(data?.is_price_hide);
      // setIsCreditButton(data?.is_credit_button_display);
      // setAgentCall(data?.agent_can_call);
      // setUpgradePermitted(data?.upgrade_permitted);
      // setPaymentSms(data?.is_booking_payment_sms);
      // setBookingSms(data?.is_booking_sms);
      setReportSms(data?.is_report_sms);
    }
  }, [partnerList]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={10}>
            <h2>Edit Partner</h2>
          </Grid>
        </Grid>
        <form onSubmit={submitHandler}>
          <Paper style={{ marginTop: "30px" }}>
            <div className={classes.contentTable}>
              <Grid container spacing={4} style={{ margin: "1rem auto" }}>
                <Grid
                  container
                  spacing={1}
                  style={{ margin: "1rem auto", marginTop: "2px" }}
                >
                  <Grid item xs={12} md={3}>
                    <h6>
                      Username <sup>*</sup>{" "}
                    </h6>
                    <Autocomplete
                      id="username"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setUserName(obj?.uuid);
                        }
                      }}
                      options={userTableData?.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      inputValue={userName || ""}
                      limitTags={1}
                      // multiple
                      getOptionLabel={(option: any) => option.username}
                      getOptionDisabled={(option: any) => {
                        return userName.includes(option.id);
                      }}
                      disableClearable
                      disableCloseOnSelect
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getUserData(`?code=${newInputValue}`);
                        }, 1000);
                        if (newInputValue.length === 0) {
                          setUserName("");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Username"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Partner Name <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      fullWidth
                      className="input"
                      name="Partner Name"
                      type="text"
                      placeholder="Enter Partner Name"
                      value={partnerName}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setPartnerName(e.target.value as String)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Partner Code <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      fullWidth
                      className="input"
                      name="Partner Code"
                      type="text"
                      placeholder="Enter Partner Code"
                      value={partnerCode}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setPartnerCode(e.target.value as String)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Partner Mobile <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      className="input"
                      name="customer_phonenumber"
                      type="number"
                      value={phoneNumber}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      placeholder="Patner Number"
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setPhoneNumber(e.target.value as String)}
                      onBlur={() => verifyMobile("mobile")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          phoneNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.phoneNumber &&
                        "Incorrect Phone Number"
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    Partner Email <sup>*</sup>{" "}
                  </h6>
                  <TextField
                    fullWidth
                    className="input"
                    name="customer_email"
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setEmail(e.target.value as String)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    Zone <sup>*</sup>{" "}
                  </h6>
                  <Autocomplete
                    id="zonename"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setZoneName(obj.id);
                      }
                    }}
                    options={zoneList?.results || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => option.city.name}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getZone(`${newInputValue}`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setZoneName(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Zone"
                        variant="outlined"
                        style={{ width: "100%", height: "40px", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    City <sup>*</sup>{" "}
                  </h6>
                  <Autocomplete
                    id="city"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCity(obj.id);
                      }
                    }}
                    options={cities || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => option?.name}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getCities(newInputValue);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setCity(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="City Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    User Type <sup>*</sup>{" "}
                  </h6>
                  <Select
                    className="input"
                    name="usertype"
                    variant="outlined"
                    value={userType}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    required
                    onChange={(e) => setUserType(e.target.value as String)}
                  >
                    {/* <MenuItem disabled value={"none"}>
                    User Type
                  </MenuItem> */}
                    <MenuItem value={"b2c"}>B2C</MenuItem>
                    <MenuItem value={"b2b"}>B2B</MenuItem>
                    <MenuItem value={"both"}>BOTH</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    Org Type<sup>*</sup>{" "}
                  </h6>
                  {id ? (
                    <Autocomplete
                      id="org_type"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setOrgType(obj.name);
                        }
                      }}
                      options={orgTypesList?.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) => option?.name}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getOrgTypes(newInputValue);
                        }, 1000);
                        if (newInputValue.length === 0) {
                          setOrgType(0);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Org Types"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          required
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Assigned</h6>
                  {id ? (
                    <Autocomplete
                      id="team lead"
                      key={key}
                      multiple
                      limitTags={1}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let sourceIds: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            sourceIds.push(obj.id);
                          });
                          setTeamLead(sourceIds);
                        }
                      }}
                      defaultValue={assignDef}
                      options={teamLeadList?.results || []}
                      getOptionLabel={(option: any) => option?.user?.username}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPanelUserListTeamLead(`?code=${newInputValue}`);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Team Lead"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid item xs={12} md={3}>
                  <h6>Discount</h6>
                  <TextField
                    className="input"
                    name="employee_id"
                    type="number"
                    placeholder="Discount"
                    value={discount}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Package Name</h6>
                  <Autocomplete
                    freeSolo
                    id="test-name-filter"
                    options={packageList?.results || []}
                    // onChange={(newValue) => {
                    //   getPackage(`code=${newValue}`);
                    // }}
                    disableClearable
                    getOptionLabel={(option: any) => option?.name}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPackage(`city_id=${cityId}&search=${newInputValue}`);
                      }, 200);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        placeholder="package"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Partner Key</h6>
                  <TextField
                    fullWidth
                    className="input"
                    name="Partner Code"
                    type="number"
                    placeholder="Enter Partner Key"
                    value={partnerKey}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setPartnerKey(e.target.value as String)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Center</h6>
                  <Autocomplete
                    id="center"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCentre(obj.id);
                      }
                    }}
                    freeSolo
                    blurOnSelect
                    options={centreData?.results || []}
                    inputValue={centreDef || ""}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) =>
                      (option?.display_name === null
                        ? option?.name
                        : option?.display_name) +
                      (option?.center_code
                        ? "(" + option?.center_code + ")"
                        : "")
                    }
                    onInputChange={(event, newInputValue) => {
                      setCentreDef(newInputValue);
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getCentre(`?name_or_code=${newInputValue}`);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Center"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Report SMS sent to</h6>
                  <Select
                    className="input"
                    name="usertype"
                    variant="outlined"
                    value={smsSent}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setSmsSent(e.target.value as String)}
                  >
                    {/* <MenuItem disabled value={"none"}>
                      Report SMS sent to
                    </MenuItem> */}
                    <MenuItem value={"client"}>Client</MenuItem>
                    <MenuItem value={"customer"}>Customer</MenuItem>
                    <MenuItem value={"both"}>BOTH</MenuItem>
                    <MenuItem value={"not_send"}>Not Send</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Report Whatsapp sent to</h6>
                  <Select
                    className="input"
                    name="usertype"
                    variant="outlined"
                    value={whatsappSent}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setWhatsappSent(e.target.value as String)}
                  >
                    {/* <MenuItem disabled value={"none"}>
                      Report Whatsapp sent to
                    </MenuItem> */}
                   <MenuItem value={"client"}>Client</MenuItem>
                    <MenuItem value={"customer"}>Customer</MenuItem>
                    <MenuItem value={"both"}>BOTH</MenuItem>
                    <MenuItem value={"not_send"}>Not Send</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Report Email sent to</h6>
                  <Select
                    className="input"
                    name="usertype"
                    variant="outlined"
                    value={emailSent}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setEmailSent(e.target.value as String)}
                  >
                    {/* <MenuItem disabled value={"none"}>
                      Report Email sent to
                    </MenuItem> */}
                    <MenuItem value={"client"}>Client</MenuItem>
                    <MenuItem value={"customer"}>Customer</MenuItem>
                    <MenuItem value={"both"}>BOTH</MenuItem>
                    <MenuItem value={"not_send"}>Not Send</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Video SMS sent to</h6>
                  <Select
                    className="input"
                    name="usertype"
                    variant="outlined"
                    value={videoSent}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setVideoSent(e.target.value as String)}
                  >
                    {/* <MenuItem disabled value={"none"}>
                      Video SMS sent to
                    </MenuItem> */}
                   <MenuItem value={"client"}>Client</MenuItem>
                    <MenuItem value={"customer"}>Customer</MenuItem>
                    <MenuItem value={"both"}>BOTH</MenuItem>
                    <MenuItem value={"not_send"}>Not Send</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>B65 Code</h6>
                  <TextField
                    fullWidth
                    className="input"
                    name="B65 Code"
                    type="number"
                    placeholder=" B65 Code"
                    value={codeB65}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setCodeB65(e.target.value as String)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Commission</h6>
                  <TextField
                    fullWidth
                    className="input"
                    name="Commission"
                    type="number"
                    placeholder="Enter Commission"
                    value={commission}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setCommission(e.target.value as String)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Video URL</h6>
                  <TextField
                    className="input"
                    name="title"
                    type="text"
                    placeholder="Enter Video URL"
                    value={url}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    {/* <FormControlLabel
                      control={<Checkbox name="IsActive" checked={isactive} />}
                      onChange={(e) => setIsActive(!isactive)}
                      label="Is Active"
                    /> */}
                    <FormControlLabel
                      control={
                        <Checkbox name="IsActive" checked={isReportWhatsapp} />
                      }
                      onChange={(e) => setIsReportWhatsapp(!isReportWhatsapp)}
                      label="Is report whatsapp"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox name="IsActive" checked={isReportEmail} />
                      }
                      onChange={(e) => setIsReportEmail(!isReportEmail)}
                      label="Is report email"
                    />
                    {/* <FormControlLabel
                      control={<Checkbox name="IsActive" checked={isPha} />}
                      onChange={(e) => setIsPha(!isPha)}
                      label="Is shown to pha"
                    /> */}
                    <FormControlLabel
                      control={<Checkbox name="IsActive" checked={isInvoice} />}
                      onChange={(e) => setIsInvoice(!isInvoice)}
                      label="Is invoice email"
                    />
                    {/* <FormControlLabel
                      control={<Checkbox name="IsActive" checked={isCredit} />}
                      onChange={(e) => setIsCredit(!isCredit)}
                      label="Is credit"
                    />
                    <FormControlLabel
                      control={<Checkbox name="IsActive" checked={isSales} />}
                      onChange={(e) => setIsSales(!isSales)}
                      label="Is shown to sales"
                    />
                    <FormControlLabel
                      control={<Checkbox name="IsActive" checked={isVerify} />}
                      onChange={(e) => setIsVerify(!isVerify)}
                      label="Is auto verify"
                    />
                    <FormControlLabel
                      control={<Checkbox name="IsActive" checked={isPrice} />}
                      onChange={(e) => setIsPrice(!isPrice)}
                      label="Is price hide"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox name="IsActive" checked={isCreditButton} />
                      }
                      onChange={(e) => setIsCreditButton(!isCreditButton)}
                      label="Is credit button display"
                    />
                    <FormControlLabel
                      control={<Checkbox name="IsActive" checked={agentCall} />}
                      onChange={(e) => setAgentCall(!agentCall)}
                      label="Agent On Call"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox name="IsActive" checked={upgradePermitted} />
                      }
                      onChange={(e) => setUpgradePermitted(!upgradePermitted)}
                      label="Upgrade permitted"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox name="IsActive" checked={paymentSms} />
                      }
                      onChange={(e) => setPaymentSms(!paymentSms)}
                      label="Is booking payment sms"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox name="IsActive" checked={bookingSms} />
                      }
                      onChange={(e) => setBookingSms(!bookingSms)}
                      label="Is booking sms"
                    /> */}
                    <FormControlLabel
                      control={<Checkbox name="IsActive" checked={reportSms} />}
                      onChange={(e) => setReportSms(!reportSms)}
                      label="Is report sms"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ padding: "20px", textAlign: "center" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ width: 300 }}
                >
                  Update Partner
                </Button>
              </Grid>
            </div>
          </Paper>
        </form>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userTableData: state.TechSupportReducer.userTableData,
  zoneList: state.TechSupportReducer.zoneList,
  cities: state.TechSupportReducer.cities,
  packageList: state.TechSupportReducer.packageList,
  teamLeadList: state.TechSupportReducer.teamLeadList,
  chatSkillsList: state.TechSupportReducer.chatSkillsList,
  orgTypesList: state.TechSupportReducer.orgTypesList,
  dialer: state.TechSupportReducer.dialer,
  centreData: state.TechSupportReducer.centreData,
  partnerList: state.TechSupportReducer.partnerList,
});

export default connect(mapStateToProps, {
  getUserData,
  getZone,
  getCities,
  getPackage,
  updatePartner,
  getPanelUserListTeamLead,
  getOrgTypes,
  getUserEdit,
  getCentre,
  getPartners,
})(EditPartner);
