import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  createNewPhlebo,
  updatePhlebo,
  getCities,
  getPhlebos,
  getZone,
  getUserTypes,
  getOrgTypes,
  getPanelUserListTeamLead,
  getCentre,
  getChatSkillsList,
  getLanguage,
  getOzontelSkillsData,
  getDialer,
} from "../../actions/TechSupportActions";
import { useHistory, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../loader";
import { genrateManagePhlebos } from "../../../helpers/generateUrl";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  zoneList: any;
  loading: Boolean;
  getAutoCompleteZone: any;
  getPhlebos: any;
  phleboList: any;
  filterPhlebo: any;
  filterZone: any;
  getCities: any;
  cities: any;
  filterBaseCity: any;
  getZone: any;
  updatePhlebo: any;
  getUserTypes: any;
  userTypesList: any;
  getOrgTypes: any;
  orgTypesList: any;
  teamLeadList: any;
  getPanelUserListTeamLead: any;
  centreData: any;
  chatSkillsList: any;
  getCentre: any;
  getChatSkillsList: any;
  ozontelskilldata: any;
  getLanguage: any;
  language: any;
  getOzontelSkillsData: any;
  getDialer: any;
  dialer: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  zoneList,
  loading,
  getAutoCompleteZone,
  getPhlebos,
  phleboList,
  filterPhlebo,
  filterZone,
  filterBaseCity,
  getCities,
  cities,
  getZone,
  updatePhlebo,
  getUserTypes,
  userTypesList,
  getOrgTypes,
  orgTypesList,
  teamLeadList,
  getPanelUserListTeamLead,
  centreData,
  chatSkillsList,
  getCentre,
  getChatSkillsList,
  ozontelskilldata,
  getLanguage,
  language,
  getOzontelSkillsData,
  getDialer,
  dialer,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [phleboName, setPhleboName] = useState<any>("");
  const [phleboUserName, setPhleboUserName] = useState<any>("");
  const [phleboUuid, setPhleboUuid] = useState<any>("");
  const [phleboSource, setPhleboSource] = useState<string>("");
  const [employeId, setEmployeId] = useState<string>();
  const [phleboOrRider, setPhleboOrRider] = useState<any>("");
  const [mapMyIndiaId, setmapMyIndiaId] = useState<any>("");
  const [base_city, setBase_City] = useState<any>("");
  const [baseCityDef, setBaseCityDef] = useState<any>("");
  const [isactive, setIsActive] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [dialers, setDialers] = useState("");
  const [orgType, setOrgType] = useState<any>("");
  const [centreDef, setCentreDef] = useState<any>("");
  const [reportingLocation, setReportingLocation] = useState<any>("");
  const [userType, setUserType] = useState<any>("");
  const [clicktoCall, setClickToCall] = useState<any>("");
  const [ameyoUsername, setAmeyoUsername] = useState<any>("");
  const [ameyoPassword, setAmeyoPassword] = useState<any>();
  const [teamLead, setTeamLead] = useState<any>([]);
  const [assignDef, setAssignDef] = useState<any>([]);
  const [defOrgType, setDefOrgType] = useState<any>([]);
  const [dailerNameDef, setDailerNameDef] = useState<any>("");
  const [languagesDef, setLanguagesDef] = useState<any>([]);
  const [key, setKey] = useState<any>(false);
  const [chatskills, setChatSkills] = useState<any>([]);
  const [chatSkilldef, setChatSkilldef] = useState<any>([]);
  const [executiveType, setExecutiveType] = useState<any>("");
  const [languages, setLanguages] = useState<any>([]);
  const [ozontelName, setozontel] = useState<any>([]);
  const [ozontelId, setOzontelId] = useState<any>([]);
  const [centre, setCentre] = useState<any>("");
  const [userTypes, setUserTypes] = useState<any>("");
  const [userTypesDef, setUserTypesDef] = useState<any>([]);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });

  const inputAlphaNumeric = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^ a-zA-Z_ ]+/gi, "")
      : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };
  const inputAlphaNumericEmpId = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, "")
      : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };
  const inputAlphaNumericPhleboNum = (e: any) => {
    const value = e.target.value ? e.target.value.replace(/[^0-9]+/gi, "") : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };
  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-0987654321`".includes(e.key) &&
      e.preventDefault()
    );
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (id) {
      getPhlebos(`${id}/`);
    }
  }, [id]);

  const UpdatePhleboData = async (e: any) => {
    e.preventDefault();
    const body: any = {
      empid: employeId,
      fullname: phleboName,
      username: phleboUserName,
      phonenumber: phoneNumber,
      u_type: phleboSource,
      start_time: startTime,
      end_time: endTime,
      phlebo_or_rider: phleboOrRider,
      mapmyindia: mapMyIndiaId,
      is_active: isactive,
      base_city: base_city,
      usertype: userType,
      usertypes: userTypes,
      org_types: orgType,
      asigned: teamLead,
      center: centre,
      reporting_location: reportingLocation,
      executive_type: executiveType ? executiveType : null,
      chat_skills: chatskills,
      ozontel_skills: ozontelId,
      dailer: dialers,
      ctc_from: clicktoCall,
      amyeo_user: ameyoUsername,
      amyeo_password: ameyoPassword,
      languages: languages,
    };
    if (phoneNumber === "" || phoneNumber === "none") {
      delete body.password;
    }
    if (executiveType === "" || executiveType === "none") {
      delete body.executive_type;
    }
    await updatePhlebo(body, phleboUuid);
    history.push(`/dashboard/ts/managephlebos`);
  };
  useEffect(() => {
    if (phleboList) {
      const list = phleboList;
      setKey(!key);
      setEmployeId(list?.empid);
      setPhleboName(list?.user?.name);
      setPhleboUserName(list?.user?.username);
      setPhleboUuid(list?.user?.uuid);
      setPhoneNumber(list?.user?.phonenumber);
      setIsActive(list && list?.is_active);
      setBaseCityDef(list?.base_city?.name);
      setBase_City(list?.base_city?.id);
      setEndTime(list?.end_time);
      setStartTime(list?.start_time);
      setPhleboOrRider(list?.phlebo_or_rider);
      setPhleboSource(list?.u_type);
      setmapMyIndiaId(list?.mapmyindiaid);
      setUserTypes(
        list && list?.panel_user_details?.usertypes?.map((val: any) => val?.id)
      );
      setUserTypesDef(list && list?.panel_user_details?.usertypes);
      setUserType(list?.panel_user_details?.usertype);
      setOrgType(
        list && list?.panel_user_details?.org_types?.map((val: any) => val?.id)
      );
      setDefOrgType(list?.panel_user_details?.org_types);
      setTeamLead(
        list?.panel_user_details?.asigned?.map((list: any) => list?.id)
      );
      setAssignDef(list?.panel_user_details?.asigned);
      setCentre(list?.panel_user_details?.center?.id || "");
      setCentreDef(list?.panel_user_details?.center?.name || "");
      setReportingLocation(list?.panel_user_details?.reporting_location);
      setExecutiveType(list?.panel_user_details?.executive_type);
      setChatSkills(
        list?.panel_user_details?.chat_skills?.map((val: any) => val?.id)
      );
      setChatSkilldef(list?.panel_user_details?.chat_skills);
      setOzontelId(
        list?.panel_user_details?.ozontel_skills?.map((val: any) => val?.id)
      );
      setozontel(list?.panel_user_details?.ozontel_skills);
      setDailerNameDef(list?.panel_user_details?.dailer?.campaign_name);
      setDialers(list?.panel_user_details?.dailer?.id);
      setLanguagesDef(list?.panel_user_details?.languages);
      setLanguages(
        list?.panel_user_details?.languages?.map((val: any) => val?.iso_639_1)
      );
      setClickToCall(list?.panel_user_details?.ctc_from);
    }
  }, [phleboList]);

  return (
    <div>
      <h2 style={{ marginTop: "60px" }}>Edit Phlebo</h2>
      <form onSubmit={UpdatePhleboData}>
        <Paper>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={3}
            style={{ margin: "1rem auto", marginTop: "20px" }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <h6>Employe Id</h6>
              <TextField
                name="empId"
                type="text"
                value={employeId}
                inputProps={{ maxLength: 10 }}
                className="input"
                variant="outlined"
                placeholder="Enter Employee Id"
                onChange={(e) => {
                  inputAlphaNumericEmpId(e);
                  setEmployeId(e.target.value as string);
                }}
                style={{ width: "100%", minWidth: "200px" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <h6>Name</h6>
              <TextField
                name="name"
                type="text"
                value={phleboName}
                className="input"
                variant="outlined"
                placeholder="Enter Name"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  inputAlphaNumeric(e);
                  setPhleboName(e.target.value as string);
                }}
                style={{ width: "100%", minWidth: "200px" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <h6>Username</h6>
              <TextField
                name="username"
                type="text"
                value={phleboUserName}
                className="input"
                variant="outlined"
                placeholder="Enter User Name"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(
                    /[^a-zA-Z0-9_]/g,
                    ""
                  );

                  setPhleboUserName(sanitizedValue);
                }}
                style={{ width: "100%", minWidth: "200px" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <h6>Phlebo Number</h6>
              <TextField
                name="number"
                type="text"
                value={phoneNumber}
                className="input"
                variant="outlined"
                placeholder="Enter Phone Number"
                inputProps={{ maxLength: 10 }}
                onChange={(e) => {
                  inputAlphaNumericPhleboNum(e);
                  setPhoneNumber(e.target.value as string);
                }}
                style={{ width: "100%", minWidth: "200px" }}
                onBlur={() => verifyMobile("mobile")}
                onFocus={() => {
                  setMobileValidation((prev: any) => ({
                    ...prev,
                    phoneNumber: true,
                  }));
                }}
                helperText={
                  !mobileValidation.phoneNumber && "Incorrect Phone Number"
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <h6>Phlebo Source</h6>
              <Select
                className="input"
                name="booking_status"
                variant="outlined"
                value={phleboSource}
                style={{ width: "100%", height: "40px", padding: "0" }}
                onChange={(e) => setPhleboSource(e.target.value as string)}
              >
                <MenuItem disabled value={"none"}>
                  Select Source
                </MenuItem>
                <MenuItem value={"rcl"}>RCL</MenuItem>
                <MenuItem value={"kartvya"}>Kartvya</MenuItem>
                <MenuItem value={"cc"}>CC</MenuItem>
                <MenuItem value={"others"}>Others</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <h6>Base City</h6>
              <Autocomplete
                id="base_city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setBase_City(obj?.id);
                  }
                }}
                options={cities || []}
                freeSolo
                blurOnSelect
                aria-required
                inputValue={baseCityDef || ""}
                getOptionLabel={(option: any) => String(option?.name)}
                onInputChange={(event, newInputValue) => {
                  setBaseCityDef(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    variant="outlined"
                    placeholder="Select Base City"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <h6>Phlebo or DRM</h6>
              <Select
                className="input"
                name="phlebo_rider"
                variant="outlined"
                value={phleboOrRider}
                style={{ width: "100%", height: "40px", padding: "0" }}
                onChange={(e) => setPhleboOrRider(e.target.value as string)}
              >
                <MenuItem disabled value={"none"}>
                  Select Phlebo or DRM
                </MenuItem>
                <MenuItem value={"phlebo"}>Phlebo</MenuItem>
                <MenuItem value={"DRM"}>DRM</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <h6>MAP MY INDIA ID</h6>
              <TextField
                name="mapMyIndiaId"
                type="text"
                value={mapMyIndiaId}
                inputProps={{ maxLength: 10 }}
                className="input"
                variant="outlined"
                placeholder="Enter Map India Id"
                onChange={(e) => {
                  inputAlphaNumericEmpId(e);
                  setmapMyIndiaId(e.target.value as string);
                }}
                style={{ width: "100%", minWidth: "200px" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <h6>Start Time</h6>
              <TextField
                id="time"
                type="time"
                value={startTime}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setStartTime(e.target.value)}
                inputProps={{
                  step: 300, // 5 min
                }}
                // sx={{ width: 150 }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <h6>End Time</h6>
              <TextField
                id="time"
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                // sx={{ width: 150 }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <h6>Phlebo Status</h6>
              <FormControlLabel
                control={<Checkbox />}
                checked={isactive}
                label="IsActive"
                onChange={(e) => setIsActive(!isactive)}
              />
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ margin: "1rem auto", marginTop: "2px" }}
            >
              <Grid item xs={12} md={3}>
                <h6>User Types </h6>
                <Autocomplete
                  id="User_Types"
                  key={key}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      // sourceIds = obj.map((item: any) => item.id);
                      let storeData: any = [];
                      obj?.map((list: any) => {
                        storeData.push(list?.id);
                      });
                      setUserTypes(storeData);
                    }
                  }}
                  options={userTypesList?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  multiple
                  defaultValue={userTypesDef || []}
                  getOptionLabel={(option: any) => {
                    return option?.name;
                  }}
                  getOptionDisabled={(option: any) => {
                    return userTypes?.includes(option?.id);
                  }}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getUserTypes(newInputValue);
                    }, 1000);
                    if (newInputValue?.length === 0) {
                      setUserTypes([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="User Types"
                      variant="outlined"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "1px",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                      onKeyDown={(e) => {
                        blockCharInAutocompleteField(e);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <h6>
                  User Type <sup>*</sup>{" "}
                </h6>
                <Select
                  className="input"
                  name="usertype"
                  variant="outlined"
                  value={userType || ""}
                  style={{
                    width: "100%",
                    margin: "0",
                    height: "40px",
                    padding: "1px",
                  }}
                  onChange={(e) => setUserType(e.target.value as String)}
                >
                  <MenuItem disabled value={"none"}>
                    User Type
                  </MenuItem>
                  <MenuItem value={"b2c"}>B2C</MenuItem>
                  <MenuItem value={"b2b"}>B2B</MenuItem>
                  <MenuItem value={"both"}>BOTH</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                <h6>
                  Org Type<sup>*</sup>{" "}
                </h6>
                {id ? (
                  <Autocomplete
                    id="Org_Type"
                    key={key}
                    multiple
                    limitTags={1}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let sourceIds: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          sourceIds.push(obj.id);
                        });
                        setOrgType(sourceIds);
                      }
                    }}
                    defaultValue={defOrgType}
                    options={orgTypesList?.results || []}
                    getOptionLabel={(option: any) => {
                      return option?.name;
                    }}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getOrgTypes(newInputValue);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Org Types"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "1px" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        onKeyDown={(e) => {
                          blockCharInAutocompleteField(e);
                        }}
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <h6>Assigned</h6>
                {id ? (
                  <Autocomplete
                    id="team lead"
                    key={key}
                    multiple
                    limitTags={1}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let sourceIds: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          sourceIds.push(obj.id);
                        });
                        setTeamLead(sourceIds);
                      }
                    }}
                    defaultValue={assignDef}
                    options={teamLeadList?.results || []}
                    getOptionLabel={(option: any) =>
                      option?.user?.username || ""
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPanelUserListTeamLead(`?code=${newInputValue}`);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Team Lead"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "1px" }}
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <h6>Center</h6>
                <Autocomplete
                  id="center"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCentre(obj.id);
                    }
                  }}
                  freeSolo
                  blurOnSelect
                  options={centreData?.results || []}
                  inputValue={centreDef || ""}
                  disableCloseOnSelect
                  getOptionLabel={(option: any) =>
                    (option?.display_name === null
                      ? option?.name
                      : option?.display_name) +
                    (option?.center_code ? "(" + option?.center_code + ")" : "")
                  }
                  onInputChange={(event, newInputValue) => {
                    setCentreDef(newInputValue);
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getCentre(`?name_or_code=${newInputValue}`);
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Center"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <h6>
                  Reporting Location<sup>*</sup>{" "}
                </h6>
                <Select
                  className="input"
                  name="ReportingLocation"
                  variant="outlined"
                  value={reportingLocation}
                  style={{
                    width: "100%",
                    margin: "0",
                    height: "40px",
                    padding: "1px",
                  }}
                  onChange={(e) =>
                    setReportingLocation(e.target.value as String)
                  }
                >
                  <MenuItem disabled value={"none"}>
                    ReportingLocation
                  </MenuItem>
                  <MenuItem value={"Gurugram"}>Gurugram</MenuItem>
                  <MenuItem value={"Noida"}>Noida</MenuItem>
                  <MenuItem value={"GGN2"}>GGN2</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                <h6>Executive Type</h6>
                <Autocomplete
                  id="ExecutiveType"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setExecutiveType(obj.value);
                    }
                  }}
                  options={[
                    {
                      text: "RCA",
                      value: "RCA",
                    },
                    {
                      text: "Resampling",
                      value: "Resampling",
                    },
                    {
                      text: "Report",
                      value: "Report",
                    },
                    {
                      text: "Refund",
                      value: "Refund",
                    },
                    {
                      text: "Admin",
                      value: "Admin",
                    },
                    {
                      text: "Discount",
                      value: "Discount",
                    },
                    {
                      text: "Ticketing",
                      value: "Ticketing",
                    },
                    {
                      text: "Consultation",
                      value: "Consultation",
                    },
                    {
                      text: "North Zone",
                      value: "North Zone",
                    },
                    {
                      text: "South Zone",
                      value: "South Zone",
                    },
                    {
                      text: "West Zone",
                      value: "West Zone",
                    },
                    {
                      text: "East Zone",
                      value: "East Zone",
                    },
                    {
                      text: "CEGenomics",
                      value: "CEGenomics",
                    },
                    {
                      text: "Inbound",
                      value: "Inbound",
                    },
                    {
                      text: "Outbound",
                      value: "Outbound",
                    },
                    {
                      text: "LTV",
                      value: "LTV",
                    },
                    {
                      text: "CoCal",
                      value: "CoCal",
                    },
                    {
                      text: "Dialer",
                      value: "Dialer",
                    },
                    {
                      text: "OBH1",
                      value: "OBH1",
                    },
                    {
                      text: "OBH2",
                      value: "OBH2",
                    },
                    {
                      text: "Lab",
                      value: "Lab",
                    },
                    {
                      text: "CentralLab",
                      value: "CentralLab",
                    },
                    {
                      text: "Escalation",
                      value: "Escalation",
                    },
                    {
                      text: "ChatAndEmail",
                      value: "ChatAndEmail",
                    },
                    {
                      text: "CASH",
                      value: "CASH",
                    },
                    {
                      text: "NONCASH",
                      value: "NONCASH",
                    },
                    {
                      text: "ABM/ASM",
                      value: "ABM/ASM",
                    },
                    {
                      text: "SE/TM",
                      value: "SE/TM",
                    },
                    {
                      text: "RBM/RSM",
                      value: "RBM/RSM",
                    },
                    {
                      text: "ZSM",
                      value: "ZSM",
                    },
                    {
                      text: "AGM SALES",
                      value: "AGM SALES",
                    },
                    {
                      text: "GM SALES",
                      value: "GM SALES",
                    },
                    {
                      text: "UpgradedCE",
                      value: "UpgradedCE",
                    },
                    {
                      text: "B2B_CX",
                      value: "B2B CX",
                    },
                    {
                      text: "InvLabDr",
                      value: "Inventory Lab Dr.",
                    },
                    {
                      text: "InvLabService",
                      value: "Inventory Lab Service",
                    },
                    {
                      text: "InvLabHead",
                      value: "Inventory Lab Head",
                    },
                    {
                      text: "SuperAdmin",
                      value: "SuperAdmin",
                    },
                    {
                      text: "BML",
                      value: "BML",
                    },
                    {
                      text: "standard",
                      value: "Standard",
                    },
                    {
                      text: "corporate_app",
                      value: "Corporate App",
                    },
                    {
                      text: "FINANCE",
                      value: "Finance",
                    },
                    {
                      text: "OLCP",
                      value: "Olc Partner",
                    },
                    {
                      text: "olc",
                      value: "olc",
                    },
                    {
                      text: "DSA",
                      value: "DSA",
                    },
                    {
                      text: "Manager",
                      value: "Manager",
                    },
                    {
                      text: "AreaManager",
                      value: "AreaManager",
                    },
                    {
                      text: "PPMC",
                      value: "PPMC",
                    },
                    {
                      text: "DRM",
                      value: "DRM",
                    },
                    {
                      text: "Contract",
                      value: "Contract",
                    },
                    {
                      text: "Auditor",
                      value: "Auditor",
                    },
                    {
                      text: "Genomics",
                      value: "Genomics",
                    },
                    {
                      text: "GenomicsAdmin",
                      value: "GenomicsAdmin",
                    },
                    {
                      text: "Routine",
                      value: "Routine",
                    },
                    {
                      text: "RoutineAdmin",
                      value: "RoutineAdmin",
                    },
                    {
                      text: "billing",
                      value: "billing",
                    },
                    {
                      text: "phlebos",
                      value: "phlebos",
                    },
                    {
                      text: "SampleAccessioning",
                      value: "SampleAccessioning",
                    },
                    {
                      text: "Others",
                      value: "Others",
                    },
                  ]}
                  freeSolo
                  blurOnSelect
                  aria-required
                  inputValue={executiveType || ""}
                  getOptionLabel={(option: any) => option.text}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      setExecutiveType(newInputValue);
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Executive Type"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <h6>Chat Skills</h6>
                {id ? (
                  <Autocomplete
                    id="team lead"
                    key={key}
                    multiple
                    limitTags={1}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let sourceIds: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          sourceIds.push(obj.id);
                        });
                        setChatSkills(sourceIds);
                      }
                    }}
                    defaultValue={chatSkilldef || []}
                    options={chatSkillsList?.results || []}
                    getOptionLabel={(option: any) => {
                      return option?.name;
                    }}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getChatSkillsList(newInputValue);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Chat Skills"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "1px",
                        }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        onKeyDown={(e) => {
                          blockCharInAutocompleteField(e);
                        }}
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <h6>Ozontel Skill</h6>
                {id ? (
                  <Autocomplete
                    id="team lead"
                    key={key}
                    multiple
                    limitTags={1}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let sourceIds: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          sourceIds.push(obj.id);
                        });
                        setOzontelId(sourceIds);
                      }
                    }}
                    defaultValue={ozontelName}
                    options={ozontelskilldata?.results || []}
                    getOptionLabel={(option: any) =>
                      String(option?.id) && String(option?.name)
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getOzontelSkillsData(`?search=${newInputValue}`);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ozontel Skill"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "1px",
                        }}
                        className="input"
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <h6>Dialer</h6>
                <Autocomplete
                  id="dialer"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setDialers(obj.id);
                    }
                  }}
                  options={dialer?.results || []}
                  inputValue={dailerNameDef || ""}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) =>
                    option?.did && option?.campaign_name
                  }
                  onInputChange={(event, newInputValue) => {
                    setDailerNameDef(newInputValue);
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getDialer(`?campaign_name=${newInputValue}`);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setDialers("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Dialer"
                      variant="outlined"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "1px",
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <h6>
                  Click To Call<sup>*</sup>{" "}
                </h6>
                <Select
                  className="input"
                  name="sampleregisterd"
                  variant="outlined"
                  value={clicktoCall || ""}
                  style={{
                    width: "100%",
                    margin: "0",
                    height: "40px",
                    padding: "1px",
                  }}
                  onChange={
                    (e) => setClickToCall(e.target.value as String)
                    //handleshowing(e)
                  }
                >
                  <MenuItem disabled value={"none"}>
                    Click To Call
                  </MenuItem>
                  <MenuItem value={"ELISION_BML"}>ELISION_BML</MenuItem>
                  <MenuItem value={"ELISION_GSM"}>ELISION_GSM</MenuItem>
                  <MenuItem value={"AMEYOCALL"}>AMEYOCALL</MenuItem>
                  <MenuItem value={"TATATELEOFFLINE"}>
                    TATATELE OFFLINE
                  </MenuItem>
                  <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                </Select>
              </Grid>
              {clicktoCall === "AMEYOCALL" ? (
                <>
                  <Grid item xs={12} md={3}>
                    <h6>Ameyo Username</h6>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter AMEYOCALL"
                      value={ameyoUsername}
                      variant="outlined"
                      inputProps={{ maxLength: 35 }}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "1px",
                      }}
                      onChange={(e) => {
                        setAmeyoUsername(e.target.value);
                        inputAlphaNumeric(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Password <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter Password"
                      value={ameyoPassword}
                      variant="outlined"
                      inputProps={{ maxLength: 35 }}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "1px",
                      }}
                      onChange={(e) => {
                        setAmeyoPassword(e.target.value);
                      }}
                      required
                    />
                  </Grid>
                </>
              ) : clicktoCall == "ELISION_BML" ||
                clicktoCall === "ELISION_GSM" ? (
                <Grid item xs={12} md={3}>
                  <h6>Ameyo Username</h6>
                  <TextField
                    className="input"
                    name="title"
                    type="text"
                    placeholder="Enter AMEYOCALL"
                    value={ameyoUsername}
                    variant="outlined"
                    inputProps={{ maxLength: 35 }}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "1px",
                    }}
                    onChange={(e) => {
                      setAmeyoUsername(e.target.value);
                      inputAlphaNumeric(e);
                    }}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} md={3}>
                <h6>Language</h6>
                {id ? (
                  <Autocomplete
                    id="language"
                    key={key}
                    multiple
                    limitTags={1}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let sourceIds: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          sourceIds.push(obj.iso_639_1);
                        });
                        setLanguages(sourceIds);
                      }
                    }}
                    defaultValue={languagesDef || []}
                    options={language?.results || []}
                    getOptionLabel={(option: any) => {
                      return option?.name_en;
                    }}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getLanguage(newInputValue);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Language"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "1px",
                        }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        onKeyDown={(e) => {
                          blockCharInAutocompleteField(e);
                        }}
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={2}></Grid>
            <Grid item xs={12} sm={4} md={2}></Grid>
            <Grid item xs={12} sm={4} md={2}></Grid>
            <Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ width: 300 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  phleboPhone: state.TechSupportReducer.phleboPhone,
  cities: state.TechSupportReducer.cities,
  phleboList: state.TechSupportReducer.phleboList,
  zoneList: state.TechSupportReducer.zoneList,
  loading: state.TechSupportReducer.loading,
  userTypesList: state.TechSupportReducer.userTypesList,
  orgTypesList: state.TechSupportReducer.orgTypesList,
  teamLeadList: state.TechSupportReducer.teamLeadList,
  centreData: state.TechSupportReducer.centreData,
  chatSkillsList: state.TechSupportReducer.chatSkillsList,
  ozontelskilldata: state.TechSupportReducer.ozontelskilldata,
  language: state.TechSupportReducer.language,
  dialer: state.TechSupportReducer.dialer,
});

export default connect(mapStateToProps, {
  createNewPhlebo,
  updatePhlebo,
  getCities,
  getPhlebos,
  getZone,
  getUserTypes,
  getOrgTypes,
  getPanelUserListTeamLead,
  getCentre,
  getChatSkillsList,
  getLanguage,
  getOzontelSkillsData,
  getDialer,
})(CreatePhlebo);