import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { arr } from "./userRole";
import { logout } from "../../actions/loginActions";
import { connect } from "react-redux";

function CheckRoles({ role, path,query,username  }) {
  localStorage.setItem("USERNAME", JSON.stringify(username));
  const n = arr.filter((item) => item.role === role);
  let lo = path.split("/").length === 2 ? `/dashboard/${n[0].url}` : path;

  if (lo !== "/dashboard/") {
    return <Redirect to={`${lo}${query}`} />;
  } else {
    logout();
    window.location.replace("/");
    return <Redirect to="/" />;
  }
}

export default connect(null, { logout })(CheckRoles);
