import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

export const ImagePreviewModal = (Props: any) => {
  return (
    <Modal
      open={Props.open}
      onClose={() => {
        Props.setOpen(false);
        Props.setFileType("");
        Props.setFileToShow("");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: Props.fileType === "pdf" ? 600 : 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 5,
          p: 2,
          overflow: "auto", // Add overflow property for scrollability
          maxHeight: "80vh", // Set a maximum height to limit scrolling
        }}
      >
        {Props.fileToShow && (
          <>
            {Props.fileType !== "pdf" && (
              <img
                src={Props.fileToShow}
                width="100%"
                height="auto"
                alt="Image"
              />
            )}
            {Props.fileType === "pdf" && (
              <embed
                src={Props.fileToShow}
                width="100%"
                height="600px"
                type="application/pdf"
              />
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};
