import React, { useEffect, useState } from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  BookingUnBlock,
  getBlockBulkUserFromCreateBooking,
  getLeadDetails,
  getLeads,
} from "../../actions/TechSupportActions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: "90%",
      flexGrow: 21,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    input: {
      padding: "1px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  getLeads: any;
  leads: any;
  getLeadDetails: any;
  lead_details: any;
  getBlockBulkUserFromCreateBooking: any;
  blockUser: any;
  BookingUnBlock: any;
}
const LeadUnblock: React.FC<Props> = ({
  loading,
  getLeads,
  leads,
  getLeadDetails,
  lead_details,
  getBlockBulkUserFromCreateBooking,
  blockUser,
  BookingUnBlock,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = useState<String>("");
  const [status, setStatus] = useState<any>("");
  const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSearch(e.target.value as string);
  };

  function inputKeyUp(e: any) {
    e.which = e.which || e.keyCode;
    if (e.which === 13) {
      searchLead();
    }
  }

  const searchLead = () => {
    let body = {
      search_by: "call_id",
      value: search,
    };

    getLeads(body);
  };

  const selectLead = async () => {
    const body = {
      lead_id: search,
    };

    await BookingUnBlock(body);
    history.push("/dashboard/ts/leadUnblock");
  };

  useEffect(() => {
    leads?.results &&
      leads?.results[0]?.id &&
      getBlockBulkUserFromCreateBooking(`?lead_id=${leads?.results[0]?.id}`);
  }, [leads]);

  useEffect(() => {
    if (blockUser?.lead_ids && leads?.results) {
      const bokingStatus = blockUser?.lead_ids?.includes(
        (leads?.results[0]?.id).toString()
      )
        ? "Blocked"
        : "Unblocked";
      setStatus(bokingStatus);
    }
  }, [blockUser, leads]);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={10}>
          <h2>Lead Unblock</h2>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={5}
          direction="row"
          justify="flex-start"
        >
          <TextField
            className="input"
            id="search"
            type="search"
            value={search}
            variant="outlined"
            placeholder="Please Enter Lead Id "
            onChange={(e) => handleSearch(e)}
            style={{ margin: "0", paddingRight: "9px", width: "80%" }}
            onKeyUp={inputKeyUp}
          />
          <Button
            variant="contained"
            color="secondary"
            style={{ width: "20%", height: "40px" }}
            disabled={search.length === 0 || loading}
            onClick={() => selectLead()}
          >
            Unblock
          </Button>
          {/* <Button
            variant="contained"
            style={{ width: "20%", height: "40px" }}
            disabled={search.length === 0 || loading}
            color="primary"
            onClick={searchLead}
          >
            Search
          </Button> */}
        </Grid>
        {/* <Grid item xs={12}>
          {leads.results && leads.results.length > 0 && (
            <TableContainer className={classes.table}>
              <Table aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">Age</StyledTableCell>
                    <StyledTableCell align="center">Gender</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {leads.results.map((lead: any) => {
                    return (
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          {lead.id || ""}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {lead.patient_name || ""}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {lead.patient_age || ""}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {lead.calling_gender || ""}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {loading ? "...Loading" : status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {status === "Blocked" && (
                            <Button
                              variant="contained"
                              color="secondary"
                              disabled={loading}
                              onClick={() => selectLead(lead.id)}
                            >
                              Unblock
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid> */}
      </Grid>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  leads: state.TechSupportReducer.leads,
  lead_details: state.TechSupportReducer.lead_details,
  blockUser: state.TechSupportReducer.blockUser,
});

export default connect(mapStateToProps, {
  getLeads,
  getLeadDetails,
  getBlockBulkUserFromCreateBooking,
  BookingUnBlock,
})(LeadUnblock);
