import React, { useEffect, useState } from "react";
import { AnyIfEmpty, useDispatch } from "react-redux";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import Popover from "@material-ui/core/Popover";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { CALL_DISABLE_TIME } from "../../../../CallTimeDisable";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  createAdditionalBooking,
  updateAdditionalBooking,
  getBookingById,
  resetBookings,
  clickToCallBooking,
  updateBooking,
  assignDoctor,
  getAvailableSlots,
  getPackage,
  getPaymentSendLink,
  getPaymentReSendLink,
  getPaymentCancelLink,
  getSmsReportSend,
  getSmsDetails,
  getEmailDetails,
  getReportStatus,
  getReportDownload,
  getSMSLink,
  getQueryTickets,
  getBiomarkerScreening,
  getDoctorBookings,
  getNewLeadDetails,
  getUnmaskedNumber,
  getAgentNewData,
  getCustomerSpecificCoupon,
  getHivPackage,
  getFailedReportData,
  getResamplingBookingByID,
  getBookingVerificationOtp,
  getPhleboBookingSelfie,
  getInvoiceByBookingID,
  PostBookingIndexing,
  getBookingDocument,
  getBookingSampleImage,
  postUploadBookingDocument,
  getviewPackageAnswer,
  updateClinicalHistoryAnswer,
  getBookingInvoice,
  getPhleboRecording,
  getPrescriptionDataByBookingId,
} from "../../../actions/TechSupportActions";
import Loader from "../../Loader2/index";
// import Invoice from "../../Comments/invoiceModel";
import moment from "moment";
import CallIcon from "@material-ui/icons/Call";
import { useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RaiseComplaintModal from "../../Comments/raiseTicketModalNew";
import ReportModal from "../../Comments/reportStatusModal";
import CreateCouponModal from "../../../components/Comments/createCouponViewBooking";
import "./index.sass";
import { ACTIONS } from "../../../interfaces/actionTypes/TechSupportType";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// import HIVForm from "../../../../components/HIVForm/index";
import SendPrescriptionPDF from "../../../components/Prescription/sendPrescriptionModal";
import ClinicalHistoryTable from "../../../components/Clinical-History/ClinicalHistoryTable";
import PdfViwerModal from "./PdfViewerModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "10px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "12%",
      float: "left",
    },
    rightContentHeader: {
      width: "88%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "2px 0px 10px 0px",
      marginTop: "40px",
      clear: "both",
    },

    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    heading: {
      fontSize: "1.3rem",
      marginTop: "1rem",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    typography: {
      padding: theme.spacing(2),
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    tableHead: {
      fontWeight: "bold",
      textTransform: "capitalize",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "8px",
      position: "sticky",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper1: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "95%",
      height: "700px",
      overflow: "scroll",
      border: "2px solid green",
      borderRadius: "5px",
    },
    customTooltip: {
      minWidth: "600px",
      width: "600px",
      backgroundColor: "white",
      padding: "10px",
      fontSize: "16px",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    paper2: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  partnerList: any;
  getPartners: any;
  resetBookings: any;
  clickToCallBooking: any;
  updateBooking: any;
  assignDoctor: any;
  getAvailableSlots: any;
  booking_slots: any;
  getPackage: any;
  packageList: any;
  getPaymentSendLink: any;
  getPaymentReSendLink: any;
  getPaymentCancelLink: any;
  getSmsDetails: any;
  getEmailDetails: any;
  emailDetails: any;
  smsDetails: any;
  paymentReSendLink: any;
  createAdditionalBooking: any;
  updateAdditionalBooking: any;
  getSMSLink: any;
  getSmsReportSend: any;
  additional_booking: any;
  getReportStatus: any;
  reportStatus: any;
  getReportDownload: any;
  reportDownload: any;
  getQueryTickets: any;
  bookingComplaints: any;
  loading: any;
  biomarkerScreening: any;
  getBiomarkerScreening: any;
  getDoctorBookings: any;
  doctorConsultationBooking: any;
  bookingIdLoader: Boolean;
  reportLoading: any;
  getNewLeadDetails: any;
  newLeadDetails: any;
  userDetails: any;
  getUnmaskedNumber: any;
  unmaskedNumber: any;
  getAgentNewData: any;
  agentsListnewdata: any;
  getCustomerSpecificCoupon: any;
  customerSpecificCouponData: any;
  hivpackageList: any;
  getHivPackage: any;
  getFailedReportData: any;
  PostBookingIndexing: any;
  getBookingDocument: any;
  bookingDocumentList: any;
  postUploadBookingDocument: any;
  getBookingSampleImage: any;
  sampleImage: any;
  transferImage: any;
  getResamplingBookingByID: any;
  resamplingBooking: any;
  getviewPackageAnswer: any;
  viewPackageAnswer: any;
  viewPackageAnswerAllergy: any;
  updateClinicalHistoryAnswer: any;
  getBookingVerificationOtp: any;
  bookingOtp: any;
  getPhleboBookingSelfie: any;
  phleboSelfie: any;
  getBookingInvoice: any;
  bookingInvoie: any;
  getPhleboRecording: any;
  phleboRedocrding: any;
  getPrescriptionDataByBookingId: any;
  genomicsFormForGet: any;
  getInvoiceByBookingID: any;
  invoiceData: any;
  isloading: any;
}

const ViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  resetBookings,
  clickToCallBooking,
  updateBooking,
  assignDoctor,
  getAvailableSlots,
  getSmsDetails,
  getEmailDetails,
  emailDetails,
  smsDetails,
  booking_slots,
  getPackage,
  packageList,
  createAdditionalBooking,
  getSMSLink,
  updateAdditionalBooking,
  getPaymentSendLink,
  getPaymentReSendLink,
  getPaymentCancelLink,
  getReportStatus,
  reportStatus,
  getReportDownload,
  reportDownload,
  getSmsReportSend,
  bookingComplaints,
  getQueryTickets,
  biomarkerScreening,
  getBiomarkerScreening,
  getDoctorBookings,
  loading,
  doctorConsultationBooking,
  bookingIdLoader,
  reportLoading,
  getNewLeadDetails,
  newLeadDetails,
  userDetails,
  getUnmaskedNumber,
  unmaskedNumber,
  getAgentNewData,
  agentsListnewdata,
  getCustomerSpecificCoupon,
  customerSpecificCouponData,
  hivpackageList,
  getHivPackage,
  getFailedReportData,
  PostBookingIndexing,
  getBookingDocument,
  bookingDocumentList,
  postUploadBookingDocument,
  getBookingSampleImage,
  sampleImage,
  getResamplingBookingByID,
  resamplingBooking,
  getviewPackageAnswer,
  viewPackageAnswer,
  viewPackageAnswerAllergy,
  updateClinicalHistoryAnswer,
  getBookingVerificationOtp,
  bookingOtp,
  getPhleboBookingSelfie,
  phleboSelfie,
  getBookingInvoice,
  bookingInvoie,
  getPhleboRecording,
  phleboRedocrding,
  getPrescriptionDataByBookingId,
  genomicsFormForGet,
  getInvoiceByBookingID,
  invoiceData,
  isloading,
}) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [assignBooking, setAssignBooking] = useState(false);
  const [doctor, setDoctor] = useState(0);
  const timer = useRef<any>(0);
  const [customer_designation, setCustomerDesignation] = useState<String>(
    booking.designation || ""
  );
  const [cityId, setCityId] = useState<any>("");
  const [customer_name, setCustomer_name] = useState<any>("");
  const [pickup_status, setPickup_status] = useState("");
  const [customer_age, setCustomer_age] = useState<any>("");
  const [customer_gender, setCustomer_gender] = useState<any>("male");
  const [customer_aadhar, setCustomer_aadhar] = useState<any>("");
  const [packageDetails, setPackageDetails] = useState<any>([]);
  const [packageDetailsOld, setPackageDetailsOld] = useState<any>([]);
  const [packageDetailsMainMember, setPackageDetailsMainMember] = useState<any>(
    booking?.packages
  );
  const [packageDetailsAdditionalMembers, setPackageDetailsAdditionalMembers] =
    useState<any>(
      booking?.additional_members
        ?.filter(
          (mem: any) =>
            mem?.pickup_status !== "cancelled" &&
            mem?.booking_status !== "cancelled"
        )
        .map((p: any) => {
          return p.packages;
        })
    );
  const [
    packageDetailsAdditionalMembersNewArray,
    setPackageDetailsAdditionalMembersNewArray,
  ] = useState<any>([]);
  const [reportSendStatus, setReportSendStatus] = useState(false);
  const [id, setId] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [openCreateCouponModal, setOpenCreateCouponModal] = useState(false);
  const [openReportModal, setReportModal] = useState(false);
  const [tempTotalPrice, setTempTotalPrice] = useState<number>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(0);
  const [personId, setPersoneId] = React.useState("none");
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );
  const [memberPackageID, setMemberPackageID] = useState<string[]>([]);
  const [billId, setBillId] = useState<any>("");
  const [additionalBillId, setAdditionalBillId] = useState<any>("");
  const [openInvoiceModel, setOpenInvoiceModel] = useState(false);
  const [customer_additonal_passport, setAdditionalCustomer_passport] =
    useState<any>("");
  const [additionalBookingStatus, setAdditionalBookingStatus] =
    useState<any>("");
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [memberType, setMemberType] = useState<any>("none");
  const [memberDetails, setMemberDetails] = useState<any>();
  const [doctorName, setDoctorName] = useState("");
  const [alreadyARedPro, setAlreadyARedPro] = useState<any>(false);
  const [redProMembershipDiscount, setRedProMembershipDiscount] =
    useState<any>(0);
  const [callButton, setCallButton] = useState<boolean>(true);
  const [leadId, setLeadId] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [customerDetails, setCustomerDetails] = useState<any>("");
  const [objId, setObjId] = useState<any>("");
  const [openReportModel, setOpenReportModel] = useState<any>(false);
  const [openReportOnWhatsAppModel, setOpenReportOnWhatsAppModel] =
    useState<boolean>(false);
  const [openMaternalModel, setOpenMaternalModel] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [phleboCostForVew, setPhleboCostForVew] = useState<any>(0);
  const [
    packageDetailsAdditionalMembersMinusThis,
    setPackageDetailsAdditionalMembersMinusThis,
  ] = useState<any>([]);
  const [packageDetailsForAllMembers, setPackageDetailsForAllMembers] =
    useState<any>([]);
  const [key, setKey] = useState<any>(false);
  const [openHIVForm, setOpenHIVForm] = useState<boolean>(false);
  const [showHIVForm, setShowHIVForm] = useState<boolean>(false);
  const [openPdfForClinicalTable, setOpenPdfForClinicalTable] =
    useState<any>(false);
  const openAnchor = Boolean(anchorEl);
  const [openCommunicationDetailsModal, setOpenCommunicationDetailsModal] =
    useState(false);
  useState(false);
  const [viewOtp, setViewOtp] = useState<any>(false);
  const [genomicsPrescriptionData, setGenomicsPrescriptionData] =
    useState<any>(false);
  const [pdfViewerModal, setPdfViewerModal] = useState<boolean>(false);
  const [invoiceType, setInvoiceType] = useState<string>("");

  const isFreeWebBookingTest =
    booking.free_test &&
      booking.free_test.free_test &&
      booking.free_test.free_test.id
      ? true
      : false;

  const usg2Codes: string[] = [
    "BC248",
    "BC070",
    "PL92",
    "PL93",
    "BC138",
    "PL02",
    "SE011",
    "BC463",
    "SE339",
    "BC463",
    "BC462",
    "BC448",
    "PL24",
  ];
  const [openPrescriptionSendModal, setOpenPrescriptionSendModal] =
    useState(false);
  const [isBtnEnable, setIsBtnEnable] = useState<boolean>(true);

  const [phleboRemarks, setPhleboRemarks] = useState<any>();
  const [phleboRemarksLink, setPhleboRemarksLink] = useState<any>();
  const [phleboRemarksChecker, serPhleboRemarlsChecker] = useState<any>(false);
  const [isInternationalNumber, setIsInternationalNumber] =
    useState<any>(false);
  const [CustomerId, setCustomerId] = useState<any>("");
  const [answerEdit, setAnswerEdit] = useState<any>("");
  const [answer, setAnswer] = useState<any>("");
  const [viewAllergy, setViewAllergy] = useState<any>(false);
  const updateAnswer = async () => {
    setAnswerEdit("");
    setAnswer("");
    await updateClinicalHistoryAnswer(answerEdit, {
      answer: answer,
      booking_id: null,
    });
    await getviewPackageAnswer(
      `unique_customer__id=${CustomerId}&question__is_allergy=false`
    );
    await getviewPackageAnswer(
      `unique_customer__id=${CustomerId}&question__is_allergy=true`,
      true
    );
  };
  useEffect(() => {
    const clickableLink = phleboRemarks?.match(/wa\.me\/\+\d+/);
    if (clickableLink) {
      setPhleboRemarksLink(clickableLink[0]);
      serPhleboRemarlsChecker(true);
    } else {
      serPhleboRemarlsChecker(false);
    }
  }, [phleboRemarks]);

  useEffect(() => {
    let storeData: any = [];
    let tempBtnOnOff: boolean = true;
    if (booking?.pk) {
      if (booking?.pk === personId) {
        booking?.packages?.map((list: any) => {
          storeData.push({
            code: list?.code,
          });
        });
      }

      booking.additional_members.length > 0 &&
        booking.additional_members?.map((list: any) => {
          if (list.id === personId) {
            list.packages.length > 0 &&
              list.packages?.map((list2: any) => {
                storeData.push({
                  code: list2?.code,
                });
              });
          }
        });
    }

    if (storeData.length > 0) {
      storeData.map((list: any) => {
        if (usg2Codes.includes(list.code)) {
          tempBtnOnOff = false;
        }
      });
    }

    setIsBtnEnable(tempBtnOnOff);
  }, [personId]);

  const popid = open ? "simple-popover" : undefined;

  const handleOpen = () => {
    setOpen(true);
    getSlots();
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const handleUnmaskedNumber = (e: any, id: any, source: any, type: any) => {
    setAnchorEl(e.currentTarget);
    getUnmaskedNumber(id, source, type);
  };

  const URINE_CONTAINER_WITHIN_24H = [
    "BC326",
    "BC285",
    "BC308",
    "BC351",
    "BC346",
    "BC437",
    "BC370",
    "BC397",
    "BC195",
    "BC199",
    "BC410",
    "BC413",
    "BC472",
    "BC288",
    "BC018",
    "BC289",
    "BC290",
    "BC291",
    "BC292",
    "BC293",
    "BC294",
    "BC296",
    "BC297",
    "BC54",
    "SE15",
    "BC299",
    "BC303",
    "BC314",
    "BC320",
    "BC321",
    "BC322",
    "BC323",
    "BC324",
    "BC325",
    "BC115",
    "BC124",
    "BC274",
    "BC185",
    "BC246",
    "BC416",
    "BC239",
    "BC226",
    "BC209",
    "BC404",
    "BC476",
  ];

  const handlePackages = (customePackage: any, reason: any) => {
    let originalPackageId = new Array();
    packageDetails.length > 0 &&
      packageDetails.map((item: any) => {
        originalPackageId.push(item.id as string);
      });

    if (
      booking?.delivery_date !== null &&
      booking?.delivery_collection_slot !== null
    ) {
      let urinePackageList: { code: string }[] = [];
      customePackage &&
        customePackage?.length > 0 &&
        customePackage.filter((item: { code: string }) => {
          URINE_CONTAINER_WITHIN_24H.forEach((obj) => {
            if (item?.code === obj) {
              urinePackageList.push(item);
              return item;
            }
          });
        });

      if (urinePackageList?.length !== 0) {
        if (urinePackageList?.length !== customePackage?.length) {
          let copyPackage = customePackage?.slice(
            0,
            customePackage?.length - 1
          );
          customePackage = copyPackage;
          setPackageDetails(copyPackage);
          alert(
            "You can only select URINE CONTAINER package in URINE CONTAINER BOOKING."
          );
        } else if (urinePackageList?.length === customePackage?.length) {
          setPackageDetails(customePackage);
        }
      } else {
        setPackageDetails([]);
      }
    } else {
      setPackageDetails(customePackage);
    }

    let accumulatedPrice: any = 0;
    let updatePackageId = new Array();

    if (customePackage && customePackage.length > 0) {
      // let atLeastOneNormalPackage = checkNormalPackage(customePackage);
      customePackage.map((item: any) => {
        updatePackageId.push(item.id);
        const packageExits = memberPackageID.find((el) => el == item.id);
        if (!packageExits) {
          // if (
          //   atLeastOneNormalPackage &&
          //   item.is_addon &&
          //   item.addon_price &&
          //   item.addon_price > 0
          // ) {
          //   accumulatedPrice = accumulatedPrice + item.addon_price;
          // } else {
          if (booking.org_type === "imaging") {
            if (item.package_center_address_prices === null) {
              accumulatedPrice = accumulatedPrice + item.offer_price;
            } else {
              accumulatedPrice =
                accumulatedPrice +
                item.package_center_address_prices.offer_price;
            }
          } else {
            if (item.package_city_prices === null) {
              accumulatedPrice = accumulatedPrice + item.offer_price;
            } else {
              accumulatedPrice =
                accumulatedPrice + item?.package_city_prices?.offer_price;
            }
          }
          // }
        } else {
          if (reason === "remove-option") {
            updatePackageId.push(item.id);
          }
        }
      });
    }

    let familyPrice: number = Math.floor(accumulatedPrice);
    let totalPriceWithFamily = tempTotalPrice + familyPrice;
    setTotalPrice(totalPriceWithFamily);
    setPrice(totalPriceWithFamily);

    let allPackageAddOnPrice = 0;
    let allPackagesList = packageDetailsForAllMembers.slice();
    allPackagesList.push(customePackage);

    allPackagesList?.map((listItem: any, index: any) => {
      let accumulatedPrice: any = 0;
      listItem?.map((item: any) => {
        accumulatedPrice =
          item?.package_city_prices === null
            ? accumulatedPrice + item?.offer_price
            : accumulatedPrice + item?.package_city_prices?.offer_price;
      });
      allPackageAddOnPrice += accumulatedPrice;
    });

    if (booking?.crm_created_booking) {
      if (allPackageAddOnPrice < 500) {
        // handleOfferPriceFunction();
      } else {
        let allPackageAddOnMainPrice: any = 0;
        for (
          let inputIteration = 0;
          inputIteration < allPackagesList?.length;
          inputIteration++
        ) {
          const addOnPackageFilterFalse = allPackagesList[
            inputIteration
          ]?.filter((listItem: any) => listItem?.is_addon === false);
          const addOnPackageFilterTrue = allPackagesList[
            inputIteration
          ]?.filter((listItem: any) => listItem?.is_addon === true);
          if (
            addOnPackageFilterFalse?.length > 0 &&
            addOnPackageFilterTrue?.length > 0
          ) {
            let accumulatedPriceForIteration: any = 0;
            allPackagesList[inputIteration]?.map((item: any) => {
              accumulatedPriceForIteration =
                item?.is_addon === true
                  ? accumulatedPriceForIteration + item?.addon_price
                  : item?.package_city_prices === null
                    ? accumulatedPriceForIteration + item?.offer_price
                    : accumulatedPriceForIteration +
                    item?.package_city_prices?.offer_price;
            });
            if (inputIteration === allPackagesList?.length - 1)
              setPrice(accumulatedPriceForIteration);
            allPackageAddOnMainPrice += accumulatedPriceForIteration;
          } else {
            let accumulatedPriceForIteration: any = 0;
            allPackagesList[inputIteration]?.map((item: any) => {
              accumulatedPriceForIteration =
                item?.package_city_prices === null
                  ? accumulatedPriceForIteration + item?.offer_price
                  : accumulatedPriceForIteration +
                  item?.package_city_prices?.offer_price;
            });
            if (inputIteration === allPackagesList?.length - 1)
              setPrice(accumulatedPriceForIteration);
            allPackageAddOnMainPrice += accumulatedPriceForIteration;
          }
        }
        setTotalPrice(allPackageAddOnMainPrice);
        setKey(!key);
        // handleOAddOnPriceFunction();
      }
    }

    if (
      reason === "remove-option" &&
      updatePackageId?.length > 0 &&
      originalPackageId?.length > 0
    ) {
      // let difference = originalPackageId.filter(
      //   (x) => !updatePackageId.includes(x)
      // );
      //removePackage(customePackage, difference);
    } else if (reason === "remove-option" && updatePackageId?.length == 0) {
      // removePackage(customePackage, []);
    }
  };
  const removePackage = (customePackage: any, updateArr: any) => {
    if (
      customePackage.length == 0 &&
      memberPackageID.length > 0 &&
      packageList.results?.length > 0
    ) {
      let accumulatedPrice = 0;
      memberPackageID.map((row) => {
        const findPackage = packageList?.results.filter(
          (el: any) => el.id == row
        );
        if (findPackage.length > 0) {
          if (findPackage[0]?.package_city_prices) {
            accumulatedPrice =
              (accumulatedPrice +
                findPackage[0]?.package_city_prices?.offer_price) |
              0;
          } else {
            accumulatedPrice =
              (accumulatedPrice + findPackage[0]?.offer_price) | 0;
          }
        }
      });
      if (accumulatedPrice > 0) {
        let familyPrice: number = Math.floor(accumulatedPrice);
        let totalPriceWithFamily = totalPrice - familyPrice;
        setMemberPackageID([]);
        //setTempTotalPrice(totalPriceWithFamily);
        setPrice(totalPriceWithFamily);
        setTotalPrice(totalPriceWithFamily);
      }
    } else if (
      customePackage.length > 0 &&
      memberPackageID.length > 0 &&
      updateArr.length > 0 &&
      packageList?.results?.length > 0
    ) {
      if (updateArr?.length > 0) {
        let accumulatedPrice = 0;
        let removePackageId = "";
        updateArr.map((row: any) => {
          const findPackage = packageList.results.filter(
            (el: any) => el.id == row
          );

          if (findPackage.length > 0) {
            removePackageId = findPackage[0].id;
            if (findPackage[0]?.package_city_prices) {
              accumulatedPrice =
                (accumulatedPrice +
                  findPackage[0]?.package_city_prices?.offer_price) |
                0;
            } else {
              accumulatedPrice =
                (accumulatedPrice + findPackage[0]?.offer_price) | 0;
            }
          }
        });
        if (accumulatedPrice > 0) {
          let familyPrice: number = Math.floor(accumulatedPrice);
          let totalPriceWithFamily = tempTotalPrice - familyPrice;
          if (removePackageId) {
            let tempArray = new Array();
            memberPackageID.map((row) => {
              if (removePackageId != row) {
                tempArray.push(row);
              }
            });
            setMemberPackageID(tempArray);
          }
          //setTempTotalPrice(totalPriceWithFamily);
          setPrice(totalPriceWithFamily);
          setTotalPrice(totalPriceWithFamily);
        }
      }
    }
  };

  useEffect(() => {
    resetBookings();
  }, []);

  useEffect(() => {
    if (raiseComplaint === true) {
      getQueryTickets("booking_ticket", `booking_id=${bookingId}`);
    }
  }, [raiseComplaint]);

  useEffect(() => {
    if (assignBooking === true) {
      getAgentNewData(`?&usergroup=Doctor`);
    }
  }, [assignBooking]);

  const [bookingId, setBookingId] = useState<any>(match.params.id);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    setPersoneId(bookingId);
    if ("true" === queryParams.get("edit")) {
      getBookingById(`${bookingId}`);
    } else {
      getBookingById(`${bookingId}`);
    }
    getBookingVerificationOtp(`${bookingId}/`);
  }, [bookingId]);

  useEffect(() => {
    if (!!booking && booking?.pk) {
      setBookingId(booking?.pk);
    }
  }, [booking]);

  const getSlots = () => {
    getAvailableSlots(
      booking && booking?.collection_date && booking?.collection_date,
      booking?.customer_zone
    );
  };
  useEffect(() => {
    if (booking?.pk == bookingId && booking?.cityid) {
      booking?.coupon_applied && setCoupon(booking?.coupon_applied.id);
      booking?.coupon_applied && setCouponCode(booking?.coupon_applied?.name);
      booking?.discounted_price &&
        setTotalPrice(booking.discounted_price?.final_total_price);
      booking?.discounted_price &&
        setTempTotalPrice(booking.discounted_price?.final_total_price);
      setPhleboCostForVew(
        booking?.discounted_price &&
        booking?.discounted_price.phlebo_cost &&
        Math.floor(booking?.discounted_price.phlebo_cost)
      );
      booking?.discounted_price &&
        setPrice(booking.discounted_price?.final_total_price);
      setIsInternationalNumber(booking?.is_international_number);
      booking?.cityid && setCityId(booking?.cityid);
      setPackageDetailsMainMember(booking?.packages);
      setPhleboRemarks(booking?.phlebo_remarks);
      setPackageDetailsAdditionalMembers(
        booking?.additional_members
          ?.filter(
            (mem: any) =>
              mem?.pickup_status !== "cancelled" &&
              mem?.booking_status !== "cancelled"
          )
          .map((p: any) => {
            return p.packages;
          })
      );
    }
  }, [booking]);

  useEffect(() => {
    if (packageDetailsAdditionalMembers?.length === 1) {
      let list;
      list = packageDetailsAdditionalMembers[0];
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 2) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(
        packageDetailsAdditionalMembers[1]
      );
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 3) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(
        packageDetailsAdditionalMembers[1]?.concat(
          packageDetailsAdditionalMembers[2]
        )
      );
      setPackageDetailsAdditionalMembersNewArray(list);
    } else if (packageDetailsAdditionalMembers?.length === 4) {
      let list;
      list = packageDetailsAdditionalMembers[0]?.concat(
        packageDetailsAdditionalMembers[1]?.concat(
          packageDetailsAdditionalMembers[2]?.concat(
            packageDetailsAdditionalMembers[3]
          )
        )
      );
      setPackageDetailsAdditionalMembersNewArray(list);
    }
  }, [packageDetailsAdditionalMembers]);

  const [isHitPackageAPI, setIsHitPackageAPI] = useState(false);

  useEffect(() => {
    if (isHitPackageAPI === true) {
      if (booking?.cityid !== undefined) {
        getPackage(`city_id=${booking?.cityid}`);
      }
    }
  }, [isHitPackageAPI]);

  useEffect(() => {
    if (customer_designation === "Mr" || customer_designation === "Baby Boy") {
      setCustomer_gender("male");
    } else if (
      customer_designation === "Mrs" ||
      customer_designation === "Baby Girl" ||
      customer_designation === "Miss" ||
      customer_designation === "Ms" ||
      customer_designation === "Smt"
    ) {
      setCustomer_gender("female");
    }
  }, [customer_designation]);

  useEffect(() => {
    if (booking?.pk == bookingId) {
      setLoadingButton(true);
      setMemberType("main");
      setBillId(booking?.bill_id);
      setCustomerId(booking?.customerid);
      setTimeout(() => {
        setLoadingButton(false);
      }, 1000);
      if (booking?.is_hiv_form !== null && booking?.is_hiv_form === true) {
        setShowHIVForm(true);
      } else {
        setShowHIVForm(false);
      }
      getResamplingBookingByID(`${bookingId}/`);
      if (booking?.lead !== null && booking?.lead > 0) {
        getNewLeadDetails(`${booking?.lead}/?booking_id=${bookingId}`, "true");
      }
    }
  }, [booking]);

  const history = useHistory();

  const cancelHandler = () => {
    const data: any = {
      booking_status: "cancelled",
    };
    updateBooking(data, bookingId);
    history.push(`/dashboard/ts`);
  };

  const handleAssignDoctor = () => {
    const data: any = {
      doctor,
      booking: Number(bookingId),
      status: "pending",
    };
    assignDoctor(data);
    setAssignBooking(false);
  };

  useEffect(() => {
    if (
      newLeadDetails &&
      newLeadDetails?.subscriptions?.length > 0 &&
      newLeadDetails?.subscriptions[0]?.discount > 0
    ) {
      setAlreadyARedPro(true);
      setRedProMembershipDiscount(
        Math.floor(
          (booking?.discounted_price?.total_price_package / 100) *
          newLeadDetails?.subscriptions[0]?.discount
        )
      );
    }
  }, [newLeadDetails]);

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    setCallButton(false);
    clickToCallBooking(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  useEffect(() => {
    if (packageDetailsAdditionalMembersMinusThis !== undefined) {
      if (
        packageDetailsAdditionalMembersMinusThis?.length === 0 &&
        booking?.packages?.length > 0
      ) {
        setPackageDetailsForAllMembers([booking?.packages]);
      } else if (
        packageDetailsAdditionalMembersMinusThis?.length > 0 &&
        booking?.packages?.length > 0
      ) {
        setPackageDetailsForAllMembers([
          ...packageDetailsAdditionalMembersMinusThis,
          booking?.packages,
        ]);
      }
    }
  }, [packageDetailsAdditionalMembersMinusThis]);

  useEffect(() => {
    if (packageDetailsForAllMembers !== undefined) {
      handlePackages(packageDetails, "");
    }
  }, [packageDetailsForAllMembers]);

  const handleCancelBooking = () => {
    const data: any = {
      booking_status: "cancelled",
    };
    updateBooking(data, bookingId);
    history.push(`/dashboard/ts`);
  };
  const handlePaymentSend = () => {
    getPaymentSendLink(booking.pk);
    alert("Payment Link Send Successfully");
    setTimeout(() => {
      getBookingById(`${bookingId}`);
    }, 2000);
  };
  const handleRefreshPaymentSend = () => {
    getPaymentSendLink(booking.pk);
    alert("Fresh Payment Link Send Successfully");
    setTimeout(() => {
      getBookingById(`${bookingId}`);
    }, 2000);
  };
  const TooltipReportAndDietConsultationContent = () => (
    <small>
      <ul style={{ paddingLeft: "15px" }}>
        <li style={{ color: "black", fontSize: "16px" }}>
          This fee is collected to ensure the safety, handling and integrity of
          your sample. We follow the following processes and guidelines to
          ensure the highest quality standards
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          Vaccinated phlebotomists .
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          .⁠⁠Company-provided sanitized sealed kits
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          Special temp control bags with temperature display to protect the
          sample integrity
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          ⁠Internal Lab Quality Control
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          Assured Convenience with Home Sample Collection
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          ⁠Safety and Emergency Care Training
        </li>
      </ul>
    </small>
  );
  const handlePaymentReSend = () => {
    getPaymentReSendLink(booking.pk);
    alert("Payment Link ReSend Successfully");
  };
  const handlePaymentCancel = () => {
    getPaymentCancelLink(booking.pk);
    alert("Payment Link Cancel Successfully");
  };
  const handleSendReportSms = () => {
    if (personId !== "none") {
      if (bookingId == personId) {
        if (billId !== null) {
          getSmsReportSend(`${personId}/booking`);
          setReportSendStatus(true);
          alert("Report SMS Send Successfully");
        } else {
          alert("Bill Id is Not Generated");
        }
      } else if (bookingId != personId) {
        if (additionalBillId !== null) {
          getSmsReportSend(`${personId}/add_booking`);
          setReportSendStatus(true);
          alert("Report SMS Send Successfully");
        } else {
          alert("Family Member Bill Id is Not Generated");
        }
      }
    }
  };

  useEffect(() => {
    if (!openReportModel) {
      setCustomerDetails("");
    }
  }, [openReportModel]);
  const handleSendReport = (data: any) => {
    setCustomerDetails(data);
    setObjId(data?.id);
    setOpenReportModel(!openReportModel);
  };
  const handleCreateCoupon = () => {
    setOpenCreateCouponModal(true);
    getCustomerSpecificCoupon(`?created_on_booking=${bookingId}`);
  };
  const handleSMSSend = () => {
    getSMSLink(booking.pk);
    alert("SMS Sent Successfully");
  };

  useEffect(() => {
    if (personId !== "none") {
      if (bookingId == personId && billId !== null) {
        if (billId !== null) {
          getReportDownload(`${bookingId}/`);
          getPrescriptionDataByBookingId(`booking_id=${bookingId}`);
        } else {
          alert("Bill Id is Not Generated");
        }
      } else {
        if (additionalBillId !== null) {
          getReportDownload(`${personId}/?additional_id=true`);
          getPrescriptionDataByBookingId(`booking_id=${personId}`);
        } else {
          alert("Family Member Bill Id is Not Generated");
          setAdditionalBillId("");
        }
      }
    }
  }, [personId]);

  useEffect(() => {
    if (CustomerId) {
      getviewPackageAnswer(
        `unique_customer__id=${CustomerId}&question__is_allergy=false`
      );
      getviewPackageAnswer(
        `unique_customer__id=${CustomerId}&question__is_allergy=true`,
        true
      );
    }
  }, [CustomerId]);

  const handleReportStatus = () => {
    if (personId !== "none") {
      if (bookingId == personId) {
        if (billId !== null && billId !== undefined) {
          getReportStatus(`?booking_id=${bookingId}`);
          setReportModal(true);
        } else {
          alert("Bill Id is Not Generated");
        }
      } else if (bookingId != personId) {
        if (additionalBillId !== null && additionalBillId !== undefined) {
          getReportStatus(`?add_member_booking=${personId}`);
          setReportModal(true);
        } else {
          alert("Family Member Bill Id is Not Generated");
        }
      }
    }
  };
  const handleCollectionPhotos = () => {
    setOpenSampleModel(true);
    if (personId !== "none") {
      if (bookingId == personId) {
        {
          getBookingSampleImage(bookingId);
        }
      } else if (bookingId != personId) {
        getBookingSampleImage(`${personId}`);
      }
    }
  };

  const handleTransferPhotos = () => {
    setOpenTransferModal(true);
    if (personId !== "none") {
      if (bookingId == personId) {
        {
          getBookingSampleImage(bookingId);
        }
      } else if (bookingId != personId) {
        getBookingSampleImage(`${personId}`);
      }
    }
  };
  const handlePhleboSelfie = () => {
    setOpenSefileModel(true);
    getPhleboBookingSelfie(`booking=${bookingId}`);
  };


  const handleClinicalHistory = () => {
    setOpenPdfForClinicalTable(true);
  };

  const handleViewOtp = () => {
    setViewOtp(true);
  };

  useEffect(() => {
    if (personId !== "none" && String(personId) === String(bookingId)) {
      getHivPackage(`?booking=${Number(bookingId)}`);
    } else if (personId !== "none" && String(personId) !== String(bookingId)) {
      getHivPackage(`?add_member_booking=${personId}`);
    }
  }, [personId]);

  useEffect(() => {
    if (personId !== "none" && String(personId) === String(bookingId)) {
      getBiomarkerScreening(`booking_id=${personId}`);
    } else if (personId !== "none" && String(personId) !== String(bookingId)) {
      getBiomarkerScreening(`add_member_booking=${personId}`);
    }
  }, [personId]);

  useEffect(() => {
    getPhleboRecording(`?booking_id=${bookingId}`);
  }, [bookingId]);

  const handleChange = (event: any, data: any) => {
    if (event.target.value == bookingId) {
      setLoadingButton(true);
      setMemberType("main");
      setBillId(data?.bill_id);
      setCustomerId(booking?.customerid);
      setTimeout(() => {
        setLoadingButton(false);
      }, 1000);
      if (data?.is_hiv_form !== null && data?.is_hiv_form === true) {
        setShowHIVForm(true);
      } else {
        setShowHIVForm(false);
      }
    } else {
      setLoadingButton(true);
      setTimeout(() => {
        setLoadingButton(false);
      }, 2000);
      setMemberType("additional");
      const additionDetails = data?.additional_members.find(
        (bId: any) => bId.id === event.target.value
      );
      if (
        additionDetails?.is_hiv_form !== null &&
        additionDetails?.is_hiv_form === true
      ) {
        setShowHIVForm(true);
      } else {
        setShowHIVForm(false);
      }
      setAdditionalBillId(additionDetails?.bill_id);
      setCustomerId(additionDetails?.customerid);
      setMemberDetails(additionDetails);
    }
    setPersoneId(event.target.value);
  };

  const handleDownloadReport = (report: any) => {
    if (report?.message === "Your report is not ready yet") {
      alert(report?.message);
    } else {
      var b64 = report?.allReportDetails?.reportDetails;
      var bin = window?.atob(b64);
      var link = document?.createElement("a");
      link.download = `${report?.allReportDetails?.Patient_Name}-${report?.allReportDetails?.Age}.pdf`;
      link.href = "data:application/octet-stream;base64," + b64;
      link.click();
    }
  };
  const handleAddFamily = (id: any) => {
    history.push(`/dashboard/ts/booking-additionalFamily/${id}/add`);
  };
  const handleIndexing = () => {
    let body = {
      booking_id: bookingId,
    };
    PostBookingIndexing(body);
    history.push(`/dashboard/ts/booking-view/${bookingId}`);
  };

  const handleSendReportOnWhatsApp = (data: any) => {
    setOpenReportOnWhatsAppModel(true);
  };

  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [openPdfTable, setOpenPdfTable] = useState<boolean>(false);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<any>("");
  const [fileLink, setFileLink] = useState<any>("");
  const [docs, setDocs] = useState<any>([]);
  const [openSampleModel, setOpenSampleModel] = useState<boolean>(false);
  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const [openSefileModel, setOpenSefileModel] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };
  const handlePdfModelTableClose = () => {
    setOpenPdfTable(false);
  };
  const handleSampleModel = () => {
    setOpenSampleModel(false);
  };
  const handleTransferModel = () => {
    setOpenTransferModal(false);
  };
  const handleViewPdf = () => {
    setOpenPdf(true);
  };

  const handleOpenModal = () => {
    setOpenUploadModal(true);
  };
  const handleCloseModal = () => {
    setOpenUploadModal(false);
  };

  const [imgSrc, setImgSrc] = useState<any>("");
  const [file1, setFile1] = React.useState<any>("");

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const fileSelectedHandler1 = async (e: React.ChangeEvent<{ files: any }>) => {
    setFile1(e.target.files[0]);
  };

  const documentUploadFunction = async () => {
    const formData = new FormData();
    formData.append("booking", bookingId);
    formData.append("remarks", remarks);
    formData.append("file", file1);
    setOpenUploadModal(false);
    setFile1("");
    setRemarks("");
    await postUploadBookingDocument(formData);
  };
  //invoice chnages
  const [invoicePdf, setInvoicePdf] = useState<boolean>(false);
  const [invoicefileLink, setInvoicefileLink] = useState<any>("");
  const handleInvoice = (booking: any) => {
    setInvoicePdf(true);
    getBookingInvoice(`booking=${booking.pk}`);
  };

  useEffect(() => {
    if (bookingInvoie && bookingInvoie?.length > 0) {
      setInvoicefileLink(bookingInvoie[0]?.invoice_pdf);
    } else if (bookingInvoie && bookingInvoie?.length == 0) {
      setInvoicefileLink("");
    }
  }, [bookingInvoie]);
  const [phleboRecording, setPhleboRecordingModel] = useState<any>("");
  const handleClosePhleboRecording = () => {
    setPhleboRecordingModel(false);
  };
  const handlePrescriptionData = () => {
    setGenomicsPrescriptionData(true);
    getPrescriptionDataByBookingId(`booking_id=${bookingId}`);
  };
  const HandleGenomicsPrescriptionDataClose = () => {
    setGenomicsPrescriptionData(false);
  };

  const handleInvoiceChange = (event: any) => {
    setInvoiceType(event.target.value)
  }

  const handleViewInvoice = () => {
    getInvoiceByBookingID(bookingId, invoiceType === "multipleInvoice" ? true : false);
    setPdfViewerModal(true);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.leftContentHeader}>
            <h6> Booking (ID: {bookingId})</h6>
          </div>
          <div className={classes.rightContentHeader}>
            {/* {booking.report_status === "consolidate" || booking?.booking_status === "cancelled" || booking?.pickup_status === "cancelled" || booking?.org_type === "imaging"?
              "" : (
                booking.booking_status === "oldresampling" || booking.booking_status === "resampling" ? "" : (
                  <Button
                    onClick={() => handleAddFamily(booking.pk)}
                    variant="contained"
                    style={{ marginLeft: "1rem", marginBottom: "1rem", backgroundColor: "#7b1fa2" }}
                  >
                    Add Family Member
                  </Button>
                )
              )} */}
            {booking?.booking_status === "cancelled" ||
              booking?.pickup_status === "cancelled" ? (
              ""
            ) : booking.booking_status === "oldresampling" ? (
              ""
            ) : (

              <Link
                to={`/dashboard/ts/${booking.org_type == "homedx"
                    ? "booking-edit"
                    : "booking-imaging-edit"
                  }/${booking.pk}/edit`}
                style={{ textDecoration: "none" }}
              >
                {booking?.org_type === "imaging" ? (
                  ""
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                    onClick={() =>
                      dispatch({ type: ACTIONS.GET_BOOKING_BY_ID, payload: {} })
                    }
                  >
                    Edit Booking
                  </Button>
                )}
              </Link>
            )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                onClick={() => handleSMSSend()}
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#e85fed",
                }}
              >
                Send Review SMS
              </Button>
            )}

            {showHIVForm && (
              <Button
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#2bb1d5",
                }}
                onClick={() => setOpenHIVForm(true)}
              >
                HIV Consent Form
              </Button>
            )}

            {/* <Button
              onClick={() => {
                setOpenPrescriptionSendModal(true)
              }}
              disabled={personId === "none"}
              variant="contained"
              style={{ width: "200px", backgroundColor: (personId === "none") ? "rgba(0, 0, 0, 0.12)" : "rgb(255 134 52)", marginLeft: "1rem", marginBottom: "1rem", }}
            >
              Send Prescription
            </Button> */}

            {booking.booking_status === "pending" &&
              booking.booking_status !== "cancelled" && (
                <Button
                  variant="contained"
                  color="error"
                  style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                  onClick={() => handleCancelBooking()}
                  disabled={booking?.org_type === "imaging"}
                >
                  Cancel
                </Button>
              )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                disabled={
                  booking.plink_id === null ||
                  booking.pickup_receive_amount !== null
                }
                onClick={() => handlePaymentCancel()}
                variant="contained"
                color="error"
                style={{ marginLeft: "1rem", marginBottom: "1rem" }}
              >
                Cancel Payment Link
              </Button>
            )}

            {booking &&
              booking?.booking_type !== "b2b" &&
              booking?.org_type !== "imaging" ? (
              <Button
                onClick={handleCreateCoupon}
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "rgb(87 7 204)",
                }}
              >
                Create Coupon
              </Button>
            ) : (
              ""
            )}
           
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                onClick={() => handleSendReport(booking)}
                disabled={
                  personId === "none" ||
                  booking.report_status === "pending" ||
                  booking.report_status === "none" ||
                  booking.report_status === null
                }
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginTop: "-10px",
                  background: "#00e5ff",
                  color: "white",
                }}
              >
                Send Report
              </Button>
            )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                disabled={
                  booking.plink_id !== null ||
                  booking.pickup_receive_amount !== null
                }
                onClick={() => handlePaymentSend()}
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#62f57a",
                }}
              >
                Send Payment Link
              </Button>
            )}

            <Button
              variant="contained"
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                backgroundColor: "#2962ff",
              }}
              onClick={() => handleIndexing()}
            >
              Refresh
            </Button>
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                disabled={booking.plink_id === null}
                onClick={() => handleRefreshPaymentSend()}
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#62f57a",
                }}
              >
                Fresh Payment Link
              </Button>
            )}

            {booking?.report_status === "partial" ||
              booking?.report_status === "consolidate" ? (
              <Button
                variant={!assignBooking ? "contained" : "outlined"}
                color="primary"
                style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                onClick={() => {
                  getDoctorBookings(`booking_id=${booking?.pk}`);
                  setAssignBooking(!assignBooking);
                }}
              >
                Assign a Doctor
              </Button>
            ) : (
              ""
            )}
            {booking &&
              booking?.booking_type !== "b2b" &&
              booking?.org_type !== "imaging" ? (
              <Button
                variant={!raiseComplaint ? "contained" : "outlined"}
                color="primary"
                style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                onClick={() => setRaiseComplaint(!raiseComplaint)}
              >
                Raise a Ticket
              </Button>
            ) : (
              ""
            )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                variant="contained"
                style={{
                  background: "#2196f3",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
                onClick={handleOpenModal}
              >
                Upload Documents
              </Button>
            )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                variant="contained"
                style={{
                  background: "rgb(16 108 7)",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
                onClick={() => {
                  setOpenPdfTable(true);
                  getBookingDocument(bookingId);
                }}
              >
                Uploaded Documents
              </Button>
            )}

            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                variant="contained"
                style={{
                  background: "#006064",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
                onClick={() => {
                  handleCollectionPhotos();
                }}
                disabled={personId === "none"}
              >
                Collection photos
              </Button>
            )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                variant="contained"
                style={{
                  background: "#F97971",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
                onClick={() => {
                  handleTransferPhotos();
                }}
                disabled={personId === "none"}
              >
                Transfer photos
              </Button>
            )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                variant="contained"
                style={{
                  background: "rgb(47 68 125)",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
                onClick={() => {
                  handlePhleboSelfie();
                }}
                disabled={personId === "none"}
              >
                Phlebo Selfie
              </Button>
            )}
            {(viewPackageAnswer && viewPackageAnswer?.length > 0) ||
              (viewPackageAnswerAllergy &&
                viewPackageAnswerAllergy?.length > 0) ? (
              <Button
                onClick={handleClinicalHistory}
                variant="contained"
                style={{
                  background: "#795548",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
              >
                CLINICAL/ALLERGY HISTORY
              </Button>
            ) : (
              ""
            )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#9c27b0",
                }}
                onClick={() => setOpenInvoiceModel(!openInvoiceModel)}
                disabled={booking.patientId == "null"}
              >
                Receipt
              </Button>
            )}
            {booking?.send_invoice === true ? (
              <Button
                variant="contained"
                startIcon={<ReceiptIcon />}
                color="primary"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#145772",
                }}
                onClick={() => handleInvoice(booking)}
                disabled={booking.patientId == "null"}
              >
                invoice
              </Button>
            ) : (
              ""
            )}
            <Button
              variant="contained"
              color="primary"
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                backgroundColor: "#78909c",
              }}
              onClick={() => handleViewOtp()}
              disabled={bookingOtp?.otp === null}
            >
              View Otp
            </Button>
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Select
                className="input"
                name="priority"
                variant="outlined"
                style={{
                  width: "17%",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  alignItems: "flex-start",
                  height: "45px",
                }}
                value={personId}
                onChange={(e: any) => handleChange(e, booking)}
              >
                <MenuItem disabled selected value={"none"}>
                  Customer Name
                </MenuItem>
                <MenuItem value={booking?.pk}>
                  {booking?.customer_name}
                </MenuItem>
                {booking.additional_members &&
                  booking.additional_members.length > 0 &&
                  booking.additional_members.map((data: any) => {
                    return (
                      <MenuItem value={data?.id}>
                        {data?.customer_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                onClick={handleReportStatus}
                variant="contained"
                disabled={personId === "none"}
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#ffbf00",
                }}
              >
                REPORT STATUS
              </Button>
            )}

            {booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                onClick={() => handleDownloadReport(reportDownload)}
                disabled={
                  personId === "none" ||
                  booking?.report_status === "na" ||
                  booking?.report_status === "pending" ||
                  booking?.report_status === "none" ||
                  booking?.report_status === null ||
                  reportLoading
                }
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  background: "#90caf9",
                }}
                startIcon={<DownloadForOfflineIcon />}
                variant="outlined"
              >
                Download Report
              </Button>
            )}

            <Select
              className="input"
              name="priority"
              variant="outlined"
              style={{
                width: "17%",
                marginLeft: "1rem",
                marginBottom: "1rem",
                alignItems: "flex-start",
                paddingRight: "1rem",
              }}
              placeholder="Select invoice"
              value={invoiceType}
              onChange={(e: any) => handleInvoiceChange(e)}
            >
              <MenuItem disabled selected value={"none"}>
                Invoice Type
              </MenuItem>
              <MenuItem value={"singleInvoice"}>Single Invoice</MenuItem>
              <MenuItem value={"multipleInvoice"}>Multiple Invoice</MenuItem>
            </Select>

            <Button
              onClick={() => handleViewInvoice()}
              variant="contained"
              disabled={invoiceType === "" || isloading}
              style={{
                marginLeft: "1rem",
                marginTop: "-10px",
                background: invoiceType === "" || isloading ? "rgba(0 0 0 0.12)" : "rgb(145 90 9)",
                color: invoiceType === "" || isloading ? "rgba(0 0 0 0.26)" : "white",
              }}
            >
              Generate Invoice
            </Button>

            {booking?.pickup_status === "confirmed" && (
              <Link
                to={`/dashboard/ts/download/${booking.pk}/download`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                >
                  TRF
                </Button>
              </Link>
            )}

            {hivpackageList && hivpackageList.length !== 0 ? (
              <Link
                to={`/dashboard/ts/salesleadadmin-hiv/${booking.pk}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  disabled={hivpackageList.length === 0}
                  style={{
                    background: "#1976d2",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Consent Form
                </Button>
              </Link>
            ) : (
              ""
            )}

            {(biomarkerScreening &&
              biomarkerScreening?.results &&
              biomarkerScreening?.results?.length !== 0) ||
              booking?.org_type === "imaging" ? (
              ""
            ) : (
              <Button
                variant="contained"
                style={{
                  background: "#1976d2",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#aa00ff",
                }}
                startIcon={<PregnantWomanIcon style={{ color: "#4a148c" }} />}
                onClick={() => setOpenMaternalModel(true)}
                disabled={isBtnEnable}
              >
                Create Maternal Marker Form
              </Button>
            )}

            {biomarkerScreening &&
              biomarkerScreening.results &&
              biomarkerScreening.results.length !== 0 ? (
              <Link
                to={`/dashboard/ts/salesleadadmin-BiomarkerScreeningreport/${personId}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "#1976d2",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                  disabled={personId === "none"}
                >
                  Double Marker Form
                </Button>
              </Link>
            ) : (
              ""
            )}
            {phleboRedocrding?.length > 0 && (
              <Button
                onClick={() => setPhleboRecordingModel(true)}
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#5d4037",
                }}
              >
                View Phlebo Recording
              </Button>
            )}
            <Button
              onClick={() => handlePrescriptionData()}
              variant="contained"
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                backgroundColor: "#0097a7",
              }}
            >
              Genomics Prescription Data
            </Button>
          </div>
        </div>
        <div className={classes.contentTable}>
          <Dialog
            open={cancelAlert}
            onClose={() => setCancelAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure!!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your booking with booking Id: {bookingId} will be cancelled.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCancelAlert(false)} color="primary">
                Discard
              </Button>
              <Button onClick={() => cancelHandler()} color="primary" autoFocus>
                Proceed
              </Button>
            </DialogActions>
          </Dialog>

          <RaiseComplaintModal
            open={raiseComplaint}
            setOpen={setRaiseComplaint}
            bookingId={Number(bookingId)}
            bookingComplaints={bookingComplaints}
            leadId={0}
            booking={booking}
            totalPrice={totalPrice}
          />
          {assignBooking && (
            <Paper className={classes.paper} elevation={15}>
              <h5>Doctor History</h5>
              <TableContainer className={classes.tableContainer}>
                {
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          Doctor Name
                        </StyledTableCell>
                        <StyledTableCell align="center">Status</StyledTableCell>
                        <StyledTableCell align="center">
                          Feedback
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Created At
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Completed Date
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Updated At
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Call Later Time
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {doctorConsultationBooking &&
                        doctorConsultationBooking?.results &&
                        doctorConsultationBooking?.results?.length > 0 &&
                        doctorConsultationBooking?.results?.map(
                          (data: any, index: any) => {
                            return (
                              <StyledTableRow key={index}>
                                {data?.doctor?.user?.usergroup === "Doctor" ? (
                                  <>
                                    <StyledTableCell align="center">
                                      {data?.doctor?.user?.fullname}
                                    </StyledTableCell>
                                  </>
                                ) : (
                                  <>
                                    <StyledTableCell align="center">
                                      SYSTEM
                                    </StyledTableCell>
                                  </>
                                )}
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {data?.status}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {data?.feedback ? data?.feedback : "NA"}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {new Date(data?.created_at).toLocaleString()}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {data?.completed_date
                                    ? new Date(
                                      data?.completed_date
                                    ).toLocaleString()
                                    : "NA"}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {data?.updated_at
                                    ? new Date(
                                      data?.updated_at
                                    ).toLocaleString()
                                    : "NA"}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {data?.call_later_time
                                    ? new Date(
                                      data?.call_later_time
                                    ).toLocaleString()
                                    : "NA"}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          }
                        )}
                    </TableBody>
                    {/* <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={3}
                                    count={doctorConsultationBooking.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter> */}
                  </Table>
                }
              </TableContainer>
              <h3 className={classes.heading} style={{ textAlign: "center" }}>
                Assign Booking To Doctor
              </h3>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="doctor"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setDoctor(obj.id);
                        setDoctorName(obj?.username);
                      }
                    }}
                    options={agentsListnewdata.results || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => option?.username}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getAgentNewData(`?${newInputValue}&usergroup=Doctor`);
                      }, 1000);
                      if (
                        newInputValue.length === 0 ||
                        newInputValue !== doctorName
                      ) {
                        setDoctor(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Doctor Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={doctor === 0}
                    onClick={handleAssignDoctor}
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
          <ul>
            <li
              style={{
                marginTop: "50px",
                marginLeft: "20px",
                fontWeight: "bolder",
                fontFamily: "inherit",
                color: "#2979ff",
              }}
            >
              <p>
                After you edit the booking It will take max 2 minutes for the
                edits to reflect in the booking?. To get the edits instantly,
                kindly click on refresh button.
              </p>
            </li>
          </ul>
          {Object.keys(newLeadDetails).length === 0 ? (
            ""
          ) : newLeadDetails?.is_first_booking === true ||
            Number(bookingId) === newLeadDetails?.is_first_booking ? (
            <ul>
              <li
                style={{
                  fontWeight: "bolder",
                  marginLeft: "20px",
                  fontFamily: "inherit",
                  color: "#2979ff",
                }}
              >
                <p>
                  Since this is their first booking, they’ll get 100% Redcash on
                  this booking.
                </p>
              </li>
              {booking && booking?.source_type === "labwalkins" && (
                <li
                  style={{
                    fontWeight: "bolder",
                    marginLeft: "20px",
                    fontFamily: "inherit",
                    color: "#bb0979",
                  }}
                >
                  <p>
                    This is a Lab Walking Booking. Please check accordingly.
                  </p>
                </li>
              )}
            </ul>
          ) : (
            <ul>
              <li
                style={{
                  fontWeight: "bolder",
                  marginLeft: "20px",
                  fontFamily: "inherit",
                  color: "#2979ff",
                }}
              >
                <p>
                  Since the customer has already done a booking (through there
                  primary/alternate/WhatsApp) no., they will get 5% Redcash on
                  this booking.
                </p>
              </li>
              {booking && booking?.source_type === "labwalkins" && (
                <li
                  style={{
                    fontWeight: "bolder",
                    marginLeft: "20px",
                    fontFamily: "inherit",
                    color: "#bb0979",
                  }}
                >
                  <p>
                    This is a Lab Walking Booking. Please check accordingly.
                  </p>
                </li>
              )}
              {booking && booking?.source_type === "ccwalkins" && (
                <li
                  style={{
                    fontWeight: "bolder",
                    marginLeft: "20px",
                    fontFamily: "inherit",
                    color: "#bb0979",
                  }}
                >
                  <p>This is a CC Walking Booking. Please check accordingly.</p>
                </li>
              )}
            </ul>
          )}
          {resamplingBooking &&
            resamplingBooking?.data &&
            Object.keys(resamplingBooking?.data).length > 0 && (
              <ul>
                <li
                  style={{
                    marginLeft: "20px",
                    fontWeight: "bolder",
                    fontFamily: "sans-serif",
                    color: "#bb0979",
                    fontSize: "15px",
                  }}
                >
                  Previous Booking Phlebo Name :
                  <span style={{ color: "#022d91" }}>
                    {resamplingBooking &&
                      resamplingBooking?.data &&
                      resamplingBooking?.data.last_booking_phlebo}
                  </span>
                </li>
                <li
                  style={{
                    marginLeft: "20px",
                    fontWeight: "bolder",
                    fontFamily: "sans-serif",
                    color: "#bb0979",
                    fontSize: "15px",
                  }}
                >
                  Reason Of Resampling (told to customer) :
                  <span style={{ color: "#022d91" }}>
                    {resamplingBooking &&
                      resamplingBooking?.data &&
                      resamplingBooking?.data.remarks?.toUpperCase()}
                  </span>
                </li>
                <li
                  style={{
                    marginLeft: "20px",
                    fontWeight: "bolder",
                    fontFamily: "sans-serif",
                    color: "#bb0979",
                    fontSize: "15px",
                  }}
                >
                  previous booking id:
                  <span style={{ color: "#022d91" }}>
                    {booking &&
                      booking?.resampling_details &&
                      booking?.resampling_details?.last_booking &&
                      booking?.resampling_details?.last_booking.map(
                        (item: any) => <>{item !== booking?.pk && item + ","}</>
                      )}
                  </span>
                </li>
              </ul>
            )}
          <ul>
            {isFreeWebBookingTest && (
              <li
                style={{
                  fontWeight: "bolder",
                  marginLeft: "20px",
                  fontFamily: "inherit",
                  color: "#bb0979",
                }}
              >
                <p>
                  Please note that free test against selected test & package
                  features are only available on the Consumer Application and
                  cannot be fulfilled or supported by agents through CRM. Guide
                  the users that they can access this feature
                  only on their app..
                </p>
              </li>
            )}
          </ul>
          <Paper className={classes.paper} elevation={15}>
            {bookingIdLoader ? (
              <Loader />
            ) : (
              <>
                {booking?.urgent_booking === true && (
                  <h3
                    style={{
                      paddingTop: "10px",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#db20bb",
                      textAlign: "right",
                    }}
                  >
                    URGENT BOOKING
                  </h3>
                )}
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                  spacing={3}
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      style={{ margin: "1rem auto" }}
                    >
                      <Grid item md={4}>
                        <h3
                          style={{
                            paddingTop: "10px",
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                        >
                          {booking?.org_type === "homedx"
                            ? "HOME-DX BOOKING DETAILS"
                            : "IMAGING BOOKING DETAILS"}
                          {alreadyARedPro ? (
                            <p style={{ color: "red" }}>VIP MEMBER</p>
                          ) : (
                            ""
                          )}
                          {newLeadDetails &&
                            newLeadDetails?.subscriptions?.length > 0 &&
                            new Date(
                              newLeadDetails?.subscriptions[0]?.created_at
                            ).setSeconds(0, 0) >
                            new Date(booking?.created_at).setSeconds(0, 0) ? (
                            alreadyARedPro &&
                              booking?.discounted_price
                                ?.subscription_discount_amount === 0 ? (
                              <p
                                style={{
                                  color: "rgb(16 118 61)",
                                  fontSize: "14px",
                                  margin: "0px",
                                  width: "915px",
                                }}
                              >
                                VIP membership was purchased after this booking
                                was created, hence VIP discount is not
                                applicable on this booking.
                              </p>
                            ) : (
                              <></>
                            )
                          ) : (
                            ""
                          )}
                          {booking && booking?.pp_package === true && (
                            <p style={{ color: "red" }}> PP Test</p>
                          )}
                        </h3>
                      </Grid>
                      <Grid item md={2}>
                        {booking && booking?.source_type === "labwalkins" && (
                          <h3
                            style={{
                              paddingTop: "10px",
                              fontSize: "24px",
                              fontWeight: "bold",
                              color: "#bb0979",
                            }}
                          >
                            Lab:{booking && booking?.lab}
                          </h3>
                        )}
                      </Grid>
                      <Grid item md={1}>
                        {resamplingBooking &&
                          resamplingBooking?.data &&
                          Object.keys(resamplingBooking?.data).length > 0 && (
                            <h3
                              style={{
                                paddingTop: "10px",
                                fontSize: "24px",
                                fontWeight: "bold",
                                color: "#bb0979",
                              }}
                            >
                              RESAMPLE (COUNT:{" "}
                              {resamplingBooking &&
                                resamplingBooking?.data &&
                                resamplingBooking?.data.count}
                              )
                            </h3>
                          )}
                      </Grid>
                      <Grid item md={1}>
                        <h3
                          style={{
                            paddingTop: "10px",
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                        >
                          {booking?.centre_address?.name
                            ? "CENTRE : " +
                            String(
                              booking?.centre_address?.name
                            ).toUpperCase()
                            : ""}
                        </h3>
                      </Grid>
                      <Grid item md={4}>
                        <h3
                          style={{
                            paddingTop: "10px",
                            fontSize: "24px",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          BOOKING (ID: {bookingId})
                          {
                            <p
                              style={{
                                color: "red",
                                fontSize: "16px",
                                fontWeight: "400",
                              }}
                            >
                              ({`${booking?.booking_type?.toUpperCase()}`}
                              {booking?.booking_sub_type === "cc" ||
                                booking?.booking_sub_type === "dc"
                                ? ` - ${booking?.booking_sub_type?.toUpperCase()}`
                                : ""}
                              {booking && booking?.source_type === "DSA"
                                ? "-DSA"
                                : ""}
                              )
                            </p>
                          }
                        </h3>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={4}
                    style={{ textAlign: "start", paddingLeft: "32px" }}
                  >
                    <h6>Collection Date</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.collection_date}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={4}
                    style={{ textAlign: "end", paddingRight: "32px" }}
                  >
                    <h6>Collection Time</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.collection_slot &&
                        moment(
                          `${booking.collection_slot.slot.split("-")[0]}`,
                          ["HH.mm.ss"]
                        ).format("hh:mm A")}
                      {" - "}
                      {booking.org_type == "homedx"
                        ? booking.collection_slot &&
                        moment(
                          `${booking.collection_slot.slot.split("-")[1]}`,
                          ["HH.mm.ss"]
                        ).format("hh:mm A")
                        : "Onwards"}
                    </p>
                  </Grid>

                  {booking?.delivery_date !== null ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{ textAlign: "start", paddingLeft: "32px" }}
                      >
                        <h6>Container Delivery Date</h6>
                        <p style={{ fontWeight: "normal" }}>
                          {booking?.delivery_date}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        style={{ textAlign: "end", paddingRight: "32px" }}
                      >
                        <h6>Container Delivery Slot</h6>
                        <p style={{ fontWeight: "normal" }}>
                          {booking?.delivery_collection_slot &&
                            booking?.delivery_collection_slot?.length > 0 &&
                            booking?.delivery_collection_slot[0]?.slot}
                        </p>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          S No.
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Customer Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>Age</TableCell>
                        <TableCell className={classes.tableHead}>
                          Gender
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          {booking.org_type == "homedx"
                            ? " Package Name"
                            : "Test Name"}
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Partner OrderId
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Aadhaar No
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Booking Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key={1}
                        style={{
                          backgroundColor:
                            booking?.booking_status !== "cancelled" &&
                              booking?.pickup_status !== "cancelled"
                              ? "white"
                              : "#edc3c3",
                        }}
                      >
                        <TableCell align="center">1.</TableCell>
                        <TableCell align="center">{`${booking?.designation === "Other" ||
                            booking?.designation === "other"
                            ? "Dear"
                            : booking?.designation || ""
                          } ${booking?.customer_name}`}</TableCell>
                        <TableCell align="center">
                          {booking.customer_age}
                        </TableCell>
                        <TableCell align="center">
                          {booking.customer_gender}
                        </TableCell>
                        <TableCell align="center">
                          <ul>
                            {booking.packages &&
                              booking.packages.map((pack: any, index: any) => {
                                const isFreeTest =
                                  booking.free_test &&
                                  booking.free_test.free_test.id === pack.id;
                                return (
                                  <li
                                    key={index}
                                    style={{
                                      margin: "1rem 0",
                                      textAlign: "left",
                                    }}
                                  >
                                    {/* {pack.name}, {pack.tat_time} */}
                                    {pack.name && pack.package_city_prices
                                      ? pack.name.toString() +
                                      ", " +
                                      pack.package_city_prices.tat_time
                                      : pack.name +
                                      (pack.tat_time
                                        ? ", " + pack.tat_time
                                        : "")}
                                    {isFreeTest && (
                                      <span
                                        style={{
                                          color: "#fff",
                                          backgroundColor: "green",
                                          padding: "0.2rem 0.5rem",
                                          borderRadius: "0.25rem",
                                          marginLeft: "0.5rem",
                                          boxShadow:
                                            "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                          fontWeight: "bold",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        FREE
                                      </span>
                                    )}
                                  </li>
                                );
                              })}
                          </ul>
                        </TableCell>
                        <TableCell align="center">
                          {booking.partner && booking.partner.id}
                        </TableCell>
                        <TableCell align="center">
                          {booking.customer_aadhar || ""}
                        </TableCell>
                        <TableCell align="center">
                          {booking?.booking_status === "cancelled" ||
                            booking?.pickup_status === "cancelled" ? (
                            <span style={{ color: "red" }}>
                              Booking Cancelled
                            </span>
                          ) : (
                            <span>{booking?.booking_status}</span>
                          )}
                        </TableCell>
                      </TableRow>
                      {booking.additional_members &&
                        booking.additional_members.length > 0 &&
                        booking.additional_members.map(
                          (member: any, index: number) => {
                            return (
                              <TableRow key={index + 1}>
                                <TableCell align="center">
                                  {index + 2}.
                                </TableCell>
                                <TableCell align="center">
                                  {`${member?.designation === "Other" ||
                                      member?.designation === "other"
                                      ? "Dear"
                                      : member?.designation || ""
                                    } ${member?.customer_name}`}
                                </TableCell>
                                <TableCell align="center">
                                  {member.customer_age}
                                </TableCell>
                                <TableCell align="center">
                                  {member.customer_gender}
                                </TableCell>
                                <TableCell align="center">
                                  <ul>
                                    {member.packages &&
                                      member.packages.map(
                                        (pack: any, index: any) => {
                                          const isFreeTest =
                                            member.free_test &&
                                            member.free_test.free_test.id ===
                                            pack.id;
                                          return (
                                            <li
                                              key={index}
                                              style={{
                                                margin: "0 0 1rem 0",
                                                textAlign: "left",
                                              }}
                                            >
                                              {/* {pack.name}, {pack.price} */}
                                              {pack?.name &&
                                                pack?.package_city_prices
                                                ? pack.name.toString() +
                                                ", " +
                                                pack?.package_city_prices
                                                  ?.tat_time
                                                : pack?.name +
                                                ", " +
                                                pack?.tat_time}
                                              {isFreeTest && (
                                                <span
                                                  style={{
                                                    color: "#fff",
                                                    backgroundColor: "green",
                                                    padding: "0.2rem 0.5rem",
                                                    borderRadius: "0.25rem",
                                                    marginLeft: "0.5rem",
                                                    boxShadow:
                                                      "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                    fontWeight: "bold",
                                                    fontSize: "0.875rem",
                                                  }}
                                                >
                                                  FREE
                                                </span>
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">
                                  {member.customer_aadhar}
                                </TableCell>
                                <TableCell align="center">
                                  {member?.booking_status === "cancelled" ||
                                    member?.pickup_status === "cancelled" ? (
                                    <span style={{ color: "red" }}>
                                      Booking Cancelled
                                    </span>
                                  ) : (
                                    <span>{member?.booking_status}</span>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      <TableRow></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "1rem auto" }}
                  component={Paper}
                >
                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      spacing={0}
                      style={{
                        padding: "10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                          Customer Address{" "}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>Locality </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {booking?.customer_landmark
                              ? booking?.customer_landmark
                              : booking?.booking_full_address}
                          </strong>
                          &nbsp;{" "}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>House / Flat / Shop Number</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {booking.customer_address &&
                              booking.customer_address}
                          </strong>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p style={{ fontSize: "14px" }}>
                          Apartment / Building / Street Number
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {booking?.address_line2 && booking?.address_line2}
                          </strong>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>Landmark</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {booking?.landmark && booking?.landmark}
                          </strong>
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                    <Grid
                      container
                      spacing={0}
                      style={{
                        padding: "10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <Grid item xs={12} md={6}>
                        <p> Package Price </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {" "}
                            {booking?.discounted_price &&
                              booking?.discounted_price.total_price_package &&
                              Math.floor(
                                booking?.discounted_price.total_price_package
                              )}
                            /-
                          </strong>
                        </p>
                      </Grid>
                      {/* {booking?.org_type === "homedx" && phleboCostForVew > 100 ? (
                        <>
                          <Grid item xs={12} md={6}>
                            <p>
                            Express slot Charges
                              
                            </p>

                          </Grid>
                          <Grid item xs={12} md={6}>
                            <p>
                              :{" "}
                              <strong>
                                {" "}

                                {booking?.discounted_price &&
                                  booking?.discounted_price.phlebo_cost &&
                                  Math.floor(
                                    booking?.discounted_price.phlebo_cost
                                  )}
                                /-
                              </strong>
                            </p>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )} */}
                      {booking?.org_type === "homedx" ? (
                        <>
                          <Grid item xs={12} md={6}>
                            <p>
                              Diagnostic Fee
                              <Tooltip
                                title={TooltipReportAndDietConsultationContent()}
                                classes={{ tooltip: classes.customTooltip }}
                              >
                                <InfoRoundedIcon
                                  style={{
                                    color: "#9142a8",
                                    marginLeft: "6px",
                                  }}
                                />
                              </Tooltip>
                            </p>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <p>
                              :{" "}
                              <strong>
                                {" "}
                                {booking?.discounted_price &&
                                  booking?.discounted_price.diagnostic_cost &&
                                  Math.floor(
                                    booking?.discounted_price.diagnostic_cost
                                  )}
                                /-
                              </strong>
                            </p>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      {booking?.org_type === "homedx" ? (
                        <>
                          <Grid item xs={12} md={6}>
                            <p>
                              Phlebo Cost
                              {booking &&
                                booking?.coupon_applied &&
                                booking?.coupon_applied?.packages &&
                                booking?.coupon_applied?.packages?.length > 0 &&
                                booking.coupon_applied?.code
                                  ?.replaceAll("_", "")
                                  ?.startsWith("FREETHYROID") ? (
                                <small
                                  style={{ color: "red", fontSize: "100%" }}
                                >
                                  (Thyroid Special)
                                </small>
                              ) : phleboCostForVew === 200 ? (
                                <small
                                  style={{ color: "red", fontSize: "100%" }}
                                >
                                  (Phlebo created booking)
                                </small>
                              ) : phleboCostForVew === 150 ? (
                                <small
                                  style={{ color: "red", fontSize: "100%" }}
                                >
                                  (Express slot)
                                </small>
                              ) : phleboCostForVew === 100 ? (
                                <small
                                  style={{ color: "red", fontSize: "100%" }}
                                >
                                  (Premium slot)
                                </small>
                              ) : (
                                ""
                              )}
                            </p>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <p>
                              :{" "}
                              <strong>
                                {" "}
                                {booking?.discounted_price &&
                                  booking?.discounted_price.phlebo_cost &&
                                  Math.floor(
                                    booking?.discounted_price.phlebo_cost
                                  )}
                                /-
                              </strong>
                            </p>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      <Grid item xs={12} md={6}>
                        <p>Report And Diet Consultation :</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          ₹
                          <span style={{ textDecoration: "line-through" }}>
                            299{" "}
                          </span>
                          <span style={{ marginLeft: "7px" }}> FREE</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {(couponCode.toLowerCase().startsWith("addfamily2") && (
                          <p style={{ fontSize: "14px" }}>
                            Discount{" "}
                            <span style={{ fontWeight: "bold" }}>
                              (Member-5%)
                            </span>
                          </p>
                        )) ||
                          (couponCode
                            .toLowerCase()
                            .startsWith("addfamily3") && (
                              <p style={{ fontSize: "14px" }}>
                                Discount{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  (Member-10%)
                                </span>
                              </p>
                            )) ||
                          ((couponCode.toLowerCase().startsWith("addfamily4") ||
                            couponCode
                              .toLowerCase()
                              .startsWith("addfamily5")) && (
                              <p style={{ fontSize: "14px" }}>
                                Discount{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  (Member-15%)
                                </span>
                              </p>
                            )) || <p style={{ fontSize: "14px" }}>Discount</p>}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {" "}
                            {booking?.discounted_price &&
                              booking?.discounted_price.counpon_discount &&
                              Math.floor(
                                booking?.discounted_price.counpon_discount
                              )}
                            /-
                          </strong>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p> RedCash Discount</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {booking?.redcash_discounted_amount}/-
                          </strong>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {booking?.discounted_price?.coupon_type ===
                          "referral" ? (
                          <p>Referral Coupon Discount</p>
                        ) : booking?.discounted_price?.coupon_type ===
                          "redeem points" ? (
                          <p>Referral Points Discount</p>
                        ) : (
                          <p>Referral Discount</p>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {
                              booking?.discounted_price
                                ?.referral_coupon_discount
                            }
                            /-
                          </strong>
                        </p>
                      </Grid>
                      {/* <Grid item xs={12} md={6}>
                        <p>  Smart Package Discount</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          : <strong>{booking?.smart_discounted_amount}/-</strong>
                        </p>
                      </Grid> */}
                      <Grid item xs={12} md={6}>
                        <p> Payment via Gift Card</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {booking?.giftcard_discounted_amount}/-
                          </strong>
                        </p>
                      </Grid>
                      {booking?.pickup_status !== "cancelled" && (
                        <>
                          <Grid item xs={12} md={6}>
                            <p> Payment via Gift Card</p>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <p>
                              :{" "}
                              <strong>
                                {booking?.giftcard_discounted_amount}/-
                              </strong>
                            </p>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} md={6}>
                        <p> VIP Membership Discount</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {alreadyARedPro
                              ? Math.floor(
                                booking?.discounted_price
                                  ?.subscription_discount_amount
                              )
                              : 0}
                            /-
                          </strong>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p> VIP Membership Price</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {alreadyARedPro
                              ? Math.floor(
                                booking?.discounted_price?.subscription_amount
                              )
                              : 0}
                            /-
                          </strong>
                        </p>
                      </Grid>
                      {booking?.discounted_price?.hard_copy_cost !== 0 ? (
                        <>
                          <Grid item xs={12} md={6}>
                            <p>Hard Copy Cost</p>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <p>
                              :{" "}
                              <strong>
                                {booking?.discounted_price?.hard_copy_cost}/-
                              </strong>
                            </p>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      {Math.floor(
                        booking?.discounted_price?.final_total_price
                      ) !== Math.floor(booking?.pitched_price) &&
                        booking?.pitched_price !== null &&
                        booking?.pitched_price !== 0 && (
                          <>
                            <Grid item xs={12} md={6}>
                              <p>Pitched Price</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <p>
                                :{" "}
                                <strong>
                                  {Math.min(
                                    Math.floor(Number(booking?.pitch_price) || 0),
                                    Math.floor(
                                      (booking?.discounted_price?.final_total_price || 0) -
                                      Math.floor(booking?.redcash_discounted_amount || 0) -
                                      Math.floor(booking?.giftcard_discounted_amount || 0)
                                    )
                                  )}
                                  /-
                                </strong>
                              </p>
                            </Grid>
                          </>
                        )}
                      <Grid item xs={12} md={6}>
                        <p style={{ fontWeight: "bold" }}> Total Amount</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          :{" "}
                          <strong>
                            {" "}
                            {booking?.discounted_price &&
                              booking?.discounted_price.final_total_price &&
                              Math.floor(
                                booking?.discounted_price.final_total_price
                              ) -
                              Math.floor(booking?.redcash_discounted_amount) -
                              Math.floor(booking?.giftcard_discounted_amount)}
                            /-
                          </strong>
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  {isInternationalNumber === true ? (
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "#ad0fec",
                        fontWeight: "bolder",
                        fontSize: "large",
                      }}
                    >
                      International no. booking. Please talk to the customer
                      through whatsapp, by clicking the link in the phlebo
                      remarks section.
                    </span>
                  ) : (
                    ""
                  )}
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>AgentName</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="agentName"
                        type="text"
                        value={(booking?.agent && booking?.agent.name) || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  {booking?.org_type === "homedx" &&
                    booking?.center?.center_type !== "collection_center" ? (
                    <>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>Phlebo</p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            name="phlebo"
                            type="text"
                            value={
                              (booking?.phlebo && booking?.phlebo.name) || ""
                            }
                            disabled
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>
                            Phlebo
                            {booking?.phlebo && booking?.phlebo.name ? (
                              <Button
                                disabled={
                                  !callButton || isInternationalNumber === true
                                }
                                className="addDeck"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleCallClick(bookingId, "phlebo")
                                }
                                startIcon={<CallIcon />}
                              ></Button>
                            ) : (
                              ""
                            )}
                          </p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            name="phlebo Number"
                            type="text"
                            value={
                              (booking.phlebo && booking.phlebo.phonenumber) ||
                              ""
                            }
                            disabled
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Mobile Number
                        {userDetails?.profile?.show_number === true ||
                          (userDetails?.profile &&
                            userDetails?.profile?.email ===
                            "syed.tajuddin@redcliffelabs.com") ? (
                          <VisibilityIcon
                            onClick={(e: any) =>
                              handleUnmaskedNumber(
                                e,
                                bookingId,
                                "booking",
                                "contact"
                              )
                            }
                            style={{ marginLeft: "2rem" }}
                          />
                        ) : (
                          ""
                        )}
                        <Button
                          disabled={
                            !callButton || isInternationalNumber === true
                          }
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() => handleCallClick(bookingId, "call")}
                          startIcon={<CallIcon />}
                        ></Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_phonenumber"
                        type="text"
                        value={booking?.customer_phonenumber || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Alternate Mobile Number
                        {userDetails?.profile?.show_number === true ||
                          (userDetails?.profile &&
                            userDetails?.profile?.email ===
                            "syed.tajuddin@redcliffelabs.com") ? (
                          <VisibilityIcon
                            onClick={(e: any) =>
                              handleUnmaskedNumber(
                                e,
                                bookingId,
                                "booking",
                                "altphonenumber"
                              )
                            }
                            style={{ marginLeft: "2rem" }}
                          />
                        ) : (
                          ""
                        )}
                        <Button
                          disabled={
                            !callButton || isInternationalNumber === true
                          }
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() => handleCallClick(bookingId, "contact")}
                          startIcon={<CallIcon />}
                        ></Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_alternatenumber"
                        type="text"
                        value={booking?.customer_altphonenumber || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Email</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_email"
                        type="email"
                        disabled
                        value={booking?.customer_email || ""}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Whatsapp Number
                        {userDetails?.profile?.show_number === true ||
                          (userDetails?.profile &&
                            userDetails?.profile?.email ===
                            "syed.tajuddin@redcliffelabs.com") ? (
                          <VisibilityIcon
                            onClick={(e: any) =>
                              handleUnmaskedNumber(
                                e,
                                bookingId,
                                "booking",
                                "whatsapp"
                              )
                            }
                            style={{ marginLeft: "2rem" }}
                          />
                        ) : (
                          ""
                        )}
                        <Button
                          disabled={
                            !callButton || isInternationalNumber === true
                          }
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() => handleCallClick(bookingId, "whatsapp")}
                          startIcon={<CallIcon />}
                        ></Button>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_whatsapppnumber"
                        type="text"
                        disabled
                        value={booking?.customer_whatsapppnumber || ""}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Passport Number</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="Passport"
                        type="text"
                        disabled
                        value={booking?.passport_number || ""}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Amount</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="financeamount"
                        type="text"
                        value={booking?.finance_amount || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Status</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="financestatus"
                        type="text"
                        value={booking?.finance_status || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Remarks</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="financeremark"
                        type="text"
                        value={booking?.finance_remarks || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Book for</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="book_for"
                        type="text"
                        value={booking?.book_for || ""}
                        disabled={true}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Phlebo Remarks</p>
                    </Grid>
                    {phleboRemarksChecker === true ? (
                      <Grid
                        item
                        xs={12}
                        md={7}
                        style={{ border: "2px solder gray" }}
                      >
                        <a
                          href={`https://${phleboRemarksLink}`}
                          target="_blank"
                        >
                          {booking.phlebo_remarks}
                        </a>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={7}>
                        <textarea
                          name="phlebo_remarks"
                          value={booking.phlebo_remarks || ""}
                          disabled
                          className="input"
                          style={{
                            margin: "0",
                            width: "100%",
                            fontWeight: "bold",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {booking?.phlebo_journey_started === true ? (
                    <>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>Journey started </p>
                        </Grid>

                        <Grid item xs={12} md={7}>
                          <TextField
                            name="Journey started"
                            type="text"
                            value={
                              booking.phlebo_journey_started === true
                                ? "Yes"
                                : "NO" || ""
                            }
                            disabled={true}
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {booking.journey_not_started_reason !== null &&
                        booking.journey_start_attempts > 0 ? (
                        <Grid
                          direction="row"
                          justify="space-around"
                          alignItems="center"
                          container
                          item
                          xs={12}
                          md={6}
                          spacing={3}
                        >
                          <Grid item xs={12} md={5}>
                            <p>
                              Journey not started reason{" "}
                              <span style={{ fontWeight: "bolder" }}>
                                (Attempts:{booking?.journey_start_attempts})
                              </span>{" "}
                            </p>
                          </Grid>

                          <Grid item xs={12} md={7}>
                            <textarea
                              name="journey_not_started_reason"
                              value={booking.journey_not_started_reason || ""}
                              disabled
                              className="input"
                              style={{
                                margin: "0",
                                width: "100%",
                                fontWeight: "bold",
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  {booking?.org_type === "homedx" ? (
                    ""
                  ) : (
                    <>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>Customer Address</p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextareaAutosize
                            maxRows={6}
                            style={{ width: "100%" }}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            disabled
                            defaultValue={
                              (booking?.customer_landmark &&
                                booking?.customer_landmark) ||
                              ""
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>Center Address</p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextareaAutosize
                            maxRows={6}
                            style={{ width: "100%" }}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            disabled
                            defaultValue={
                              (booking?.center_address &&
                                booking?.center_address?.address) ||
                              ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  {booking?.center?.center_type === "collection_center" ? (
                    <Grid item xs={12}>
                      <h3
                        style={{
                          borderTop: "dotted 1px #cccc",
                          paddingTop: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        Centre Address
                      </h3>

                      <b>
                        {booking?.centre_address
                          ? booking?.centre_address?.address +
                          ", " +
                          booking?.centre_address?.area +
                          ", " +
                          booking?.centre_address?.city +
                          " - " +
                          booking?.centre_address?.pincode +
                          ", " +
                          booking?.centre_address?.state
                          : ""}
                      </b>
                      <br />
                      <b>
                        Phone No. :{" "}
                        {booking?.centre_address
                          ? booking?.centre_address?.mobile
                          : ""}
                      </b>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  {booking?.payment_image &&
                    booking?.payment_image.map((image: any) => {
                      return (
                        <Grid
                          key={image.id}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          spacing={3}
                        >
                          <img
                            src={image.image}
                            alt="payment"
                            style={{ width: "100%", aspectRatio: "1/1" }}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </>
            )}
          </Paper>

          {openUploadModal === true && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openUploadModal}
              onClose={handleCloseModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openUploadModal}>
                <div style={{ backgroundColor: "whitesmoke", padding: "20px" }}>
                  <h6 style={{ fontWeight: "700", fontSize: "22px" }}>
                    Upload Documents
                  </h6>
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    style={{ margin: "1rem auto", background: "#e1f5fe" }}
                  >
                    <Grid item md={12}>
                      <p style={{ fontWeight: "600", fontSize: "20px" }}>
                        Remarks
                      </p>
                      <TextField
                        name="remarks"
                        type="text"
                        placeholder="Enter Remarks"
                        value={remarks}
                        className="input"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        onChange={(e) => setRemarks(e.target.value as String)}
                        required
                      />
                    </Grid>
                    <Grid item md={12}>
                      <sup
                        style={{
                          fontSize: "11px",
                          marginLeft: "15px",
                          marginTop: "15px",
                          background: "#fff",
                          color: "rgba(0, 0, 0, 0.54)",
                          letterSpacing: "0.00938em",
                          height: "40px",
                        }}
                      >
                        Upload Document
                      </sup>
                      <input
                        className="input"
                        style={{
                          width: "100%",
                          marginTop: "-15px",
                          marginBottom: "10px",
                          padding: "18.5px 14px",
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: "5px",
                        }}
                        accept=".pdf,.doc,.jpg,.jpeg,.png"
                        type="file"
                        name="file"
                        id="file"
                        multiple
                        onChange={fileSelectedHandler1}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      style={{
                        paddingTop: "27px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={documentUploadFunction}
                        disabled={remarks === "" || file1 === ""}
                        style={{
                          height: "40px",
                          paddingTop: "4px",
                          color:
                            remarks === "" || file1 === "" ? "gray" : "white",
                        }}
                      >
                        UPLOAD
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
            </Modal>
          )}

          {openPdfTable === true && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openPdfTable}
              onClose={handlePdfModelTableClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openPdfTable}>
                <Paper className={classes.paper} elevation={15}>
                  <h5>Uploaded Documents</h5>
                  {bookingDocumentList &&
                    bookingDocumentList?.results &&
                    bookingDocumentList?.results?.length > 0 ? (
                    <TableContainer className={classes.tableContainer}>
                      {
                        <Table stickyHeader aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                S No.
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Remarks
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Created At
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                View Document
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {bookingDocumentList &&
                              bookingDocumentList?.results &&
                              bookingDocumentList?.results?.length > 0 &&
                              bookingDocumentList?.results.map(
                                (data: any, index: any) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {index + 1}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {data?.remarks}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {new Date(
                                          data?.created_at
                                        ).toLocaleString()}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        <Button
                                          variant="contained"
                                          style={{
                                            background: true
                                              ? "#2196f3"
                                              : "gray",
                                            marginLeft: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                          onClick={() => {
                                            setFileLink(data?.file);
                                            setDocs([{ uri: `${data?.file}` }]);
                                            handleViewPdf();
                                          }}
                                        >
                                          Uploaded Documents
                                        </Button>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      }
                    </TableContainer>
                  ) : (
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        textAlign: "center",
                      }}
                    >
                      No Data Found.
                    </p>
                  )}
                </Paper>
              </Fade>
            </Modal>
          )}

          {openPdf === true && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openPdf}
              onClose={handlePdfModelClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openPdf}>
                {fileLink?.includes(".jpg") ||
                  fileLink?.includes(".jpeg") ||
                  fileLink?.includes(".png") ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${fileLink}`}
                      style={{ width: "95vh", height: "80%" }}
                      title="IMAGE Document"
                    />
                  </div>
                ) : fileLink?.includes(".doc") ||
                  fileLink?.includes(".docx") ? (
                  <div id="mera-doc-viewer">
                    {/* <DocViewer
                      style={{ height: "-webkit-fill-available" }}
                      pluginRenderers={DocViewerRenderers}
                      documents={[{ uri: fileLink, fileType: "doc" }]}
                    /> */}
                  </div>
                ) : (
                  <iframe
                    src={`${fileLink}`}
                    style={{ width: "80%", height: "750px" }}
                    title="PDF Document"
                  ></iframe>
                )}
              </Fade>
            </Modal>
          )}

          {openSampleModel === true && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openSampleModel}
              onClose={handleSampleModel}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openSampleModel}>
                <Paper className={classes.paper} elevation={15}>
                  <h5>Collection photos</h5>
                  {sampleImage &&
                    sampleImage?.results &&
                    sampleImage?.results?.length > 0 ? (
                    <TableContainer className={classes.tableContainer}>
                      {
                        <Table stickyHeader aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                S No.
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Customer Name
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Created At
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                View Document
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {sampleImage &&
                              sampleImage?.results &&
                              sampleImage?.results?.length > 0 &&
                              sampleImage?.results.map(
                                (data: any, index: any) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {index + 1}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {data?.customer_name}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {new Date(
                                          data?.created_at
                                        ).toLocaleString()}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        <Button
                                          variant="contained"
                                          style={{
                                            background: true
                                              ? "#2196f3"
                                              : "gray",
                                            marginLeft: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                          onClick={() => {
                                            setFileLink(data?.sample_image);
                                            setDocs([
                                              { uri: `${data?.sample_image}` },
                                            ]);
                                            handleViewPdf();
                                          }}
                                        >
                                          Uploaded Documents
                                        </Button>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      }
                    </TableContainer>
                  ) : (
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        textAlign: "center",
                      }}
                    >
                      No Data Found.
                    </p>
                  )}
                </Paper>
              </Fade>
            </Modal>
          )}
          {openTransferModal === true && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openTransferModal}
              onClose={handleTransferModel}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openTransferModal}>
                <Paper className={classes.paper} elevation={15}>
                  <h5>Transfer photos</h5>
                  {sampleImage &&
                    sampleImage?.results &&
                    sampleImage?.results?.length > 0 ? (
                    <TableContainer className={classes.tableContainer}>
                      {
                        <Table stickyHeader aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                S No.
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Customer Name
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Created At
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                View Document
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {sampleImage &&
                              sampleImage?.results &&
                              sampleImage?.results?.length > 0 &&
                              sampleImage?.results.map(
                                (data: any, index: any) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {index + 1}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {data?.customer_name}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {new Date(
                                          data?.batch_image_date
                                        ).toLocaleString()}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        <Button
                                          variant="contained"
                                          style={{
                                            background: true
                                              ? "#2196f3"
                                              : "gray",
                                            marginLeft: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                          onClick={() => {
                                            setFileLink(data?.batch_image_url);
                                            setDocs([
                                              {
                                                uri: `${data?.batch_image_url}`,
                                              },
                                            ]);
                                            handleViewPdf();
                                          }}
                                        >
                                          Uploaded Documents
                                        </Button>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      }
                    </TableContainer>
                  ) : (
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        textAlign: "center",
                      }}
                    >
                      No Data Found.
                    </p>
                  )}
                </Paper>
              </Fade>
            </Modal>
          )}
          {openSefileModel === true && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openSefileModel}
              onClose={() => setOpenSefileModel(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openSefileModel}>
                <Paper className={classes.paper} elevation={15}>
                  <h5
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bolder",
                    }}
                  >
                    Phlebo Selfie
                  </h5>
                  {phleboSelfie &&
                    phleboSelfie?.results &&
                    phleboSelfie?.results?.length > 0 ? (
                    <TableContainer className={classes.tableContainer}>
                      {
                        <Table stickyHeader aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                S No.
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Customer Name
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Created At
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                View Document
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {phleboSelfie &&
                              phleboSelfie?.results &&
                              phleboSelfie?.results?.length > 0 &&
                              phleboSelfie?.results.map(
                                (data: any, index: any) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {index + 1}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {data?.booking_details?.customer_name}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        {new Date(
                                          data?.created_at
                                        ).toLocaleString()}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ height: "50px" }}
                                        align="center"
                                      >
                                        <Button
                                          variant="contained"
                                          style={{
                                            background: true
                                              ? "#2196f3"
                                              : "gray",
                                            marginLeft: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                          onClick={() => {
                                            setFileLink(data?.file);
                                            setDocs([{ uri: `${data?.file}` }]);
                                            handleViewPdf();
                                          }}
                                        >
                                          Uploaded Documents
                                        </Button>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      }
                    </TableContainer>
                  ) : (
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        textAlign: "center",
                      }}
                    >
                      No Data Found.
                    </p>
                  )}
                </Paper>
              </Fade>
            </Modal>
          )}
          <Dialog
            open={viewOtp}
            onClose={() => setViewOtp(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ color: "#eb04c4", fontWeight: "bolder" }}
            >
              Booking Verification OTP
            </DialogTitle>
            <DialogContent>
              <h4 style={{ fontWeight: "bold" }}>OTP: {bookingOtp.otp}</h4>
            </DialogContent>
          </Dialog>
          <Popover
            id={popid}
            open={openAnchor}
            anchorEl={anchorEl}
            onClose={handleAnchorClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography className={classes.typography}>
              {unmaskedNumber.number}
            </Typography>
          </Popover>
          <ReportModal
            loading={loading}
            openReportModal={openReportModal}
            setReportModal={setReportModal}
            reportStatus={reportStatus}
          />
         
          {openCreateCouponModal && !loading && (
            <CreateCouponModal
              open={openCreateCouponModal}
              setOpen={setOpenCreateCouponModal}
              bookingId={bookingId}
              leadId={booking?.lead}
              booking={booking}
              bookingAmount={Number(
                booking?.discounted_price?.total_price_package
              )}
              customerSpecificCouponData={customerSpecificCouponData}
            />
          )}
          {/* <Invoice
            loading={loading}
            openInvoiceModel={openInvoiceModel}
            setOpenInvoiceModel={setOpenInvoiceModel}
            bookingId={bookingId} booking={undefined}          /> */}
        
          {/* <CreateMaternalMarkerForm
            openMaternalModel={openMaternalModel}
            setOpenMaternalModel={setOpenMaternalModel}
            booking={booking}
            personId={personId}
          /> */}
          {/* {openHIVForm && (
            <HIVForm
              openHIVForm={openHIVForm}
              setOpenHIVForm={setOpenHIVForm}
              personId={personId}
              memberType={memberType}
            />
          )} */}
          {openPrescriptionSendModal && (
            <SendPrescriptionPDF
              open={openPrescriptionSendModal}
              setOpen={setOpenPrescriptionSendModal}
              bookingId={bookingId}
            />
          )}
          {openPdfForClinicalTable && (
            <ClinicalHistoryTable
              openPdfForClinicalTable={openPdfForClinicalTable}
              setOpenPdfForClinicalTable={setOpenPdfForClinicalTable}
              viewPackageAnswer={viewPackageAnswer}
              viewPackageAnswerAllergy={viewPackageAnswerAllergy}
              setAnswerEdit={setAnswerEdit}
              answerEdit={answerEdit}
              answer={answer}
              setAnswer={setAnswer}
              updateAnswer={updateAnswer}
              viewAllergy={viewAllergy}
              setViewAllergy={setViewAllergy}
              getviewPackageAnswer={getviewPackageAnswer}
              CustomerId={CustomerId}
            />
          )}
          {invoicePdf === true && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={invoicePdf}
              onClose={() => setInvoicePdf(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={invoicePdf}>
                {invoicefileLink?.includes(".jpg") ||
                  invoicefileLink?.includes(".jpeg") ||
                  invoicefileLink?.includes(".png") ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${invoicefileLink}`}
                      style={{ width: "95vh", height: "80%" }}
                      title="IMAGE Document"
                    />
                  </div>
                ) : invoicefileLink?.includes(".doc") ||
                  invoicefileLink?.includes(".docx") ? (
                  <div id="mera-doc-viewer">
                    {/* <DocViewer
                      style={{ height: "-webkit-fill-available" }}
                      pluginRenderers={DocViewerRenderers}
                      documents={[{ uri: invoicefileLink, fileType: "doc" }]}
                    /> */}
                  </div>
                ) : (
                  <iframe
                    src={`${invoicefileLink}`}
                    style={{ width: "80%", height: "750px" }}
                    title="PDF Document"
                  ></iframe>
                )}
              </Fade>
            </Modal>
          )}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={phleboRecording}
            onClose={handleClosePhleboRecording}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={phleboRecording}>
              <div className={classes.paper2}>
                <div className={classes.head}>
                  <h4
                    style={{
                      marginBottom: "1rem",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      color: "#007BFF", // Light blue color
                      textAlign: "center",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      borderBottom: "2px solid #007BFF",
                      paddingBottom: "0.5rem",
                      marginTop: "1rem",
                    }}
                    id="transition-modal-title"
                  >
                    Phlebo Recording
                  </h4>
                  <CloseIcon onClick={handleClosePhleboRecording} />
                </div>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={3}
                  style={{ margin: "1rem auto" }}
                >
                  {phleboRedocrding[0] && phleboRedocrding[0]?.uploaded_url ? (
                    <audio controls>
                      <source
                        src={phleboRedocrding[0]?.uploaded_url?.trim()}
                        type="audio/wav"
                      />
                      Your browser does not support the <code>audio</code>{" "}
                      element.
                    </audio>
                  ) : (
                    "NA"
                  )}
                </Grid>
              </div>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={genomicsPrescriptionData}
            onClose={() => setGenomicsPrescriptionData(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={genomicsPrescriptionData}>
              <div className={classes.paper2}>
                <div className={classes.head}>
                  <h4
                    style={{
                      marginBottom: "1rem",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      color: "#007BFF",
                      textAlign: "center",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      borderBottom: "2px solid #007BFF",
                      paddingBottom: "0.5rem",
                      marginTop: "1rem",
                    }}
                    id="transition-modal-title"
                  >
                    Genomics Prescription Data
                  </h4>
                  <CloseIcon onClick={HandleGenomicsPrescriptionDataClose} />
                </div>
                <div>
                  <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell align="center">
                            Booking id
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Doctor Name
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Doctor Email
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Doctor Number
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Document
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {genomicsFormForGet &&
                          genomicsFormForGet?.prescription_list &&
                          genomicsFormForGet?.prescription_list?.length > 0 &&
                          genomicsFormForGet?.prescription_list.map(
                            (data: any, index: any) => {
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell
                                    style={{ height: "50px" }}
                                    align="center"
                                  >
                                    {data.booking}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{ height: "50px" }}
                                    align="center"
                                  >
                                    {data?.doctor_name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{ height: "50px" }}
                                    align="center"
                                  >
                                    {data?.doctor_email}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{ height: "50px" }}
                                    align="center"
                                  >
                                    {data?.doctor_number}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{ height: "50px" }}
                                    align="center"
                                  >
                                    <Button
                                      variant="contained"
                                      style={{
                                        background: true ? "#2196f3" : "gray",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                      onClick={() => {
                                        setFileLink(data?.prescription);
                                        setDocs([
                                          { uri: `${data?.prescription}` },
                                        ]);
                                        handleViewPdf();
                                      }}
                                    >
                                      Uploaded Documents
                                    </Button>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </Fade>
          </Modal>
          {invoiceData && pdfViewerModal && (
            <PdfViwerModal
              setPdfViewerModal={setPdfViewerModal}
              pdfViewerModal={pdfViewerModal}
              invoiceUrl={invoiceData?.invoice_url} />
          )}
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.TechSupportReducer.booking,
  agentsListnewdata: state.TechSupportReducer.agentsListnewdata,
  booking_slots: state.TechSupportReducer.booking_slots,
  packageList: state.TechSupportReducer.packageList,
  paymentReSendLink: state.TechSupportReducer.paymentReSendLink,
  emailDetails: state.TechSupportReducer.emailDetails,
  smsDetails: state.TechSupportReducer.smsDetails,
  reportStatus: state.TechSupportReducer.reportStatus,
  reportDownload: state.TechSupportReducer.reportDownload,
  bookingComplaints: state.TechSupportReducer.bookingComplaints,
  loading: state.TechSupportReducer.loading,
  biomarkerScreening: state.TechSupportReducer.biomarkerScreening,
  doctorConsultationBooking:
    state.TechSupportReducer.doctorConsultationBooking,
  bookingIdLoader: state.TechSupportReducer.bookingIdLoader,
  reportLoading: state.TechSupportReducer.reportLoading,
  newLeadDetails: state.TechSupportReducer.newLeadDetails,
  userDetails: state.TechSupportReducer.userDetails,
  unmaskedNumber: state.TechSupportReducer.unmaskedNumber,
  ticketTimeline: state.TechSupportReducer.ticketTimeline,
  customerSpecificCouponData:
    state.TechSupportReducer.customerSpecificCouponData,
  resamplingBooking: state.TechSupportReducer.resamplingBooking,
  hivpackageList: state.TechSupportReducer.hivpackageList,
  bookingOtp: state.TechSupportReducer.bookingOtp,
  phleboSelfie: state.TechSupportReducer.phleboSelfie,
  bookingDocumentList: state.TechSupportReducer.bookingDocumentList,
  sampleImage: state.TechSupportReducer.sampleImage,
  viewPackageAnswer: state.TechSupportReducer.viewPackageAnswer,
  viewPackageAnswerAllergy: state.TechSupportReducer.viewPackageAnswerAllergy,
  bookingInvoie: state.TechSupportReducer.bookingInvoie,
  phleboRedocrding: state.TechSupportReducer.phleboRedocrding,
  genomicsFormForGet: state.TechSupportReducer.genomicsFormForGet,
  invoiceData: state.TechSupportReducer.invoiceData,
  isloading: state.TechSupportReducer.loading
});

export default connect(mapStateToProps, {
  getBookingById,
  resetBookings,
  updateBooking,
  assignDoctor,
  getAvailableSlots,
  getPackage,
  createAdditionalBooking,
  updateAdditionalBooking,
  getPaymentSendLink,
  getPaymentReSendLink,
  getPaymentCancelLink,
  clickToCallBooking,
  getSmsDetails,
  getEmailDetails,
  getSmsReportSend,
  getReportStatus,
  getReportDownload,
  getSMSLink,
  getAgentNewData,
  getQueryTickets,
  getBiomarkerScreening,
  getDoctorBookings,
  getNewLeadDetails,
  getUnmaskedNumber,
  getCustomerSpecificCoupon,
  getHivPackage,
  getFailedReportData,
  PostBookingIndexing,
  getBookingDocument,
  postUploadBookingDocument,
  getBookingSampleImage,
  getResamplingBookingByID,
  getviewPackageAnswer,
  updateClinicalHistoryAnswer,
  getBookingVerificationOtp,
  getPhleboBookingSelfie,
  getBookingInvoice,
  getPhleboRecording,
  getPrescriptionDataByBookingId,
  getInvoiceByBookingID,
})(ViewBooking);