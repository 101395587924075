export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "ts/get_pin_code",
  GET_CITIES: "ts/get_cities",
  GET_AREA: "ts/get_area",
  GET_AGENTS: "ts/get_agents",
  GET_AGENTS_BY_USERGROUP: "ts/get_agents_by_usergroup",
  GET_PACKAGES: "ts/get_packages",
  GET_PHLEBOS: "ts/get_phlebos",
  GET_PHLEBOS_DATA: "ts/get_phlebos_data",
  GET_PARTNERS: "ts/get_partners",
  GET_PARTNERS_AUTO: "ts/get_partners_auto",
  GET_COUPONS: "ts/get_coupons",
  CREATE_BOOKING: "ts/create_booking",
  GET_BANK_NAME: "ts/get_bank_name",
  UPDATE_BOOKING: "ts/update_booking",
  GET_BOOKING_LIST: "ts/get_booking_list",
  SET_ALL_BOOKING_LIST_LOADING: "ts/get_booking_list_loading",
  GET_LEAD_DETAILS: "ts/get_lead_details",
  GET_LEAD_BY_ID: "ts/get_lead_by_id",
  LEAD_DETAILS_RESET: "ts/lead_details_reset",
  GET_LEADS: "ts/get_leads",
  GET_BOOKING_BY_ID: "ts/get_booking_by_id",
  CLICK_TO_CALL: "ts/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "ts/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "ts/add_comments",
  SET_COMMENTS: "ts/set_commnets",
  UPLOAD_FILE: "ts/upload",
  SET_LOADING: "ts_booking/set_loading",
  SET_AREA_LOADING: "ts_booking/set_area_loading",
  SEARCH_CALL: "ts/serach",
  BOOKING_RESET: "ts/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "ts/createcall",
  GET_LEAD_SOURCE: "ts/getleadsource",
  GET_RECORDINGS: "ts/get_recordings",
  GET_RECORDINGS_BACKUP: "ts/get_recordings_backup",
  GET_BOOKINGS_COMMENTS: "ts/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "ts/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "ts/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "ts/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "ts/send",
  GET_CALL_STATS: "ts/get_call_stats",
  RAISE_COMPLAINT: "ts/raise_complaint",
  GET_COMPLAINTS: "ts/get_complaints",
  ASSIGN_DOCTOR: "ts/assign_doctor",
  SEND_FEEDBACK: "ts/send_feedback",
  GET_AVAILABLE_SLOTS: "ts/get_available_slots",
  GET_ALL_LEADS: "ts/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "ts/set_comments",
  GET_PANEL_USERS: "ts/get_panel_user",
  ADD_REMARK_ON_CALL: "ts/add_remark_on_call",
  GET_BREAK_DATA: "ts/get_break_data",
  UPDATE_COUPON: "ts/update_coupon",
  CREATE_COUPON: "ts/create_coupon",
  GET_VERIFICATION_BOOKING_DETAILS: "ts/get_verification_booking_details",
  GET_UNMASKED_NUMBER: "ts/get_unmasked_number",
  CREATE_ADDITIONAL_BOOKING: "ts/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "ts/update_additional_booking",
  LEAD_TRANSFER_TO_TATATALE: "ts/lead_transfer_to_tatatale",
  GET_LEAD_STATUS: "ts/get_lead_status",
  GET_PRESCRIPTION_DATA: "ts/get_prescription_data",
  CHECK_GEOFENCE_AREA: "ts/checking-geofence-area",
  GET_RED_COIN: "ts/get_red_coin",
  GET_ADDRESS_TYPE_SEATING: "qc/get_address_type_seating",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "qc/checking-geofence-area-mapmyindia",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "qc/get-mapmyindia-access-token",
  GET_USER_DETAILS: "qc/get_user_details",
  GET_NOTIFICATION_MESSAGE: "qc/get_notification_message",
  GET_TICKET_DATA: "qc/get_ticket_data",
  GET_TICKET_CATEGORIES: "ts/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "ts/get_ticket_categories",
  CREATE_TASK_TICKET: "ts/create_task_ticket",
  GET_RED_TECH_COMMENT: "ts/redtech_comments/",
  POST_RED_TECH_COMMENT: "ts/postredtech_comments/",
  GET_QUALITY_SCORING_REPORT: "ts/get_quality_scoring_report",
  GET_CALLS_DATA: "ts/get_calls_data",
  POST_QUALITY_SCORE: "ts/post_quality_scoring",
  GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
    "ts/get_quality_scoring_report_for_backup",
  GET_CENTER_INFORMATION: "ts/get_center_information",
  SET_USER_LOADING: "ts/set_User_loading",
  GET_USERS_LIST: "ts/get_user_list",
  GET_MANAGEHIERARCHY_LIST: "ts/get_managehierarchy_list",
  GET_OTHERUSER_LIST: "ts/get_otheruser_list",
  UPDATE_USERS_LIST: "ts/update_user_list",
  UPDATE_USERS_STATUS: "ts/update_user_status",
  GET_PANEL_COMMENT: "ts/get_panel_comments",
  GET_PANEL_USER_LIST_DATA: "ts/get_panel_user_list_data",
  GET_PANELUSER_ACTIVE_DEACTIVE: "ts/get_active_deactive_panel_user",
  GET_AGENT_DATA: "ts/get_agent_data",
  GET_ASSIGNED_USERGROUP: "ts/get_assigned_usergroup",
  GET_PANEL_USER_LIST_DATA_SALES_LEAD: "ts/get_panel_user_list_data_sales_lead",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "ts/get_panel_user_list_data_team_lead",
  CREATE_PANEL_USER: "ts/create_panel_user",
  UPDATED_PANEL_USER: "ts/updated_panel_user",
  UPDATE_TICKET_STATUS: "ts/update_ticekt_status",
  UPDATE_USERS_PASSWORD: "ts/update_users_password",
  RAISE_NEW_RAISE_TICEKT_DISPOSITION: "ts/cstech_create-ticekt",
  DISABLED_USER_DETAILS: "ts/auth/user_details",
  GET_USER_LIST_DATA: "ts/auth/user_data",
  GET_OZONTEL_SKILL_DATA: "ts/get_ozontel_skill_data",
  UPDATE_PHLEBOS: "ts/update_phlebos",
  GET_PHLEBO_COMMENTS: "ts/get_phlebo_commnets",
  GET_ZONE: "ts/get_zone",
  CREATE_NEW_PHLEBO: "ts/create_phlebo",
  GET_PHLEBO_BY_PHONE: "ts/get_phlebo_by_phone",
  GET_PAYMENT_INFORMATION: "ts/get_payment_information",
  GET_RIDER_LIST: "ts/get_rider_list",
  UPDATE_RIDER_TO_LAB_STATUS: "ts/update_rider_to_lab_status",
  GET_SAMPLE_HANDOVER_DATA: "ts/getsample_handover_data",
  GET_SAMPLE_JOURNEY_COMMENTS: "ts/get_sample_journey_comments",
  GET_BATCH_BOOKING_AMOUNT: "ts/get_batch_amount",
  UPDATE_RIDER_SAMPLE_HANDOVER: "ts/update_rider_sample_handover",
  GET_TICKET_COMMENTS: "ts/ticket_comments",
  GET_TICKET_COMMENTS_FOR_DOCUMENTS: "ts/ticket_comments_for_documents",
  SET_NEW_TICKET_LOADING: "ts/ticket_loading",
  GET_BOOKING_COMPLAINTS: "ts/get_booking_complaints",
  UPDATE_DISPOSITION: "ts/update_disposition",
  GET_LANGUAGE: "ts/get_language",
  GET_DIALER: "ts/get_dialer",
  GET_ORG_TYPES: "ts/get_org_types",
  GET_CHAT_SKILLS_LISTS: "ts/get_chat_skills_lists",
  GET_ALL_USERS: "ts/get_all_users",
  GET_OTHER_USERS: "ts/get_other_users",
  POST_DISPOSITION_DATA: "ts/lead/lead-status-update/",
  GET_LEAD_DISPOSITION_LIST: "ts/plead/status-category/",
  SET_ALL_BOOKING_LOADING: "ts/set_all_booking_loading",
  GET_TIMESLOTS: "ts/get_timeslots",
  CREATE_ZONE: "ts/create_zone",
  GET_ZONE_COMMENTS: "ts/get_zone_comments",
  UPDATE_ZONE: "ts/update_zone",
  GET_CAMPAINGS_DETAILS: "ts/dialer_settings",
  GET_BLACKLISTED_NUMBER: "ts/blacklisted_number",
  CREATE_BLACKLIST: "ts/get_blacklist",
  CREATE_DIALER: "ts/create_dialer",
  GET_ASSIGNED_UNASSIGNED_BOOKING: "ts/get_assigned_unassigned_bookings",
  SET_ASSIGNED_LOADING: "ts/set_assigned_loader",
  GET_MAP_BOOKING_LIST: "ts/get_map_booking",
  GET_PHLEBO_CURRENT_LOCATION: "ts/phlebo_current_location",
  UPDATED_CITY_ALIAS: "ts/city_alias_updated",
  GET_CITY_ALIAS: "ts/get_city_alias",
  CREATE_CITY_ALIAS: "ts/city_alias_created",
  GET_CITY_COMMENTS: "ts/get_city_comments",
  GET_PACKAGE_COMMENTS: "ts/get_package_comments",
  SEND_INITIAL_WHATSAPP_MESSAGE: "ts/send_initial_whatsapp_message",
  GET_LTV_LEAD: "ts/get_ltv_lead",
  UPDATE_IMAGING_TICKET: "ts/update_imaging_ticket",
  GET_TOP_RC_FRESH_LEADS: "ts/get_top_rc_fresh_leads",
  GET_IMAGING_TICKET: "ts/get_imaging_ticket",
  GET_WHATSAPP_TEMPLATE: "ts/get_whatsapp_template",
  CREATE_WHATSAPP_TEMPLATE: "ts/create_whatsapp_template",
  INITIATE_REFUND: "ts/initiate_refund",
  SYNC_BOOKING_WITH_ES: "ts/sync_booking_with_es",
  SEND_LOCATION_REQUEST: "ts/communication/send-location-request/",
  GET_PAYMENT_SEND_LINK: "ts/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "ts/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "ts/get_payment_cancel_link",
  GET_SMS_REPORT_SEND: "ts/get_sms_report_send",
  GET_SMS_DETAILS: "ts/get_sms_details",
  GET_EMAIL_DETAILS: "ts/get_email_details",
  GET_REPORT_STATUS: "ts/get_report_status",
  GET_REPORT_DOWNLOAD: "ts/get_report_download",
  SET_REPORT_LOADING: "ts/set_report_loading",
  GET_ADDITIONAL_BOOKING: "ts/get_additional_booking",
  GET_SIGNATURE: "ts/get_signature",
  GET_QRCODE_DATA: "ts/get_qrcode_data",
  SET_GEO_CHECK_ERROR: "ts/set_geo_check_error",
  SET_GEO_LOADING: "ts/set_geo_loading",
  GET_CENTER: "ts/get_center",
  CREATE_PARTNER: "ts/create_partner",
  GET_USER_DATA: "ts/get_users_data",
  GET_PACKAGE_ALIAS: "ts/get_package_alias",
  CREATE_PACKAGE_ALIAS: "ts/package_alias_created",
  UPDATED_PACKAGE_ALIAS: "ts/package_alias_updated",
  GET_AGENTS_NEW_DATA: "ts/get_agents_new_data",
  POST_REGENERATE_PAYMENT: "ts/post_regenerate_payment",
  CANCEL_TO_CONFIRM: "ts/cancel_to_confirm",
  CONFIRM_TO_PENDING: "ts/confirm_to_pending",
  GET_BOOKING_SUB_DISPOSITION: "ts/ead/lead-status/",
  GET_PANEL_USER_COMMENT: "ts/get-panel-user-comment",
  GET_VERIFIED_BY_LIST: "ts/get_verified_by_list",
  GET_COLLECTION_SLOT: "ts/get_collection_slot",
  GET_PHLEBO_TIMELINE: "ts/timeline",
  LOCATION_VERIFIED: "ts/location_verified",
  GET_ADDRESS_DETAILS_LAT_LONG: "ts/get-address-details-lat-long",
  GET_BOOKING_CALLS: "ts/get_booking_calls",
  GET_USER_EDIT_DATA: "ts/get_user_edit",
  CREATE_LAB: "ts/create_lab",
  UPDATE_LAB: "ts/update_employee",
  GET_LAB_CREDENTIAL: "ts/get_lab_cred",
  GET_LAB_LOG: "ts/get_lablog",
  RESET_LOGS: "ts/reset_logs",
  UPDATE_CENTER: "ts/update_center",
  UPDATED_DIALER: "ts/updated_dialer",
  GET_CENTER_DATA: "ts/get_center_Data",
  GET_SUB_CENTER_INFORMATION: "ts/get_sub_center_information",
  GET_IMAGING_TIMESLOTS: "ts/get_imaging_timeslots",
  GET_DISPLAY_CITIES: "ts/get_display_cities",
  GET_MULTIPLE_PACKAGES: "ts/get_multiple_package",
  GET_PAGINATION_COMMENT: "ts/get_pagination_comment",
  SET_PHLEBOS_LOADING: "ts_booking/set_loading_phlebos",
  GET_PHLEBOS_AUTO: "ts/get_phlebos_auto",
  GET_USER_TYPES: "ts/get_user_types",
  CREATE_AREA: "ts/create_area",
  UPDATE_AREA: "ts/update_area",
  GET_AREA_COMMENTS: "ts/get_area_comments",
  UPDATE_PARTNER: "ts/partner/update/",
  PARTNER_CREATED: "ts/partner/create/",
  SET_PARTNER_LOADING: "ts/partner/loading/",
  UPDATE_PICK_UP_STATUS: "ts/update_pick_up_status",
  GET_PANEL_IN_LAB: "ts/panelinlab",
  SET_SYNC_LOADING: "ts/set_sync_loading",
  GET_SYNC_DATA: "ts/get_sync_data",
  GET_BOOKING_UHID: "ts/GET_BOOKING_UHID",
  RESET_BOOKING_UHID: "ts/RESET_BOOKING_UHID",
  GET_PACKAGE_ALL_TUBE: "ts/get_package_all_tube",
  GET_TUBE_BY_BOOKING: "ts/get_tube_by_booking",
  POST_SAMPLE_COLLECTION: "ts/post_sample_collection",
  GET_RECEIVED_PACKAGES: "ts/get_received_packages",
  UPDATE_PACKAGE_TUBE_DETAILS: "ts/update_package_tube_details",
  GET_VALID_BARCODE: "ts/get_valid_bcode/",
  POST_BARCODE: "ts/post_barcode",
  CREATE_BARCODE: "ts/create_barcode",
  GET_CMS_LIST_II: "ts/get_cms_list_ll",
  GET_CMS_WALLET: "ts/get_cms_wallet",
  GET_CMS_LIST: "ts/get_cms_list",
  UPDATE_CMS: "ts/update_cms",
  CREATE_CMS: "ts/create_cms",
  GET_BATCH_FOR_CMS: "ts/get_batch_for_cms",
  GET_CMS_ADV_PAYMENT: "ts/get_cms_adv_payment",
  GET_MANUAL_SETTLE_PAYMENT: "ts/booking/manually-settle-payment",
  POST_STOP_SENDING_REPORT: "ts/booking/restrict-booking-report",
  CREATE_NEW_CENTER: "ts/create_new_center",
  GET_SALES_EXECUTIVE: "ts/get_sales_executive",
  GET_SALES_MANAGER: "ts/get_sales_manager",
  GET_IENTITY_PROOF_DATA: "ts/get_identity_proof_data",
  GET_CENTRE_CITIES: "ts/get_centre_cities",
  GET_PAYMENT_MODE_LIST: "ts/get_payment_mode_list",
  GET_RATELIST_FILTER: "ts/get_ratelist_filter",
  GET_TAG_PROCCESSING_LAB: "ts/get_tag_proccessing_lab",
  GET_CENTRE_CODE: "ts/get_centre_code",
  UPLOAD_CENTRES: "ts/upload_centres",
  GET_CENTERS: "ts/get_centers",
  GET_BUSINESS_LOCATION: "ts/GET_BUSINESS_LOCATION",
  GET_CC_CONSUMPTION_ITEMS: "ts/get_cc_consumption_items",
  CC_TO_INV: "ts/cc_to_inv",
  RESET_CC_TO_INVENTORY_RES: "ts/RESET_CC_TO_INVENTORY_RES",
  GET_IFSC_DATA: "ts/get_ifscdata",
  DISABLE_USER_2FOTP: "ts/disable_user_2fotp",
  GET_MRR_SALES: "ts/get-mrr-sales",
  SET_MMR_LOADING: "ts/get-mrr-sales-loading",
  GET_TERRITORY_DATA: "ts/get-territory-data",
  GET_CENTER_INFORMATION_PENDING: "ts/get_center_info_pending",
  GET_CENTER_COMMENTS: "ts/get_center_comments",
  GET_STATE_LIST: "ts/get_state_list",
  GET_LAB_ORG_CODE: "ts/getlaborgcode",
  CREATE_CENTRE_LAB_ORG: "ts/create_centre_lab_org",
  UPDATE_CENTRE_LAB_ORG: "ts/updatecentrelaborg",
  GET_CENTRE_LAB_ORG: "ts/get_centre_lab_org",
  GET_BLOCK_USER_FROM_CREATE_BOOKING: "ts/get_block_user_from_create_booking",
  BOOKING_UNBLOCK: "ts/booking_unblock",
  GET_BOOKINGS_BY_CENTRE: "ts/get-booking-by-center",
  GET_LAB_USER: "ts/get_labuser",
  CREATE_LAB_USER: "ts/post_labuser",
  UPDATE_LAB_USER: "ts/update_labuser",
  GET_UNREGISTERD_BOOKING: "ts/get_unregisterd_booking",
  UPDATE_UNREGISTERD_BOOKING_STATUS: "ts/update_unregisterd_booking_status",
  GET_USER_INFO: "ts/get-user-info",
  GET_SMS_LINK: "ts/get_sms_link",
  GET_INVOICE_BY_BOOKING_ID: "ts/booking/invoices/",
  SET_BIOMARKER_LOADER: "ts/set_biomarker_loader",
  GET_BIOMARKER_SCREENING: "ts/biomarker_screening",
  GET_NEW_LEAD_DETAILS: "ts/lead_details",
  GET_CUSTOMER_SPECIFIC_COUPON: "ts/get_customer_specfic_coupon",
  GET_HIV_PACKAGES: "ts/get_hiv_package",
  GET_FAILED_REPORT_DATA_BY_ID: "ts/report_failed_data_by_id/",
  GET_FAILED_REPORT_DATA: "ts/report_failed/",
  GET_RESAMPLING_BOOKING_BY_ID: "ts/booking/resampling-coun/",
  GET_BOOKING_VERIFICATION_OTP: "ts/booking/booking-verification-otp",
  GET_PHLEBO_BOOKING_SELFIE: "ts/booking/phlebo-booking-attachment-selfie",
  UPDATE_CLINICAL_HISTORY_ANSWER: "ts/package/package-answer",
  GET_PHLEBO_RECORDING: "ts/communication/get-recording",
  SET_BOOKING_INVOICE_LOADING: "ts/booking/booking-invoice/loading",
  GET_BOOKING_INVOICE: "ts/booking/booking-invoice",
  GET_VIEW_PACKAGE_ANSWER_ALLERGY: "ts/package/package-answer-allergy/",
  GET_BOOKING_SAMPLE_IMAGE: "ts/booking_sample_image",
  POST_BOOKING_INDEXING: "ts/booking/booking-indexing/",
  GET_VIEW_PACKAGE_ANSWER: "ts/package-answer/",
  UPLOAD_BOOKING_DOCUMENT: "ts/upload_booking_document",
  GET_BOOKING_DOCUMENT: "ts/get_booking_document",
  GET_PRESCRIPTION_DATA_BY_BOOKINGID: "ts/rescription_upload-cr",
  GET_LEAD_SUBSCRIPTIONS_DATA:"ts/get_lead_subscriptions",
  GET_REF_DOCTOR: "ts/get_ref_doctor",
  POST_REF_DOCTOR: "ts/post_ref_doctor",
  REMOVE_SUBSCRIPTION: "ts/remove_subscription",
  SET_LOADING_PACKAGE_QUESTION: "ts/set_loading_question",
  GET_PACKAGE_QUESTION: "ts/package/package-question",
  POST_PACKAGE_ANSWERS: "ts/package/package-answer/",
  GET_CONTAINER_SLOTS: "ts/get_container_slots",
  PUT_FAMILY_MEMBER_UPDATE: "ts/put_family_member_update",
  ADD_UNIQUE_CUSTOMER: "ts/add_unique_customer",
  BOOKING_EDIT_IN_SALES: "ts/booking_edit_in_sales",
  GET_COUPON_AUTO_APPLY: "ts/coupon//auto_apply_valid_lead/",
  GET_VIP_ACTIVATION_STATUS: "ts/sample/send_lab_location/",
  GET_LAB_TRANSFER_DETAILS:"ts/get-lab-transfer-details",
  GET_DIGITAL_PRESCRIPTION_LIST:"ts/get-digital-prescription",
  SEND_DIGITAL_PRESCRIPTION:"ts/send-digital-presription",
  CREATE_CUSTOMER_SPECIFIC_COUPON:"ts/create-customer-specific",
  SENT_REPORT_DATA_BY_ID:"ts/sent-report-data-by-id",
  GET_REPORT_SEND_MANUAL:"ts/get-report-send-manual",
  WHATSAPP_STATUS_MESSAGE_ID:"ts/whatsapp-status-message-id",
  GET_WHATSAPP_COMMUNICATION_DETAILS:"ts/get-whatsapp-communication",
  SET_WHATSAPP_DETAILS_LOADING:"ts/whatsapp-loading",
  GET_LAB: "ts/get_lab",
  GET_SHOW_ALL_STORE_LOCATIONS: "ts/get_show_all_store_locations",
  CREATE_USER: "ts/create_user",
  GET_INVENTORY_USERS: "ts/get_inventory_users",
  SET_LOCATION_RIGHTS_THRU_USER: "ts/set_location_rights_thru_user",
  GET_LOCATIONS_OPT: "ts/get_locations_opt",
  GET_API_LOGS_FOR_PASSED_MODEL_MANGO: "ts/GET_API_LOGS_FOR_PASSED_MODEL_MANGO",
  RESET_API_LOGS_MANGO: "ts/RESET_API_LOGS_MANGO",
  POST_OR_UPDATE_STORE_LOCATION: "ts/post_or_update_store_location",
  GET_STORE_LOCATION_OR_FILTER_OPT: "ts/get_store_location_or_filter_opt",
  GET_STORE_LOCATION_OR_FILTER: "ts/get_store_location_or_filter",
  GET_STORE_LOCATION_OPT: "ts/get_store_location_opt",
  GET_FULL_STORE_LOCATION: "ts/get_full_store_location",
  GET_LAB_AND_HLM: "ts/GET_LAB_AND_HLM",
  GET_STORE_LOCATION_FILTER: "ts/get_store_location_filter",
  GET_SINGLE_CENTRE: "ts/get_single_centre",
  GET_LOCATION_II: "ts/GET_LOCATION_II",
  RESET_LOCATION_MAPPING: "ts/RESET_LOCATION_MAPPING",
  GET_ITEM_MASTER_FOR_MAP_ITEMS: "ts/get_item_master_for_map_items",
  GET_ITEM_TYPE: "ts/get_item_type",
  GET_MACHINE: "ts/get_machine",
  GET_FULL_ITEM_MASTER: "ts/get_full_item_master",
  GET_ALL_TAGGED_ITEMS_TO_LOCATION: "ts/get_all_tagged_items_to_location",
  GET_STORE_PERMITTED_ITEMS_OPT: "ts/get_store_permitted_items_opt",
  GET_STORE_LOCATION: "ts/get_store_location",
  GET_ITEM_MASTER: "ts/get_item_master",
  GET_VENDOR_MASTER: "ts/get_vendor_master",
  POST_PO: "ts/post_po",
  ADD_ITEMS_TO_PO: "ts/add_items_to_po",
  GET_PO_OR_FILTER: "ts/get_po_or_filter",
  GET_PI_INDENTS: "ts/get_pi_indents",
  GET_QUOTATION_FOR_CREATING_PO: "ts/get_quotation_for_creating_po",
  GET_QUOTATION_ITEMS_THRU_VENDOR_AND_RECIEPT_ID:
  "ts/get_quotation_items_thru_vendor_and_reciept_id",
  GET_FULL_PO: "ts/get_full_po",
  POST_OR_UPDATE_PO: "ts/post_or_update_po",
  GET_CURRENCY_MASTER: "ts/get_currency_master",
  GET_QUOTATION_FOR_CURRENCY_VALIDATION:
  "ts/get_quotation_for_currency_validation",
  GET_VENDORS_THRU_ITEM: "ts/get_vendors_thru_item",
  RESET_VENDORS_THRU_ITEM: "ts/reset_vendors_thru_item",
  POST_PO_AGAINST_PI: "ts/post_po_against_pi",
  RESET_PO_AGAINST_PI: "ts/RESET_PO_AGAINST_PI",
  GET_COMPANY_MASTER: "ts/invSupAdmin/GET_COMPANY_MASTER",
  RESET_API_LOGS: "ts/invSupAdmin/RESET_API_LOGS",
  GET_API_LOGS_FOR_PASSED_MODEL: "ts/invSupAdmin/GET_API_LOGS_FOR_PASSED_MODEL",
  GET_INV_SUPER_ADMIN_USERS: "ts/invsuperadmin/get_inv_super_admin_users",
  GET_ITEMS_THROUGH_LOCATION: "ts/get_items_through_location",
  GET_NEW_ITEM_MASTER_OR_FILTER: "ts/get_new_item_master_or_filter",
  POST_RAISE_INDENT: "ts/post_raise_indent",
  RESET_NEW_ITEM_MASTER_OR_FILTER_: "ts/reset_new_Item_master_or_filter",
  POST_OR_UPDATE_INDENT_STATUS_FOR_RAISE_INDENT:
    "ts/post_or_update_indent_status_for_raise_indent",
  GET_INDENTS: "ts/invSupAdmin/GET_INDENTS",
  GET_INDENT_ITEMS: "ts/invSupAdmin/GET_INDENT_ITEMS",
  RESET_INDENT_ITEMS: "ts/invSupAdmin/RESET_INDENT_ITEMS",
  RESET_INDENT_VIEW: "ts/invSupAdmin/RESET_INDENT_VIEW",
  RESET_INDENT_STATUS_API: "ts/invSupAdmin/RESET_INDENT_STATUS_API",
  GET_INDENT_ISSUE_OR_FILTER_FOR_RAISE_INDENT:
  "ts/get_indent_issue_or_filter_for_raise_indent",
  GET_MANUFACTURE: "ts/get_manufacture",
  GET_ALL_LOCATIONS: "ts/get_all_locations",
  GET_COMPANY:"ts/GET_COMPANY",
  GET_GRN_OR_FILTER: "get_grn_or_filter",
  GET_FULL_GRN: "get_full_grn",
  RESET_GRN_PAGE: "reset_grn_page",
  POST_GRN_WITH_ITEM_SET: "post_grn_with_item_set",
  GET_ITEM_MASTER_OR_FILTER: "get_item_master_or_filter", 
  POST_MOVE_AGENT:"ts/post-move-agent",
};

interface UpdatedPanelUser {
  type: typeof ACTIONS.UPDATED_PANEL_USER;
  payload: Object;
}
interface GetUserEdit {
  type: typeof ACTIONS.GET_USER_EDIT_DATA;
  payload: Array<any>;
}
interface BookingConfirmtoPending {
  type: typeof ACTIONS.CONFIRM_TO_PENDING;
  payload: Array<any>;
}
interface BookingConfirmtoCancel {
  type: typeof ACTIONS.CANCEL_TO_CONFIRM;
  payload: Array<any>;
}
interface PostRegeneratePyament {
  type: typeof ACTIONS.POST_REGENERATE_PAYMENT;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface UpdateUsersPassword {
  type: typeof ACTIONS.UPDATE_USERS_PASSWORD;
  payload: Array<any>;
}
interface CreatePanelUser {
  type: typeof ACTIONS.CREATE_PANEL_USER;
  payload: Object;
}
interface SetUserList {
  type: typeof ACTIONS.UPDATE_USERS_LIST;
  payload: Object;
}
interface UpdateUserStatus {
  type: typeof ACTIONS.UPDATE_USERS_STATUS;
  payload: Object;
}
interface GetPanelComments {
  type: typeof ACTIONS.GET_PANEL_COMMENT;
  payload: Array<any>;
}
interface GetPanelUserList {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA;
  payload: Array<any>;
}
interface GetActiveDeactivePanelUser {
  type: typeof ACTIONS.GET_PANELUSER_ACTIVE_DEACTIVE;
  payload: Array<any>;
}

interface GetAgentData {
  type: typeof ACTIONS.GET_AGENT_DATA;
  payload: Array<any>;
}
interface GetAssignedUsergroup {
  type: typeof ACTIONS.GET_ASSIGNED_USERGROUP;
  payload: Object;
}
interface GetPanelUserDataSalesLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD;
  payload: Array<any>;
}
interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}

interface GetRecordings {
  type: typeof ACTIONS.GET_RECORDINGS;
  payload: Object;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetRecordingsBackups {
  type: typeof ACTIONS.GET_RECORDINGS_BACKUP;
  payload: Object;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}

interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetRedCoin {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface GetPhleboData {
  type: typeof ACTIONS.GET_PHLEBOS_DATA;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}
interface GetPartnersAuto {
  type: typeof ACTIONS.GET_PARTNERS_AUTO;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetAreaLoading {
  type: typeof ACTIONS.SET_AREA_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}
interface SetBookings {
  type: typeof ACTIONS.SET_ALL_BOOKING_LIST_LOADING;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}
interface GetBreakData {
  type: typeof ACTIONS.GET_BREAK_DATA;
  payload: Object;
}
interface UpdateCoupon {
  type: typeof ACTIONS.UPDATE_COUPON;
  payload: Object;
}
interface CreateCoupon {
  type: typeof ACTIONS.CREATE_COUPON;
  payload: Object;
}
interface GetBankName {
  type: typeof ACTIONS.GET_BANK_NAME;
  payload: Array<any>;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface DisabledUserDetails {
  type: typeof ACTIONS.DISABLED_USER_DETAILS;
  payload: Object;
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}
interface GetTataTalyLead {
  type: typeof ACTIONS.LEAD_TRANSFER_TO_TATATALE;
  payload: Object;
}
interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Array<any>;
}
interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetQualityScoringReport {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT;
  payload: Array<any>;
}
interface GetCallsData {
  type: typeof ACTIONS.GET_CALLS_DATA;
  payload: Array<any>;
}
interface PostQualityScore {
  type: typeof ACTIONS.POST_QUALITY_SCORE;
  payload: Array<any>;
}
interface GetQualityScoringReportForBackup {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP;
  payload: Array<any>;
}
interface RaiseNewTicektDisposition {
  type: typeof ACTIONS.RAISE_NEW_RAISE_TICEKT_DISPOSITION;
  payload: Array<any>;
}
interface GetUserData {
  type: typeof ACTIONS.GET_USER_LIST_DATA;
  payload: Array<any>;
}
interface UpdateUsersPassword {
  type: typeof ACTIONS.UPDATE_USERS_PASSWORD;
  payload: Array<any>;
}
interface getOzontelSkillData {
  type: typeof ACTIONS.GET_OZONTEL_SKILL_DATA;
  payload: Object;
}
interface SetUserLoading {
  type: typeof ACTIONS.SET_USER_LOADING;
  payload: boolean;
}
interface UpdatePhlebos {
  type: typeof ACTIONS.UPDATE_PHLEBOS;
  payload: Object;
}
interface GetPhleboComment {
  type: typeof ACTIONS.GET_PHLEBO_COMMENTS;
  payload: Object;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface CreatePhlebo {
  type: typeof ACTIONS.CREATE_NEW_PHLEBO;
  payload: boolean;
}
interface GetPhleboByPhone {
  type: typeof ACTIONS.GET_PHLEBO_BY_PHONE;
  payload: Object;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetRiderList {
  type: typeof ACTIONS.GET_RIDER_LIST;
  payload: Array<any>;
}
interface UpdateRiderToLabStatus {
  type: typeof ACTIONS.UPDATE_RIDER_TO_LAB_STATUS;
  payload: Array<any>;
}
interface GetSampleHandOver {
  type: typeof ACTIONS.GET_SAMPLE_HANDOVER_DATA;
  payload: Array<any>;
}
interface GetSampleJourneyComments {
  type: typeof ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS;
  payload: Object;
}
interface GetBatchBookingAmount {
  type: typeof ACTIONS.GET_BATCH_BOOKING_AMOUNT;
  payload: boolean;
}
interface UpdateRiderSampleHandover {
  type: typeof ACTIONS.UPDATE_RIDER_SAMPLE_HANDOVER;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface GetTicketCommentsForDocuments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS_FOR_DOCUMENTS;
  payload: Array<any>;
}
interface SetNewTicktingLoading {
  type: typeof ACTIONS.SET_NEW_TICKET_LOADING;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface GetLanguage {
  type: typeof ACTIONS.GET_LANGUAGE;
  payload: boolean;
}
interface GetDialer {
  type: typeof ACTIONS.GET_DIALER;
  payload: boolean;
}
interface GetOrgTypes {
  type: typeof ACTIONS.GET_ORG_TYPES;
  payload: boolean;
}
interface GetChatSkillsList {
  type: typeof ACTIONS.GET_CHAT_SKILLS_LISTS;
  payload: boolean;
}
interface GetAllUsers {
  type: typeof ACTIONS.GET_ALL_USERS;
  payload: boolean;
}
interface GetOtherUsers {
  type: typeof ACTIONS.GET_OTHER_USERS;
  payload: boolean;
}
interface PostDispositionData {
  type: typeof ACTIONS.POST_DISPOSITION_DATA;
  payload: Array<any>;
}
interface GetLeadDispostion {
  type: typeof ACTIONS.GET_LEAD_DISPOSITION_LIST;
  payload: Object;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface GetTimeslots {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Object;
}
interface CreateZone {
  type: typeof ACTIONS.CREATE_ZONE;
  payload: Object;
}
interface GetZoneComment {
  type: typeof ACTIONS.GET_ZONE_COMMENTS;
  payload: Object;
}
interface UpdateZone {
  type: typeof ACTIONS.UPDATE_ZONE;
  payload: Object;
}
interface GetCampaignsDetails {
  type: typeof ACTIONS.GET_CAMPAINGS_DETAILS;
  payload: boolean;
}
interface GetBlackListedList {
  type: typeof ACTIONS.GET_BLACKLISTED_NUMBER;
  payload: boolean;
}
interface CreateBlacklist {
  type: typeof ACTIONS.CREATE_BLACKLIST;
  payload: Object;
}
interface createDialer {
  type: typeof ACTIONS.CREATE_DIALER;
  payload: Object;
}
interface GetAssignedUnassignedBooking {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING;
  payload: Array<any>;
}
interface SetAssignedUnassignedLoader {
  type: typeof ACTIONS.SET_ASSIGNED_LOADING;
  payload: Array<any>;
}
interface GetMapBooking {
  type: typeof ACTIONS.GET_MAP_BOOKING_LIST;
  payload: Array<any>;
}
interface GetPhleboCurrentLocation {
  type: typeof ACTIONS.GET_PHLEBO_CURRENT_LOCATION;
  payload: Array<any>;
}
interface GetCityAlias {
  type: typeof ACTIONS.GET_CITY_ALIAS;
  payload: Array<any>;
}
interface CreateCityAlias {
  type: typeof ACTIONS.CREATE_CITY_ALIAS;
  payload: Array<any>;
}
interface GetCityComments {
  type: typeof ACTIONS.GET_CITY_COMMENTS;
  payload: Object;
}
interface GetPackageComments {
  type: typeof ACTIONS.GET_PACKAGE_COMMENTS;
  payload: Object;
}
interface SendInititalWhatsapp {
  type: typeof ACTIONS.SEND_INITIAL_WHATSAPP_MESSAGE;
  payload: Array<any>;
}
interface GetLtvLead {
  type: typeof ACTIONS.GET_LTV_LEAD;
  payload: boolean;
}
interface UpdateImagingTicket {
  type: typeof ACTIONS.UPDATE_IMAGING_TICKET;
  payload: Array<any>;
}
interface GetTopRcFreshLeads {
  type: typeof ACTIONS.GET_TOP_RC_FRESH_LEADS;
  payload: Array<any>;
}
interface GetImagingTicket {
  type: typeof ACTIONS.GET_IMAGING_TICKET;
  payload: Object;
}
interface GetWhatsappTemplate {
  type: typeof ACTIONS.GET_WHATSAPP_TEMPLATE;
  payload: Array<any>;
}
interface CreateWhatsappTemplate {
  type: typeof ACTIONS.CREATE_WHATSAPP_TEMPLATE;
  payload: boolean;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}
interface SyncBookingWithEs {
  type: typeof ACTIONS.SYNC_BOOKING_WITH_ES;
  payload: boolean;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface GetSmsReportSent {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface SetReportLoading {
  type: typeof ACTIONS.SET_REPORT_LOADING;
  payload: boolean;
}
interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface GetSignature {
  type: typeof ACTIONS.GET_SIGNATURE;
  payload: Array<any>;
}
interface GetAdditionalBooking {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface GetCentre {
  type: typeof ACTIONS.GET_CENTER;
  payload: Array<any>;
}
interface createPartner {
  type: typeof ACTIONS.CREATE_PARTNER;
  payload: Object;
}
interface GetUsersData {
  type: typeof ACTIONS.GET_USER_DATA;
  payload: Object;
}
interface GetPackageAlias {
  type: typeof ACTIONS.GET_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface CreatePackageAlias {
  type: typeof ACTIONS.CREATE_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface UpdatePackageAlias {
  type: typeof ACTIONS.UPDATED_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface GetAgentNewData {
  type: typeof ACTIONS.GET_AGENTS_NEW_DATA;
  payload: Array<any>;
}
interface GetBookingSubDisposition {
  type: typeof ACTIONS.GET_BOOKING_SUB_DISPOSITION;
  payload: boolean;
}
interface GetPanelUserComment {
  type: typeof ACTIONS.GET_PANEL_USER_COMMENT;
  payload: boolean;
}
interface GetVerifiedByList {
  type: typeof ACTIONS.GET_VERIFIED_BY_LIST;
  payload: Object;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}
interface GetPhleboTimeline {
  type: typeof ACTIONS.GET_PHLEBO_TIMELINE;
  payload: Object;
}
interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}
interface GetAddressDetailtstLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface GetBookingCall {
  type: typeof ACTIONS.GET_BOOKING_CALLS;
  payload: Object;
}
interface CreateLab {
  type: typeof ACTIONS.CREATE_LAB;
  payload: boolean;
}
interface UpdateLab {
  type: typeof ACTIONS.UPDATE_LAB;
  payload: boolean;
}
interface GetLabCredential {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL;
  payload: Array<any>;
}
interface GetLabLog {
  type: typeof ACTIONS.GET_LAB_LOG;
  payload: Array<any>;
}
interface ResetLogs {
  type: typeof ACTIONS.RESET_LOGS;
  payload: boolean;
}
interface UpdateCenter {
  type: typeof ACTIONS.UPDATE_CENTER;
  payload: Array<any>;
}
interface UpdatedDialer {
  type: typeof ACTIONS.UPDATED_DIALER;
  payload: Object;
}

interface GetCenterData {
  type: typeof ACTIONS.GET_CENTER_DATA;
  payload: Object;
}
interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetPaginationComment {
  type: typeof ACTIONS.GET_PAGINATION_COMMENT;
  payload: Array<any>;
}
interface SetPhlebosLoading {
  type: typeof ACTIONS.SET_PHLEBOS_LOADING;
  payload: boolean;
}
interface GetPhleboAuto {
  type: typeof ACTIONS.GET_PHLEBOS_AUTO;
  payload: Array<any>;
}
interface GetOtherUser {
  type: typeof ACTIONS.GET_OTHERUSER_LIST;
  payload: Array<any>;
}
interface GetUserTypes {
  type: typeof ACTIONS.GET_USER_TYPES;
  payload: boolean;
}
interface CreateArea {
  type: typeof ACTIONS.CREATE_AREA;
  payload: Object;
}
interface UpdateArea {
  type: typeof ACTIONS.UPDATE_AREA;
  payload: Object;
}
interface GetAreaComments {
  type: typeof ACTIONS.GET_AREA_COMMENTS;
  payload: Array<any>;
}
interface UpdatePartner {
  type: typeof ACTIONS.UPDATE_PARTNER;
  payload: Array<any>;
}
interface CreatePartner {
  type: typeof ACTIONS.PARTNER_CREATED;
  payload: Array<any>;
}
interface PartnerLoading {
  type: typeof ACTIONS.SET_PARTNER_LOADING;
  payload: Array<any>;
}
interface UpdatePickUpStatus {
  type: typeof ACTIONS.UPDATE_PICK_UP_STATUS;
  payload: Array<any>;
}
interface SyncLoading {
  type: typeof ACTIONS.SET_SYNC_LOADING;
  payload: Array<any>;
}
interface GetPanelInLab {
  type: typeof ACTIONS.GET_PANEL_IN_LAB;
  payload: Array<any>;
}
interface GetSyncData {
  type: typeof ACTIONS.GET_SYNC_DATA;
  payload: Object;
}
interface GetBookingUHID {
  type: typeof ACTIONS.GET_BOOKING_UHID;
  payload: Array<any>;
}
interface ResetBookingUHID {
  type: typeof ACTIONS.RESET_BOOKING_UHID;
  payload: Array<any>;
}
interface GetPackageTubeDetails {
  type: typeof ACTIONS.GET_PACKAGE_ALL_TUBE;
  payload: Array<any>;
}
interface GetTubeByBooking {
  type: typeof ACTIONS.GET_TUBE_BY_BOOKING;
  payload: boolean;
}
interface PostSampleCollection {
  type: typeof ACTIONS.POST_SAMPLE_COLLECTION;
  payload: Array<any>;
}
interface GetReceivedPackages {
  type: typeof ACTIONS.GET_RECEIVED_PACKAGES;
  payload: Array<any>;
}
interface UpdateTube {
  type: typeof ACTIONS.UPDATE_PACKAGE_TUBE_DETAILS;
  payload: Array<any>;
}
interface GetValidBarcode {
  type: typeof ACTIONS.GET_VALID_BARCODE;
  payload: Array<any>;
}
interface postBarcodeGenerate {
  type: typeof ACTIONS.POST_BARCODE;
  payload: Array<any>;
}
interface CreateBarcode {
  type: typeof ACTIONS.CREATE_BARCODE;
  payload: Array<any>;
}
interface GetCMSListII {
  type: typeof ACTIONS.GET_CMS_LIST_II;
  payload: boolean;
}
interface GetCmsWallet {
  type: typeof ACTIONS.GET_CMS_WALLET;
  payload: boolean;
}
interface getCmsList {
  type: typeof ACTIONS.GET_CMS_LIST;
  payload: boolean;
}
interface updateCms {
  type: typeof ACTIONS.UPDATE_CMS;
  payload: boolean;
}
interface createCms {
  type: typeof ACTIONS.CREATE_CMS;
  payload: boolean;
}
interface getCmsbatchList {
  type: typeof ACTIONS.GET_BATCH_FOR_CMS;
  payload: boolean;
}
interface getCmsAdvPayment {
  type: typeof ACTIONS.GET_CMS_ADV_PAYMENT;
  payload: boolean;
}
interface GetManuallySettlePayments {
  type: typeof ACTIONS.GET_MANUAL_SETTLE_PAYMENT;
  payload: Array<any>;
}
interface PostStopSendingReport {
  type: typeof ACTIONS.POST_STOP_SENDING_REPORT;
  payload: Array<any>;
}
interface CreateNewCenter {
  type: typeof ACTIONS.CREATE_NEW_CENTER;
  payload: boolean;
}
interface GetBankName {
  type: typeof ACTIONS.GET_BANK_NAME;
  payload: Array<any>;
}
interface GetSalesExecutive {
  type: typeof ACTIONS.GET_SALES_EXECUTIVE;
  payload: Array<any>;
}
interface GetSalesManager {
  type: typeof ACTIONS.GET_SALES_MANAGER;
  payload: Array<any>;
}
interface GetIdentityProof {
  type: typeof ACTIONS.GET_IENTITY_PROOF_DATA;
  payload: Array<any>;
}
interface GetCentreCities {
  type: typeof ACTIONS.GET_CENTRE_CITIES;
  payload: Array<any>;
}
interface GetPaymentModeList {
  type: typeof ACTIONS.GET_PAYMENT_MODE_LIST;
  payload: Array<any>;
}
interface GetRatelistFilter {
  type: typeof ACTIONS.GET_RATELIST_FILTER;
  payload: boolean;
}
interface GetTagProccessingLab {
  type: typeof ACTIONS.GET_TAG_PROCCESSING_LAB;
  payload: Array<any>;
}
interface GetCentreCode {
  type: typeof ACTIONS.GET_CENTRE_CODE;
  payload: Array<any>;
}
interface UploadCentres {
  type: typeof ACTIONS.UPLOAD_CENTRES;
  payload: boolean;
}
interface GetCenters {
  type: typeof ACTIONS.GET_CENTERS;
  payload: Array<any>;
}
interface GetBusinessLocation {
  type: typeof ACTIONS.GET_BUSINESS_LOCATION;
  payload: Array<any>;
}
interface GetCCConsumption {
  type: typeof ACTIONS.GET_CC_CONSUMPTION_ITEMS;
  payload: Object;
}
interface CCToInv {
  type: typeof ACTIONS.CC_TO_INV;
  payload: Object;
}

interface ResetToCCInventoryRes {
  type: typeof ACTIONS.RESET_CC_TO_INVENTORY_RES;
  payload: Object;
}

interface GetIfscData {
  type: typeof ACTIONS.GET_IFSC_DATA;
  payload: any;
}
interface Disable2fotp {
  type: typeof ACTIONS.DISABLE_USER_2FOTP;
  payload: Array<any>;
}
interface getmrrsales {
  type: typeof ACTIONS.GET_MRR_SALES;
  payload: Object;
}
interface Setmrrsales {
  type: typeof ACTIONS.SET_MMR_LOADING;
  payload: Object;
}
interface GetTerritoryData {
  type: typeof ACTIONS.GET_TERRITORY_DATA;
  payload: Array<any>;
}
interface GetCenterInfoPending {
  type: typeof ACTIONS.GET_CENTER_INFORMATION_PENDING;
  payload: boolean;
}
interface CenterComments {
  type: typeof ACTIONS.GET_CENTER_COMMENTS;
  payload: Array<any>;
}
interface GetStateList {
  type: typeof ACTIONS.GET_STATE_LIST;
  payload: Array<any>;
}
interface GetLabOrgCode {
  type: typeof ACTIONS.GET_LAB_ORG_CODE;
  payload: boolean;
}
interface CreateCentreLabOrg {
  type: typeof ACTIONS.CREATE_CENTRE_LAB_ORG;
  payload: boolean;
}
interface UpdateCentreLabOrg {
  type: typeof ACTIONS.UPDATE_CENTRE_LAB_ORG;
  payload: boolean;
}
interface GetCentreLabOrg {
  type: typeof ACTIONS.GET_CENTRE_LAB_ORG;
  payload: boolean;
}
interface GetBlockUserFromCreateBooking {
  type: typeof ACTIONS.GET_BLOCK_USER_FROM_CREATE_BOOKING;
  payload: Array<any>;
}
interface bookingUnBlock {
  type: typeof ACTIONS.BOOKING_UNBLOCK;
  payload: object;
}

interface GetBookingByCentre {
  type: typeof ACTIONS.GET_BOOKINGS_BY_CENTRE;
  payload: object;
}
interface GetLabUser {
  type: typeof ACTIONS.GET_LAB_USER;
  payload: Array<any>;
}
interface CreateLabUser {
  type: typeof ACTIONS.CREATE_LAB_USER;
  payload: Array<any>;
}
interface UpdateLabUser {
  type: typeof ACTIONS.UPDATE_LAB_USER;
  payload: Array<any>;
}
interface GetUnregisterdBooking {
  type: typeof ACTIONS.GET_UNREGISTERD_BOOKING;
  payload: Object;
}
interface UpdateUnregisterdBookingStatus {
  type: typeof ACTIONS.UPDATE_UNREGISTERD_BOOKING_STATUS;
  payload: Object;
}
interface GetLab {
  type: typeof ACTIONS.GET_LAB;
  payload: Array<any>;
}
interface GetShowAllStoreLocations {
  type: typeof ACTIONS.GET_SHOW_ALL_STORE_LOCATIONS;
  payload: Array<any>;
}
interface getUserInfo {
  type: typeof ACTIONS.GET_USER_INFO;
  payload: Object;
}
interface GetSMSLink {
  type: typeof ACTIONS.GET_SMS_LINK;
  payload: Object;
}
interface GetInvoiceByBookingId {
  type: typeof ACTIONS.GET_INVOICE_BY_BOOKING_ID;
  payload: Object;
}
interface SetBiomarkerLoader {
  type: typeof ACTIONS.SET_BIOMARKER_LOADER;
  payload: boolean;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface SetBiomarkerLoader {
  type: typeof ACTIONS.SET_BIOMARKER_LOADER;
  payload: boolean;
}
interface PostBookingIndexing {
  type: typeof ACTIONS.POST_BOOKING_INDEXING;
  payload: Array<any>;
}
interface GetNewLeadDetails {
  type: typeof ACTIONS.GET_NEW_LEAD_DETAILS;
  payload: any;
}
interface GetCustomerSpecificCoupon {
  type: typeof ACTIONS.GET_CUSTOMER_SPECIFIC_COUPON;
  payload: Array<any>;
}
interface GetHivPackage {
  type: typeof ACTIONS.GET_HIV_PACKAGES;
  payload: Object;
}
interface GetFailedReportBYId {
  type: typeof ACTIONS.GET_FAILED_REPORT_DATA_BY_ID;
  payload: Object;
}
interface GetFailedReportData {
  type: typeof ACTIONS.GET_FAILED_REPORT_DATA;
  payload: Array<any>;
}
interface GetResamplingBookingByID {
  type: typeof ACTIONS.GET_RESAMPLING_BOOKING_BY_ID;
  payload: Object;
}
interface GetBookingVerificationOtp {
  type: typeof ACTIONS.GET_BOOKING_VERIFICATION_OTP;
  payload: Object;
}
interface GetPhleboBookingSelfie {
  type: typeof ACTIONS.GET_PHLEBO_BOOKING_SELFIE;
  payload: boolean;
}

interface UpdateClinicalHistoryAnswer {
  type: typeof ACTIONS.UPDATE_CLINICAL_HISTORY_ANSWER;
  payload: Object;
}
interface GetPhleboRecording {
  type: typeof ACTIONS.GET_PHLEBO_RECORDING;
  payload: boolean;
}
interface setBookingInvoiceLoading {
  type: typeof ACTIONS.SET_BOOKING_INVOICE_LOADING;
  payload: Array<any>;
}
interface getBookingInvoice {
  type: typeof ACTIONS.GET_BOOKING_INVOICE;
  payload: Array<any>;
}
interface GetBookingSampleImage {
  type: typeof ACTIONS.GET_BOOKING_SAMPLE_IMAGE;
  payload: Array<any>;
}
interface GetviewPackageAnswerAllergy {
  type: typeof ACTIONS.GET_VIEW_PACKAGE_ANSWER_ALLERGY;
  payload: Array<any>;
}
interface getviewPackageAnswer {
  type: typeof ACTIONS.GET_VIEW_PACKAGE_ANSWER;
  payload: Object;
}

interface PostUploadBookingDocument {
  type: typeof ACTIONS.UPLOAD_BOOKING_DOCUMENT;
  payload: Array<any>;
}

interface GetBookingDocument {
  type: typeof ACTIONS.GET_BOOKING_DOCUMENT;
  payload: Array<any>;
}
interface GetPrescriptionDataByBookingId {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA_BY_BOOKINGID;
  payload: Array<any>;
}
interface GetLeadSubcriptions {
  type: typeof ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA;
  payload: Array<any>;
}
interface GetRefDoctor {
  type: typeof ACTIONS.GET_REF_DOCTOR;
  payload: Array<any>;
}
interface PostRefDoctor {
  type: typeof ACTIONS.POST_REF_DOCTOR;
  payload: Array<any>;
}
interface PostRemoveSubscription {
  type: typeof ACTIONS.REMOVE_SUBSCRIPTION;
  payload: Array<any>;
}

interface GetPackageQuestion {
  type: typeof ACTIONS.GET_PACKAGE_QUESTION;
  payload: Array<any>;
}
interface SetPackageLoading {
  type: typeof ACTIONS.SET_LOADING_PACKAGE_QUESTION;
  payload: boolean;
}
interface PostPackageAnswer {
  type: typeof ACTIONS.POST_PACKAGE_ANSWERS;
  payload: Array<any>;
}
interface GetContainerSlots {
  type: typeof ACTIONS.GET_CONTAINER_SLOTS;
  payload: Array<any>;
}
interface PutFamilyMemberBookingForUpdate {
  type: typeof ACTIONS.PUT_FAMILY_MEMBER_UPDATE;
  payload: Array<any>;
}
interface AddUniqueCustomer {
  type: typeof ACTIONS.ADD_UNIQUE_CUSTOMER;
  payload: Array<any>;
}
interface BookingEditInSales {
  type: typeof ACTIONS.BOOKING_EDIT_IN_SALES;
  payload: Array<any>;
}
interface GetCouponAutoApply {
  type: typeof ACTIONS.GET_COUPON_AUTO_APPLY;
  payload: Array<any>;
}
interface getVIPActivationStatus {
  type: typeof ACTIONS.GET_VIP_ACTIVATION_STATUS;
  payload: Array<any>;
}
interface getlabtransfer {
  type: typeof ACTIONS.GET_LAB_TRANSFER_DETAILS;
  payload: Array<any>;
}
interface getDigitalprescription {
  type: typeof ACTIONS.GET_DIGITAL_PRESCRIPTION_LIST;
  payload: Array<any>;
}
interface sendDigitalPre {
  type: typeof ACTIONS.SEND_DIGITAL_PRESCRIPTION;
  payload: Array<any>;
}
interface createCustomerSpecificCoupon{
  type: typeof ACTIONS.CREATE_CUSTOMER_SPECIFIC_COUPON;
  payload: Array<any>;
}
interface sendReportdata{
  type: typeof ACTIONS.SENT_REPORT_DATA_BY_ID;
  payload: Array<any>;
}
interface getReportSendManual{
  type: typeof ACTIONS.GET_REPORT_SEND_MANUAL;
  payload: Array<any>;
}
interface whatsappStatusMessage{
  type: typeof ACTIONS.WHATSAPP_STATUS_MESSAGE_ID;
  payload: Array<any>;
}
interface getwhatsappcommunitcation{
  type: typeof ACTIONS.GET_WHATSAPP_COMMUNICATION_DETAILS;
  payload: Array<any>;
}
interface whatsappLoading{
  type: typeof ACTIONS.SET_WHATSAPP_DETAILS_LOADING;
  payload: Array<any>;
}
interface CreateUser {
  type: typeof ACTIONS.CREATE_USER;
  payload: Array<any>;
}
interface GetInventoryuser {
  type: typeof ACTIONS.GET_INVENTORY_USERS;
  payload: Array<any>;
}
interface setLocationRightsThruUser {
  type: typeof ACTIONS.SET_LOCATION_RIGHTS_THRU_USER;
  payload: Array<any>;
}
interface GetLocationsOpt {
  type: typeof ACTIONS.GET_LOCATIONS_OPT;
  payload: Array<any>;
}
interface GetApiLogsForPassedModalMango {
  type: typeof ACTIONS.GET_API_LOGS_FOR_PASSED_MODEL_MANGO;
  payload: Array<any>;
}
interface ResetApiLogsMango {
  type: typeof ACTIONS.RESET_API_LOGS_MANGO;
  payload: Array<any>;
}
interface PostOrUpdateStoreLocation {
  type: typeof ACTIONS.POST_OR_UPDATE_STORE_LOCATION;
  payload: Array<any>;
}
interface GetStoreLocationOrFilterOpt {
  type: typeof ACTIONS.GET_STORE_LOCATION_OR_FILTER_OPT;
  payload: Array<any>;
}
interface GetStoreLocationOrFilter {
  type: typeof ACTIONS.GET_STORE_LOCATION_OR_FILTER;
  payload: Array<any>;
}
interface GetStoreLocationOpt {
  type: typeof ACTIONS.GET_STORE_LOCATION_OPT;
  payload: Array<any>;
}
interface getFullStoreLocation {
  type: typeof ACTIONS.GET_FULL_STORE_LOCATION;
  payload: Array<any>;
}
interface GetLabAndHLM {
  type: typeof ACTIONS.GET_LAB_AND_HLM;
  payload: Array<any>;
}
interface GetStoreLocationFilter {
  type: typeof ACTIONS.GET_STORE_LOCATION_FILTER;
  payload: Array<any>;
}
interface GetSignleCentre {
  type: typeof ACTIONS.GET_SINGLE_CENTRE;
  payload: Array<any>;
}
interface getLocationII {
  type: typeof ACTIONS.GET_LOCATION_II;
  payload: Array<any>;
}
interface resetLocationMapping {
  type: typeof ACTIONS.RESET_LOCATION_MAPPING;
  payload: Array<any>;
}
interface GetItemMasterForMapItems {
  type: typeof ACTIONS.GET_ITEM_MASTER_FOR_MAP_ITEMS;
  payload: Array<any>;
}
interface GetItemType {
  type: typeof ACTIONS.GET_ITEM_TYPE;
  payload: Array<any>;
}
interface GetMachine {
  type: typeof ACTIONS.GET_MACHINE;
  payload: Array<any>;
}
interface GetFullItemMaster {
  type: typeof ACTIONS.GET_FULL_ITEM_MASTER;
  payload: Array<any>;
}
interface getAllTaggedItemsToLocation {
  type: typeof ACTIONS.GET_ALL_TAGGED_ITEMS_TO_LOCATION;
  payload: Array<any>;
}
interface GetStorePermittedItemOpt {
  type: typeof ACTIONS.GET_STORE_PERMITTED_ITEMS_OPT;
  payload: Array<any>;
}
interface GetStoreLocation {
  type: typeof ACTIONS.GET_STORE_LOCATION;
  payload: Array<any>;
}
interface GetItemMaster {
  type: typeof ACTIONS.GET_ITEM_MASTER;
  payload: Array<any>;
}

interface GetVendorMaster {
  type: typeof ACTIONS.GET_VENDOR_MASTER;
  payload: Array<any>;
}
interface PostPO {
  type: typeof ACTIONS.POST_PO;
  payload: Array<any>;
}
interface AddItemsToPO {
  type: typeof ACTIONS.ADD_ITEMS_TO_PO;
  payload: Array<any>;
}
interface GetPOOrFilter {
  type: typeof ACTIONS.GET_PO_OR_FILTER;
  payload: Array<any>;
}
interface GetPIIndents {
  type: typeof ACTIONS.GET_PI_INDENTS;
  payload: Array<any>;
}
interface GetQuotationsForCreatingPO {
  type: typeof ACTIONS.GET_QUOTATION_FOR_CREATING_PO;
  payload: Array<any>;
}
interface GetQuotationItemsThruVendorAndRecieptId {
  type: typeof ACTIONS.GET_QUOTATION_ITEMS_THRU_VENDOR_AND_RECIEPT_ID;
  payload: Array<any>;
}
interface GetFullPO {
  type: typeof ACTIONS.GET_FULL_PO;
  payload: Array<any>;
}
interface PostOrUpdatePO {
  type: typeof ACTIONS.POST_OR_UPDATE_PO;
  payload: Array<any>;
}
interface GetCurrencyMaster {
  type: typeof ACTIONS.GET_CURRENCY_MASTER;
  payload: Array<any>;
}
interface GetQuotationForCurrencyValidation {
  type: typeof ACTIONS.GET_QUOTATION_FOR_CURRENCY_VALIDATION;
  payload: Array<any>;
}
interface GetVendorsThruItem {
  type: typeof ACTIONS.GET_VENDORS_THRU_ITEM;
  payload: Array<any>;
}
interface ResetVendorsThruItem {
  type: typeof ACTIONS.RESET_VENDORS_THRU_ITEM;
  payload: Array<any>;
}
interface PostPOAgainstPI {
  type: typeof ACTIONS.POST_PO_AGAINST_PI;
  payload: Array<any>;
}
interface ResetPoAgainstPi {
  type: typeof ACTIONS.RESET_PO_AGAINST_PI;
  payload: Array<any>;
}
interface getCompanyMaster {
  type: typeof ACTIONS.GET_COMPANY_MASTER;
  payload: Array<any>;
}

interface GetStoreLocation {
  type: typeof ACTIONS.GET_STORE_LOCATION;
  payload: Array<any>;
}
interface GetGRNOrFilter {
  type: typeof ACTIONS.GET_GRN_OR_FILTER;
  payload: Array<any>;
}

interface GetFullGRN {
  type: typeof ACTIONS.GET_FULL_GRN;
  payload: Array<any>;
}

interface ResetGRNPage {
  type: typeof ACTIONS.RESET_GRN_PAGE;
  payload: Array<any>;
}

interface postGRNwithItemSet {
  type: typeof ACTIONS.POST_GRN_WITH_ITEM_SET;
  payload: Array<any>;
}
interface GetItemMasterOrFilter {
  type: typeof ACTIONS.GET_ITEM_MASTER_OR_FILTER;
  payload: Array<any>;
}
interface GetAPILogsForPassedModel {
  type: typeof ACTIONS.GET_API_LOGS_FOR_PASSED_MODEL;
  payload: Array<any>;
}
interface GetInvSuperAdminUsers {
  type: typeof ACTIONS.GET_INV_SUPER_ADMIN_USERS;
  payload: Array<any>;
}
interface GetItemsThroughLocation {
  type: typeof ACTIONS.GET_ITEMS_THROUGH_LOCATION;
  payload: Array<any>;
}

interface GetNewItemMasterOrFilter {
  type: typeof ACTIONS.GET_NEW_ITEM_MASTER_OR_FILTER;
  payload: Array<any>;
}

interface postRaiseIndent {
  type: typeof ACTIONS.POST_RAISE_INDENT;
  payload: Array<any>;
}

interface resetNewItemMasterOrFilter {
  type: typeof ACTIONS.RESET_NEW_ITEM_MASTER_OR_FILTER_;
  payload: Array<any>;
}
interface PostOrUpdateIndentStatusForRaiseIndent {
  type: typeof ACTIONS.POST_OR_UPDATE_INDENT_STATUS_FOR_RAISE_INDENT;
  payload: Array<any>;
}

interface GetIndents {
  type: typeof ACTIONS.GET_INDENTS;
  payload: Array<any>;
}

interface GetIndentItems {
  type: typeof ACTIONS.GET_INDENT_ITEMS;
  payload: Array<any>;
}

interface ResetIndentItems {
  type: typeof ACTIONS.RESET_INDENT_ITEMS;
  payload: Array<any>;
}

interface ResetIndentStatusApi {
  type: typeof ACTIONS.RESET_INDENT_STATUS_API;
  payload: Array<any>;
}

interface ResetIndentView {
  type: typeof ACTIONS.RESET_INDENT_VIEW;
  payload: Array<any>;
}
interface GetIndentIssueOrFilterForRaiseIndent {
  type: typeof ACTIONS.GET_INDENT_ISSUE_OR_FILTER_FOR_RAISE_INDENT;
  payload: Array<any>;
}
interface GetManufacture {
  type: typeof ACTIONS.GET_MANUFACTURE;
  payload: Array<any>;
}
interface GetAllLocations {
  type: typeof ACTIONS.GET_ALL_LOCATIONS;
  payload: Array<any>;
}
interface GetCompany {
  type: typeof ACTIONS.GET_COMPANY;
  payload: Array<any>;
}

interface ResetApiLogs {
  type: typeof ACTIONS.RESET_API_LOGS;
  payload: Array<any>;
}
interface postMoveAgent {
  type: typeof ACTIONS.POST_MOVE_AGENT;
  payload: Array<any>;
}



export type QualityExecutiveTypes =
  | CreatePanelUser
  | UpdatedPanelUser
  | SetUserList
  | UpdateUserStatus
  | GetPanelComments
  | GetPanelUserList
  | GetActiveDeactivePanelUser
  | GetAgentData
  | GetAssignedUsergroup
  | GetPanelUserDataSalesLead
  | GetPanelUserDataTeamLead
  | SetLoading
  | GetRecordings
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetPartnersAuto
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | AddRemarkOnCall
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | GetBreakData
  | UpdateCoupon
  | CreateCoupon
  | GetVerificationBookingDetails
  | GetUnmaskedNumber
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetTataTalyLead
  | GetLeadStatus
  | GetPrescriptionData
  | CheckGeoFenceArea
  | GetRedCoin
  | GetAddressTypeSeating
  | CheckGeoFenceAreaMapMyIndia
  | GetMapMyIndiaAccessToken
  | GetUserDetails
  | GetNotificationMessage
  | GetRecordingsBackups
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetQualityScoringReport
  | GetCallsData
  | PostQualityScore
  | GetQualityScoringReportForBackup
  | GetCenterInfo
  | UpdateTicketStatus
  | DisabledUserDetails
  | RaiseNewTicektDisposition
  | GetUserData
  | getOzontelSkillData
  | SetUserLoading
  | UpdatePhlebos
  | GetPhleboComment
  | GetZone
  | CreatePhlebo
  | GetPhleboByPhone
  | GetPaymentInfro
  | GetRiderList
  | UpdateRiderToLabStatus
  | GetSampleHandOver
  | GetSampleJourneyComments
  | GetBatchBookingAmount
  | UpdateRiderSampleHandover
  | UpdateUsersPassword
  | GetTicketComments
  | GetTicketCommentsForDocuments
  | SetNewTicktingLoading
  | GetBookingQueryTickets
  | UpdateActionDisposition
  | GetLanguage
  | GetDialer
  | GetOrgTypes
  | GetChatSkillsList
  | GetAllUsers
  | PostDispositionData
  | GetLeadDispostion
  | GetBookingsLoader
  | GetTimeslots
  | CreateZone
  | GetZoneComment
  | UpdateZone
  | GetCampaignsDetails
  | GetBlackListedList
  | CreateBlacklist
  | createDialer
  | GetAssignedUnassignedBooking
  | SetAssignedUnassignedLoader
  | GetMapBooking
  | GetPhleboCurrentLocation
  | GetCityAlias
  | CreateCityAlias
  | GetCityComments
  | GetPackageComments
  | SendInititalWhatsapp
  | GetLtvLead
  | UpdateImagingTicket
  | GetTopRcFreshLeads
  | GetImagingTicket
  | GetWhatsappTemplate
  | CreateWhatsappTemplate
  | InitiateRefund
  | SyncBookingWithEs
  | SendLocationRequest
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetPaymentCancelLink
  | GetSmsReportSent
  | GetSmsDetails
  | GetEmailDetails
  | GetReportStatus
  | GetReportDownload
  | SetReportLoading
  | GetQRCodeData
  | GetSignature
  | GetAdditionalBooking
  | CheckGeoCheckError
  | GeoLoading
  | GetCentre
  | createPartner
  | GetUsersData
  | GetPackageAlias
  | CreatePackageAlias
  | UpdatePackageAlias
  | GetAgentNewData
  | PostRegeneratePyament
  | BookingConfirmtoCancel
  | BookingConfirmtoPending
  | GetBookingSubDisposition
  | GetPanelUserComment
  | GetVerifiedByList
  | GetCollectionSlot
  | GetPhleboTimeline
  | LocationVerified
  | GetAddressDetailtstLong
  | GetBookingCall
  | GetPhleboData
  | GetUserEdit
  | CreateLab
  | UpdateLab
  | GetLabCredential
  | GetLabLog
  | ResetLogs
  | GetPhleboData
  | UpdateCenter
  | GetCenterData
  | GetSubCenterInformation
  | GetImagingTimeSlots
  | GetDisplayCities
  | GetMultiplepackage
  | GetPaginationComment
  | SetPhlebosLoading
  | GetPhleboAuto
  | GetOtherUser
  | GetOtherUsers
  | GetUserTypes
  | CreateArea
  | UpdateArea
  | GetAreaComments
  | SetAreaLoading
  | UpdatePartner
  | CreatePartner
  | PartnerLoading
  | UpdatePickUpStatus
  | SyncLoading
  | GetPanelInLab
  | GetSyncData
  | GetBookingUHID
  | ResetBookingUHID
  | GetPackageTubeDetails
  | GetTubeByBooking
  | PostSampleCollection
  | GetReceivedPackages
  | UpdateTube
  | GetValidBarcode
  | postBarcodeGenerate
  | CreateBarcode
  | GetCMSListII
  | GetCmsWallet
  | getCmsList
  | updateCms
  | createCms
  | getCmsbatchList
  | getCmsAdvPayment
  | GetManuallySettlePayments
  | PostStopSendingReport
  | SetBookings
  | UpdatePickUpStatus
  | CreateNewCenter
  | GetBankName
  | GetSalesExecutive
  | GetSalesManager
  | GetIdentityProof
  | GetCentreCities
  | GetPaymentModeList
  | GetRatelistFilter
  | GetTagProccessingLab
  | GetCentreCode
  | UploadCentres
  | GetCenters
  | GetBusinessLocation
  | GetCCConsumption
  | CCToInv
  | ResetToCCInventoryRes
  | GetIfscData
  | Disable2fotp
  | getmrrsales
  | Setmrrsales
  | GetTerritoryData
  | GetCenterInfoPending
  | CenterComments
  | GetLabOrgCode
  | CreateCentreLabOrg
  | UpdateCentreLabOrg
  | GetCentreLabOrg
  | GetStateList
  | GetBlockUserFromCreateBooking
  | bookingUnBlock
  | GetBookingByCentre
  | UpdateCentreLabOrg
  | GetLabUser
  | CreateLabUser
  | UpdateLabUser
  | GetUnregisterdBooking
  | UpdateUnregisterdBookingStatus
  | getUserInfo
  | GetSMSLink
  | GetInvoiceByBookingId
  | GetNewLeadDetails
  | GetCustomerSpecificCoupon
  | GetHivPackage
  | GetFailedReportBYId
  | GetFailedReportData
  | GetResamplingBookingByID
  | GetBookingVerificationOtp
  | GetPhleboBookingSelfie
  | UpdateClinicalHistoryAnswer
  | getviewPackageAnswer
  | GetviewPackageAnswerAllergy
  | GetBookingSampleImage
  | getBookingInvoice
  | setBookingInvoiceLoading
  | GetPhleboRecording
  | PostBookingIndexing
  | SetBiomarkerLoader
  | GetBiomarkerScreening
  | GetBookingDocument
  | PostUploadBookingDocument
  | GetPrescriptionDataByBookingId
  | GetLeadSubcriptions
  | GetRefDoctor
  | PostRefDoctor
  | PostRemoveSubscription
  | GetPackageQuestion
  | SetPackageLoading
  | PostPackageAnswer
  | GetContainerSlots
  | PutFamilyMemberBookingForUpdate
  | AddUniqueCustomer
  | BookingEditInSales
  | GetCouponAutoApply
  | getVIPActivationStatus
  | getlabtransfer
  | getDigitalprescription
  | sendDigitalPre
  | createCustomerSpecificCoupon
  | sendReportdata
  | getReportSendManual
  | whatsappStatusMessage
  | getwhatsappcommunitcation
  | whatsappLoading
  | GetLab
  | GetShowAllStoreLocations
  | CreateUser
  | GetInventoryuser
  | setLocationRightsThruUser
  | GetLocationsOpt
  | GetApiLogsForPassedModalMango
  | ResetApiLogsMango
  | PostOrUpdateStoreLocation
  | GetStoreLocationOrFilterOpt
  | GetStoreLocationOrFilter
  | getFullStoreLocation
  | GetStoreLocationOpt
  | GetLabAndHLM
  | GetStoreLocationFilter
  | GetSignleCentre
  | getLocationII
  | resetLocationMapping
  | GetItemMasterForMapItems
  | GetItemType
  | GetMachine
  | GetFullItemMaster
  | getAllTaggedItemsToLocation
  | GetStorePermittedItemOpt
  | getUserInfo
  | GetStoreLocation
  | GetItemMaster
  | GetVendorMaster
  | PostPO
  | AddItemsToPO
  | GetPOOrFilter
  | GetPIIndents
  | GetQuotationsForCreatingPO
  | GetQuotationItemsThruVendorAndRecieptId
  | GetFullPO
  | PostOrUpdatePO
  | GetCurrencyMaster
  | GetQuotationForCurrencyValidation
  | GetVendorsThruItem
  | ResetVendorsThruItem
  | PostPOAgainstPI
  | ResetPoAgainstPi
  | getCompanyMaster
  | ResetApiLogs
  | GetAPILogsForPassedModel
  | GetInvSuperAdminUsers
  | GetItemsThroughLocation
  | GetNewItemMasterOrFilter
  | postRaiseIndent
  | resetNewItemMasterOrFilter
  | PostOrUpdateIndentStatusForRaiseIndent
  | GetIndents
  | GetIndentItems
  | ResetIndentItems
  | ResetIndentStatusApi
  | ResetIndentView
  | GetIndentIssueOrFilterForRaiseIndent
  | GetManufacture
  | GetAllLocations
  | GetCompany
  | GetVendorMaster
  | GetStoreLocation
  | GetGRNOrFilter
  | GetFullGRN
  | ResetGRNPage
  | postGRNwithItemSet
  | GetItemMasterOrFilter
  | GetAPILogsForPassedModel
  | ResetApiLogs
  | postMoveAgent;