import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getBookingCalls } from "../../actions/TechSupportActions";
import { useHistory } from "react-router";
// import { updateUnregisterdBooking } from "../../actions/CustomerSupport";

let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);
type ModalProps = {
  bookingCall: any;
  getBookingCalls: any;
  openModal: any;
  setopenModal: any;
  bookingId: any;
};

const BookingCallCount: React.FC<ModalProps> = ({
  bookingCall,
  getBookingCalls,
  openModal,
  setopenModal,
  bookingId,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    setopenModal(false);
  };

  useEffect(() => {
    if (bookingId !== 0 && openModal) {
      getBookingCalls(bookingId);
    }
  }, [openModal]);

  return (
    <div>
      <Dialog open={openModal} onClose={handleClose}>
        {bookingCall &&
        bookingCall.results &&
        bookingCall.results.length > 0 ? (
          bookingCall.results.map((data: any) => {
            return (
              <div>
                <DialogTitle>
                  Customer Name : {data.booking.customer_name}
                </DialogTitle>
                <DialogTitle>
                  {" "}
                  First Call: {new Date(data.created_at).toLocaleString()}
                </DialogTitle>
                <DialogTitle>
                  {" "}
                  Last Call: {new Date(data.updated_at).toLocaleString()}
                </DialogTitle>
                <DialogTitle>
                  {" "}
                  Customer Support Call : {data.cs_call_count}
                </DialogTitle>
                <DialogTitle>
                  {" "}
                  Phlebo User Call: {data.ph_call_count}
                </DialogTitle>
                <DialogTitle>
                  {" "}
                  Phlebo Admin Call: {data.pha_call_count}
                </DialogTitle>
                <DialogTitle>
                  {" "}
                  Sample Accessioning Call: {data.sa_call_count}
                </DialogTitle>
                <DialogTitle>
                  {" "}
                  Support Executive Call: {data.se_call_count}
                </DialogTitle>
                <DialogTitle>
                  {" "}
                  Support Executive To Phlebo Call:{" "}
                  {data.se_call_to_phlebo_count}
                </DialogTitle>
              </div>
            );
          })
        ) : (
          <DialogTitle> No Booking Call Found</DialogTitle>
        )}
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bookingCall: state.TechSupportReducer.bookingCall,
});

export default connect(mapStateToProps, {
  getBookingCalls,
})(BookingCallCount);
