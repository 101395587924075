import React, { useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  MenuItem,
  Select,
  TableContainer,
  Slider,
  withStyles,
  Switch,
} from "@material-ui/core";
import { useEffect } from "react";
import {
  createPartner,
  getPartners,
  updatePartner,
  getUserData,
  getZone,
  getCities,
  getOrgTypes,
} from "../../actions/TechSupportActions";
import Button from "@mui/material/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loader from "../../components/loader";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { Edit } from "@material-ui/icons";
import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormGroup from "@material-ui/core/FormGroup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import "./index.sass";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "1rem",
      padding: "1rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const PrettoSlider = withStyles({
  root: {
    color: "rgb(146, 74, 145)",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "violet",
    border: "2px solid purple",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

interface Props {
  loading: Boolean;
  partnerLoading: Boolean;
  userDetails: any;
  getPartners: any;
  partnerList: any;
  createPartner: any;
  updatePartner: any;
  userTableData: any;
  getUserData: any;
  getZone: any;
  zoneList: any;
  cities: any;
  getCities: any;
  getOrgTypes: any;
  orgTypesList: any;
}
const CreateCentrePage: React.FC<Props> = ({
  loading,
  partnerLoading,
  userDetails,
  getPartners,
  partnerList,
  updatePartner,
  createPartner,
  userTableData,
  getUserData,
  getZone,
  zoneList,
  cities,
  getCities,
  getOrgTypes,
  orgTypesList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  const [centerId, setCenterId] = useState<any>();
  const [partnerName, setPartnereName] = useState<any>("");
  const [partnerCode, setPartnereCode] = useState<any>("");
  const [commission, setcommission] = useState<any>("");
  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [email, setEmail] = React.useState("");
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [mobileValidation, setMobileValidation] = useState({
    mobileNumber: true,
  });
  const [centerShow, setCentreShow] = useState<boolean>(true);
  const [editButton, setEditButton] = useState<any>(false);
  const [userName, setUserName] = useState<any>("");
  const [zoneName, setZoneName] = useState<any>(0);
  const [city, setCity] = useState<any>([]);
  const [orgType, setOrgType] = useState<any>("");

  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "mob" &&
      mobileNumber &&
      !mobileRegEx.test(mobileNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        mobileNumber: false,
      }));
    }
  };
  useEffect(() => {
    getPartners("");
  }, []);

  const handleSubmitCentre = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    // if (!editButton) {
    formData.append("partner_name", partnerName);
    formData.append("partner_code", partnerCode);
    formData.append("partner_mobile", mobileNumber);
    formData.append("partner_email", email);
    formData.append("commission", commission);
    formData.append("city", city);
    formData.append("zone", zoneName);
    formData.append("org_type", orgType);
    // }
    // formData.append("partner_name", partnerName);
    // formData.append("partner_code", partnerCode);

    if (editButton) {
      await updatePartner(partnerName, formData);
    } else {
      await createPartner(formData);
    }
    history.push("/dashboard/ts/PartnerCreation");
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = partnerList?.links && partnerList?.links?.next.split("?")[1];
      getPartners(`?${url}`);
    } else if (newPage < page) {
      let url =
        partnerList?.links && partnerList?.links?.previous.split("?")[1];
      getPartners(`${url == undefined ? "" : "?" + url}`);
    }
    setPage(newPage);
  };

  const handleEditCentre = (data: any) => {
    setDisableEdit(true);
    setEditButton(true);
    setPartnereName(data?.partner_name);
    setPartnereCode(data?.partner_code);
    setMobileNumber(data?.user?.phonenumber);
    setEmail(data?.user?.email);
    setcommission(data?.commission);
    setCity(data?.city);
    setZoneName(data?.zone);
    setOrgType(data?.org_type);
  };



  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <form onSubmit={handleSubmitCentre}>
        <h4
          className={classes.hoveItem}
          style={{ fontWeight: "bold", background: "#924A91", color: "#fff" }}
          onClick={() => setCentreShow(centerShow)}
        >
          {/* {centerShow ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
          ADD PARTNER
        </h4>
        <Paper elevation={5} className={classes.paper}>
          {centerShow ? (
            <>
              <Grid
                container
                spacing={3}
                direction="row"
                style={{ alignItems: "left" }}
              >
                {!editButton ? (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="username"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setUserName(obj?.uuid);
                          }
                        }}
                        options={userTableData?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        // multiple
                        getOptionLabel={(option: any) => option.username}
                        getOptionDisabled={(option: any) => {
                          return userName.includes(option.id);
                        }}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getUserData(`?code=${newInputValue}`);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setUserName("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Username"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        className="input"
                        name="title"
                        type="text"
                        label="Partner Code"
                        value={partnerCode}
                        required
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => setPartnereCode(e.target.value)}
                        InputProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        className="input"
                        name="partner_name"
                        type="text"
                        label="Partner Name"
                        value={partnerName}
                        required
                        variant="outlined"
                        onChange={(e) => setPartnereName(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        className="input"
                        name="mobile_number"
                        type="number"
                        label="Partner Mobile Number"
                        value={mobileNumber}
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) =>
                          setMobileNumber(e.target.value as String)
                        }
                        onWheel={(e: any) => e.target.blur()}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        onBlur={() => verifyMobile("mob")}
                        onFocus={() => {
                          setMobileValidation((prev: any) => ({
                            ...prev,
                            mobileNumber: true,
                          }));
                        }}
                        helperText={
                          !mobileValidation.mobileNumber &&
                          "Incorrect Mobile Number"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name="email"
                        type="email"
                        value={email}
                        className="input"
                        label="Partner Email "
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value as string)}
                        style={{ width: "100%" }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="zonename"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setZoneName(obj.id);
                          }
                        }}
                        options={zoneList?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option.city.name}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getZone(`${newInputValue}`);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setZoneName(0);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Zone/City"
                            variant="outlined"
                            style={{
                              width: "100%",
                              height: "40px",
                              padding: "0",
                            }}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="city"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setCity(obj.id);
                          }
                        }}
                        options={cities || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option?.name}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getCities(newInputValue);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setCity(0);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="City Name"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            required
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="org_type"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setOrgType(obj.name);
                          }
                        }}
                        options={orgTypesList?.results || []}
                        defaultValue={orgType}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option?.name}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getOrgTypes(newInputValue);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setOrgType(0);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Org Types"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name="lat"
                        type="text"
                        value={commission}
                        className="input"
                        label="Commission"
                        variant="outlined"
                        onChange={(e) =>
                          setcommission(e.target.value as string)
                        }
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="username"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setUserName(obj?.uuid);
                          }
                        }}
                        options={userTableData?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        // multiple
                        getOptionLabel={(option: any) => option.username}
                        getOptionDisabled={(option: any) => {
                          return userName.includes(option.id);
                        }}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getUserData(`?code=${newInputValue}`);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setUserName("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Username"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        className="input"
                        name="title"
                        type="text"
                        label="Partner Code"
                        value={partnerCode}
                        required
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => setPartnereCode(e.target.value)}
                        InputProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        className="input"
                        name="center_name"
                        type="text"
                        label="Partner Name"
                        value={partnerName}
                        required
                        variant="outlined"
                        onChange={(e) => setPartnereName(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        className="input"
                        name="mobile_number"
                        type="number"
                        label="Partner Mobile Number"
                        value={mobileNumber}
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) =>
                          setMobileNumber(e.target.value as String)
                        }
                        onWheel={(e: any) => e.target.blur()}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        onBlur={() => verifyMobile("mob")}
                        onFocus={() => {
                          setMobileValidation((prev: any) => ({
                            ...prev,
                            mobileNumber: true,
                          }));
                        }}
                        helperText={
                          !mobileValidation.mobileNumber &&
                          "Incorrect Mobile Number"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name="email"
                        type="email"
                        value={email}
                        className="input"
                        label="Partner Email "
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value as string)}
                        style={{ width: "100%" }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="zonename"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setZoneName(obj.id);
                          }
                        }}
                        options={zoneList?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option.city.name}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getZone(`${newInputValue}`);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setZoneName(0);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Zone/City"
                            variant="outlined"
                            style={{
                              width: "100%",
                              height: "40px",
                              padding: "0",
                            }}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="city"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setCity(obj.id);
                          }
                        }}
                        options={cities || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option?.name}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getCities(newInputValue);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setCity(0);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="City Name"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            required
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="org_type"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setOrgType(obj.name);
                          }
                        }}
                        options={orgTypesList?.results || []}
                        defaultValue={orgType}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option?.name}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getOrgTypes(newInputValue);
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setOrgType(0);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Org Types"
                            variant="outlined"
                            style={{
                              width: "100%",
                              margin: "0",
                              padding: "1px",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name="lat"
                        type="text"
                        value={commission}
                        className="input"
                        label="Commission"
                        variant="outlined"
                        onChange={(e) =>
                          setcommission(e.target.value as string)
                        }
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          ) : (
            ""
          )}
          <Grid
            container
            spacing={3}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!mobileValidation.mobileNumber}
                type="submit"
              >
                {editButton ? "Update Partner" : "Create Partner"}
              </Button>
            </Grid>
            {!editButton ? (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ts/PartnerCreation")}
                >
                  Reset
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ts/PartnerCreation")}
                >
                  Cancel Update
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </form>
      <Paper elevation={5} className={classes.paper}>
        <Grid
          container
          spacing={3}
          className={classes.centerIt}
          style={{
            margin: "1rem auto",
          }}
        >
          <Grid item xs={12} sm={6} md={2}>
            <h5 style={{ marginTop: 15 }}>Search Option </h5>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className="input"
              label="Partner/Code"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPartners(`?code=${e.target.value}&show_all=true`);
                }, 1000);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => history.push("/dashboard/ts/PartnerCreation")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "1rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "515px" }}
          >
            {partnerLoading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      Partner Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Partner Code
                    </StyledTableCell>
                    <StyledTableCell align="center">Commission</StyledTableCell>
                    <StyledTableCell align="center">
                      Partner Email
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Partner Phone
                    </StyledTableCell>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center">Zone</StyledTableCell>
                    <StyledTableCell align="center">
                      Edit Partner
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {partnerList &&
                    partnerList?.results &&
                    partnerList?.results?.length > 0 &&
                    partnerList?.results?.map((data: any, index: any) => {
                      return (
                        <StyledTableRow
                          key={index}
                          style={{
                            background:
                              data?.is_active === true ? "" : "#f7c2be",
                          }}
                        >
                          <StyledTableCell align="center">
                            {data?.partner_name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            {data?.partner_code}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            {data?.commission}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            {data?.user?.email}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            {data?.user?.phonenumber}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            {data?.city}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            {data?.zone}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Button
                              disabled={disableEdit}
                              color="primary"
                              variant="contained"
                            >
                              <Edit onClick={() => handleEditCentre(data)} />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    colSpan={6}
                    count={partnerList.count || 0}
                    rowsPerPageOptions={[]}
                    rowsPerPage={partnerList.page_size}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              </Table>
            )}
          </TableContainer>
        </div>
      </Paper>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  partnerLoading: state.TechSupportReducer.partnerLoading,
  partnerList: state.TechSupportReducer.partnerList,
  userTableData: state.TechSupportReducer.userTableData,
  zoneList: state.TechSupportReducer.zoneList,
  cities: state.TechSupportReducer.cities,
  orgTypesList: state.TechSupportReducer.orgTypesList,
});
export default connect(mapStateToProps, {
  createPartner,
  getPartners,
  updatePartner,
  getUserData,
  getZone,
  getCities,
  getOrgTypes,
})(CreateCentrePage);
