import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import PaymentsIcon from "@mui/icons-material/Payments";
import ElectricMopedIcon from "@mui/icons-material/ElectricMoped";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader80";
import moment from "moment";
import { useRef } from "react";
import BookingCommentsModal from "../components/Comments/bookingComments";
import { MessageRounded } from "@material-ui/icons";
import GamesIcon from "@material-ui/icons/Games";
import BookingCallCount from "../components/Comments/bookingcallcountmodal";
import AdditonalFamilyModal from "../tables/AdditionalFamily/AdditionFamilyDetails";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Autocomplete from "@mui/material/Autocomplete";
import SaveIcon from "@mui/icons-material/Save";
import {
  updateBookingZone,
  postRegeneratePayment,
  cancelToConfirm,
  confirmToPending,
  updatePickUpStatus,
  postStopSendingReport,
  getManuallySettlePayments,
} from "../actions/TechSupportActions";
import LocationVerificationModal from "../components/Comments/locationVerificationModal";
import Tooltip from "@mui/material/Tooltip";
import "./index.sass";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@material-ui/icons/Close";
import PendingIcon from "@mui/icons-material/Pending";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { width } from "@mui/system";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "10px",
      width: "100%",
    },
    table: {
      minWidth: 700,
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getBookings: any;
  getAllNewBookings: any;
  bookingList: any;
  newbookingList: any;
  page: any;
  setPage: any;
  setopenModal: any;
  openModal: any;
  getAllLeads: any;
  leadTransferByComma: any;
  agentBYUserGroup: any;
  loading: Boolean;
  selectedLeads: any;
  setSelectedLeads: any;
  panel_user: any;
  setPaymentId: any;
  paymentId: any;
  openPaymentModal: any;
  setOpenPaymentModal: any;
  setPanelUser: any;
  setTotalCount: any;
  getZone: any;
  zoneList: any;
  updateBookingZone: any;
  bookingListLoading: boolean;
  openPhlebotModel: any;
  setOpenPhleboModel: any;
  setBookingIdModal: any;
  postRegeneratePayment: any;
  cancelToConfirm: any;
  confirmToPending: any;
  updatePickUpStatus: any;
  userDetails:any;
  setSyncOpenModalNew:any;
  setSyncId:any;
  setBookingData:any;
  setTubeId:any;
  setBId:any;
  setBookingType:any;
  setAdditionalSyncId:any;
  SyncLoading:any;
  postStopSendingReport: any;
  getManuallySettlePayments: any;
}

const BookingsTable: React.FC<Props> = ({
  getBookings,
  bookingList,
  newbookingList,
  page,
  setPage,
  setopenModal,
  agentBYUserGroup,
  leadTransferByComma,
  getAllLeads,
  openModal,
  selectedLeads,
  setSelectedLeads,
  panel_user,
  setPanelUser,
  setPaymentId,
  setOpenPaymentModal,
  openPaymentModal,
  paymentId,
  setBookingIdModal,
  setOpenPhleboModel,
  setTotalCount,
  getZone,
  zoneList,
  updateBookingZone,
  loading,
  bookingListLoading,
  openPhlebotModel,
  postRegeneratePayment,
  cancelToConfirm,
  confirmToPending,
  getAllNewBookings,
  updatePickUpStatus,
  userDetails,
  setSyncOpenModalNew,
  setSyncId,
  setBookingData,
  setTubeId,
  setBId,
  setBookingType,
  setAdditionalSyncId,
  SyncLoading,
  postStopSendingReport,
  getManuallySettlePayments,
}) => {
  const classes = useStyles();
  const paymentIdForInvoice = process.env.REACT_APP_PAYMENT_ID_FOR_INVOICE;
  const [bookingId, setBookingId] = useState<number>(0);
  const [leadId, setleadId] = useState<number>(0);
  const [bookingIdCall, setBookingIdCall] = useState<number>(0);
  const [additionalMemebr, setAdditionalMemebr] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  const [openCall, setOpenCall] = useState<boolean>(false);
  const [zoneId, setZoneId] = useState<any>("");
  const [bkId, setBkId] = useState<any>("");
  const [bookingIdLocation, setBookingIdLocation] = useState<number>(0);
  const [leadIdLocation, setLeadIdLocation] = useState<number>(0);
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const [customerLatitude, setCustomerLatitude] = useState<any>();
  const [customerLongitude, setCustomerLongitude] = useState<any>();
  const [customerLatitude1, setCustomerLatitude1] = useState<any>();
  const [customerLongitude1, setCustomerLongitude1] = useState<any>();
  const [cityId, setCityId] = useState<any>("");
  const [addressLine2, setAddressLine2] = useState<string>();
  const [favoritePlace, setFavoritePlace] = useState<any>();
  const [customer_address, setCustomer_address] = useState<String>("");
  const [customer_landmark1, setCustomer_landmark1] = useState<string>("");
  const [verifyKey, setVerifyKey] = useState<boolean>(false);
  const [heading, setHeading] = useState<string>("");

  const timer = useRef<any>(0);

  const handleClickLocation = (id: number) => {
    setOpenLocation(true);
    setBookingIdLocation(id);
  };

  const handleClick = (id: number, lead: number) => {
    setopenModal(true);
    setBookingId(id);
    setBookingIdModal(id);
  };
  const handleClickCall = (id: number) => {
    setOpenCall(true);
    setBookingIdCall(id);
  };
  const updateZone = (zid: any, bid: any) => {
    setZoneId(zid);
    setBkId(bid);
  };
  const handleUpdateZone = async () => {
    const data = {
      booking_id: bkId,
      zone_id: zoneId,
    };
    if (bkId !== "" && zoneId !== "") {
      await updateBookingZone(data);
    } else {
      alert("please select zone then update");
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = bookingList.links && bookingList.links.next?.split("?")[1];
      getAllNewBookings(`${url}`);
    } else if (newPage < page) {
      let url = bookingList.links && bookingList.links.previous?.split("?")[1];
      getAllNewBookings(`${url}`);
    }
    setPage(newPage as number);
  };
  const handlePaymentInfo = (id: any) => {
    setPaymentId(id);
    setOpenPaymentModal(true);
    setPaymentId(id);
  };
  const handleAdditionalView = (member: any) => {
    setAdditionalMemebr(member);
    setAdditonalModal(true);
  };
  useEffect(() => {
    setTotalCount(bookingList?.count);
  }, [bookingList]);
  const openPhleboJourneyModel = (data: any) => {
    setBookingId(data);
    setBookingIdModal(data);
    setOpenPhleboModel(true);
  };

  const handleRegeneratePayment = (id: any) => {
    dialogBox(id);
  };

  const dialogBox = (getId: any) => {
    let text = "Are You Sure You Want To Regenerate Subscription Payment?";
    if (window.confirm(text) === true) {
      text = "Yes";
      postRegeneratePayment(getId);
      getAllNewBookings();
    } else {
      text = "No";
    }
  };

  const handleCancelBooking = (id: any) => {
    dialogBox1(id);
  };

  const dialogBox1 = (getId: any) => {
    let text =
      "Are You Sure You Want To Change Booking Status Confirmed to Cancel?";
    if (window.confirm(text) === true) {
      text = "Yes";
      cancelToConfirm(getId);
      getAllNewBookings();
    } else {
      text = "No";
    }
  };
  const handlePendingBooking = (id: any) => {
    dialogBox2(id);
  };

  const dialogBox2 = (getId: any) => {
    let text =
      "Are You Sure You Want To Change Booking Status Confirmed to Pending?";
    if (window.confirm(text) === true) {
      text = "Yes";
      confirmToPending(getId);
      getAllNewBookings();
    } else {
      text = "No";
    }
  };

  const handlePickUpStatusBooking = (id: number) => {
    dialogBoxForPickUpStatus(id);
  };

  const dialogBoxForPickUpStatus = (getId: number) => {
    let text =
      "Are You Sure You Want To Change The Pick Up Status From Hold/Pending to Confirm?";
    if (window.confirm(text) === true) {
      text = "Yes";
      updatePickUpStatus(getId);
      getAllNewBookings();
    } else {
      text = "No";
    }
  };

  const handleSyncNew = async (
    id: any,
    data: any,
    bId: any,
    lab: any,
    type: any
  ) => {
      setSyncId(id);
      setSyncOpenModalNew(true);
      setBookingData(data);
      setTubeId(id);
      setBId(bId);
      setBookingType(type);
      setAdditionalSyncId(0);
  };

  const handlePaymentSettlement = (data: any) => {
    let text = "Are You Sure You Want To Settle The Payment ?";
    if (window.confirm(text) === true) {
      text = "Yes";
      getManuallySettlePayments(`booking_ids=${data.pk}`);
    } else {
      text = "No";
    }
  };
  const handleDoNotSendReport = (data: any) => {
    let text = "Are You Sure You Want To Stop The Report?";
    if (window.confirm(text) === true) {
      text = "Yes";
      const postData: any = {
        restrict_report: true,
        booking: data?.pk,
      };
      postStopSendingReport(postData);
    } else {
      text = "No";
    }
  };
  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "500px" }}
      >
        {bookingListLoading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Phlebo Journey</StyledTableCell>
                <StyledTableCell align="center">Action </StyledTableCell>
                <StyledTableCell align="center">Lead ID</StyledTableCell>
                <StyledTableCell align="center">Booking ID</StyledTableCell>
                <StyledTableCell align="center">Lims Id</StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">
                  Location Verified
                </StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">
                  Location Event Status
                </StyledTableCell>
                {!(
                  userDetails?.profile?.executive_type ===
                    "gene_counsellor_admin" ||
                  userDetails?.profile?.executive_type === "gene_counsellor"
                ) && <StyledTableCell style={{ backgroundColor:"rgb(146, 74, 145)",color:"white"}}  align="center">Sync</StyledTableCell>}
                <StyledTableCell align="center">Booking Date</StyledTableCell>
                <StyledTableCell align="center">Booking For</StyledTableCell>
                <StyledTableCell align="center">Price</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Agent</StyledTableCell>
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Gender</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Source Type</StyledTableCell>
                <StyledTableCell align="center">
                  Collection Date
                </StyledTableCell>
                <StyledTableCell align="center">
                  Collection Time
                </StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">Booking Status</StyledTableCell>
                <StyledTableCell align="center">Report Status</StyledTableCell>
                <StyledTableCell align="center">
                  Verification Status
                </StyledTableCell>
                <StyledTableCell align="center">SE Call Count</StyledTableCell>
                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                <StyledTableCell align="center">Pickup Amount</StyledTableCell>
                <StyledTableCell align="center">Payment Id</StyledTableCell>
                <StyledTableCell align="center">Payment Mode</StyledTableCell>
                <StyledTableCell align="center">Pickup Date</StyledTableCell>
                <StyledTableCell align="center">Pickup Time</StyledTableCell>
                <StyledTableCell align="center">Booking Time</StyledTableCell>
                <StyledTableCell align="center">
                  Receivable Amount
                </StyledTableCell>
                <StyledTableCell align="center">Call Count</StyledTableCell>
                <StyledTableCell align="center">Payment Info</StyledTableCell>
                <StyledTableCell align="center">
                  Regenerate Subscription
                </StyledTableCell>
                <StyledTableCell align="center">
                  Payment Settlement
                </StyledTableCell>
                <StyledTableCell align="center">
                  Do Not Send Report
                </StyledTableCell>
                <StyledTableCell align="center">
                  Confirm To Cancel
                </StyledTableCell>
                <StyledTableCell align="center">
                  Confirm To Pending
                </StyledTableCell>
                <StyledTableCell align="center">
                  Pickup status Pending/Hold To Confirm
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookingList?.results &&
                bookingList?.results?.length > 0 &&
                bookingList?.results?.map((booking: any, index: any) => {
                  const pendingData = booking?.additional_members?.filter(
                    (data: any) => data?.pickup_status === "pending"
                  );
                  const confirmedData = booking?.additional_members?.filter(
                    (data: any) => data?.pickup_status === "confirmed"
                  );

                  return (
                    <StyledTableRow
                      style={{
                        backgroundColor:
                          booking?.booking_type === "b2b" &&
                          booking?.receivable_amount === 0 &&
                          booking?.phlebo_reached === false
                            ? "#ffe082"
                            : booking?.booking_type === "b2b" &&
                              booking?.receivable_amount !== 0 &&
                              booking?.phlebo_reached === false
                            ? "#f48fb1"
                            : booking?.booking_type === "b2b" &&
                              booking?.receivable_amount === 0 &&
                              booking?.phlebo_reached
                            ? "#4caf50"
                            : booking?.booking_type === "b2b" &&
                              booking?.receivable_amount !== 0 &&
                              booking?.phlebo_reached
                            ? "#4caf50"
                            : booking?.booking_type === "b2c" &&
                              booking?.receivable_amount == 0 &&
                              booking?.phlebo_reached === false
                            ? "#64ffda"
                            : booking?.booking_type === "b2c" &&
                              booking?.receivable_amount == 0 &&
                              booking?.phlebo_reached
                            ? "#4caf50"
                            : booking?.discounted_price?.total_price_package >=
                              "2000"
                            ? "#cddc39"
                            : "",
                      }}
                      key={index}
                    >
                      <StyledTableCell align="center">
                        {booking?.booking_type === "b2c" &&
                        booking?.org_type === "homedx" ? (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                openPhleboJourneyModel(booking?.pk)
                              }
                              style={{ height: "27px" }}
                            >
                              <ElectricMopedIcon />
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/ts/booking-view/${booking.pk}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ height: "27px" }}
                          >
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking.lead}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.additional_members?.length > 0 ? (
                          <div>
                            {booking.pk}+
                            <Chip
                              label={
                                "T" +
                                booking?.additional_members?.length +
                                `${
                                  confirmedData.length > 0
                                    ? "C" + confirmedData.length
                                    : ""
                                }` +
                                `${
                                  pendingData.length > 0
                                    ? "P" + pendingData.length
                                    : ""
                                }`
                              }
                              style={{
                                background:
                                  pendingData.length > 0
                                    ? "#e91e63"
                                    : "#1b5e20",
                              }}
                              onClick={() =>
                                handleAdditionalView(
                                  booking?.additional_members
                                )
                              }
                            />
                          </div>
                        ) : (
                          booking?.pk
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {booking?.bill_id || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div style={{ display: "flex" }}>
                          <Autocomplete
                            id="zone"
                            key={booking?.customer_areapincode}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                let obj = JSON.parse(
                                  JSON.stringify(newValue, null, " ")
                                );
                                updateZone(obj.id, booking.pk);
                              }
                            }}
                            defaultValue={{
                              id: "",
                              city: { city: booking?.zone_name },
                            }}
                            options={zoneList?.results || []}
                            freeSolo
                            blurOnSelect
                            onInputChange={(event, newInputValue) => {
                              clearTimeout(timer.current);
                              timer.current = setTimeout(() => {
                                if (event !== null)
                                  getZone(newInputValue?.split(",")[0]);
                              }, 1000);
                            }}
                            getOptionLabel={(option: any) =>
                              `${
                                option?.city?.name
                                  ? option?.city?.name
                                  : option?.city?.city
                              }`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Zone Name"
                                variant="outlined"
                                style={{ width: "150px" }}
                                size="small"
                              />
                            )}
                            disabled={booking?.pickup_status !== "confirmed"}
                            // disabled
                          />
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            style={{ height: "27px", marginLeft: "8px" }}
                            onClick={() => handleUpdateZone()}
                          >
                            <SaveIcon />
                          </Button>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_out_of_zone === true &&
                        booking?.address_verified === true ? (
                          <Button
                            onClick={() => {
                              setCustomerLatitude(booking?.customer_latitude);
                              setCustomerLatitude1(
                                booking?.verified_location?.lat
                              );
                              setCustomerLongitude(booking?.customer_longitude);
                              setCustomerLongitude1(
                                booking?.verified_location?.long
                              );
                              setLeadIdLocation(booking?.lead);
                              setCityId(
                                booking?.customer_areapincode?.city?.id
                              );
                              setAddressLine2(booking?.address_line2);
                              setFavoritePlace("");
                              setCustomer_address(booking?.customer_address);
                              setCustomer_landmark1(booking?.customer_landmark);
                              setHeading("Out of Service - Verified");
                              handleClickLocation(booking?.pk);
                            }}
                            variant="outlined"
                            style={{ width: "270px", padding: "0px" }}
                          >
                            Out of Service - Verified
                          </Button>
                        ) : booking?.customer_out_of_zone === true ? (
                          <Button
                            onClick={() => {
                              setCustomerLatitude(booking?.customer_latitude);
                              setCustomerLatitude1(
                                booking?.verified_location?.lat
                              );
                              setCustomerLongitude(booking?.customer_longitude);
                              setCustomerLongitude1(
                                booking?.verified_location?.long
                              );
                              setLeadIdLocation(booking?.lead);
                              setCityId(
                                booking?.customer_areapincode?.city?.id
                              );
                              setAddressLine2(booking?.address_line2);
                              setFavoritePlace("");
                              setCustomer_address(booking?.customer_address);
                              setCustomer_landmark1(booking?.customer_landmark);
                              setHeading("Non-Serviceable - Not Verified");
                              handleClickLocation(booking?.pk);
                            }}
                            variant="outlined"
                            style={{ width: "270px", padding: "0px" }}
                          >
                            Non-Serviceable - Not Verified
                          </Button>
                        ) : booking?.address_verified === false &&
                          booking?.distance_above_deviation === false ? (
                          <Button
                            onClick={() => {
                              setCustomerLatitude(booking?.customer_latitude);
                              setCustomerLatitude1(
                                booking?.verified_location?.lat
                              );
                              setCustomerLongitude(booking?.customer_longitude);
                              setCustomerLongitude1(
                                booking?.verified_location?.long
                              );
                              setLeadIdLocation(booking?.lead);
                              setCityId(
                                booking?.customer_areapincode?.city?.id
                              );
                              setAddressLine2(booking?.address_line2);
                              setFavoritePlace("");
                              setCustomer_address(booking?.customer_address);
                              setCustomer_landmark1(booking?.customer_landmark);
                              setHeading("Location Not Shared");
                              handleClickLocation(booking?.pk);
                            }}
                            variant="outlined"
                            style={{ width: "270px", padding: "0px" }}
                          >
                            Location Not Shared
                          </Button>
                        ) : booking?.address_verified === true &&
                          booking?.distance_above_deviation === true ? (
                          <Button
                            onClick={() => {
                              setCustomerLatitude(booking?.customer_latitude);
                              setCustomerLatitude1(
                                booking?.verified_location?.lat
                              );
                              setCustomerLongitude(booking?.customer_longitude);
                              setCustomerLongitude1(
                                booking?.verified_location?.long
                              );
                              setLeadIdLocation(booking?.lead);
                              setCityId(
                                booking?.customer_areapincode?.city?.id
                              );
                              setAddressLine2(booking?.address_line2);
                              setFavoritePlace("");
                              setCustomer_address(booking?.customer_address);
                              setCustomer_landmark1(booking?.customer_landmark);
                              setHeading("More than 3 Km - Verified");
                              handleClickLocation(booking?.pk);
                            }}
                            variant="outlined"
                            style={{ width: "270px", padding: "0px" }}
                          >
                            More than 3 Km - Verified
                          </Button>
                        ) : booking?.address_verified === false &&
                          booking?.distance_above_deviation === true ? (
                          <Button
                            onClick={() => {
                              setCustomerLatitude(booking?.customer_latitude);
                              setCustomerLatitude1(
                                booking?.verified_location?.lat
                              );
                              setCustomerLongitude(booking?.customer_longitude);
                              setCustomerLongitude1(
                                booking?.verified_location?.long
                              );
                              setLeadIdLocation(booking?.lead);
                              setCityId(
                                booking?.customer_areapincode?.city?.id
                              );
                              setAddressLine2(booking?.address_line2);
                              setFavoritePlace("");
                              setCustomer_address(booking?.customer_address);
                              setCustomer_landmark1(booking?.customer_landmark);
                              setHeading("More than 3 Km - Not Verified");
                              handleClickLocation(booking?.pk);
                            }}
                            variant="outlined"
                            style={{ width: "270px", padding: "0px" }}
                          >
                            More than 3 Km - Not Verified
                          </Button>
                        ) : booking?.address_verified === true &&
                          booking?.distance_above_deviation === false ? (
                          <Button
                            onClick={() => {
                              setCustomerLatitude(booking?.customer_latitude);
                              setCustomerLatitude1(
                                booking?.verified_location?.lat
                              );
                              setCustomerLongitude(booking?.customer_longitude);
                              setCustomerLongitude1(
                                booking?.verified_location?.long
                              );
                              setLeadIdLocation(booking?.lead);
                              setCityId(
                                booking?.customer_areapincode?.city?.id
                              );
                              setAddressLine2(booking?.address_line2);
                              setFavoritePlace("");
                              setCustomer_address(booking?.customer_address);
                              setCustomer_landmark1(booking?.customer_landmark);
                              setHeading("Less than 3 Km - Verified");
                              handleClickLocation(booking?.pk);
                            }}
                            variant="outlined"
                            style={{ width: "270px", padding: "0px" }}
                          >
                            Less than 3 Km - Verified
                          </Button>
                        ) : (
                          <Button></Button>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageRounded
                            style={{
                              color: "#6a1b9a",
                              cursor: "pointer",
                              fontSize: "42px",
                              padding: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#f3e5f5",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                              transition:
                                "background-color 0.3s ease, transform 0.3s ease",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#e1bee7";
                              e.currentTarget.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f3e5f5";
                              e.currentTarget.style.transform = "scale(1)";
                            }}
                            onClick={() =>
                              handleClick(booking?.pk, booking?.id)
                            }
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.location_event_status
                          ? booking?.location_event_status
                          : "NA"}
                      </StyledTableCell>
                      {(
                            <StyledTableCell align="center">
                              {booking?.bill_id === null &&
                              booking?.pickup_status === "confirmed" &&
                              booking?.is_sync === false ? (
                                <Button
                                  disabled={booking?.is_sync}
                                  variant="contained"
                                  color="inherit"
                                  onLoad={SyncLoading}
                                  onClick={() =>
                                    handleSyncNew(
                                      booking?.pk ? booking?.pk : booking?.id,
                                      booking,
                                      booking?.pk ? booking?.pk : booking?.id,
                                      booking?.lab,
                                      "booking_id"
                                    )
                                  }
                                >
                                  Receive
                                </Button>
                              ) : (
                                "NA"
                              )}
                              {
                              booking?.bill_id === null &&
                              booking?.is_sync === true ? (
                                <Button
                                  disabled={
                                    booking?.is_sync !== true &&
                                    booking?.bill_id !== null
                                  }
                                  variant="contained"
                                  color="inherit"
                                  onClick={() =>
                                    handleSyncNew(
                                      booking?.pk ? booking?.pk : booking?.id,
                                      booking,
                                      booking?.pk ? booking?.pk : booking?.id,
                                      booking?.lab,
                                      "booking_id"
                                    )
                                  }
                                >
                                  Receive Again
                                </Button>
                              ) : (
                                ""
                              )}
                            </StyledTableCell>
                          ) && (
                            <StyledTableCell align="center">
                              {(booking?.bill_id === null &&
                                booking?.pickup_status === "confirmed" &&
                                booking?.is_sync === false) ||
                              booking?.org_type === "BML" ? (
                                <Button
                                  disabled={booking?.is_sync}
                                  variant="contained"
                                  color="inherit"
                                  onLoad={SyncLoading}
                                  onClick={() =>
                                    handleSyncNew(
                                      booking?.pk ? booking?.pk : booking?.id,
                                      booking,
                                      booking?.pk ? booking?.pk : booking?.id,
                                      booking?.lab,
                                      "booking_id"
                                    )
                                  }
                                >
                                  Receive
                                </Button>
                              ) : (
                                "NA"
                              )}
                              {userDetails?.profile?.executive_type ===
                                "Admin" &&
                              booking?.bill_id === null &&
                              booking?.is_sync === true ? (
                                <Button
                                  disabled={
                                    booking?.is_sync !== true &&
                                    booking?.bill_id !== null
                                  }
                                  variant="contained"
                                  color="inherit"
                                  onClick={() =>
                                    handleSyncNew(
                                      booking?.pk ? booking?.pk : booking?.id,
                                      booking,
                                      booking?.pk ? booking?.pk : booking?.id,
                                      booking?.lab,
                                      "booking_id"
                                    )
                                  }
                                >
                                  Receive Again
                                </Button>
                              ) : (
                                ""
                              )}
                            </StyledTableCell>
                          )}
                      <StyledTableCell align="center">
                        {booking?.booking_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.center?.name
                          ? booking?.center?.name
                          : "HOME DX"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.discounted_price?.final_total_price -
                          booking?.giftcard_discounted_amount -
                          booking?.redcash_discounted_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">{`${
                        booking.designation || ""
                      } ${booking?.customer_name}`}</StyledTableCell>
                      <StyledTableCell align="center">
                        {(booking?.agent && booking?.agent.name) || "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_age}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_gender}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_email}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.source_type}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.collection_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.collection_slot &&
                          moment(
                            `${booking?.collection_slot?.slot?.split("-")[0]}`,
                            ["HH.mm.ss"]
                          ).format("hh:mm A")}
                        {" - "}
                        {booking?.collection_slot &&
                          moment(
                            `${booking?.collection_slot?.slot?.split("-")[1]}`,
                            ["HH.mm.ss"]
                          ).format("hh:mm A")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_areapincode?.area}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.numbers?.customer_phonenumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.booking_status}`}
                          style={{
                            background:
                              booking?.booking_status === "pending"
                                ? "#ffee58"
                                : booking?.booking_status === "confirmed"
                                ? "#00e676"
                                : booking?.booking_status === "cancelled"
                                ? "#ff3d00"
                                : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.report_status}`}
                          style={{
                            background:
                              booking?.report_status === "pending"
                                ? "#ffee58"
                                : booking?.report_status === "consolidate"
                                ? "#00e676"
                                : booking?.report_status === "partial"
                                ? "#d500f9"
                                : "#ff3d00",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.verification_status}`}
                          style={{
                            background:
                              booking?.verification_status === "pending"
                                ? "#ffee58"
                                : booking?.verification_status === "confirmed"
                                ? "#00e676"
                                : booking?.verification_status === "cancelled"
                                ? "#ff3d00"
                                : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.se_call_count}`}
                          color="primary"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.pickup_status}`}
                          style={{
                            background:
                              booking?.pickup_status === "pending"
                                ? "#ffee58"
                                : booking?.pickup_status === "confirmed"
                                ? "#00e676"
                                : booking?.pickup_status === "cancelled"
                                ? "#ff3d00"
                                : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.pickup_receive_amount}
                      </StyledTableCell>
                      {booking?.payment_mode === "online" &&
                      booking?.pickup_receive_amount !== null ? (
                        <StyledTableCell align="center">
                          {booking?.plink_id}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center"></StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        {booking?.payment_mode}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.pickup_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.pickup_time}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {new Date(booking?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.receivable_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <GamesIcon
                          onClick={() => handleClickCall(booking?.pk)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <LocalAtmIcon
                          onClick={() => handlePaymentInfo(booking?.pk)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip
                          title="Regenrate Booking Subscription"
                          onClick={() => handleRegeneratePayment(booking?.pk)}
                          style={{
                            marginLeft: "1rem",
                            height: "32px",
                            width: "40px",
                            color: "black",
                          }}
                        >
                          <RefreshIcon />
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip
                          title="Payment Settlement"
                          onClick={() => handlePaymentSettlement(booking)}
                          style={{
                            marginLeft: "1rem",
                            height: "45px",
                            width: "45px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#333",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "50%",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            cursor: "pointer",
                            transition:
                              "background-color 0.3s ease, transform 0.3s ease",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#e0e0e0";
                            e.currentTarget.style.transform = "scale(1.05)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "#f5f5f5";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          <PaymentsIcon style={{ color: "#333" }} />
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip
                          title="Do Not Send Report"
                          onClick={() => handleDoNotSendReport(booking)}
                          style={{
                            marginLeft: "1rem",
                            height: "45px",
                            width: "45px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#333",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "50%",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            cursor: "pointer",
                            transition:
                              "background-color 0.3s ease, transform 0.3s ease",
                          }}
                        >
                          <DoNotDisturbIcon  style={{ color: "#333" }} 
                           onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#e0e0e0";
                            e.currentTarget.style.transform = "scale(1.05)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "#f5f5f5";
                            e.currentTarget.style.transform = "scale(1)";
                          }}/>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip
                          title="Booking & Pickup Status Confirm To Cencel"
                          onClick={() => handleCancelBooking(booking?.pk)}
                          style={{
                            marginLeft: "1rem",
                            height: "32px",
                            width: "40px",
                            color: "black",
                          }}
                        >
                          <CloseIcon />
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip
                          title="Pickup status Confirm To Pending"
                          onClick={() => handlePendingBooking(booking?.pk)}
                          style={{
                            marginLeft: "1rem",
                            height: "32px",
                            width: "40px",
                            color: "black",
                          }}
                        >
                          <PendingIcon />
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip
                          title="Pickup status Pending/Hold To Confirm"
                          onClick={() => handlePickUpStatusBooking(booking?.pk)}
                          style={{
                            marginLeft: "1rem",
                            height: "32px",
                            width: "40px",
                            color: "black",
                          }}
                        >
                          <CheckRoundedIcon />
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow style={{ textAlign: "center" }}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={4}
                  count={bookingList?.count || 0}
                  rowsPerPage={bookingList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {bookingList?.results && bookingList?.results?.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
      <BookingCommentsModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}
        leadId={leadId}
      />
      <BookingCallCount
        openModal={openCall}
        setopenModal={setOpenCall}
        bookingId={bookingIdCall}
      />
      <AdditonalFamilyModal
        additionalMemebr={additionalMemebr}
        additionalModal={additionalModal}
        setAdditonalModal={setAdditonalModal}
        loading={loading}
      />
      <LocationVerificationModal
        openModal={openLocation}
        setopenModal={setOpenLocation}
        bookingId={bookingIdLocation}
        leadId={leadIdLocation}
        setCustomerLatitude={setCustomerLatitude}
        setCustomerLongitude={setCustomerLongitude}
        customerLatitude={customerLatitude}
        customerLongitude={customerLongitude}
        setCustomerLatitude1={setCustomerLatitude1}
        setCustomerLongitude1={setCustomerLongitude1}
        customerLatitude1={customerLatitude1}
        customerLongitude1={customerLongitude1}
        cityId={cityId}
        addressLine2={addressLine2}
        favoritePlace={favoritePlace}
        customer_address={customer_address}
        customer_landmark1={customer_landmark1}
        addressId={"none"}
        heading={heading}
        setVerifyKey={setVerifyKey}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userDetails: state.loginReducer.userDetails,
});

export default connect(mapStateToProps, {
  updateBookingZone,
  postRegeneratePayment,
  cancelToConfirm,
  confirmToPending,
  updatePickUpStatus,
  postStopSendingReport,
  getManuallySettlePayments,
})(BookingsTable);
