import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TextField, Grid, Select, MenuItem } from "@material-ui/core";
import Button from "@mui/material/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import {
  updateDialer,
  getLeadSource,
  getDialer,
} from "../../actions/TechSupportActions";
import { useHistory } from "react-router-dom";
import Loader from "../loader";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  contentTable: {
    width: "100%",
    padding: "10px 0px 10px 0px",
  },
}));
type ModalProps = {
  updateDialer: any;
  dialer: any;
  loading: any;
  getLeadSource: any;
  lead_source: any;
  language: any;
  getDialer: any;
  editDialerDetails: any;
  setOpenDialerModal: any;
  openDialerModal: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  loading,
  dialer,
  getLeadSource,
  lead_source,
  language,
  getDialer,
  updateDialer,
  editDialerDetails,
  setOpenDialerModal,
  openDialerModal,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [didNumber, setDidNumber] = useState<String>(
    editDialerDetails && editDialerDetails.data && editDialerDetails.data.did
  );
  const [campaignName, setCampaignsName] = useState<String>(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.campaign_name
  );
  const [campignType, setCampignType] = useState<any>(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.campaign_type
  );
  const [gclId, setGclId] = useState(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.gcl_id
  );
  const [purpose, setPurpose] = useState<String>(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.purpose
  );
  const [provider, setProvider] = useState<String>(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.provider
  );
  const [skills, setSkills] = useState<String>(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.skills
  );
  const [marketingNumber, setMarketingNumber] = useState<String>(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.marketing_number
  );
  const [url, setUrl] = useState<String>(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.promotional_url
  );
  const [isactive, setIsActive] = useState<boolean>(
    editDialerDetails &&
    editDialerDetails?.data &&
    editDialerDetails?.data?.is_active
  );
  const [leadSource, setLeadSource] = useState<any>();
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });
  const [disLeadSource, setDisLeadSource] = useState<string>("");

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(didNumber && didNumber[0]) < 6 ||
        (didNumber && didNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          didNumber: false,
        }));
      }
    }
  };
  useEffect(() => {
    setDidNumber(editDialerDetails?.did);
    setCampaignsName(editDialerDetails?.campaign_name);
    setCampignType(editDialerDetails?.campaign_type);
    setGclId(editDialerDetails?.gcl_id);
    setPurpose(editDialerDetails?.purpose);
    setProvider(editDialerDetails?.provider);
    setSkills(editDialerDetails?.skills);
    setMarketingNumber(editDialerDetails?.marketing_number);
    setUrl(editDialerDetails?.promotional_url);
    setIsActive(editDialerDetails?.is_active);
    setLeadSource(editDialerDetails?.lead_source);
    setDisLeadSource(editDialerDetails?.lead_source);
  }, [editDialerDetails]);


  const handleClose = () => {
    setOpenDialerModal(false);
    history.push("/dashboard/ts/campaigns");
  };

  const submitHandler = async (data: any) => {
    const body: any = {
      did: didNumber,
      campaign_name: campaignName,
      campaign_type: campignType,
      provider: provider,
      skills: skills,
      marketing_number: marketingNumber,
      purpose: purpose,
      gcl_id: gclId,
      promotional_url: url,
      is_active: isactive,
      lead_source: leadSource,
    };
    await updateDialer(body, editDialerDetails && editDialerDetails.id);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDialerModal}
        onClose={handleClose}
        closeAfterTransition
       
      >       
          <Fade in={openDialerModal}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Edit Dialer
                </h4>
                <CloseIcon onClick={() => handleClose()} />
              </div>
              <div className={classes.contentTable}>
                <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                  <Grid item xs={12} md={3}>
                    <h6>
                      DID<sup>*</sup>{" "}
                    </h6>
                    <TextField
                      className="input"
                      name="did_number"
                      type="number"
                      value={didNumber}
                      defaultValue={editDialerDetails?.did}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      required
                      placeholder="Enter DID Number"
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setDidNumber(e.target.value as String)}
                      onBlur={() => verifyMobile("mobile")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          phoneNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.phoneNumber && "Incorrect DID Number"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Campaign Name <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      fullWidth
                      className="input"
                      name="Campaign Name"
                      type="text"
                      placeholder="Enter Campaign Name"
                      defaultValue={editDialerDetails?.campaign_name}
                      value={campaignName}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) =>
                        setCampaignsName(e.target.value as String)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Campaign Type<sup>*</sup>{" "}
                    </h6>
                    <Select
                      className="input"
                      name="usertype"
                      variant="outlined"
                      value={campignType}
                      defaultValue={editDialerDetails?.campaign_type}
                      style={{
                        width: "100%",
                        margin: "0",
                        height: "40px",
                        padding: "1px",
                      }}
                      onChange={(e) => setCampignType(e.target.value as String)}
                      required
                    >
                      <MenuItem disabled value={"none"}>
                        Campaign Type
                      </MenuItem>
                      <MenuItem value={"online"}>Online</MenuItem>
                      <MenuItem value={"offline"}>Offline</MenuItem>
                      <MenuItem value={"onlinesystem"}>
                        Online System Calling
                      </MenuItem>
                      <MenuItem value={"offlinesystem"}>Offline System Calling</MenuItem>
                      <MenuItem value={"facebook"}>Facebook</MenuItem>
                      <MenuItem value={"google"}>Google</MenuItem>
                      <MenuItem value={"website"}>Website</MenuItem>
                      <MenuItem value={"app"}>App</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={3}>
                  <h6>
                  Provider <sup>*</sup>{" "}
                  </h6>
                  <Select
                    className="input"
                    name="provider"
                    variant="outlined"
                    value={provider}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setProvider(e.target.value as String)}
                  >
                    <MenuItem value={"ELISION_BML"}>ELISION_BML</MenuItem>
                    <MenuItem value={"ELISION_GSM"}>ELISION_GSM</MenuItem>
                    <MenuItem value={"AMEYOCALL"}>AMEYOCALL</MenuItem>
                    <MenuItem value={"TATATELEOFFLINE"}>
                      TATATELE OFFLINE
                    </MenuItem>
                    <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                  </Select>
                </Grid>

                  <Grid item xs={12} md={3}>
                    <h6>Skills</h6>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter Skills"
                      value={skills}
                      defaultValue={editDialerDetails?.skills}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setSkills(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>Marketing Number</h6>
                      <TextField
                      className="input"
                      name="marketing_phonenumber"
                      type="text"
                      placeholder="Enter Marketing Number"
                      value={marketingNumber}
                      variant="outlined"
                      onChange={(e) => setMarketingNumber(e.target.value as String)}
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Purpose <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      className="input"
                      name="employee_id"
                      type="text"
                      placeholder="Enter Purpose"
                      value={purpose}
                      defaultValue={editDialerDetails?.purpose}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setPurpose(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Lead Source <sup>*</sup>{" "}
                    </h6>
                    <Autocomplete
                      id="leadsource"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setLeadSource(obj.name);
                        }
                      }}
                      options={lead_source}
                      inputValue={disLeadSource}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) => option && option.name}
                      onInputChange={(event, newInputValue) => {
                        if(event?.type){
                          setDisLeadSource(newInputValue)
                        }
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getLeadSource(`?code=${newInputValue}`);
                        }, 1000);
                        // if (newInputValue.length === 0) {
                        //   setLeadSource(0);
                        // }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>GCL ID</h6>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter GCL ID"
                      defaultValue={editDialerDetails?.gcl_id}
                      value={gclId}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setGclId(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h6>Promotional URL</h6>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter URL"
                      defaultValue={editDialerDetails?.promotional_url}
                      value={url}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>Is Active</h6>
                    <FormControlLabel
                      control={<Checkbox />}
                      defaultValue={editDialerDetails?.is_active}
                      checked={isactive}
                      label="IsActive"
                      onChange={(e) => setIsActive(!isactive)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={3}
                  style={{
                    margin: "1rem 0",
                    width: "100%",
                    padding: "1rem",
                  }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      disabled={!mobileValidation?.phoneNumber}
                      onClick={submitHandler}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  lead_source: state.TechSupportReducer.lead_source,
  language: state.TechSupportReducer.language,
  dialer: state.TechSupportReducer.dialer,
});

export default connect(mapStateToProps, {
  updateDialer,
  getLeadSource,
  getDialer,
})(CreatePhlebo);
