import React, { useEffect,useState } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./index.sass";
import { resourceLimits } from "worker_threads";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import Stack from "@mui/material/Stack";
import {
  TablePagination,
} from "@material-ui/core";
import {
  getPanelComment,
} from "../../actions/TechSupportActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
}));
type ModalProps = {
  openModal: any;
  setopenModal: any;
  panelComments: any;
  getPanelComment: any;
  loading: Boolean;
  id: any;
};

const CommentsModal: React.FC<ModalProps> = ({
  loading,
  openModal,
  setopenModal,
  panelComments,
  getPanelComment,
  id,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const [comment, setComment] = React.useState<string>("");
  const [maxHeight, setMaxHeight] = React.useState<any>(300);

  const handleClose = () => {
    setopenModal(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        panelComments?.links?.next && panelComments?.links?.next.split("?")[1];
      getPanelComment(id, `?${url}`);
    } else if (newPage < page) {
      let url =
        panelComments?.links?.previous &&
        panelComments?.links?.previous.split("?")[1] || "";
      getPanelComment(id, `?${url}`);
    }
    setPage(newPage as number);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <h6
              style={{
                marginBottom: "10px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
              }}
              id="transition-modal-title"
            >
              Comment History
              {Number(maxHeight) === Number(300) ? (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomOutMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(600)
                        ? setMaxHeight(Number(600))
                        : setMaxHeight(Number(600))
                    }
                  />
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomInMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(300)
                        ? setMaxHeight(Number(300))
                        : setMaxHeight(Number(300))
                    }
                  />
                </Stack>
              )}
            </h6>
            <div
              id="transition-modal-description"
              className="modal-form-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: `${maxHeight}px`,
              }}
            >
              <div
                className="comment-history"
                style={{ height: `${maxHeight}px` }}
              >
                {loading
                  ? "Please Wait"
                  : panelComments
                  ? panelComments?.results &&
                    panelComments?.results?.length > 0 &&
                    panelComments?.results?.map((comment: any) => {
                      return (
                        <p>
                          {comment?.created_at.substring(0, 10)} :{" "}
                          {comment?.created_at.substring(11, 19)} :{" "}
                          {comment?.by_user} : {comment?.comment}:{" "}
                        </p>
                      );
                    })
                  : ""}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={panelComments?.count || 0}
                    rowsPerPage={panelComments?.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  panelComments: state.TechSupportReducer.panelComments,
});

export default connect(mapStateToProps, {
  getPanelComment,
})(CommentsModal);
