import { Grid, MenuItem, Paper, Select, TextField, Chip, InputLabel, FormControl } from "@material-ui/core";
import {
    createStyles, makeStyles,
    Theme
} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import { height } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { createCMS, getBatchforCms, getCmsAdvancePayment, getCmsList, getPanelInLab, getUserDetails, updateCms } from "../../actions/TechSupportActions";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        table: {
            minWidth: 700,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
        },
        hoveItem: {
            cursor: "pointer",
        },

        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        root1: {
            // backgroundColor: "#fa4d83",

        }
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: "#924A91",
        color: theme.palette.common.white,
        fontSize: "12px",
      },
      body: {
        fontSize: 12,
      },
    })
  )(TableCell);
  
  const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);

interface Props {
    createCMS: any;
    updateCms: any;
    fetchCmsList: any;
    getCmsList: any;
    getBatchforCms: any;
    fetchbatchCms: any;
    panelInLab: any;
    getPanelInLab: any;
    getUserDetails: any;
    userDetails: any;
    getCmsAdvancePayment: any;
    fetchCmsAdvPayment: any;
}
const StopsPlace: React.FC<Props> = ({
    createCMS,
    updateCms,
    fetchCmsList,
    getCmsList,
    getBatchforCms,
    fetchbatchCms,
    panelInLab,
    getPanelInLab,
    getUserDetails,
    userDetails,
    getCmsAdvancePayment,
    fetchCmsAdvPayment,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [amount, setAmount] = React.useState<any>();
    const [labName, setLabName] = useState<any>("");
    const [advanceAmount, setAdvanceAmount] = useState<any>();
    const [cmsId, setCmsId] = useState<any>("");
    const [batch, setBtach] = useState<any>([]);
    const [batchDef, setBtachDef] = useState<any>([]);
    const [labAmount, setLabAmount] = useState<any>("");
    const [finAmount, setFinAmount] = useState<any>("");
    const [totalAmount, setTotalAmount] = useState<any>("");
    const [statusValue, setStatusValue] = useState<any>("");
    const [lab, setLab] = useState<any>("");
    const [file64, setFile64] = React.useState<any>([]);
    const [remarks, setRemarks] = useState<any>("");
    const [showTables, setShowTables] = useState(false);
    const [fileUpload, setFileUpload] = React.useState<any>([]);
    const toggleTables = () => {
        setShowTables(!showTables);
    };
    const StyledTableCell: any = withStyles((theme: Theme) =>
        createStyles({
            head: {
                backgroundColor: "#924A91",
                color: theme.palette.common.white,
                fontSize: "14px",
            },
            body: {
                fontSize: 14,
            },
        })
    )(TableCell);

    const StyledTableRow: any = withStyles((theme: Theme) =>
        createStyles({
            root: {
                "&:nth-of-type(odd)": {
                    backgroundColor: theme.palette.action.hover,
                },
            },
        })
    )(TableRow);
    const toBase64 = (file: any) =>
        new Promise((resolve, reject) => {
            const reader: any = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
            }

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error: any) => reject(error);
        });
    const fileUploadHandler = (e: React.ChangeEvent<{ files: any }>) => {
        setFileUpload(e.target.files);
    };
    useEffect(() => {
        
        const list: any = file64;
        if (fileUpload.length > 0) {

            for (let i = 0; i <= fileUpload.length; i++) {
                toBase64(fileUpload[i]).then((data: any) => {
                    let extention = "." + String(data.split(';')[0].split('/')[1]) || "";
                    let b64 = String(data.split(",")[1])
                    list.push({ "base64": b64, "ext": extention });
                });
            }

        }
        setFile64(list);
    }, [fileUpload]);

    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const handleClientdata = async (e: any) => {
        e.preventDefault();
        const clientDataEdit = {
            lab: lab,
            batches: batch,
            amount: totalAmount,
            advance_payments: advanceAmount,
            amount_entered_by_finance: finAmount,
            status: statusValue,
            approver_remarks: remarks,
            cms_slip: file64,
        };
        await updateCms(clientDataEdit, id);
        history.push(`/dashboard/fn/cms`)
    };

    const [cmsDataList, setCmsDataList] = useState<any>([])
    useEffect(() => {
        const data: any = []
        if (fetchCmsList && fetchCmsList?.results?.length > 0) {
            fetchCmsList?.results?.map((list: any) => {
                data?.push({ ...list });
            })
        }
        setCmsDataList(data);
    }, [fetchCmsList])

    useEffect(() => {
        getCmsList(`${id}`)
    }, [])


    useEffect(() => {
        if (cmsDataList) {
            const cmsDatalist = cmsDataList;
            setTotalAmount(cmsDatalist[0]?.amount || [])
            setAdvanceAmount(cmsDatalist[0]?.advance_payments?.map((val: any) => val?.id) || [])
            setBtach(cmsDatalist[0]?.batches?.map((val: any) => val?.id) || [])
            setLab(cmsDatalist[0]?.lab?.id)
            setLabName(cmsDatalist[0]?.lab?.name)
            setStatusValue(cmsDatalist[0]?.status || [])
            setFinAmount(cmsDatalist[0]?.amount_entered_by_finance)
            setLabAmount(cmsDatalist[0]?.amount_entered_by_lab)
            setRemarks(cmsDatalist[0]?.approver_remarks)
        }
    }, [cmsDataList]);

    return (
        <Grid container spacing={2}>
            <form onSubmit={handleClientdata} style={{ width: "100%" }}>
                <Paper style={{ margin: "70px auto", width: "100%", marginTop: 60 }} className={classes.root1}>
                    <h2 style={{ fontWeight: "bold", paddingTop: "20px", paddingBottom: "10px", textAlign: "center" }}>Update Cms Details</h2>
                    <Grid container spacing={2} style={{ marginLeft: 1 }}>
                        <Grid item xs={12} sm={2} >
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        setLab(obj?.lab_credentials?.id);
                                    }
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setLabName(newInputValue)
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        // getPanelInLab(`?name=${newInputValue}`);
                                    }, 1000);
                                }}
                                options={panelInLab?.results || []}
                                blurOnSelect
                                aria-required
                                inputValue={labName}
                                getOptionLabel={(option: any) => `${option?.lab_credentials?.name}`}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="input"
                                        label="Lab"
                                        placeholder="Select Lab"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2} >
                            <TextField
                                label="Total Amount"
                                variant="outlined"
                                className="input"
                                placeholder="Enter Amount"
                                fullWidth
                                disabled
                                type="number"
                                value={totalAmount}
                                onChange={(e) => setTotalAmount(parseFloat(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} >
                            <TextField
                                label="Deposite Amount(CMS)"
                                variant="outlined"
                                className="input"
                                placeholder="Enter Amount"
                                fullWidth
                                type="number"
                                disabled={userDetails?.profile?.executive_type !== "FINANCE"}
                                value={labAmount}
                                onChange={(e) => setLabAmount(parseFloat(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl style={{ width: "100%" }} className="input">
                                <InputLabel>Upload Reciept</InputLabel>
                                <input
                                    className="input"
                                    style={{
                                        width: "100%",
                                        margin: "0",
                                        padding: "5px 5px",
                                        border: "1px solid rgba(0, 0, 0, 0.23)",
                                        borderRadius: "5px",
                                        height: "40px",
                                    }}
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={fileUploadHandler}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} >
                            <TextField
                                label="Finance Approval Amount"
                                variant="outlined"
                                className="input"
                                placeholder="Enter Amount"
                                fullWidth
                                type="number"
                                value={finAmount}
                                onChange={(e) => setFinAmount(parseFloat(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} >
                            <FormControl style={{ width: "100%" }} className="input">
                                <InputLabel>Select Status</InputLabel>
                                <Select
                                    label="Library QC Status"
                                    variant="outlined"
                                    className="input"
                                    fullWidth
                                    value={statusValue || "none"}
                                    style={{ width: "100%", height: "40px" }}
                                    onChange={(e) => setStatusValue(e.target.value as String)}
                                >
                                    <MenuItem disabled value={"none"}>Select Status</MenuItem>
                                    <MenuItem value={"pending"}>Pending</MenuItem>
                                    <MenuItem value={"approved"}>Approved</MenuItem>
                                    <MenuItem value={"rejected"}>Rejected</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <textarea
                                className="input"
                                name="remarks"
                                placeholder="Enter Remakrs"
                                value={remarks}
                                required={statusValue === "rejected"}
                                style={{ width: "100%", margin: "0", height: "40px" }}
                                onChange={(e: any) => setRemarks(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={12} style={{ padding: "20px", textAlign: "center" }}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            style={{ width: 300 }}

                        >
                            Update
                        </Button>
                    </Grid>
                </Paper>
            </form>
            <div style={{ width: "100%", marginTop: "-40px" }}>
                <button onClick={toggleTables}>
                    {showTables ? "Hide" : "Show"} Details Summary
                </button>
                {showTables && (
                    <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                        <Grid component={Paper} item xs={12} sm={6} style={{ padding: "5px", marginTop: "20px", width: "50%" }}>
                            <h6>Total Phelbo cash</h6>
                            <div style={{ width: "100%" }} className="data-table">
                                <TableContainer style={{ height: "45vh" }}>
                                    <Table stickyHeader aria-label="simple table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell style={{ backgroundColor: "#1d84a1", color: "#fff" }} align="center">S No.</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#1d84a1", color: "#fff" }} align="center">Phlebo Name</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#1d84a1", color: "#fff" }} align="center">Amount Recived By Lab</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cmsDataList &&
                                                cmsDataList?.flatMap((list: any) => {
                                                    return list?.batches?.map(
                                                        (data: any, index: number) => {
                                                            return (
                                                                <StyledTableRow key={index}>
                                                                    <StyledTableCell align="center">
                                                                        {index + 1}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">
                                                                        {data?.phlebo?.user?.fullname}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">
                                                                        {data?.amount_received_by_lab}
                                                                    </StyledTableCell>

                                                                </StyledTableRow>
                                                            );
                                                        }
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <hr />
                        </Grid>
                        <Grid component={Paper} item xs={12} sm={6} style={{ padding: "5px", marginTop: "20px", width: "50%" }}>
                            <h6>Total Center cash</h6>
                            <div style={{ width: "100%" }} className="data-table">
                                <TableContainer style={{ height: "45vh" }}>
                                    <Table stickyHeader aria-label="simple table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell style={{ backgroundColor: "#1d84a1", color: "#fff" }} align="center">S No.</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#1d84a1", color: "#fff" }} align="center">Center Name</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#1d84a1", color: "#fff" }} align="center">Amount</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cmsDataList &&
                                                cmsDataList?.flatMap((list: any) => {
                                                    return list?.advance_payments?.map(
                                                        (data: any, index: number) => {
                                                            return (
                                                                <StyledTableRow key={index}>
                                                                    <StyledTableCell align="center">
                                                                        {index + 1}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">
                                                                        {data?.center__name}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">
                                                                        {data?.amount}
                                                                    </StyledTableCell>

                                                                </StyledTableRow>
                                                            );
                                                        }
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                    </div>
                )}
            </div>
        </Grid>
    );
};
const mapStateToProps = (state: any) => ({
    fetchCmsList: state.TechSupportReducer.fetchCmsList,
    fetchbatchCms: state.TechSupportReducer.fetchbatchCms,
    panelInLab: state.TechSupportReducer.panelInLab,
    userDetails: state.TechSupportReducer.userDetails,
    fetchCmsAdvPayment: state.TechSupportReducer.fetchCmsAdvPayment

});
export default connect(mapStateToProps, {
    updateCms,
    createCMS,
    getCmsList,
    getBatchforCms,
    getPanelInLab,
    getUserDetails,
    getCmsAdvancePayment,
})(StopsPlace);
