import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Fade, Backdrop } from "@mui/material";
import { getSampleJourneyComments } from "../../actions/TechSupportActions";
import "./index.sass";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    zIndex: 1,
  },
}));

type ModalProps = {
  open: boolean;
  setOpen: Function;
  sampleId: number;
  getSampleJourneyComments: Function;
  sampleJourneyComments: any;
  loading: boolean;
};

const CommentsModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  sampleId,
  getSampleJourneyComments,
  sampleJourneyComments,
  loading,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
   
    if (sampleId !== 0) {
      getSampleJourneyComments(sampleId);
    }
   
  }, [sampleId]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h6
              style={{
                marginBottom: "10px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
              }}
              id="transition-modal-title"
            >
              Sample Journey
            </h6>
            <div
              id="transition-modal-description"
              className="modal-form-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className="comment-history">
                {loading
                  ? "Please Wait"
                  : sampleJourneyComments &&
                    sampleJourneyComments?.results &&
                    sampleJourneyComments?.results.length > 0
                  ? sampleJourneyComments.results.map((comment: any) => (
                      <p>
                        {comment.created_at.substring(0, 10)} :{" "}
                        {comment.created_at.substring(11, 19)} : Given To:{" "}
                        {comment.given_to} Remarks : {comment.remarks}:{" "}
                      </p>
                    ))
                  : ""}
              </div>
              {/* ) : null} */}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sampleJourneyComments: state.TechSupportReducer.sampleJourneyComments,
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  getSampleJourneyComments,
})(CommentsModal);
