export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_USER: 'login/set_user',
  LOGOUT: 'login/logout',
  SET_PROFILE: "login/set_profile",
  RESET_PASSWORD: "login/reset_password",
  SEND_OTP: "login/send_otp",
  SET_LOADING: 'login/set_loading',
  PANELUSER_LOGOUT:'login/paneluser_logout',
  GET_FIREBASE_NOTIFICATION:'login/get_leads',
  GET_USER_DETAILS:"login/get_user_details",
  GET_USER:"login/get_user_data",
  GET_USER_PROFILE: "login/get_user_profile",
  WHATSAPP_DATA_FROM_PUSHER: "login/whatsapp_data_from_pusher",
  LOCATION_DATA_FROM_PUSHER: "login/location_data_from_pusher",
  GET_AGENTS_NOTES_DATA : "login/get_agents_notes_data",
  CHANGE_AGENTS_NOTES_DATA : "login/change_agents_notes_data",
  CRON_LEAD_CHAT_UPDATE : "login/cron_lead_chat_update",
  SEARCH_CALL: "login/serach",
  GET_UHIDS_LIST: "login/get_uhids_list",
  MERGE_UNIQUE_CUSTOMER: "login/merge_unique_customer",
  GET_CALL_RECORDINGS_LIST: "login/get_call_recordings_list",
  GET_REPORT_STATUS_LIVE: "login/get_report_status_live",
};

interface SetUser {
  type: typeof ACTIONS.SET_USER
  payload: Array<string | number>
}
interface WhatsappDataFromPusher {
  type: typeof ACTIONS.WHATSAPP_DATA_FROM_PUSHER
  payload: Array<any>
}
interface GetUserDetails {
    type: typeof ACTIONS.GET_USER_DETAILS
    payload: Array<any>
}
interface GetUserProfile {
  type: typeof ACTIONS.GET_USER_PROFILE
  payload: Array<string | number>
}
interface GetUserDeta {
    type: typeof ACTIONS.GET_USER
    payload: Array<any>
}
interface Logout {
  type: typeof ACTIONS.LOGOUT
  payload: any
}
interface PanelUserLogout {
  type: typeof ACTIONS.PANELUSER_LOGOUT
  payload: any
}
interface SetProfile {
  type: typeof ACTIONS.SET_PROFILE;
  payload: object;
}
interface ResetPassword {
  type: typeof ACTIONS.RESET_PASSWORD;
  payload: object;
}
interface FireBaseToken {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: object;
}
interface SendOTP {
  type: typeof ACTIONS.SEND_OTP;
  payload: object;
}

interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING
  payload: boolean
}
interface LocationDataFromPusher {
  type: typeof ACTIONS.LOCATION_DATA_FROM_PUSHER
  payload: Array<any>
}
interface GetAgentsNotesData {
  type: typeof ACTIONS.GET_AGENTS_NOTES_DATA
  payload: boolean
}
interface ChangeAgentsNotesData {
  type: typeof ACTIONS.CHANGE_AGENTS_NOTES_DATA
  payload: boolean
}

interface CronLeadChatUpdate {
  type: typeof ACTIONS.CRON_LEAD_CHAT_UPDATE
  payload: boolean
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}

interface GetUHIDsList {
  type: typeof ACTIONS.GET_UHIDS_LIST;
  payload: Array<any>;
}
interface PostMergeUniqueCustomer {
  type: typeof ACTIONS.MERGE_UNIQUE_CUSTOMER;
  payload: Array<any>;
}
interface GetCallRecordingsWithLead {
  type: typeof ACTIONS.GET_CALL_RECORDINGS_LIST;
  payload: Array<any>;
}
interface GetReportStatusLive {
  type: typeof ACTIONS.GET_REPORT_STATUS_LIVE;
  payload: Array<any>;
}

export type LoginActionTypes = SetUser | Logout | SetProfile | SetLoadng | ResetPassword | PanelUserLogout | FireBaseToken | GetUserDetails | GetUserDeta  | GetUserProfile | WhatsappDataFromPusher | LocationDataFromPusher | GetAgentsNotesData | ChangeAgentsNotesData | CronLeadChatUpdate | SearchCall | GetUHIDsList | PostMergeUniqueCustomer | GetCallRecordingsWithLead | GetReportStatusLive