import React, { useState } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid, MenuItem } from "@mui/material";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect } from "react";
import {
  createLab,
  getLabCredential,
  updateLab,
  getCities,
  getRiderList,
  getLabLogs,
  resetLogs,
  getBankName,
} from "../../actions/TechSupportActions";
import Autocomplete from "@mui/material/Autocomplete";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { FormControlLabel, Checkbox } from "@material-ui/core";


  
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      table: {
        minWidth: 700,
      },
      root: {
        flexGrow: 0,
      },
      toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
       
        ...theme.mixins.toolbar,
      },
      content: {
        width: "80%",
        flexGrow: 18,
      },
      tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
      },
    })
  );
  


interface Props {
  createLab: any;
  loading: boolean;
  getLabCredential: any;
  labCredential: any;
  updateLab: any;
  getCities: any;
  cities: any;
  getRiderList: any;
  riderList: any;
  getLabLogs: any;
  labLogs: any;
  resetLogs: any;
  bankName: any;
  getBankName: any;
}
const CreateLabMaster: React.FC<Props> = ({
  createLab,
  loading,
  getLabCredential,
  labCredential,
  updateLab,
  getCities,
  cities,
  getRiderList,
  riderList,
  getLabLogs,
  labLogs,
  resetLogs,
  bankName,
  getBankName,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const history = useHistory();
  const [name, setName] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [latitude, setLatitude] = useState<any>("");
  const [longitude, setLongitude] = useState<any>("");
  const [labId, setLabId] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [number, setNumber] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [associatedCities, setAssociatedCities] = useState<any>([]);
  const [associatedCitiesDef, setAssociatedCitiesDef] = useState<any>([]);
  const [cityName, setCityName] = useState<any>("");
  const [riders, setRiders] = useState<any>([]);
  const [ridersDef, setRidersDef] = useState<any>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [headerImg, setHeaderImg] = useState<any>("");
  const [footerTxt, setFooterTxt] = useState<any>("");
  const [openLogs, setOpenLogs] = useState<any>(false);
  const [bank, setBank] = useState("");
  const [bankInput, setBankInput] = useState("");
  const [bankNumber, setBankNumber] = useState<any>("");
  const [bankIfsc, setBankIfsc] = useState<any>("");
  const [bankBranch, setBankBranch] = useState<any>("");
  const [bankAddress, setBankAddress] = useState<any>("");
  const [page, setPage] = useState(0);
  const [labCode, setLabCode] = useState<any>("");
  const [end_date, setEnd_date] = useState<any>("");
  const [ownership, setOwnership] = useState<any>("");
  const { id } = useParams<{ id: string }>();
  const [isWebsite, setIsWebsite] = useState<boolean>(true);

  const submitForm = async (e: any) => {
    e.preventDefault();

    const data: any = {
      name: name,
      lab_address: address,
      latitude: latitude,
      longitude: longitude,
      labid: labId,
      email: email,
      city: city,
      riders: riders,
      contact_number: number,
      associated_cities: associatedCities,
      is_active: isActive,
      website_display: isWebsite,
      rcl_bank_name: bankInput,
      rcl_bank_number: bankNumber,
      rcl_bank_ifsc: bankIfsc,
      rcl_bank_branch: bankBranch,
      rcl_bank_address: bankAddress,
      code: labCode,
      close_date: end_date,
      ownership: ownership,
      header_file: headerImg,
      footer_text: footerTxt,
    };
    if (!footerTxt) {
      delete data.footer_text;
    }
    if (!headerImg) {
      delete data.header_file;
    }
    if (!city) {
      delete data.city;
    }
    if (!address) {
      delete data.lab_address;
    }
    if (!end_date) {
      delete data.close_date;
    }
    if (!ownership) {
      delete data.ownership;
    }
    if (!labId) {
      delete data.labid;
    }
    if (id !== "add") {
      await updateLab(data, id);
    } else {
      await createLab(data);
    }
    getLabCredential();
    history.push("/dashboard/ts/create_lab_master");
  };

  useEffect(() => {
    getCities();
    getRiderList(`?usergroup=Rider`);
    getBankName();
  }, []);

  useEffect(() => {
    if (id !== "add") {
      getLabCredential(`${id}/`);
    }
  }, [id]);

  console.log(id , 'seeID');
  

  useEffect(() => {
    if (labCredential) {
      let list = labCredential;
      setName(list?.name);
      setEmail(list?.email);
      setNumber(list?.contact_number || "");
      setAddress(list?.lab_address || "");
      setLabId(list?.labid || "");
      setLatitude(list?.latitude || "");
      setLongitude(list?.longitude || "");
      setCity(list?.city?.id);
      setCityName(list?.city?.name || "");
      setAssociatedCitiesDef(list?.associated_cities || []);
      setRidersDef(list?.riders || []);
      setRiders(
        list?.riders?.length > 0 && list?.riders?.map((val: any) => val?.id)
      );
      setAssociatedCities(
        list?.associated_cities?.length > 0 &&
          list?.associated_cities?.map((val: any) => val?.id)
      );
      setIsActive(list?.is_active);
      setIsWebsite(list?.website_display || "");
      
      setBank(list?.bank?.id || "");
      setBankInput(list?.rcl_bank_name || "");
      setBankNumber(list?.rcl_bank_number || "");
      setBankIfsc(list?.rcl_bank_ifsc || "");
      setBankBranch(list?.rcl_bank_branch || "");
      setBankAddress(list?.rcl_bank_address || "");
      // setDataId(list?.id);
      setLabCode(list?.code);
      setOwnership(list?.ownership || "");
      setEnd_date(list?.close_date);
      setFooterTxt(list?.footer_text || "");
      
    }
  }, [labCredential]);
  const fileSelectedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const selectedFile = selectedFiles[0];

      toBase64(selectedFile).then((data: any) => {
        setHeaderImg(data);
      });
    }
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  return (
    <form onSubmit={submitForm} style={{ width: "100%" }}>
      <Paper style={{ margin: "80px auto", width: "90%", marginTop: 70 }}>
        <h4 style={{ textAlign: "center", fontWeight: "bold" }}>LAB MASTER</h4>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Lab Name"
              variant="outlined"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Lab Code"
              variant="outlined"
              required
              value={labCode}
              onChange={(e) => setLabCode(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Lab id"
              variant="outlined"
              required
              value={labId}
              onChange={(e) => setLabId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Lab Address"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Latitude"
              variant="outlined"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Longitude"
              variant="outlined"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              type="email"
              variant="outlined"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Contact Number"
              type="number"
              variant="outlined"
              required
              onWheel={(e: any) => e.target.blur()}
              onInput={(e: any) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              // key={id}
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj?.id;
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option?.name}
              disableClearable
              disableCloseOnSelect
              inputValue={cityName}
              onInputChange={(event, newInputValue) => {
                setCityName(newInputValue);
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(`?q=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              id="city"
              key={city}
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON?.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj?.map((item: any) => item?.id);
                }
                setAssociatedCities(sourceIds);
              }}
              options={cities || []}
              freeSolo
              aria-required
              limitTags={1}
              multiple
              defaultValue={
                associatedCitiesDef !== undefined ||
                associatedCitiesDef !== null
                  ? associatedCitiesDef
                  : []
              }
              getOptionLabel={(option: any) => option?.name}
              getOptionDisabled={(option: any) => {
                return (
                  associatedCities?.length > 0 &&
                  associatedCities?.includes(option?.id)
                );
              }}
              disableClearable
              disableCloseOnSelect={true}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(`?q=${newInputValue}`);
                }, 1000);
                if (newInputValue?.length === 0) {
                  setAssociatedCities([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Associated Cities"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              id="rider"
              key={associatedCities}
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj?.map((item: any) => item?.id);
                }
                setRiders(sourceIds);
              }}
              options={riderList?.results || []}
              freeSolo
              aria-required
              limitTags={1}
              multiple
              defaultValue={ridersDef || []}
              getOptionLabel={(option: any) =>
                option?.user?.username
                  ? option?.user?.username
                  : option?.user?.name === undefined
                  ? option?.user
                  : ""
              }
              getOptionDisabled={(option: any) => {
                return ridersDef?.includes(option?.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                setRidersDef(newInputValue);
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getRiderList(
                    `?usergroup=Rider&user__username__icontains=${newInputValue}`
                  );
                }, 1000);
                if (newInputValue?.length === 0) {
                  setRiders([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Riders"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              id="bank_name"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setBank(obj?.id);
                }
              }}
              freeSolo
              blurOnSelect
              options={bankName?.results || []}
              disableCloseOnSelect
              getOptionLabel={(option: any) => option?.name}
              onInputChange={(event, newInputValue) => {
                setBankInput(newInputValue);
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getBankName(`?name=${newInputValue}`);
                }, 1000);
                if (newInputValue?.length === 0) {
                  setBank("");
                }
              }}
              inputValue={bankInput}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Bank"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              type="number"
              fullWidth
              size="small"
              label="Bank Number"
              variant="outlined"
              required
              value={bankNumber}
              onChange={(e) => setBankNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Ifsc Code"
              variant="outlined"
              required
              value={bankIfsc}
              onChange={(e) => setBankIfsc(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Bank Branch"
              variant="outlined"
              required
              value={bankBranch}
              onChange={(e) => setBankBranch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Bank Address"
              variant="outlined"
              required
              value={bankAddress}
              onChange={(e) => setBankAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Close Date"
              value={end_date}
              variant="outlined"
              // inputProps={{
              //     max: moment().subtract(1, "days").format("YYYY-MM-DD"),
              // }}
              onChange={(e) => setEnd_date(e.target.value as string)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              aria-required
              variant="outlined"
              label="Ownership"
              value={ownership}
              select
              size="small"
              onChange={(e) => setOwnership(e.target.value)}
            >
              <MenuItem value="medicentre">Medicentre</MenuItem>
              <MenuItem value="COCO">COCO</MenuItem>
              <MenuItem value="HLM">HLM</MenuItem>
              <MenuItem value="O&M">O&M</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Footer text"
              variant="outlined"
              value={footerTxt}
              onChange={(e) => setFooterTxt(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Header image"
              variant="outlined"
              type="file"
              inputProps={{
                accept: "image/png, image/jpg, image/jpeg",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              helperText="note: file dimensions must be W(1055px)*H(110px)"
              onChange={fileSelectedHandler}
            />
            {id !== "add" && labCredential?.header_file ? (
              <a href={labCredential?.header_file} target="_blank">
                click to view uploaded file
              </a>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControlLabel
              control={<Checkbox />}
              label="Is Active"
              labelPlacement="start"
              checked={isActive}
              onChange={(e) => setIsActive(!isActive)}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Is Website Display"
            
              labelPlacement="start"
              checked={isWebsite}
              onChange={(e) => setIsWebsite(!isWebsite)}
            />
          </Grid>
        </Grid>
        <Grid xs={12} style={{ textAlign: "center", padding: "20px" }}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ width: 300 }}
            disabled={loading || String(number)?.length !== 10}
          >
            {id !== "add" ? "Update" : "Create"}
            {loading && <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Paper>
    </form>
  );
};
const mapStateToProps = (state: any) => ({
  labCredential: state.TechSupportReducer.labCredential,
  cities: state.TechSupportReducer.cities,
  riderList: state.TechSupportReducer.riderList,
  labLogs: state.TechSupportReducer.labLogs,
  loading: state.TechSupportReducer.loading,
  bankName: state.TechSupportReducer.bankName,
});
export default connect(mapStateToProps, {
  createLab,
  getLabCredential,
  updateLab,
  getCities,
  getRiderList,
  resetLogs,
  getLabLogs,
  getBankName,
})(CreateLabMaster);
