import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    Slider,
    Switch,
    TableContainer,
    TableFooter,
    TablePagination,
    TextField,
    withStyles,
    DialogContentText,
  } from "@material-ui/core";
  import Checkbox from "@material-ui/core/Checkbox";
  import Box from "@mui/material/Box";
  
  import FormControlLabel from "@material-ui/core/FormControlLabel";
  import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
  import FormGroup from "@material-ui/core/FormGroup";
  import Paper from "@material-ui/core/Paper";
  import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
  import Table from "@material-ui/core/Table";
  import TableBody from "@material-ui/core/TableBody";
  import TableCell from "@material-ui/core/TableCell";
  import TableHead from "@material-ui/core/TableHead";
  import TableRow from "@material-ui/core/TableRow";
  import { Edit, MessageRounded } from "@material-ui/icons";
  import CloudUploadIcon from "@material-ui/icons/CloudUpload";
  import ExpandLessIcon from "@material-ui/icons/ExpandLess";
  import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
  import Autocomplete from "@material-ui/lab/Autocomplete";
  import Button from "@mui/material/Button";
  import moment from "moment";
  import React, { useEffect, useRef, useState } from "react";
  import { connect } from "react-redux";
  import { useHistory } from "react-router-dom";
  import TicketCommentsModal from "../Center-Creation/CenterComments";
  import "./index.sass";
import Loader from "../../components/loader";
import { ccToInv, createNewCenter, getAgentList, getBankName, getCCConsumptionItems, getCenterInfo, getCentreCode, getIdentityProof, getpaymentMoodList, getPinCode, getRateListFilter, getSalesExecutive, getSalesManager, getStateList, getTagProcessingLab, resetCCToInventoryRes, updateCenter, uploadCentres } from "../../actions/TechSupportActions";
  
  const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: "#924A91",
        color: theme.palette.common.white,
        fontSize: "14px",
      },
      body: {
        fontSize: 14,
      },
    })
  )(TableCell);
  
  const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 0,
      },
      table: {
        minWidth: 700,
      },
      toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
      },
      content: {
        width: "80%",
        flexGrow: 21,
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      paper: {
        marginTop: "1rem",
        padding: "1rem 0.5rem 0.5rem 0.5rem",
        width: "100%",
      },
      contentHeader: {
        width: "100%",
        padding: "0px 10px 40px 10px",
        minHeight: "64px",
      },
      leftContentHeader: {
        width: "50%",
        float: "left",
      },
      rightContentHeader: {
        width: "50%",
        float: "right",
        textAlign: "right",
        paddingRight: "20px",
      },
      buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      head: {
        textAlign: "center",
        fontWeight: "normal",
        marginBottom: "2rem",
      },
      slotContainer: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      },
      slot: {
        width: "150px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        margin: "0.5rem",
      },
      hoveItem: {
        cursor: "pointer",
      },
      selectedSlot: {
        width: "150px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
        cursor: "pointer",
        margin: "0.5rem",
      },
      chips: {
        display: "flex",
      },
      chip: {
        margin: 2,
        background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
        maxWidth: "130px",
      },
      input: {
        padding: "1px",
      },
      tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
      },
      dialog: {
        zIndex: 1,
      },
      centerIt: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      spaceAround: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },
      spaceEvenly: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      },
      spaceBetween: {
        display: "flex",
        justifyContent: " space-between",
        alignItems: "center",
      },
      flexStart: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
    })
  );
  
  const PrettoSlider = withStyles({
    root: {
      color: "rgb(146, 74, 145)",
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "violet",
      border: "2px solid purple",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);
  
  interface Props {
    uploadCentres: any;
    uploadCentresData: any;
    getCentreCode: any;
    centreCode: any;
    getCenterInfo: any;
    getPinCode: any;
    centerInfo: any;
    pin_code: any;
    createNewCenter: any;
    getAgentList: any;
    agentBYUserGroup: any;
    getSalesExecutive: any;
    getSalesManager: any;
    salesManager: any;
    salesExecutive: any;
    updateCenter: any;
    getIdentityProof: any;
    identityProofData: any;
    getStateList: any;
    stateList: any;
    getpaymentMoodList: any;
    paymentModeList: any;
    getRateListFilter: any;
    rateListFilter: any;
    getTagProcessingLab: any;
    tagProccessingLab: any;
    getBankName: any;
    bankName: any;
    loading: Boolean;
    userDetails: any;
    ccToInv: any;
    resetCCToInventoryRes: any;
    cc_to_inv: any;
    // get_cc_consumption: any;
    // getCCConsumptionItems: any;
  }
  const CcDsa: React.FC<Props> = ({
    uploadCentres,
    uploadCentresData,
    getCentreCode,
    centreCode,
    getCenterInfo,
    getPinCode,
    centerInfo,
    pin_code,
    createNewCenter,
    getAgentList,
    agentBYUserGroup,
    getSalesExecutive,
    getSalesManager,
    salesManager,
    salesExecutive,
    identityProofData,
    updateCenter,
    getIdentityProof,
    getStateList,
    getpaymentMoodList,
    paymentModeList,
    getRateListFilter,
    rateListFilter,
    getTagProcessingLab,
    tagProccessingLab,
    stateList,
    getBankName,
    bankName,
    loading,
    userDetails,
    ccToInv,
    resetCCToInventoryRes,
    cc_to_inv,
    // get_cc_consumption,
    // getCCConsumptionItems,
  }) => {
    const classes = useStyles();
    const history = useHistory();
    const timer = useRef<any>(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [center, setCentre] = useState<any>([]);
    const [centerId, setCenterId] = useState<any>();
    const [centerTypeFilter, setCentreTypeFilter] = useState<any>("");
    const [warningForDOB, setWarningForDOB] = useState<any>("");
    const [isActive, setIsActive] = useState<any>(true);
    const [centerName, setCentreName] = useState<any>("");
    const [centerType, setCentreType] = useState<any>("collection_center");
    // const [patientType, setPaitentType] = useState<any>("")
    const [identityProof, setIdentityProof] = useState<any>(1);
    const [centerCode, setCentreCode] = useState<any>(
      centreCode?.centre_code || ""
    );
    const [expiryDate, setExpiryDate] = useState<any>(
      moment().add(5, "years").format("YYYY-MM-DD")
    );
    const [contractDate, setContractDate] = useState<any>(
      moment().format("YYYY-MM-DD")
    );
    const [mobileNumber, setMobileNumber] = useState<any>("");
  
    const [altNumber, setaltNumber] = useState<any>("");
    const [sales_Manager, setSales_Manager] = useState<any>("");
    const [sales_ManagerDef, setSales_ManagerDef] = useState<any>("");
    const [sales_Executive, setSales_Executive] = useState<any>([]);
    const [sales_ExecutiveDef, setSales_ExecutiveDef] = useState<any>([]);
    const [ownerName, setOwnerName] = useState<any>("");
    const [images, setImages] = useState<any>([]);
    const [lastName, setLastName] = useState("");
    const [selectedAgeType, setSelectedAgeType] = React.useState("age");
    const [centerAddress, setCentreAddress] = React.useState("");
    const [cityName, setCityName] = React.useState<String>("");
    const [cityId, setCityId] = React.useState<any>();
    const [pincode, setPinCode] = React.useState<Number>(0);
    const [nationality, setNationality] = React.useState("Indian");
    const [centerHeadalterNateNumber, setCentreHeadAlternateNumber] =
      useState<any>();
    const [centerHeadalContactNumber, setCentreHeadContactNumber] =
      useState<any>();
    const [state, setState] = useState<any>("");
    const [email, setEmail] = React.useState("");
    const [busyId, setBusyId] = React.useState("");
    const [adharNumber, setAdharNumber] = React.useState<any>("");
    const [genders, setGenders] = React.useState("");
    const [area, setArea] = React.useState<String>("");
    const [securityAmount, setSecurityAmount] = React.useState("");
    const [serviceRateCategory, setServiceRateCategory] = React.useState<any>("");
    const [serviceRateCategoryDef, setServiceRateCategoryDef] =
      React.useState<any>("");
    const [barCodeType, setBarCodeType] = useState<any>("preprainted");
    const [tag_ProccessingLab, setTag_ProccessingLab] = React.useState("");
    const [categoryName, setCategoryName] = useState<any>("none");
    const [paymentMode, setPaymentMode] = React.useState("");
    const [bankAccountHolder, setbankAccountHolder] = React.useState("");
    const [bank, setBank] = React.useState("");
    const [bankInput, setBankInput] = React.useState("");
    const [gstinNumber, setGstinNumber] = React.useState("");
    const [panNumber, setPanNumber] = React.useState("");
    const [ifscCode, setIfscCode] = React.useState("");
    const [accountNumber, setAccountNumber] = React.useState("");
    const [minAmountForNotification, setMinNotication] = React.useState("");
    const [minAmountForBill, setMinAmountForBill] = React.useState<any>(0);
    const [center_longitude, setCentreLongitude] = useState<any>("");
    const [center_latitude, setCentreLatitude] = useState<any>("");
    const [designation, setDesignation] = useState<any>("");
    const [creditLimit, setCreditLimit] = useState<any>("");
    const [invoiceCycle, setInvoiceCycle] = useState<any>("");
    const [reportWhatsappSentTo, setReportWhatsappSentTo] = useState<any>("");
    const [reportEmailSentTo, setReportEmailSentTo] = useState<any>("");
    const [reportSmsSentTo, setReportSmsSentTo] = useState<any>("");
    const [autosettle, setAutosettle] = useState<any>("manual_settlement");
    const [accountActive, setAccountActive] = useState<any>("true");
    const [packageRights, setPackageRights] = useState<any>("redcliffe");
    const [pinCodeId, setPincodeId] = useState<any>("");
    const [pinCodeIdDef, setPincodeIdDef] = useState<any>("");
    const [sampleRegisterTo, setSampleRegisterTo] = useState<any>("itdose");
    const [file, setFile] = React.useState<any>([]);
    const [fileUpload, setFileUpload] = React.useState<any>([]);
    const [disableEdit, setDisableEdit] = useState<boolean>(false);
    const [openModal, setopenModal] = useState<boolean>(false);
    const [centerdata, setCenterdata] = useState<any>("");
    // const[orgtype,setorgType]= useState<any>("");
    const [smsType, setSmsType] = React.useState({
      mail: false,
      sms: false,
      screen: false,
      isbillinglock: true,
      isapproved: false,
      is_booking_payment_sms: false,
      is_booking_sms: false,
      is_report_sms: false,
      is_report_whatsapp: false,
      is_report_email: false,
      is_shown_to_pha: false,
      is_shown_to_sales: false,
      is_auto_verify: false,
      is_price_hide: false,
    });
    const [mobileValidation, setMobileValidation] = useState({
      mobileNumber: true,
      centerHeadalterNateNumber: true,
      centerHeadalContactNumber: true,
      altNumber: true,
    });
    const [centerShow, setCentreShow] = useState<boolean>(false);
    const [centerHeadShow, setCentreHeadShow] = useState<boolean>(false);
    const [centerBankShow, setCentreBankShow] = useState<boolean>(false);
    const [centerControlingShow, setCentreControllingShow] =
      useState<boolean>(false);
    const [assignLabId, setAssignLabId] = useState<any>(5);
    const [editButton, setEditButton] = useState<any>(false);
    const [subCenterType, setSubCentreType] = useState<any>("DSA");
    const DefaultSwitch: any = withStyles({
      switchBase: {
        color: "red",
        "&$checked": {
          color: "green",
        },
        "&$checked + $track": {
          backgroundColor: "green",
        },
      },
      checked: {},
      track: {
        backgroundColor: "red",
      },
    })(Switch);
  
    const verifyMobile = (type: any) => {
      const mobileRegEx = /^[6-9]\d{9}$/;
      if (
        type === "mob" &&
        mobileNumber &&
        !mobileRegEx.test(mobileNumber.toString())
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          mobileNumber: false,
        }));
      }
      if (
        type === "centAlt" &&
        altNumber &&
        !mobileRegEx.test(altNumber.toString())
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          altNumber: false,
        }));
      }
      if (
        type === "contAlt" &&
        centerHeadalterNateNumber &&
        !mobileRegEx.test(centerHeadalterNateNumber.toString())
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          centerHeadalterNateNumber: false,
        }));
      }
      if (
        type === "contMob" &&
        centerHeadalContactNumber &&
        !mobileRegEx.test(centerHeadalContactNumber.toString())
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          centerHeadalContactNumber: false,
        }));
      }
    };
    const CenterTypeData = [
      {
        value: "collection_center",
        text: "Collection Center",
      },
      {
        value: "direct_client",
        text: "Direct Client",
      },
      {
        value: "GE_Home_Collection",
        text: "GE Home Collection",
      },
      {
        value: "GE Lab",
        text: "GE Lab",
      },
      {
        value: "GE CC",
        text: "GE CC",
      },
    ];
  
    const salutationData = [
      {
        value: "Mr",
        text: "Mr",
      },
      {
        value: "Master",
        text: "Master",
      },
      {
        value: "Miss",
        text: "Miss",
      },
      {
        value: "Smt",
        text: "Smt",
      },
      {
        value: "Dr.",
        text: "Dr.",
      },
    ];
  
    const SubCenterTypeData = {
      dc: [
        {
          value: "cep",
          text: "CEP",
        },
        {
          value: "walk_in",
          text: "Walk In",
        },
        {
          value: "qa",
          text: "QA",
        },
        {
          value: "sis",
          text: "SIS",
        },
        {
          value: "coco",
          text: "COCO",
        },
        {
          text: "DC-Credit",
          value: "direct_credit",
        },
        {
          text: "DC-Cash",
          value: "direct_cash",
        },
        {
          text: "HLM-SIS",
          value: "hlm_sis",
        },
        {
          text: "HLM-Credit",
          value: "hlm_credit",
        },
        {
          text: "Credit",
          value: "credit",
        },
        {
          text: "HLM Client Cash",
          value: "hlm_client_cash",
        },
        {
          text: "HLM Client Credit",
          value: "hlm_client_credit",
        },
        {
          text: "Rolling Advance",
          value: "rolling_advance",
        },
      ],
      cc: [
        {
          value: "phlebo",
          text: "Phlebo",
        },
        {
          value: "ccp",
          text: "CCP",
        },
        {
          value: "cc",
          text: "CC",
        },
        {
          text: "Rolling Advance",
          value: "rolling_advance",
        },
      ],
      ge: [
        {
          value: "cash",
          text: "Cash",
        },
        {
          value: "credit",
          text: "Credit",
        },
        {
          text: "Rolling Advance",
          value: "rolling_advance",
        },
      ],
    };
  
    const fileSelectedHandler = (e: React.ChangeEvent<{ files: any }>) => {
      setFile(e.target.files[0]);
    };
    const fileUploadHandler = (e: React.ChangeEvent<{ files: any }>) => {
      setFileUpload(e.target.files[0]);
    };
    useEffect(() => {
      if (centreCode && editButton === false) {
        setCentreCode(centreCode.centre_code);
      }
    }, [centreCode]);
  
    useEffect(() => {
      getCenterInfo("?show_all=true");
      getPinCode();
      getBankName();
      getSalesExecutive();
      getSalesManager();
      getStateList();
      getIdentityProof();
      getpaymentMoodList();
      // getRateListFilter();
      getTagProcessingLab();
      resetCCToInventoryRes();
      // getCCConsumptionItems();
    }, []);
  
    useEffect(() => {
      if (centerType !== "") {
        getCentreCode(centerType, subCenterType);
      }
      if (centerType === "collection_center") {
        setCategoryName("prepaid");
        if (!editButton) {
          smsType.isbillinglock = true;
        }
      }
      if (centerType === "GE_Home_Collection") {
        smsType.is_shown_to_pha = true;
      }
      if (centerType === "direct_client" && !editButton) {
        setAutosettle("auto_settlement");
      }
    }, [centerType]);
  
    const handleSmsControl = (event: any) => {
      setSmsType({ ...smsType, [event.target.name]: event.target.checked });
    };
  
    const handleUploadCentre = async (e: any) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("centre_file", fileUpload);
      await uploadCentres(formData);
      getCenterInfo("?show_all=true");
    };
    const handleSubmitCentre = async (e: any) => {
      e.preventDefault();
      const formData = new FormData();
      if (!editButton) {
        formData.append("name", centerName);
      }
  
      formData.append("display_name", centerName);
      formData.append("center_type", centerType);
  
      if (!editButton) {
        formData.append("center_code", centerCode);
      }
      // formData.append("center_code", centerCode);
      formData.append("contract_start_date", contractDate);
      formData.append("contract_end_date", expiryDate);
      formData.append("mobile", mobileNumber);
      if (altNumber) {
        formData.append("alternative_phone", altNumber);
      }
  
      formData.append("email", email);
      formData.append("identity_proof", identityProof);
      formData.append("identity_proof_file", file);
      if (sales_Executive.length !== 0) {
        formData.append("multiple_users", String(sales_Executive));
      }
      if (sales_Manager !== null) {
        formData.append("sales_manager", sales_Manager);
      }
      formData.append("address", centerAddress);
      if (pinCodeId) {
        formData.append("location", pinCodeId);
        formData.append("city", cityId);
      }
  
      formData.append("security_amount", securityAmount);
      formData.append("is_active", isActive);
      formData.append("barcode_type", barCodeType);
      if (busyId) {
        formData.append("busyID", busyId);
      }
  
      formData.append("type", categoryName);
      formData.append("owner_salutation", designation);
      formData.append("owner_name", ownerName);
  
      if (centerHeadalterNateNumber) {
        formData.append("owner_alt_phone", centerHeadalterNateNumber);
      }
      if (centerHeadalContactNumber) {
        formData.append("owner_phone", centerHeadalContactNumber);
      }
      formData.append("owner_nationality", nationality);
      if (adharNumber) {
        formData.append("aadhar_no", adharNumber);
      }
      if (panNumber) {
        formData.append("pan", panNumber);
      }
  
      if (bankAccountHolder) {
        formData.append("account_holder_name", bankAccountHolder);
      }
      if (ifscCode) {
        formData.append("bank_ifsc", ifscCode);
      }
      formData.append("packages_rights", packageRights);
  
      if (gstinNumber) {
        formData.append("gstin", gstinNumber);
      }
      if (accountNumber) {
        formData.append("bank_account_no", accountNumber);
      }
      if (bank) {
        formData.append("bank", bank);
      }
      formData.append("is_billing_lock", JSON.stringify(smsType.isbillinglock));
      if (minAmountForNotification) {
        formData.append("min_amnt_for_notification", minAmountForNotification);
      }
      formData.append("min_amnt_for_billing_lock", String(minAmountForBill));
      if (serviceRateCategory !== "") {
        formData.append("multiple_ratelist", String(serviceRateCategory));
      }
      if (invoiceCycle) {
        formData.append("invoice_cycle", invoiceCycle);
      }
      if (reportEmailSentTo) {
        formData.append("report_email_sent_to", reportEmailSentTo);
      }
      if (reportSmsSentTo) {
        formData.append("report_sms_sent_to", reportSmsSentTo);
      }
      if (reportWhatsappSentTo) {
        formData.append("report_whatsapp_sent_to", reportWhatsappSentTo);
      }
      formData.append("is_mail", JSON.stringify(smsType.mail));
      formData.append("is_sms", JSON.stringify(smsType.sms));
      formData.append("is_screen", JSON.stringify(smsType.screen));
      if (centerType !== "collection_center") {
        formData.append("patient_type", autosettle);
      }
      formData.append("is_account_active", accountActive);
      // if(orgtype){
      //   formData.append("org_type", orgtype);
      // }
  
      formData.append(
        "is_booking_payment_sms",
        JSON.stringify(smsType.is_booking_payment_sms)
      );
      formData.append("is_booking_sms", JSON.stringify(smsType.is_booking_sms));
  
      formData.append("is_shown_to_pha", JSON.stringify(smsType.is_shown_to_pha));
      formData.append(
        "is_shown_to_sales",
        JSON.stringify(smsType.is_shown_to_sales)
      );
      formData.append("is_auto_verify", JSON.stringify(smsType.is_auto_verify));
      formData.append("is_price_hide", JSON.stringify(smsType.is_price_hide));
      formData.append("paymentmode", paymentMode ? paymentMode : "1");
      formData.append("country", "1");
      if (creditLimit !== "") {
        formData.append("credit_limit", creditLimit);
      }
      if (center_latitude && center_latitude !== "null") {
        formData.append("latitude", center_latitude);
      }
      if (center_longitude && center_longitude !== "null") {
        formData.append("logitude", center_longitude);
      }
      formData.append("sample_register_to", sampleRegisterTo);
      if (subCenterType) {
        formData.append("subtype", subCenterType);
      }
      if (images.length > 0) {
        [...images].map((val: any, index: any) => {
          formData.append(`image[${index}]`, val);
        });
      }
  
      if (editButton) {
        await updateCenter(formData, centerId);
      } else {
        await createNewCenter(formData);
      }
  
      getCenterInfo("?show_all=true");
      setEditButton(false);
      setCentreShow(false);
      setCentreHeadShow(false);
      setCentreBankShow(false);
      setCentreControllingShow(false);
      history.push("/dashboard/ts/centerCreationModalDSA");
    };
  
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      if (newPage > page) {
        let url = centerInfo.links && centerInfo.links.next.split("?")[1];
        getCenterInfo(`?${url}`);
      } else if (newPage < page) {
        let url = centerInfo.links && centerInfo.links.previous.split("?")[1];
        getCenterInfo(`${url == undefined ? "" : "?" + url}`);
      }
      setPage(newPage);
    };
  
    const handleEditCentre = (data: any) => {
      setDisableEdit(true);
      setEditButton(true);
      setPackageRights(data?.packages_rights || "");
      setPincodeId((data?.area?.id as Number) || "");
      setCentreLatitude(data?.latitude || "");
      setCentreLongitude(data?.logitude || "");
      setSampleRegisterTo(data?.sample_register_to || "");
      setPincodeIdDef(data?.area || "");
      setCityId(data?.area?.city || "");
      setArea((data?.area?.area as String) || "");
      setCityName((data?.area?.city_name as String) || "");
      setBarCodeType(data?.barcode_type || "");
      setPaymentMode(data?.paymentmode || "");
      setBankInput(data?.bank?.name || "");
      setBank(data?.bank?.id || "");
      setCategoryName(data?.type || "");
      setIdentityProof(data?.identity_proof || "");
      setCentreShow(true);
      setCentreHeadShow(true);
      setCentreBankShow(true);
      setCentreControllingShow(true);
      setCentreName(data?.display_name || "");
      setCentreType(data?.center_type || "");
      setSubCentreType(data?.subtype || "");
      setCentreCode(data?.center_code || "");
      setIsActive(data?.is_active);
      // setorgType(data?.org_type);
      setContractDate(
        data?.contract_start_date
          ? data?.contract_start_date
          : moment().format("YYYY-MM-DD")
      );
      setExpiryDate(
        data?.contract_end_date
          ? data?.contract_end_date
          : moment().add(5, "years").format("YYYY-MM-DD")
      );
      setMobileNumber(data?.mobile || "");
      setaltNumber(data?.alternative_phone || "");
      setCentreHeadContactNumber(data?.center_head_phone || "");
      setCentreHeadAlternateNumber(data?.owner_alt_phone || "");
      setDesignation(data?.owner_salutation || "");
      setCentreHeadContactNumber(data?.owner_phone || "");
      setEmail(data?.email || "");
      // setPaitentType(data.patient_type)
      setSales_Manager(data?.sales_manager?.id || "");
      setSales_ManagerDef({
        id: data?.sales_manager?.id,
        username: data?.sales_manager?.name,
      });
      // salesManager.results &&
      //   salesManager.results.find((code: any) => {
      //     if (code.uuid === data.user) {
      //       setSales_ManagerDef(code);
      //     }
      //   });
      setOwnerName(data?.owner_name || "");
      setSales_Executive(data?.users || []);
  
      salesExecutive.results &&
        salesExecutive.results.map((code: any) => {
          data?.users?.map((dat: any) => {
            if (code.uuid === dat) {
              sales_ExecutiveDef.push(code);
            }
          });
        });
      setCentreAddress(data?.address || "");
      setSecurityAmount(data?.security_amount || "");
      setServiceRateCategoryDef(data?.ratelist[0] || "");
      setServiceRateCategory([data?.ratelist[0]?.id] || "");
      setBusyId(
        data?.busyID == null || data?.busyID == "null" ? "" : data?.busyID
      );
      setbankAccountHolder(data?.account_holder_name || "");
      setCreditLimit(data?.credit_limit || "");
      setGstinNumber(data?.gstin || "");
      setPanNumber(data?.pan || "");
      setInvoiceCycle(data?.invoice_cycle || "");
      setIfscCode(data?.bank_ifsc || "");
      setAccountNumber(data?.bank_account_no || "");
      setMinNotication(data?.min_amnt_for_notification || "");
      setMinAmountForBill(Number(data?.min_amnt_for_billing_lock) || 0);
      setCenterId(data?.id || "");
      setAdharNumber(data?.aadhar_no || "");
      setReportWhatsappSentTo(data?.report_whatsapp_sent_to || "");
      setReportEmailSentTo(data?.report_email_sent_to || "");
      setReportSmsSentTo(data?.report_sms_sent_to || "");
      setAutosettle(data?.patient_type);
      setAccountActive(data?.is_account_active);
      const smType = {
        mail: data.is_mail,
        sms: data.is_sms,
        screen: data.is_screen,
        isbillinglock: data.is_billing_lock,
        isapproved: data.is_active,
        is_booking_payment_sms: data.is_booking_payment_sms,
        is_booking_sms: data.is_booking_sms,
        is_report_sms: data.is_report_sms,
        is_report_whatsapp: data.is_report_whatsapp,
        is_report_email: data.is_report_email,
        is_shown_to_pha: data.is_shown_to_pha,
        is_shown_to_sales: data.is_shown_to_sales,
        is_auto_verify: data.is_auto_verify,
        is_price_hide: data.is_price_hide,
      };
  
      setSmsType(smType);
    };
    useEffect(() => {
      getRateListFilter(tag_ProccessingLab, "");
    }, [tag_ProccessingLab]);
    useEffect(() => {
      if (categoryName !== "") {
        {
          paymentModeList.map((val: any) => {
            if (categoryName === "postpaid" && val.name === "Credit") {
              setPaymentMode(val.id);
            } else if (categoryName === "prepaid" && val.name === "Cash") {
              setPaymentMode(val.id);
            }
          });
        }
      }
      if (categoryName === "postpaid") {
        setMinAmountForBill(0);
      }
    }, [categoryName]);
  
    const disableCenter = async (data: any) => {
      // const body: any = {
      //   is_active: !val.is_active,
      // };
      // await updateCenter(body, val.id);
      // getCenterInfo("?show_all=true");
      openModalFun(data);
    };
  
    const [modalStatus, setModalStatus] = useState<boolean>(false);
    const [centerActive, setCenterActive] = useState<boolean>(false);
    const [dataCenterId, setDataCenterId] = useState<number>(0);
    const [disposition, setDisposition] = useState<string>("");
  
    const openModalFun = (val: any) => {
      setDisposition("");
      setDataCenterId(val?.id);
      setCenterActive(val?.is_active);
      setModalStatus(true);
    };
  
    const closeModalFun = () => {
      setDisposition("");
      setDataCenterId(0);
      setModalStatus(false);
    };
  
    const submitDisposition = async () => {
      const body: any = {
        is_active: !centerActive,
        comment: disposition,
      };
      await updateCenter(body, dataCenterId);
      closeModalFun();
      getCenterInfo("?show_all=true");
    };
  
    const handleClick = (data: any) => {
      setopenModal(true);
      setCenterdata(data);
    };
  
    const [windowSize, setWindowSize] = useState(getWindowSize());
  
    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }
  
      window.addEventListener("resize", handleWindowResize);
  
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, []);
  
    function getWindowSize() {
      const { innerWidth, innerHeight } = window;
      return { innerWidth, innerHeight };
    }
  
    const [itemData, setItemData] = useState<any>([]);
    const productList: string[] = ["C&D00870", "C&D00872", "P&S00035","OTH00028"];
    // useEffect(() => {
    //   let storeData: any = [];
    //   if (get_cc_consumption && get_cc_consumption?.length > 0) {
    //     get_cc_consumption?.map((list: any) => {
    //       if (productList.includes(list?.code)) {
    //         storeData.push({
    //           itemId: list?.id,
    //           userQty:
    //             list?.code === "C&D00870"
    //               ? 50
    //               : list?.code === "C&D00872"
    //               ? 50
    //               : list?.code === "P&S00035"
    //               ? 25
    //               : list?.code === "OTH00028"
    //               ? 25
    //               : 0,
    //         });
    //       }
    //     });
    //   }
    //   setItemData(storeData);
    // }, [get_cc_consumption]);
  
    // timer
  
    const [disableConsReq, setDisableConsReq] = useState<boolean>(true);
  
    useEffect(() => {
      if (disableConsReq) {
        setTimeout(() => {
          setDisableConsReq(false);
        }, 10000);
      }
    }, [disableConsReq === true]);
  
    // Consumables Requistion Modal
  
    // const [submitFormStatus, setSubmitFormStatus] = useState<boolean>(false);
    // const [param, setParam] = useState<number>(0);
  
    // const consuReqModal = (paramData: number) => {
    //   setParam(paramData);
    //   paramData ? setSubmitFormStatus(true) : setSubmitFormStatus(false);
    // };
  
    // const modalCloseWithNo = () => {
    //   setSubmitFormStatus(false);
    //   setParam(0);
    // };
  
    // const modalCloseWithYes = () => {
    //   setDisableConsReq(true);
    //   setSubmitFormStatus(false);
    //   // postCCToIv(param);
    // };
  
    // const postCCToIv = async (centreCcToInv: any) => {
    //   let storeData: any = [];
    //   itemData?.length > 0 &&
    //     itemData?.map((list: any) => {
    //       storeData.push({
    //         item: list?.itemId,
    //         store_item: null,
    //         qty: list?.userQty,
    //         date: moment().format("YYYY-MM-DD"),
    //         is_exceed: false,
    //         item_wise_remark: "",
    //       });
    //     });
  
    //   let data: any = {
    //     cc_code: centreCcToInv,
    //     to_location: 1,
    //     date: moment().format("YYYY-MM-DD"),
    //     remark: "",
    //     si_pi: "SI",
    //     items: storeData,
    //   };
    //   ccToInv(data);
    //   setItemData([]);
    //   setParam(0);
    // };
  
    useEffect(() => {
      if (cc_to_inv && cc_to_inv?.id) {
        resetCCToInventoryRes();
        history.push("/dashboard/ts/centerCreationModalDSA");
      }
    }, [cc_to_inv]);
  
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
  
        {/* <Dialog
          open={submitFormStatus}
          // onClose={modalCloseWithNo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContent
              style={{ width: "250px", flexDirection: "column" }}
              className={classes.centerIt}
            >
              <DialogContentText
                id="alert-dialog-description"
                style={{ textAlign: "center", color: "#000" }}
              >
                Are you sure to send requisition to store? 🧐
              </DialogContentText>
              <DialogActions>
                <Button variant="contained" onClick={modalCloseWithNo}>
                  No
                </Button>
                <Button variant="contained" onClick={modalCloseWithYes}>
                  Yes
                </Button>
              </DialogActions>
            </DialogContent>
          </DialogContent>
        </Dialog> */}
  
        <Dialog
          open={modalStatus}
          onClose={closeModalFun}
          aria-labelledby="form-dialog-title"
          className={classes.dialog}
        >
          {/* <DialogTitle id="form-dialog-title">Disposition Form</DialogTitle> */}
          <DialogContent>
            <Grid
              container
              spacing={2}
              style={
                {
                  // margin: "0px 10px 10px 10px",
                  // minWidth: "500px",
                  // width: "70%",
                }
              }
            >
              {/* <Grid item xs={12} md={12} style={{ height: "440px" }}></Grid> */}
              <Grid item xs={12} md={6}>
                <p>Disposition</p>
                {centerActive ? (
                  <TextField
                    // className="input"
                    value={disposition}
                    onChange={(e: any) => setDisposition(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    select
                    style={{ width: "250px" }}
                  >
                    <MenuItem value="No Business within 2 months">
                      No Business within 2 months
                    </MenuItem>
                    <MenuItem value="MOU cancelled by Owner">
                      MOU cancelled by Owner
                    </MenuItem>
                    <MenuItem value="Center closed">Center closed</MenuItem>
                    <MenuItem value="Incorrect documents">
                      Incorrect documents
                    </MenuItem>
                    <MenuItem value="Sales person request">
                      Sales person request
                    </MenuItem>
                    <MenuItem value="Payment is not received">
                      Payment is not received
                    </MenuItem>
                    <MenuItem value="Misuse of center code">
                      Misuse of center code
                    </MenuItem>
                    <MenuItem value="Incorrect given address">
                      Incorrect given address
                    </MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    className="input"
                    value={disposition}
                    onChange={(e: any) => setDisposition(e.target.value)}
                    variant="outlined"
                    required
                    style={{ width: "250px" }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={submitDisposition} color="primary">
              Save
            </Button>
            <Button onClick={closeModalFun} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
  
        <form onSubmit={handleSubmitCentre}>
          <Paper
            elevation={5}
            className={classes.paper}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {windowSize.innerWidth && windowSize.innerWidth < 600 ? (
              <h6
                className={classes.hoveItem}
                style={{
                  fontWeight: "bold",
                  background: "#924A91",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "10px",
                }}
                onClick={() => setCentreShow(centerShow ? false : true)}
              >
                {centerShow ? <ExpandLessIcon /> : <ExpandMoreIcon />} CENTRE
                CREATION MASTER
              </h6>
            ) : (
              <h4
                className={classes.hoveItem}
                style={{
                  fontWeight: "bold",
                  background: "#924A91",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => setCentreShow(centerShow ? false : true)}
              >
                {centerShow ? <ExpandLessIcon /> : <ExpandMoreIcon />} CENTRE
                CREATION MASTER
              </h4>
            )}
            {centerShow ? (
              <>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  style={{ alignItems: "left" }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      label="Centre Code"
                      value={centerType !== "" ? centerCode : ""}
                      required
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3} lg>
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="ORG Type"
                        value={orgtype}
                        onChange={(e) => setorgType(e.target.value)}
                      >
                        <MenuItem value="homedx">Home DX</MenuItem>
                        <MenuItem value="genomics">Genomics</MenuItem>
                      </TextField>
                    </Grid> */}
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="center_name"
                      type="text"
                      label="Centre Name"
                      value={centerName}
                      required
                      variant="outlined"
                      onChange={(e) => setCentreName(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      disabled
                      id="centre_type"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setCentreType(obj?.value);
                        }
                      }}
                      defaultValue={
                        CenterTypeData &&
                        CenterTypeData.find((code: any) => {
                          return code?.value === centerType;
                        })
                      }
                      options={CenterTypeData}
                      freeSolo
                      blurOnSelect
                      //   getOptionLabel={(option: any) => `${option?.text}`}
                      inputValue={"Collection Center"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Centre Type"
                          variant="outlined"
                          fullWidth
                          className="input"
                          // required
                        />
                      )}
                    />
                  </Grid>
                  {centerType === "direct_client" ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="Sub centre_type"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setSubCentreType(obj.value);
                          }
                        }}
                        defaultValue={
                          SubCenterTypeData.dc &&
                          SubCenterTypeData.dc.find((code: any) => {
                            return code.value === subCenterType;
                          })
                        }
                        options={SubCenterTypeData.dc}
                        freeSolo
                        blurOnSelect
                        getOptionLabel={(option: any) => `${option.text}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Partner Type"
                            variant="outlined"
                            style={{ width: "100%" }}
                            className="input"
                          />
                        )}
                      />
                    </Grid>
                  ) : centerType === "collection_center" ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        disabled
                        id="Sub centre_type"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setSubCentreType(obj.value);
                          }
                        }}
                        defaultValue={
                          SubCenterTypeData.cc &&
                          SubCenterTypeData.cc.find((code: any) => {
                            return code.value === subCenterType;
                          })
                        }
                        options={SubCenterTypeData.cc}
                        freeSolo
                        blurOnSelect
                        getOptionLabel={(option: any) => `${option.text}`}
                        inputValue="DSA"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Partner Type"
                            variant="outlined"
                            style={{ width: "100%" }}
                            className="input"
                          />
                        )}
                      />
                    </Grid>
                  ) : centerType === "GE_Home_Collection" ||
                    centerType === "GE Lab" ||
                    centerType === "GE CC" ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        id="Sub centre_type"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setSubCentreType(obj.value);
                          }
                        }}
                        defaultValue={
                          SubCenterTypeData.ge &&
                          SubCenterTypeData.ge.find((code: any) => {
                            return code.value === subCenterType;
                          })
                        }
                        options={SubCenterTypeData.ge}
                        freeSolo
                        blurOnSelect
                        getOptionLabel={(option: any) => `${option.text}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Partner Type"
                            variant="outlined"
                            style={{ width: "100%" }}
                            className="input"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
  
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="securityAmount"
                      type="text"
                      value={securityAmount}
                      className="input"
                      label="Security Amount "
                      variant="outlined"
                      onChange={(e) =>
                        setSecurityAmount(e.target.value as string)
                      }
                      style={{ width: "100%" }}
                      // required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="contract_date"
                      type="date"
                      value={contractDate}
                      className="input"
                      label="Contract Date"
                      variant="outlined"
                      required
                      inputProps={{
                        min: editButton
                          ? moment(contractDate).format("YYYY-MM-DD")
                          : moment().format("YYYY-MM-DD"),
                      }}
                      // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                      onChange={(e) => setContractDate(e.target.value as string)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="expiry_date"
                      type="date"
                      disabled={contractDate === ""}
                      value={expiryDate}
                      className="input"
                      label="Expiry Date"
                      inputProps={{
                        min: moment(contractDate).format("YYYY-MM-DD"),
                      }}
                      helperText={
                        moment(expiryDate).isAfter(contractDate)
                          ? ""
                          : "Expiry Date Should Greater Than Contract Date"
                      }
                      variant="outlined"
                      onChange={(e) => setExpiryDate(e.target.value as string)}
                      style={{ width: "100%" }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="mobile_number"
                      type="number"
                      label="Mobile Number"
                      value={mobileNumber}
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) => setMobileNumber(e.target.value as String)}
                      required
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onBlur={() => verifyMobile("mob")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          mobileNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.mobileNumber &&
                        "Incorrect Mobile Number"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="alt_number"
                      type="number"
                      label="Alternate Number"
                      value={altNumber}
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) => setaltNumber(e.target.value as String)}
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onBlur={() => verifyMobile("centAlt")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          altNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.altNumber &&
                        "Incorrect Alternate Number"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="email"
                      type="email"
                      value={email}
                      className="input"
                      label="Email "
                      variant="outlined"
                      onChange={(e) => setEmail(e.target.value as string)}
                      style={{ width: "100%" }}
                      required
                    />
                  </Grid>
  
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="identityproof"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setIdentityProof(obj?.id);
                        }
                      }}
                      defaultValue={
                        identityProofData &&
                        identityProofData.find((code: any) => {
                          return code?.id === Number(identityProof);
                        })
                      }
                      options={identityProofData || []}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => `${option?.name}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Identity Proof"
                          variant="outlined"
                          style={{ width: "100%" }}
                          className={classes.input}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <input
                      className="input"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "5px 5px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      onChange={fileSelectedHandler}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="sales_manager"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setSales_Manager(obj?.panel_user_id);
                        }
                      }}
                      defaultValue={
                        sales_ManagerDef || { panel_user_id: "", username: "" }
                      }
                      options={salesManager?.results || []}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => `${option?.username}`}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getSalesManager(newInputValue);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sales Manager"
                          variant="outlined"
                          style={{ width: "100%" }}
                          className="input"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="sales_executive"
                      onChange={(event, newValue) => {
                        let sourceIds: any = [];
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          sourceIds = obj.map((item: any) => item?.uuid);
                        }
                        setSales_Executive(sourceIds);
                      }}
                      defaultValue={sales_ExecutiveDef}
                      multiple
                      freeSolo
                      blurOnSelect
                      limitTags={2}
                      options={salesExecutive?.results || []}
                      getOptionDisabled={(option: any) => {
                        return sales_Executive.includes(option?.id);
                      }}
                      disableClearable
                      disableCloseOnSelect
                      getOptionLabel={(option: any) => `${option?.username}`}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getSalesExecutive(newInputValue);
                        }, 1000);
  
                        if (newInputValue.length === 0) {
                          setSales_Executive([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Sales Executive"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                        />
                      )}
                    />
                  </Grid>
  
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="centerAddress"
                      type="text"
                      value={centerAddress}
                      className="input"
                      label="Centre Address"
                      variant="outlined"
                      onChange={(e) => setCentreAddress(e.target.value as string)}
                      style={{ width: "100%" }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="tag_processinglab"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setTag_ProccessingLab(obj?.id);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getTagProcessingLab(`?name=${newInputValue}`);
                        }, 1000);
                        if (newInputValue.length === 0) {
                          getRateListFilter("", "");
                          setTag_ProccessingLab("");
                        }
                      }}
                      defaultValue={
                        tagProccessingLab.results &&
                        tagProccessingLab.results.find((code: any) => {
                          return code.id === tag_ProccessingLab;
                        })
                      }
                      options={tagProccessingLab.results || []}
                      freeSolo
                      disableClearable
                      blurOnSelect
                      getOptionLabel={(option: any) => `${option?.name}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Lab to filter Service Rate Category"
                          variant="outlined"
                          fullWidth
                          className="input"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="serviceRateCate"
                      key={tag_ProccessingLab}
                      onChange={(event, newValue) => {
                        // let sourceIds: any = [];
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setServiceRateCategory([obj?.id]);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getRateListFilter("", newInputValue);
                        }, 1000);
                        if (newInputValue.length === 0) {
                          getRateListFilter("", "");
                        }
                      }}
                      options={rateListFilter?.results || []}
                      freeSolo
                      defaultValue={
                        serviceRateCategoryDef || { id: "", name: "" }
                      }
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) => `${option?.name}`}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Service Rate Category"
                          variant="outlined"
                          style={{ width: "100%" }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      key={editButton}
                      fullWidth
                      aria-required
                      variant="outlined"
                      value={categoryName}
                      disabled={centerType === "collection_center"}
                      required
                      select
                      size="small"
                      label="Category Name"
                      onChange={(e) => setCategoryName(e.target.value)}
                    >
                      <MenuItem value="prepaid">Prepaid</MenuItem>
                      <MenuItem value="postpaid">Postpaid</MenuItem>
                    </TextField>
                  </Grid>
  
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      label="Payment Mode"
                      variant="outlined"
                      fullWidth
                      value={"Cash"}
                      disabled
                      // select
                      size="small"
                      required
                    />
                    {/* <TextField
                      label="Payment Mode"
                      variant="outlined"
                      fullWidth
                      value={paymentMode}
                      disabled
                      select
                      size="small"
                      required
                    >
                      {paymentModeList.map((val: any) => (
                        <MenuItem value={val?.id}>{val?.name}</MenuItem>
                      ))}
                    </TextField> */}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      aria-required
                      variant="outlined"
                      label="Barcode Type"
                      value={barCodeType}
                      required
                      select
                      size="small"
                      onChange={(e) => setBarCodeType(e.target.value)}
                    >
                      <MenuItem value="preprainted">Pre Printed</MenuItem>
                      <MenuItem value="systemgenerated">System Genrated</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="busyId"
                      type="text"
                      value={busyId}
                      className="input"
                      label="Busy Id "
                      variant="outlined"
                      onChange={(e) => setBusyId(e.target.value as string)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Adhar_Number"
                      type="number"
                      value={adharNumber}
                      className="input"
                      label="Aadhaar Number *"
                      variant="outlined"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      helperText={
                        adharNumber?.length < 12 || adharNumber === null
                          ? "Enter a valid 12 Digit Aadhaar No."
                          : ""
                      }
                      onChange={(e) => setAdharNumber(e.target.value as string)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="pincode"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setPincodeId(obj?.id as Number);
                          setArea(obj?.area as String);
                          setCityName(obj?.city as String);
                          setCityId(obj?.cityid);
                        }
                      }}
                      defaultValue={
                        pinCodeIdDef || {
                          id: "",
                          area: "",
                          city: "",
                          cityid: "",
                          pincode: "",
                        }
                      }
                      options={pin_code?.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) =>
                        option.pincode !== ""
                          ? `${option.pincode + "," + option.area}`
                          : ""
                      }
                      onInputChange={(event, newInputValue) => {
                        //   clearTimeout(timer.current);
                        //   timer.current = setTimeout(() => {
                        //   }, 1000);
                        getPinCode(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Pin Code"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="area"
                      type="text"
                      value={area}
                      className="input"
                      label="Area "
                      variant="outlined"
                      disabled
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="city"
                      type="text"
                      value={cityName}
                      className="input"
                      label="City Name "
                      variant="outlined"
                      disabled
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="lat"
                      type="text"
                      value={center_latitude}
                      className="input"
                      label="Latitude"
                      variant="outlined"
                      onChange={(e) =>
                        setCentreLatitude(e.target.value as string)
                      }
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="lon"
                      type="text"
                      value={center_longitude}
                      className="input"
                      label="Longitude"
                      variant="outlined"
                      onChange={(e) =>
                        setCentreLongitude(e.target.value as string)
                      }
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      key={editButton}
                      fullWidth
                      variant="outlined"
                      select
                      size="small"
                      label="Sample Register To"
                      value={sampleRegisterTo}
                      required
                      onChange={(e) => setSampleRegisterTo(e.target.value)}
                    >
                      <MenuItem value="livehealth">LiveHealth</MenuItem>
                      <MenuItem value="itdose">Itdose</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <label
                      htmlFor="fileUp"
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        margin: "0px",
                        width: "100%",
                        padding: "5px 5px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                        height: 40,
                      }}
                    >
                      <div style={{ width: "20%", textAlign: "left" }}>
                        Upload
                      </div>
                      <div style={{ width: "60%", textAlign: "left" }}>
                        <input
                          // style={{ display: "none" }}
                          id={"fileUp"}
                          multiple
                          type="file"
                          accept="image/png, image/jpg, image/jpeg, application/pdf"
                          onChange={(e) =>
                            setImages(
                              e.target.files && e.target.files.length > 0
                                ? e.target.files
                                : []
                            )
                          }
                        />
                      </div>
                    </label>
                  </Grid>
                </Grid>
                {windowSize.innerWidth && windowSize.innerWidth < 600 ? (
                  <h6
                    className={classes.hoveItem}
                    style={{
                      fontWeight: "bold",
                      marginTop: "1rem",
                      background: "#924A91",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    CENTRE HEAD DETAILS
                  </h6>
                ) : (
                  <h4
                    className={classes.hoveItem}
                    style={{
                      fontWeight: "bold",
                      marginTop: "1rem",
                      background: "#924A91",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    CENTRE HEAD DETAILS
                  </h4>
                )}
  
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  style={{ alignItems: "left" }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="salutation"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setDesignation(obj.value);
                        }
                      }}
                      defaultValue={
                        salutationData &&
                        salutationData.find((code: any) => {
                          return code.value === designation;
                        })
                      }
                      options={salutationData}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => option.text}
                      renderInput={(params) => (
                        <TextField
                          className={classes.input}
                          {...params}
                          label="Salutation"
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Ownername"
                      type="text"
                      value={ownerName}
                      className="input"
                      label="Owner Name"
                      variant="outlined"
                      onChange={(e) => setOwnerName(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="nationality"
                      type="text"
                      value={nationality}
                      className="input"
                      label="Nationality"
                      variant="outlined"
                      onChange={(e) => setNationality(e.target.value as string)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="contact"
                      type="number"
                      label="Centre Head Mobile Number"
                      value={centerHeadalContactNumber}
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCentreHeadContactNumber(e.target.value as String)
                      }
                      onBlur={() => verifyMobile("contMob")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          centerHeadalContactNumber: true,
                        }));
                      }}
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      helperText={
                        !mobileValidation.centerHeadalContactNumber &&
                        "Incorrect Mobile Number"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="contact"
                      type="number"
                      label="Centre Head Alternate Number"
                      value={centerHeadalterNateNumber}
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCentreHeadAlternateNumber(e.target.value as String)
                      }
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onBlur={() => verifyMobile("contAlt")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          centerHeadalterNateNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.centerHeadalterNateNumber &&
                        "Incorrect Alternate Number"
                      }
                    />
                  </Grid>
                </Grid>
                {windowSize.innerWidth && windowSize.innerWidth < 600 ? (
                  <h6
                    className={classes.hoveItem}
                    onClick={() =>
                      setCentreBankShow(centerBankShow ? false : true)
                    }
                    style={{
                      fontWeight: "bold",
                      marginTop: "1rem",
                      background: "#924A91",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    CENTRE BANK DETAILS
                  </h6>
                ) : (
                  <h4
                    className={classes.hoveItem}
                    onClick={() =>
                      setCentreBankShow(centerBankShow ? false : true)
                    }
                    style={{
                      fontWeight: "bold",
                      marginTop: "1rem",
                      background: "#924A91",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    CENTRE BANK DETAILS
                  </h4>
                )}
  
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  style={{ alignItems: "left" }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="acountholder"
                      type="text"
                      value={bankAccountHolder}
                      className="input"
                      label="Account Holder Name"
                      variant="outlined"
                      onChange={(e) => setbankAccountHolder(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      id="bank"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setBank(obj.id);
                        }
                      }}
                      defaultValue={
                        bankName.count > 0 &&
                        bankName.results.find((code: any) => {
                          return code.id === bank;
                        })
                      }
                      freeSolo
                      blurOnSelect
                      options={bankName?.results}
                      disableCloseOnSelect
                      getOptionLabel={(option: any) => option?.name}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getBankName(`?name=${newInputValue}`);
                        }, 1000);
  
                        setBankInput(newInputValue);
                      }}
                      inputValue={bankInput}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Bank Name"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="GSTINNumber"
                      type="text"
                      value={gstinNumber}
                      className="input"
                      label="GSTIN"
                      variant="outlined"
                      onChange={(e) => setGstinNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="panNumber"
                      type="text"
                      value={panNumber}
                      className="input"
                      label="PAN"
                      variant="outlined"
                      // inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={(e) =>
                        setPanNumber(String(e.target.value).toUpperCase())
                      }
                      style={{ width: "100%" }}
                      helperText="Ex:- ABCDE9999F"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="IFSC"
                      type="text"
                      value={ifscCode}
                      className="input"
                      label="IFSC Code"
                      variant="outlined"
                      onChange={(e) => setIfscCode(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="accountNumber"
                      type="number"
                      value={accountNumber}
                      className="input"
                      label="Account Number"
                      variant="outlined"
                      onChange={(e) => setAccountNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                {windowSize.innerWidth && windowSize.innerWidth < 600 ? (
                  <h6
                    className={classes.hoveItem}
                    onClick={() =>
                      setCentreControllingShow(
                        centerControlingShow ? false : true
                      )
                    }
                    style={{
                      fontWeight: "bold",
                      background: "#924A91",
                      color: "#fff",
                      marginTop: "1rem",
                      padding: "5px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    ACCOUNT CONTROLLING DETAILS
                  </h6>
                ) : (
                  <h4
                    className={classes.hoveItem}
                    onClick={() =>
                      setCentreControllingShow(
                        centerControlingShow ? false : true
                      )
                    }
                    style={{
                      fontWeight: "bold",
                      background: "#924A91",
                      color: "#fff",
                      marginTop: "1rem",
                      padding: "5px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    ACCOUNT CONTROLLING DETAILS
                  </h4>
                )}
  
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  style={{ alignItems: "left" }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="minamountfornoti"
                      type="number"
                      value={minAmountForNotification}
                      className="input"
                      label="Min Amt. For Notification *"
                      variant="outlined"
                      onChange={(e) => setMinNotication(e.target.value as string)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
  
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="minamountforbill"
                      type="number"
                      value={minAmountForBill}
                      disabled={categoryName === "postpaid"}
                      className="input"
                      label="Min Amt For Bill *"
                      variant="outlined"
                      onChange={(e) => setMinAmountForBill(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Package Rights"
                      value={packageRights}
                      select
                      size="small"
                      required
                      label="Package Rights"
                      variant="outlined"
                      onChange={(e) => setPackageRights(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="redcliffe">Redcliffe</MenuItem>
                      <MenuItem value="self">Self</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                      <MenuItem value="all">All</MenuItem>
                    </TextField>
                  </Grid>
                  {categoryName === "postpaid" ? (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="Credit Limit"
                          type="number"
                          value={creditLimit}
                          className="input"
                          required
                          label="Credit Limit"
                          variant="outlined"
                          onChange={(e) => setCreditLimit(e.target.value)}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="Credit Limit"
                          type="number"
                          value={invoiceCycle}
                          select
                          size="small"
                          required
                          label="Invoice Cycle"
                          variant="outlined"
                          onChange={(e) => setInvoiceCycle(e.target.value)}
                          fullWidth
                        >
                          <MenuItem value="2">2 Days</MenuItem>
                          <MenuItem value="15">15 Days</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                        </TextField>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Report Whatsapp Sent To"
                      type="number"
                      value={reportWhatsappSentTo}
                      label="Report Whatsapp Sent To"
                      variant="outlined"
                      select
                      size="small"
                      onChange={(e) => setReportWhatsappSentTo(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="client">Client</MenuItem>
                      <MenuItem value="customer">Customer</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                      <MenuItem value="not_send">Not Send</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      type="number"
                      value={reportEmailSentTo}
                      select
                      size="small"
                      label="Report Email Sent To"
                      variant="outlined"
                      onChange={(e) => setReportEmailSentTo(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="client">Client</MenuItem>
                      <MenuItem value="customer">Customer</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                      <MenuItem value="not_send">Not Send</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      type="number"
                      value={reportSmsSentTo}
                      select
                      size="small"
                      label="Report Sms Sent To"
                      variant="outlined"
                      onChange={(e) => setReportSmsSentTo(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="client">Client</MenuItem>
                      <MenuItem value="customer">Customer</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                      <MenuItem value="not_send">Not Send</MenuItem>
                    </TextField>
                  </Grid>
  
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      value={accountActive}
                      select
                      size="small"
                      label="Billing on/off"
                      variant="outlined"
                      onChange={(e) => setAccountActive(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="true">on</MenuItem>
                      <MenuItem value="false">off</MenuItem>
                    </TextField>
                  </Grid>
                  {centerType === "direct_client" ||
                  centerType === "GE Lab" ||
                  centerType === "GE CC" ||
                  centerType === "GE_Home_Collection" ? (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          value={autosettle}
                          select
                          size="small"
                          label="Autosettle"
                          variant="outlined"
                          onChange={(e) => setAutosettle(e.target.value)}
                          fullWidth
                        >
                          <MenuItem value="auto_settlement">
                            Auto Settlement
                          </MenuItem>
                          <MenuItem value="manual_settlement">
                            Manual Settlement
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isbillinglock"
                                checked={smsType.isbillinglock}
                                disabled={centerType === "collection_center"}
                              />
                            }
                            onChange={handleSmsControl}
                            label="Is Billing Lock"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox name="screen" checked={smsType.screen} />
                            }
                            onChange={handleSmsControl}
                            label="Is Special Price Billing"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox name="mail" checked={smsType.mail} />
                            }
                            onChange={handleSmsControl}
                            label="Is HLM Client"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_booking_payment_sms"
                                checked={smsType.is_booking_payment_sms}
                              />
                            }
                            onChange={handleSmsControl}
                            label="Booking payment sms"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_booking_sms"
                                checked={smsType.is_booking_sms}
                              />
                            }
                            onChange={handleSmsControl}
                            label="booking sms"
                          />
  
                          {centerType !== "corporate" ? (
                            <FormControlLabel
                              control={
                                <Checkbox name="sms" checked={smsType.sms} />
                              }
                              onChange={handleSmsControl}
                              label="Phlebo Required"
                            />
                          ) : (
                            ""
                          )}
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_shown_to_pha"
                                checked={smsType.is_shown_to_pha}
                              />
                            }
                            onChange={handleSmsControl}
                            label="shown to pha"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_shown_to_sales"
                                checked={smsType.is_shown_to_sales}
                              />
                            }
                            onChange={handleSmsControl}
                            label="shown to sales"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_auto_verify"
                                checked={smsType.is_auto_verify}
                              />
                            }
                            onChange={handleSmsControl}
                            label="auto verify"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="is_price_hide"
                                checked={smsType.is_price_hide}
                              />
                            }
                            onChange={handleSmsControl}
                            label="price hide"
                          />
                        </FormGroup>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <FormGroup row>
                        {/* <FormControlLabel
                          control={
                            <Checkbox name="mail" checked={smsType.mail} />
                          }
                          onChange={handleSmsControl}
                          label="Is HLM Client"
                        />
                        <FormControlLabel
                          control={<Checkbox name="sms" checked={smsType.sms} />}
                          onChange={handleSmsControl}
                          label="Phlebo Required"
                        /> */}
                        <FormControlLabel
                          control={
                            <Checkbox name="screen" checked={smsType.screen} />
                          }
                          onChange={handleSmsControl}
                          label="Is Special Price Billing"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isbillinglock"
                              checked={smsType.isbillinglock}
                              disabled={centerType === "collection_center"}
                            />
                          }
                          onChange={handleSmsControl}
                          label="Is Billing Lock"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isapproved"
                              checked={smsType.isapproved}
                            />
                          }
                          onChange={handleSmsControl}
                          label="Is Approved"
                        />
  
                        {/* <FormControlLabel
                            control={
                              <Checkbox
                                name="Is Shown Pha"
                                checked={smsType.isapproved}
                              />
                            }
                            onChange={handleSmsControl}
                            label="Is Shown Pha"
                          /> */}
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={
                        !mobileValidation.mobileNumber ||
                        !mobileValidation.altNumber ||
                        !mobileValidation.centerHeadalContactNumber ||
                        !mobileValidation.centerHeadalterNateNumber
                      }
                      type="submit"
                    >
                      {editButton ? "Update Centre" : "Create Center"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    {!editButton ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() =>
                          history.push(
                            "/dashboard/ts/centerCreationModalDSA"
                          )
                        }
                      >
                        Reset
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() =>
                          history.push(
                            "/dashboard/ts/centerCreationModalDSA"
                          )
                        }
                      >
                        Cancel Update
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
  
            {/* <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "right" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    !mobileValidation.mobileNumber ||
                    !mobileValidation.altNumber ||
                    !mobileValidation.centerHeadalContactNumber ||
                    !mobileValidation.centerHeadalterNateNumber
                  }
                  type="submit"
                >
                  {editButton ? "Update Centre" : "Create Center"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {!editButton ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() =>
                      history.push("/dashboard/ts/centerCreationModalDSA")
                    }
                  >
                    Reset
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() =>
                      history.push("/dashboard/ts/centerCreationModalDSA")
                    }
                  >
                    Cancel Update
                  </Button>
                )}
              </Grid>
            </Grid> */}
          </Paper>
        </form>
  
        <form onSubmit={handleUploadCentre}>
          <Paper
            elevation={5}
            className={classes.paper}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={3} className={classes.centerIt}>
              <Grid item xs={12} sm={6} md={3}>
                <input
                  className="input"
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "5px 5px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  type="file"
                  name="fileUp"
                  id="fileUp"
                  multiple
                  onChange={fileUploadHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled
                  startIcon={<CloudUploadIcon />}
                >
                  Bulk Upload
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={7}>
                <sub>*File Format should be CSV(comma delimited)</sub>
                <br />
                <sub>
                  Columns (Centre Code | Centre Name | Centre Type | contract date
                  | expiry date | identity proof | mobile number | email id |
                  centre address | salutation | name | mobile number | Min Amount
                  for Notification | service rate category | sales manager | type)
                </sub>
              </Grid>
            </Grid>
          </Paper>
        </form>
  
        <Paper
          elevation={5}
          className={classes.paper}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            spacing={3}
            className={classes.centerIt}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={12} sm={6} md={2} className={classes.centerIt}>
              <h5 style={{ marginTop: 10 }} className={classes.centerIt}>
                Search Option{" "}
              </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                className="input"
                label="Centre/Code"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCenterInfo(
                      `?name_or_code=${e.target.value}&show_all=true`
                    );
                  }, 1000);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() =>
                  history.push("/dashboard/ts/centerCreationModalDSA")
                }
              >
                Reset
              </Button>
            </Grid>
          </Grid>
  
          <div className="data-table" style={{ width: "100%" }}>
            {windowSize.innerWidth && windowSize.innerWidth <= 425 ? (
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "515px" }}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <Table stickyHeader aria-label="simple table">
                    <TableBody>
                      {centerInfo &&
                        centerInfo.results &&
                        centerInfo.results.length > 0 &&
                        centerInfo.results.map((data: any, index: any) => {
                          return (
                            <StyledTableRow
                              key={index}
                              style={{
                                background:
                                  data?.is_active === true ? "" : "#ef9a9a",
                              }}
                            >
                              <div>
                                <Box
                                  sx={
                                    windowSize.innerWidth &&
                                    windowSize.innerWidth <= 320
                                      ? {
                                          width: "100%",
                                          height: 170,
                                          margin: "0 0 5px 0",
                                          backgroundColor: "#9b59b6",
                                          "&:hover": {
                                            backgroundColor: "#8e44ad",
                                            opacity: [0.9, 0.8, 0.7],
                                          },
                                        }
                                      : windowSize.innerWidth &&
                                        windowSize.innerWidth <= 375
                                      ? {
                                          width: "100%",
                                          height: 190,
                                          margin: "0 0 5px 0",
                                          backgroundColor: "#9b59b6",
                                          "&:hover": {
                                            backgroundColor: "#8e44ad",
                                            opacity: [0.9, 0.8, 0.7],
                                          },
                                        }
                                      : windowSize.innerWidth &&
                                        windowSize.innerWidth <= 425
                                      ? {
                                          width: "100%",
                                          height: 200,
                                          margin: "0 0 5px 0",
                                          backgroundColor: "#9b59b6",
                                          "&:hover": {
                                            backgroundColor: "#8e44ad",
                                            opacity: [0.9, 0.8, 0.7],
                                          },
                                        }
                                      : {
                                          width: "100%",
                                          height: 200,
                                          margin: "0 0 5px 0",
                                          backgroundColor: "#9b59b6",
                                          "&:hover": {
                                            backgroundColor: "#8e44ad",
                                            opacity: [0.9, 0.8, 0.7],
                                          },
                                        }
                                  }
                                  className={classes.centerIt}
                                  style={
                                    windowSize.innerWidth &&
                                    windowSize.innerWidth <= 320
                                      ? {
                                          padding: "5px",
                                          borderRadius: "5px",
                                          fontSize: "9px",
                                        }
                                      : windowSize.innerWidth &&
                                        windowSize.innerWidth <= 375
                                      ? {
                                          padding: "5px",
                                          borderRadius: "5px",
                                          fontSize: "11px",
                                        }
                                      : windowSize.innerWidth &&
                                        windowSize.innerWidth <= 425
                                      ? {
                                          padding: "5px",
                                          borderRadius: "5px",
                                          fontSize: "12px",
                                        }
                                      : {
                                          padding: "5px",
                                          borderRadius: "5px",
                                          fontSize: "10px",
                                        }
                                  }
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "15%",
                                      }}
                                      className={classes.spaceBetween}
                                    >
                                      <div>
                                        <span style={{ marginRight: "10px" }}>
                                          1
                                        </span>
                                        <span>Customer Name</span>
                                      </div>
                                      <div>
                                        <span>Booking Id: </span>
                                        <span>99889098</span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "85%",
                                      }}
                                      className={classes.centerIt}
                                    >
                                      <div
                                        style={{
                                          width: "80%",
                                          height: "100%",
                                          border: "2px solid red",
                                        }}
                                      >
                                        <div className={classes.flexStart}>
                                          <div>
                                            <span style={{ color: "#ecf0f1" }}>
                                              Age:{" "}
                                            </span>
                                            <strong>23</strong>
                                          </div>
                                          <div>
                                            <span style={{ color: "#ecf0f1" }}>
                                              Gender:{" "}
                                            </span>
                                            <strong>Male</strong>
                                          </div>
                                        </div>
                                        <div>
                                          <span style={{ color: "#ecf0f1" }}>
                                            Date:{" "}
                                          </span>
                                          <strong>
                                            {moment().format("DD-MM-YYYY")}
                                          </strong>
                                        </div>
                                        <div>
                                          <span style={{ color: "#ecf0f1" }}>
                                            Expected Report:{" "}
                                          </span>
                                          <strong>
                                            {" "}
                                            {moment().format(
                                              "DD-MM-YYYY, hh:mm A"
                                            )}
                                          </strong>
                                        </div>
                                        <div>
                                          <span style={{ color: "#ecf0f1" }}>
                                            Report Status:{" "}
                                          </span>
                                          <strong>Rejected</strong>
                                        </div>
                                        <div>
                                          <span style={{ color: "#ecf0f1" }}>
                                            Booking Status:{" "}
                                          </span>
                                          <strong>Sent</strong>
                                        </div>
                                        <div>
                                          <span>Centre Name: </span>
                                          <strong style={{ color: "#f1c40f" }}>
                                            COLLECTION DSA
                                          </strong>
                                        </div>
                                      </div>
  
                                      <div
                                        style={{
                                          width: "20%",
                                          height: "100%",
                                          flexDirection: "column",
                                        }}
                                        className={classes.centerIt}
                                      >
                                        <MessageRounded
                                          style={{ color: "#bdc3c7" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Box>
                              </div>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TablePagination
                        colSpan={6}
                        count={centerInfo.count || 0}
                        rowsPerPageOptions={[]}
                        rowsPerPage={centerInfo.page_size}
                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                        page={page}
                        SelectProps={{
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                      />
                    </TableFooter>
                  </Table>
                )}
              </TableContainer>
            ) : (
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "515px" }}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        {/* {userDetails?.profile?.executive_type === "Auditor" ? (
                          ""
                        ) : (
                          <StyledTableCell align="center">
                            Consumable Requistion
                          </StyledTableCell>
                        )} */}
                        <StyledTableCell align="center">
                          Centre Name
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Centre Code
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Centre Comment
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Centre Sub Type
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Centre Owner
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Centre Email
                        </StyledTableCell>
                        <StyledTableCell align="center">Number</StyledTableCell>
                        <StyledTableCell align="center">
                          Uploaded files
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Created Date
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Expiry Date
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Assigned Lab
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Service Rate Category
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Centre Type
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Autosettle
                        </StyledTableCell>
                        <StyledTableCell align="center">Billing</StyledTableCell>
                        <StyledTableCell align="center">
                          Centre Status
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Edit Centre
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {centerInfo &&
                        centerInfo.results &&
                        centerInfo.results.length > 0 &&
                        centerInfo.results.map((data: any, index: any) => {
                          return (
                            <StyledTableRow
                              key={index}
                              style={{
                                background:
                                  data?.is_active === true ? "" : "#ef9a9a",
                              }}
                            >
                              {/* {userDetails?.profile?.executive_type ===
                              "Auditor" ? (
                                ""
                              ) : (
                                <StyledTableCell align="center">
                                  {data?.center_type === "collection_center" ? (
                                    <Button
                                      variant="contained"
                                      disabled={
                                        (data?.inventory_location &&
                                          data?.inventory_location
                                            ?.is_stock_created === "Yes") ||
                                        loading === true ||
                                        disableConsReq === true
                                      }
                                      onClick={() => {
                                        consuReqModal(data?.center_code);
                                      }}
                                    >
                                      {data?.inventory_location
                                        ?.is_stock_created === "Yes"
                                        ? "Sent"
                                        : "Send"}
                                    </Button>
                                  ) : (
                                    "N/A"
                                  )}
                                </StyledTableCell>
                              )} */}
                              <StyledTableCell align="center">
                                {data?.display_name === null
                                  ? data?.name
                                  : data?.display_name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {" "}
                                {data?.center_code}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <div>
                                  <MessageRounded
                                    onClick={() => handleClick(data?.id)}
                                  />
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {" "}
                                {data?.subtype}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {" "}
                                {data?.owner_name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {" "}
                                {data?.email}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {" "}
                                {data?.mobile}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {" "}
                                {data?.images &&
                                  data?.images.length > 0 &&
                                  data?.images.map((val: any) => {
                                    return (
                                      <a href={val.file} target="blank">
                                        {val.file}
                                      </a>
                                    );
                                  })}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.contract_start_date}{" "}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.contract_end_date}
                              </StyledTableCell>
                              {/* <StyledTableCell align="center">
                    {" "}
                    {data?.area?.state}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {" "}
                    {data?.area?.city_name}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {" "}
                    {data.address}
                  </StyledTableCell> */}
                              <StyledTableCell align="center">
                                {" "}
                                {data.ratelist
                                  ? data?.ratelist[0]?.assigned_lab
                                  : ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {" "}
                                {data.ratelist ? data?.ratelist[0]?.name : ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {" "}
                                {data.center_type}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.patient_type}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.is_account_active ? "on" : "off"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data.center_type !== "corporate" ? (
                                  <DefaultSwitch
                                    checked={data.is_active}
                                    name="checkedA"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    disableRipple
                                    onClick={() => disableCenter(data)}
                                  />
                                ) : data.is_active ? (
                                  "Active"
                                ) : (
                                  "Inactive"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data.center_type !== "corporate" ? (
                                  <Button
                                    disabled={disableEdit}
                                    color="primary"
                                    variant="contained"
                                  >
                                    <Edit
                                      onClick={() => handleEditCentre(data)}
                                    />
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TablePagination
                        count={centerInfo?.count || 0}
                        rowsPerPageOptions={[]}
                        colSpan={3}
                        rowsPerPage={centerInfo?.page_size}
                        page={page}
                        SelectProps={{
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                      />
                    </TableFooter>
                  </Table>
                )}
              </TableContainer>
            )}
            <TicketCommentsModal
              openModal={openModal}
              setopenModal={setopenModal}
              centerdata={centerdata}
            />
          </div>
        </Paper>
      </main>
    );
  };
  const mapStateToProps = (state: any) => ({
    loading: state.TechSupportReducer.loading,
    centerInfo: state.TechSupportReducer.centerInfo,
    centreCode: state.TechSupportReducer.centreCode,
    pin_code: state.TechSupportReducer.pin_code,
    agentBYUserGroup: state.TechSupportReducer.agentBYUserGroup,
    salesManager: state.TechSupportReducer.salesManager,
    salesExecutive: state.TechSupportReducer.salesExecutive,
    identityProofData: state.TechSupportReducer.identityProofData,
    stateList: state.TechSupportReducer.stateList,
    paymentModeList: state.TechSupportReducer.paymentModeList,
    rateListFilter: state.TechSupportReducer.rateListFilter,
    tagProccessingLab: state.TechSupportReducer.tagProccessingLab,
    uploadCentresData: state.TechSupportReducer.uploadCentresData,
    userDetails: state.TechSupportReducer.userDetails,
    bankName: state.TechSupportReducer.bankName,
    cc_to_inv: state.TechSupportReducer.cc_to_inv,
    // get_cc_consumption: state.TechSupportReducer.get_cc_consumption,
  });
  export default connect(mapStateToProps, {
    getCenterInfo,
    getPinCode,
    createNewCenter,
    getBankName,
    getAgentList,
    getSalesExecutive,
    getSalesManager,
    updateCenter,
    getIdentityProof,
    getStateList,
    getpaymentMoodList,
    getRateListFilter,
    getTagProcessingLab,
    getCentreCode,
    uploadCentres,
    ccToInv,
    resetCCToInventoryRes,
    // getCCConsumptionItems,
  })(CcDsa);
  