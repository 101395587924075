import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '@mui/material/Button';
import { Grid, TextField, MenuItem, Select } from "@material-ui/core";
import { sendDigitalPrescription, getDigitalPrescriptionList,getBookingById } from "../../actions/TechSupportActions";
import Loader from "../../components/Loader80";
import RenderPDF from "../ImageModal/index";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "800px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
}));

type ModalProps = {
  setOpen: Function;
  open: any;
  sendDigitalPrescription: any;
  getDigitalPrescriptionList: any;
  sendDigitalPrescriptionToCustomer: any;
  digitalPrescriptionListWithBooking: any;
  bookingId: any;
  getBookingById: any;
  booking: any;
  loading: any;
};

const SendPrescriptionModal: React.FC<ModalProps> = ({
  sendDigitalPrescription,
  setOpen,
  open,
  getDigitalPrescriptionList,
  sendDigitalPrescriptionToCustomer,
  digitalPrescriptionListWithBooking,
  bookingId,
  getBookingById,
  booking,
  loading,
}) => {
  const classes = useStyles();
  const [prescriptionSentOnWhatsapp, setPrescriptionSentOnWhatsapp] = useState<String>("");
  const [prescriptionSentOnEmail, setPrescriptionSentOnEmail] = useState<String>("");
  const [mobileValidation, setMobileValidation] = useState<any>(true);
  const [emailValidation, setEmailValidation] = useState<any>(true);
  const [checkedWay, setCheckedWay] = useState<any>("whatsapp");
  const [personId, setPersonId] = useState<any>('none');
  const [customerName, setCustomerName] = useState<String>("");
  const [customerAge, setCustomerAge] = useState<String>("");
  const [gender, setGender] = useState<String>("none");
  const [idForPrescription, setIdForPrescription] = useState<any>(0);
  const [openViewPrescriptionModal, setOpenViewPrescriptionModal] = useState<boolean>(false);
  const [key, setKey] = useState<any>(false);

  useEffect(() => {
    getBookingById(`${bookingId}/`);
    getDigitalPrescriptionList(bookingId);
  }, [])

  useEffect(() => {
    if (booking?.pk == bookingId) {
        setPersonId(bookingId);
        setCustomerName(booking?.customer_name);
        setCustomerAge(booking?.customer_age);
        setGender(booking?.customer_gender);
        setPrescriptionSentOnWhatsapp(booking?.customer_whatsapppnumber);
        setIdForPrescription(booking?.digital_prescription?.id);
        setKey(!key);
    }
  }, [booking])

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open === false) {
      setEmailValidation(true)
      setMobileValidation(true)
      setPrescriptionSentOnEmail("")
      setPrescriptionSentOnWhatsapp("")
      setCheckedWay("")
    }
  }, [open])

  const handleSendPrescription = async () => {
    const body: any = {
      number: prescriptionSentOnWhatsapp,
      booking: personId,
    }
    // setEmailValidation(true);
    setMobileValidation(true);
    // setPrescriptionSentOnEmail("");
    setPrescriptionSentOnWhatsapp("");
    setOpen(false);
    if (digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count > 0) {
      sendDigitalPrescription(body, digitalPrescriptionListWithBooking?.results[0]?.id);
    }
  };

  const handleCanelPrescription = () => {
    // setEmailValidation(true);
    setMobileValidation(true);
    // setPrescriptionSentOnEmail("");
    setPrescriptionSentOnWhatsapp("");
    setOpen(false);
  };

  const verifyMobile = () => {
    const mobileRegEx = /^(?:\d{10}|X{6}\d{4})$/;
    if (prescriptionSentOnWhatsapp && !mobileRegEx.test(prescriptionSentOnWhatsapp.toString())) {
      setMobileValidation(false);
    } else {
      setMobileValidation(true);
    }
  };

  // const verifyEmail = () => {
  //   const nameRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //   if (prescriptionSentOnEmail && !nameRegex.test(prescriptionSentOnEmail.toString())) {
  //     setEmailValidation(false);
  //   }
  // };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backdropFilter: "blur(5px)" },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <>
              <div style={{ display: "flex", justifyContent: "space-between", marginRight: "24px" }}>
                <h1 style={{ marginBottom: "40px" }}>Send Prescription</h1>
                <Button
                  onClick={() => setOpenViewPrescriptionModal(true)}
                  disabled={loading || (digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count === 0) || (digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count > 0 && digitalPrescriptionListWithBooking?.results[0]?.file === null)}
                  style={{ 
                    backgroundColor: (loading || (digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count === 0) || (digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count > 0 && digitalPrescriptionListWithBooking?.results[0]?.file === null)) ? "rgba(0, 0, 0, 0.12)" : "#298ecf",
                    color: (loading || (digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count === 0) || (digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count > 0 && digitalPrescriptionListWithBooking?.results[0]?.file === null)) ? "rgba(0, 0, 0, 0.26)" : "white",
                    height: "40px"
                  }}
                >
                  View Prescription
                </Button>
              </div>
              <Grid container spacing={3} style={{ padding: "12px" }}>
                <div style={{ display: "flex", width: "100%", paddingBottom: "32px" }}>
                  <p style={{ width: "45%", margin: "0", height: "40px", backgroundColor: "white", fontSize: "30px", fontWeight: "100" }}>Select Patient:</p>
                  <Select
                    className="input"
                    name="customer_name"
                    variant="outlined"
                    style={{ width: "55%", margin: "0", height: "40px", backgroundColor: "white" }}
                    value={personId}
                    onChange={(e: any) => {
                        setPersonId(e.target.value);
                        const selectedData = booking?.additional_members.find(
                        (data: any) => data.id === e.target.value
                        );
                        setCustomerName(selectedData?.customer_name || booking?.customer_name);
                        setCustomerAge(selectedData?.customer_age || booking?.customer_age);
                        setGender(selectedData?.customer_gender || booking?.customer_gender);
                        const prescriptionId = bookingId !== e.target.value
                                                ? (selectedData?.digital_prescription?.id === undefined ? 0 : selectedData?.digital_prescription?.id)
                                                : (booking?.digital_prescription?.id === undefined ? 0 : booking?.digital_prescription?.id)
                        setIdForPrescription(prescriptionId);
                        getDigitalPrescriptionList(e.target.value);
                    }}
                    >
                    <MenuItem disabled value="none">Customer Name</MenuItem>
                    <MenuItem value={booking?.pk}>{booking?.customer_name}</MenuItem>
                    {booking?.additional_members?.map((data: any) => (
                        <MenuItem key={data?.id} value={data?.id}>{data?.customer_name}</MenuItem>
                    ))}
                  </Select>
                </div>
                {
                  loading ?
                    <Loader />
                    :
                    ( 
                      ((digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count === 0)) ?
                        <h2 style={{ display: "flex", justifyContent: "center", width: "100%" }}>Please create prescription first for this patient.</h2>
                        :
                        <div style={{ display: "flex", width: "100%", paddingBottom: "32px" }}>
                          <p style={{ width: "45%", margin: "0", height: "40px", backgroundColor: "white", fontSize: "30px", fontWeight: "100" }}>Send Prescription On:</p>
                          <div style={{ width: "55%" }} key={key}>
                            <TextField
                              className="input"
                              name="whatsapp_name"
                              type="string"
                              label="Whatsapp Number"
                              value={prescriptionSentOnWhatsapp}
                              variant="outlined"
                              onWheel={(e: any) => e.target.blur()}
                              onInput={(e: any) => {
                                const inputValue = e.target.value.trim();
                                e.target.value = inputValue.slice(0, 10);
                              }}
                              onBlur={() => verifyMobile()}
                              onFocus={() => {
                                setMobileValidation(true);
                              }}
                              onChange={(e) => setPrescriptionSentOnWhatsapp(e.target.value)}
                              style={{ width: "100%" }}
                            />
                            <sub style={{ color: "red" }}>
                              {!mobileValidation && "Please enter valid mobile number"}
                            </sub>
                          </div>
                        </div>
                    )
                }
                {/* <div className="checkList" style={{ display: "flex" }}>
                  <div style={{ fontSize: "large", fontWeight: "bolder", width: "30%" }}>Send Prescription On:</div>
                  <div>
                    <TextField
                      className="input"
                      name="whatsapp_name"
                      type="number"
                      label="Whatsapp Number"
                      value={prescriptionSentOnWhatsapp}
                      variant="outlined"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onBlur={() => verifyMobile()}
                      onFocus={() => {
                        setMobileValidation(true);
                      }}
                      onChange={(e) => setPrescriptionSentOnWhatsapp(e.target.value)}
                      style={{ width: "70%" }}
                    />
                    <sub style={{ color: "red" }}>
                      {!mobileValidation && "Please enter valid mobile number"}
                    </sub>
                  </div> */}
                  {/* <div style={{ display: "flex" }}>
                    <div style={{ paddingLeft: "20px" }}>
                      <input value={"whatsapp"} type="checkbox" checked={checkedWay === "whatsapp" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedWay("") : setCheckedWay(e.target.value);}} />
                      <span style={{ paddingLeft: "4px" }}>Whatsapp</span>
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <input value={"email"} type="checkbox" checked={checkedWay === "email" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedWay("") : setCheckedWay(e.target.value);}} />
                      <span style={{ paddingLeft: "4px" }}>Email</span>
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <input value={"both"} type="checkbox" checked={checkedWay === "both" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedWay("") : setCheckedWay(e.target.value);}} />
                      <span style={{ paddingLeft: "4px" }}>Both</span>
                    </div>
                  </div> */}
                {/* </div> */}
                {/* </Grid> */}
                {/* {
                  (checkedWay === "both" || checkedWay === "whatsapp") &&
                    <Grid item xs={12} style={{ padding: "20px 0" }}>
                      <TextField
                        className="input"
                        name="whatsapp_name"
                        type="number"
                        label="Whatsapp Number"
                        value={prescriptionSentOnWhatsapp}
                        variant="outlined"
                        onWheel={(e: any) => e.target.blur()}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        onBlur={() => verifyMobile()}
                        onFocus={() => {
                          setMobileValidation(true);
                        }}
                        onChange={(e) => setPrescriptionSentOnWhatsapp(e.target.value)}
                        style={{ width: "100%" }}
                      />
                      <sub style={{ color: "red" }}>
                        {!mobileValidation && "Please enter valid mobile number"}
                      </sub>
                    </Grid>
                } */}
                {/* {
                  (checkedWay === "both" || checkedWay === "email") &&
                    <Grid item xs={12}>
                      <TextField
                        className="input"
                        name="email"
                        type="text"
                        label="Email ID"
                        value={prescriptionSentOnEmail}
                        variant="outlined"
                        onBlur={() => verifyEmail()}
                        onFocus={() => {
                          setEmailValidation(true);
                        }}
                        onChange={(e) => setPrescriptionSentOnEmail(e.target.value)}
                        style={{ width: "100%" }}
                      />
                      <sub style={{ color: "red" }}>
                        {!emailValidation ? "Please enter valid e-mail id" : ""}
                      </sub>
                    </Grid>
                } */}
                <div style={{ display: "flex", margin: "0 37%" }}>
                  <Button
                    variant="contained"
                    style={{
                      marginTop: "20px",
                      width: "100px",
                      marginRight: "5px"
                    }}
                    color="primary"
                    onClick={() => handleSendPrescription()}
                    disabled={prescriptionSentOnWhatsapp === "" || prescriptionSentOnWhatsapp === null || prescriptionSentOnWhatsapp === undefined || !mobileValidation || (digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count === 0)}
                  >
                    Send
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      marginTop: "20px",
                      width: "100px",
                      marginLeft: "5px"
                    }}
                    color="secondary"
                    onClick={handleCanelPrescription}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </>
          </div>
        </Fade>
      </Modal>
      {
        openViewPrescriptionModal &&
          <RenderPDF
            src={digitalPrescriptionListWithBooking && digitalPrescriptionListWithBooking?.count > 0 && digitalPrescriptionListWithBooking?.results[0]?.file}
            open={openViewPrescriptionModal}
            setOpen={setOpenViewPrescriptionModal}
            heading={true}
          />
      }
    </>
  );
};

const mapStateToProps = (state: any) => ({
  sendDigitalPrescriptionToCustomer: state.TechSupportReducer.sendDigitalPrescriptionToCustomer,
  digitalPrescriptionListWithBooking: state.TechSupportReducer.digitalPrescriptionListWithBooking,
  loading: state.TechSupportReducer.loading,
  booking: state.TechSupportReducer.booking,
});

export default connect(mapStateToProps, {
  sendDigitalPrescription,
  getDigitalPrescriptionList,
  getBookingById,
})(SendPrescriptionModal);