export const GENDER_CHOICE = [
  {
    display_name: "Mrs",
    db_name: "Mrs",
    gender: "female",
  },
  {
    display_name: "Miss",
    db_name: "Miss",
    gender: "female",
  },
  {
    display_name: "Ms",
    db_name: "Ms",
    gender: "female",
  },
  {
    display_name: "Smt",
    db_name: "Smt",
    gender: "female",
  },
  {
    display_name: "Mr",
    db_name: "Mr",
    gender: "male",
  },
  {
    display_name: "Baby Boy",
    db_name: "Master",
    gender: "male",
  },
  {
    display_name: "Baby Girl",
    db_name: "Master",
    gender: "female",
  },
];

export const EXECUTIVE_TYPES = [
  {
    text: "Consultation",
    value: "Consultation",
  },
  {
    text: "Escalation",
    value: "Escalation",
  },
  {
    text: "Inbound",
    value: "Inbound",
  },
  {
    text: "LTV",
    value: "LTV",
  },
  {
    text: "Outbound",
    value: "Outbound",
  },
  {
    text: "RCA",
    value: "RCA",
  },
  {
    text: "Resampling",
    value: "Resampling",
  },
  {
    text: "Report",
    value: "Report",
  },
  {
    text: "Ticketing",
    value: "Ticketing",
  },
  {
    text: "CoCal",
    value: "CoCal",
  },
];

export const ADDUSER_EXECUTIVE_TYPES = [
  {
    text: "RCA",
    value: "RCA",
  },
  {
    text: "Resampling",
    value: "Resampling",
  },
  {
    text: "Report",
    value: "Report",
  },
  {
    text: "Refund",
    value: "Refund",
  },
  {
    text: "Admin",
    value: "Admin",
  },
  {
    text: "Discount",
    value: "Discount",
  },
  {
    text: "Ticketing",
    value: "Ticketing",
  },
  {
    text: "Consultation",
    value: "Consultation",
  },
  {
    text: "North Zone",
    value: "North Zone",
  },
  {
    text: "South Zone",
    value: "South Zone",
  },
  {
    text: "West Zone",
    value: "West Zone",
  },
  {
    text: "East Zone",
    value: "East Zone",
  },
  {
    text: "CEGenomics",
    value: "CEGenomics",
  },
  {
    text: "Inbound",
    value: "Inbound",
  },
  {
    text: "Outbound",
    value: "Outbound",
  },
  {
    text: "LTV",
    value: "LTV",
  },
  {
    text: "CoCal",
    value: "CoCal",
  },
  {
    text: "Dialer",
    value: "Dialer",
  },
  {
    text: "OBH1",
    value: "OBH1",
  },
  {
    text: "OBH2",
    value: "OBH2",
  },
  {
    text: "Lab",
    value: "Lab",
  },
  {
    text: "CentralLab",
    value: "CentralLab",
  },
  {
    text: "Escalation",
    value: "Escalation",
  },
  {
    text: "ChatAndEmail",
    value: "ChatAndEmail",
  },
  {
    text: "CASH",
    value: "CASH",
  },
  {
    text: "NONCASH",
    value: "NONCASH",
  },
  {
    text: "ABM/ASM",
    value: "ABM/ASM",
  },
  {
    text: "SE/TM",
    value: "SE/TM",
  },
  {
    text: "RBM/RSM",
    value: "RBM/RSM",
  },
  {
    text: "ZSM",
    value: "ZSM",
  },
  {
    text: "AGM SALES",
    value: "AGM SALES",
  },
  {
    text: "GM SALES",
    value: "GM SALES",
  },
  {
    text: "UpgradedCE",
    value: "UpgradedCE",
  },
  {
    text: "B2B_CX",
    value: "B2B CX",
  },
  {
    text: "InvLabDr",
    value: "Inventory Lab Dr.",
  },
  {
    text: "InvLabService",
    value: "Inventory Lab Service",
  },
  {
    text: "InvLabHead",
    value: "Inventory Lab Head",
  },
  {
    text: "SuperAdmin",
    value: "SuperAdmin",
  },
  {
    text: "BML",
    value: "BML",
  },
  {
    text: "standard",
    value: "Standard",
  },
  {
    text: "corporate_app",
    value: "Corporate App",
  },
  {
    text: "FINANCE",
    value: "Finance",
  },
  {
    text: "OLCP",
    value: "Olc Partner",
  },
  {
    text: "olc",
    value: "olc",
  },
  {
    text: "DSA",
    value: "DSA",
  },
  {
    text: "Manager",
    value: "Manager",
  },
  {
    text: "AreaManager",
    value: "AreaManager",
  },
  {
    text: "PPMC",
    value: "PPMC",
  },
  {
    text: "DRM",
    value: "DRM",
  },
  {
    text: "Contract",
    value: "Contract",
  },
  {
    text: "Auditor",
    value: "Auditor",
  },
  {
    text: "Genomics",
    value: "Genomics",
  },
  {
    text: "GenomicsAdmin",
    value: "GenomicsAdmin",
  },
  {
    text: "Routine",
    value: "Routine",
  },
  {
    text: "RoutineAdmin",
    value: "RoutineAdmin",
  },
  {
    text: "billing",
    value: "billing",
  },
  {
    text: "phlebos",
    value: "phlebos",
  },
  {
    text: "SampleAccessioning",
    value: "SampleAccessioning",
  },
  {
    text: "Others",
    value: "Others",
  },
];
