import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDFZm-xt49cmxl4nu51i01dfe6B7NObI8o",
  authDomain: "redcliffelabs-backend.firebaseapp.com",
  projectId: "redcliffelabs-backend",
  storageBucket: "redcliffelabs-backend.appspot.com",
  messagingSenderId: "633054019343",
  appId: "1:633054019343:web:d0c1ffc1f36b25cb15487a"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
   
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

