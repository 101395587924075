import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  raiseNewComplaint,
  initiateRefund,
} from "../../actions/TechSupportActions";
import { useEffect } from "react";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  open: boolean;
  bookingId: number;
  raiseNewComplaint: any;
  complaint: any;
  leadId: number;
  setOpen: Function;
  totalPrice: number;
  initiateRefund: any;
  booking: any;
  complaints: any;
  getBookingQueryTickets: any;
  bookingComplaints: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  bookingId,
  leadId,
  raiseNewComplaint,
  complaint,
  setOpen,
  totalPrice,
  initiateRefund,
  booking,
  complaints,
  getBookingQueryTickets,
  bookingComplaints,
}) => {
  const classes = useStyles();

  const [query, setQuery] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("none");
  const [disposition, setDisposition] = useState<string>("none");
  const [createBookingId, setCreateBookingId] = useState<any>(bookingId);
  const [createAmount, setCreateAmount] = useState<any>(totalPrice);
  const [bookingType, setBookingType] = useState<any>();
  const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleClose = () => {
    setDisposition("");
    setOpen("");
  };

  useEffect(() => {
    setCreateAmount(totalPrice);
  }, [totalPrice]);

  useEffect(() => {
    setBookingType(booking?.booking_type);
  }, [booking]);

  const raiseComplaint = () => {
    const type =
      bookingId !== 0 ? "booking_ticket" : leadId !== 0 ? "lead_ticket" : "";
    const id = bookingId !== 0 ? bookingId : leadId !== 0 ? leadId : 0;

    const body1: any = {
      status: "pending",
      querytype: query,
      query: description,
      disposition,
    };
    const body2: any = {
      amount: Number(createAmount),
      booking: createBookingId,
      cs_remarks: description,
    };
    if (disposition === "") {
      delete body1.disposition;
    }
    if (disposition !== "refund_query") {
      raiseNewComplaint(type, body1, id);
      setDisposition("none");
      setQuery("");
      setDescription("");
    } else initiateRefund(body2);
    setQuery("none");
    setDescription("");
    setDisposition("");
    handleClose();
  };

  useEffect(() => {
    if (complaint) {
      handleClose();
    }
   
  }, [complaint]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {bookingType === "b2c" ? (
              <>
                <div className={classes.head}>
                  <h4
                    style={{
                      marginBottom: "1rem",
                    }}
                    id="transition-modal-title"
                  >
                    Raise a query ticket
                  </h4>
                  <CloseIcon onClick={() => setOpen(false)} />
                </div>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  
                  spacing={3}
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={12} md={12}>
                    <p>Disposition</p>
                    <Select
                      className="input"
                      name="action"
                      variant="outlined"
                      value={disposition}
                      style={{ width: "100%" }}
                      onChange={(e) => setDisposition(e.target.value as string)}
                    >
                      <MenuItem disabled value={"none"}>
                        Select Disposition
                      </MenuItem>
                      <MenuItem value={"phlebo_delay"}>Phlebo Delay</MenuItem>
                      <MenuItem value={"phlebo_behavior"}>
                        Phlebo Behavior
                      </MenuItem>
                      {/* <MenuItem value={"phlebo_markhold"}>Phlebo Markhold</MenuItem>
                                    <MenuItem value={"resampling"}>Resampling</MenuItem> */}
                      <MenuItem value={"report_related_query"}>
                        Report Tat Breached
                      </MenuItem>
                      <MenuItem value={"report_not_delivered"}>
                        Report Not Delivered
                      </MenuItem>
                      <MenuItem value={"fault_report"}>Fault Report</MenuItem>
                      <MenuItem value={"refund_query"}>Refund Query</MenuItem>
                      <MenuItem value={"doctor_consultation"}>
                        Doctor Consultations
                      </MenuItem>
                      <MenuItem value={"social_media_ticket"}>
                        Social Media Ticket
                      </MenuItem>
                      <MenuItem value={"others"}>Others</MenuItem>
                    </Select>
                  </Grid>
                  {disposition === "refund_query" ? (
                    <>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          className="input"
                          name="name"
                          type="number"
                          onWheel={(e: any) => e.target.blur()}
                          label="Booking Id"
                          value={createBookingId}
                          variant="outlined"
                          onChange={(e) => setCreateBookingId(e.target.value)}
                          style={{ width: "100%" }}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          className="input"
                          name="name"
                          type="number"
                          onWheel={(e: any) => e.target.blur()}
                          label="Amount"
                          inputProps={{ min: 0, max: totalPrice }}
                          value={createAmount}
                          variant="outlined"
                          onKeyDown={(e) => {
                            blockCharInPhoneField(e);
                          }}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            if (inputValue.length === 0) {
                              setCreateAmount(0);
                            } else {
                              setCreateAmount(inputValue);
                            }
                          }}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12}>
                    <TextField
                      name="comment"
                      type="text"
                      multiline
                      rows={3}
                      value={description}
                      className="input"
                      variant="outlined"
                      placeholder="Description"
                      onChange={(e) => setDescription(e.target.value as string)}
                      style={{ width: "100%" }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{ height: "50px" }}
                      onClick={raiseComplaint}
                      disabled={description === "" || disposition === "none"}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <h3
                style={{ color: "red", alignItems: "center", display: "flex" }}
              >
                {" "}
                Doctor's consultation is not allowed for this booking(B2B)
              </h3>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  complaint: state.TechSupportReducer.complaint,
});

export default connect(mapStateToProps, {
  raiseNewComplaint,
  initiateRefund,
})(CommentsModal2);
