import React, { useState, useEffect, useRef } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  MenuItem,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,  Select,
  FormControl,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { connect } from "react-redux";
import CurrencyRupeeTwoToneIcon from "@mui/icons-material/CurrencyRupeeTwoTone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import { getCMSListII, getCmsWallet, getLabCredential } from "../../actions/TechSupportActions";
import BatchIdModel from "../cms/batch";
import CmsAdvPaymentModel from "../cms/advancePayment";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: "90%",
      flexGrow: 21,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    input: {
      padding: "1px",
    },
  })
);

  const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: "#924A91",
        color: theme.palette.common.white,
        fontSize: "12px",
      },
      body: {
        fontSize: 12,
      },
    })
  )(TableCell);
  
  const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);

interface Props {
  loading: boolean;
  getCMSListII: any;
  get_cms_list_II: any;
  getLabCredential: any;
  labCredential: any;
  getCmsWallet: any;
  cmsWalletData: any;
}
const CMS: React.FC<Props> = ({
  loading,
  getCMSListII,
  get_cms_list_II,
  getLabCredential,
  labCredential,
  getCmsWallet,
  cmsWalletData,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const history = useHistory();

  const [cmsId, setCmsID] = useState<any>("");
  const [openBatch, setOpenBatch] = useState<boolean>(false);
  const [batchId, setBatchId] = useState<number>(0);
  const [openBatch1, setOpenBatch1] = useState<boolean>(false);
  const [batchId1, setBatchId1] = useState<number>(0);
  const [cmsListData, setCmsListData] = useState<any>([]);
  const [status, setStatus] = useState<any>("");
  const [lab, setLab] = useState<any>("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [amounts, setAmounts] = useState<string[]>([]);

  useEffect(() => {
    getCMSListII();
    getLabCredential();
    getCmsWallet();
  }, []);

  useEffect(() => {
    const cmsData: any = [];
    if (get_cms_list_II && get_cms_list_II?.results?.length > 0) {
      get_cms_list_II?.results?.map((list: any) => {
        cmsData.push({
          ...list,
        });
      });
    }
    setCmsListData(cmsData);
  }, [get_cms_list_II]);

  const handleClickToBactch = (id: number) => {
    setOpenBatch(true);
    setBatchId(id);
  };

  const handleClickToAdvCms = (id: number) => {
    setOpenBatch1(true);
    setBatchId1(id);
  };

  const filterBookings = (e: any) => {
    e.preventDefault();
    setPage(0);
    getCMSListII(`?id=${cmsId}&lab=${lab}&status=${status}`);
  };

  const resetHandler = () => {
    history.go(0)
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        get_cms_list_II?.links && get_cms_list_II?.links.next.split("?")[1];
      getCMSListII(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        get_cms_list_II?.links && get_cms_list_II?.links.previous.split("?")[1];
      getCMSListII(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };

//   const [cmsEditId, setCmsEditId] = useState<number>(0);
//   const handleCMSEdit = (editId: number) => {
//     setCmsEditId(editId);
//   };

  useEffect(() => {
    if (cmsWalletData.length > 0) {
      const amounts = cmsWalletData.map((data: any) => data.amount);
      const totalAmount = amounts.reduce((num: any, amount: any) => num + amount, 0);
      setAmounts(amounts);
      setTotal(totalAmount);
    } else {
      setAmounts([]);
      setTotal(0);
    }
  }, [cmsWalletData]);

  return (
    <main className={classes.content}>
      {/* <ViewCMS cmsEditId={cmsEditId} setCmsEditId={setCmsEditId} cmsId={cmsId} lab={lab} status={status} page={page} /> */}
      <div className={classes.toolbar} />

      <div style={{ display: "flex", marginBottom: "12px" }}>
        <div
          style={{
            padding: "2px 10px",
            border: "1px solid #924A91",
            borderRadius: "20px",
            maxWidth: "440px",
            width: "100%",
            cursor: "pointer",
            fontSize: "25px",
          }}
          onClick={() => setOpen(true)}
        >
          <CurrencyRupeeTwoToneIcon
            style={{
              color: "#924A91",
              fontSize: "2rem",
              padding: 5,
              border: "1px solid #924A91",
              borderRadius: "50%",
              marginRight: 10,
              marginBottom: 4,
              marginLeft: "auto"
            }}
          />
          CMS Balance ({total})
        </div>
      </div>
      <Grid container spacing={1} direction="row">

        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left", marginTop: "0.5rem" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="cmsId"
              type="number"
              label="CMS ID"
              value={cmsId}
              variant="outlined"
              onChange={(e) => setCmsID(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLab(obj.id);
                }
              }}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getLabCredential(`?name=${newInputValue}`);
                }, 1000);

                if (newInputValue.length === 0) {
                  setLab("");
                }
              }}
              options={labCredential?.results || []}
              blurOnSelect
              aria-required
              freeSolo
              getOptionLabel={(option: any) => `${option?.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="input"
                  label="Lab"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <FormControl style={{ width: "100%" }} className="input">
              <InputLabel>Status</InputLabel>
              <Select
                label="Library QC Status"
                variant="outlined"
                className="input"
                fullWidth
                value={status || "none"}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) => setStatus(e.target.value as string)}
              >
                <MenuItem value="none" disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={resetHandler}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>


      <Grid component={Paper} style={{ padding: "5px", marginTop: "20px" }}>
        <div style={{ width: "100%" }} className="data-table">
          <TableContainer style={{ height: "75vh" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableCell align="center">S No.</StyledTableCell>
                {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                <StyledTableCell align="center">Created Date</StyledTableCell>
                <StyledTableCell align="center">CMS Id</StyledTableCell>
                <StyledTableCell align="center">Lab</StyledTableCell>
                <StyledTableCell align="center">Home DX</StyledTableCell>
                <StyledTableCell align="center">Offline</StyledTableCell>
                <StyledTableCell align="center">CMS Amount</StyledTableCell>
                <StyledTableCell align="center">Lab Amount</StyledTableCell>
                <StyledTableCell align="center">
                  Approved Amount
                </StyledTableCell>
                <StyledTableCell align="center">Pending Amount</StyledTableCell>
                <StyledTableCell align="center">CMS Slip</StyledTableCell>
                <StyledTableCell align="center">
                  Finance Acknowledge
                </StyledTableCell>
                <StyledTableCell align="center">Finance Remark</StyledTableCell>
                <StyledTableCell align="center">Remark</StyledTableCell>
              </TableHead>
              <TableBody>
                {cmsListData.map((cms: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {cms?.status === "pending" ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={cms?.status !== "pending"}
                            onClick={() => handleCMSEdit(cms?.id)}
                          >
                            View CMS
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={cms?.status !== "rejected"}
                            onClick={() => handleCMSEdit(cms?.id)}
                          >
                            View CMS
                          </Button>
                        )}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {moment(cms?.created_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.lab?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant="contained"
                          style={{
                            background: "#0091ea",
                            marginLeft: "1rem",
                            marginBottom: "1rem",
                          }}
                          onClick={() => handleClickToBactch(cms?.id)}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant="contained"
                          style={{
                            background: "#0091ea",
                            marginLeft: "1rem",
                            marginBottom: "1rem",
                          }}
                          onClick={() => handleClickToAdvCms(cms?.id)}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.amount_entered_by_lab}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.amount_entered_by_finance}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.amount - cms?.amount_entered_by_finance}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <a
                          href={cms?.cms_slip}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <VisibilityIcon />
                        </a>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.approver_remarks ? cms?.approver_remarks : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {cms?.creator_remarks
                          ?.split("\n")
                          .slice(1)
                          .map((remark: any, index: any) => (
                            <span key={index}>
                              {index > 1 && ","} {remark.trim()}
                            </span>
                          ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={4}
              count={get_cms_list_II?.count}
              rowsPerPage={get_cms_list_II?.page_size}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </div>
        <BatchIdModel
          openBatch={openBatch}
          setOpenBatch={setOpenBatch}
          batchId={batchId}
        />
        <CmsAdvPaymentModel
          openBatch1={openBatch1}
          setOpenBatch1={setOpenBatch1}
          batchId1={batchId1}
        />
      </Grid>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="lab-wise-amount"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 5,
            padding: "10px",
            maxHeight: "70%",
            height: "100%",
            maxWidth: "70%",
            width: "100%",
            border: "0",
          }}
        >
          <TableContainer style={{ height: "100%", borderRadius: "10px", border: "1px solid lightgrey" }}  >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">S.No</StyledTableCell>
                  <StyledTableCell align="center">Lab Name</StyledTableCell>
                  <StyledTableCell align="center">Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {cmsWalletData.map((item: any, index: any) => {
                  return (
                    <StyledTableRow key={item.id} >
                      <StyledTableCell style={{ height: "45px" }} align="center">{index + 1}</StyledTableCell>
                      <StyledTableCell style={{ height: "45px" }} align="center">{item.lab.name}</StyledTableCell>
                      <StyledTableCell style={{ height: "45px" }} align="center">{item.amount}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  labCredential: state.TechSupportReducer.labCredential,
  get_cms_list_II: state.TechSupportReducer.get_cms_list_II,
  cmsWalletData: state.TechSupportReducer.cmsWalletData,
});

export default connect(mapStateToProps, {
  getLabCredential,
  getCMSListII,
  getCmsWallet,
})(CMS);
