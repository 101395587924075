import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '@mui/material/Button';
import { Chip, TextField, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { createZone, getTimeslots, getCities, getPhlebos } from '../../actions/TechSupportActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useRef } from "react";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    createZone: any;
    area: any;
    getCities: any;
    cities: any;
    getPhlebos: any;
    phleboList: any;
    getTimeslots: any;
    timeslotsList: any;
    open: boolean;
    setOpen: Function;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    setOpen,
    createZone,
    area,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getTimeslots,
    timeslotsList,
}) => {
    const classes = useStyles();
    const [timeslotsIds, setTimeslotsIds] = useState<any>([])
    const [phlebosId, setPhlebosIds] = useState<any>([])
    const [mapMyIndiaId, setMapMyIndiaId] = useState<any>()
    const [city, setCity] = useState(0)
    const [zoneName, setZoneName] = useState<any>()
    const timer = useRef<any>(0)

    const handleClose = () => {
        setOpen(false);
    };

    const createNewZone = async () => {
        const body: any = {
            phlebos: phlebosId,
            city,
            time_slots: timeslotsIds,
            mapmyindiaid: mapMyIndiaId,
            name:zoneName
        }
        await createZone(body)
        handleClose()
    };

    useEffect(() => {
        getCities()
        getTimeslots()
       
    }, [])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Create Zone
                            </h4>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid container direction="row" alignItems="flex-start"  spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    className="input"
                                    name="ZoneName"
                                    placeholder="Zone Name"
                                    variant="outlined"
                                    value={zoneName}
                                    onChange={(e) => setZoneName(e.target.value as string)}
                                    inputProps={{ maxLength: 15 }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Autocomplete
                                    id="timeslots"
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            let slotIds: any = []
                                            newValue.map((val: any) => {
                                                let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                slotIds.push(obj.id)
                                            })
                                            setTimeslotsIds(slotIds)
                                        }
                                    }}
                                    multiple
                                    disableClearable
                                    limitTags={1}

                                    options={timeslotsList.results}
                                    freeSolo
                                    blurOnSelect
                                    getOptionLabel={(option: any) => {
                                        return `${option?.start_time}-${option?.end_time}`
                                    }}
                                    renderTags={(value: string[], getTagProps) =>
                                        value.map((option: any, index: number) => (
                                            <Chip
                                                variant="default"
                                                color="primary"
                                                label={`${option?.start_time}-${option?.end_time}`}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Time Slots"
                                            variant="outlined"
                                            required
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                            className="input"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Autocomplete
                                    id="phlebo"
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            let phleboId: any = []
                                            newValue.map((val: any) => {
                                                let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                phleboId.push(obj.id)
                                            })
                                            setPhlebosIds(phleboId)
                                        }
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current)
                                        timer.current = setTimeout(() => {
                                            getPhlebos(`code=${newInputValue}`)
                                        }, 1000)
                                    }}
                                    multiple
                                    disableClearable
                                    aria-required

                                    limitTags={1}
                                    options={phleboList.results ? [...phleboList.results, { id: 0, user: { name: "No Phlebo" } }] : []}
                                    freeSolo
                                    blurOnSelect
                                    getOptionLabel={(option: any) => option.user && option.user.name}
                                    renderTags={(value: string[], getTagProps) =>
                                        value.map((option: any, index: number) => (
                                            <Chip
                                                variant="default"
                                                color="primary"
                                                label={option.user && option.user.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Phlebo"
                                            variant="outlined"
                                            required
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                            className="input"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Autocomplete
                                    id="city"
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                            setCity(obj.id)
                                        }
                                    }}
                                    options={cities}
                                    freeSolo
                                    blurOnSelect
                                    aria-required
                                    getOptionLabel={(option: any) => option.name}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current)
                                        timer.current = setTimeout(() => {
                                            getCities(newInputValue)
                                        }, 1000)
                                        if (newInputValue.length === 0) {
                                            setCity(0)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField className="input"
                                            {...params}
                                            placeholder="City"
                                            variant="outlined"
                                            required
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    className="input"
                                    name="MapMyIndiaId"
                                    placeholder="MapMyIndiaId"
                                    variant="outlined"
                                    value={mapMyIndiaId}
                                    onChange={(e) => setMapMyIndiaId(e.target.value as string)}
                                    inputProps={{ maxLength: 8 }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={timeslotsIds === "" || phlebosId === "" || city === 0}
                                    style={{ height: "50px" }}
                                    onClick={createNewZone}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    area: state.TechSupportReducer.area,
    timeslotsList: state.TechSupportReducer.timeslotsList,
    cities: state.TechSupportReducer.cities,
    phleboList: state.TechSupportReducer.phleboList,
    loading: state.TechSupportReducer.loading
});

export default connect(mapStateToProps, {
    createZone,
    getCities,
    getPhlebos,
    getTimeslots
})(CommentsModal2);
