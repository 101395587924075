import { ACTIONS } from "../interfaces/notification";
import { ACTIONS as actions } from "../interfaces/notification";
import AXIOS from "../config/Axios";
import Prefix from "../config/ApiPrefix";
import snackBarUpdate from "../actions/snackBarActions";
import SecureStorage from "../config/SecureStorage";

export const messageBox = (title:any, body:any, action:any) => async (
  dispatch: Function
) => {
  //   dispatch({ type: ACTIONS.SHOW_NOTIFICATION, payload: true });
  // const { title, body, action} = body;

  try {
    let data = {
      title: title,
      body: body,
      action: action,
    };
    

    dispatch({ type: ACTIONS.SHOW_NOTIFICATION, payload: data });
  } catch (err) {
    //   let title = "";
    //   if (err.response) {
    //     
    //     title = err.response.data.errors;
    //     

    //   } else {
    //     title = "Something went wrong!";
    //   }
    //   snackBarUpdate({
    //     payload: {
    //       message: title,
    //       status: true,
    //       type: "error",
    //     },
    //   })(dispatch);
    // //   dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
