
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { 
    Grid,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Select,
    MenuItem,
    Dialog,
    DialogContent,
    DialogTitle
 } from '@material-ui/core'
import { Link, useHistory } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Loader from "../components/Loader2/index";
import Button from '@mui/material/Button';
import { postUniqueCustomerManagement, getUHIDsList, getCallRecordingsWithLead } from '../../actions/loginActions';
// import { generateHealthDataFilterUrl } from '../../helpers/generateUrl'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "90%",
            flexGrow: 21,
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        paper1: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        dialog: {
            zIndex: 2,
          },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "2rem",
        },
        tableHead: {
            background: "rgb(146, 74, 145)!important",
        },
        tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
        },
    })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    mergeUniqueCustomer: boolean;
    loading: any;
    postUniqueCustomerManagement: any;
    getUHIDsList:any;
    uhidsList: any;
    getCallRecordingsWithLead: any;
    callRecordingsList: any;
}

const LeadsTable: React.FC<Props> = ({
    mergeUniqueCustomer,
    loading,
    postUniqueCustomerManagement,
    getUHIDsList,
    uhidsList,
    getCallRecordingsWithLead,
    callRecordingsList,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [customer_name, setcustomerName] = useState<any>("");
    const [uhid1, setUhid1] = useState<any>("");
    const [uhid2, setUhid2] = useState<any>("");
    const [uhidName1, setUhidName1] = useState<any>("");
    const [uhidName2, setUhidName2] = useState<any>("");
    const [leadId, setLeadId] = React.useState<any>("");
    const [openConfirmationModal, setOpenConfirmationModal] = useState<any>(false);
    const [openRecording, setOpenRecording] = useState<any>(false);

    useEffect(() => {
        getUHIDsList();
    }, []);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        if (newPage > page) {
            let url = uhidsList && uhidsList?.next.split("?")[1];
            getUHIDsList(url);
        } else if (newPage < page) {
            let url = uhidsList && uhidsList?.previous.split("?")[1];
            getUHIDsList(url);
        }
        setPage(newPage as number);
    };

    // const handleChangePageForAudio = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    //     if (newPage > page) {
    //         let url = uhidsList && uhidsList?.next.split("?")[1];
    //         getCallRecordingsWithLead(url);
    //     } else if (newPage < page) {
    //         let url = uhidsList && uhidsList?.previous.split("?")[1];
    //         getCallRecordingsWithLead(url);
    //     }
    //     setPage(newPage as number);
    // };

    const filterLeadData = () => {
        // const body: any = {
        //     customer_name: customer_name,
        //     start_date: start_date,
        //     end_date: end_date,
        // }
        // const url = generateHealthDataFilterUrl(body).substring(2);
        // getUHIDsList(`${url ? `?${url}&page=1` : "?page=1"}`);
        getUHIDsList(`lead_id=${leadId}`);
        setPage(0);
    };

    document.querySelectorAll("#noTyping").forEach((el) => {
        el.addEventListener("keydown", function (e) {
          e.preventDefault();
        });
    });

    const handleConfirm = () => {

        let body = {
            "main_ucid": uhid2,
            "duplicate_ucid": uhid1,
        };

        setOpenConfirmationModal(false);
        setUhid1("");
        setUhid2("");
        setUhidName1("");
        setUhidName2("");

        postUniqueCustomerManagement(body);
    };

    const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

    return (
        <>
            <main className="data-table" style={{ width: "95%" }}>
                <div className={classes.toolbar} />
                <h2>Merge 2 Unique Customers</h2>
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                    {/* <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="customername"
                            type="text"
                            label="Customer Name"
                            value={customer_name}
                            variant="outlined"
                            onChange={(e) => setcustomerName(e.target.value)}
                            style={{ width: "100%" }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="start_date"
                            id="noTyping"
                            type="date"
                            value={start_date}
                            className="input"
                            label="Start Date"
                            variant="outlined"
                            onChange={(e: any) => setStartDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="endDate"
                            id="noTyping"
                            type="date"
                            value={end_date}
                            className="input"
                            label="End Date"
                            variant="outlined"
                            onChange={(e: any) => setEndDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                        className="input"
                        name="lead"
                        type="number"
                        label="Lead ID"
                        value={leadId}
                        onKeyDown={(e) => blockInvalidChar(e)}
                        variant="outlined"
                        onChange={(e) => setLeadId(e.target.value)}
                        style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            disabled={leadId === ""}
                            onClick={filterLeadData}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/ts/mergeuhid")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Lead ID</StyledTableCell>
                                        <StyledTableCell align="center">Customer Name</StyledTableCell>
                                        <StyledTableCell align="center">UHID</StyledTableCell>
                                        <StyledTableCell align="center">Customer Name 2</StyledTableCell>
                                        <StyledTableCell align="center">UHID 2</StyledTableCell>
                                        <StyledTableCell align="center">Call Recordings</StyledTableCell>
                                        <StyledTableCell align="center">Gender</StyledTableCell>
                                        <StyledTableCell align="center">Score</StyledTableCell>
                                        <StyledTableCell align="center">Merge this Customer into Customer 2</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {uhidsList && uhidsList?.results && uhidsList?.results?.length > 0 && uhidsList?.results.map((item: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index} style={{ backgroundColor: "#ffeb3b" }}>
                                                <StyledTableCell align="center">{item?.LEAD_ID}</StyledTableCell>
                                                <StyledTableCell align="center">{item?.CUSTOMER_NAME}</StyledTableCell>
                                                <StyledTableCell align="center">{item?.ID}</StyledTableCell>
                                                <StyledTableCell align="center">{item?.CUSTOMER_NAME_2}</StyledTableCell>
                                                <StyledTableCell align="center">{item?.ID_2}</StyledTableCell>
                                                <StyledTableCell align="center" onClick={() => {
                                                    setOpenRecording(true);
                                                    getCallRecordingsWithLead(item?.LEAD_ID);
                                                }}>
                                                    <Button variant="contained">
                                                        Listen Audio
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{item?.GENDER}</StyledTableCell>
                                                <StyledTableCell align="center">{item?.SCORE}</StyledTableCell>
                                                <StyledTableCell align="center" id="customSelectHealth">
                                                    <Select
                                                        className="input"
                                                        name="priority"
                                                        variant="outlined"
                                                        style={{
                                                            width: "160px",
                                                            alignItems: "flex-start",
                                                            height: "45px",
                                                            marginRight: "5px !important",
                                                        }}
                                                        value={uhid1}
                                                        onChange={(e: any) => {
                                                            setUhid1(e.target.value)
                                                            if (e.target.value === item?.ID) {
                                                                setUhid2(item?.ID_2);
                                                                setUhidName2(item?.CUSTOMER_NAME_2);
                                                                setUhidName1(item?.CUSTOMER_NAME);
                                                            }
                                                            if (e.target.value === item?.ID_2) {
                                                                setUhid2(item?.ID);
                                                                setUhidName2(item?.CUSTOMER_NAME);
                                                                setUhidName1(item?.CUSTOMER_NAME_2);
                                                            }
                                                        }}
                                                        >
                                                        <MenuItem value={item?.ID}>{item?.CUSTOMER_NAME}</MenuItem>
                                                        <MenuItem value={item?.ID_2}>{item?.CUSTOMER_NAME_2}</MenuItem>
                                                    </Select>
                                                    <Select
                                                        className="input"
                                                        name="priority"
                                                        variant="outlined"
                                                        style={{
                                                            width: "160px",
                                                            alignItems: "flex-start",
                                                            height: "45px",
                                                            marginRight: "5px !important",
                                                        }}
                                                        value={uhid2}
                                                        disabled
                                                        onChange={(e: any) => setUhid2(e.target.value)}
                                                        >
                                                        <MenuItem value={item?.ID}>{item?.CUSTOMER_NAME}</MenuItem>
                                                        <MenuItem value={item?.ID_2}>{item?.CUSTOMER_NAME_2}</MenuItem>
                                                    </Select>
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        disabled={ uhid1 !== item?.ID && uhid1 !== item?.ID_2}
                                                        onClick={() => setOpenConfirmationModal(true)}
                                                    >
                                                        Merge
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={4}
                                        count={uhidsList?.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={uhidsList?.page_size || 0}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div>
                <Dialog
                    open={openConfirmationModal}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle id="form-dialog-title">
                    <div
                        style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#d8d2d24a", padding: "10px" }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ marginBottom: "14px" }}>Merge 2 Unique Customers</div>
                        </div>
                        <div>
                        <CloseIcon onClick={() => {
                            setOpenConfirmationModal(false);
                        }} />
                        </div>
                    </div>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ height: "200px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <h2 style={{ fontSize: "20px", fontFamily: "monospace", fontWeight: "100", display: "flex", justifyContent: "center", paddingTop: "30px" }}>
                                You're merging <span style={{ fontSize: "20px", fontFamily: "monospace", fontWeight: "700" }}>&nbsp;{uhidName1}</span> (UHID: <span style={{ fontSize: "20px", fontFamily: "monospace", fontWeight: "700" }}>{uhid1}</span>) into <span style={{ fontSize: "20px", fontFamily: "monospace", fontWeight: "700" }}>&nbsp;{uhidName2}</span> (UHID: <span style={{ fontSize: "20px", fontFamily: "monospace", fontWeight: "700" }}>{uhid2}</span>)
                            </h2>
                            <h2 style={{ fontSize: "20px", fontFamily: "monospace", fontWeight: "100", display: "flex", justifyContent: "center", paddingTop: "20px", paddingBottom: "30px", color: "#d00606" }}>
                                This <span style={{ fontSize: "20px", fontFamily: "monospace", fontWeight: "700" }}>&nbsp;{uhidName1}</span> (UHID: <span style={{ fontSize: "20px", fontFamily: "monospace", fontWeight: "700" }}>{uhid1}</span>) will be deleted.
                            </h2>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className="r-cl" style={{ display: "flex", paddingRight: "10px", paddingBottom: "50px" }}>
                                    <div style={{ paddingRight: "20px", paddingTop: "10px" }}>Please Click on Confirm To Proceed.</div>
                                    <Button
                                        style={{
                                            background: "rgb(236 236 27 / 93%)",
                                            color: "black",
                                        }}
                                        variant="contained"
                                        onClick={handleConfirm}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openRecording}
                    onClose={() => setOpenRecording(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openRecording}>
                        <div className={classes.paper}>
                            <div className={classes.head}>

                            </div>
                                <>
                                    <TableContainer
                                        className={classes.tableContainer}
                                        component={Paper}
                                        style={{ maxHeight: "560px", height: "600px" }}
                                    >
                                        <Table stickyHeader aria-label="simple table">
                                            <TableHead className={classes.tableHead}>
                                                <StyledTableRow>
                                                    <StyledTableCell className={classes.tableHeadCell}>
                                                        Audio Call
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                            {callRecordingsList?.results && callRecordingsList?.results?.length > 0 ? callRecordingsList?.results?.map((list: any) => {
                                                return (
                                                    <StyledTableRow key={list.id}>
                                                        <StyledTableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {list.uploaded_url ? (
                                                                <audio controls muted>
                                                                    <source src={list.uploaded_url} type="audio/wav" />
                                                                    Your browser does not support the
                                                                    <code>audio</code> element.
                                                                </audio>
                                                            ) : (
                                                                "N/A"
                                                            )}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            }) : "No Call Recordings Available."}
                                            </TableBody>
                                            {/* <TableFooter>
                                                <TablePagination
                                                    rowsPerPageOptions={[]}
                                                    colSpan={3}
                                                    count={callRecordingsList?.count || 0}
                                                    rowsPerPage={callRecordingsList?.page_size}
                                                    page={page}
                                                    onPageChange={handleChangePageForAudio}
                                                />
                                            </TableFooter> */}
                                        </Table>
                                    </TableContainer>
                                </>
                        </div>
                    </Fade>
                </Modal>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.loginReducer.loading,
    uhidsList: state.loginReducer.uhidsList,
    mergeUniqueCustomer: state.loginReducer.mergeUniqueCustomer,
    callRecordingsList: state.loginReducer.callRecordingsList,
});

export default connect(mapStateToProps, {
    postUniqueCustomerManagement,
    getUHIDsList,
    getCallRecordingsWithLead,
})(LeadsTable);