import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import {
  getPinCode,
  getPackage,
  getBookingById,
  getCoupons,
  getAvailableSlots,
  createAdditionalBooking,
  resetBookings,
} from "../../../actions/TechSupportActions";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ACTIONS } from "../../../interfaces/actionTypes/TechSupportType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

interface Props {
  getPinCode: any;
  pin_code: any;
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  createAdditionalBooking: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
  resetBookings: any;
  lead_details: any;
}

const EditBookingForm: React.FC<Props> = ({
  getPinCode,
  pin_code,
  packageList,
  getPackage,
  couponList,
  getCoupons,
  createAdditionalBooking,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  loading,
  resetBookings,
  lead_details,
}) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const history = useHistory();
  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [pincode, setPinCode] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.pincode
  );
  const [zoneName, setZoneName] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.area
  );
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [customer_name, setCustomer_name] = useState<String>("");
  const [customer_designation, setCustomer_designation] = useState<String>("");
  const [customer_age, setCustomer_age] = useState<number>();
  const [customer_gender, setCustomer_gender] = useState<String>("");
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking.customer_phonenumber
  );
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [customer_areaname, setCustomer_areaname] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.area
  );
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>("");
  const [parentPackageDetails, setParentPackageDetails] = useState<any>([]);
  const [packageDetails, setPackageDetails] = useState<any>([]);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [packages, setPackages] = useState<Number[]>(
    booking.packages &&
      booking.packages.map((pack: any) => {
        return pack.id;
      })
  );
  const [coupon, setCoupon] = useState<Number>(0);
  const [findSelectedSlot, setFindSelectedSlot] = useState<any>();
  const [phleboCost, setPhleboCost] = useState<any>(0);
  const [phleboCostMainBooking, setPhleboCostMainBooking] = useState<any>(0);
  const [phleboCostOld, setPhleboCostOld] = useState<any>(0);
  const [minAmountPhleboCost, setMinAmountPhleboCost] = useState<any>(0);
  const [alreadyARedPro, setAlreadyARedPro] = useState<any>(false);
  const [refresh, setRefresh] = useState<any>(false);
  const [redProMembershipDiscount, setRedProMembershipDiscount] =
    useState<any>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [tempTotalPrice, setTempTotalPrice] = useState<number>(0);
  const [tempPrice, setTempPrice] = useState<number>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [cityId, setCityId] = useState<any>(0);
  const [packKey, setPackKey] = useState<any>(0);
  const [zoneId, setZoneId] = useState<any>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [passport_number, setPassportNumber] = useState<String>("");
  const [aadhar, setAadhar] = useState<any>("");
  const [bookingFor, setBookingFor] = useState<any>("");
  const [isPrescribed, setIsPrescribed] = useState<any>("");
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const bookingId = match.params.id;
  const timer = useRef<any>(0);

  useEffect(() => {
    resetBookings();
    getCoupons();
    dispatch({ type: ACTIONS.GET_BOOKING_BY_ID, payload: [] });
  }, []);

  useEffect(() => {
    getBookingById(`${bookingId}`);
  }, [bookingId]);

  useEffect(() => {
    if (
      booking?.pk == bookingId &&
      booking?.cityid &&
      pincode !== booking?.customer_areapincode?.id
    ) {
      setCollection_date(booking?.collection_date);
      setPinCode(
        booking?.customer_areapincode && booking.customer_areapincode.id
      );
      setZoneName(
        booking.customer_areapincode && booking.customer_areapincode.area
      );
      setSelectedSlot(booking?.collection_slot && booking?.collection_slot?.id);
      setCustomer_phonenumber(booking.customer_phonenumber);
      setCustomer_alternatenumber(booking.customer_altphonenumber);
      setCustomer_whatsapppnumber(booking.customer_whatsapppnumber);
      setCustomer_email(booking.customer_email);
      getPinCode(
        booking?.customer_areapincode &&
          `${booking?.customer_areapincode?.pincode}&show_code=true`
      );
    }
  }, [booking]);

  useEffect(() => {
    if (
      booking?.pk == bookingId &&
      booking?.cityid &&
      cityId !== booking?.cityid
    ) {
      booking?.coupon_applied && setCoupon(booking?.coupon_applied.id);
      booking?.coupon_applied && setCouponCode(booking?.coupon_applied?.name);
      booking?.discounted_price &&
        setTotalPrice(booking.discounted_price?.final_total_price);
      booking?.discounted_price &&
        setTempTotalPrice(booking.discounted_price?.final_total_price);
      booking?.discounted_price &&
        setTempPrice(booking.discounted_price?.total_price_package);
      booking.discounted_price &&
        setPrice(booking.discounted_price?.total_price_package);
      booking.discounted_price &&
        setPhleboCostMainBooking(booking.discounted_price?.phlebo_cost);
      booking.packages && setParentPackageDetails(booking.packages);
      booking.cityid && setCityId(booking.cityid);
      booking.cityid && getPackage(`city_id=${booking.cityid}`);
    }
  }, [booking]);

  useEffect(() => {
    booking?.customer_phonenumber &&
      setCustomer_phonenumber(booking?.customer_phonenumber);
    booking?.customer_altphonenumber &&
      setCustomer_alternatenumber(booking?.customer_altphonenumber);
    booking?.customer_whatsapppnumber &&
      setCustomer_whatsapppnumber(booking?.customer_whatsapppnumber);
  }, [booking]);

  useEffect(() => {
    if (
      lead_details &&
      lead_details?.subscriptions?.length > 0 &&
      lead_details?.subscriptions[0]?.discount > 0
    ) {
      setAlreadyARedPro(true);
      if (lead_details?.booking_count > 10) {
        setRedProMembershipDiscount(0);
      } else {
        if (booking?.discounted_price?.subscription_amount > 0) {
          setRedProMembershipDiscount(Math.floor((price / 100) * 10));
        } else if (booking?.discounted_price?.subscription_amount === 0) {
          setRedProMembershipDiscount(
            Math.floor((price / 100) * lead_details?.subscriptions[0]?.discount)
          );
        }
      }
    }
  }, [lead_details, price]);

  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);

  useEffect(() => {
    if (selectedSlot > 0 && timeSlots !== undefined) {
      const findingSelectedSlot = timeSlots.filter(
        (x: any) => x.id === selectedSlot
      );
      setFindSelectedSlot(findingSelectedSlot);
    }
  }, [selectedSlot, timeSlots]);

  useEffect(() => {
    if (
      findSelectedSlot &&
      findSelectedSlot?.length > 0 &&
      findSelectedSlot[0]?.id > -1
    ) {
      setPhleboCost(findSelectedSlot[0]?.phlebo_cost);
      findSelectedSlot[0]?.phlebo_cost === 0
        ? setExtraCharge(false)
        : setExtraCharge(true);
      setPhleboCostOld(findSelectedSlot[0]?.phlebo_cost);
      setMinAmountPhleboCost(findSelectedSlot[0]?.minimum_amount_phlebo_cost);
    }
  }, [findSelectedSlot]);

  useEffect(() => {
    if (booking?.customer_gender !== undefined || pin_code?.length > 0) {
      getArea(
        booking?.collection_date,
        booking?.customer_areapincode && booking?.customer_areapincode?.pincode
      );
      setCustomer_areapincode(booking?.customer_areapincode?.id);
    }
  }, [pin_code]);

  const getArea = (collection_date: String, pincode: String) => {
    const zone =
      pin_code.results &&
      pin_code.results.find((code: any) => code.pincode === pincode);
    setZoneName(zone && (zone.area as String));
    if (zone && zone.zone_data && zone.zone_data.id) {
      getAvailableSlots(
        collection_date,
        zone && zone.zone_data && zone.zone_data.id
      );
    }
    setCustomer_areapincode(zone && (zone.id as Number));
    setCustomer_city(zone && (zone.city as String));
    setZoneId(zone && zone.zone_data && zone.zone_data.id);
  };

  // const checkNormalPackage = (packages: any) => {
  //   let isNormalPackage = false;
  //   packages &&
  //     packages.length > 0 &&
  //     packages.map((item: any) => {
  //       if (!item.is_addon) {
  //         isNormalPackage = true;
  //       }
  //     });
  //   return isNormalPackage;
  // };

  const handlePackages = (customePackage: any) => {
    const min_phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].min_booking_amount_for_phlebo;
    const phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].phlebo_cost;
    setRefresh(!refresh);
    setPackageDetails(customePackage);
    let accumulatedPrice: any = 0;
    let packIds: any = [];
    if (customePackage && customePackage.length > 0) {
      // let atLeastOneNormalPackage = checkNormalPackage(customePackage);
      customePackage.map((item: any) => {
        //find from parent package for addtions pages
        const findPackage = parentPackageDetails.filter(
          (el: any) => el.id == item.id
        );
        if (
          findPackage &&
          findPackage.length > 0 &&
          item.code == "PL50" &&
          findPackage[0]?.code == "PL50"
        ) {
          packIds.push(item.id);
          accumulatedPrice = 0;
        } else {
          packIds.push(item.id);
          // if (
          //   atLeastOneNormalPackage &&
          //   item.is_addon &&
          //   item.addon_price &&
          //   item.addon_price > 0
          // ) {
          //   accumulatedPrice = accumulatedPrice + item.addon_price;
          // } else {
          if (item.package_city_prices === null) {
            accumulatedPrice = accumulatedPrice + item.offer_price;
          } else {
            accumulatedPrice =
              accumulatedPrice + item.package_city_prices.offer_price;
          }
          // if (accumulatedPrice > min_phlebo_cost) {
          //     setExtraCharge(false);
          // } else {
          //     setPhleboCost(phlebo_cost)
          //     setExtraCharge(true);
          // }
        }
        // }
      });
    }

    let familyPrice: number = Math.floor(accumulatedPrice);
    let totalPriceWithFamily = tempTotalPrice + familyPrice;
    let priceWithFamily = tempPrice + familyPrice;

    if (!alreadyARedPro) {
      if (priceWithFamily < minAmountPhleboCost && minAmountPhleboCost > 0) {
        setExtraCharge(true);
        setPhleboCost(phleboCostOld);
      } else {
        setPhleboCost(0);
        setExtraCharge(false);
      }
    } else {
      if (priceWithFamily <= 199) {
        setPhleboCost(phleboCostOld);
        setExtraCharge(true);
      } else {
        setPhleboCost(0); // Doing 0 as per Pradeep Sir
        setExtraCharge(false);
      }
    }

    setTotalPrice(totalPriceWithFamily);
    setPrice(priceWithFamily);
    setPackages(packIds);
  };

  useEffect(() => {
    if (extraCharge) {
      setPrice(price + phleboCost);
    }
  }, [extraCharge]);

  useEffect(() => {
    if (price >= minAmountPhleboCost && minAmountPhleboCost > 0) {
      setPhleboCost(0);
    }
  }, [price]);

  // useEffect(() => {
  //   if (!alreadyARedPro) {
  //     if (price < minAmountPhleboCost && minAmountPhleboCost > 0) {
  //       setExtraCharge(true);
  //       setPhleboCost(phleboCostOld);
  //       setPrice(price+phleboCostOld);
  //     } else {
  //       setPhleboCost(0);
  //       setExtraCharge(false);
  //     }
  //   } else {
  //     if (totalPrice <= 199) {
  //       setPhleboCost(phleboCostOld);
  //       setExtraCharge(true);
  //       setPrice(price+phleboCostOld);
  //     } else {
  //       setPhleboCost(0);  // Doing 0 as per Pradeep Sir
  //       setExtraCharge(false);
  //     }
  //   }
  // }, [phleboCost, refresh])

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      customer_aadhar: aadhar,
      passport_number: passport_number,
      is_prescription: isPrescribed,
      book_for: bookingFor,
      customer_age,
      customer_gender,
      customer_name,
      collection_slot: selectedSlot,
      designation: customer_designation,
      packages: packageDetails.map((pack: any) => {
        return pack.id;
      }),
    };
    await createAdditionalBooking(bookingId, data);
    history.push(`/dashboard/ts/booking-view/${bookingId}`);
  };
  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setPhleboCost(0);
    setMinAmountPhleboCost(0);
    setCollection_date(e.target.value as string);
    if (zoneId) {
      getAvailableSlots(e.target.value, zoneId);
      getPackage(`city_id=${cityId}`);
    }
  };
  useEffect(() => {
    if (customer_designation === "Mr" || customer_designation === "Baby Boy") {
      setCustomer_gender("male");
    } else if (
      customer_designation === "Mrs" ||
      customer_designation === "Baby Girl" ||
      customer_designation === "Miss" ||
      customer_designation === "Ms" ||
      customer_designation === "Smt"
    ) {
      setCustomer_gender("female");
    }
  }, [customer_designation]);
  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <h2>Add Family Member in Booking ID - {bookingId} </h2>
      </div>
      <Paper className={classes.paper} elevation={15}>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Booking date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={booking?.booking_date || ""}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px",
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Sample Collection date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={
                      (collection_date && collection_date.toString()) || ""
                    }
                    // onChange={(e) => setCollection_date(e.target.value as string)}
                    onChange={(e) => changeCollectionDate(e)}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px",
                    }}
                    id="noTyping"
                    max={`${new Date().getFullYear() + 1}-${
                      new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                    }-${
                      new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                    }`}
                    // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="sampleCollectionTime"
                style={{ height: "100%", overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                <div className={classes.slotContainer}>
                  {timeSlots &&
                    timeSlots.length > 0 &&
                    timeSlots.map((slot: any) => {
                      return (
                        slot.available_slots > 0 && (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            // onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time1hr}-{slot.end_time1hr}
                            </p>
                            {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                            <p style={{ textAlign: "center", color: "green" }}>
                              {" "}
                              Available slots: {slot.available_slots}
                            </p>
                          </Card>
                        )
                      );
                    })}
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{ margin: "1rem auto", background: "#e1f5fe" }}
          >
            <Grid item md={2}>
              <p>Title</p>
              <Select
                className="input"
                name="customer_designation"
                variant="outlined"
                value={customer_designation}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) =>
                  setCustomer_designation(e.target.value as String)
                }
                required
              >
                <MenuItem value="Mr">Mr</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
                <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                <MenuItem value="Baby Girl">Baby Girl</MenuItem>
              </Select>
            </Grid>
            <Grid item md={3}>
              <p>Customer Name</p>
              <TextField
                name="customer_name"
                type="text"
                placeholder="Enter Name"
                value={customer_name}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) =>
                  setCustomer_name(
                    e.target.value.replace(/[^A-Za-z- ']/, "") as String
                  )
                }
                // onChange={validateCustomerName}
                required
              />
            </Grid>
            <Grid item md={2}>
              <p>Age</p>
              <TextField
                name="customer_age"
                type="number"
                placeholder="Enter Age"
                value={customer_age}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                inputProps={{ min: 0, max: 150 }}
                onChange={(e) => {
                  let ageValue = parseInt(e.target.value);
                  if (ageValue > 150) ageValue = 150;
                  if (ageValue < 0) ageValue = 0;
                  e.target.value = ageValue.toString();
                  setCustomer_age(e.target.value as any);
                }}
                required
              />
            </Grid>
            <Grid item md={2}>
              <p>Gender</p>
              <TextField
                className="input"
                name="customer_gender"
                variant="outlined"
                value={
                  customer_gender === "male"
                    ? "Male"
                    : customer_gender === "female"
                    ? "Female"
                    : ""
                }
                style={{
                  width: "100%",
                  margin: "0",
                  padding: "0",
                  height: "40px",
                }}
                // onChange={(e) => setCustomer_gender(e.target.value as String)}
                disabled
                required
              />
            </Grid>
            <Grid item md={3}>
              <p>Package Name</p>
              <Autocomplete
                id="package"
                key={packageKey}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let packs: any = [];
                    newValue.map((val: any) => {
                      let obj = JSON.parse(JSON.stringify(val, null, " "));
                      packs.push(obj);
                    });
                    handlePackages(packs);
                    setPackKey(1);
                  }
                }}
                multiple
                options={packageList?.results || []}
                value={packageDetails}
                disableClearable
                limitTags={0}
                getOptionLabel={(option: any) =>
                  option?.name && option?.package_city_prices
                    ? option?.name.toString() +
                      ", " +
                      option?.package_city_prices?.tat_time +
                      ", Rs. " +
                      option?.package_city_prices?.offer_price +
                      "/-"
                    : option?.name +
                      ", " +
                      option?.tat_time +
                      ", Rs. " +
                      option?.offer_price +
                      "/-"
                }
                getOptionDisabled={(option: any) => {
                  const packID = packageDetails.map((pack: any) => pack.id);
                  return packID.includes(option?.id);
                }}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPackage(`city_id=${cityId}&search=${newInputValue}`);
                  }, 1000);
                }}
                freeSolo
                disableCloseOnSelect
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="default"
                      color="primary"
                      label={
                        option?.package_city_prices
                          ? option?.name?.toString() +
                            ", " +
                            option?.package_city_prices?.tat_time +
                            ", Rs. " +
                            option?.package_city_prices?.offer_price +
                            "/-"
                          : option?.name?.toString() +
                            ", " +
                            option?.tat_time +
                            ", Rs." +
                            option?.offer_price +
                            "/-"
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Package"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    className="input"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                    required={packageDetails?.length === 0 ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <p>Passport</p>
              <TextField
                className="input"
                name="passport"
                type="text"
                placeholder="Enter Passport Number"
                value={passport_number}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e: any) => setPassportNumber(e.target.value)}
              />
            </Grid>
            <Grid item md={3}>
              <p>Aadhaar Number</p>
              <TextField
                className="input"
                name="aadhar"
                type="number"
                onWheel={(e: any) => e.target.blur()}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 12);
                }}
                placeholder="Enter Aadhaar Number"
                value={aadhar}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e: any) => setAadhar(e.target.value)}
              />
            </Grid>
            <Grid item md={3}>
              <p>Booking For</p>
              <Select
                className="input"
                name="bookingFor"
                variant="outlined"
                value={bookingFor}
                style={{ width: "100%", margin: "0", height: "40px" }}
                required
                onChange={(e: any) => setBookingFor(e.target.value)}
              >
                <MenuItem value="self">Self</MenuItem>
                <MenuItem value="father_in_law">Father/Father in law</MenuItem>
                <MenuItem value="mother_in_law">Mother/Mother in law</MenuItem>
                <MenuItem value="son_in_law">Son/Son in law</MenuItem>
                <MenuItem value="daughter_in_law">
                  Daughter/Daughter in law
                </MenuItem>
                <MenuItem value="spouse">Spouse</MenuItem>
                <MenuItem value="friend">Friend</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </Grid>
            <Grid item md={3}>
              <p>Is Prescribed</p>
              <Select
                className="input"
                name="is_prescribed"
                variant="outlined"
                value={isPrescribed}
                style={{ width: "100%", margin: "0", height: "40px" }}
                onChange={(e: any) => setIsPrescribed(e.target.value)}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_phonenumber"
                    type="text"
                    value={customer_phonenumber}
                    placeholder="Enter Mobile Number"
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Alternate Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_alternatenumber"
                    type="text"
                    value={customer_alternatenumber}
                    placeholder="Enter Alternate Mobile Number"
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Email</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_email"
                    type="email"
                    placeholder="Enter Email"
                    value={customer_email}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Whatsapp Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_whatsapppnumber"
                    type="text"
                    placeholder="Enter Whatsapp Number"
                    value={customer_whatsapppnumber}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
              <div className="priceDetails" style={{ padding: "1rem" }}>
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell">Customer Name</td>
                      <td className="cell">Packages</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="cell">{customer_name}</td>
                      <td className="cell">
                        <ul>
                          {packageDetails.map((pack: any, index: any) => {
                            return (
                              <li key={index}>
                                {pack.name}
                                <br />
                                DESCRIPTION : {pack.description}
                                <br />
                                TaT:{" "}
                                {pack.package_city_prices
                                  ? pack.package_city_prices.tat_time
                                  : pack.tat_time}
                                <br />
                                Price:{" "}
                                {pack.package_city_prices
                                  ? pack.package_city_prices.offer_price
                                  : pack.offer_price}
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="cell">{booking.customer_name}</td>
                      <td className="cell">
                        <ul>
                          {booking.packages &&
                            booking.packages.map((pack: any, index: any) => {
                              return (
                                <li key={index}>
                                  {pack.name}
                                  <br />
                                  DESCRIPTION : {pack.description}
                                  <br />
                                  TaT:{" "}
                                  {pack.package_city_prices
                                    ? pack.package_city_prices.tat_time
                                    : pack.tat_time}
                                  <br />
                                  Price:{" "}
                                  {pack.package_city_prices
                                    ? pack.package_city_prices.offer_price
                                    : pack.offer_price}
                                </li>
                              );
                            })}
                        </ul>
                      </td>
                    </tr>
                    {booking.additional_members &&
                      booking.additional_members.map(
                        (member: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="cell">{member.customer_name}</td>
                              <td className="cell">
                                <ul>
                                  {member.packages &&
                                    member.packages.map(
                                      (pack: any, index: any) => {
                                        return (
                                          <li key={index}>
                                            {pack.name}
                                            <br />
                                            DESCRIPTION : {pack.description}
                                            <br />
                                            TaT:{" "}
                                            {pack.package_city_prices
                                              ? pack.package_city_prices
                                                  .tat_time
                                              : pack.tat_time}
                                            <br />
                                            Price:{" "}
                                            {pack.package_city_prices
                                              ? pack.package_city_prices
                                                  .offer_price
                                              : pack.offer_price}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
              <div
                className="priceDetails"
                style={{ padding: "1rem 1rem 0 1rem" }}
              >
                <h6 className="totalPrice">
                  Price: ₹ {Math.floor(price)}{" "}
                  {extraCharge && phleboCostMainBooking === 0 && phleboCost > 0
                    ? `+${phleboCost}`
                    : ""}
                </h6>
                {price >= minAmountPhleboCost ? (
                  <h6 className="totalPrice">Total Amount: ₹ {price}</h6>
                ) : (
                  <h6 className="totalPrice">
                    Total Amount: ₹{" "}
                    {phleboCost > 0 && phleboCostMainBooking === 0
                      ? totalPrice + phleboCost
                      : totalPrice}
                  </h6>
                )}
              </div>
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={
                loading ||
                !selectedSlot ||
                packageDetails?.length === 0 ||
                customer_name.trim().length === 0
              }
            >
              Add Family
            </Button>
          </div>
        </form>
      </Paper>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.TechSupportReducer.pin_code,
  packageList: state.TechSupportReducer.packageList,
  couponList: state.TechSupportReducer.couponList,
  booking: state.TechSupportReducer.booking,
  booking_slots: state.TechSupportReducer.booking_slots,
  loading: state.TechSupportReducer.loading,
  lead_details: state.TechSupportReducer.lead_details,
});

export default connect(mapStateToProps, {
  getPinCode,
  getPackage,
  getCoupons,
  getBookingById,
  getAvailableSlots,
  createAdditionalBooking,
  resetBookings,
})(EditBookingForm);
