import React from "react";
import TechSupportDashboardLeft from "../../TechSupport-Panel/dashboard-left";
import TechSupportDashboardRight from "../../TechSupport-Panel/dashboard-right";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const QualityAgentDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <TechSupportDashboardLeft />
            <TechSupportDashboardRight>{children && children}</TechSupportDashboardRight>
        </div>
    );
};

export default QualityAgentDash;

