import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useRef } from "react";
import Loader from "../../components/loader";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getCmsAdvancePayment, getCmsList } from "../../actions/TechSupportActions";

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
            padding: "5px",
            textTransform: "capitalize",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            width: "90%",
            flexGrow: 21,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        root: {
            flexGrow: 1,
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 10px 0px",
        },
        contentFilter: {
            width: "100%",
            padding: "0px 10px 10px 0px",
        },
        contentTable: {
            width: "100%",
            padding: "0px 0px 10px 0px",

            marginTop: "20px",
            minHeight: "300px",
        },

        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto",
        },
        tableRow: {
            border: "none",
            padding: "5px",
            textTransform: "capitalize",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    })
);

interface Props {
    getCmsAdvancePayment: any;
    fetchCmsAdvPayment: any;
    setOpenBatch1: Function;
    openBatch1: boolean;
    batchId1: number;
    getCmsList: any;
    fetchCmsList: any;
}
const CMS: React.FC<Props> = ({
    getCmsAdvancePayment,
    fetchCmsAdvPayment,
    setOpenBatch1,
    openBatch1,
    batchId1,
    getCmsList,
    fetchCmsList,


}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const { id } = useParams<{ id: string }>();
    const [page, setPage] = useState(0);
    useEffect(() => {
        if (batchId1) {
            getCmsList(`${batchId1}`)
        }
    }, [batchId1]);

    const [cmsListData, setCmsListData] = useState<any>([])
    useEffect(() => {
        const cmsData: any = [];
        if (fetchCmsList && fetchCmsList?.results?.length > 0 && fetchCmsList?.results?.flatMap((sample: any) => {
            sample?.advance_payments?.length > 0 && sample?.advance_payments?.map((list: any) => {
                cmsData.push({
                    ...list
                })
            })
        })) {
        }
        setCmsListData(cmsData);
    }, [fetchCmsList]);



    const handleClose = () => {
        setOpenBatch1(false);
    };
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = fetchCmsList?.links && fetchCmsList?.links.next.split("?")[1];
            getCmsAdvancePayment(url);
        } else if (newPage < page) {
            let url = fetchCmsList?.links && fetchCmsList?.links.previous.split("?")[1];
            getCmsAdvancePayment(url);
        }
        setPage(newPage as number);
    };
    return (
        <div style={{ width: "100%" }}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openBatch1}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openBatch1} style={{ margin: 20, width: "1300px" }}>
                    <Grid component={Paper} style={{ padding: "5px" }}>
                        <h6>Total Amount:{fetchCmsAdvPayment?.total_amount}</h6>
                        <div style={{ width: "100%" }} className="data-table">
                            <TableContainer style={{ height: "70vh" }}>
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">Id</StyledTableCell>
                                            <StyledTableCell align="center">Center Name (Code)</StyledTableCell>
                                            <StyledTableCell align="center">Amount</StyledTableCell>

                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cmsListData.map((cms: any, index: any) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="center">
                                                        {cms?.id}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {`${cms?.center__name} (${cms?.center__center_code})`}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {cms?.amount}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={4}
                                    count={fetchCmsList?.count}
                                    rowsPerPage={fetchCmsList?.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </TableContainer>
                        </div>
                    </Grid>


                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    fetchCmsAdvPayment: state.TechSupportReducer.fetchCmsAdvPayment,
    fetchCmsList: state.TechSupportReducer.fetchCmsList
});

export default connect(mapStateToProps, {
    getCmsAdvancePayment,
    getCmsList,
})(CMS);