import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import "./index.sass";
import LoginForm from "../../components/LoginForm";
import { login } from "../../actions/loginActions";

interface Props {
  userDetails: any;
}

const Login: React.FC<Props> = ({ userDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, loadingOTP } = useSelector(
    (state: any) => state.loginReducer
  );

  const handleForm = async (form: object) => {
    try {
      await dispatch(login(form));

      let newTabId = Math.random().toString(36).substr(2, 9);
      newTabId = newTabId + "IsMainTab";
      sessionStorage.setItem("tabId", newTabId);
      localStorage.setItem("isTabIdSet", "true");
      const pageTitle = "RedCliffe Tech Support ";
      document.title = pageTitle;
      sessionStorage.setItem("openerTabId", newTabId);

      history.push("/dashboard");
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="login-container">
      <div className="mainHeading">
        <img
          src="https://staticcdn.redcliffelabs.com/media/gallary-file/None/bf8ae4e8-1f26-45a6-8011-938ad23ad65a.svg"
          alt=""
          style={{ width: "350px" }}
        />
        <p style={{ fontSize: "30px", marginLeft: "3rem", color: "white" }}>
          Healthy India Ki Trusted Lab
        </p>
      </div>
      <div className="login-container__form">
        <LoginForm
          loading={loading}
          loadingOTP={loadingOTP}
          handleForm={handleForm}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(Login);
