import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { syncBookingWithES } from "../actions/TechSupportActions";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);
interface Props {
  loading: boolean;
  syncBookingWithES: any;
}

const CustomUploader: React.FC<Props> = ({ loading, syncBookingWithES }) => {
  const history = useHistory();
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [startId, setStartId] = useState("");
  const [endId, setEndId] = useState("");

  const submitFile = async (e: any) => {
    e.preventDefault();
    const data: any = {
      start_id: Number(startId),
      end_id: Number(endId),
    };
    await syncBookingWithES(data);
    history.push("/dashboard/ts/bulk_booking");
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="reset-user-password">
          <div className={classes.contentHeader}>
            <h2 style={{ fontWeight: "bold" }}>Sync Booking With ES</h2>
          </div>
          <div className={classes.contentTable}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="booking"
                  type="number"
                  label="Start Id"
                  value={startId}
                  variant="outlined"
                  onChange={(e) => setStartId(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="booking"
                  type="number"
                  label="End Id"
                  value={endId}
                  variant="outlined"
                  onChange={(e) => setEndId(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  fullWidth
                  className={classes.submit}
                  onClick={submitFile}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ts/bulk_booking")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  syncBookingWithES,
})(CustomUploader);
