import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '@mui/material/Button';
import {  TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createNewPhlebo } from "../../actions/TechSupportActions";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  createNewPhlebo: any;
  openPhlebo: boolean;
  zoneList: any;
  getAutoCompleteZone: any;
  setOpenPhlebo: Function;
  getPhlebos: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  openPhlebo,
  setOpenPhlebo,
  createNewPhlebo,
  zoneList,
  getAutoCompleteZone,
  getPhlebos,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0)

  const [phleboName, setPhleboName] = useState<string>("");
  const [phleboNumber, setPhleboNumber] = useState<string>("");
  const [employeId, setEmployeId] = useState<string>("");
  const [id, setId] = useState<any>(0);
  const [phleboSource, setPhleboSource] = useState<string>("none");
  const [zoneName, setZoneName] = useState<string>("none");
  const handleClose = () => {
    setOpenPhlebo(false);
  };

  const CreateNewPhlebo = async () => {
    const body: any = {
      fullname: phleboName,
      phonenumber: phleboNumber,
      empid: employeId,
      u_type: phleboSource,
      zone: zoneName,
    };
    if (phleboName.length > 50) {
      alert("Phlebo Name Can't more then 50");
    } else if (phleboNumber.length !== 10) {
      alert("Enter Correct Number");
    } else {
      await createNewPhlebo(body);
      setPhleboName("");
      setPhleboNumber("");
      setEmployeId("")
      setPhleboSource("")
      getPhlebos();
      setOpenPhlebo(false);

    }
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPhlebo}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        key={id}
        BackdropProps={{
          timeout: 500,
        }}
      >

        <Fade in={openPhlebo}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Create Phlebo
              </h4>
              <CloseIcon onClick={() => setOpenPhlebo(false)} />
            </div>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              
              spacing={3}
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="empId"
                  type="text"
                  value={employeId}
                  className="input"
                  variant="outlined"
                  placeholder="Enter Employe Id"
                  onChange={(e) => setEmployeId(e.target.value as string)}
                  style={{ width: "100%", minWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="name"
                  type="text"
                  value={phleboName}
                  className="input"
                  variant="outlined"
                  placeholder="Phlebo Name"
                  onChange={(e) => setPhleboName(e.target.value as string)}
                  style={{ width: "100%", minWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="number"
                  type="number"
                  value={phleboNumber}
                  className="input"
                  variant="outlined"
                  placeholder="Phone Number"
                  onChange={(e) => setPhleboNumber(e.target.value as string)}
                  style={{ width: "100%", minWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Select
                  className="input"
                  name="phlebo_sorce"
                  variant="outlined"
                  value={phleboSource}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  onChange={(e) => setPhleboSource(e.target.value as string)}
                  required
                >
                  <MenuItem disabled value={"none"}>
                    Select Source
                  </MenuItem>
                  <MenuItem value={"rcl"}>RCL</MenuItem>
                  <MenuItem value={"kartvya"}>Kartvya</MenuItem>
                  <MenuItem value={"cc"}>CC</MenuItem>
                  <MenuItem value={"others"}>Others</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Autocomplete
                  id="zone"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setZoneName(obj.id)
                    }
                  }}
                  defaultValue={
                    zoneList?.results &&
                    zoneList?.results.find((code: any) => {
                      return code.id === zoneName;
                    })
                  }
                  options={zoneList?.results || []}
                  freeSolo
                  blurOnSelect
                  getOptionLabel={(option: any) =>
                    `${option.id}, ${option?.city?.name}`
                  }
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current)
                    timer.current = setTimeout(() => {
                      getAutoCompleteZone(newInputValue.split(",")[0])
                    }, 1000)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Zone"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      className="input"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ height: "50px" }}
                  onClick={CreateNewPhlebo}
                >
                  CREATE PHLEBO
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  area: state.TechSupportReducer.area,
  loading: state.TechSupportReducer.loading
});

export default connect(mapStateToProps, {
  createNewPhlebo,
})(CreatePhlebo);
