import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Fade, Backdrop } from "@mui/material";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
// import Loader from "../components/loader/index";
import {
  Paper,
  Table,
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  getBatchBookingAmount,
  resetBookings,
} from "../../actions/TechSupportActions";
import "./index.sass";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    zIndex: 1,
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    top: "200px",
  },
}));
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type ModalProps = {
  openBatch: boolean;
  setOpenBatch: Function;
  batchId: number;
  batchbookingamount: any;
  getBatchBookingAmount: any;
  resetBookings: any;
  loading: boolean;
};

const CommentsModal: React.FC<ModalProps> = ({
  openBatch,
  setOpenBatch,
  batchId,
  batchbookingamount,
  getBatchBookingAmount,
  resetBookings,
  loading,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpenBatch(false);
  };

  useEffect(() => {
   
    if (batchId !== 0) {
      getBatchBookingAmount(`?batch_id=${batchId}`);
      resetBookings();
    }
  }, [batchId]);
  return (
    <div style={{ width: "100%" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBatch}
        onClose={handleClose}
        closeAfterTransition
       
        style={{ maxWidth: 1200, margin: "0px auto" }}
      >
        <Fade in={openBatch} style={{ margin: 20 }}>
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "700px" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                {batchbookingamount.data === undefined ? (
                  ""
                ) : (
                  <StyledTableRow>
                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                    <StyledTableCell align="center">Amount</StyledTableCell>
                  </StyledTableRow>
                )}
              </TableHead>
              <TableBody>
                {batchbookingamount &&
                  batchbookingamount?.data &&
                  batchbookingamount.data.map((data: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.booking_id}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.amount}{" "}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                {batchbookingamount.data === undefined ? (
                  <StyledTableCell align="center">
                    <h2> {batchbookingamount} </h2>
                  </StyledTableCell>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  batchbookingamount: state.TechSupportReducer.batchbookingamount,
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  getBatchBookingAmount,
  resetBookings,
})(CommentsModal);
