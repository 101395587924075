import React, { useState } from "react";
import { connect } from "react-redux";
import {
  makeStyles,
} from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Modal from "@mui/material/Modal";
import Loader from "../../Loader2";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: 'scroll'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "3px solid green",
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "90%",
    height:"90%"
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  tableContainer: {
    top: "200px",
  },

  centerIt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fontWeight: {
    fontWeight: "bold",
  },
  tableHead: {
    background: "rgb(146, 74, 145)!important",
  },
  tableHeadCell: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

interface Props {
    setPdfViewerModal:any
    pdfViewerModal:any
    invoiceUrl:any
    isloading: boolean
}

const PdfViewerModal: React.FC<Props> = ({
    setPdfViewerModal,
    pdfViewerModal,
    invoiceUrl,
    isloading,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setPdfViewerModal(false);
  };

  console.log(isloading,"isloading")
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={pdfViewerModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={pdfViewerModal}>
          <div className={classes.paper}>
          {isloading ? (
              <Loader />
            ) : 
          <iframe
            src={invoiceUrl}
            width="100%"
            height="100%"
            style={{border:"none"}}
            />}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
    isloading: state.SalesLeadAdminReducer.loading
});

export default connect(mapStateToProps, {
})(PdfViewerModal);