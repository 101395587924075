import React from "react";
import { connect } from "react-redux";
import {
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router";
import { updateUnregisterdBooking } from "../../actions/TechSupportActions";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);
interface Props {
  updateUnregisterdBooking: any;
  loading: boolean;
}
type ModalProps = {
  bookingId: number;
  updateUnregisterdBooking: any;
};

const UnregisterdStatusModal: React.FC<ModalProps> = ({
  // leadId,
  bookingId,
  updateUnregisterdBooking,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const handleChange = (event: any) => {
    setStatus(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    setOpen(false);
    setStatus("")
    
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (status === "" ? false :  true) {
      const data: any = {
        booking_status: status,
      };
      await updateUnregisterdBooking(data, bookingId);
      history.push("/dashboard/ts/unregister-booking");
      setOpen(false);
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>Edit Booking Status</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">Status</InputLabel>
              <Select
                className="input"
                native
                value={status}
                onChange={handleChange}
                input={<Input id="demo-dialog-native" />}
              >
                <option aria-label="None" value="" />
                <option value="pending">pending</option>
                <option value="confirmed">confirmed</option>
                <option value="cancelled">cancelled</option>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button> */}
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={status === ""}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  updateUnregisterdBooking,
})(UnregisterdStatusModal);
