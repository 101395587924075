import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import {
  TableContainer,
  Table,
  Chip,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "100%",
  },
  table: {
    maxWidth: "100%",
    margin: "auto",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  tableContainer: {
    marginTop: "1rem",
    position: "sticky",
    top: "200px",
  },
}));

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type ModalProps = {
  additionalMemebr: any;
  additionalModal: any;
  setAdditonalModal: any;
  loading: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  additionalMemebr,
  additionalModal,
  setAdditonalModal,
  loading,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);

  const [phleboName, setPhleboName] = useState<string>("");
  const [phleboNumber, setPhleboNumber] = useState<string>("");
  const [employeId, setEmployeId] = useState<string>("");
  const [id, setId] = useState<any>(0);
  const [phleboSource, setPhleboSource] = useState<string>("none");
  const [zoneName, setZoneName] = useState<string>("none");
  const [bookingId, setBookingId] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [memberData, setMemberData] = useState<any>();
  const handleClose = () => {
    setAdditonalModal(false);
    setId(id + 1);
  };
  useEffect(() => {
    setMemberData(additionalMemebr);
  }, [additionalMemebr]);

  const filterBookings = () => {
    const allData = [...additionalMemebr];
    const filterData =
      allData &&
      allData.filter((data: any) => {
        if (bookingId !== "") {
          return data.id == bookingId;
        } else {
          return data.customer_name.includes(name);
        }
      });
    if (bookingId === "" && name === "") {
      setMemberData(allData);
    } else {
      setMemberData(filterData);
    }
  };
  const handleReset = () => {
    const allData = [...additionalMemebr];
    setMemberData(allData);
    setBookingId("");
    setName("");
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={additionalModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        key={id}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={additionalModal}>
          <div className={classes.paper}>
            <Paper elevation={15} className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  ADDITIONAL FAMILY MEMBER
                </h4>
                <CloseIcon onClick={() => setAdditonalModal(false)} />
              </div>
              <Grid
                container
                spacing={3}
                direction="row"
                style={{ alignItems: "left" }}
              >
                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    name="name"
                    type="number"
                    label="Booking Id"
                    value={bookingId}
                    variant="outlined"
                    onChange={(e) => {
                      setBookingId(e.target.value);
                      setName("");
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    name="name"
                    type="text"
                    label="Name"
                    value={name}
                    variant="outlined"
                    onChange={(e) => {
                      setName(e.target.value as string);
                      setBookingId("");
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    fullWidth
                    onClick={filterBookings}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "580px" }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        Additional Booking ID
                      </StyledTableCell>
                      <StyledTableCell align="center">Bill ID</StyledTableCell>
                      <StyledTableCell align="center">NAME</StyledTableCell>
                      <StyledTableCell align="center">GENDER</StyledTableCell>
                      <StyledTableCell align="center">AGE</StyledTableCell>
                      <StyledTableCell align="center">
                        Created At
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        BOOKING STATUS
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        REPORT STATUS
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        PICKUP STATUS
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        REPORT VERIFICATION STATUS
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {memberData &&
                      memberData.map((data: any, index: any) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">
                              {data?.id || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.bill_id || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.customer_name || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.customer_gender || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.customer_age || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {new Date(data?.created_at).toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.booking_status || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.report_status || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.pickup_status || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.report_verification_status || "N/A"}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(CreatePhlebo);
