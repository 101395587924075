import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Paper } from '@material-ui/core';
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: "0.2rem ",
    margin: "20px 5% 0 5%",
  },
  scrollComponent: {
    width: "95%",
    height: "100%",
    overflowY: "auto",
  },
  imageWrapper: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
}));


type PropsCR = {
    open: any;
    setOpen: any;
    src: any;
    heading: boolean;
};

const ImageModal: React.FC<PropsCR> = ({
    open,
    setOpen,
    src,
    heading,
}) => {
  const classes = useStyles();

  const [zoomLevel, setZoomLevel] = useState(60);

  const handleZoomIn = () => {
    if (zoomLevel < 120) {
      setZoomLevel(zoomLevel + 10);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 20) {
      setZoomLevel(zoomLevel - 10);
    }
  };

  const handleClose = () => {
    setZoomLevel(60);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
            style: { backdropFilter: 'blur(5px)' },
        }}
      >
        <Fade in={open}>
          <div className={classes.scrollComponent}>
            <Paper elevation={15} className={classes.paper} >
                <div className="modal-content">
                  { 
                    (src?.includes(".jpg") || src?.includes(".jpeg") || src?.includes(".png")) ?
                    <>
                      <div style={{ display: "flex", justifyContent: "space-between", margin: "0 10px 0 10px" }}>
                          <div className="zoom-options" style={{ marginTop: "10px" }}>
                              <button className="zoom-button" onClick={handleZoomIn} style={{ minWidth: "40px", color: "black", backgroundColor: "#137ff4", minHeight: "25px", border: "none", marginRight: "8px" }}>
                              +
                              </button>
                              <span className="zoom-level">{zoomLevel}%</span>
                              <button className="zoom-button" onClick={handleZoomOut} style={{ minWidth: "40px", color: "black", backgroundColor: "#137ff4", minHeight: "25px", border: "none", marginLeft: "8px" }}>
                              -
                              </button>
                          </div>

                          <h3 style={{ marginRight: "8%" }}>
                              {heading ? "Receipt Image" : ""}
                          </h3>

                          <button className="close" onClick={handleClose}>
                              &times;
                          </button>
                      </div>

                      <div className={classes.imageWrapper} style={{ zoom: `${zoomLevel}%` }}>
                          <img src={src} className={classes.image} alt="Image" />
                      </div>
                    </>
                    : (
                      (src?.includes(".doc") || src?.includes(".docx")) ?
                        <div id="prescription-doc-viewer">
                          <button className="close" onClick={handleClose} style={{ padding: "10px 20px 0 0" }}>
                              &times;
                          </button>
                          {/* <DocViewer
                            style={{ height: "100%" }}
                            pluginRenderers={DocViewerRenderers}
                            documents={[{ uri: src, fileType: "doc" }]}
                          /> */}
                        </div>
                        :
                        <>
                          <button className="close" onClick={handleClose} style={{ padding: "10px 20px 10px 0", display: "flex", justifyContent: "flex-end" }}>
                              &times;
                          </button>
                          <iframe
                            src={`${src}`}
                            style={{ width: "100%", height: "700px" }}
                            title="PDF Document"
                          ></iframe>
                        </>
                    )
                  }

                </div>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(ImageModal);