import React, { useState } from "react";
import { useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, TextField, Grid } from "@material-ui/core";

import { useEffect } from "react";
import {
  getCenterInfo,
  getLabCredential,
  getLabOrgCode,
  createCentreLabOrg,
  updateCentreLabOrg,
  getCentreLabOrg,
  //getLabDetails,
} from "../../actions/TechSupportActions";
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader2/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getLabOrgCode: any;
  labOrgCode: any;
  //getLabDetails: any;
  labDetails: any;
  createCentreLabOrg: any;
  updateCentreLabOrg: any;
  getCentreLabOrg: any;
  centreLabOrg: any;
  getLabCredential: any;
  labCredential: any;
  getCenterInfo: any;
  centerInfo: any;
  loading: any;
  userDetails: any
}
const CentreLabOrg: React.FC<Props> = ({
  getLabOrgCode,
  labOrgCode,
  //getLabDetails,
  labDetails,
  createCentreLabOrg,
  updateCentreLabOrg,
  getCentreLabOrg,
  centreLabOrg,
  getCenterInfo,
  centerInfo,
  loading,
  userDetails,
  getLabCredential,
  labCredential,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  //create
  const [lab, setLab] = React.useState<any>("");
  const [labDetail, setLabDetails] = React.useState<any>("");
  const [labDetailName, setLabDetailsName] = React.useState<any>("");
  const [orgCode, setOrgCode] = useState<any>("");
  const [autoCode, setAutoCode] = useState<any>(false);
  useEffect(() => {
    if (Object.keys(labOrgCode).length !== 0&&(userDetails?.profile?.pilot_user === true||userDetails?.profile?.pilot_user === "True")) {
      setOrgCode(labOrgCode.org_code);
      setAutoCode(true)
    }else{
      setAutoCode(false)
    }
  }, [labOrgCode,userDetails]);

  //tag centre
  const [centre, setCentre] = useState<any>("");
  const [isEdit, setIsEdit] = useState<any>(false);
  const [editId, setEditId] = useState<any>("");
  const [disableBooking, setDisableBooking] = useState<boolean>(false);
  const [centreName, setCentreName] = useState<any>("");
  const [page, setPage] = useState(0);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = centreLabOrg.links && centreLabOrg.links.next.split("?")[1];
      getCentreLabOrg(url);
    } else if (newPage < page) {
      let url = centreLabOrg.links && centreLabOrg.links.previous.split("?")[1];
      getCentreLabOrg(url);
    }
    setPage(newPage as number);
  };
  useEffect(() => {
    //getLabDetails();
    getCenterInfo(`?optimizev2=true`);
    getLabOrgCode();
    getLabCredential();
    getCentreLabOrg();
  }, []);

  const handleReset = () => {
    setCentre("");
    setOrgCode("");
    setLab("");
    getCenterInfo(`?optimizev2=true`);
    getLabOrgCode();
    getLabCredential();
    getCentreLabOrg();
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    setDisableBooking(true);
    const data = {
      center: centre,
      assigned_lab: labDetail,
      org_code: orgCode,
    };
    const data2 = {
      center: centre,
      assigned_lab: labDetail,
      org_code: orgCode,
    }
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setDisableBooking(false);
    }, 15000);
    if (isEdit) {
      await updateCentreLabOrg(data2, editId);
    } else {
      await createCentreLabOrg(data);
    }

    handleReset();
    setCentreName("");
    setLabDetailsName("");
  };
  const handleEdit = (val: any) => {
    setIsEdit(true);
    setEditId(val.id);
    setOrgCode(val.org_code);
    setLabDetails(val?.assigned_lab?.id || "");
    setLabDetailsName(val?.assigned_lab?.name || "");
    setCentre(val?.centre_id || "");
    setCentreName(val?.center || "");
  };
  return (
    <Grid container spacing={2} style={{ marginTop: 50 }}>
      <h2>Centre Lab Org</h2>
      <form style={{ width: "100%" }} onSubmit={handleSave}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              id="center"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCentre(obj.id);
                  getCentreLabOrg(`center__id=${obj.id}`);
                }
              }}
              freeSolo
              blurOnSelect
              loading={loading}
              options={centerInfo?.results || []}
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                (option?.display_name === null
                  ? option?.name
                  : option?.display_name) +
                "(" +
                option?.center_code +
                ")"
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCenterInfo(`?optimizev2=true&name_or_code=${newInputValue}`);
                }, 1000);
                setCentreName(newInputValue);
              }}
              inputValue={centreName}
              renderInput={(params) => (
                <TextField
                className="input"
                  {...params}
                  label="Centre"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              id="lab"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLabDetails(obj.id);
                }
              }}
              inputValue={labDetailName}
              loading={loading}
              options={labCredential.results || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getLabCredential(`?lab_name=${newInputValue}`);
                }, 1000);

                setLabDetailsName(newInputValue);
                if (newInputValue === "") {
                  setLabDetails("");
                }
              }}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => `${option?.name}`}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Assigned Lab"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
         
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                className="input"
                label="Org Code"
                type="number"
                variant="outlined"
                fullWidth
                disabled={autoCode}
                required={!isEdit}
                value={orgCode}
                onChange={(e) => setOrgCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={loading||disableBooking}

              >
                {isEdit ? "update" : "save"}
              </Button>
            </Grid>

          <Grid item xs={12} sm={6} md={1}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/dashboard/ts/centre_lab_org")}

            >
              reset
            </Button>
          </Grid>
        </Grid>
      </form>

      <Grid container style={{ marginTop: 10 }}>
        <div style={{ width: "100%" }} className="data-table">
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "540px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">S. No.</StyledTableCell>
                    <StyledTableCell align="center">Centre Name</StyledTableCell>
                    <StyledTableCell align="center">Org Code</StyledTableCell>
                    <StyledTableCell align="center">Assigned Lab</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>               
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {centreLabOrg &&
                    centreLabOrg.results &&
                    centreLabOrg.results?.length > 0 &&
                    centreLabOrg.results?.map((row: any, index: number) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.display_name === null
                              ? row?.center
                              : row?.display_name}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row?.org_code}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.assigned_lab?.name}
                          </StyledTableCell>                      
                            <StyledTableCell align="center">
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleEdit(row)}
                              >
                                edit
                              </Button>
                            </StyledTableCell>                       

                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={centreLabOrg.count}
                    rowsPerPage={centreLabOrg.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              </Table>
            )}
          </TableContainer>
        </div>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state: any) => ({
  centerInfo: state.TechSupportReducer.centerInfo,
  labDetails: state.TechSupportReducer.labDetails,
  labCredential: state.TechSupportReducer.labCredential,
  centreLabOrg: state.TechSupportReducer.centreLabOrg,
  labOrgCode: state.TechSupportReducer.labOrgCode,
  userDetails: state.TechSupportReducer.userDetails,
  loading: state.TechSupportReducer.loading,
});
export default connect(mapStateToProps, {
  getCenterInfo,
  getLabCredential,
  getLabOrgCode,
  getCentreLabOrg,
  createCentreLabOrg,
  updateCentreLabOrg,
  //getLabDetails,
})(CentreLabOrg);