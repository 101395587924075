import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid, TableCell, TableContainer, Table, withStyles, TableBody, TableHead, TableRow } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { raiseNewTicektDisposition, initiateRefund } from '../../actions/TechSupportActions';
import { useEffect } from "react";
import Loader from '../loader';
import { Link } from 'react-router-dom';

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: 'scroll',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "3px solid green",
        borderRadius: "5px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    table: {
        maxWidth: "100%",
        margin: "auto",
        overflow: 'scroll'
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        overflow: 'scroll'

    },
}));
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#178073",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },

    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

type ModalProps = {
    open: boolean;
    bookingId: number;
    raiseNewTicektDisposition: any;
    bookingComplaints: any;
    leadId: number;
    setOpen: Function;
    ticketLoader: boolean;
    initiateRefund: any;
    totalPrice: number;
    booking: any;

};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    bookingId,
    leadId,
    raiseNewTicektDisposition,
    bookingComplaints,
    setOpen,
    ticketLoader,
    initiateRefund,
    totalPrice,
    booking,

}) => {
    const classes = useStyles();

    // const [query, setQuery] = useState("none")
    const [description, setDescription] = useState("")
    const [newTicket, setNewTicket] = useState<boolean>(false)
    const [disposition, setDisposition] = useState<any>("")
    const [createBookingId, setCreateBookingId] = useState<any>(bookingId)
    const [createAmount, setCreateAmount] = useState<any>(totalPrice)
    const [resamplingAmount, setResamplingAmount] = useState<any>()
    const [cancelledAmount, setCancelleAmount] = useState<any>()
    const [resamplingBookingId, setResamplingBookingId] = useState<any>()
    const [bookingType, setBookingType] = useState<any>()
    const [subTicketType, setSubTicketType] = useState<any>("")
    const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();


    useEffect(() => {
        setBookingType(booking?.booking_type)
        if (booking?.pickup_status === "cancelled" || booking?.booking_status === "cancelled") {
            setCancelleAmount(booking?.cancelled_booking_amount)
        }
        else if (booking?.booking_status === "oldresampling" || booking?.booking_status === "resampling") {
            setResamplingAmount(booking?.resampling_booking_amount)
            setResamplingBookingId(booking?.resampling_booking_id)
        }

    }, [booking])

    const handleClose = () => {
        setDisposition("")
        setOpen("");
        setSubTicketType("");
        setNewTicket(false)

    };

    useEffect(() => {
        setCreateAmount(totalPrice);
    }, [totalPrice]);



    const raiseComplaint = () => {

        const body1: any = {
            query: description,
            disposition: disposition,
            lead_id: leadId,
            booking_id: bookingId,
            sub_ticket_type: subTicketType,
            querytype: "doctor",
        }

        const body2: any = {
            amount: resamplingAmount === "" || resamplingAmount === null ? createAmount : Number(resamplingAmount),
            booking: createBookingId,
            cs_remarks: description
        }
        const body3: any = {
            amount: cancelledAmount === "" || cancelledAmount === null ? createAmount : Number(cancelledAmount),
            booking: createBookingId,
            cs_remarks: description
        }
        const body4: any = {
            amount: createAmount === "" ? 0 : Number(createAmount),
            booking: createBookingId,
            cs_remarks: description
        }
        if (disposition === "") {
            delete body1.disposition
        }
        if (leadId === 0) {
            delete body1["lead_id"]
        }
        if (disposition !== "doctor_consultation") {
            delete body1["querytype"]
        }
        if (disposition !== "refund_query") {
            raiseNewTicektDisposition(body1)
            // setQuery("none")
            setDescription("")
            setDisposition("")
            handleClose()
        }

        else if (booking?.booking_status === "oldresampling" || booking?.booking_status === "resampling") {
            initiateRefund(body2)
        }
        else if (booking.booking_status === "cancelled" || booking.pickup_status === "cancelled") {
            initiateRefund(body3)
        }
        else {
            initiateRefund(body4)
        }

        // setQuery("none")
        setDescription("")
        setDisposition("")
        handleClose()

    };


    const handleCross = () => {
        setOpen(false)
        setNewTicket(false)
    }
    useEffect(() => {
        setCreateBookingId(bookingId)

    }, [bookingId])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {bookingType === "b2c" || bookingType !== undefined ? (
                            <>
                                <div className={classes.head}>
                                    <h4 style={{ fontWeight: "bolder" }}>Raise A Query Ticket</h4>
                                    <CloseIcon onClick={handleCross} />
                                </div>
                                {bookingComplaints?.results && bookingComplaints?.results.length > 0 && !newTicket ? (
                                    <>
                                        <TableContainer
                                            className={classes.tableContainer}
                                            style={{ maxHeight: "400px" }} >
                                            {ticketLoader ? <Loader /> : (
                                                <Table stickyHeader aria-label="simple table">
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            <StyledTableCell align="center">Booking Id</StyledTableCell>
                                                            <StyledTableCell align="center">Child Id</StyledTableCell>
                                                            <StyledTableCell align="center">Agent</StyledTableCell>
                                                            <StyledTableCell align="center">Created At</StyledTableCell>
                                                            <StyledTableCell align="center">Query Type</StyledTableCell>
                                                            <StyledTableCell align="center">Query Description</StyledTableCell>
                                                            <StyledTableCell align="center">Receiver Remarks</StyledTableCell>
                                                            <StyledTableCell align="center">Status</StyledTableCell>
                                                            <StyledTableCell align="center">View</StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            bookingComplaints?.results && bookingComplaints?.results.length > 0 && bookingComplaints?.results.map((ticket: any, index: any) => {
                                                                return (
                                                                    <StyledTableRow key={index}>
                                                                        <StyledTableCell align="center">{ticket?.booking !== null ? (ticket?.booking?.id || ticket?.booking?.pk) : (ticket?.add_booking?.mainbooking)}</StyledTableCell>
                                                                        <StyledTableCell align="center">{ticket?.booking === null ? (ticket?.add_booking?.id) : ""}</StyledTableCell>
                                                                        <StyledTableCell align="center">{ticket.panel_user && ticket.panel_user.name}</StyledTableCell>
                                                                        <StyledTableCell align="center">{new Date(ticket.created_at).toLocaleString()}</StyledTableCell>
                                                                        <StyledTableCell align="center">{ticket.querytype}</StyledTableCell>
                                                                        <StyledTableCell align="center">{ticket.query}</StyledTableCell>
                                                                        <StyledTableCell align="center">{ticket.receicer_remarks}</StyledTableCell>
                                                                        <StyledTableCell align="center">{ticket.status}</StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <Link to={`/dashboard/slm/booking-view/${ticket?.booking !== null ? (ticket?.booking?.id || ticket?.booking?.pk) : (ticket?.add_booking?.mainbooking)}`}>
                                                                                <Button
                                                                                    style={{
                                                                                        backgroundColor: "rgb(232 179 57)"
                                                                                    }}
                                                                                    variant="contained"
                                                                                >
                                                                                    View
                                                                                </Button>
                                                                            </Link>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </TableContainer>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            style={{ height: "50px", marginTop: "50px", backgroundColor: "#7ed27e" }}
                                            onClick={() => setNewTicket(true)}
                                        >
                                            Do You Want to Raise New Ticket
                                        </Button>
                                    </>
                                ) : (
                                    <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                                        <Grid item xs={12} >
                                            <p style={{ fontWeight: "bold" }}>Ticket Type</p>
                                            <Select className="input"
                                                name="action"
                                                variant="outlined"
                                                value={disposition}

                                                style={{ width: "100%" }}
                                                onChange={(e) => setDisposition(e.target.value as string)}

                                            >
                                                <MenuItem value={"phlebo_delay"}>Phlebo Delay</MenuItem>
                                                <MenuItem value={"phlebo_behavior"}>Phlebo Behavior</MenuItem>
                                                <MenuItem value={"report_related_query"}>Report Tat Breached</MenuItem>
                                                <MenuItem value={"report_not_delivered"}>Report Not Delivered</MenuItem>
                                                <MenuItem value={"fault_report"}>Report Related Query</MenuItem>
                                                <MenuItem value={"payment_related_issue"}> Payment Related Issue</MenuItem>
                                                <MenuItem value={"refund_query"}>Refund Query</MenuItem>
                                                <MenuItem value={"doctor_consultation"}>Report Consultation</MenuItem>
                                                <MenuItem value={"document_required"}>Document Required for Report</MenuItem>
                                                <MenuItem value={"social_media_ticket"}>Social Media Ticket</MenuItem>
                                                <MenuItem value={"report_discrepancy_b2b"}>Report discrepancy (b2b issue)</MenuItem>
                                                <MenuItem value={"payment_related_concern_b2b"}>Payment related concern (b2b issue)</MenuItem>
                                                <MenuItem value={"phlebo_behavior_b2b"}>Phlebo behavior (b2b issue)</MenuItem>
                                                <MenuItem value={"phlebo_delay_b2b"}>Phlebo delay (b2b issue)</MenuItem>
                                                <MenuItem value={"report_tat_breached_b2b "}>Report TAT breached (b2b issue)</MenuItem>
                                                <MenuItem value={"resampling_b2b"}>Resampling (b2b issue)</MenuItem>
                                                <MenuItem value={"others"}>Others</MenuItem>
                                            </Select>
                                        </Grid>

                                        {disposition === "fault_report" && <Grid item xs={12} >
                                            <p>Sub Ticket Type</p>
                                            <Select className="input"
                                                name="sub_ticket_type"
                                                variant="outlined"
                                                value={subTicketType}

                                                style={{ width: "100%" }}
                                                onChange={(e) => setSubTicketType(e.target.value as string)}
                                            >
                                                <MenuItem value={"inaccurate_report"}>Inaccurate Report</MenuItem>
                                                <MenuItem value={"name_correction"}>Name Correction</MenuItem>
                                                <MenuItem value={"age_correction"}>Age Correction</MenuItem>
                                                <MenuItem value={"gender_correction"}>Gender Correction</MenuItem>
                                                <MenuItem value={"doctor_name_to_be_updated"}>Doctor Name to be updated</MenuItem>
                                                <MenuItem value={"others"}>Others (Passport addition, Smart report required,lab address in correct, barcode related etc)</MenuItem>
                                            </Select>
                                        </Grid>}
                                        {disposition === "refund_query" ? (
                                            <>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextField
                                                        className="input"
                                                        name="name"
                                                        type="number"
                                                        onWheel={(e: any) => e.target.blur()}
                                                        label="Booking Id"
                                                        value={createBookingId}
                                                        variant="outlined"
                                                        onChange={(e) => setCreateBookingId(e.target.value)}
                                                        style={{ width: "100%" }}
                                                        disabled
                                                    />
                                                </Grid>
                                                {(booking?.booking_status === "oldresampling" || booking?.booking_status === "resampling" && resamplingAmount !== null && resamplingAmount !== undefined) ? (
                                                    <>
                                                        <Grid item xs={12} sm={6} md={6}>
                                                            <TextField
                                                                className="input"
                                                                name="resamplingAmount"
                                                                type="number"
                                                                label="Resampling Amount"
                                                                InputProps={{
                                                                    inputProps: {
                                                                        max: resamplingAmount, min: 0
                                                                    }
                                                                }}
                                                                value={resamplingAmount}
                                                                variant="outlined"
                                                                onKeyDown={(e) => {
                                                                    blockCharInPhoneField(e)

                                                                }}
                                                                required
                                                                onChange={(e) => setResamplingAmount(e.target.value)}

                                                                style={{ width: "100%" }}
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : (booking.booking_status === "cancelled" || booking.pickup_status === "cancelled") ? (
                                                    <>
                                                        <Grid item xs={12} sm={6} md={6}>
                                                            <TextField
                                                                className="input"
                                                                name="cancelledAmount"
                                                                type="number"
                                                                label="Cancelled Amount"
                                                                InputProps={{
                                                                    inputProps: {
                                                                        max: cancelledAmount, min: 0
                                                                    }
                                                                }}
                                                                value={cancelledAmount}
                                                                variant="outlined"
                                                                onKeyDown={(e) => {
                                                                    blockCharInPhoneField(e)
                                                                }}
                                                                required
                                                                onChange={(e) => setCancelleAmount(e.target.value)}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Grid>
                                                    </>
                                                ) :
                                                    <>
                                                        <Grid item xs={12} sm={6} md={6}>
                                                            <TextField
                                                                className="input"
                                                                name="name"
                                                                type="number"
                                                                onWheel={(e: any) => e.target.blur()}
                                                                label="Amount"
                                                                InputProps={{
                                                                    inputProps: {
                                                                        max: totalPrice, min: 0
                                                                    }
                                                                }}
                                                                value={createAmount}
                                                                variant="outlined"
                                                                onKeyDown={(e) => {
                                                                    blockCharInPhoneField(e)

                                                                }}
                                                                required
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value;
                                                                    if (inputValue.length === 0) {
                                                                        setCreateAmount(0)
                                                                    }
                                                                    else {
                                                                        setCreateAmount(inputValue);
                                                                    }
                                                                }}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Grid>
                                                    </>}

                                            </>
                                        ) : ""}
                                        <Grid item xs={12}>
                                            <TextField
                                                name="comment"
                                                type="text"
                                                multiline
                                                rows={3}
                                                value={description}
                                                className="input"
                                                variant="outlined"
                                                placeholder="Description"
                                                onChange={(e) => setDescription(e.target.value as string)}
                                                style={{ width: "100%" }}
                                                required
                                            />
                                        </Grid>
                                        {disposition === "refund_query" ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ height: "50px" }}
                                                        onClick={raiseComplaint}

                                                        disabled={description === "" || disposition === ""}                                    >
                                                        CREATE REFUND TICKET
                                                    </Button>
                                                </Grid>
                                            </>
                                        ) : <>
                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ height: "50px" }}
                                                    onClick={raiseComplaint}
                                                    disabled={description === "" || disposition === ""}                                    >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </>
                                        }
                                    </Grid>
                                )}
                            </>) : <h3 style={{ color: "red", alignItems: "center", display: "flex" }}> Doctor's consultation is not allowed for this booking(B2B)</h3>}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    ticketLoader: state.TechSupportReducer.ticketLoader
});

export default connect(mapStateToProps, {
    initiateRefund,
    raiseNewTicektDisposition,
})(CommentsModal2);