import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import { updateClinicalHistoryAnswer } from "../../actions/TechSupportActions";
import {
  Paper,
  TableCell,
  TableContainer,
  Table,
  withStyles,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    backdropFilter: "blur(1.2px)",
  },
  table: {
    maxWidth: "100%",
    margin: "auto",
    overflow: "scroll",
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "95%",
    height: "700px",
    overflow: "scroll",
    border: "2px solid green",
    borderRadius: "8px",
    opacity: "0.5",
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    overflow: "scroll",
  },
  closeicon: {
    backgroundColor: "#d1ff33",
    width: "1.5em",
    height: "1.5em",
    borderRadius: "50%",
    border: "1px solid #c6ff00",
    cursor: "pointer",
    color: "#1a237e",
    marginLeft: "60%",
  },
}));
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#0d5457",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type ModalProps = {
  openPdfForClinicalTable: boolean;
  setOpenPdfForClinicalTable: Function;
  viewPackageAnswer: any;
  updateClinicalHistoryAnswer: any;
  viewPackageAnswerAllergy: any;
  setAnswerEdit: any;
  answer: any;
  setAnswer: any;
  updateAnswer: any;
  viewAllergy: any;
  setViewAllergy: any;
  answerEdit: any;
  CustomerId: any;
  getviewPackageAnswer: any;
};

const ClinicalModal: React.FC<ModalProps> = ({
  openPdfForClinicalTable,
  setOpenPdfForClinicalTable,
  viewPackageAnswer,
  updateClinicalHistoryAnswer,
  viewPackageAnswerAllergy,
  setAnswerEdit,
  answer,
  setAnswer,
  updateAnswer,
  viewAllergy,
  setViewAllergy,
  answerEdit,
  CustomerId,
  getviewPackageAnswer,
}) => {
  const classes = useStyles();
  const [editClinicalHistory, setEditClinicalHistory] = useState<any>("");
   const[clinicalAnswer,setClinicalAnswer]=useState<any>("")
   const[clinicalFile,setClinicalFile]=useState<any>("")

   const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const fileSelectedHandlerForSingleQuestion = async (e: React.ChangeEvent<{ files: any }>) => {
    const base64 = await convertBase64(e.target.files[0])
    setClinicalFile(base64);
  };


  const handlePdfModelTableCloseForClinical = () => {
    setOpenPdfForClinicalTable(false);
  };
  

  const handleSubmitClinical = async (data: any) => {
    const searchTerm1 = ';';
    const searchTerm2 = '/';
    const indexOfFirst = clinicalFile.indexOf(searchTerm1);
    const indexOfSecond = clinicalFile.indexOf(searchTerm2);
    let str = clinicalFile.substring(indexOfSecond + 1, indexOfFirst);

    setEditClinicalHistory("");
    const body: any = {
      answer: clinicalAnswer,
      doctor_prescription: clinicalFile,
      booking_id:null,
      ext: str,
    };
    if (clinicalFile.length <= 0) {
      delete body.doctor_prescription;
    }
    updateClinicalHistoryAnswer(data.id, body);
    
    setTimeout(async () => {
      await getviewPackageAnswer(`unique_customer__id=${CustomerId}&question__is_allergy=false`);
      await getviewPackageAnswer(`unique_customer__id=${CustomerId}&question__is_allergy=true`, true);
    }, 5000);
  };
  const handleEditClinical = (data: any) => {
    setEditClinicalHistory(data.id)
    setClinicalAnswer(data.answer)
  
  };
  useEffect(() => {
    if (CustomerId !== "" && CustomerId !== undefined && CustomerId !== null) {
      if (viewAllergy === false) {
        getviewPackageAnswer(`unique_customer__id=${CustomerId}&question__is_allergy=false`)
      }
      else {
        getviewPackageAnswer(
          `unique_customer__id=${CustomerId}&question__is_allergy=true`,
          true
        );
      }
    }
  }, [CustomerId, viewAllergy])

  const [openPdf, setOpenPdf] = useState<boolean>(false)
  const [fileLink, setFileLink] = useState<any>("")
  const [docs, setDocs] = useState<any>([])

  const handlePdfModelClose = () => {
    setOpenPdf(false)
  }

  const handleViewPdf = () => {
    setOpenPdf(true)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPdfForClinicalTable}
        onClose={handlePdfModelTableCloseForClinical}
        closeAfterTransition
      >
        <Fade in={openPdfForClinicalTable}>
          <Paper className={classes.paper} elevation={15}>
            {
              updateAnswer !== "" ? <>
                <Button
                  variant={viewAllergy ? "outlined" : "contained"}
                  style={{ backgroundColor: viewAllergy ? "#3498db" : '#2ecc71' }}
                  onClick={() => setViewAllergy(false)}
                >
                  Clinical History Details
                </Button>

                <Button
                  variant={!viewAllergy ? "outlined" : "contained"}
                  style={{ marginLeft: "15px", backgroundColor: viewAllergy ? "#2ecc71" : '#3498db' }}
                  onClick={() => setViewAllergy(true)}

                >
                  Allergy Details
                </Button>
              </> : <h3>Clinical History Details</h3>
            }

            <CloseIcon
              className={classes.closeicon}
              onClick={() => {
                setOpenPdfForClinicalTable(false);
              }}
            />
            {!viewAllergy &&
              viewPackageAnswer &&
              viewPackageAnswer?.length > 0 ? (
              <TableContainer className={classes.tableContainer}>
                {
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">S No.</StyledTableCell>
                        <StyledTableCell align="center">
                          Question
                        </StyledTableCell>
                        <StyledTableCell align="center">Answer</StyledTableCell>
                        <StyledTableCell align="center">
                          Created At
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          View Document
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Edit
                        </StyledTableCell>

                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {viewPackageAnswer &&
                        viewPackageAnswer?.length > 0 &&
                        viewPackageAnswer?.map((data: any, index: any) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {data?.question_text}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {editClinicalHistory === data.id ? (
                                  <TextField
                                    name="coupon"
                                    type="text"
                                    value={clinicalAnswer}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Clinical History Answer"
                                    onChange={(e: any) =>setClinicalAnswer(e.target.value)}
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                  />
                                ) : (
                                  data.answer
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {new Date(data?.created_at).toLocaleString()}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {editClinicalHistory === data.id ? (
                                  <>
                                    <sup
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bolder",
                                        color: "#131313",
                                        letterSpacing: "0.00938em",
                                      }}
                                    >
                                      Upload USG/Doctor’s Prescription
                                    </sup>
                                    <input
                                      className="input"
                                      style={{
                                        width: "100%",
                                        marginBottom: "10px",
                                        padding: "14px 16px",
                                        border: "5px solid rgba(0, 0, 0, 0.23)",
                                        borderRadius: "5px",
                                      }}
                                      accept=".pdf,.doc,.jpg,.jpeg,.png"
                                      type="file"
                                      name="file"
                                      id="file"
                                      multiple
                                      onChange={fileSelectedHandlerForSingleQuestion}
                                    />
                                  </>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor:
                                        data.doctor_prescription === null
                                          ? "gray"
                                          : "rgb(78 108 144)",
                                      marginLeft: "1rem",
                                      marginBottom: "1rem",
                                    }}
                                     disabled={data.doctor_prescription === null}
                                     onClick={() => {
                                      setFileLink(data?.doctor_prescription);
                                      setDocs([{ uri: `${data?.doctor_prescription}` }]);
                                      handleViewPdf();
                                    }}
                                  
                                  >
                                    USG/Doctor’s Prescription
                                  </Button>
                                )}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {editClinicalHistory === data.id ? (
                                  <Button
                                    style={{ backgroundColor: "#004d40" }}
                                    variant="contained"
                                    onClick={() =>
                                      handleSubmitClinical(data)
                                    }
                                  >
                                    <CheckCircleOutlineRounded />
                                  </Button>
                                ) : (
                                  <Button
                                    style={{ backgroundColor: "#ff9800" }}
                                    variant="contained"
                                    onClick={() =>
                                      handleEditClinical(data)
                                  
                                    }
                                  >
                                    <Edit />
                                  </Button>
                                )}
                              </StyledTableCell>

                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                }
              </TableContainer>
            ) :
             viewAllergy &&
              viewPackageAnswerAllergy &&
              viewPackageAnswerAllergy?.length > 0 ? (
              <TableContainer className={classes.tableContainer}>
                {
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">S No.</StyledTableCell>
                        <StyledTableCell align="center">
                          Question
                        </StyledTableCell>
                        <StyledTableCell align="center">Answer</StyledTableCell>

                        <StyledTableCell align="center">
                          Created At
                        </StyledTableCell>
                        <StyledTableCell align="center">Edit</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {viewPackageAnswerAllergy?.map(
                        (data: any, index: any) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {data?.question_text}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {answerEdit && answerEdit === data.id ? (
                                  <TextField
                                    variant="outlined"
                                    onChange={(e) => setAnswer(e.target.value)}
                                    value={answer}
                                  />
                                ) : (
                                  data?.answer
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {new Date(data?.created_at).toLocaleString()}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ height: "50px" }}
                                align="center"
                              >
                                {answerEdit && answerEdit === data.id ? (
                                  <Button
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    onClick={() => updateAnswer()}
                                  >
                                    save
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="warning"
                                    size="small"
                                    onClick={() => {
                                      setAnswerEdit(data?.id);
                                      setAnswer(data?.answer);
                                    }}
                                  >
                                    edit
                                  </Button>
                                )}
                              </StyledTableCell>

                            </StyledTableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                }
              </TableContainer>
            ) : (
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  textAlign: "center",
                }}
              >
                No Data Found.
              </p>
            )}
          </Paper>
        </Fade>
      </Modal>
      {openPdf === true &&
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPdf}
            onClose={handlePdfModelClose}
            closeAfterTransition
          >
            <Fade in={openPdf}>
              {
                (fileLink?.includes(".jpg") || fileLink?.includes(".jpeg") || fileLink?.includes(".png")) ?
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img
                      src={`${fileLink}`}
                      style={{ width: "95vh", height: "80%" }}
                      title="IMAGE Document"
                    />
                  </div>
                  : (
                    (fileLink?.includes(".doc") || fileLink?.includes(".docx")) ?
                      <div id="mera-doc-viewer">
                        {/* <DocViewer
                          style={{ height: "-webkit-fill-available" }}
                          pluginRenderers={DocViewerRenderers}
                          documents={[{ uri: fileLink, fileType: "doc" }]}
                        /> */}
                      </div>
                      :
                      <iframe
                        src={`${fileLink}`}
                        style={{ width: "80%", height: "750px" }}
                        title="PDF Document"
                      ></iframe>
                  )
              }
            </Fade>
          </Modal>
        }
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  updateClinicalHistoryAnswer
})(ClinicalModal);