import React, { useState } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import {
  Button,
  Paper,
  TextField,
  Grid,
  Chip,
  TableContainer,
  Dialog,
} from "@mui/material";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import moment from "moment";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import { useEffect } from "react";
import {
  getLabCredential,
  getLabLogs,
  resetLogs,
} from "../../actions/TechSupportActions";
import { useHistory } from "react-router-dom";
import Loader from "../loader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  TableFooter,
  TablePagination,
} from "@material-ui/core";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    fontWeight: {
      fontWeight: "bold",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "1rem 2rem 2rem 2rem",
      width: "100%",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    hoveItem: {
      cursor: "pointer",
    },

    chips: {
      display: "flex",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

interface Props {
  loading: boolean;
  getLabCredential: any;
  labCredential: any;
  getLabLogs: any;
  labLogs: any;
  resetLogs: any;
}
const CreateLabs: React.FC<Props> = ({
  loading,
  getLabCredential,
  labCredential,
  getLabLogs,
  labLogs,
  resetLogs,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getLabCredential();
  }, []);
  const timer = useRef<any>(0);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [openLogs, setOpenLogs] = useState<any>(false);
  const handleClear = () => {
    history.push("/dashboard/ts/lab_master");
  };
  const handlePackageMasterPagination = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = labCredential?.links && labCredential?.links.next.split("?")[1];
      getLabCredential(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        labCredential?.links && labCredential?.links.previous.split("?")[1];
      getLabCredential(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };
  const handleLog = (id: any) => {
    getLabLogs(`?lab_cre=${id}`);
    setOpenLogs(true);
  };
  const handleLogClose = () => {
    resetLogs();
    setOpenLogs(false);
  };

  const handleEdit = ((list: any) => {
    history.push(`/dashboard/ts/create_lab_master/${list?.id}`)
  })
  return (
    <main className={classes.content}>
        <div className={classes.toolbar} />
      <Grid item xs={12}>
        <Paper style={{ marginTop: 7 }} elevation={5}>
          <Grid container>
            <Grid xs={12}>
              <h2 style={{fontWeight:"bold", textAlign:"center"}}>Lab Master</h2>
            </Grid>
            <Grid xs={10}></Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => history.push("/dashboard/ts/create_lab_master/add")}
              >
                Create
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            className={classes.centerIt}
            style={{
              margin: "1rem auto",
            }}
          >
            <Grid item xs={12} sm={6} md={2}>
              <h5 style={{ marginTop: 10 }}>Search Option </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                className="input"
                label="Name"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getLabCredential(`?name=${e.target.value}`);
                  }, 1000);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => handleClear()}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          <TableContainer style={{ height: 680 }}>
            {loading && !openLogs ? (
              <Loader />
            ) : (
              <Table size="small" stickyHeader aria-label="simple">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      S. No.
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Logs
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Lab Id
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Name
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Code
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Ownership
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Pubilc key
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Email
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Contact No.
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      City
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Associated Cities
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Address
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Longitude
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Latitude
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Riders
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Bank Name
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Bank Number
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      IFSC Code
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Bank Branch
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Bank Address
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Is Active
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Close Date
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      className={classes.fontWeight}
                    >
                      Action
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {labCredential?.results &&
                    labCredential?.results?.length > 0 &&
                    labCredential?.results.map((list: any, index: any) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <MessageTwoToneIcon
                            onClick={() => handleLog(list.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.labid}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.code}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.ownership}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.public_key}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.contact_number}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.city?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.associated_cities?.length > 0 &&
                            list?.associated_cities?.map((val: any) => {
                              return <Chip size="small" label={val.name} />;
                            })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.lab_address}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.longitude}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.latitude}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.riders?.length > 0 &&
                            list?.riders?.map((val: any) => {
                              return <Chip size="small" label={val.user} />;
                            })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.rcl_bank_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.rcl_bank_number ? list?.rcl_bank_number : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.rcl_bank_ifsc ? list?.rcl_bank_ifsc : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.rcl_bank_branch ? list?.rcl_bank_branch : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.rcl_bank_address
                            ? list?.rcl_bank_address
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.is_active === true ? "Yes" : "No"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.close_date}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            onClick={() => handleEdit(list)}
                          >
                            Edit
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            )}
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={labCredential?.count}
                rowsPerPage={labCredential?.page_size}
                page={page}
                onPageChange={handlePackageMasterPagination}
              />
            </TableFooter>
          </TableContainer>
        </Paper>
      </Grid>
      <Dialog
        open={openLogs}
        onClose={() => handleLogClose()}
        fullWidth
        maxWidth="md"
      >
        <TableContainer style={{ height: 400, padding: 20 }}>
          {loading ? (
            <Loader />
          ) : (
            <Table size="small" stickyHeader aria-label="simple">
              <TableBody>
                {labLogs && labLogs.results && labLogs.results.length > 0
                  ? labLogs.results.map((val: any, index: any) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell style={{ width: 190 }}>
                        {moment(val?.created_at).format(
                          "DD-MM-YYYY, hh:mm A"
                        )}
                        :
                      </StyledTableCell>
                      <StyledTableCell>{val?.comment}</StyledTableCell>
                    </StyledTableRow>
                  ))
                  : "NO LOGS FOUND!"}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Dialog>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  labCredential: state.TechSupportReducer.labCredential,
  labLogs: state.TechSupportReducer.labLogs,
  loading: state.TechSupportReducer.loading,
});
export default connect(mapStateToProps, {
  getLabCredential,
  resetLogs,
  getLabLogs,
})(CreateLabs);
