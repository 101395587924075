import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  TablePagination,
  TableFooter,
  withStyles,
} from "@material-ui/core";
import { Button, Chip } from "@material-ui/core";
import { genrateCentersFilter } from "../../../helpers/generateUrl";
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import {
  getPanelUserListTeamLead,
  updateCenter,
  getCentre,
} from "../../actions/TechSupportActions";
import Loader from "../loader";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    paper: {
      padding: "1rem",
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.2rem",
    },
    actionButton: {
      margin: "0 0.5rem",
      cursor: "pointer",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      textTransform: "uppercase",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getCentre: any;
  centreData: any;
  loading: Boolean;
  getPanelUserListTeamLead: any;
  teamLeadList: any;
  updateCenter: any;
}

const TechManageCenters: React.FC<Props> = ({
  getCentre,
  centreData,
  getPanelUserListTeamLead,
  teamLeadList,
  updateCenter,
  loading,
}) => {
  const history = useHistory();
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [editUser, setEditUser] = useState("");
  const [assignedUser, setAssignedUser] = useState<any>([]);
  const [assignedUserId, setAssignedUserId] = useState<any>([]);
  const [center, setCenter] = useState<any>("");
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);

  useEffect(() => {
    getCentre();
    getPanelUserListTeamLead();
  }, []);

  const handleUserList = (user: any) => {
    setEditUser(user?.id);
    setAssignedUser(user?.asigned);
    setAssignedUserId(user?.asigned.map((item: any) => item?.id));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = centreData?.links && centreData?.links?.next.split("?")[1];
      getCentre(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url = centreData?.links && centreData?.links?.previous.split("?")[1];
      getCentre(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };

  const handleSubmit = async (user: any) => {
    setEditUser("");
    const body: any = {
      asigned: assignedUserId,
    };
    await updateCenter(body, user.id);
    setTimeout(() => {
      getCentre();
    }, 6000);
    getCentre();
  };

  const filterPanelUser = async () => {
    setDisableFilterBtn(true);
    const body: any = {
      center: center,
    };
    const url = genrateCentersFilter(body).substring(2);
    getCentre(`${url}`);
  };

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={10}>
            <h2>Manage Centers</h2>
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="center"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCenter(obj.name);
                }
              }}
              options={centreData?.results || []}
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => `${option.name}`}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCentre(`?name_or_code=${newInputValue}&show_all=true`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Select Center"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterPanelUser}
              disabled={disableFilterBtn}
            >
              Filter
            </Button>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/ts/centers")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <div style={{ width: "100%" }} className="data-table">
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "900px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      Centers name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Center code
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Center type
                    </StyledTableCell>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center">Mobile</StyledTableCell>
                    <StyledTableCell align="center">Address</StyledTableCell>
                    <StyledTableCell align="center">Owner name</StyledTableCell>
                    <StyledTableCell align="center">Org type</StyledTableCell>
                    <StyledTableCell align="center">
                      Assigned Agent
                    </StyledTableCell>
                    <StyledTableCell align="center">Edit</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {centreData?.results &&
                    centreData?.results.length > 0 &&
                    centreData?.results?.map((code: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {code?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code?.center_code}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code?.center_type}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code.area.city_name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code?.mobile}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code?.address}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code?.owner_name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {code?.org_type}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser === code.id ? (
                              <Autocomplete
                                id="agent_assigned"
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    let panelUserArr: any = [];
                                    let panelUserId: any = [];
                                    newValue.map((val: any) => {
                                      let obj = JSON.parse(
                                        JSON.stringify(val, null, " ")
                                      );
                                      panelUserArr.push(obj);
                                      panelUserId.push(obj.id);
                                    });
                                    setAssignedUser(panelUserArr);
                                    setAssignedUserId(panelUserId);
                                  }
                                }}
                                value={assignedUser}
                                onInputChange={(event, newInputValue) => {
                                  clearTimeout(timer.current);
                                  timer.current = setTimeout(() => {
                                    getPanelUserListTeamLead(`?code=${newInputValue}`);
                                  }, 1000);
                                }}
                                multiple
                                disableClearable
                                disableCloseOnSelect
                                limitTags={1}
                                options={teamLeadList?.results || []}
                                freeSolo
                                blurOnSelect
                                getOptionDisabled={(option: any) => {
                                  return assignedUserId.includes(option.id);
                                }}
                                getOptionLabel={(option: any) =>
                                  option?.user && option?.user?.name
                                }
                                renderTags={(value: string[], getTagProps) =>
                                  value.map((option: any, index: number) => (
                                    <Chip
                                      key={index}
                                      variant="default"
                                      color="primary"
                                      label={option?.user && option?.user?.name}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Panel User"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      minWidth: "200px",
                                    }}
                                    className="input"
                                  />
                                )}
                              />
                            ) : (
                              <div>
                                <div style={{ padding: "5px 5px 5px 5px" }}>
                                  {code?.asigned?.map((data: any) => {
                                    return (
                                      <span
                                        key={data.id}
                                        style={{ padding: "0px 0px 0px 10px" }}
                                      >
                                        {data},{" "}
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editUser === code.id ? (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleSubmit(code)}
                              >
                                <CheckCircleOutlineRounded />
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleUserList(code)}
                              >
                                <Edit />
                              </Button>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={centreData?.count || 0}
                      rowsPerPage={centreData?.page_size}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            )}
          </TableContainer>
        </div>
        {centreData && centreData.length === 0 && (
          <h3 className={classes.heading} style={{ textAlign: "center" }}>
            No Data Found
          </h3>
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  teamLeadList: state.TechSupportReducer.teamLeadList,
  centreData: state.TechSupportReducer.centreData,
});

export default connect(mapStateToProps, {
  getPanelUserListTeamLead,
  updateCenter,
  getCentre,
})(TechManageCenters);
