import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import { TextField, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import {
  getPackage,
  createPackageAlias,
  getPackageAlias,
} from "../../actions/TechSupportActions";
import Autocomplete from "@material-ui/lab/Autocomplete";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  setOpen: any;
  open: boolean;
  packageList: any;
  createPackageAlias: any;
  getPackageAlias: any;
  cityComments: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  cityComments,
  setOpen,
  open,
  packageList,
  createPackageAlias,
  getPackageAlias,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [alias, setAlias] = useState<any>("");
  const [packageId, setPackageId] = useState<any>("");

  useEffect(() => {
    getPackageAlias(`?org_type=homedx&is_active=all`);
    getPackage(`org_type=homedx`);
  }, []);

  const updateAlias = async () => {
    const body: any = {
      package: packageId,
      name: alias,
    };
    await createPackageAlias(body);
    getPackageAlias(`?org_type=homedx&is_active=all`);
    history.push("/dashboard/ts/alias");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Add Package Alias
              </h4>
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              
              spacing={1}
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  id="package_1"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setPackageId(obj?.id);
                    }
                  }}
                  options={packageList?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  getOptionLabel={(option: any) => option?.name}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPackage(`org_type=homedx&search=${newInputValue}`);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setPackageId("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="TEST NAME"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="alias"
                  type="text"
                  value={alias}
                  className="input"
                  label="ENTER ALIAS"
                  variant="outlined"
                  disabled={packageId === ""}
                  onChange={(e) => setAlias(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={updateAlias}
                  disabled={packageId === "" || alias === ""}
                >
                  Create Alias
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  cityAlias: state.TechSupportReducer.cityAlias,
  cities: state.TechSupportReducer.cities,
  packageList: state.TechSupportReducer.packageList,
});

export default connect(mapStateToProps, {
  createPackageAlias,
  getPackageAlias,
})(CommentsModal2);
