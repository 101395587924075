import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Card,
  Checkbox,
  Grid,
  Paper,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DialogContentText from "@mui/material/DialogContentText";
import {
  getPackage,
  getNewPackage,
  getBookingById,
  getCoupons,
  getAvailableSlots,
  getMapMyIndiaAccessToken,
  checkGeoFenchMapMyIndia,
  getTimeslots,
  SendLocationRequest,
  getNewLeadDetails,
  getRedCoing,
  getLeadSubscriptionsData,
  checkGeoFenchMapMyIndiaPilot,
  getRefDoctor,
  postRefDoctor,
  postRemoveSubscription,
  getContainerSlots,
  putFamilyMemberBookingForUpdate,
  postPackageAnswer,
  getPackageQuestion,
  editBookingInSales,
  addUniqueCustomer,
  getCouponAutoApply,
  getVIPActivationStatus,
} from "../../../actions/TechSupportActions";
import Button from "@mui/material/Button";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import Loader from "../../Loader2";
import { GENDER_CHOICE } from "../../constant";
import { setTimeout } from "timers";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import OutlinedInput from "@mui/material/OutlinedInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ImageModal from "../../../components/ImageModal/index";
import testList from "../../../../helpers/additionalTest";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
    },
    customTooltip: {
      minWidth: "600px",
      width: "600px",
      backgroundColor: "white",
      padding: "10px",
      fontSize: "16px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "175px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "175px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedNotAvailableSlot: {
      width: "175px",
      height: "80px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dff0, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    dialog: {
      zIndex: 2,
    },
    paper1: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "95%",
      height: "700px",
      overflow: "scroll",
      border: "2px solid green",
      borderRadius: "5px",
    },
    tableContainer: {
      marginTop: "8px",
      top: "200px",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "60vh",
      width: "50vh",
    },
  },
};

interface Props {
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
  getTimeslots: any;
  timeslots: any;
  userDetails: any;
  getNewPackage: any;
  SendLocationRequest: any;
  getNewLeadDetails: any;
  newLeadDetails: any;
  getRefDoctor: any;
  refDoctor: any;
  postRefDoctor: any;
  postRefReducer: any;
  getRedCoing: any;
  redCoin: any;
  getLeadSubscriptionsData: any;
  leadSubscriptionsData: any;
  postRemoveSubscription: any;
  getContainerSlots: any;
  containerSlots: any;
  bookingListLoader: any;
  editBookingInSales: any;
  putFamilyMemberBookingForUpdate: any;
  familyMemberUpdateData: any;
  addUniqueCustomer: any;
  uniqueCustomerData: any;
  postPackageAnswer: any;
  getPackageQuestion: any;
  packageQuestion: any;
  checkGeoFenchMapMyIndiaPilot: any;
  getCouponAutoApply: any;
  couponAutoApplied: any;
  getVIPActivationStatus: any;
  vipActivationStatus: any;
}

const EditBookingForm: React.FC<Props> = ({
  packageList,
  getPackage,
  couponList,
  getCoupons,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  loading,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  getTimeslots,
  timeslots,
  userDetails,
  getNewPackage,
  SendLocationRequest,
  getNewLeadDetails,
  newLeadDetails,
  getRefDoctor,
  refDoctor,
  postRefDoctor,
  postRefReducer,
  getRedCoing,
  redCoin,
  getLeadSubscriptionsData,
  leadSubscriptionsData,
  postRemoveSubscription,
  getContainerSlots,
  containerSlots,
  bookingListLoader,
  editBookingInSales,
  putFamilyMemberBookingForUpdate,
  familyMemberUpdateData,
  addUniqueCustomer,
  uniqueCustomerData,
  postPackageAnswer,
  getPackageQuestion,
  packageQuestion,
  checkGeoFenchMapMyIndiaPilot,
  getCouponAutoApply,
  couponAutoApplied,
  getVIPActivationStatus,
  vipActivationStatus,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [cType, setCType] = useState<any>("none");
  const [cValue, setCValue] = useState<any>("");
  const [referralCouponDiscount, setReferralCouponDiscount] = useState<any>("");
  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [booking_date, setBooking_date] = useState<string>(
    booking.booking_date
  );
  const [timeSlots, setTimeSlots] = useState<
    Array<{ available_slots: number }>
  >([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [customer_name, setCustomer_name] = useState<String>(
    booking.customer_name
  );
  const [customer_designation, setCustomer_designation] = useState<string>();
  const [designation, setDesignation] = useState<String>();
  const [customer_age, setCustomer_age] = useState<string>(
    booking.customer_age
  );
  const [customer_gender, setCustomer_gender] = useState<string>();
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking.customer_phonenumber
  );
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );
  const [emailValidation, setEmailValidation] = useState<any>(true);
  const [customer_landmark, setCustomer_landmark] = useState<String>(
    booking?.customer_landmark
  );
  const [landmark, setLandmark] = useState<any>(null);
  const [customer_longitude, setCustomerLongitude] = useState<any>("");
  const [customer_latitude, setCustomerLatitude] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [customer_areaname, setCustomer_areaname] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.area
  );
  const [customer_address, setCustomer_address] = useState<String>(
    booking.customer_address && booking.customer_address
  );
  const [addressLine2, setAddressLine2] = useState<any>(
    booking?.address_line2 && booking?.address_line2
  );
  const [customer_areapincodeDef, setCustomer_areapincodeDef] =
    useState<Number>(booking.customer_areapincode);
  const [favoritePlace, setFavoritePlace] = useState<any>(
    booking?.landmark && booking?.landmark
  );
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>(
    booking.customer_aadhar
  );
  const [adharPassport, setAdharPassport] = useState(
    booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
  );
  const [imgSrc, setImgSrc] = useState<any>("");
  const [partner, setPartner] = useState<String>(
    booking.partner && booking.partner.id
  );
  const [packageDetails, setPackageDetails] = useState<any>(booking?.packages);
  const [packageEditAdditionalMember, setPackageEditAdditionalMember] =
    useState<any>();
  const [packageDetailsOld, setPackageDetailsOld] = useState<any>(
    booking.packages
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cValueForNum, setCValueForNum] = useState<any>(0);
  const [packageDetailsAdditionalMembers, setPackageDetailsAdditionalMembers] =
    useState<any>(
      booking?.additional_members
        ?.filter(
          (mem: any) =>
            mem?.pickup_status !== "cancelled" &&
            mem?.booking_status !== "cancelled"
        )
        .map((p: any) => {
          return p.packages;
        })
    );
  const [
    packageDetailsAdditionalMembersNewArray,
    setPackageDetailsAdditionalMembersNewArray,
  ] = useState<any>([]);
  const [packageKey, setPackageKey] = useState<any>(false);
  const [packages, setPackages] = useState<Number[]>(
    booking.packages &&
      booking.packages.map((pack: any) => {
        return pack.id;
      })
  );
  const [packagesCode, setPackagesCode] = useState<any>(
    booking.packages &&
      booking.packages.map((pack: any) => {
        return pack?.code;
      })
  );
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [discount, setDiscount] = useState<number>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [cityId, setCityId] = useState<any>(booking && booking.cityid);
  const [findSelectedSlot, setFindSelectedSlot] = useState<any>();
  const [phleboCost, setPhleboCost] = useState<any>(0);
  const [phleboCostOld, setPhleboCostOld] = useState<any>(0);
  const [minAmountPhleboCost, setMinAmountPhleboCost] = useState<any>(0);
  const [packKey, setPackKey] = useState<any>(0);
  const [zoneId, setZoneId] = useState<any>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [passport, setPassport] = useState<any>(
    booking?.passport_number === null ? "" : booking?.passport_number
  );
  const [aadhar, setAadhar] = useState<any>(booking?.customer_aadhar);
  const [bookingFor, setBookingFor] = useState<any>(booking?.book_for);
  const [isPrescribed, setIsPrescribed] = useState<any>(
    booking?.is_prescription
  );
  const [phleboRemarks, setPhleboRemarks] = useState<any>(
    booking?.phlebo_remarks
  );
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [redcaseCheck, setRedCashCheck] = useState<any>(
    booking?.redcash_discounted_amount
  );
  const [referralPointsDiscount, setReferralPointsDiscount] = useState<any>(0);
  const [referralPointsApplied, setReferralPointsApplied] =
    useState<any>(false);
  const [
    redProMembershipDiscountPercentage,
    setRedProMembershipDiscountPercentage,
  ] = useState<any>(0);
  const [redProMembershipDiscount, setRedProMembershipDiscount] =
    useState<any>(0);
  const [giftCardDiscount, setGiftCardDiscount] = useState<any>(0);
  const [giftCardTotalDiscount, setGiftCardTotalDiscount] = useState<any>(0);
  const [alreadyARedPro, setAlreadyARedPro] = useState<any>(false);
  const [file1, setFile1] = React.useState<any>("");
  const [percentageApplyFlag, setPercentageApplyFlag] =
    useState<boolean>(false);
  const [pickup_status, setPickup_status] = useState("");
  const [requestedForHardCopy, setRequestedForhHardCopy] = useState<any>(false);
  const [referringDoctor, setReferringDoctor] = useState<any>("");
  const [referringDoctorName, setReferringDoctorName] = useState<any>("");
  const [createNewRefDoctor, setCreateNewRedDoctor] = useState<any>("");
  const [doctorDef, setDoctorDef] = useState<any>("");
  const [bookingType, setBookingType] = useState<any>(booking?.booking_type);
  const [hardCopyPrice, setHardCopyPrice] = useState<any>(0);
  const [refresh, setRefresh] = useState<any>(false);
  const [removePhleboCharge, setRemovePhleboCharges] = useState<any>(false);
  const [removeDiscountCharge, setRemoveDiscountCharge] = useState<any>(false);
  const [redCoinMessage, setRedCoinMessage] = useState<any>("");
  const [redCashButton, setRedCashButton] = useState<boolean>(false);
  const [openAddonTest, setOpenAddonTest] = useState<boolean>(false);
  const [currMem, setCurrMem] = useState<any>("");
  const [currPkgs, setCurrPkgs] = useState<any>("");
  const [updateRedCash, setUpdateRedCash] = useState<boolean>(false);
  const [totalRedCoin, setTotalRedCoin] = useState<any>("");
  const [redDisc, setRedDisc] = useState<any>(0);
  const [totalRedCash, setTotalRedCash] = useState<any>(0);
  const [redCashDiscountPercentage, setRedCashDiscountPercentage] =
    useState<any>(0);
  const [openLoyaltyModal, setOpenLoyaltyModal] = useState<boolean>(false);
  const [checkedWay, setCheckedWay] = useState<any>("");
  const [proMembershipPrice, setProMembershipPrice] = useState<any>(0);
  const [applyCouponCheker, setautoApplyCouponCheker] = useState<any>("none");
  // const [containerDate, setContainerDate] = useState<String>("");
  // const [containerSlot, setContainerSlot] = useState<String>("");
  // const [containerObject, setContainerObject] = useState<any>({
  //   start_time: "",
  //   end_time: ""
  // });
  // const [checkedContainerDeliverySlot, setCheckedContainerDeliverySlot] = useState<any>("");
  const [additionalMembersStatus, setAdditionalMembersStatus] = useState<any>();
  // const [dateValidation, setDateValidation] = useState<any>(0);
  const [phleboCostForPremiumSlot, setPhleboCostForPremiumSlot] =
    useState<any>(0);
  // const [deleteUrineData, setDeleteUrineData] = useState<boolean>(booking?.delivery_date !== null ? false : true);
  const [disableUpdateButton, setDisableUpdateButton] =
    useState<boolean>(false);
  const bookingId = match.params.id;
  const timer = useRef<any>(0);
  const [addMemberNumber, setAddMemberNumber] = useState<any>(
    booking?.additional_members?.length
  );
  const [mainMemberPrice, setMainMemberPrice] = useState<any>(0);
  const [totalPriceForBooking, setTotalPriceForBooking] = useState<any>(0);
  const [
    bookingUpdateDisabledWithoutSaveCount,
    setBookingUpdateDisabledWithoutSaveCount,
  ] = useState<number>(0);
  const [normalMember, setNormalMember] = useState<any>(0);
  const [showFemaleInMain, setShowFemaleInMain] = useState<boolean>(false);
  const [RedCashCheker, setRedCashCheker] = useState<any>(false);
  const [isMatchSelectedSlot, setIsMatchSelectedSlot] = useState<any>(false);
  const [allergyQuestionAnswer, setAllergyQuestionAnswer] = useState<any>([]);
  const [packagePriceUpdate, setPackagePriceUpdate] = useState<any>(false);
  const [saveNewAddress, setSaveNewAddress] = useState<String>("");
  const [zoneKey, setZoneKey] = useState<any>(0);
  const [currUser, setCurrUser] = useState<any>("");
  const [clinicalQuestionAnswer, setClinicalQuestionAnswer] = useState<any>([]);
  const [currUserForClinical, setCurrUserForClinical] = useState<any>("");
  const [
    packagePriceAdditionalMembersUpdate,
    setPackagePriceAdditionalMembersUpdate,
  ] = useState<any>(false);

  const [bookingCustomerid, setBookingCustomerid] = useState<any>("");
  const [thyroidFemaleSlot, setThyroidFemaleSlot] = useState<boolean>(false);
  const [thyroidPhleboCost, setThyroidPhleboCost] = useState<any>(0);
  const [deniedEmailCheck, setDeniedEmailCheck] = useState<boolean>(false);
  const [patientPhoneNo, setPatientPhoneNo] = useState<any>("");
  const [isClearCoupon, setIsClearCoupon] = useState<boolean>(false);
  const [IsLoyaltyCheker, setIsLoyaltyCheker] = useState<boolean>(false);
  const [invoiceChoice, setInvoiceChoice] = useState<any>("");
  const [invoiceChoiceCheck, setInvoiceChoiceCheck] = useState<any>(false);
  const [autoApplyCoupon, setAutoApplyCoupon] = useState<any>("");
  const [checkCartValue, setCheckChartValue] = useState<any>(0);
  const [diagnosticFee, setDiagnosticFee] = useState<any>(0);

  useEffect(() => {
    getBookingById(`${bookingId}`);
    getVIPActivationStatus(`${bookingId}/?is_lead=false`);
  }, [bookingId]);

  const SendLocationMessage = async () => {
    let body = {
      lead_id: booking?.lead,
      address_id: booking?.customer_booking_address,
      // customer_whatsapp_phonenumber: customer_whatsapppnumber, No need
      attempt: "first",
    };
    if (
      booking?.verified_location?.customer_verified_latitude === null ||
      booking?.verified_location?.customer_verified_longitude === null ||
      booking?.verified_location?.customer_verified_latitude === "" ||
      booking?.verified_location?.customer_verified_longitude === "" ||
      booking?.verified_location?.customer_verified_latitude === 0 ||
      booking?.verified_location?.customer_verified_longitude === 0 ||
      booking?.verified_location?.customer_verified_latitude === undefined ||
      booking?.verified_location?.customer_verified_longitude === undefined
    ) {
      await SendLocationRequest(body);
    } else {
      alert("We have already customer verified address.");
    }
  };

  useEffect(() => {
    if (booking?.pk > 0 && collection_date !== booking?.collection_date) {
      setCollection_date(booking?.collection_date);
      setBooking_date(booking?.booking_date);
      setCustomerLatitude(booking?.customer_latitude);
      setCustomerLongitude(booking?.customer_longitude);
      setPackageKey(!packageKey);
      setBookingType(booking?.booking_type);
      setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
      setCustomer_name(booking?.customer_name);
      setPickup_status(booking.pickup_status);
      setRequestedForhHardCopy(booking?.requested_for_hard_copy);
      setCustomer_age(booking.customer_age);
      setUrgentBooking(booking && booking?.urgent_booking);
      setBookingCustomerid(booking?.customerid);
      setCustomer_phonenumber(booking.customer_phonenumber);
      setCustomer_alternatenumber(booking.customer_altphonenumber);
      setCustomer_whatsapppnumber(booking.customer_whatsapppnumber);
      setCustomer_email(booking.customer_email);
      setCustomer_landmark(booking?.customer_landmark);
      setCustomer_areaname(
        booking.customer_areapincode && booking.customer_areapincode.area
      );
      setRemovePhleboCharges(booking?.remove_phlebo_charges);
      setremoveDiagnosticFee(booking.remove_diagnostic_charges);
      setDoctorDef(booking?.ref_doctor);
      setCType(booking?.discounted_price?.coupon_type);
      setPriceConfirmed(booking?.price_confirmed === false ? true : false);
      setCustomer_address(booking.customer_address && booking.customer_address);
      setAddressLine2(booking?.address_line2 && booking?.address_line2);
      setCustomer_areapincodeDef(booking.customer_areapincode);
      setFavoritePlace(booking?.landmark && booking?.landmark);
      setRedCashCheck(booking?.redcash_discounted_amount);
      setRedDisc(booking?.redcash_discounted_amount);
      setCustomer_aadhar(booking?.customer_aadhar);
      setPitchedPrice(booking?.pitched_price);
      setRedCashButton(booking?.redcash_discounted_amount === 0 ? false : true);
      setAdharPassport(
        booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
      );
      setInvoiceChoice(booking?.invoice_type);
      setPartner(booking.partner && booking.partner.id);
      setPackageDetails(booking.packages || []);
      setPackageDetailsOld(booking.packages);
      setDeniedEmailCheck(booking.is_customer_email_deny);
      setPackages(
        booking.packages &&
          booking.packages.map((pack: any) => {
            return pack.id;
          })
      );
      setPackagesCode(
        booking.packages &&
          booking.packages.map((pack: any) => {
            return pack?.code;
          })
      );
      setCustomer_areapincode(booking?.customer_areapincode?.id);
      setCustomer_city(booking?.customer_areapincode?.city?.city as String);
      setZoneId(booking?.customer_zone);
      setPackageDetailsAdditionalMembers(
        booking?.additional_members
          ?.filter(
            (mem: any) =>
              mem?.pickup_status !== "cancelled" &&
              mem?.booking_status !== "cancelled"
          )
          .map((p: any) => {
            return p.packages;
          })
      );
      setAdditionalMembersStatus(
        booking?.additional_members?.filter(
          (mem: any) =>
            mem?.pickup_status === "cancelled" ||
            mem?.booking_status === "cancelled"
        )
      );
      // setPrice(
      //   booking?.discounted_price?.total_price_package
      // );
      setDiscount(
        booking.discounted_price && booking.discounted_price.counpon_discount
      );
      setExtraCharge(
        booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
      );
      setCityId(booking && booking.cityid);
      setPassport(
        booking?.passport_number === null ? "" : booking?.passport_number
      );
      let filterFreePack =
        booking &&
        booking?.packages &&
        booking?.packages?.filter(
          (item: any) =>
            item?.id === Number(process.env.REACT_APP_GLUCOSE_FASTING_ID) ||
            item?.id === Number(process.env.REACT_APP_GLUCOSE_RANDOM_ID)
        );
      if (filterFreePack?.length > 0) {
        setFreeGlucoseFastingTestDisabled(true);
      }
      if (Number(booking?.discounted_price?.phlebo_cost) === 150)
        setPhleboCostForPremiumSlot(booking?.discounted_price?.phlebo_cost);
      if (Number(booking?.discounted_price?.phlebo_cost) > 0)
        setPhleboCost(booking?.discounted_price?.phlebo_cost);
      if (removeDiagnosticFee === false || removeDiagnosticFee === "false") {
        setDiagnosticFee(booking?.discounted_price?.diagnostic_cost);
      } else if (removeDiagnosticFee === true) {
        setDiagnosticFee(0);
      }

      setAadhar(booking?.customer_aadhar);
      setBookingFor(booking?.book_for);
      setIsPrescribed(`${booking?.is_prescription}`);
      setPhleboRemarks(booking?.phlebo_remarks);
      setSelectedSlot(booking?.collection_slot && booking?.collection_slot?.id);
      setProMembershipPrice(booking?.discounted_price?.subscription_amount);
      setAddMemberNumber(booking?.additional_members?.length);
      if (booking?.hivform && booking?.hivform?.length !== 0) {
        setHivform({
          ref_doctor_name: booking?.hivform[0]?.ref_doctor_name,
          ref_doctor_phone: booking?.hivform[0]?.ref_doctor_phone,
          ref_by_doc_or_self:
            booking?.hivform[0]?.ref_doctor_phone?.length === 10
              ? "doctor"
              : "self",
          adhaar_card: booking?.hivform[0]?.adhaar_card,
          adhaar_number: booking?.hivform[0]?.adhaar_number,
          counselling_date: booking?.hivform[0]?.counselling_date,
          counsellor_name: booking?.hivform[0]?.counsellor_name,
          report_can_be_seen_by: booking?.hivform[0]?.report_can_be_seen_by,
        });
        setImageAddhaarURL(booking?.hivform[0]?.adhaar_card);
      }
      // setContainerDate(booking?.delivery_date);
      // if (booking?.delivery_date !== null) {
      //   setContainerSlot(booking?.delivery_collection_slot[0]?.id);
      //   setContainerObject({
      //     id: booking?.delivery_collection_slot[0]?.id,
      //     start_time: booking?.delivery_collection_slot[0]?.slot?.slice(0, 8),
      //     end_time: booking?.delivery_collection_slot[0]?.slot?.slice(9, 17)
      //   });
      // }
      // setCheckedContainerDeliverySlot(booking?.delivery_date !== null ? "1" : "");
      // let date1 = moment(`${booking?.booking_date}`);
      // let date2 = moment().format("YYYY-MM-DD");
      // setDateValidation(-1 * (date1.diff(date2, 'days')));
      // setDeleteUrineData(booking?.delivery_date !== null ? false : true);
      if (booking?.additional_members?.length > 0) {
        setNormalMember(
          booking?.additional_members?.filter(
            (mem: any) =>
              mem?.pickup_status !== "cancelled" &&
              mem?.booking_status !== "cancelled"
          )?.length
        );
      } else {
        setNormalMember(0);
      }
      if (booking?.lead !== null && booking?.lead > 0) {
        getNewLeadDetails(`${booking?.lead}/?booking_id=${bookingId}`, "false");
        getCouponAutoApply(`${booking?.lead}/?booking_id=${bookingId}`);
      }
    }
  }, [booking]);

  useEffect(() => {
    // if (booking_status === "cancelled" || pickup_status === "cancelled")
    setUpdateRedCash(!updateRedCash);
    // if (booking?.booking_status !== booking_status || booking?.pickup_status !== pickup_status) setDiscount(0);
    if (booking?.pickup_status !== pickup_status) setDiscount(0);
    handlePackages(packageDetails);
  }, [pickup_status]);

  const [memberDiscountCheck, setMemberDiscountCheck] =
    useState<boolean>(false);
  const [memberDiscountFlag, setMemberDiscountFlag] = useState<boolean>(false);

  const [initialState, setInitialState] = useState<any>(false);
  const [inputListInitial, setInputListInitial] = useState<any>([]);
  const [inputList, setInputList] = useState<any>([
    {
      customerId: "",
      lead: "",
      customerDesignation: "",
      designation: "",
      customerName: "",
      customerAge: "",
      customerGender: "",
      customer_email: "",
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
      isSelected: false,
      is_prescribed: "",
      patient_phone_no: "",
      bookingFor: "",
      oldBookingFor: "",
      passport: "",
      aadhar: "",
      oldNameUHID: "",
      oldAgeUHID: "",
      oldGenderUHID: "",
      freeGlucoseFastingTestDisabled: false,
      freeGlucoseFastingRandomTestDisabled: false,
      lastBookingDate: "",
      previousBookedPackages: [],
      isPickupStatus: "",
      isPickupStatusPrevious: "",
      isBookingStatus: "",
      isBookingStatusPrevious: "",
      isEdit: false,
      bookingId: "",
      hivform: {
        ref_doctor_name: "",
        ref_doctor_phone: "",
        ref_by_doc_or_self: "doctor",
        adhaar_card: "",
        adhaar_number: "",
        counselling_date: "",
        counsellor_name: "",
        report_can_be_seen_by: [],
        aadhaar_url: "",
        hiv_form_id: "",
      },
      allergyForm: [],
      clinicalForm: [],
      free_test: [],
    },
  ]);

  const [hivform, setHivform] = useState<any>({
    ref_doctor_name: "",
    ref_doctor_phone: "",
    ref_by_doc_or_self: "doctor",
    adhaar_card: "",
    adhaar_number: "",
    counselling_date: "",
    counsellor_name: "",
    report_can_be_seen_by: [],
  });
  const [viewImgAadhaar, setViewImgAadhaar] = useState<boolean>(false);
  const [imageAddhaarURL, setImageAddhaarURL] = useState<any>("");
  const [imageAddhaarViewURL, setImageAddhaarViewURL] = useState<any>("");

  const handleFileSelectedHIVMainMember = async (
    e: React.ChangeEvent<{ files: any }>
  ) => {
    const base64: any = await convertBase64(e.target.files[0]);
    const newBase = base64.split(",")[1];

    setHivform((prevHivform: any) => {
      const updatedHivform = { ...prevHivform };
      updatedHivform.adhaar_card = newBase;
      return updatedHivform;
    });
  };

  const handleFileSelected = async (
    e: React.ChangeEvent<{ files: any }>,
    index: any
  ) => {
    const base64: any = await convertBase64(e.target.files[0]);
    const newBase = base64.split(",")[1];
    const list = [...inputList];
    list[index].hivform["adhaar_card"] = newBase;
    setInputList(list);
  };

  const handleCheckBox = async (data: any, index: any) => {
    const list = [...inputList];
    list[index].hivform["report_can_be_seen_by"] = [...data];
    setInputList(list);
  };

  const handleCheckBoxReferredBy = async (data: any, index: any) => {
    const list = [...inputList];
    list[index].hivform["ref_by_doc_or_self"] = data;
    setInputList(list);
  };

  useEffect(() => {
    const allAdditionalMembers: any = [];
    if (
      booking &&
      booking?.additional_members &&
      booking?.additional_members?.length > 0
    ) {
      booking?.additional_members?.map((data: any) => {
        allAdditionalMembers.push({
          customerId: data?.customerid,
          lead: data?.lead,
          customerDesignation:
            data?.customer_gender?.trim()?.toLowerCase() === "female" ||
            data?.designation?.trim()?.toLowerCase() == "ms"
              ? "Ms"
              : "Mr",
          designation:
            data?.customer_gender?.trim()?.toLowerCase() === "female" ||
            data?.designation?.trim()?.toLowerCase() == "ms"
              ? "Ms"
              : "Mr",
          customerName: data?.customer_name,
          customer_email: data?.customer_email,
          customerAge: data?.customer_age !== null ? data?.customer_age : "",
          customerGender:
            data?.customer_gender?.trim()?.toLowerCase() === "female" ||
            data?.designation?.trim()?.toLowerCase() == "ms"
              ? "female"
              : "male",
          customerPackage: data?.packages?.length > 0 ? data?.packages : [],
          packagePrice: 0,
          dateOfBirth: data?.dob !== null ? data?.dob : "",
          unique_customer_number: data.unique_customer_number,
          patient_phone_no:
            data.patient_phone_no !== null ? data.patient_phone_no : "",
          isSelected: false,
          is_prescribed: "",
          bookingFor: data?.book_for,
          oldBookingFor: data?.book_for,
          passport: "",
          aadhar: "",
          oldNameUHID: data?.customer_name,
          oldAgeUHID: data?.customer_age,
          oldGenderUHID: data.customer_gender,
          freeGlucoseFastingTestDisabled: false,
          freeGlucoseFastingRandomTestDisabled: false,
          lastBookingDate: "NA",
          previousBookedPackages: [],
          isPickupStatus: data?.pickup_status,
          isPickupStatusPrevious: data?.pickup_status,
          isBookingStatus: data?.booking_status,
          isBookingStatusPrevious: data?.booking_status,
          isEdit: false,
          bookingId: data?.id,
          hivform: {
            ref_doctor_name:
              data?.hivform?.length === 0
                ? ""
                : data?.hivform[0]?.ref_doctor_name,
            ref_doctor_phone:
              data?.hivform?.length === 0
                ? ""
                : data?.hivform[0]?.ref_doctor_phone,
            ref_by_doc_or_self:
              data?.hivform?.length === 0 ||
              data?.hivform[0]?.ref_doctor_phone?.length === 10
                ? "doctor"
                : "self",
            adhaar_card:
              data?.hivform?.length === 0 ? "" : data?.hivform[0]?.adhaar_card,
            adhaar_number:
              data?.hivform?.length === 0
                ? ""
                : data?.hivform[0]?.adhaar_number,
            counselling_date:
              data?.hivform?.length === 0
                ? ""
                : data?.hivform[0]?.counselling_date,
            counsellor_name:
              data?.hivform?.length === 0
                ? ""
                : data?.hivform[0]?.counsellor_name,
            report_can_be_seen_by:
              data?.hivform?.length === 0
                ? []
                : data?.hivform[0]?.report_can_be_seen_by,
            aadhaar_url:
              data?.hivform?.length === 0 ? "" : data?.hivform[0]?.adhaar_card,
            hiv_form_id:
              data?.hivform?.length === 0 ? "" : data?.hivform[0]?.id,
          },
          allergyForm: [],
          clinicalForm: [],
          free_test: data.free_test,
        });
      });
      setInputList(allAdditionalMembers);
      setInputListInitial(allAdditionalMembers);
    }
  }, [booking?.additional_members, initialState]);

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list = [...inputList];
    const [objectKey, propertyName] = name?.split(".") ?? [];
    if (name === "passport") {
      const result = value.replace(/[^A-Z0-9]/, "");
      list[index][name] = result;
    } else if (name === "customerName") {
      const result = value.replace(/[^a-zA-Z ]/g, "");
      list[index][name] = result;
    } else if (name === "dateOfBirth") {
      const birthDateObj = new Date(value);
      const currentDate = new Date();
      const yearsDifference =
        currentDate.getFullYear() - birthDateObj.getFullYear();
      if (
        currentDate.getMonth() < birthDateObj.getMonth() ||
        (currentDate.getMonth() === birthDateObj.getMonth() &&
          currentDate.getDate() < birthDateObj.getDate())
      ) {
        list[index]["customerAge"] = yearsDifference - 1;
      } else {
        list[index]["customerAge"] = yearsDifference;
      }
      list[index][name] = value;
    } else if (objectKey === "hivform") {
      if (
        propertyName === "counsellor_name" ||
        propertyName === "ref_doctor_name"
      ) {
        list[index][objectKey][propertyName] = value
          .trimStart()
          .replace(/[^a-zA-Z ]/g, "");
      } else {
        list[index][objectKey][propertyName] = value;
      }
    } else {
      list[index][name] = value;
    }
    if (e.target.name == "designation") {
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.display_name == e.target.value
      );
      list[index]["customerGender"] = findGender[0]?.gender;
      list[index]["customerDesignation"] = findGender[0]?.db_name;
      getPackage(
        `city_id=${cityId}&org_type=homedx&gender=${findGender[0]?.gender}`
      );
    }
    if (e.target.name === "patient_phone_no") {
      const result = value.replace(/\D/g, "");
      list[index][name] = result;
    }
    setInputList(list);
  };

  const [uhidModalOpen, setUhidmodalOpen] = useState<boolean>(false);
  const [allUHIDCustomer, setAllUHIDCustomer] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [editBookingForIndex, setEditBookingForIndex] = useState<number>(-1);
  const [bookingForText, setBookingForText] = useState<string>("");
  const [selfExistsInLead, setSelfExistsInLead] = useState<boolean>(false);
  const [gridSize, setGridSize] = useState({ xs: 11, md: 11 });
  const [urgentBooking, setUrgentBooking] = useState<boolean>(false);
  const [removeDiagnosticFee, setremoveDiagnosticFee] = useState<any>(false);
  const [priceConfirmed, setPriceConfirmed] = useState<any>("");
  const [pitchedPrice, setPitchedPrice] = useState<any>("");

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    const allUHID: any = [];
    const uhidArray = [booking?.customerid];
    if (
      booking?.additional_members &&
      booking?.additional_members?.length > 0
    ) {
      booking?.additional_members?.map((item: any, index: any) => {
        uhidArray.push(item?.customerid);
      });
    }
    if (
      redCoin &&
      redCoin?.uniquecustomer &&
      redCoin.uniquecustomer.length > 0
    ) {
      redCoin.uniquecustomer.map((data: any) => {
        if (!uhidArray.includes(data?.id)) {
          allUHID.push({
            customerId: data?.id,
            lead: data?.lead,
            customerDesignation:
              data?.gender?.trim()?.toLowerCase() === "female" ||
              data?.designation?.trim()?.toLowerCase() == "ms"
                ? "Ms"
                : "Mr",
            designation:
              data?.gender?.trim()?.toLowerCase() === "female" ||
              data?.designation?.trim()?.toLowerCase() == "ms"
                ? "Ms"
                : "Mr",
            customerName: data?.display_name,
            customer_email: data?.email,
            customerAge: data?.age !== null ? data?.age : "",
            customerGender:
              data?.gender?.trim()?.toLowerCase() === "female" ||
              data?.designation?.trim()?.toLowerCase() == "ms"
                ? "female"
                : "male",
            customerPackage: [],
            packagePrice: 0,
            dateOfBirth: data?.dob !== null ? data?.dob : "",
            unique_customer_number: data.unique_customer_number,
            patient_phone_no:
              data.patient_phone_no !== null ? data.patient_phone_no : "",
            isSelected: false,
            is_prescribed: "",
            bookingFor: data?.booking_for,
            oldBookingFor: data?.booking_for,
            passport: "",
            aadhar: "",
            oldNameUHID: data?.display_name,
            oldAgeUHID: data?.age !== null ? data?.age : "",
            oldGenderUHID:
              data?.gender?.trim()?.toLowerCase() === "female" ||
              data?.designation?.trim()?.toLowerCase() == "ms"
                ? "female"
                : "male",
            freeGlucoseFastingTestDisabled: false,
            freeGlucoseFastingRandomTestDisabled: false,
            lastBookingDate: data?.last_booking_created_date
              ? moment(data?.last_booking_created_date).format("Do MMMM, YYYY")
              : "NA",
            previousBookedPackages: data?.last_packages_booked
              ? data?.last_packages_booked
              : [],
            isPickupStatus: "",
            isPickupStatusPrevious: "",
            isBookingStatus: "",
            isBookingStatusPrevious: "",
            isEdit: false,
            bookingId: "existing_member_booking",
            hivform: {
              ref_doctor_name: "",
              ref_doctor_phone: "",
              ref_by_doc_or_self: "doctor",
              adhaar_card: "",
              adhaar_number: "",
              counselling_date: "",
              counsellor_name: "",
              report_can_be_seen_by: [],
              aadhaar_url: "",
              hiv_form_id: "",
            },
            allergyForm: [],
            clinicalForm: [],
            free_test: data.free_test,
          });
        }
      });
    }
    setAllUHIDCustomer(allUHID);
  }, [redCoin?.uniquecustomer]);

  const handleCloseUHID = () => {
    setUhidmodalOpen(false);
  };

  const handleUHIDAddCustomer = (data: any, index: any) => {
    const list = [...allUHIDCustomer];
    list[index]["isSelected"] = true;
    setAllUHIDCustomer(list);
  };

  const handleUHIDRemoveCustomer = (data: any, index: any) => {
    const list = [...allUHIDCustomer];
    list[index]["isSelected"] = false;
    setAllUHIDCustomer(list);
  };

  useEffect(() => {
    const selectedCustomer: any = [];
    allUHIDCustomer.map((data: any, index: any) => {
      if (data.isSelected) {
        selectedCustomer.push(data);
      }
    });
    inputList.map((data: any, index: any) => {
      if (data.bookingId === "manual_member_booking") {
        selectedCustomer.push(data);
      }
    });
    if (selectedCustomer.length !== 0) {
      if (
        booking &&
        booking?.additional_members &&
        booking?.additional_members?.length > 0
      ) {
        setInputList([...inputListInitial, ...selectedCustomer]);
      } else {
        setInputList(selectedCustomer);
      }
    } else {
      if (
        booking &&
        booking?.additional_members &&
        booking?.additional_members?.length > 0
      ) {
        setInputList([...inputListInitial]);
      } else {
        setInputList([
          {
            customerId: "",
            lead: "",
            customerDesignation: "",
            designation: "",
            customerName: "",
            customerAge: "",
            customer_email: "",
            customerGender: "",
            customerPackage: [],
            packagePrice: 0,
            dateOfBirth: "",
            isSelected: "start",
            is_prescribed: "",
            patient_phone_no: "",
            bookingFor: "",
            oldBookingFor: "",
            passport: "",
            aadhar: "",
            oldNameUHID: "",
            oldAgeUHID: "",
            oldGenderUHID: "",
            freeGlucoseFastingTestDisabled: false,
            freeGlucoseFastingRandomTestDisabled: false,
            lastBookingDate: "",
            previousBookedPackages: [],
            isPickupStatus: "",
            isPickupStatusPrevious: "",
            isBookingStatus: "",
            isBookingStatusPrevious: "",
            isEdit: false,
            bookingId: "",
            hivform: {
              ref_doctor_name: "",
              ref_doctor_phone: "",
              ref_by_doc_or_self: "doctor",
              adhaar_card: "",
              adhaar_number: "",
              counselling_date: "",
              counsellor_name: "",
              report_can_be_seen_by: [],
              aadhaar_url: "",
              hiv_form_id: "",
            },
            allergyForm: [],
            clinicalForm: [],
            free_test: [],
          },
        ]);
      }
    }
  }, [allUHIDCustomer]);

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        customerId: "",
        lead: "",
        customerDesignation: "",
        designation: "",
        customerName: "",
        customerAge: "",
        customer_email: "",
        customerGender: "",
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
        isSelected: true,
        is_prescribed: "",
        patient_phone_no: "",
        bookingFor: "",
        oldBookingFor: "",
        passport: "",
        aadhar: "",
        oldNameUHID: "",
        oldAgeUHID: "",
        oldGenderUHID: "",
        freeGlucoseFastingTestDisabled: false,
        freeGlucoseFastingRandomTestDisabled: false,
        lastBookingDate: "",
        previousBookedPackages: [],
        isPickupStatus: "",
        isPickupStatusPrevious: "",
        isBookingStatus: "",
        isBookingStatusPrevious: "",
        isEdit: false,
        bookingId: "manual_member_booking",
        hivform: {
          ref_doctor_name: "",
          ref_doctor_phone: "",
          ref_by_doc_or_self: "doctor",
          adhaar_card: "",
          adhaar_number: "",
          counselling_date: "",
          counsellor_name: "",
          report_can_be_seen_by: [],
          aadhaar_url: "",
          hiv_form_id: "",
        },
        allergyForm: [],
        clinicalForm: [],
        free_test: [],
      },
    ]);
  };

  const handleRemoveClick = (data: any, index: any) => {
    const indexOfUHID = allUHIDCustomer.findIndex(
      (item: any) => item.customerId === data?.customerId
    );
    if (indexOfUHID !== -1) {
      handleUHIDRemoveCustomer("data", indexOfUHID);
      handleClose();
    } else {
      const list = [...inputList];
      handlePackage(index, [], -1);
      list.splice(index, 1);
      setInputList(list);
    }
  };
  useEffect(() => {
    getMapMyIndiaAccessToken();
  }, []);

  useEffect(() => {
    if (familyMemberUpdateData?.booking_for?.length) {
      setInputList([...inputListInitial]);
      getRedCoing(newLeadDetails?.mobile);
    }
  }, [familyMemberUpdateData]);

  useEffect(() => {
    if (uniqueCustomerData) {
      let list = [...inputList];
      list.forEach((item, ind) => {
        if (item["customerName"] === uniqueCustomerData[0]?.display_name) {
          list[ind]["customerId"] = uniqueCustomerData[0]?.id;
          list[ind]["oldNameUHID"] = uniqueCustomerData[0]?.display_name;
        }
      });
      setInputList([...list]);
    }
  }, [uniqueCustomerData]);

  useEffect(() => {
    if (requestedForHardCopy === "true" || requestedForHardCopy === true) {
      setHardCopyPrice(150);
    } else {
      setHardCopyPrice(0);
    }
  }, [requestedForHardCopy]);

  useEffect(() => {
    if (packageDetailsAdditionalMembers?.length > 0) {
      const list: any[] = packageDetailsAdditionalMembers.reduce(
        (acc: string | any[], curr: any) => acc.concat(curr)
      );
      setPackageDetailsAdditionalMembersNewArray(list);
    }
  }, [packageDetailsAdditionalMembers]);

  useEffect(() => {
    if (
      newLeadDetails &&
      newLeadDetails?.subscriptions?.length > 0 &&
      newLeadDetails?.subscriptions[0]?.discount > 0
    ) {
      setAlreadyARedPro(true);
      setCheckedWay(`${newLeadDetails?.subscriptions[0]?.id}`);
      setRedProMembershipDiscountPercentage(
        newLeadDetails?.subscriptions[0]?.discount_percentage
      );
      if (newLeadDetails?.booking_count <= 10) {
        if (
          (newLeadDetails &&
            newLeadDetails?.subscriptions?.length > 0 &&
            new Date(newLeadDetails?.subscriptions[0]?.created_at).setSeconds(
              0,
              0
            ) < new Date(booking?.created_at).setSeconds(0, 0)) ||
          booking?.discounted_price?.subscription_discount_amount !== 0
        ) {
          setCheckedWay(`${newLeadDetails?.subscriptions[0]?.id}`);

          setRedProMembershipDiscount(
            Math.ceil(
              (totalPriceForBooking / 100) *
                newLeadDetails?.subscriptions[0]?.discount
            )
          );
        }
      }
    }
  }, [newLeadDetails, totalPriceForBooking]);

  useEffect(() => {
    if (
      booking?.giftcard !== null &&
      (booking?.giftcard_discounted_amount !== null ||
        booking?.giftcard_discounted_amount === 0)
    ) {
      const packagesOld = Number(
        booking?.discounted_price?.total_price_package
      );
      const totalPrice =
        totalPriceForBooking +
        hardCopyPrice +
        diagnosticFee +
        Math.max(phleboCost, phleboCostForPremiumSlot) -
        Math.floor(redDisc) -
        Math.floor(discount) -
        Math.floor(redProMembershipDiscount);
      setGiftCardTotalDiscount(totalPrice);
      const GiftCardValue = booking?.giftcard?.total_giftcard_value;

      if (packagesOld === totalPriceForBooking) {
        setGiftCardDiscount(booking?.giftcard_discounted_amount);
      } else {
        if (GiftCardValue > totalPrice) {
          setGiftCardDiscount(totalPrice);
        } else {
          setGiftCardDiscount(GiftCardValue);
        }
      }
    } else {
      setGiftCardDiscount(0);
    }
  }, [
    booking,
    totalPriceForBooking,
    redProMembershipDiscount,
    diagnosticFee,
    phleboCost,
    phleboCostForPremiumSlot,
    hardCopyPrice,
  ]);

  useEffect(() => {
    if (checkedWay === "") {
      setRedProMembershipDiscount(0);
    } else {
      setRedProMembershipDiscount(
        Math.ceil(
          (totalPriceForBooking / 100) * redProMembershipDiscountPercentage
        )
      );
      if (booking?.redcash_discounted_amount === 0) {
        setRedCashButton(false);
        setRedDisc(0);
      }
    }
  }, [checkedWay]);

  useEffect(() => {
    if (booking?.coupon_applied) {
      setCoupon(booking?.coupon_applied.id);
      setCouponCode(booking?.coupon_applied?.name);
    }
    if (booking?.designation && booking?.customer_gender) {
      setCustomer_designation(booking?.designation);
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.db_name == booking?.designation
      );
      findGender.length > 0 && setDesignation(findGender[0].display_name);
      setCustomer_gender(booking?.customer_gender);
      findGender[0] && findGender[0]?.display_name === "Mr"
        ? setShowFemaleInMain(false)
        : setShowFemaleInMain(true);
    }
  }, [booking]);

  const [isHitCouponAPI, setIsHitCouponAPI] = useState(false);

  useEffect(() => {
    if (isHitCouponAPI === true) {
      getCoupons("?org_type=homedx");
    }
  }, [isHitCouponAPI]);

  const [isHitPackageAPI, setIsHitPackageAPI] = useState(false);

  useEffect(() => {
    if (isHitPackageAPI === true) {
      if (booking?.customer_gender !== undefined) {
        getPackage(
          `city_id=${booking?.cityid}&gender=${booking?.customer_gender}`
        );
      }
    }
  }, [isHitPackageAPI]);

  const handleAddLoyalty = () => {
    setOpenLoyaltyModal(false);
  };

  const handleCanel = () => {
    setCheckedWay("");
    setRedProMembershipDiscountPercentage(0);
    setProMembershipPrice(0);
    setOpenLoyaltyModal(false);
    setDiscount(0);
  };

  const [isHitLeadSubscriptionsDataAPI, setIsHitLeadSubscriptionsDataAPI] =
    useState(false);

  useEffect(() => {
    if (isHitLeadSubscriptionsDataAPI === true) {
      getLeadSubscriptionsData();
    }
  }, [isHitLeadSubscriptionsDataAPI]);

  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(
      slots &&
        slots[0]?.zone_data?.time_slots?.filter(
          (item: { available_slots: number; start_time: any; id: number }) => {
            var dateNow = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
            var dateThen = moment(new Date()).format(
              `DD/MM/YYYY ${item?.start_time}`
            );
            var timeDiff = moment(dateThen, "DD/MM/YYYY HH:mm:ss").diff(
              moment(dateNow, "DD/MM/YYYY HH:mm:ss")
            );

            if (collection_date === moment().format("YYYY-MM-DD")) {
              if (
                item?.id === booking?.collection_slot?.id &&
                booking?.collection_date === moment().format("YYYY-MM-DD")
              )
                return item;
              else return timeDiff > -2700000 && item?.available_slots > 0;
            } else if (collection_date < moment().format("YYYY-MM-DD")) {
              if (item?.id === booking?.collection_slot?.id) return item;
            } else return item?.available_slots > 0;
          }
        )
    );
  }, [booking_slots, collection_date]);

  useEffect(() => {
    const checkMatch = () => {
      const matchFound =
        timeSlots &&
        timeSlots.length > 0 &&
        timeSlots.every((slot: any) => selectedSlot !== slot.id);
      setIsMatchSelectedSlot(matchFound);
    };
    checkMatch();
  }, [booking_slots, timeSlots]);

  useEffect(() => {
    if (Number(selectedSlot) > 0 && timeSlots !== undefined) {
      const findingSelectedSlot = timeSlots.filter(
        (x: any) => x.id === Number(selectedSlot)
      );
      setFindSelectedSlot(findingSelectedSlot);
    }
  }, [selectedSlot, timeSlots]);

  useEffect(() => {
    if (
      findSelectedSlot &&
      findSelectedSlot?.length > 0 &&
      findSelectedSlot[0]?.id > -1
    ) {
      setPhleboCost(
        findSelectedSlot[0]?.phlebo_cost === 100
          ? 0
          : findSelectedSlot[0]?.phlebo_cost
      );
      setPhleboCostOld(
        findSelectedSlot[0]?.phlebo_cost === 100
          ? 0
          : findSelectedSlot[0]?.phlebo_cost
      );
      setMinAmountPhleboCost(findSelectedSlot[0]?.minimum_amount_phlebo_cost);
    }
  }, [findSelectedSlot]);

  useEffect(() => {
    if (removePhleboCharge === true) {
      setPhleboCost(0);
      setPhleboCostForPremiumSlot(0);
    }
  }, [removePhleboCharge]);

  useEffect(() => {
    if (zoneId > 0 && collection_date?.length > 0) {
      if (booking?.org_type !== "imaging") {
        getAvailableSlots(collection_date, zoneId);
      } else {
        getTimeslots();
      }
    }
  }, [zoneId]);

  const getNoofPackageBuyOneGetOne = () => {
    let noOfBuyOneGetOne = 0;
    booking.additional_members &&
      booking.additional_members.map((item: any) => {
        if (item?.packages && item?.packages.length > 0) {
          item?.packages.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
            }
          });
        }
      });
    return noOfBuyOneGetOne;
  };

  const SAMPLE_20M_INTERVAL_3TIMES = ["BC052A"];
  const SAMPLE_1H_INTERVAL_3TIMES = "BC29";
  const SAMPLE_1H_INTERVAL_2TIMES = "BC031";
  const SAMPLE_2H_INTERVAL_2TIMES = "BC032A";
  const URINE_CONTAINER_WITHIN_24H = [
    "BC326",
    "BC285",
    "BC308",
    "BC351",
    "BC346",
    "BC437",
    "BC370",
    "BC397",
    "BC195",
    "BC199",
    "BC410",
    "BC413",
    "BC472",
    "BC288",
    "BC018",
    "BC289",
    "BC290",
    "BC291",
    "BC292",
    "BC293",
    "BC294",
    "BC296",
    "BC297",
    "BC54",
    "SE15",
    "BC299",
    "BC303",
    "BC314",
    "BC320",
    "BC321",
    "BC322",
    "BC323",
    "BC324",
    "BC325",
    "BC115",
    "BC124",
    "BC274",
    "BC185",
    "BC246",
    "BC416",
    "BC239",
    "BC226",
    "BC209",
    "BC404",
    "BC476",
  ];

  useEffect(() => {
    if (packageDetailsAdditionalMembers !== undefined) {
      handlePackages(booking?.packages);
    }
  }, [packageDetailsAdditionalMembers]);

  const [SAMPLE_1H_INTERVAL_3TIMES_SLOTS, setSAMPLE_1H_INTERVAL_3TIMES_SLOTS] =
    useState<Array<{ available_slots: number }>>([]);
  const [SAMPLE_1H_INTERVAL_2TIMES_SLOTS, setSAMPLE_1H_INTERVAL_2TIMES_SLOTS] =
    useState<Array<{ available_slots: number }>>([]);
  const [SAMPLE_2H_INTERVAL_2TIMES_SLOTS, setSAMPLE_2H_INTERVAL_2TIMES_SLOTS] =
    useState<Array<{ available_slots: number }>>([]);

  useEffect(() => {
    if (timeSlots?.length > 0) {
      setSAMPLE_1H_INTERVAL_3TIMES_SLOTS(
        timeSlots?.filter((single, index) => {
          if (index < timeSlots?.length - 2) {
            if (
              timeSlots[index]?.available_slots > 0 &&
              timeSlots[index + 1]?.available_slots > 0 &&
              timeSlots[index + 2]?.available_slots > 0
            ) {
              return single;
            }
          }
        })
      );
      setSAMPLE_1H_INTERVAL_2TIMES_SLOTS(
        timeSlots?.filter((single, index) => {
          if (index < timeSlots?.length - 1) {
            if (
              timeSlots[index]?.available_slots > 0 &&
              timeSlots[index + 1]?.available_slots > 0
            ) {
              return single;
            }
          }
        })
      );
      setSAMPLE_2H_INTERVAL_2TIMES_SLOTS(
        timeSlots?.filter((single, index) => {
          if (index < timeSlots?.length - 3) {
            if (
              timeSlots[index]?.available_slots > 0 &&
              timeSlots[index + 3]?.available_slots > 0
            ) {
              return single;
            }
          }
        })
      );
    }
  }, [timeSlots]);

  const handlePackages = (customerPackage: any) => {
    const isBC063TestAvailableInCustomer: boolean =
      customerPackage &&
      customerPackage?.some((item: any) => item.code === "BC063");
    const isBC063PackageAvailableInCustomer: boolean =
      customerPackage &&
      customerPackage?.some((item: any) =>
        item?.test?.some(
          (testItem: { code: string }) => testItem?.code === "BC063"
        )
      );
    if (isBC063PackageAvailableInCustomer && isBC063TestAvailableInCustomer) {
      alert(
        "You are selecting a test/package, which is already available in selected package/test added for the patient."
      );
    }
    let randomTest: {
      my_free_package: number;
      code: string;
    }[] = [];

    randomTest =
      customerPackage?.length === 0
        ? []
        : customerPackage &&
          customerPackage?.length > 0 &&
          customerPackage.filter((item: any) => {
            return item?.code === "CAMP013A" || item?.code === "CAMP012A";
          });

    if (
      randomTest?.length === 1 &&
      customerPackage?.length === 1 &&
      randomTest[0]?.my_free_package === 420
    ) {
      customerPackage = [];
    }

    if (randomTest?.length === 0) {
      setFreeGlucoseFastingTestDisabled(false);
    } else {
      setFreeGlucoseFastingTestDisabled(true);
    }

    let paisaWaaleGlucosePackageArray =
      customerPackage?.length === 0
        ? []
        : customerPackage &&
          customerPackage?.length > 0 &&
          customerPackage.filter(
            (item: any) => item?.code === "BC023" || item?.code === "BC034"
          );
    const idExists: boolean =
      customerPackage?.length === 0
        ? false
        : customerPackage?.some(
            (item: { test: { id: number; name: string }[] }) =>
              item?.test?.length > 0 &&
              item?.test?.some(
                (testItem: { id: number; name: string }) =>
                  testItem?.id ===
                    Number(process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID) ||
                  testItem?.id ===
                    Number(process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID)
              )
          );

    if (paisaWaaleGlucosePackageArray?.length !== 0) {
      let removeRandomGlucoseTest: { code: string }[] = [];
      removeRandomGlucoseTest =
        customerPackage &&
        customerPackage?.length > 0 &&
        customerPackage.filter((item: any) => {
          return item?.code !== "CAMP013A" && item?.code !== "CAMP012A";
        });
      customerPackage = removeRandomGlucoseTest;
      setFreeGlucoseFastingTestDisabled(false);
    }

    // if (booking?.agent?.usergroup !== "Phlebos") {
    //   let urinePackageList: { code: string; }[] = [];
    //   // let normalPackageList: { code: string; }[] = [];
    //   customerPackage && customerPackage?.length > 0 && customerPackage.filter((item: { code: string; }) => {
    //     URINE_CONTAINER_WITHIN_24H.forEach(obj => {
    //         if ( item?.code === obj ) {
    //           urinePackageList.push(item);
    //           return item;
    //         }
    //     });
    //   });

    //   // if (urinePackageList?.length === 0) {
    //   //   normalPackageList = customerPackage;
    //   // } else {
    //   //   customerPackage && customerPackage?.length > 0 && normalPackageList.push(customerPackage[customerPackage?.length - 1]);
    //   // }

    //   if (customerPackage && customerPackage?.length > 0) {
    //     if (additionalMembersStatus?.length !== booking?.additional_members?.length) {
    //       if (booking?.delivery_date === null) {
    //         if (urinePackageList?.length > 0) {
    //           let copyPackage = customerPackage.filter((item: { code: string; }) => !URINE_CONTAINER_WITHIN_24H.includes(item?.code));
    //           customerPackage = copyPackage;
    //           setPackageDetails(copyPackage);
    //           // alert("For adding URINE PACKAGE create another booking.")
    //         } else {
    //           setPackageDetails(customerPackage);
    //         }
    //       } else {
    //         if (urinePackageList?.length > 0) {
    //           if (urinePackageList?.length !== customerPackage?.length) {
    //             let copyPackage = customerPackage.filter((item: { code: string; }) => URINE_CONTAINER_WITHIN_24H.includes(item?.code));
    //             customerPackage = copyPackage;
    //             setPackageDetails(copyPackage);
    //             alert("You can only add URINE CONTAINER PACKAGE in this booking.")
    //           } else {
    //             setPackageDetails(customerPackage);
    //           }
    //         } else {
    //           alert("You can only select/update URINE CONTAINER package in URINE CONTAINER BOOKING. For other packages, do another booking.");
    //         }
    //       }
    //     } else {
    //       if (booking?.delivery_date === null) {
    //         if (urinePackageList?.length > 0) {
    //           let copyPackage = customerPackage.filter((item: { code: string; }) => !URINE_CONTAINER_WITHIN_24H.includes(item?.code));
    //           customerPackage = copyPackage;
    //           setPackageDetails(copyPackage);
    //           alert("Please create another booking for adding URINE PACKAGE.")
    //         } else {
    //           setCheckedContainerDeliverySlot("");
    //           setPackageDetails(customerPackage);
    //         }
    //       } else {
    //         if (urinePackageList?.length > 0) {
    //           if (urinePackageList?.length !== customerPackage?.length) {
    //             let copyPackage = customerPackage.filter((item: { code: string; }) => URINE_CONTAINER_WITHIN_24H.includes(item?.code));
    //             customerPackage = copyPackage;
    //             setPackageDetails(copyPackage);
    //           } else {
    //             setPackageDetails(customerPackage);
    //           }
    //         }else {
    //           setPackageDetails(customerPackage);
    //           setCheckedContainerDeliverySlot("");
    //         }
    //       }
    //     }
    //   } else {
    //     setPackageDetails([]);
    //   }

    //   let countUrinePack: { code: string; }[] = [];
    //   customerPackage && customerPackage?.length > 0 && customerPackage.filter((item: { code: string; }) => {
    //     URINE_CONTAINER_WITHIN_24H.forEach(obj => {
    //         if ( item?.code === obj ) {
    //           countUrinePack.push(item);
    //           return item;
    //         }
    //     });
    //   });

    //   if (countUrinePack?.length > 0) {
    //     setDeleteUrineData(false);
    //   } else {
    //     setDeleteUrineData(true);
    //   }
    // } else {
    //   setPackageDetails(customerPackage)
    // }

    if (
      !customerPackage?.some(
        (pkg: any) => pkg?.id === booking?.free_test?.free_test_for?.id
      )
    ) {
      const withoutFreeTest = customerPackage?.filter(
        (pkg: any) => pkg?.id !== booking?.free_test?.free_test?.id
      );
      // Set package details to filtered results or an empty array if no matches found
      setPackageDetails(withoutFreeTest || []);
    } else {
      setPackageDetails(customerPackage);
    }

    // setPackageDetails(customerPackage)

    let accumulatedPrice: any = 0;
    let mainMemberAccumulatedPrice: any = 0;
    let packIds: any = [];
    let packCodes: any = [];
    setRefresh(!refresh);

    // Main Member
    customerPackage &&
      customerPackage.length > 0 &&
      customerPackage.map((item: any) => {
        packIds.push(item.id);
        packCodes.push(item?.code);
      });
    if (
      customerPackage &&
      customerPackage.length > 0 &&
      pickup_status !== "cancelled"
    ) {
      let pl50Count =
        packageDetails?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
      let priceApplicable = pl50Count > 0 ? pl50Count % 2 : 0;
      customerPackage.map((item: any) => {
        packIds.push(item.id);
        packCodes.push(item?.code);
        // if (pl50Count > 0 && priceApplicable == 1 && item?.code == "PL50") {
        //   accumulatedPrice = accumulatedPrice + 0;
        // } else {

        if (item?.id !== booking?.free_test?.free_test?.id) {
          if (
            item?.package_city_prices !== null &&
            item?.package_city_prices?.offer_price >= 0
          ) {
            accumulatedPrice =
              accumulatedPrice + item?.package_city_prices?.offer_price;
          } else if (
            item?.package_city_prices === null &&
            item?.offer_price >= 0
          ) {
            accumulatedPrice = accumulatedPrice + item?.offer_price;
          } else {
            accumulatedPrice = accumulatedPrice + item?.price;
          }
        }

        // }
        // setPrice(Math.floor(accumulatedPrice));
      });
    }

    mainMemberAccumulatedPrice = accumulatedPrice;
    setMainMemberPrice(Math.floor(accumulatedPrice));

    // Additional Family Members
    if (
      booking?.additional_members &&
      booking?.additional_members?.length > 0
    ) {
      booking.additional_members
        ?.filter(
          (mem: any) =>
            mem?.pickup_status !== "cancelled" &&
            mem?.booking_status !== "cancelled"
        )
        .map((item: any, index: any) => {
          if (item.packages && item?.id !== booking?.free_test?.free_test?.id) {
            item.packages.map((pack: any) => {
              if (
                pack?.package_city_prices !== null &&
                pack?.package_city_prices?.offer_price >= 0
              ) {
                accumulatedPrice =
                  accumulatedPrice + pack?.package_city_prices?.offer_price;
              } else if (
                pack?.package_city_prices === null &&
                pack?.offer_price >= 0
              ) {
                accumulatedPrice = accumulatedPrice + pack?.offer_price;
              } else {
                accumulatedPrice = accumulatedPrice + pack?.price;
              }
            });
          }
        });
    }

    let allPackagesListCopy =
      packageDetailsAdditionalMembers !== undefined
        ? packageDetailsAdditionalMembers?.slice()
        : [];
    let differentPack: any[] = [];
    let differentPackCounts:any = {};
    allPackagesListCopy.push(customerPackage);
    allPackagesListCopy &&
      allPackagesListCopy?.length &&
      allPackagesListCopy?.map((listItem: any, index: any) => {
        listItem &&
          listItem?.length > 0 &&
          listItem?.filter((item: any) => {
            if (Number(item?.no_patients) > 1) {
              differentPack.push(item);
              if (differentPackCounts[item?.id]) {
                differentPackCounts[item?.id].count += 1;
              } else {
                differentPackCounts[item?.id] = {
                  count: 1,
                  no_patients: item?.no_patients,
                  price:
                    item?.package_city_prices === null
                      ? item?.offer_price
                      : item?.package_city_prices?.offer_price,
                };
              }
            }
          });
      });

    let sumOfDifferentPackCounts = 0;

    Object.entries(differentPackCounts).forEach(([id, item]: [string, any]) => {
      const { price, count, no_patients } = item;
      sumOfDifferentPackCounts += price * Math.floor(count / no_patients);
    });

    if (booking?.crm_created_booking) {
      let allPackagesList =
        packageDetailsAdditionalMembers !== undefined
          ? packageDetailsAdditionalMembers?.slice()
          : [];
      let allPackageAddOnPrice = 0;
      allPackagesList.push(customerPackage);
      allPackagesList &&
        allPackagesList?.length &&
        allPackagesList?.map((listItem: any, index: any) => {
          let accumulatedPrice: any = 0;
          listItem &&
            listItem?.length > 0 &&
            listItem?.map((item: any) => {
              accumulatedPrice =
                item?.package_city_prices === null
                  ? accumulatedPrice + item?.offer_price
                  : accumulatedPrice + item?.package_city_prices?.offer_price;
            });
          allPackageAddOnPrice += accumulatedPrice;
        });

      if (allPackageAddOnPrice - sumOfDifferentPackCounts >= 500) {
        let allPackageAddOnMainPrice: any = 0;
        for (
          let inputIteration = 0;
          inputIteration < allPackagesList?.length;
          inputIteration++
        ) {
          const addOnPackageFilterFalse = allPackagesList[
            inputIteration
          ]?.filter((listItem: any) => listItem?.is_addon === false);
          const addOnPackageFilterTrue = allPackagesList[
            inputIteration
          ]?.filter((listItem: any) => listItem?.is_addon === true);
          if (
            addOnPackageFilterFalse?.length > 0 &&
            addOnPackageFilterTrue?.length > 0
          ) {
            let accumulatedPriceForIteration: any = 0;
            allPackagesList[inputIteration]?.map((item: any) => {
              accumulatedPriceForIteration =
                item?.is_addon === true
                  ? accumulatedPriceForIteration + item?.addon_price
                  : item?.package_city_prices === null
                  ? accumulatedPriceForIteration + item?.offer_price
                  : accumulatedPriceForIteration +
                    item?.package_city_prices?.offer_price;
            });
            if (
              inputIteration === allPackagesList?.length - 1 &&
              pickup_status !== "cancelled"
            ) {
              // setPrice(accumulatedPriceForIteration);
              mainMemberAccumulatedPrice = accumulatedPriceForIteration;
              setMainMemberPrice(Math.floor(accumulatedPriceForIteration));
            }
            allPackageAddOnMainPrice += accumulatedPriceForIteration;
          } else {
            let accumulatedPriceForIteration: any = 0;
            allPackagesList[inputIteration]?.map((item: any) => {
              accumulatedPriceForIteration =
                item?.package_city_prices === null
                  ? accumulatedPriceForIteration + item?.offer_price
                  : accumulatedPriceForIteration +
                    item?.package_city_prices?.offer_price;
            });
            if (
              inputIteration === allPackagesList?.length - 1 &&
              pickup_status !== "cancelled"
            ) {
              // setPrice(accumulatedPriceForIteration);
              mainMemberAccumulatedPrice = accumulatedPriceForIteration;
              setMainMemberPrice(Math.floor(accumulatedPriceForIteration));
            }
            allPackageAddOnMainPrice += accumulatedPriceForIteration;
          }
        }
        // setTotalPrice(allPackageAddOnMainPrice);
        // setTotalPriceForBooking(Math.floor(allPackageAddOnMainPrice) - sumOfDifferentPackCounts);
      }
    }

    setPackages(packIds);
    setPackagesCode(packCodes);
    const allTestWithPreTest = customerPackage?.filter((v:any)=>v?.package_or_test === "test" && (v?.id === Number(process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID) || v?.id === Number(process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID)))
    const allPackages = customerPackage?.filter((v:any)=>v?.package_or_test !== "test")

    if (allTestWithPreTest?.length > 0 && allPackages?.length > 0) {
      allTestWithPreTest.map((testVID: any) => {
        allPackages.map((packageItem: any) => {
          if (packageItem?.test?.some((pacdId: any) => testVID?.id == pacdId?.id)) {
            alert(` Alert: ${testVID?.name} already exists in this Package. ${testVID?.name} - test will be removed from this member.`);
            const mainMemberPackages = customerPackage?.filter((custV:any)=>custV?.id != testVID?.id)
            setPackageDetails(mainMemberPackages || [])
          }
        });
      });
    }

    handlePackage(
      0,
      inputList[0]?.customerPackage === undefined
        ? []
        : inputList[0]?.customerPackage,
      mainMemberAccumulatedPrice
    );
  };

  const handlePackage = (
    index: number,
    customerPackages: any,
    firstMember: number
  ) => {
    const isBC063TestAvailableInCustomer: boolean =
      customerPackages &&
      customerPackages?.some((item: any) => item.code === "BC063");
    const isBC063PackageAvailableInCustomer: boolean =
      customerPackages &&
      customerPackages?.some((item: any) =>
        item?.test?.some(
          (testItem: { code: string }) => testItem?.code === "BC063"
        )
      );
    if (isBC063PackageAvailableInCustomer && isBC063TestAvailableInCustomer) {
      alert(
        "You are selecting a test/package, which is already available in selected package/test added for the patient."
      );
    }

    const list = [...inputList];

    let randomTest: { code: string }[] = [];
    randomTest =
      customerPackages &&
      customerPackages?.length > 0 &&
      customerPackages.filter((item: any) => {
        return item?.code === "CAMP013A" || item?.code === "CAMP012A";
      });

    if (randomTest?.length === 0) {
      list[index]["freeGlucoseFastingTestDisabled"] = false;
      list[index]["freeGlucoseFastingRandomTestDisabled"] = false;
    } else {
      list[index]["freeGlucoseFastingTestDisabled"] = true;
      list[index]["freeGlucoseFastingRandomTestDisabled"] = true;
    }
    // if (!customerPackages?.some((pkg: any) => pkg?.id === list[index]?.free_test?.free_test_for?.id)) {
    //   const withoutFreeTest = customerPackages?.filter((pkg: any) => pkg?.id !== list[index]?.free_test?.free_test_for?.id);
    //   setPackageDetails(withoutFreeTest || []);
    // } else {
    //   setPackageDetails(customerPackages);
    // }

    let paisaWaaleGlucosePackageArray =
      customerPackages &&
      customerPackages.filter(
        (item: any) => item?.code === "BC023" || item?.code === "BC034"
      );
    const idExists: boolean =
      customerPackages?.length > 0 &&
      customerPackages?.some(
        (item: { test: { id: number; name: string }[] }) =>
          item?.test?.length > 0 &&
          item?.test?.some(
            (testItem: { id: number; name: string }) =>
              testItem?.id ===
                Number(process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID) ||
              testItem?.id ===
                Number(process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID)
          )
      );

    if (paisaWaaleGlucosePackageArray?.length !== 0) {
      let removeRandomGlucoseTest: { code: string }[] = [];
      removeRandomGlucoseTest =
        customerPackages &&
        customerPackages?.length > 0 &&
        customerPackages.filter((item: any) => {
          return item?.code !== "CAMP013A" && item?.code !== "CAMP012A";
        });
      customerPackages = removeRandomGlucoseTest;
      list[index]["freeGlucoseFastingTestDisabled"] = false;
      list[index]["freeGlucoseFastingRandomTestDisabled"] = false;
    }

    if (randomTest?.length === 1 && customerPackages?.length === 1) {
      customerPackages = [];
      list[index]["freeGlucoseFastingTestDisabled"] = true;
      list[index]["freeGlucoseFastingRandomTestDisabled"] = true;
    }

    // if (booking?.agent?.usergroup !== "Phlebos") {
    //   // Doing it for other than Phlebos
    //   if (booking?.delivery_date !== null && booking?.delivery_collection_slot !== null) {

    //     let urinePackageList: { code: string; }[] = [];
    //     customerPackages && customerPackages?.length > 0 && customerPackages.filter((item: { code: string; }) => {
    //       URINE_CONTAINER_WITHIN_24H.forEach(obj => {
    //         if (item?.code === obj) {
    //           urinePackageList.push(item);
    //           return item;
    //         }
    //       });
    //     });

    //     if (urinePackageList?.length !== 0) {
    //       if (urinePackageList?.length !== customerPackages?.length) {
    //         let copyPackage = customerPackages?.length > 0 ? customerPackages.filter((item: { code: string; }) => URINE_CONTAINER_WITHIN_24H.includes(item?.code)) : [];
    //         customerPackages = copyPackage;
    //         setPackageEditAdditionalMember(copyPackage);
    //         alert("You can only select URINE CONTAINER package in URINE CONTAINER BOOKING.");
    //       } else if (urinePackageList?.length === customerPackages?.length) {
    //         setPackageEditAdditionalMember(customerPackages);
    //       }
    //     } else {
    //       customerPackages = [];
    //       setPackageEditAdditionalMember([]);
    //     }
    //   } else {
    //     let copyPackage = customerPackages?.length > 0 ? customerPackages.filter((item: { code: string; }) => !URINE_CONTAINER_WITHIN_24H.includes(item?.code)) : [];
    //     if (copyPackage?.length !== customerPackages?.length) alert("Please create another booking for adding URINE PACKAGE.")
    //     customerPackages = copyPackage;
    //     setPackageEditAdditionalMember(copyPackage);
    //   }
    // } else {
    //   setPackageEditAdditionalMember(customerPackages);
    // }
    setPackageEditAdditionalMember(customerPackages);

    let accumulatedPrice: any = 0;

    if (customerPackages && customerPackages.length > 0) {
      customerPackages.map((item: any) => {
        if (item.packages && item?.id !== booking?.free_test?.free_test?.id) {
          if (
            item?.package_city_prices !== null &&
            item?.package_city_prices?.offer_price >= 0
          ) {
            accumulatedPrice =
              accumulatedPrice + item?.package_city_prices?.offer_price;
          } else if (
            item?.package_city_prices === null &&
            item?.offer_price >= 0
          ) {
            accumulatedPrice = accumulatedPrice + item?.offer_price;
          } else {
            accumulatedPrice = accumulatedPrice + item?.price;
          }
        }
      });
    }

    list[index]["packagePrice"] = accumulatedPrice;
    list[index]["customerPackage"] = customerPackages;
    setInputList(list);
    let differentPack: any[] = [];
    let differentPackCounts:any = {};

    let allPackagesPriceForAdditionalMembers = 0;
    list?.map((listItem: any, index: any) => {
      let accumulatedPrice: any = 0;
      listItem?.customerPackage?.map((item: any) => {
        if (item?.id !== booking?.free_test?.free_test?.id) {
          if (
            item?.package_city_prices !== null &&
            item?.package_city_prices?.offer_price >= 0 &&
            item.id !== booking?.free_test?.free_test
          ) {
            accumulatedPrice =
              accumulatedPrice + item?.package_city_prices?.offer_price;
          } else if (
            item?.package_city_prices === null &&
            item?.offer_price >= 0 &&
            item.id !== booking?.free_test?.free_test
          ) {
            accumulatedPrice = accumulatedPrice + item?.offer_price;
          } else {
            accumulatedPrice = accumulatedPrice + item?.price;
          }
        }
      });
      if (
        listItem?.isPickupStatus !== "cancelled" &&
        listItem?.isBookingStatus !== "cancelled"
      ) {
        list[index]["packagePrice"] = accumulatedPrice;
        allPackagesPriceForAdditionalMembers += accumulatedPrice;
        list[index]["customerPackage"] &&
          list[index]["customerPackage"]?.length > 0 &&
          list[index]["customerPackage"]?.filter((item: any) => {
            if (Number(item?.no_patients) > 1) {
              differentPack.push(item);
              if (differentPackCounts[item?.id]) {
                differentPackCounts[item?.id].count += 1;
              } else {
                differentPackCounts[item?.id] = {
                  count: 1,
                  no_patients: item?.no_patients,
                  price:
                    item?.package_city_prices === null
                      ? item?.offer_price
                      : item?.package_city_prices?.offer_price,
                };
              }
            }
          });
      } else {
        list[index]["packagePrice"] = 0;
      }
    });

    const mainMemberPackage =
      packageDetails !== undefined ? [...packageDetails] : [];

    mainMemberPackage &&
      mainMemberPackage?.length > 0 &&
      mainMemberPackage?.filter((item: any) => {
        if (Number(item?.no_patients) > 1) {
          differentPack.push(item);
          if (differentPackCounts[item?.id]) {
            differentPackCounts[item?.id].count += 1;
          } else {
            differentPackCounts[item?.id] = {
              count: 1,
              no_patients: item?.no_patients,
              price:
                item?.package_city_prices === null
                  ? item?.offer_price
                  : item?.package_city_prices?.offer_price,
            };
          }
        }
      });

    let sumOfDifferentPackCounts = 0;

    Object.entries(differentPackCounts).forEach(([id, item]: [string, any]) => {
      const { price, count, no_patients } = item;
      sumOfDifferentPackCounts += price * Math.floor(count / no_patients);
    });

    let constPrice = 0;

    if (firstMember === -1) {
      constPrice = Math?.floor(
        allPackagesPriceForAdditionalMembers +
          mainMemberPrice -
          sumOfDifferentPackCounts
      );
      setTotalPriceForBooking(
        Math?.floor(
          allPackagesPriceForAdditionalMembers +
            mainMemberPrice -
            sumOfDifferentPackCounts
        )
      );
    } else {
      constPrice = Math?.floor(
        allPackagesPriceForAdditionalMembers +
          firstMember -
          sumOfDifferentPackCounts
      );
      setTotalPriceForBooking(
        Math?.floor(
          allPackagesPriceForAdditionalMembers +
            firstMember -
            sumOfDifferentPackCounts
        )
      );
    }

    if (booking?.crm_created_booking) {
      if (constPrice >= 500) {
        for (
          let inputIteration = 0;
          inputIteration < list?.length;
          inputIteration++
        ) {
          const addOnPackageFilterFalse = list[
            inputIteration
          ]?.customerPackage?.filter(
            (listItem: any) => listItem?.is_addon === false
          );
          const addOnPackageFilterTrue = list[
            inputIteration
          ]?.customerPackage?.filter(
            (listItem: any) => listItem?.is_addon === true
          );
          if (
            addOnPackageFilterFalse?.length > 0 &&
            addOnPackageFilterTrue?.length > 0
          ) {
            let accumulatedPriceForIteration: any = 0;
            list[inputIteration]?.customerPackage?.map((item: any) => {
              accumulatedPriceForIteration =
                item?.is_addon === true
                  ? accumulatedPriceForIteration + item?.addon_price
                  : item?.package_city_prices === null
                  ? accumulatedPriceForIteration + item?.offer_price
                  : accumulatedPriceForIteration +
                    item?.package_city_prices?.offer_price;
            });
            if (
              list[inputIteration]["isPickupStatus"] !== "cancelled" &&
              list[inputIteration]["isBookingStatus"] !== "cancelled"
            ) {
              list[inputIteration]["packagePrice"] =
                accumulatedPriceForIteration;
            } else {
              list[inputIteration]["packagePrice"] = 0;
            }
          } else {
            let accumulatedPriceForIteration: any = 0;
            list[inputIteration]?.customerPackage?.map((item: any) => {
              accumulatedPriceForIteration =
                item?.package_city_prices === null
                  ? accumulatedPriceForIteration + item?.offer_price
                  : accumulatedPriceForIteration +
                    item?.package_city_prices?.offer_price;
            });
            if (
              list[inputIteration]["isPickupStatus"] !== "cancelled" &&
              list[inputIteration]["isBookingStatus"] !== "cancelled"
            ) {
              list[inputIteration]["packagePrice"] =
                accumulatedPriceForIteration;
            } else {
              list[inputIteration]["packagePrice"] = 0;
            }
          }
        }

        let allPackagePrice = 0;
        list &&
          list?.map((rowItem: any) => {
            allPackagePrice = allPackagePrice + rowItem?.packagePrice;
          });

        if (firstMember === -1) {
          setTotalPriceForBooking(
            Math?.floor(
              allPackagePrice + mainMemberPrice - sumOfDifferentPackCounts
            )
          );
        } else {
          setTotalPriceForBooking(
            Math?.floor(
              allPackagePrice + firstMember - sumOfDifferentPackCounts
            )
          );
        }
      }
    }
    const allTestWithPreTest = customerPackages?.filter((v:any)=>v?.package_or_test === "test" && (v?.id === Number(process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID) || v?.id === Number(process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID)))
    const allPackages = customerPackages?.filter((v:any)=>v?.package_or_test !== "test")

    if (allTestWithPreTest?.length > 0 && allPackages?.length > 0) {
      allTestWithPreTest.map((testVID: any) => {
        allPackages.map((packageItem: any) => {
          if (packageItem?.test?.some((pacdId: any) => testVID?.id == pacdId?.id)) {
            alert(` Alert: ${testVID?.name} already exists in this Package. ${testVID?.name} - test will be removed from this member.`);
            list[index]["customerPackage"] = customerPackages?.filter((custV:any)=>custV?.id != testVID?.id)
           
          }
        });
      });
    }
    setInputList(list);
  };

  useEffect(() => {
    if (!alreadyARedPro) {
      if (
        totalPriceForBooking < minAmountPhleboCost &&
        minAmountPhleboCost > 0
      ) {
        setExtraCharge(true);
        setPhleboCost(phleboCostOld);
      } else {
        setPhleboCost(0);
        setExtraCharge(false);
      }
    } else {
      setPhleboCost(0);
      setExtraCharge(false);
    }
  }, [phleboCost, refresh]);

  useEffect(() => {
    if (
      totalPriceForBooking <= 100 &&
      packageDetails &&
      packageDetails?.length >= 0
    ) {
      const arr = packageDetails;
      let filterPackageOtherThanFree = arr.filter(
        (item: any) => item?.code !== "CAMP013A" && item?.code !== "CAMP012A"
      );
      handlePackages(filterPackageOtherThanFree);
      inputList?.length > 0 &&
        inputList?.map((item: any, index: number) => {
          if (item?.customerPackage?.length > 0) {
            let filterPackageOtherThanFreeInside =
              item?.customerPackage?.filter(
                (pack: any) =>
                  pack?.code !== "CAMP013A" && pack?.code !== "CAMP012A"
              );
            handlePackage(index, filterPackageOtherThanFreeInside, -1);
          }
        });
    }
  }, [totalPriceForBooking]);

  useEffect(() => {
    if (
      totalPriceForBooking <= 1299 &&
      packageDetails &&
      packageDetails?.length >= 0
    ) {
      const arr = packageDetails;
      let filterPackageOtherThanFree = arr.filter(
        (item: any) => item?.code !== "BC035A"
      );
      handlePackages(filterPackageOtherThanFree);
      inputList?.length > 0 &&
        inputList?.map((item: any, index: number) => {
          if (item?.customerPackage?.length > 0) {
            let filterPackageOtherThanFreeInside =
              item?.customerPackage?.filter(
                (pack: any) => pack?.code !== "BC035A"
              );
            handlePackage(index, filterPackageOtherThanFreeInside, -1);
          }
        });
    }
  }, [totalPriceForBooking]);

  useEffect(() => {
    if (
      totalPriceForBooking >= 0 &&
      booking?.discounted_price?.coupon_type === "referral" &&
      booking?.discounted_price?.referral_coupon_discount > 0
    ) {
      const discountPercentage = booking?.coupon_applied?.discount;
      setReferralCouponDiscount(
        Math.floor((totalPriceForBooking / 100) * discountPercentage)
      );
    }
  }, [totalPriceForBooking]);

  useEffect(() => {
    if (booking?.discounted_price?.coupon_type === "referral") {
      setCType("referral");
      setReferralCouponDiscount(
        booking?.discounted_price?.referral_coupon_discount
      );
    }
  }, [booking]);

  const fileSelectedHandlerReceipt = async (
    e: React.ChangeEvent<{ files: any }>
  ) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]));
    const base64 = await convertBase64(e.target.files[0]);
    setFile1(base64);
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList?.results?.find((coup: any) => coup?.id === coupon) ||
      couponList?.results?.find((coup: any) => coup?.code === couponCode);
    if (appliedCoupon && appliedCoupon?.minimum_price < totalPriceForBooking) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon?.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon?.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(
            Math.floor(
              ((totalPriceForBooking - redProMembershipDiscount) *
                appliedCoupon?.discount) /
                100
            )
          );
        }
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor(appliedCoupon?.discount));
        }
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };

  const applyCouponPerc = () => {
    setPercentageApplyFlag(true);
    if (cValue === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon = { cType: cType, cValue: cValue };
    if (true) {
      if (appliedCoupon.cType === "percentage") {
        if (appliedCoupon.cValue > 100) {
          alert("Please Select Valid Number");
        } else {
          setDiscount(
            Math.floor(
              ((totalPriceForBooking - redProMembershipDiscount) *
                appliedCoupon.cValue) /
                100
            )
          );
        }
      }
      if (appliedCoupon.cType === "amount") {
        setDiscount(Math.floor(appliedCoupon.cValue));
      }
    }
    
  };
  const applyCouponNum = () => {
    setPercentageApplyFlag(true);
    if (cValueForNum === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    else{
      setDiscount(Math.floor(cValueForNum));
    }
    
  };

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };

  const submitForm = async (e: any) => {
    setDisableUpdateButton(true);
    e.preventDefault();
    let packagesOld = packageDetailsOld.map((pack: any) => {
      return pack?.package_city_prices
        ? pack?.package_city_prices?.offer_price
        : pack?.offer_price;
    });
    let packagesOldPrice = packagesOld.reduce(
      (packSum: any, a: any) => packSum + a,
      0
    );

    let packagesNew = packageDetails.map((pack: any) => {
      return pack?.package_city_prices
        ? pack?.package_city_prices?.offer_price
        : pack?.offer_price;
    });
    let packagesNewPrice = packagesNew.reduce(
      (packSum: any, a: any) => packSum + a,
      0
    );

    let accumulatedPrice: any = 0;
    let list = [...inputList];
    list?.map((listItem: any, index: any) => {
      if (
        listItem?.isPickupStatus !== "cancelled" &&
        listItem?.isBookingStatus !== "cancelled"
      ) {
        listItem?.customerPackage?.map((item: any) => {
          if (
            item?.package_city_prices !== null &&
            item?.package_city_prices?.offer_price >= 0 &&
            item.id !== booking?.free_test?.free_test
          ) {
            accumulatedPrice =
              accumulatedPrice + item?.package_city_prices?.offer_price;
          } else if (
            item?.package_city_prices === null &&
            item?.offer_price >= 0 &&
            item.id !== booking?.free_test?.free_test
          ) {
            accumulatedPrice = accumulatedPrice + item?.offer_price;
          } else {
            accumulatedPrice = accumulatedPrice + item?.price;
          }
        });
      }
    });

    let packagesAdditionalMembersPrice = accumulatedPrice;

    packagesOldPrice = Number(booking?.discounted_price?.total_price_package); //packagesOldPrice + packagesAdditionalMembersPrice;
    packagesNewPrice = packagesNewPrice + packagesAdditionalMembersPrice;

    const body: any = {
      lead: booking?.lead,
      booking_id: Number(bookingId),
    };

    let additionalPacakges: any = [];

    inputList &&
      inputList.length > 0 &&
      inputList.map((item: any, index: number) => {
        if (
          item?.isPickupStatusPrevious !== "cancelled" &&
          item?.isBookingStatusPrevious !== "cancelled"
        ) {
          //   const freePackageforaddRemoved =
          // item?.customerPackage
          //   ?.map((pack: any) => {
          //     return !booking.additional_members[index].free_test.free_test.id.includes(item.free_test.free_test.id)
          //       ? item.free_test.free_test.id
          //       : null;
          //   })
          //   .filter((id: any) => id !== null) || [];

          const freePackageRemoved =
            item.free_test &&
            item.free_test.free_test &&
            !item.customerPackage.includes(item.free_test.free_test.id)
              ? item.free_test.free_test.id
              : null;
          if (
            (item?.bookingId === "manual_member_booking" ||
              item?.bookingId === "existing_member_booking") &&
            item?.customerId !== ""
          ) {
            const body: any = {
              additional_customerid: item?.customerId,
              customer_aadhar: item?.aadhar,
              customer_age: item?.customerAge,
              customer_gender: item?.customerGender,
              customer_name: item?.customerName,
              customerid:
                item.oldNameUHID.toLowerCase()?.replace(/\s/g, "") ===
                  item.customerName.toLowerCase()?.replace(/\s/g, "") &&
                (item.oldAgeUHID === item.customerAge ||
                  item.oldAgeUHID !== item.customerAge) &&
                (item.oldGenderUHID === item.customerGender ||
                  item.oldGenderUHID !== item.customerGender)
                  ? item.customerId
                  : "",
              book_for: item?.bookingFor,
              free_test: item?.free_test,
              is_prescription: item?.is_prescribed,
              phone_number:
                item.patient_phone_no !== null ? item.patient_phone_no : "",
              passport_number: item?.passport,
              designation: item?.designation,
              customer_email: item?.customer_email,
              packages: item?.customerPackage?.map((pack: any) => {
                return pack.id;
              }),
              free_package_removed: freePackageRemoved
                ? String(freePackageRemoved)
                : null,
            };
            if (
              item?.customerPackage?.filter(
                (item: any) => item?.is_hiv_form === true
              )?.length !== 0
            ) {
              const updatedHivform = { ...item.hivform };
              if (updatedHivform?.hiv_form_id !== "")
                body.hivform_id = updatedHivform?.hiv_form_id;
              if (updatedHivform?.adhaar_card?.length < 300) {
                delete updatedHivform.adhaar_card;
              }
              updatedHivform.report_can_be_seen_by =
                updatedHivform.report_can_be_seen_by?.toString();
              body.hivform = updatedHivform;
            }
            additionalPacakges.push(body);
          } else if (
            item?.isPickupStatus !== "cancelled" &&
            item?.isBookingStatus !== "cancelled"
          ) {
            const body: any = {
              id: item?.bookingId,
              customer_aadhar: item?.aadhar,
              customer_age: item?.customerAge,
              customer_gender: item?.customerGender,
              customer_name: item?.customerName,
              customerid:
                item.oldNameUHID.toLowerCase()?.replace(/\s/g, "") ===
                  item.customerName.toLowerCase()?.replace(/\s/g, "") &&
                (item.oldAgeUHID === item.customerAge ||
                  item.oldAgeUHID !== item.customerAge) &&
                (item.oldGenderUHID === item.customerGender ||
                  item.oldGenderUHID !== item.customerGender)
                  ? item.customerId
                  : "",
              pickup_status: item?.isPickupStatus,
              free_test: item?.free_test,
              book_for: item?.bookingFor,
              is_prescription: item?.is_prescribed,
              phone_number:
                item.patient_phone_no !== null ? item.patient_phone_no : "",
              passport_number: item?.passport,
              designation: item?.designation,
              customer_email: item?.customer_email,
              packages: item?.customerPackage?.map((pack: any) => {
                return pack.id;
              }),
              free_package_removed: freePackageRemoved
                ? String(freePackageRemoved)
                : null,
            };
            if (
              item?.customerPackage?.filter(
                (item: any) => item?.is_hiv_form === true
              )?.length !== 0
            ) {
              const updatedHivform = { ...item.hivform };
              if (updatedHivform?.hiv_form_id !== "")
                body.hivform_id = updatedHivform?.hiv_form_id;
              if (updatedHivform?.adhaar_card?.length < 300) {
                delete updatedHivform.adhaar_card;
              }
              updatedHivform.report_can_be_seen_by =
                updatedHivform.report_can_be_seen_by?.toString();
              body.hivform = updatedHivform;
            }
            additionalPacakges.push(body);
          } else {
            const body: any = {
              id: item?.bookingId,
              customer_aadhar: item?.aadhar,
              customer_age: item?.customerAge,
              customer_gender: item?.customerGender,
              customer_name: item?.customerName,
              customerid:
                item.oldNameUHID.toLowerCase()?.replace(/\s/g, "") ===
                  item.customerName.toLowerCase()?.replace(/\s/g, "") &&
                (item.oldAgeUHID === item.customerAge ||
                  item.oldAgeUHID !== item.customerAge) &&
                (item.oldGenderUHID === item.customerGender ||
                  item.oldGenderUHID !== item.customerGender)
                  ? item.customerId
                  : "",
              pickup_status: item?.isPickupStatus,
              free_test: item?.free_test,
              book_for: item?.bookingFor,
              is_prescription: item?.is_prescribed,
              phone_number:
                item.patient_phone_no !== null ? item.patient_phone_no : "",
              passport_number: item?.passport,
              designation: item?.designation,
              customer_email: item?.customer_email,
              booking_status: item?.isBookingStatus,
              packages: item?.customerPackage?.map((pack: any) => {
                return pack.id;
              }),
              free_package_removed: freePackageRemoved
                ? String(freePackageRemoved)
                : null,
            };
            if (
              item?.customerPackage?.filter(
                (item: any) => item?.is_hiv_form === true
              )?.length !== 0
            ) {
              const updatedHivform = { ...item.hivform };
              if (updatedHivform?.hiv_form_id !== "")
                body.hivform_id = updatedHivform?.hiv_form_id;
              if (updatedHivform?.adhaar_card?.length < 300) {
                delete updatedHivform.adhaar_card;
              }
              updatedHivform.report_can_be_seen_by =
                updatedHivform.report_can_be_seen_by?.toString();
              body.hivform = updatedHivform;
            }
            additionalPacakges.push(body);
          }
        }
      });

    const freePackageRemoved =
      booking.free_test &&
      booking.free_test.free_test &&
      !packages.includes(booking.free_test.free_test.id)
        ? booking.free_test.free_test.id
        : null;
    const data: any = {
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender?.toLowerCase(),
      customer_email: customer_email === "" ? null : customer_email,
      customerid:
        booking?.customer_name?.toLowerCase()?.replace(/\s/g, "") ===
          customer_name?.toLowerCase().replace(/\s/g, "") ||
        (typeof booking?.customer_gender === "string" &&
          booking?.customer_gender.toLowerCase() ===
            customer_gender?.toLowerCase() &&
          typeof booking?.customer_gender === "string" &&
          booking?.customer_gender.toLowerCase() !==
            customer_gender?.toLowerCase()) ||
        (!isNaN(Number(booking?.customer_age)) &&
          !isNaN(Number(customer_age)) &&
          (Number(booking?.customer_age) !== Number(customer_age) ||
            isNaN(Number(customer_age))))
          ? bookingCustomerid
          : "",
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: customer_alternatenumber,
      customer_whatsapppnumber: customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark: saveNewAddress + " " + customer_landmark,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_areapincode: customer_areapincode,
      customer_city: customer_city,
      customer_aadhar: aadhar,
      passport_number: passport === null ? "" : passport,
      packages: packages,
      coupon_applied: coupon,
      coupon_amount: Number(cValue),
      coupon_type: cType,
      // "partner": booking_source,
      designation: customer_designation,
      address_line2: addressLine2,
      is_prescription: isPrescribed,
      landmark: favoritePlace,
      pickup_status: pickup_status,
      payment_image: file1,
      phlebo_remarks: phleboRemarks,
      is_referral_applied: "false",
      remove_coupon: "true",
      requested_for_hard_copy: requestedForHardCopy,
      remove_diagnostic_charges: String(removeDiagnosticFee),
      remove_phlebo_charges: String(removePhleboCharge),
      ref_doctor: referringDoctor,
      is_redcash_applied: redCashButton ? "true" : "false",
      subscription: checkedWay,
      book_for: bookingFor,
      attach_og_coupon: true,
      // delivery_date: booking?.delivery_date !== null ? containerDate : moment(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD'),
      // delivery_collection_slot: containerSlot === "" ? 11 : containerSlot,
      additional_member: additionalPacakges,
      invoice_type:
        inputList &&
        inputList.length == 1 &&
        inputList.some((x: any) => x?.customerName === "")
          ? "single-invoice"
          : invoiceChoice,
      // phone_number: patientPhoneNo,
      is_customer_email_deny: deniedEmailCheck,
      phone_number: patientPhoneNo,
      urgent_booking: urgentBooking,
      giftcard:
        booking?.giftcard && Object.keys(booking?.giftcard)?.length > 0
          ? booking?.giftcard?.code
          : null,
      price_confirmed: !priceConfirmed,
      free_package_removed: String(freePackageRemoved),
      pitched_price:
        pitchedPrice === "" || pitchedPrice === 0
          ? totalPriceForBooking -
            (booking?.discounted_price?.coupon_type === "redeem points"
              ? Math.floor(booking?.discounted_price?.referral_coupon_discount)
              : Math.floor(referralCouponDiscount)) -
            Math.floor(redProMembershipDiscount) +
            hardCopyPrice +
            diagnosticFee +
            Math.max(thyroidPhleboCost, phleboCost, phleboCostForPremiumSlot) -
            Math.floor(redDisc) -
            Math.floor(discount) +
            proMembershipPrice -
            giftCardDiscount
          : Number(pitchedPrice),
    };

    const propertiesToCheck: string[] = [
      "customer_phonenumber",
      "customer_altphonenumber",
      "customer_whatsapppnumber",
    ];

    for (const property of propertiesToCheck) {
      if (data[property]?.includes("X")) {
        delete data[property];
      }
    }

    if (
      packageDetails?.filter((item: any) => item?.is_hiv_form === true)
        ?.length !== 0
    ) {
      const updatedHivform = { ...hivform };
      if (booking?.hivform?.length > 0)
        data.hivform_id = booking?.hivform[0]?.id;
      if (updatedHivform?.adhaar_card?.length < 300) {
        delete updatedHivform.adhaar_card;
      }
      updatedHivform.report_can_be_seen_by =
        updatedHivform.report_can_be_seen_by?.toString();
      data.hivform = updatedHivform;
    }

    // if (deleteUrineData) {
    //   delete data.delivery_date;
    //   delete data.delivery_collection_slot;
    // }

    if (
      data["subscription"] === "" ||
      (booking?.discounted_price?.subscription_amount === 0 &&
        newLeadDetails?.subscriptions !== null)
    )
      delete data["subscription"];

    if (booking?.coupon_applied?.discount_type === "percentage") {
      if (packagesOldPrice <= packagesNewPrice) {
        delete data["remove_coupon"];
      } else {
        if (
          (packagesOldPrice < 1000 && packagesNewPrice < 1000) ||
          (packagesOldPrice > 4999 && packagesNewPrice > 4999) ||
          (packagesOldPrice > 999 &&
            packagesNewPrice > 999 &&
            packagesOldPrice < 2000 &&
            packagesNewPrice < 2000) ||
          (packagesOldPrice > 1999 &&
            packagesNewPrice > 1999 &&
            packagesOldPrice < 5000 &&
            packagesNewPrice < 5000)
        ) {
          delete data["remove_coupon"];
        }
      }
    } else {
      delete data["remove_coupon"];
    }
    if (pickup_status === "cancelled") {
      delete data["giftcard"];
    }
    if (RedCashCheker === false) {
      delete data["is_redcash_applied"];
    }
    if (booking.booking_type === "b2b") {
      delete data["remove_coupon"];
      delete data["requested_for_hard_copy"];
    }

    if (pickup_status === "cancelled") {
      delete data["giftcard"];
    }
    if (freePackageRemoved === null) {
      delete data["free_package_removed"];
    }

    if (booking?.booking_type === "b2c" && redCashButton) {
      delete data.coupon_amount;
      delete data.coupon_type;
      delete data.coupon_applied;
    }

    if (
      booking?.coupon_applied?.is_red_cash === false &&
      redCashButton &&
      booking?.booking_type === "b2c"
    ) {
      if (booking?.pickup_status !== "confirmed")
        data["remove_coupon"] = "true";
    }
    if (
      (booking &&
        booking?.coupon_applied &&
        booking?.coupon_applied?.should_autoapply === false) ||
      !couponCode?.replaceAll("_", "")?.startsWith("HPL20")
    ) {
      delete data.attach_og_coupon;
    }

    if (removePhleboCharge === false) {
      delete data["remove_phlebo_charges"];
    }
    if (
      ((cValue === "" || cValue === 0) && cType === "percentage") ||
      (cType === "code" && cValue === "") ||
      cValue === 0
    ) {
      delete data.coupon_amount;
      delete data.coupon_type;
      // delete data.coupon_applied;
    }
    if (data["coupon_applied"] === 0) {
      delete data.coupon_applied;
    }

    if (data["customer_areapincode"] === 0) {
      delete data["customer_areapincode"];
    }

    if (data["payment_image"] === "") {
      delete data["payment_image"];
    }

    if (cType === "" || cType === "none") {
      delete data.coupon_amount;
      delete data.coupon_type;
      delete data.coupon_applied;
    }
    if (
      data["coupon_applied"] === 0 ||
      couponCode === "" ||
      cType === "percentage"
    ) {
      delete data["coupon_applied"];
    }
    if (
      booking?.discounted_price?.coupon_type === "redeem points" &&
      booking?.discounted_price?.referral_coupon_discount >= 0
    ) {
      data["is_referral_applied"] = "true";
    }
    if (cType === "amount" && cValueForNum!==0) {
      data["coupon_amount"] = Number(cValueForNum);
      data["coupon_type"] = cType;
    }
    if (data["customer_areapincode"] === 0) {
      delete data["customer_areapincode"];
    }

    if (cType === "referral") {
      delete data.coupon_amount;
    }
    if (
      !couponCode?.replaceAll("_", "")?.startsWith("HPL20") &&
      booking?.coupon_applied &&
      booking?.coupon_applied?.should_autoapply === false
    ) {
      delete data.attach_og_coupon;
    }

    if (additionalPacakges?.length === 0) {
      delete data["additional_member"];
    }
    if (
      cType === "percentage" &&
      (removeDiscountCharge === true || removeDiscountCharge === "true")
    ) {
      data["remove_coupon"] = "true";
    }
    await editBookingInSales(data, bookingId);
    if (allergyQuestionAnswer.length > 0) {
      const arr: any = [];
      allergyQuestionAnswer.map((val: any) =>
        arr.push({
          question_text: val.question_text,
          question: val.question,
          unique_customer: booking?.customerid,
          booking_id: null,
          answer: val.checked === "no" ? "no" : val.answer,
        })
      );
      postPackageAnswer(arr);
    }
    if (inputList.length > 0) {
      inputList.map((val1: any) => {
        if (val1?.allergyForm?.length > 0) {
          const arr: any = [];
          val1?.allergyForm.map((val: any) => {
            arr.push({
              question_text: val.question_text,
              question: val.question,
              unique_customer: val1?.customerId,
              booking_id: null,
              answer: val.checked === "no" ? "no" : val.answer,
            });
          });
          postPackageAnswer(arr);
        }
      });
    }
    if (clinicalQuestionAnswer.length > 0) {
      const arr: any = [];
      clinicalQuestionAnswer.map((val: any) =>
        arr.push({
          question_text: val.question_text,
          question: val.question,
          unique_customer: booking?.customerid,
          answer: val.answer,
          booking_id: null,
          doctor_prescription: val.doctor_prescription,
        })
      );
      postPackageAnswer(arr);
    }
    if (inputList.length > 0) {
      inputList.map((val1: any) => {
        if (val1?.clinicalForm?.length > 0) {
          const arr: any = [];
          val1?.clinicalForm.map((val: any) => {
            arr.push({
              question_text: val.question_text,
              question: val.question,
              unique_customer: val1?.customerId,
              answer: val.answer,
              booking_id: null,
              doctor_prescription: val.doctor_prescription,
            });
          });
          postPackageAnswer(arr);
        }
      });
    }
    if (checkedWay === "" && booking?.discounted_price?.subscription_amount > 0)
      await postRemoveSubscription(body);
    history.push(`/dashboard/ts/booking-view/${bookingId}/?edit=true`);
  };

  useEffect(() => {
    if (disableUpdateButton) {
      setTimeout(() => {
        setDisableUpdateButton(false);
      }, 10000);
    }
  }, [disableUpdateButton]);

  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setPhleboCost(0);
    setPhleboCostForPremiumSlot(0);
    setMinAmountPhleboCost(0);
    setCollection_date(e.target.value as string);
    if (zoneId) {
      getPackage(`city_id=${cityId}&gender=${customer_gender}`);
      if (booking?.org_type !== "imaging") {
        getAvailableSlots(e.target.value, zoneId);
      } else {
        getTimeslots();
      }
    }
  };

  useEffect(() => {
    setCustomer_landmark(landmark ? landmark.label : booking.customer_landmark);
  }, [landmark]);

  const [showFemale, setShowFemale] = useState<any>(false);

  useEffect(() => {
    if (designation === "Mr") {
      setShowFemale(false);
      if (bookingFor === "mother" || bookingFor === "daughter") {
        setBookingFor("");
      }
    } else if (designation === "Ms") {
      setShowFemale(true);
      if (bookingFor === "father" || bookingFor === "son") {
        setBookingFor("");
      }
    }
  }, [designation]);

  const titleSelection = (title: string) => {
    const findGender = GENDER_CHOICE.filter(
      (el: any) => el.display_name == title
    );
    setDesignation(title);
    getPackage(
      `city_id=${booking?.cityid}&org_type=homedx&gender=${customer_gender}`
    );
    setCustomer_designation(findGender[0].db_name);
    setCustomer_gender(findGender[0].gender);

    if (title === "Ms") setShowFemaleInMain(true);
    if (title === "Mr") setShowFemaleInMain(false);
    if (
      showFemaleInMain === true &&
      (bookingFor === "father" || bookingFor === "son")
    ) {
      setBookingFor("");
    }
    if (
      showFemaleInMain === false &&
      (bookingFor === "mother" || bookingFor === "daughter")
    ) {
      setBookingFor("");
    }
  };

  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      if (mapAddressObject?.addressTokens?.pincode !== "") {
        // setPinCode(mapAddressObject?.addressTokens?.pincode);
        const displayAddress = `${mapAddressObject?.placeName}, ${mapAddressObject?.placeAddress}`;
        setCustomer_landmark(displayAddress);
        setCustomer_city(mapAddressObject?.addressTokens?.city);
        // setSaveOldAddress(displayAddress);
        checkGeoFenchMapMyIndiaPilot(
          mapAddressObject?.eLoc,
          mapAddressObject?.addressTokens?.city,
          mapAddressObject?.placeAddress,
          booking?.lead
        );
        setErrorMessage("");
      } else {
        setErrorMessage(
          "Please select complete locality from dorpdown with pincode"
        );
      }
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      const city_id = geoFencesMapMyIndia?.cityid;
      const zone_Id = geoFencesMapMyIndia?.customer_zone;
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
      if (city_id) {
        setSelectedSlot(0);
        setCityId(city_id);
        if (Number(city_id) !== Number(cityId)) {
          // setPackageKey(random);
          setPackagePriceAdditionalMembersUpdate(false);
          setAddMemberNumber(booking?.additional_members?.length);
          setPackagePriceUpdate(true);
          const pCodeMain =
            packageDetails !== undefined
              ? packageDetails?.map((pack: any) => pack?.code).join(",")
              : "";
          const pCodeAdditional =
            booking?.additional_members?.length > 0
              ? inputList
                  ?.map((item: any) =>
                    item?.customerPackage
                      ?.map((pack: any) => pack?.code)
                      .join(",")
                  )
                  .join(",")
              : "";

          const combinedPCode =
            pCodeMain && pCodeAdditional
              ? pCodeMain + "," + pCodeAdditional
              : pCodeMain || pCodeAdditional;
          getPackage(
            `city_id=${city_id}&org_type=homedx&codes=${combinedPCode}&locality_change=true`
          );
        }
        setZoneId(zone_Id);
      }
    }
  }, [geoFencesMapMyIndia]);

  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName:any = "jsonp_callback_" + "data" + id;
       (window as any)[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            const removePincode = data?.suggestedLocations.filter(
              (data: any) => data.type !== "PINCODE"
            );
            setZoneKey(suggestedData.length);
            setSuggestedAddres(removePincode);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });

  const handleAddDoctor = async () => {
    let body = {
      name: referringDoctorName,
    };
    await postRefDoctor(body);
  };

  useEffect(() => {
    setReferringDoctor(postRefReducer?.id);
  }, [postRefReducer]);

  useEffect(() => {
    if (
      referralPointsDiscount === 0 &&
      redCoin?.coins >= 0 &&
      !isNaN(redProMembershipDiscount) &&
      !isNaN(totalPriceForBooking)
    ) {
      const maxRedDiscount =
        ((totalPriceForBooking -
          redProMembershipDiscount +
          Math.max(phleboCostForPremiumSlot, phleboCost)) /
          100) *
        redCashDiscountPercentage;
      const redCashDeduct =
        maxRedDiscount - totalRedCash >= 0 ? totalRedCash : maxRedDiscount;
      if (redCashButton) {
        setRedDisc(redCashDeduct);
      } else {
        setRedDisc(0);
      }
    }
  }, [redCashButton, totalPriceForBooking, redProMembershipDiscount]);

  useEffect(() => {
    if (redCoin?.coins >= 0 && booking?.redcash_discounted_amount >= 0) {
      setTotalRedCash(
        Number(Math.floor(redCoin?.coins)) +
          Math.floor(booking?.redcash_discounted_amount)
      );
    }
  }, [redCoin, booking]);

  useEffect(() => {
    if (newLeadDetails?.mobile?.length > 9) {
      if (
        (redCoin &&
          redCoin?.uniquecustomer?.length > 0 &&
          redCoin?.uniquecustomer[0]?.lead !== booking?.lead) ||
        (redCoin && redCoin?.length === 0)
      )
        getRedCoing(newLeadDetails?.mobile);
    }
  }, [newLeadDetails]);

  useEffect(() => {
    setRedCashDiscountPercentage(redCoin?.redcash_percentage);
  }, [redCoin]);

  useEffect(() => {
    if (totalRedCash == 0) {
      setRedCoinMessage(`You have 0 Red Cash`);
    } else {
      setRedCoinMessage(` Red Cash Available`);
    }
  }, [totalRedCash]);

  const handleRedCheck = (event: any) => {
    setRedCashCheker(true);
    handleMemberDiscountCheck(false);
    setRedCashButton(event.target.checked);
    setDiscount(0);
    setCValue("");
    setCoupon(0);
    setCouponCode("");
    setCType("");
    setCouponKey(couponKey + 1);
  };

  const handleMemberDiscountCheck = async (checked: any) => {
    const isChecked = checked;
    setRedCashButton(false);

    setMemberDiscountCheck(isChecked);
    setMemberDiscountFlag(isChecked);
    const filterArr =
      inputList.filter(
        (data: any) =>
          data.isSelected !== "start" &&
          data.isBookingStatus !== "cancelled" &&
          data.isPickupStatus !== "cancelled"
      ) || [];
    let coup =
      filterArr.length === 1
        ? "ADDFAMILY2"
        : filterArr.length === 2
        ? "ADDFAMILY3"
        : filterArr.length === 3
        ? "ADDFAMILY4"
        : filterArr.length === 4
        ? "ADDFAMILY5"
        : "";
    if (checked) {
      setCouponCode(coup);
      coup && getCoupons(`?code=${coup}&org_type=homedx`);
    } else {
      setCouponCode("");
      // setMemberDiscountCheck(false)
      setCoupon(0);
      setDiscount(0);
    }
  };
  useEffect(() => {
    if (couponList?.results?.length > 0 && memberDiscountCheck && couponCode) {
      setCoupon(couponList?.results[0].id);
      applyCoupon();
    }
  }, [couponList]);

  const [freeGlucoseFastingTestDisabled, setFreeGlucoseFastingTestDisabled] =
    useState<boolean>(false);
  const handleAddFreeHbA1cTest = (data: any, ind: number) => {
    setRedCashCheck(false);
    setCValue("");
    setDiscount(0);
    setCoupon(0);
    setCouponCode("");
    const FreeHbA1cTestObject = {
      id: Number(process.env.REACT_APP_FREE_HBA1C_ID),
      created_at: "",
      updated_at: "",
      slug: "Free-HbA1C",
      lpslug: "Free-HbA1C",
      code: "BC035A",
      name: "Free HbA1C",
      company_name: "",
      parameter: 1,
      description: "HbA1C",
      details: "",
      faq: "",
      recommended_gender: "both",
      type: "",
      package_city_prices: null,
      recommended_age: "5-80",
      tat_time: "",
      tat_time_duration: "",
      fasting_time: "10-12 Hrs Fasting Required",
      discount: "",
      package_price: 99,
      offer_price: 0,
      addon_price: "",
      is_active: false,
      is_addon: false,
      is_premium: false,
      is_popular: false,
      website_display: false,
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
      og_tag: "",
      twitter_card: "",
      specimen_instructions: "",
      package_image: "",
      test_category_id: 8,
      text_criteria: "",
      home_collection: true,
      best_package_app: false,
      is_family_package: false,
      scratch_code: "",
      qna: "",
      ptype: "CC",
      content: "",
      org_type: "homedx",
      center_id: "",
      bookings_count: 0,
      ppmc_features: "",
      temperature: "8",
      package_or_test: "package",
      no_patients: "",
      b65_code: "",
      histopathology_history_form_needed: false,
      babyscreen_form_needed: false,
      department: "Biochemistry",
      owner_name: "",
      testimonials: "",
      also_known_as: "",
      illness_or_wellness: "",
      booking_count: 0,
      smart_report_available: false,
      package_cost: 0.0,
      individual_cost: 0.0,
      sample_report: "",
      report_from: "itdose",
    };

    const mainMemberArr = packageDetails;
    const customerArr: any = [];
    inputList.length > 0 &&
      inputList.map(
        (val: any) =>
          val.customerPackage &&
          val.customerPackage.length > 0 &&
          val.customerPackage.map((vol: any) => customerArr.push(vol))
      );
    let isTestAdded = false;

    const isFreePackageAvailableInMain: boolean = mainMemberArr.some(
      (item: any) => item?.code === "BC035A"
    );
    const isBC035PackageAvailableInCustomer: boolean = customerArr.some(
      (item: any) =>
        item?.test?.some(
          (testItem: { code: string }) => testItem?.code === "BC035"
        )
    );

    const isFreePackageAvailableInCustomer: boolean = customerArr.some(
      (item: any) => item?.code === "BC035A"
    );
    const idExistsInCustomer: boolean = customerArr?.some((item: any) =>
      item?.test?.some(
        (testItem: { id: number; name: string }) =>
          testItem?.id === Number(process.env.REACT_APP_FREE_HBA1C_ID)
      )
    );
    if (isBC035PackageAvailableInCustomer) {
      alert(
        "[Free HbA1C Test can not be added, as the HbA1C Test is already available in BC035 package.]"
      );
    }

    if (isFreePackageAvailableInMain && !isFreePackageAvailableInCustomer) {
      alert(
        "[Free HbA1C Test can not be added, as the HbA1C Test is already available in main member package.]"
      );
    } else if (isFreePackageAvailableInCustomer) {
      alert(
        "[Free HbA1C Test can be added to only 1 patient in a booking.If free HbA1C is added to any customer and agent tries to add it in any other cx.]"
      );
    } else if (idExistsInCustomer) {
      alert(
        "[HbA1C test already available in the selected package.if normal HbA1C is already available in test or within package form in the cart]"
      );
    } else {
      if (data === "main_member") {
        handlePackages([...mainMemberArr, FreeHbA1cTestObject]);
      } else {
        handlePackage(ind, [...customerArr, FreeHbA1cTestObject], -1);
      }
    }
  };

  const handleAddFreeGlucoseFastingTest = (data: any, ind: number) => {
    const FreeGlucoseFastingTestObject = {
      id: Number(process.env.REACT_APP_GLUCOSE_FASTING_ID),
      created_at: "",
      updated_at: "",
      slug: "Free-Glucose-Fasting-Test",
      lpslug: "Free-Glucose-Fasting-Test",
      code: "CAMP012A",
      name: "Free Glucose Fasting Test",
      company_name: "",
      parameter: 1,
      description: "Glucose Fasting",
      details: "",
      faq: "",
      recommended_gender: "both",
      type: "",
      package_city_prices: null,
      recommended_age: "5-80",
      tat_time: "",
      tat_time_duration: "",
      fasting_time: "10-12 Hrs Fasting Required",
      discount: "",
      package_price: 99,
      offer_price: 0,
      addon_price: "",
      is_active: false,
      is_addon: false,
      is_premium: false,
      is_popular: false,
      website_display: false,
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
      og_tag: "",
      twitter_card: "",
      specimen_instructions: "",
      package_image: "",
      test_category_id: 8,
      text_criteria: "",
      home_collection: true,
      best_package_app: false,
      is_family_package: false,
      scratch_code: "",
      qna: "",
      ptype: "CC",
      content: "",
      org_type: "homedx",
      center_id: "",
      bookings_count: 0,
      ppmc_features: "",
      temperature: "8",
      package_or_test: "package",
      no_patients: "",
      b65_code: "",
      histopathology_history_form_needed: false,
      babyscreen_form_needed: false,
      department: "Biochemistry",
      owner_name: "",
      testimonials: "",
      also_known_as: "",
      illness_or_wellness: "",
      booking_count: 0,
      smart_report_available: false,
      package_cost: 0.0,
      individual_cost: 0.0,
      sample_report: "",
      report_from: "itdose",
      my_free_package: 420,
    };
    const FreeGlucoseRandomTestObject = {
      id: Number(process.env.REACT_APP_GLUCOSE_RANDOM_ID),
      created_at: "",
      updated_at: "",
      slug: "Free-Glucose-Random-Test",
      lpslug: "Free-Glucose-Random-Test",
      code: "CAMP013A",
      name: "Free Glucose Random Test",
      company_name: "",
      parameter: 1,
      description: "Glucose Random",
      details: "",
      faq: "",
      recommended_gender: "both",
      type: "",
      recommended_age: "5-80",
      tat_time: "",
      tat_time_duration: "",
      fasting_time: null,
      discount: "",
      package_price: 99,
      offer_price: 0,
      package_city_prices: null,
      addon_price: "",
      is_active: false,
      is_addon: false,
      is_premium: false,
      is_popular: false,
      website_display: false,
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
      og_tag: "",
      twitter_card: "",
      specimen_instructions: "",
      package_image: "",
      test_category_id: 8,
      text_criteria: "",
      home_collection: true,
      best_package_app: false,
      is_family_package: false,
      scratch_code: "",
      qna: "",
      ptype: "CC",
      content: "",
      org_type: "homedx",
      center_id: "",
      bookings_count: 0,
      ppmc_features: "",
      temperature: "8",
      package_or_test: "package",
      no_patients: "",
      b65_code: "",
      histopathology_history_form_needed: false,
      babyscreen_form_needed: false,
      department: "Biochemistry",
      owner_name: "",
      testimonials: "",
      also_known_as: "",
      illness_or_wellness: "",
      booking_count: 0,
      smart_report_available: false,
      package_cost: 0.0,
      individual_cost: 0.0,
      sample_report: "",
      report_from: "itdose",
      my_free_package: 420,
    };

    if (data === "main_member") {
      const arr = packageDetails;
      let freePackageArray = arr.filter(
        (item: { code: string }) =>
          item?.code === "CAMP013A" || item?.code === "CAMP012A"
      );
      if (freePackageArray?.length === 0) {
        const filteredCodes = arr.filter(
          (item: { code: string }) =>
            item?.code === "BC023" || item?.code === "BC034"
        );
        const idExists: boolean =
          arr?.length > 0 &&
          arr?.some(
            (item: { test: { id: number; name: string }[] }) =>
              item?.test?.length > 0 &&
              item?.test?.some(
                (testItem: { id: number; name: string }) =>
                  testItem?.id ===
                    Number(process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID) ||
                  testItem?.id ===
                    Number(process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID)
              )
          );
        if (filteredCodes?.length !== 0) {
          alert(
            "[Free Glucose Test can not be added, as the Glucose Test is already available.]"
          );
        } else if (idExists) {
          alert("[Glucose test already available in the selected package.]");
        } else {
          let filteredFasting = arr.filter(
            (item: { fasting_time: string | null }) =>
              item?.fasting_time !== null &&
              item?.fasting_time !== "Not Required"
          );
          if (filteredFasting?.length !== 0) {
            arr.push(FreeGlucoseFastingTestObject);
            setFreeGlucoseFastingTestDisabled(true);
            handlePackages(arr);
          } else {
            arr.push(FreeGlucoseRandomTestObject);
            setFreeGlucoseFastingTestDisabled(true);
            handlePackages(arr);
          }
        }
      }
    } else {
      const arr = data?.customerPackage;
      let freePackageArray = arr.filter(
        (item: { code: string }) =>
          item?.code === "CAMP013A" || item?.code === "CAMP012A"
      );
      if (freePackageArray?.length === 0) {
        const filteredCodes = arr.filter(
          (item: { code: string }) =>
            item?.code === "BC023" || item?.code === "BC034"
        );
        const idExists: boolean =
          arr?.length > 0 &&
          arr?.some(
            (item: { test: { id: number; name: string }[] }) =>
              item?.test?.length > 0 &&
              item?.test?.some(
                (testItem: { id: number; name: string }) =>
                  testItem?.id ===
                    Number(process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID) ||
                  testItem?.id ===
                    Number(process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID)
              )
          );
        if (filteredCodes?.length !== 0) {
          alert(
            "[Free Glucose Test can not be added, as the Glucose Test is already available.]"
          );
        } else if (idExists) {
          alert("[Glucose test already available in the selected package.]");
        } else {
          let filteredFasting = arr.filter(
            (item: { fasting_time: string | null }) =>
              item?.fasting_time !== null &&
              item?.fasting_time !== "Not Required"
          );
          if (filteredFasting?.length !== 0) {
            arr.push(FreeGlucoseFastingTestObject);
          } else {
            arr.push(FreeGlucoseRandomTestObject);
          }
          handlePackage(ind, arr, -1);
        }
      }
    }
    // let body = {
    //   module: "free glucose fasting test",
    //   lead_id: leadData,
    //   panel_user_id: userDetails?.profile?.paneluser_id,
    //   text: "free glucose fasting test",
    // }
    // postModuleInteraction(body)
  };

  const verifyEmail = () => {
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (customer_email && !nameRegex.test(customer_email.toString())) {
      setEmailValidation(false);
    }
  };
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [openPdfTable, setOpenPdfTable] = useState<boolean>(false);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<any>("");
  const [fileLink, setFileLink] = useState<any>("");
  const [docs, setDocs] = useState<any>([]);

  const handleClinicalHistory = () => {
    setOpenPdfTable(true);
  };
  const handlePdfModelTableClose = () => {
    setOpenPdfTable(false);
  };
  const handleViewPdf = () => {
    setOpenPdf(true);
  };

  const TooltipReportAndDietConsultationContent = () => (
    <small>
      <ul style={{ paddingLeft: "15px" }}>
        <li style={{ color: "black", fontSize: "16px" }}>
          This fee is collected to ensure the safety, handling and integrity of
          your sample. We follow the following processes and guidelines to
          ensure the highest quality standards
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          Vaccinated phlebotomists .
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          .⁠⁠Company-provided sanitized sealed kits
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          Special temp control bags with temperature display to protect the
          sample integrity
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          ⁠Internal Lab Quality Control
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          Assured Convenience with Home Sample Collection
        </li>
        <li style={{ color: "black", fontSize: "16px" }}>
          ⁠Safety and Emergency Care Training
        </li>
      </ul>
    </small>
  );

  useEffect(() => {
    let count = 0;
    inputList &&
      inputList?.length &&
      inputList?.map((item: any) => {
        if (item?.isSelected === true) {
          if (item?.customerId === "") {
            count = count + 1;
          }
        }
      });
    setBookingUpdateDisabledWithoutSaveCount(count);
  }, [inputList]);

  const [disableUpdate, setDisableUpdate] = useState<any>(false);

  useEffect(() => {
    const list = [...inputList];
    setDisableUpdate(false);
    list?.map((item: any, index: number) => {
      if (item?.customerPackage?.length === 0) {
        if (index !== 0) setDisableUpdate(true);
        else {
          if (booking?.additional_members?.length !== 0) {
            setDisableUpdate(true);
          }
        }
      }
    });
  }, [inputList]);
  const handleAddonTest = (pkg: any, mem: any) => {
    setCurrMem(mem);
    setCurrPkgs(pkg);
    setOpenAddonTest(true);
  };
  const handleAddnPkg = (pkg: any, type: any) => {
    if (currMem === "main") {
      let packs = [...packageDetails];
      packs.push(pkg);
      handlePackages(packs);
      setCurrPkgs(packs);
    } else {
      let packs = [...inputList[currMem].customerPackage];
      packs.push(pkg);
      handlePackage(currMem, packs, -1);
      setCurrPkgs(packs);
    }
  };
  const removeAddnTest = (pkgs: any) => {
    const arr: any = [];
    if (
      pkgs &&
      pkgs?.length > 0 &&
      !pkgs?.some((item: any) => item?.package_or_test === "package")
    ) {
      pkgs.map((val: any) => {
        if (!testList.some((val1: any) => val.code === val1.code)) {
          arr.push(val);
        }
      });
      return arr;
    } else {
      return pkgs;
    }
  };

  useEffect(() => {
    let arr: any = [];
    let arr2: any = [...inputList];
    packageQuestion?.map((data: any, index: any) => {
      if (data.is_allergy) {
        arr.push({
          question_text: data.question,
          question_type: data.question_type,
          checked: "yes",
          question: data.id,
          unique_customer:
            currUser === 0
              ? booking?.customerid
              : inputList.length > 0
              ? inputList[currUser - 1]?.customerId
              : "",
          answer: "",
        });
      }
    });
    if (currUser === 0) {
      setAllergyQuestionAnswer(arr);
    } else if (currUser !== "" && arr2.length > 0) {
      arr2[currUser - 1].allergyForm = arr;
      setInputList(arr2);
    }
  }, [packageQuestion]);
  const fetchAllergyQn = (ids: any, member: any) => {
    let arr2: any = [...inputList];
    setCurrUser(member);
    if (ids.length > 0) {
      let pids = [];
      if (member === 0) {
        let toRemove =
          booking.packages.length > 0
            ? booking.packages.map((val: any) => val.id)
            : [];
        pids = ids.filter((el: any) => !toRemove.includes(el));
        if (pids.length > 0) {
          getPackageQuestion(`package_ids=${pids}`);
        }
      } else {
        let toRemove =
          booking.additional_members.length > 0 &&
          booking.additional_members[member - 1]?.packages?.length > 0
            ? booking.additional_members[member - 1]?.packages.map(
                (val: any) => val.id
              )
            : [];
        pids = ids.filter((el: any) => !toRemove.includes(el));
        if (pids.length > 0) {
          getPackageQuestion(`package_ids=${pids}`);
        }
      }
    } else {
      if (member === 0) {
        setAllergyQuestionAnswer([]);
      } else if (member !== "") {
        arr2[member - 1].allergyForm = [];
        setInputList(arr2);
      }
    }
  };
  const allergyAnsHandler = (val: any, index: any, index2: any, key: any) => {
    let ans = val.trimStart();
    let arr2: any = [...inputList];
    if (index2 === 0) {
      let arr = [...allergyQuestionAnswer];
      arr[index][key] = ans;
      setAllergyQuestionAnswer(arr);
    } else if (index2 !== "") {
      let arr = [...inputList[index2 - 1].allergyForm];
      arr[index][key] = ans;
      arr2[index2 - 1].allergyForm = arr;
      setInputList(arr2);
    }
  };

  //clinical history question
  useEffect(() => {
    let arr: any = [];
    let arr2: any = [...inputList];
    packageQuestion?.map((data: any, index: any) => {
      if (data.is_allergy === false) {
        arr.push({
          question_text: data.question,
          question: data.id,
          unique_customer:
            currUserForClinical === 0
              ? booking?.customerid
              : inputList.length > 0
              ? inputList[currUserForClinical - 1]?.customerId
              : "",
          answer: "",
          doctor_prescription: "",
          ext: "",
        });
      }
    });
    if (currUserForClinical === 0) {
      setClinicalQuestionAnswer(arr);
    } else if (currUserForClinical !== "" && arr2.length > 0) {
      arr2[currUserForClinical - 1].clinicalForm = arr;
      setInputList(arr2);
    }
  }, [packageQuestion]);

  const fetchClinicalQn = (ids: any, member: any) => {
    let arr2: any = [...inputList];
    setCurrUserForClinical(member);
    if (ids.length > 0) {
      let pids = [];
      if (member === 0) {
        let toRemove =
          booking.packages.length > 0
            ? booking.packages.map((val: any) => val.id)
            : [];
        pids = ids.filter((el: any) => !toRemove.includes(el));
        if (pids.length > 0) {
          getPackageQuestion(`package_ids=${pids}`);
        }
      } else {
        let toRemove =
          booking.additional_members.length > 0 &&
          booking.additional_members[member - 1]?.packages?.length > 0
            ? booking.additional_members[member - 1]?.packages.map(
                (val: any) => val.id
              )
            : [];
        pids = ids.filter((el: any) => !toRemove.includes(el));
        if (pids.length > 0) {
          getPackageQuestion(`package_ids=${pids}`);
        }
      }
    } else {
      if (member === 0) {
        setClinicalQuestionAnswer([]);
      } else if (member !== "") {
        arr2[member - 1].clinicalForm = [];
        setInputList(arr2);
      }
    }
  };
  const ClinicalAnsHandler = (val: any, index: any, index2: any, key: any) => {
    let ans = val.trimStart();
    let arr2: any = [...inputList];
    if (index2 === 0) {
      let arr = [...clinicalQuestionAnswer];
      arr[index][key] = ans;
      setClinicalQuestionAnswer(arr);
    } else if (index2 !== "") {
      let arr = [...inputList[index2 - 1].clinicalForm];
      arr[index][key] = ans;
      arr2[index2 - 1].clinicalForm = arr;
      setInputList(arr2);
    }
  };

  const handleClinicalPrescription = async (
    e: React.ChangeEvent<{ files: any }>
  ) => {
    const base64: any = await convertBase64(e.target.files[0]);
    return { base64 };
  };

  useEffect(() => {
    if (
      couponAutoApplied &&
      couponAutoApplied?.coupon_codes &&
      couponAutoApplied?.coupon_codes?.length > 0 &&
      discount <= 0
    ) {
      const firstCoupon = couponAutoApplied?.coupon_codes[0];
      setCType(firstCoupon?.minimum_price?.discount_type);
      setCoupon(firstCoupon?.minimum_price?.id);
      setCheckChartValue(firstCoupon.minimum_price);
      setAutoApplyCoupon(firstCoupon.code);
      setCouponCode(autoApplyCoupon);
      getCoupons(`?code=${autoApplyCoupon}&org_type=homedx`);
    }
  }, [couponAutoApplied, checkCartValue, inputList?.length]);

  useEffect(() => {
    if (
      applyCouponCheker !== false &&
      autoApplyCoupon !== "" &&
      totalPriceForBooking > checkCartValue
    ) {
      setCouponCode(autoApplyCoupon);
      setCType("code");
      setautoApplyCouponCheker(true);
      applyCouponCheker && couponCode && applyCoupon();
    }
  }, [totalPriceForBooking, autoApplyCoupon, checkCartValue, couponCode]);

  const handlePriceConfirm = () => {
    setPriceConfirmed(!priceConfirmed);
  };

  const handleAutoApplyCoupon = () => {
    setautoApplyCouponCheker(
      applyCouponCheker === "none" || applyCouponCheker === false ? true : false
    );

    if (
      autoApplyCoupon !== "" &&
      totalPriceForBooking > checkCartValue &&
      applyCouponCheker === true
    ) {
      setCType("code");
      applyCouponCheker == true &&
        autoApplyCoupon &&
        setCouponCode(autoApplyCoupon);
      couponCode && applyCoupon();
    }
    if (applyCouponCheker === false) {
      setCouponCode("");
      setCouponKey(couponKey + 1);
      setCType("");
    }
  };
  useEffect(() => {
    if (removeDiagnosticFee == false || removeDiagnosticFee === "false") {
      const filterArr = inputList.filter(
        (data: any) =>
          data.isSelected !== "start" &&
          data.isBookingStatus !== "cancelled" &&
          data.isPickupStatus !== "cancelled"
      );
      if (filterArr.length === 0) {
        setDiagnosticFee(89);
      } else if (filterArr.length === 1) {
        setDiagnosticFee(128);
      } else if (filterArr.length > 1) {
        setDiagnosticFee(89 + filterArr.length * 39);
      }
    } else {
      setDiagnosticFee(0);
    }
  }, [inputList.length, totalPriceForBooking, booking, removeDiagnosticFee]);
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <Grid container direction="row" alignItems="center">
          <Grid item md={5}>
            <h3
              style={{
                paddingTop: "10px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              EDIT BOOKING
            </h3>
          </Grid>
          <Grid item md={5}>
            <h3
              style={{
                paddingTop: "10px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              {booking?.centre_address !== null
                ? "CENTRE : " +
                  String(booking?.centre_address?.name).toUpperCase()
                : "HOME-DX BOOKING"}
            </h3>
          </Grid>
          <Grid item md={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                fontSize: "10px",
                marginLeft: "20%",
                width: "80%",
                alignItems: "center",
              }}
              onClick={() => {
                setTimeout(() => setButtonDisabled(false), 300000);
                setButtonDisabled(true);
                setApiHitCount(1);
                SendLocationMessage();
              }}
              disabled={buttonDisabled || booking?.lead === null}
            >
              Send location Message
            </Button>
            {apiHitCount > 0 ? (
              <p
                style={{
                  color: "green",
                  width: "100%",
                  paddingRight: "20%",
                  margin: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                Message Sent
              </p>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
      {Object.keys(booking).length === 0 ? (
        <Loader />
      ) : (
        <Paper className={classes.paper} elevation={15}>
          <form onSubmit={submitForm}>
            <Grid container spacing={3} style={{ margin: "auto" }}>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Booking Date</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <input
                      className="input"
                      name="collection_date"
                      type="date"
                      value={booking_date || ""}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        height: "40px",
                      }}
                      required
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Sample Collection Date</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <input
                      className="input"
                      name="collection_date"
                      type="date"
                      value={
                        (collection_date && collection_date.toString()) || ""
                      }
                      onChange={(e) => changeCollectionDate(e)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        height: "40px",
                      }}
                      id="noTyping"
                      min={booking?.booking_date}
                      max={moment(
                        new Date().setDate(new Date().getDate() + 7)
                      ).format("YYYY-MM-DD")}
                      required
                    />
                  </Grid>
                </Grid>
                {bookingType !== "b2b" ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <p>Requested For Hard Copy</p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          className="input"
                          name="requested_for_hard_copy"
                          variant="outlined"
                          value={requestedForHardCopy}
                          style={{ width: "100%", margin: "0", height: "40px" }}
                          onChange={(e) =>
                            setRequestedForhHardCopy(e.target.value)
                          }
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    {booking && booking?.source_type !== "labwalkins" && (
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <p>Remove Diagnostic Fee</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Select
                            className="input"
                            name="removeDiagnosticFee"
                            variant="outlined"
                            value={removeDiagnosticFee}
                            style={{
                              width: "100%",
                              margin: "0",
                              height: "40px",
                            }}
                            disabled={booking.report_status === "consolidate"}
                            onChange={(e) =>
                              setremoveDiagnosticFee(e.target.value)
                            }
                          >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  ""
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Remove Phlebo Charge</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      className="input"
                      name="removePhleboCharge"
                      variant="outlined"
                      value={removePhleboCharge}
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      disabled={booking.report_status === "consolidate"}
                      onChange={(e) => setRemovePhleboCharges(e.target.value)}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Remove Discount</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      className="input"
                      name="removeDiscountCharge"
                      variant="outlined"
                      value={removeDiscountCharge}
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      disabled={booking.report_status === "consolidate"}
                      onChange={(e) => setRemoveDiscountCharge(e.target.value)}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  spacing={0}
                  style={{
                    padding: "10px",
                    background: "#e1f5fe",
                    marginLeft: "-10px",
                    height: "100%",
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <p>Locality</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="customer_landmark"
                      key={booking?.customer_landmark}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setMapAddressObject(obj);
                          setCustomer_landmark(obj.placeAddress);
                          setSaveNewAddress(obj.placeName);
                        }
                      }}
                      options={suggestedAddress ? suggestedAddress : []}
                      freeSolo
                      aria-required
                      getOptionLabel={(option: any) =>
                        `${option?.placeName}, ${option?.placeAddress}`
                      }
                      disabled={
                        booking?.pickup_status === "confirmed" ||
                        (booking?.phlebo && booking?.phlebo?.name?.length > 0)
                      }
                      defaultValue={{
                        placeName: booking?.customer_landmark,
                        placeAddress: "",
                      }}
                      filterOptions={(option: any) => option}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          locationAutoCompleteSearch(newInputValue, event);
                        }, 500);
                      }}
                      // onKeyDown={(e)=>handleKeyDown(e)}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Search Locality"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                          required
                        />
                      )}
                    />
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>House / Flat / Shop Number</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={customer_address}
                      className="input"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      onChange={(e) =>
                        setCustomer_address(e.target.value as String)
                      }
                      required
                      disabled={booking.report_status === "consolidate"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>Apartment / Building / Street Number</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={addressLine2}
                      className="input"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      onChange={(e) =>
                        setAddressLine2(e.target.value as String)
                      }
                      required
                      disabled={booking.report_status === "consolidate"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <p>Landmark</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={favoritePlace}
                      className="input"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      onChange={(e) =>
                        setFavoritePlace(e.target.value as String)
                      }
                      required
                      disabled={booking.report_status === "consolidate"}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                        <p>Area Name </p>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          : <strong>{zoneName}</strong>
                        </p>
                      </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              spacing={3}
              style={{ margin: "1rem auto", background: "#eff" }}
            >
              <Grid item md={4}>
                <p>Mobile Number</p>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={customer_phonenumber}
                  placeholder="Enter Mobile Number"
                  className="input"
                  variant="outlined"
                  disabled
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_phonenumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("mobile")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_phonenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_phonenumber &&
                    "Incorrect Phone Number"
                  }
                  required
                />
              </Grid>
              <Grid item md={4}>
                <p>Alternate Mobile Number</p>
                <TextField
                  name="customer_alternatenumber"
                  type="text"
                  value={customer_alternatenumber}
                  placeholder="Enter Alternate Mobile Number"
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_alternatenumber(e.target.value as String)
                  }
                  required
                  disabled={booking.report_status === "consolidate"}
                  onBlur={() => verifyMobile("alternate")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_alternatenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_alternatenumber &&
                    "Incorrect alternate Number"
                  }
                />
              </Grid>
              <Grid item md={4}>
                <p>Whatsapp Number</p>
                <TextField
                  name="customer_whatsapppnumber"
                  type="text"
                  value={customer_whatsapppnumber}
                  placeholder="Enter Whatsapp Mobile Number"
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_whatsapppnumber(e.target.value as String)
                  }
                  required
                  disabled={booking.report_status === "consolidate"}
                  onBlur={() => verifyMobile("whatsapp")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_whatsapppnumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_whatsapppnumber &&
                    "Incorrect Whatsapp Number"
                  }
                />
              </Grid>
              {/* <Grid item md={3}>
                <p>Email</p>
                <TextField
                  name="customer_email"
                  type="email"
                  placeholder="Enter Email"
                  value={customer_email}
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onBlur={() => verifyEmail()}
                  onFocus={() => {
                    setEmailValidation(true);
                  }}
                  onChange={(e) => setCustomer_email(e.target.value as String)}
                  required={customer_email === "" || !deniedEmailCheck}
                  disabled={deniedEmailCheck}
                />
                <sub style={{ color: "red", fontSize: "16px" }}>
                  {!emailValidation ? "Please enter valid e-mail id" : ""}
                </sub>
              </Grid> */}
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ float: "left" }}>
              <div>
                <p
                  style={{
                    color: "purple",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  URGENT BOOKING
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={urgentBooking}
                        onChange={(e) => setUrgentBooking(e.target.checked)}
                        name="Urgent Booking"
                        style={{
                          margin: "0",
                          marginLeft: "10px",
                        }}
                      />
                    }
                    style={{ paddingTop: "7px" }}
                    label=""
                  />
                </p>
              </div>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={{ float: "right", marginRight: "5%" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deniedEmailCheck}
                    onChange={(e: any) =>
                      setDeniedEmailCheck(e?.target?.checked)
                    }
                    disabled={customer_email?.trim()?.length > 0}
                    name="deniedEmailCheck"
                  />
                }
                label="Customer denied email"
              />
            </Grid> */}
            <Grid
              container
              direction="row"
              spacing={3}
              style={{ margin: "1rem auto", background: "#e1f5fe" }}
            >
              <Grid item md={2}>
                <p>Title</p>
                <Select
                  className="input"
                  name="customer_designation"
                  variant="outlined"
                  value={designation || ""}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  onChange={(e) => titleSelection(e.target.value as string)}
                  required
                  disabled={booking.pickup_status === "confirmed"}
                >
                  <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Ms">Ms (Mrs/Miss)</MenuItem>
                </Select>
              </Grid>
              <Grid item md={2}>
                <p>Customer Name</p>
                <TextField
                  name="customer_name"
                  type="text"
                  placeholder="Enter Name"
                  value={customer_name}
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e) =>
                    setCustomer_name(
                      e.target.value.replace(/[^A-Za-z- ']/, "") as String
                    )
                  }
                  required
                  // disabled={booking?.bill_id !== null}
                />
              </Grid>
              <Grid item md={2}>
                <p>Age</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <TextField
                    name="customer_age"
                    type="number"
                    placeholder="Enter Age"
                    value={customer_age}
                    className="input"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ margin: "0" }}
                    onChange={(e) => {
                      const enteredAge = Number(e.target.value);
                      setCustomer_age(
                        enteredAge > 0 && enteredAge < 120
                          ? e.target.value
                          : customer_age
                      );
                    }}
                    required
                    // disabled={booking?.bill_id !== null}
                  />
                </div>
                {+customer_age && +customer_age < 5 ? (
                  <p
                    style={{ textAlign: "center", color: "red", width: "100%" }}
                  >
                    Age cannot be less than 5 years
                  </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item md={1}>
                <p>Gender</p>
                <TextField
                  className="input"
                  name="customer_gender"
                  variant="outlined"
                  value={
                    customer_gender === "male" || customer_gender === "Male"
                      ? "Male"
                      : customer_gender === "female" ||
                        customer_gender === "Female"
                      ? "Female"
                      : customer_gender === "other"
                      ? "Male"
                      : ""
                  }
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    height: "40px",
                  }}
                  onChange={(e) => setCustomer_gender(e.target.value as string)}
                  required
                  disabled
                />
              </Grid>
              <Grid item md={3} onMouseEnter={() => setIsHitPackageAPI(true)}>
                <p>Package Name</p>
                <Autocomplete
                  id="package"
                  key={packageKey}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let packs: any = [];
                      newValue.map((val: any) => {
                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                        packs.push(obj);
                      });
                      setRedCashButton(false);
                      let arr = removeAddnTest(packs);
                      handlePackages(arr);
                      fetchAllergyQn(
                        packs.length > 0 && packs.map((val: any) => val.id),
                        0
                      );
                      fetchClinicalQn(
                        packs.length > 0 && packs.map((val: any) => val.id),
                        0
                      );
                      setPackKey(1);
                    }
                    setCValue("");
                    setDiscount(0);
                    setCoupon(0);
                    setCouponCode("");
                    setCType("");
                    setReferralPointsDiscount(0);
                    setReferralCouponDiscount(0);
                    setReferralPointsApplied(false);
                  }}
                  multiple
                  filterOptions={(options, state) => options}
                  options={packageList?.results || []}
                  value={packageDetails}
                  disableClearable
                  limitTags={0}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <div style={{ display: "flex" }}>
                        {option?.package_city_prices ? (
                          option?.highlight?.name &&
                          option?.highlight?.name.length > 0 ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${String(option?.highlight?.name)}, ${
                                  option?.package_city_prices?.tat_time
                                }, Rs. ${
                                  option?.package_city_prices?.offer_price
                                }/- <br /> ${
                                  option?.package_or_test === "test"
                                    ? option?.also_known_as
                                      ? `Also known as: ${option?.also_known_as}`
                                      : option?.alias?.length > 0
                                      ? `Also known as: ${option?.alias[0].name}`
                                      : ""
                                    : option?.highlight?.["test.name"]
                                    ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                    : ""
                                }`,
                              }}
                            ></div>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${option?.name.toString()}, ${
                                  option?.package_city_prices?.tat_time
                                }, Rs. ${
                                  option?.package_city_prices?.offer_price
                                } <br /> ${
                                  option?.package_or_test === "test"
                                    ? option?.also_known_as
                                      ? `Also known as: ${option?.also_known_as}`
                                      : option?.alias?.length > 0
                                      ? `Also known as: ${option?.alias[0].name}`
                                      : ""
                                    : option?.highlight?.["test.name"]
                                    ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                    : ""
                                }`,
                              }}
                            />
                          )
                        ) : option?.highlight?.name &&
                          option?.highlight?.name.length > 0 ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${String(option?.highlight?.name)}, ${
                                option?.tat_time ? `${option?.tat_time},` : ""
                              } ${
                                "Rs. " + option?.offer_price + "/-" || ""
                              } <br /> ${
                                option?.package_or_test === "test"
                                  ? option?.also_known_as
                                    ? `Also known as: ${option?.also_known_as}`
                                    : option?.alias?.length > 0
                                    ? `Also known as: ${option?.alias[0].name}`
                                    : ""
                                  : option?.highlight?.["test.name"]
                                  ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                  : ""
                              }`,
                            }}
                          ></div>
                        ) : (
                          option?.name.toString() +
                          ", " +
                          option?.tat_time +
                          ", Rs." +
                          option?.offer_price +
                          "/-"
                        )}

                        {/* {option?.package_city_prices && !option?.highlight?.name
                        ? 
                        option?.package_city_prices?.tat_time +
                        ", Rs. " +
                        option?.package_city_prices?.offer_price +
                        "/-"
                        :  ""} */}
                      </div>
                    </>
                  )}
                  getOptionDisabled={(option: any) => {
                    const packID = packageDetails.map((pack: any) => pack.id);
                    return packID.includes(option.id);
                  }}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPackage(
                        `city_id=${cityId}&search=${newInputValue}&org_type=homedx&gender=${customer_gender}`
                      );
                    }, 1000);
                  }}
                  freeSolo
                  disableCloseOnSelect
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant="default"
                        color="primary"
                        label={
                          option.package_city_prices
                            ? option.name.toString() +
                              ", " +
                              option.package_city_prices.tat_time +
                              ", Rs. " +
                              option?.package_city_prices.offer_price +
                              "/-"
                            : option.name.toString() +
                              ", " +
                              option.tat_time +
                              ", Rs." +
                              option?.offer_price +
                              "/-"
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  disabled={booking.report_status === "consolidate"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Package"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      className="input"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              {/* <Grid item md={2}>
                <p>Patient Phone no *</p>
                <TextField
                  className="input"
                  name="patient_phone_no"
                  type="text"
                  placeholder="Enter Patient Phone no"
                  value={booking?.unique_customer_number === true ? "XXXXXXXXXX" : patientPhoneNo}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required={booking?.unique_customer_number === false || booking?.unique_customer_number === null}
                  style={{ width: "100%", margin: "0" }}
                  onInput={(e: any) => {
                    e.target.value = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 10);
                  }}
                  inputProps={{
                    pattern: "^[6-9]\\d{9}$",
                    title:
                      "Please enter a valid 10-digit phone number starting with a digit from 6 to 9.",
                  }}
                  disabled={booking?.unique_customer_number === true}
                  onChange={(e) => {
                    setPatientPhoneNo(e.target.value as string);
                  }}
                />
              </Grid> */}
              <Grid item md={2}>
                <p>Customer Email</p>
                <TextField
                  className="input"
                  name="customer_email"
                  type="email"
                  placeholder="Enter customer Email id"
                  value={customer_email}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  disabled={deniedEmailCheck}
                  required={!deniedEmailCheck}
                  // onBlur={() => verifyEmail()}
                  onFocus={() => {
                    setEmailValidation(true);
                  }}
                  onChange={(e) => setCustomer_email(e.target.value as String)}
                  // onChange={(e) => handleInputChange(e, index)}
                />
                <sub style={{ color: "red", fontSize: "16px" }}>
                  {!emailValidation ? "Please enter valid e-mail id" : ""}
                </sub>
              </Grid>
              <Grid item md={2}>
                <p>Partner</p>
                <TextField
                  name="partner"
                  type="text"
                  value={partner}
                  placeholder="Enter Order Id"
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={booking.report_status === "consolidate"}
                  onChange={(e) => setPartner(e.target.value as String)}
                  style={{ width: "100%", margin: "0" }}
                />
              </Grid>
              <Grid item md={2}>
                <p>Passport Number</p>
                <TextField
                  className="input"
                  name="passport"
                  type="text"
                  placeholder="Enter Passport Number"
                  value={passport}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={booking.report_status === "consolidate"}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e: any) => setPassport(e.target.value)}
                />
              </Grid>
              <Grid item md={2}>
                <p>Aadhar Number</p>
                <TextField
                  className="input"
                  name="aadhar"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 12);
                  }}
                  placeholder="Enter Aadhar Number"
                  value={aadhar}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={booking.report_status === "consolidate"}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e: any) => setAadhar(e.target.value)}
                />
              </Grid>
              <Grid item md={2}>
                <p>Booking For</p>
                <Select
                  className="input"
                  name="bookingFor"
                  variant="outlined"
                  value={bookingFor ? bookingFor : "Select Booking For"}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  required
                  disabled={booking.report_status === "consolidate"}
                  onChange={(e: any) => setBookingFor(e.target.value)}
                >
                  <MenuItem value="Select Booking For" disabled>
                    Select Booking For
                  </MenuItem>
                  <MenuItem value="self" disabled>
                    Self
                  </MenuItem>
                  <MenuItem value="father" disabled={showFemale}>
                    Father
                  </MenuItem>
                  <MenuItem value="mother" disabled={showFemale === false}>
                    Mother
                  </MenuItem>
                  <MenuItem value="son" disabled={showFemale}>
                    Son
                  </MenuItem>
                  <MenuItem value="daughter" disabled={showFemale === false}>
                    Daughter
                  </MenuItem>
                  <MenuItem value="spouse">Spouse</MenuItem>
                  <MenuItem value="grandparents">Grandparents</MenuItem>
                  <MenuItem value="siblings">Siblings</MenuItem>
                  <MenuItem value="in-laws">In-laws</MenuItem>
                  <MenuItem value="other">Others</MenuItem>
                </Select>
              </Grid>
              <Grid item md={2}>
                <p>Is Prescribed</p>
                <Select
                  className="input"
                  name="is_prescribed"
                  variant="outlined"
                  value={
                    isPrescribed === "true" || isPrescribed === "false"
                      ? isPrescribed
                      : ""
                  }
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  onChange={(e: any) => setIsPrescribed(e.target.value)}
                  disabled={booking.report_status === "consolidate"}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid>

              <Grid item md={2}>
                <p>
                  {" "}
                  <p>Referring Doctor</p>
                </p>
                <Autocomplete
                  id="ref_doctor"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setReferringDoctor(obj?.id);
                    }
                  }}
                  freeSolo
                  blurOnSelect
                  options={refDoctor?.results || []}
                  disableCloseOnSelect
                  disableClearable
                  getOptionLabel={(option: any) =>
                    option?.name ? option?.name : ""
                  }
                  onInputChange={(event, newInputValue) => {
                    setDoctorDef(newInputValue);
                    setReferringDoctorName(newInputValue);
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getRefDoctor(`?name=${newInputValue}`);

                      setCreateNewRedDoctor(refDoctor?.count);
                    }, 1000);
                  }}
                  loading={loading}
                  inputValue={doctorDef || ""}
                  disabled={booking.report_status === "consolidate"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Doctor's Name"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      className="input"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              {createNewRefDoctor === 0 &&
                createNewRefDoctor !== "" &&
                createNewRefDoctor !== undefined && (
                  <>
                    <Grid item md={2}>
                      <Button
                        style={{
                          height: "auto",
                          marginTop: "20%",
                          width: "100%",
                        }}
                        onClick={handleAddDoctor}
                        variant="contained"
                        color="secondary"
                      >
                        Add Doctor
                      </Button>
                    </Grid>
                  </>
                )}
              <Grid item md={2}>
                <p>Pickup Status</p>
                <Select
                  className="input"
                  name="is_prescribed"
                  variant="outlined"
                  value={pickup_status}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  onChange={(e: any) => setPickup_status(e.target.value)}
                  disabled={booking.report_status === "consolidate"}
                >
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                  <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                  <MenuItem value={"hold"}>Hold</MenuItem>
                </Select>
              </Grid>

              <Grid
                item
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontWeight: "bolder", margin: "0" }}>Free Test:</p>
                <Button
                  variant="contained"
                  className="input"
                  disabled={
                    totalPriceForBooking <= 100 ||
                    freeGlucoseFastingTestDisabled ||
                    packageDetails?.length === 0 ||
                    booking?.pickup_status === "confirmed" ||
                    booking.booking_status === "resampling" ||
                    booking.report_status === "consolidate" ||
                    (packageDetails?.length > 0 &&
                      packageDetails?.some((item: { test: any[] }) =>
                        item?.test?.some(
                          (testItem) =>
                            testItem?.id ===
                              Number(
                                process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID
                              ) ||
                            testItem?.id ===
                              Number(
                                process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID
                              )
                        )
                      ))
                  }
                  fullWidth
                  onClick={() => {
                    handleAddFreeGlucoseFastingTest("main_member", 0);
                  }}
                >
                  Free Glucose Test
                </Button>
              </Grid>
              <Grid
                item
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontWeight: "bolder", margin: "0" }}>Free HbA1c:</p>
                <Button
                  variant="contained"
                  className="input"
                  style={{ backgroundColor: "#827717" }}
                  disabled={
                    booking.additional_members.length <= 0 ||
                    totalPriceForBooking < 1299 ||
                    booking?.pickup_status === "confirmed" ||
                    booking.booking_status === "resampling" ||
                    booking.report_status === "consolidate" ||
                    (packageDetails?.length > 0 &&
                      packageDetails?.some((item: { test: any[] }) =>
                        item?.test?.some(
                          (testItem) =>
                            testItem?.id ===
                            Number(process.env.REACT_APP_FREE_HBA1C_ID)
                        )
                      ))
                  }
                  fullWidth
                  onClick={() => {
                    handleAddFreeHbA1cTest("main_member", 0);
                  }}
                >
                  Free HbA1c test
                </Button>
              </Grid>
              <Grid
                item
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontWeight: "bolder", margin: "0" }}>
                  Addon Tests:
                </p>
                <Button
                  variant="contained"
                  className="input"
                  fullWidth
                  disabled={
                    packageDetails?.length === 0 ||
                    (packageDetails &&
                      packageDetails?.length > 0 &&
                      !packageDetails?.some(
                        (item: any) => item?.package_or_test === "package"
                      ))
                  }
                  onClick={() => {
                    handleAddonTest(packageDetails, "main");
                  }}
                >
                  Addon Tests
                </Button>
              </Grid>
              <Grid
                item
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <sup
                  style={{
                    fontSize: "14px",
                    top: "11px",
                  }}
                >
                  Upload Receipt
                </sup>
                <input
                  className="input"
                  required={
                    pickup_status === "confirmed" && invoiceChoiceCheck == false
                  }
                  style={{
                    width: "100%",
                    padding: "5px 1px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "5px",
                  }}
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  onChange={fileSelectedHandlerReceipt}
                />
              </Grid>

              <Grid item md={3}>
                {imgSrc === "" ? (
                  ""
                ) : (
                  <img
                    src={imgSrc ? imgSrc : ""}
                    style={{ width: "400px", height: "250px" }}
                  />
                )}
              </Grid>

              <Grid item md={3}></Grid>
              {allergyQuestionAnswer && allergyQuestionAnswer.length > 0 ? (
                <Grid
                  item
                  md={12}
                  style={{
                    backgroundColor: "#edd6d6",
                    marginTop: "10px",
                    border: "1px dashed",
                    borderRadius: "2px",
                    margin: "10px",
                  }}
                >
                  <h6 id="form-dialog-title">Question For Allergy packages</h6>

                  <Grid
                    container
                    spacing={2}
                    style={{
                      margin: "0px 10px 10px 10px",
                      minWidth: "500px",
                      width: "70%",
                    }}
                  >
                    {allergyQuestionAnswer.map((data: any, index2: any) => {
                      return (
                        <>
                          <Grid item xs={12} md={12}>
                            <p>
                              {index2 + 1}:{data.question_text} (If yes, please
                              specify)*
                            </p>
                            {data.question_type === "radio" ? (
                              <>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={data?.checked}
                                  onChange={(e) =>
                                    allergyAnsHandler(
                                      e.target.value,
                                      index2,
                                      0,
                                      "checked"
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio required />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio required />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </>
                            ) : (
                              ""
                            )}
                            {(data?.checked === "yes" &&
                              data.question_type === "radio") ||
                            data.question_type !== "radio" ? (
                              <TextField
                                className="input"
                                name="allergy_question"
                                type={
                                  data.question_type === "number"
                                    ? "number"
                                    : "text"
                                }
                                value={data?.answer}
                                variant="outlined"
                                style={{ width: "100%", margin: "0" }}
                                required
                                inputProps={{ maxLength: 100 }}
                                onChange={(e) => {
                                  allergyAnsHandler(
                                    data.question_type !== "number"
                                      ? e.target.value
                                      : data.question_type === "number" &&
                                        Number(e.target.value) > 0 &&
                                        Number(e.target.value) < 101
                                      ? e.target.value
                                      : "",
                                    index2,
                                    0,
                                    "answer"
                                  );
                                }}
                                placeholder="Type Here..."
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                        </>
                      );
                    })}
                    <p style={{ fontSize: "17px", marginTop: "10px" }}>
                      * This information will be sent to the lab as Well as
                      Customer
                    </p>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {clinicalQuestionAnswer && clinicalQuestionAnswer.length > 0 ? (
                <Grid
                  item
                  md={12}
                  style={{
                    backgroundColor: "#edd6d6",
                    marginTop: "10px",
                    border: "1px dashed",
                    borderRadius: "2px",
                    margin: "10px",
                  }}
                >
                  <h6
                    style={{
                      fontWeight: "bolder",
                      marginBottom: "10px",
                      marginTop: "10px",
                      marginLeft: "43%",
                    }}
                  >
                    {" "}
                    CLINICAL HISTORY FORM
                  </h6>

                  <Grid
                    container
                    spacing={2}
                    style={{
                      margin: "0px 10px 10px 10px",
                      minWidth: "500px",
                      width: "70%",
                    }}
                  >
                    {clinicalQuestionAnswer.map((data: any, index2: any) => {
                      return (
                        <>
                          <Grid item md={6}>
                            <p
                              style={{
                                fontWeight: "600",
                                color: "#131313",
                              }}
                            >
                              Q{index2 + 1}:{data.question_text}
                              {data.is_required === true && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </p>
                            <TextField
                              name="ClinicalAnswer"
                              type="text"
                              placeholder="Enter Write Your Answer"
                              value={data?.answer}
                              className="input"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{ width: "100%", margin: "0" }}
                              onChange={(e) => {
                                ClinicalAnsHandler(
                                  e.target.value,
                                  index2,
                                  0,
                                  "answer"
                                );
                              }}
                              required
                            />
                          </Grid>
                          <Grid item md={6}>
                            <sup
                              style={{
                                fontSize: "12px",
                                fontWeight: "bolder",
                                color: "#131313",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Upload USG/Doctor’s Prescription
                            </sup>
                            <input
                              className="input"
                              style={{
                                width: "100%",
                                marginBottom: "10px",
                                padding: "14px 16px",
                                border: "5px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "5px",
                              }}
                              accept=".pdf,.doc,.jpg,.jpeg,.png"
                              type="file"
                              name="file"
                              id="file"
                              multiple
                              onChange={async (e) => {
                                let result = await handleClinicalPrescription(
                                  e
                                );
                                ClinicalAnsHandler(
                                  result.base64,
                                  index2,
                                  0,
                                  "doctor_prescription"
                                );
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "17px",
                        marginTop: "10px",
                      }}
                    >
                      * This information will be sent to the lab as Well as
                      Customer
                    </p>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              {packageDetails &&
                packageDetails?.length > 0 &&
                packageDetails?.filter(
                  (item: any) => item?.is_hiv_form === true
                )?.length > 0 && (
                  <Grid
                    container
                    md={12}
                    style={{
                      paddingLeft: "10px",
                      backgroundColor: "#bdd8ef94",
                    }}
                  >
                    <h6
                      style={{
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    >
                      HIV CONSENT FORM
                    </h6>
                    <Grid
                      item
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        marginBottom: "12px",
                      }}
                    >
                      <div className="checkList" style={{ display: "flex" }}>
                        <div style={{ fontWeight: "bolder" }}>Ref By:</div>
                        <div style={{ display: "flex" }}>
                          <div style={{ paddingLeft: "20px" }}>
                            <input
                              value="self"
                              type="checkbox"
                              checked={hivform?.ref_by_doc_or_self === "self"}
                              onChange={(e) => {
                                const { checked } = e.target;
                                setHivform((prevHivform: any) => ({
                                  ...prevHivform,
                                  ref_by_doc_or_self: checked
                                    ? "self"
                                    : "doctor",
                                }));
                              }}
                              required={
                                hivform?.ref_by_doc_or_self?.length > 0
                                  ? false
                                  : true
                              }
                            />
                            <span style={{ paddingLeft: "4px" }}>Self</span>
                          </div>
                          <div style={{ paddingLeft: "20px" }}>
                            <input
                              value="doctor"
                              type="checkbox"
                              checked={hivform?.ref_by_doc_or_self === "doctor"}
                              onChange={(e) => {
                                const { checked } = e.target;
                                setHivform((prevHivform: any) => ({
                                  ...prevHivform,
                                  ref_by_doc_or_self: checked
                                    ? "doctor"
                                    : "self",
                                }));
                              }}
                              required={
                                hivform?.ref_by_doc_or_self?.length > 0
                                  ? false
                                  : true
                              }
                            />
                            <span style={{ paddingLeft: "4px" }}>Doctor</span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={3}>
                        <p style={{ fontWeight: "600", color: "#131313" }}>
                          Ref Doctor Name
                          {hivform?.ref_by_doc_or_self === "doctor" && "*"}
                        </p>
                        <TextField
                          name="hivform.ref_doctor_name"
                          type="text"
                          placeholder="Enter Name"
                          value={hivform?.ref_doctor_name}
                          className="input"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%", margin: "0" }}
                          onChange={(e) =>
                            setHivform({
                              ...hivform,
                              ref_doctor_name: e.target.value
                                .trimStart()
                                .replace(/[^a-zA-Z ]/g, ""),
                            })
                          }
                          required={hivform?.ref_by_doc_or_self === "doctor"}
                          disabled={hivform?.ref_by_doc_or_self === "self"}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <p style={{ fontWeight: "600", color: "#131313" }}>
                          Ref Doc Mobile Number
                          {hivform?.ref_by_doc_or_self === "doctor" && "*"}
                        </p>
                        <TextField
                          className="input"
                          name="hivform.ref_doctor_phone"
                          type="text"
                          value={hivform?.ref_doctor_phone}
                          variant="outlined"
                          onWheel={(e: any) => e.target.blur()}
                          onInput={(e: any) => {
                            e.target.value = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 10);
                          }}
                          placeholder="Ref Mobile Number"
                          onChange={(e) =>
                            setHivform({
                              ...hivform,
                              ref_doctor_phone: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                          required
                          inputProps={{
                            pattern: "^[6-9]\\d{9}$",
                            title:
                              "Please enter a valid 10-digit phone number starting with a digit from 6 to 9.",
                          }}
                          disabled={hivform?.ref_by_doc_or_self === "self"}
                        />
                        {hivform?.ref_by_doc_or_self !== "self" && (
                          <sub style={{ color: "#f44336", fontSize: "12px" }}>
                            {hivform?.ref_doctor_phone?.length !== 0 &&
                              (parseInt(
                                hivform?.ref_doctor_phone &&
                                  hivform?.ref_doctor_phone[0]
                              ) > 5 && hivform?.ref_doctor_phone?.length === 10
                                ? ""
                                : "Please enter valid mobile number")}
                          </sub>
                        )}
                      </Grid>
                      <Grid item md={3}>
                        <p style={{ fontWeight: "600", color: "#131313" }}>
                          Counsellor Name
                          {hivform?.ref_by_doc_or_self === "doctor" && "*"}
                        </p>
                        <TextField
                          name="hivform.counsellor_name"
                          type="text"
                          placeholder="Enter Counsellor Name"
                          value={hivform?.counsellor_name}
                          className="input"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%", margin: "0" }}
                          onChange={(e) =>
                            setHivform({
                              ...hivform,
                              counsellor_name: e.target.value
                                .trimStart()
                                .replace(/[^a-zA-Z ]/g, ""),
                            })
                          }
                          required={hivform?.ref_by_doc_or_self === "doctor"}
                          disabled={hivform?.ref_by_doc_or_self === "self"}
                        />
                      </Grid>
                      <Grid item md={3} id="custom__helper">
                        <p style={{ fontWeight: "600", color: "#131313" }}>
                          Aadhaar/Passport Number*
                        </p>
                        <TextField
                          className="input"
                          name="hivform.adhaar_number"
                          placeholder="Enter Aadhaar/Passport Number"
                          value={hivform?.adhaar_number}
                          variant="outlined"
                          type="text"
                          onWheel={(e: any) => e.target.blur()}
                          onInput={(e: any) => {
                            e.target.value = e.target.value
                              .replace(/[^a-zA-Z0-9]/g, "")
                              .slice(0, 12);
                          }}
                          style={{ width: "100%", margin: "0" }}
                          onChange={(e) =>
                            setHivform({
                              ...hivform,
                              adhaar_number: e.target.value,
                            })
                          }
                          required={
                            booking?.booking_status !== "cancelled" &&
                            pickup_status !== "cancelled"
                          }
                          inputProps={{
                            pattern: "^[a-zA-Z0-9]{7,12}$",
                            title:
                              "Please enter a valid 7 to 12 alphanumeric Aadhaar/Passport number with only alphanumeric characters.",
                          }}
                        />
                        <sub style={{ color: "#f44336", fontSize: "12px" }}>
                          {hivform?.adhaar_number?.length === 0 ||
                          hivform?.adhaar_number?.length >= 7
                            ? ""
                            : "Please enter a valid Aadhaar/Passport number"}
                        </sub>
                      </Grid>
                      <Grid item md={2}>
                        <p style={{ fontWeight: "600", color: "#131313" }}>
                          Counselling Date
                          {hivform?.ref_by_doc_or_self === "doctor" && "*"}
                        </p>
                        <input
                          className="input"
                          id="noTyping"
                          name="hivform.counselling_date"
                          type="date"
                          value={hivform?.counselling_date}
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "18.5px 14px",
                            height: "40px",
                          }}
                          min={
                            hivform?.counselling_date === null
                              ? moment(new Date()).format("YYYY-MM-DD")
                              : hivform?.counselling_date
                          }
                          onChange={(e) =>
                            setHivform({
                              ...hivform,
                              counselling_date: e.target.value,
                            })
                          }
                          required={hivform?.ref_by_doc_or_self === "doctor"}
                          disabled={hivform?.ref_by_doc_or_self === "self"}
                        />
                      </Grid>
                      <Grid
                        item
                        md={3}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                        }}
                      >
                        <sup
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#131313",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          Upload Aadhaar Card
                          {(imageAddhaarURL === null ||
                            imageAddhaarURL === "") &&
                            "*"}
                        </sup>
                        <input
                          className="input"
                          style={{
                            width: "100%",
                            marginTop: "17px",
                            padding: "6px 14px",
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "5px",
                          }}
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          name="file"
                          id="file"
                          required={
                            imageAddhaarURL === null || imageAddhaarURL === ""
                              ? true
                              : false
                          }
                          onChange={(e) => handleFileSelectedHIVMainMember(e)}
                        />
                      </Grid>
                      <Grid
                        item
                        md={1}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => {
                            setImageAddhaarViewURL(imageAddhaarURL);
                            setViewImgAadhaar(true);
                          }}
                          variant="contained"
                          style={{
                            backgroundColor:
                              imageAddhaarURL === null || imageAddhaarURL === ""
                                ? "gray"
                                : "rgb(16 125 7)",
                          }}
                          disabled={
                            imageAddhaarURL === null || imageAddhaarURL === ""
                          }
                        >
                          View
                        </Button>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          marginBottom: "12px",
                        }}
                      >
                        <div className="checkList" style={{ display: "flex" }}>
                          <div style={{ fontWeight: "bolder" }}>
                            Report Can Be Seen By*:
                          </div>
                          <div style={{ display: "flex" }}>
                            <div style={{ paddingLeft: "20px" }}>
                              <input
                                value={"self"}
                                type="checkbox"
                                checked={hivform?.report_can_be_seen_by.includes(
                                  "self"
                                )}
                                onChange={(e) => {
                                  const { checked, value } = e.target;
                                  if (checked) {
                                    let list = [
                                      ...hivform?.report_can_be_seen_by,
                                      value,
                                    ];
                                    setHivform((prevHivform: any) => ({
                                      ...prevHivform,
                                      report_can_be_seen_by: list,
                                    }));
                                  } else {
                                    let list =
                                      hivform?.report_can_be_seen_by.filter(
                                        (item: string) => item !== value
                                      );
                                    setHivform((prevHivform: any) => ({
                                      ...prevHivform,
                                      report_can_be_seen_by: list,
                                    }));
                                  }
                                }}
                                required={
                                  hivform?.report_can_be_seen_by?.length > 0
                                    ? false
                                    : true
                                }
                              />
                              <span style={{ paddingLeft: "4px" }}>Self</span>
                            </div>
                            <div style={{ paddingLeft: "20px" }}>
                              <input
                                value={"refdoctor"}
                                type="checkbox"
                                checked={hivform?.report_can_be_seen_by.includes(
                                  "refdoctor"
                                )}
                                onChange={(e) => {
                                  const { checked, value } = e.target;
                                  if (checked) {
                                    let list = [
                                      ...hivform?.report_can_be_seen_by,
                                      value,
                                    ];
                                    setHivform((prevHivform: any) => ({
                                      ...prevHivform,
                                      report_can_be_seen_by: list,
                                    }));
                                  } else {
                                    let list =
                                      hivform?.report_can_be_seen_by.filter(
                                        (item: string) => item !== value
                                      );
                                    setHivform((prevHivform: any) => ({
                                      ...prevHivform,
                                      report_can_be_seen_by: list,
                                    }));
                                  }
                                }}
                                required={
                                  hivform?.report_can_be_seen_by?.length > 0
                                    ? false
                                    : true
                                }
                              />
                              <span style={{ paddingLeft: "4px" }}>
                                Ref. Doctor
                              </span>
                            </div>
                            <div style={{ paddingLeft: "20px" }}>
                              <input
                                value={"refagency"}
                                type="checkbox"
                                checked={hivform?.report_can_be_seen_by.includes(
                                  "refagency"
                                )}
                                onChange={(e) => {
                                  const { checked, value } = e.target;
                                  if (checked) {
                                    let list = [
                                      ...hivform?.report_can_be_seen_by,
                                      value,
                                    ];
                                    setHivform((prevHivform: any) => ({
                                      ...prevHivform,
                                      report_can_be_seen_by: list,
                                    }));
                                  } else {
                                    let list =
                                      hivform?.report_can_be_seen_by.filter(
                                        (item: string) => item !== value
                                      );
                                    setHivform((prevHivform: any) => ({
                                      ...prevHivform,
                                      report_can_be_seen_by: list,
                                    }));
                                  }
                                }}
                                required={
                                  hivform?.report_can_be_seen_by?.length > 0
                                    ? false
                                    : true
                                }
                              />
                              <span style={{ paddingLeft: "4px" }}>
                                Ref. Agency
                              </span>
                            </div>
                            <div style={{ paddingLeft: "20px" }}>
                              <input
                                value={"relative"}
                                type="checkbox"
                                checked={hivform?.report_can_be_seen_by.includes(
                                  "relative"
                                )}
                                onChange={(e) => {
                                  const { checked, value } = e.target;
                                  if (checked) {
                                    let list = [
                                      ...hivform?.report_can_be_seen_by,
                                      value,
                                    ];
                                    setHivform((prevHivform: any) => ({
                                      ...prevHivform,
                                      report_can_be_seen_by: list,
                                    }));
                                  } else {
                                    let list =
                                      hivform?.report_can_be_seen_by.filter(
                                        (item: string) => item !== value
                                      );
                                    setHivform((prevHivform: any) => ({
                                      ...prevHivform,
                                      report_can_be_seen_by: list,
                                    }));
                                  }
                                }}
                                required={
                                  hivform?.report_can_be_seen_by?.length > 0
                                    ? false
                                    : true
                                }
                              />
                              <span style={{ paddingLeft: "4px" }}>
                                Relative
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>

            <h4>Additional Members:</h4>
            {booking &&
              booking?.additional_members?.length > 0 &&
              inputList.map((x: any, index: number) => {
                if (x?.isSelected === false) {
                  const currAllergyQn = x?.allergyForm;
                  const currClinicalQn = x.clinicalForm;

                  const idExists: boolean =
                    x?.customerPackage?.length > 0 &&
                    x?.customerPackage?.some(
                      (item: { test: { id: number; name: string }[] }) =>
                        item?.test?.length > 0 &&
                        item?.test?.some(
                          (testItem: { id: number; name: string }) =>
                            testItem?.id ===
                              Number(
                                process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID
                              ) ||
                            testItem?.id ===
                              Number(
                                process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID
                              )
                        )
                    );
                  const idExistsHba1c: boolean =
                    x?.customerPackage?.length > 0 &&
                    x?.customerPackage?.some(
                      (item: { test: { id: number; name: string }[] }) =>
                        item?.test?.length > 0 &&
                        item?.test?.some(
                          (testItem: { id: number; name: string }) =>
                            testItem?.id ===
                            Number(process.env.REACT_APP_FREE_HBA1C_ID)
                        )
                    );

                  const isBC035APackageAvailable = x.customerPackage.some(
                    (packageItem: any) => packageItem?.code === "BC035A"
                  );
                  const isBC035PackageAvailable = x.customerPackage.some(
                    (packageItem: any) => packageItem?.code === "BC035"
                  );

                  if (isBC035APackageAvailable && isBC035PackageAvailable) {
                    const updatedPackages = x.customerPackage.filter(
                      (item: any) => item?.code !== "BC035A"
                    );
                    handlePackage(
                      index,
                      updatedPackages,
                      x.customerPackage[x.customerPackage.length - 1]
                    );
                  }
                  return (
                    <>
                      <p>
                        {x.customerId !== ""
                          ? `UHID : ${
                              x.oldNameUHID === x.customerName
                                ? x.customerId
                                : ""
                            }`
                          : ""}
                      </p>
                      <Grid
                        container
                        direction="row"
                        spacing={3}
                        style={{
                          margin: "1rem auto",
                          paddingBottom: "10px",
                          background:
                            x?.isPickupStatus !== "cancelled" &&
                            x?.isBookingStatus !== "cancelled"
                              ? "#e1f5fe"
                              : "rgb(215 42 38 / 21%)",
                        }}
                      >
                        {/* <Grid item md={12}>
                          <p style={{ margin: "0" }}>{x.customerId !== "" ? `UHID : ${x.oldNameUHID === x.customerName ? x.customerId : ""}` : ""}</p>
                        </Grid> */}
                        <Grid item md={2}>
                          <p>Title</p>
                          <Select
                            className="input"
                            name="designation"
                            variant="outlined"
                            value={x.designation}
                            style={{
                              width: "100%",
                              margin: "0",
                              height: "40px",
                            }}
                            onChange={(e) => handleInputChange(e, index)}
                            required
                            displayEmpty={true}
                            disabled={x?.isPickupStatus === "confirmed"}
                            renderValue={
                              x.designation !== ""
                                ? undefined
                                : () => "Select Title"
                            }
                          >
                            <MenuItem value="Mr">Mr</MenuItem>
                            <MenuItem value="Ms">Ms (Mrs/Miss)</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item md={2}>
                          <p>Customer Name</p>
                          <TextField
                            className="input"
                            name="customerName"
                            type="text"
                            placeholder="Enter Name"
                            value={x.customerName || ""}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            onChange={(e) => handleInputChange(e, index)}
                            required={
                              x?.isPickupStatusPrevious !== "cancelled" &&
                              x?.isBookingStatusPrevious !== "cancelled"
                            }
                            // disabled={booking?.bill_id !== null}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <p>Age</p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end",
                            }}
                          >
                            <TextField
                              name="customerAge"
                              type="number"
                              placeholder="Enter Age"
                              value={x.customerAge}
                              className="input"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{ margin: "0" }}
                              onChange={(e) => {
                                handleInputChange(e, index);
                              }}
                              required
                              // disabled={booking?.bill_id !== null}
                            />
                          </div>
                        </Grid>
                        <Grid item md={1}>
                          <p>Gender</p>
                          <TextField
                            className="input"
                            name="customerGender"
                            variant="outlined"
                            placeholder="Select Gender"
                            value={
                              x.customerGender === "male" ||
                              x.customerGender === "Male"
                                ? "Male"
                                : x.customerGender === "female" ||
                                  x.customerGender === "Female"
                                ? "Female"
                                : x.customerGender === "other"
                                ? "Male"
                                : ""
                            }
                            style={{
                              width: "100%",
                              margin: "0",
                              height: "40px",
                            }}
                            onChange={(e) => handleInputChange(e, index)}
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <p>Package Name</p>
                          <Autocomplete
                            id="customerPackage"
                            key={packageKey}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                let packs: any = [];
                                newValue.map((val: any) => {
                                  let obj = JSON.parse(
                                    JSON.stringify(val, null, " ")
                                  );
                                  packs.push(obj);
                                });
                                let len = packs.length;
                                let arr = removeAddnTest(packs);
                                handlePackage(index, arr, -1);
                                fetchAllergyQn(
                                  packs.length > 0 &&
                                    packs.map((val: any) => val.id),
                                  index + 1
                                );
                                fetchClinicalQn(
                                  packs.length > 0 &&
                                    packs.map((val: any) => val.id),
                                  index + 1
                                );
                              }
                              setRedCashButton(false);
                              setCValue("");
                              setDiscount(0);
                              setCoupon(0);
                              setCouponCode("");
                              setCType("");
                              setReferralPointsDiscount(0);
                              setReferralCouponDiscount(0);
                              setReferralPointsApplied(false);
                              // setCouponMessage("");
                            }}
                            multiple
                            // loading={geoLoading}
                            disableClearable
                            options={packageList?.results || []}
                            limitTags={0}
                            // disabled={is_pincode}
                            freeSolo
                            disableCloseOnSelect
                            filterOptions={(options, state) => options}
                            aria-required
                            onInputChange={(event, newInputValue) => {
                              // if (newInputValue.trim().length > 0) {
                              //   setIsPackageTextPresent(true);
                              // } else {
                              //   setIsPackageTextPresent(false);
                              // }
                              clearTimeout(timer.current);
                              timer.current = setTimeout(() => {
                                getPackage(
                                  `city_id=${cityId}&search=${newInputValue}&org_type=homedx${
                                    x?.customerGender
                                      ? `&gender=${x?.customerGender}`
                                      : ""
                                  }`
                                );
                              }, 1000);
                            }}
                            value={x?.customerPackage}
                            disabled={
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              x?.isPickupStatusPrevious === "confirmed" ||
                              booking.isBookingStatusPrevious === "resampling"
                            }
                            getOptionLabel={(option: any) => {
                              return option?.name && option?.package_city_prices
                                ? option?.name.toString() +
                                    ", " +
                                    option?.package_city_prices?.tat_time +
                                    ", Rs. " +
                                    option?.package_city_prices?.offer_price +
                                    "/-"
                                : option?.name +
                                    ", " +
                                    option?.tat_time +
                                    ", Rs. " +
                                    option?.offer_price +
                                    "/-";
                            }}
                            getOptionDisabled={(option: any) => {
                              const packID = x?.customerPackage.map(
                                (pack: any) => pack?.id
                              );
                              return packID?.includes(option?.id);
                            }}
                            renderOption={(option, { selected }) => (
                              <>
                                <Checkbox
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                <div style={{ display: "flex" }}>
                                  {option?.package_city_prices ? (
                                    option?.highlight?.name &&
                                    option?.highlight?.name.length > 0 ? (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: `${String(
                                            option?.highlight?.name
                                          )}, ${
                                            option?.package_city_prices
                                              ?.tat_time
                                          }, Rs. ${
                                            option?.package_city_prices
                                              ?.offer_price
                                          }/- <br /> ${
                                            option?.package_or_test === "test"
                                              ? option?.also_known_as
                                                ? `Also known as: ${option?.also_known_as}`
                                                : option?.alias?.length > 0
                                                ? `Also known as: ${option?.alias[0].name}`
                                                : ""
                                              : option?.highlight?.["test.name"]
                                              ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                              : ""
                                          }`,
                                        }}
                                      ></div>
                                    ) : (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: `${option?.name.toString()}, ${
                                            option?.package_city_prices
                                              ?.tat_time
                                          }, Rs. ${
                                            option?.package_city_prices
                                              ?.offer_price
                                          } <br /> ${
                                            option?.package_or_test === "test"
                                              ? option?.also_known_as
                                                ? `Also known as: ${option?.also_known_as}`
                                                : option?.alias?.length > 0
                                                ? `Also known as: ${option?.alias[0].name}`
                                                : ""
                                              : option?.highlight?.["test.name"]
                                              ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                              : ""
                                          }`,
                                        }}
                                      />
                                    )
                                  ) : option?.highlight?.name &&
                                    option?.highlight?.name.length > 0 ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `${String(
                                          option?.highlight?.name
                                        )}, ${
                                          option?.tat_time
                                            ? `${option?.tat_time},`
                                            : ""
                                        } ${
                                          "Rs. " + option?.offer_price + "/-" ||
                                          ""
                                        } <br /> ${
                                          option?.package_or_test === "test"
                                            ? option?.also_known_as
                                              ? `Also known as: ${option?.also_known_as}`
                                              : option?.alias?.length > 0
                                              ? `Also known as: ${option?.alias[0].name}`
                                              : ""
                                            : option?.highlight?.["test.name"]
                                            ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                            : ""
                                        }`,
                                      }}
                                    ></div>
                                  ) : (
                                    option?.name.toString() +
                                    ", " +
                                    option?.tat_time +
                                    ", Rs." +
                                    option?.offer_price +
                                    "/-"
                                  )}

                                  {/* {option?.package_city_prices && !option?.highlight?.name
                                  ? 
                                  option?.package_city_prices?.tat_time +
                                  ", Rs. " +
                                  option?.package_city_prices?.offer_price +
                                  "/-"
                                  :  ""} */}
                                </div>
                              </>
                            )}
                            renderInput={(params) => (
                              <TextField
                                className="input"
                                {...params}
                                placeholder="Package"
                                variant="outlined"
                                style={{
                                  width: "100%",
                                  margin: "0",
                                  padding: "0",
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  onKeyDown: (e) => {
                                    if (e.key === "Enter") {
                                      e.stopPropagation();
                                    }
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <p>Customer Email</p>
                          <TextField
                            className="input"
                            name="customer_email"
                            type="email"
                            placeholder="Enter customer Email id"
                            value={x.customer_email}
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                            disabled={deniedEmailCheck}
                            required={!deniedEmailCheck}
                            // onBlur={() => verifyEmail()}
                            onFocus={() => {
                              setEmailValidation(true);
                            }}
                            // onChange={(e) =>
                            //   setCustomer_email(e.target.value as String)
                            // }
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <sub style={{ color: "red", fontSize: "16px" }}>
                            {!emailValidation
                              ? "Please enter valid e-mail id"
                              : ""}
                          </sub>
                        </Grid>
                        <Grid item md={2}>
                          <p>Patient Phone no *</p>
                          <TextField
                            className="input"
                            name="patient_phone_no"
                            type="text"
                            placeholder="Enter Patient Phone no"
                            value={x?.unique_customer_number
                              ?.toString()
                              ?.toUpperCase()}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            // required={
                            //   booking?.booking_status !== "cancelled" ||
                            //   booking?.pickup_status !== "cancelled" ||
                            //   x?.isPickupStatusPrevious !== "cancelled" ||
                            //   x?.isBookingStatusPrevious !== "cancelled"
                            // }
                            style={{ width: "100%", margin: "0" }}
                            onInput={(e: any) => {
                              e.target.value = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 10);
                            }}
                            disabled={
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              booking.report_status === "consolidate" ||
                              booking?.booking_status === "cancelled" ||
                              booking?.pickup_status === "cancelled" ||
                              x?.unique_customer_number !== null
                            }
                            onChange={(e) => handleInputChange(e, index)}
                            {...(x?.unique_customer_number == null
                              ? {
                                  inputProps: {
                                    pattern: "^[6-9]\\d{9}$",
                                    title:
                                      "Please enter a valid 10-digit phone number starting with a digit from 6 to 9.",
                                  },
                                }
                              : {})}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <p>Passport Number</p>
                          <TextField
                            className="input"
                            name="passport"
                            type="text"
                            placeholder="Enter Passport Number"
                            value={x.passport || ""}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            onInput={(e: any) => {
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 10);
                            }}
                            disabled={
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              booking.report_status === "consolidate"
                            }
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <p>Aadhaar Number</p>
                          <TextField
                            className="input"
                            name="aadhar"
                            type="number"
                            onWheel={(e: any) => e.target.blur()}
                            onInput={(e: any) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 12);
                            }}
                            placeholder="Enter Aadhaar Number"
                            value={x.aadhar || ""}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            onChange={(e) => handleInputChange(e, index)}
                            disabled={
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              booking.report_status === "consolidate"
                            }
                          />
                        </Grid>
                        <Grid item md={2}>
                          <p>Booking For</p>
                          <Select
                            className="input"
                            name="bookingFor"
                            variant="outlined"
                            value={x.bookingFor}
                            style={{
                              width: "100%",
                              margin: "0",
                              height: "40px",
                            }}
                            required={
                              x?.isPickupStatusPrevious !== "cancelled" &&
                              x?.isBookingStatusPrevious !== "cancelled"
                            }
                            displayEmpty={true}
                            renderValue={
                              x.bookingFor !== ""
                                ? undefined
                                : () => "Select Booking For"
                            }
                            onChange={(e) => handleInputChange(e, index)}
                            disabled={
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              booking.report_status === "consolidate"
                            }
                          >
                            <MenuItem value="self" disabled>
                              Self
                            </MenuItem>
                            <MenuItem
                              value="father"
                              disabled={
                                x?.customerGender?.toLowerCase() !== "male"
                              }
                            >
                              Father
                            </MenuItem>
                            <MenuItem
                              value="mother"
                              disabled={
                                x?.customerGender?.toLowerCase() === "male"
                              }
                            >
                              Mother
                            </MenuItem>
                            <MenuItem
                              value="son"
                              disabled={
                                x?.customerGender?.toLowerCase() !== "male"
                              }
                            >
                              Son
                            </MenuItem>
                            <MenuItem
                              value="daughter"
                              disabled={
                                x?.customerGender?.toLowerCase() === "male"
                              }
                            >
                              Daughter
                            </MenuItem>
                            <MenuItem value="spouse">Spouse</MenuItem>
                            <MenuItem value="grandparents">
                              Grandparents
                            </MenuItem>
                            <MenuItem value="siblings">Siblings</MenuItem>
                            <MenuItem value="in-laws">In-laws</MenuItem>
                            <MenuItem value="other">Others</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item md={2}>
                          <p>Is Prescribed</p>
                          <Select
                            className="input"
                            name="is_prescribed"
                            variant="outlined"
                            value={x.is_prescribed}
                            displayEmpty={true}
                            style={{
                              width: "100%",
                              margin: "0",
                              height: "40px",
                            }}
                            onChange={(e) => handleInputChange(e, index)}
                            renderValue={
                              x.is_prescribed !== ""
                                ? undefined
                                : () => "Select Prescribed"
                            }
                            disabled={
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              booking.report_status === "consolidate"
                            }
                          >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item md={2}>
                          <p>Pickup Status</p>
                          <Select
                            className="input"
                            name="isPickupStatus"
                            variant="outlined"
                            value={x.isPickupStatus}
                            style={{
                              width: "100%",
                              margin: "0",
                              height: "40px",
                            }}
                            onChange={(e) => handleInputChange(e, index)}
                            disabled={
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              booking.report_status === "consolidate"
                            }
                          >
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                            <MenuItem value={"hold"}>Hold</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item md={2}>
                          <p>Booking Status</p>
                          <Select
                            className="input"
                            name="isBookingStatus"
                            variant="outlined"
                            value={x.isBookingStatus}
                            style={{
                              width: "100%",
                              margin: "0",
                              height: "40px",
                            }}
                            onChange={(e) => {
                              handleInputChange(e, index);
                              // if (e.target.value === "cancelled" || x.isBookingStatus === "cancelled") {
                              setUpdateRedCash(!updateRedCash);
                              setDiscount(0);
                              handlePackages(packageDetails);
                              // }
                            }}
                            disabled={
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              booking.report_status === "consolidate"
                            }
                          >
                            <MenuItem value={x.isBookingStatusPrevious}>
                              {x.isBookingStatusPrevious?.toUpperCase()}
                            </MenuItem>
                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item md={2}>
                          <p style={{ fontWeight: "bolder" }}>Free Test:</p>
                          <Button
                            variant="contained"
                            className="input"
                            disabled={
                              totalPriceForBooking <= 100 ||
                              x?.freeGlucoseFastingTestDisabled ||
                              x?.customerPackage?.length === 0 ||
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              x?.isPickupStatusPrevious === "confirmed" ||
                              booking.isBookingStatusPrevious ===
                                "resampling" ||
                              idExists
                            }
                            style={{ height: "40px" }}
                            fullWidth
                            onClick={() => {
                              handleAddFreeGlucoseFastingTest(x, index);
                            }}
                          >
                            Free Glucose Test
                          </Button>
                        </Grid>
                        <Grid item md={2}>
                          <p style={{ fontWeight: "bolder" }}>Free HbA1c:</p>
                          <Button
                            variant="contained"
                            className="input"
                            disabled={
                              booking.additional_members.length <= 0 ||
                              totalPriceForBooking < 1299 ||
                              x?.isPickupStatusPrevious === "cancelled" ||
                              x?.isBookingStatusPrevious === "cancelled" ||
                              x?.isPickupStatusPrevious === "confirmed" ||
                              booking.isBookingStatusPrevious ===
                                "resampling" ||
                              idExistsHba1c
                            }
                            style={{
                              height: "40px",
                              backgroundColor: "#827717",
                            }}
                            fullWidth
                            onClick={() => {
                              handleAddFreeHbA1cTest(x, index);
                            }}
                          >
                            Free HbA1c test
                          </Button>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontWeight: "bolder", margin: "0" }}>
                            Addon Tests:
                          </p>
                          <Button
                            variant="contained"
                            className="input"
                            fullWidth
                            disabled={
                              x?.customerPackage?.length === 0 ||
                              (x?.customerPackage &&
                                x?.customerPackage?.length > 0 &&
                                !x?.customerPackage?.some(
                                  (item: any) =>
                                    item?.package_or_test === "package"
                                ))
                            }
                            onClick={() => {
                              handleAddonTest(x?.customerPackage, index);
                            }}
                          >
                            Addon Tests
                          </Button>
                        </Grid>

                        {/* {
                          (inputList[index]?.isPickupStatus !== "cancelled" && inputList[index]?.isBookingStatus !== "cancelled") && (inputList[index]?.isEdit === false) &&
                          <>
                            <Grid item md={8} style={{ marginTop: "20px" }}></Grid>
                            <Grid item md={2} style={{ marginTop: "20px" }}>
                              <Button
                                variant="contained"
                                className="input"
                                style={{ height: "40px", backgroundColor: "#cc6762", fontSize: "14px" }}
                                fullWidth
                                onClick={() => {
                                  const list = [...inputList];
                                  for (let i=0; i<inputList?.length; i++) {
                                    if (list[i]["isEdit"]) setOpenEditModal(true)
                                  }
                                  setAdditionalMemberPackagesOtherThanSelected(booking?.additional_members?.filter((mem: any) => (mem?.pickup_status !== "cancelled" && mem?.booking_status !== "cancelled" && mem?.customerid !== x?.customerId)).map((p: any) => { return p.packages; }))
                                  for (let i=0; i<inputList?.length; i++) {
                                    list[i]["isEdit"] = false;
                                  }
                                  list[index]["isEdit"] = true;
                                  setInputList(list);
                                }}
                              >
                                <EditRoundedIcon style={{ marginRight: "8px" }} />
                                Edit This Member
                              </Button>
                            </Grid>
                            <Grid item md={2} style={{ marginTop: "20px" }}>
                              <Button
                                variant="contained"
                                style={{ height: "40px", backgroundColor: "#e20b0b", fontSize: "14px" }}
                                onClick={() => handleCancelButton(x?.bookingId)}
                              >
                                <CancelRoundedIcon style={{ marginRight: "8px" }} />
                                Cancel Member
                              </Button>
                            </Grid>
                          </>
                        }
                        {
                          (inputList[index]?.isEdit === true) &&
                          <>
                            <Grid item md={8} style={{ marginTop: "20px" }}></Grid>
                            <Grid item md={2} style={{ marginTop: "20px" }}>
                              <Button
                                variant="contained"
                                className="input"
                                style={{ height: "40px", backgroundColor: "#e20b0b", fontSize: "12px" }}
                                fullWidth
                                onClick={() => {
                                  const list = [...inputList];
                                  list[index]["isEdit"] = false;
                                  setInputList(list);
                                  setInitialState(!initialState);
                                }}
                              >
                                <CancelRoundedIcon style={{ marginRight: "7px" }} />
                                Don't Edit
                              </Button>
                            </Grid>
                            <Grid item md={2} style={{ marginTop: "20px" }}>
                              <Button
                                variant="contained"
                                className="input"
                                style={{ height: "40px", backgroundColor: "#13b813", fontSize: "12px" }}
                                fullWidth
                                disabled={x?.customerName === "" || x?.customerAge < 6 || x?.customerPackage?.length === 0 || x?.bookingFor === ""}
                                onClick={() => {
                                  updateFamilyMemberHandler(x)
                                }}
                              >
                                <CheckCircleRoundedIcon style={{ marginRight: "7px" }} />
                                Update This Member
                              </Button>
                            </Grid>
                          </>
                        } */}
                        {currAllergyQn && currAllergyQn.length > 0 ? (
                          <Grid
                            item
                            md={12}
                            style={{
                              backgroundColor: "#edd6d6",
                              marginTop: "10px",
                              border: "1px dashed",
                              borderRadius: "2px",
                              margin: "10px",
                            }}
                          >
                            <h6 id="form-dialog-title">
                              Question For Allergy packages
                            </h6>

                            <Grid
                              container
                              spacing={2}
                              style={{
                                margin: "0px 10px 10px 10px",
                                minWidth: "500px",
                                width: "70%",
                              }}
                            >
                              {currAllergyQn.map((data: any, index2: any) => {
                                return (
                                  <>
                                    <Grid item xs={12} md={12}>
                                      <p>
                                        {index2 + 1}:{data.question_text} (If
                                        yes, please specify)*
                                      </p>
                                      {data.question_type === "radio" ? (
                                        <>
                                          <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={data?.checked}
                                            onChange={(e) =>
                                              allergyAnsHandler(
                                                e.target.value,
                                                index2,
                                                index + 1,
                                                "checked"
                                              )
                                            }
                                            row
                                          >
                                            <FormControlLabel
                                              value="yes"
                                              control={<Radio required />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              value="no"
                                              control={<Radio required />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {(data?.checked === "yes" &&
                                        data.question_type === "radio") ||
                                      data.question_type !== "radio" ? (
                                        <TextField
                                          className="input"
                                          name="allergy_question"
                                          type={
                                            data.question_type === "number"
                                              ? "number"
                                              : "text"
                                          }
                                          value={data?.answer}
                                          variant="outlined"
                                          style={{ width: "100%", margin: "0" }}
                                          required
                                          inputProps={{ maxLength: 100 }}
                                          onChange={(e) => {
                                            allergyAnsHandler(
                                              data.question_type !== "number"
                                                ? e.target.value
                                                : data.question_type ===
                                                    "number" &&
                                                  Number(e.target.value) > 0 &&
                                                  Number(e.target.value) < 101
                                                ? e.target.value
                                                : "",
                                              index2,
                                              index + 1,
                                              "answer"
                                            );
                                          }}
                                          placeholder="Type Here..."
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </>
                                );
                              })}
                              <p
                                style={{ fontSize: "17px", marginTop: "10px" }}
                              >
                                * This information will be sent to the lab as
                                Well as Customer
                              </p>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {currClinicalQn && currClinicalQn.length > 0 && (
                          <Grid
                            item
                            md={12}
                            style={{
                              backgroundColor: "#edd6d6",
                              marginTop: "10px",
                              border: "1px dashed",
                              borderRadius: "2px",
                              margin: "10px",
                            }}
                          >
                            <h6
                              style={{
                                fontWeight: "bolder",
                                marginBottom: "10px",
                                marginTop: "10px",
                                marginLeft: "43%",
                              }}
                            >
                              {" "}
                              CLINICAL HISTORY FORM
                            </h6>
                            <Grid
                              container
                              spacing={2}
                              style={{
                                margin: "0px 10px 10px 10px",
                                minWidth: "500px",
                                width: "70%",
                              }}
                            >
                              {currClinicalQn.map((data: any, index2: any) => {
                                return (
                                  <>
                                    <Grid item md={6}>
                                      <p
                                        style={{
                                          fontWeight: "600",
                                          color: "#131313",
                                        }}
                                      >
                                        Q{index2 + 1}:{data.question_text}
                                        {data.is_required === true && (
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        )}
                                      </p>
                                      <TextField
                                        name="ClinicalAnswer"
                                        type="text"
                                        placeholder="Enter Write Your Answer"
                                        value={data?.answer}
                                        className="input"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        onChange={(e) => {
                                          ClinicalAnsHandler(
                                            e.target.value,
                                            index2,
                                            index + 1,
                                            "answer"
                                          );
                                        }}
                                        required
                                      />
                                    </Grid>
                                    <Grid item md={6}>
                                      <sup
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "bolder",
                                          color: "#131313",
                                          letterSpacing: "0.00938em",
                                        }}
                                      >
                                        Upload USG/Doctor’s Prescription
                                      </sup>
                                      <input
                                        className="input"
                                        style={{
                                          width: "100%",
                                          marginBottom: "10px",
                                          padding: "14px 16px",
                                          border:
                                            "5px solid rgba(0, 0, 0, 0.23)",
                                          borderRadius: "5px",
                                        }}
                                        accept=".pdf,.doc,.jpg,.jpeg,.png"
                                        type="file"
                                        name="file"
                                        id="file"
                                        multiple
                                        onChange={async (e) => {
                                          let result =
                                            await handleClinicalPrescription(e);
                                          ClinicalAnsHandler(
                                            result.base64,
                                            index2,
                                            index + 1,
                                            "doctor_prescription"
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </>
                                );
                              })}
                              <p
                                style={{
                                  fontWeight: "600",
                                  fontSize: "17px",
                                  marginTop: "10px",
                                }}
                              >
                                * This information will be sent to the lab as
                                Well as Customer
                              </p>
                            </Grid>
                          </Grid>
                        )}
                        {+inputList[index]?.customerAge < 5 &&
                          inputList[index]?.customerAge !== "" && (
                            <p
                              style={{
                                textAlign: "center",
                                width: "100%",
                                color: "red",
                              }}
                            >
                              Age cannot be less than 5 years.
                            </p>
                          )}
                        {x?.customerPackage &&
                          x?.customerPackage?.length > 0 &&
                          x?.customerPackage?.filter(
                            (item: any) => item?.is_hiv_form === true
                          )?.length > 0 && (
                            <Grid
                              container
                              md={12}
                              style={{
                                paddingLeft: "10px",
                                backgroundColor: "#bdd8ef94",
                              }}
                            >
                              <h6
                                style={{
                                  fontWeight: 600,
                                  display: "flex",
                                  justifyContent: "center",
                                  marginBottom: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                HIV CONSENT FORM
                              </h6>
                              <Grid
                                item
                                md={12}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  marginBottom: "12px",
                                }}
                              >
                                <div
                                  className="checkList"
                                  style={{ display: "flex" }}
                                >
                                  <div style={{ fontWeight: "bolder" }}>
                                    Ref By:
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <div style={{ paddingLeft: "20px" }}>
                                      <input
                                        value={"self"}
                                        type="checkbox"
                                        checked={
                                          x?.hivform?.ref_by_doc_or_self ===
                                          "self"
                                        }
                                        onChange={(e) => {
                                          const { checked, value } = e.target;
                                          if (checked) {
                                            handleCheckBoxReferredBy(
                                              "self",
                                              index
                                            );
                                          } else {
                                            handleCheckBoxReferredBy(
                                              "doctor",
                                              index
                                            );
                                          }
                                        }}
                                        required={
                                          x?.hivform?.ref_by_doc_or_self
                                            ?.length > 0
                                            ? false
                                            : true
                                        }
                                        disabled={
                                          x?.isPickupStatusPrevious ===
                                            "cancelled" ||
                                          x?.isBookingStatusPrevious ===
                                            "cancelled" ||
                                          booking.report_status ===
                                            "consolidate"
                                        }
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        Self
                                      </span>
                                    </div>
                                    <div style={{ paddingLeft: "20px" }}>
                                      <input
                                        value={"doctor"}
                                        type="checkbox"
                                        checked={
                                          x?.hivform?.ref_by_doc_or_self ===
                                          "doctor"
                                        }
                                        onChange={(e) => {
                                          const { checked, value } = e.target;
                                          if (checked) {
                                            handleCheckBoxReferredBy(
                                              "doctor",
                                              index
                                            );
                                          } else {
                                            handleCheckBoxReferredBy(
                                              "self",
                                              index
                                            );
                                          }
                                        }}
                                        required={
                                          x?.hivform?.ref_by_doc_or_self
                                            ?.length > 0
                                            ? false
                                            : true
                                        }
                                        disabled={
                                          x?.isPickupStatusPrevious ===
                                            "cancelled" ||
                                          x?.isBookingStatusPrevious ===
                                            "cancelled" ||
                                          booking.report_status ===
                                            "consolidate"
                                        }
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        Doctor
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item md={3}>
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      color: "#131313",
                                    }}
                                  >
                                    Ref Doctor Name
                                    {x?.hivform?.ref_by_doc_or_self ===
                                      "doctor" && "*"}
                                  </p>
                                  <TextField
                                    name="hivform.ref_doctor_name"
                                    type="text"
                                    placeholder="Enter Name"
                                    value={x?.hivform?.ref_doctor_name}
                                    className="input"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    required={
                                      x?.hivform?.ref_by_doc_or_self ===
                                      "doctor"
                                    }
                                    disabled={
                                      x?.hivform?.ref_by_doc_or_self ===
                                        "self" ||
                                      x?.isPickupStatusPrevious ===
                                        "cancelled" ||
                                      x?.isBookingStatusPrevious ===
                                        "cancelled" ||
                                      booking.report_status === "consolidate"
                                    }
                                  />
                                </Grid>
                                <Grid item md={3}>
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      color: "#131313",
                                    }}
                                  >
                                    Ref Doc Mobile Number
                                    {x?.hivform?.ref_by_doc_or_self ===
                                      "doctor" && "*"}
                                  </p>
                                  <TextField
                                    className="input"
                                    name="hivform.ref_doctor_phone"
                                    type="text"
                                    value={x?.hivform?.ref_doctor_phone}
                                    variant="outlined"
                                    onWheel={(e: any) => e.target.blur()}
                                    onInput={(e: any) => {
                                      e.target.value = e.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 10);
                                    }}
                                    placeholder="Ref Mobile Number"
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    style={{ width: "100%" }}
                                    required
                                    inputProps={{
                                      pattern: "^[6-9]\\d{9}$",
                                      title:
                                        "Please enter a valid 10-digit phone number starting with a digit from 6 to 9.",
                                    }}
                                    disabled={
                                      x?.hivform?.ref_by_doc_or_self ===
                                        "self" ||
                                      x?.isPickupStatusPrevious ===
                                        "cancelled" ||
                                      x?.isBookingStatusPrevious ===
                                        "cancelled" ||
                                      booking.report_status === "consolidate"
                                    }
                                  />
                                  {x?.hivform?.ref_by_doc_or_self === "self" ||
                                  x?.isPickupStatusPrevious === "cancelled" ||
                                  x?.isBookingStatusPrevious === "cancelled" ||
                                  booking.report_status === "consolidate" ? (
                                    ""
                                  ) : (
                                    <sub
                                      style={{
                                        color: "#f44336",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {x?.hivform?.ref_doctor_phone?.length !==
                                        0 &&
                                        (parseInt(
                                          x?.hivform?.ref_doctor_phone &&
                                            x?.hivform?.ref_doctor_phone[0]
                                        ) > 5 &&
                                        x?.hivform?.ref_doctor_phone?.length ===
                                          10
                                          ? ""
                                          : "Please enter valid mobile number")}
                                    </sub>
                                  )}
                                </Grid>
                                <Grid item md={3}>
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      color: "#131313",
                                    }}
                                  >
                                    Counsellor Name
                                    {x?.hivform?.ref_by_doc_or_self ===
                                      "doctor" && "*"}
                                  </p>
                                  <TextField
                                    name="hivform.counsellor_name"
                                    type="text"
                                    placeholder="Enter Counsellor Name"
                                    value={x?.hivform?.counsellor_name}
                                    className="input"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    required={
                                      x?.hivform?.ref_by_doc_or_self ===
                                      "doctor"
                                    }
                                    disabled={
                                      x?.hivform?.ref_by_doc_or_self ===
                                        "self" ||
                                      x?.isPickupStatusPrevious ===
                                        "cancelled" ||
                                      x?.isBookingStatusPrevious ===
                                        "cancelled" ||
                                      booking.report_status === "consolidate"
                                    }
                                  />
                                </Grid>
                                <Grid item md={3} id="custom__helper">
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      color: "#131313",
                                    }}
                                  >
                                    Aadhaar/Passport Number*
                                  </p>
                                  <TextField
                                    className="input"
                                    name="hivform.adhaar_number"
                                    placeholder="Enter Aadhaar/Passport Number"
                                    value={x?.hivform?.adhaar_number}
                                    variant="outlined"
                                    type="text"
                                    onWheel={(e: any) => e.target.blur()}
                                    onInput={(e: any) => {
                                      e.target.value = e.target.value
                                        .replace(/[^a-zA-Z0-9]/g, "")
                                        .slice(0, 12);
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    required={
                                      x?.isPickupStatus !== "cancelled" &&
                                      x?.isBookingStatus !== "cancelled"
                                    }
                                    inputProps={{
                                      pattern: "^[a-zA-Z0-9]{7,12}$",
                                      title:
                                        "Please enter a valid 7 to 12 alphanumeric Aadhaar/Passport number with only alphanumeric characters.",
                                    }}
                                    disabled={
                                      x?.isPickupStatusPrevious ===
                                        "cancelled" ||
                                      x?.isBookingStatusPrevious ===
                                        "cancelled" ||
                                      booking.report_status === "consolidate"
                                    }
                                  />
                                  <sub
                                    style={{
                                      color: "#f44336",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {x?.hivform?.adhaar_number?.length === 0 ||
                                    x?.hivform?.adhaar_number?.length >= 7
                                      ? ""
                                      : "Please enter a valid Aadhaar/Passport number"}
                                  </sub>
                                </Grid>
                                <Grid item md={2}>
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      color: "#131313",
                                    }}
                                  >
                                    Counselling Date
                                    {x?.hivform?.ref_by_doc_or_self ===
                                      "doctor" && "*"}
                                  </p>
                                  <input
                                    className="input"
                                    id="noTyping"
                                    name="hivform.counselling_date"
                                    type="date"
                                    value={x?.hivform?.counselling_date}
                                    style={{
                                      width: "100%",
                                      margin: "0",
                                      padding: "18.5px 14px",
                                      height: "40px",
                                    }}
                                    min={
                                      x?.hivform?.counselling_date === null
                                        ? moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                        : x?.hivform?.counselling_date
                                    }
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    required={
                                      x?.hivform?.ref_by_doc_or_self ===
                                      "doctor"
                                    }
                                    disabled={
                                      x?.hivform?.ref_by_doc_or_self ===
                                        "self" ||
                                      x?.isPickupStatusPrevious ===
                                        "cancelled" ||
                                      x?.isBookingStatusPrevious ===
                                        "cancelled" ||
                                      booking.report_status === "consolidate"
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <sup
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#131313",
                                      letterSpacing: "0.00938em",
                                    }}
                                  >
                                    Upload Aadhaar Card
                                    {(x?.hivform?.aadhaar_url === null ||
                                      x?.hivform?.aadhaar_url === "") &&
                                      "*"}
                                  </sup>
                                  <input
                                    className="input"
                                    style={{
                                      width: "100%",
                                      marginTop: "17px",
                                      padding: "6px 14px",
                                      border: "1px solid rgba(0, 0, 0, 0.23)",
                                      borderRadius: "5px",
                                    }}
                                    accept=".jpg,.jpeg,.png"
                                    type="file"
                                    name="file"
                                    id="file"
                                    required={
                                      x?.hivform?.aadhaar_url === null ||
                                      x?.hivform?.aadhaar_url === ""
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      x?.isPickupStatusPrevious ===
                                        "cancelled" ||
                                      x?.isBookingStatusPrevious ===
                                        "cancelled" ||
                                      booking.report_status === "consolidate"
                                    }
                                    onChange={(e) =>
                                      handleFileSelected(e, index)
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={1}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      setImageAddhaarViewURL(
                                        x?.hivform?.aadhaar_url
                                      );
                                      setViewImgAadhaar(true);
                                    }}
                                    variant="contained"
                                    style={{
                                      backgroundColor:
                                        x?.hivform?.aadhaar_url === null ||
                                        x?.hivform?.aadhaar_url === ""
                                          ? "gray"
                                          : "rgb(16 125 7)",
                                    }}
                                    disabled={
                                      x?.hivform?.aadhaar_url === null ||
                                      x?.hivform?.aadhaar_url === ""
                                    }
                                  >
                                    View
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  md={6}
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    marginBottom: "12px",
                                  }}
                                >
                                  <div
                                    className="checkList"
                                    style={{ display: "flex" }}
                                  >
                                    <div style={{ fontWeight: "bolder" }}>
                                      Report Can Be Seen By*:
                                    </div>
                                    <div style={{ display: "flex" }}>
                                      <div style={{ paddingLeft: "20px" }}>
                                        <input
                                          value={"self"}
                                          type="checkbox"
                                          checked={x?.hivform?.report_can_be_seen_by?.includes(
                                            "self"
                                          )}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;
                                            if (checked) {
                                              let list = [
                                                ...x?.hivform
                                                  ?.report_can_be_seen_by,
                                                value,
                                              ];
                                              handleCheckBox(list, index);
                                            } else {
                                              let list =
                                                x?.hivform?.report_can_be_seen_by.filter(
                                                  (item: string) =>
                                                    item !== value
                                                );
                                              handleCheckBox(list, index);
                                            }
                                          }}
                                          required={
                                            x?.hivform?.report_can_be_seen_by
                                              ?.length > 0
                                              ? false
                                              : true
                                          }
                                          disabled={
                                            x?.isPickupStatusPrevious ===
                                              "cancelled" ||
                                            x?.isBookingStatusPrevious ===
                                              "cancelled" ||
                                            booking.report_status ===
                                              "consolidate"
                                          }
                                        />
                                        <span style={{ paddingLeft: "4px" }}>
                                          Self
                                        </span>
                                      </div>
                                      <div style={{ paddingLeft: "20px" }}>
                                        <input
                                          value={"refdoctor"}
                                          type="checkbox"
                                          checked={x?.hivform?.report_can_be_seen_by?.includes(
                                            "refdoctor"
                                          )}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;
                                            if (checked) {
                                              let list = [
                                                ...x?.hivform
                                                  ?.report_can_be_seen_by,
                                                value,
                                              ];
                                              handleCheckBox(list, index);
                                            } else {
                                              let list =
                                                x?.hivform?.report_can_be_seen_by.filter(
                                                  (item: string) =>
                                                    item !== value
                                                );
                                              handleCheckBox(list, index);
                                            }
                                          }}
                                          required={
                                            x?.hivform?.report_can_be_seen_by
                                              ?.length > 0
                                              ? false
                                              : true
                                          }
                                          disabled={
                                            x?.isPickupStatusPrevious ===
                                              "cancelled" ||
                                            x?.isBookingStatusPrevious ===
                                              "cancelled" ||
                                            booking.report_status ===
                                              "consolidate"
                                          }
                                        />
                                        <span style={{ paddingLeft: "4px" }}>
                                          Ref. Doctor
                                        </span>
                                      </div>
                                      <div style={{ paddingLeft: "20px" }}>
                                        <input
                                          value={"refagency"}
                                          type="checkbox"
                                          checked={x?.hivform?.report_can_be_seen_by?.includes(
                                            "refagency"
                                          )}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;
                                            if (checked) {
                                              let list = [
                                                ...x?.hivform
                                                  ?.report_can_be_seen_by,
                                                value,
                                              ];
                                              handleCheckBox(list, index);
                                            } else {
                                              let list =
                                                x?.hivform?.report_can_be_seen_by.filter(
                                                  (item: string) =>
                                                    item !== value
                                                );
                                              handleCheckBox(list, index);
                                            }
                                          }}
                                          required={
                                            x?.hivform?.report_can_be_seen_by
                                              ?.length > 0
                                              ? false
                                              : true
                                          }
                                          disabled={
                                            x?.isPickupStatusPrevious ===
                                              "cancelled" ||
                                            x?.isBookingStatusPrevious ===
                                              "cancelled" ||
                                            booking.report_status ===
                                              "consolidate"
                                          }
                                        />
                                        <span style={{ paddingLeft: "4px" }}>
                                          Ref. Agency
                                        </span>
                                      </div>
                                      <div style={{ paddingLeft: "20px" }}>
                                        <input
                                          value={"relative"}
                                          type="checkbox"
                                          checked={x?.hivform?.report_can_be_seen_by?.includes(
                                            "relative"
                                          )}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;
                                            if (checked) {
                                              let list = [
                                                ...x?.hivform
                                                  ?.report_can_be_seen_by,
                                                value,
                                              ];
                                              handleCheckBox(list, index);
                                            } else {
                                              let list =
                                                x?.hivform?.report_can_be_seen_by.filter(
                                                  (item: string) =>
                                                    item !== value
                                                );
                                              handleCheckBox(list, index);
                                            }
                                          }}
                                          required={
                                            x?.hivform?.report_can_be_seen_by
                                              ?.length > 0
                                              ? false
                                              : true
                                          }
                                          disabled={
                                            x?.isPickupStatusPrevious ===
                                              "cancelled" ||
                                            x?.isBookingStatusPrevious ===
                                              "cancelled" ||
                                            booking.report_status ===
                                              "consolidate"
                                          }
                                        />
                                        <span style={{ paddingLeft: "4px" }}>
                                          Relative
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </>
                  );
                }
              })}

            {inputList.map((x: any, index: number) => {
              if (x?.isSelected === true) {
                const currAllergyQn = x?.allergyForm;
                const currClinicalQn = x.clinicalForm;
                let showFemale;

                if (
                  x.designation === "Ms" ||
                  x.customerGender === "female" ||
                  x.customerGender === "Female"
                )
                  showFemale = true;
                if (
                  x.designation === "Mr" ||
                  x.customerGender === "male" ||
                  x.customerGender === "Male" ||
                  x.customerGender === "other"
                )
                  showFemale = false;
                const idExists: boolean =
                  x?.customerPackage?.length > 0 &&
                  x?.customerPackage?.some(
                    (item: { test: { id: number; name: string }[] }) =>
                      item?.test?.length > 0 &&
                      item?.test?.some(
                        (testItem: { id: number; name: string }) =>
                          testItem?.id ===
                            Number(
                              process.env.REACT_APP_GLUCOSE_FASTING_TEST_ID
                            ) ||
                          testItem?.id ===
                            Number(process.env.REACT_APP_GLUCOSE_RANDOM_TEST_ID)
                      )
                  );

                return (
                  <>
                    <Grid item md={1}>
                      {
                        // inputList?.filter((x: any) => x?.isSelected === true).length === 1 && (
                        <HighlightOffIcon
                          style={{
                            marginBottom: "1rem",
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                          }}
                          onClick={() => handleRemoveClick(x, index)}
                        />
                        // )
                      }
                    </Grid>
                    <p>
                      {x.customerId !== ""
                        ? `UHID : ${
                            x.oldNameUHID === x.customerName ? x.customerId : ""
                          }`
                        : ""}
                    </p>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      style={{
                        margin: "1rem auto",
                        paddingBottom: "10px",
                        background: "#e1f5fe",
                      }}
                    >
                      <Grid item md={2}>
                        <p>Title*</p>
                        <Select
                          className="input"
                          name="designation"
                          variant="outlined"
                          value={x.designation}
                          style={{ width: "100%", margin: "0", height: "40px" }}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                          displayEmpty={true}
                          renderValue={
                            x.designation !== ""
                              ? undefined
                              : () => "Select Title"
                          }
                        >
                          <MenuItem value="Mr">Mr</MenuItem>
                          <MenuItem value="Ms">Ms (Mrs/Miss)</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={2}>
                        <p>Customer Name*</p>
                        <TextField
                          className="input"
                          name="customerName"
                          type="text"
                          placeholder="Enter Name"
                          value={x.customerName || ""}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%", margin: "0" }}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </Grid>
                      <Grid item md={2}>
                        <p>DOB</p>
                        <input
                          className="input"
                          name="dateOfBirth"
                          type="date"
                          placeholder=""
                          value={x.dateOfBirth}
                          onChange={(e) => handleInputChange(e, index)}
                          min={`${new Date().getFullYear() - 150}-${
                            new Date().getMonth() + 1 < 10
                              ? `0${new Date().getMonth() + 1}`
                              : new Date().getMonth() + 1
                          }-${
                            new Date().getDate() < 10
                              ? `0${new Date().getDate()}`
                              : new Date().getDate()
                          }`}
                          max={`${new Date().getFullYear()}-${
                            new Date().getMonth() + 1 < 10
                              ? `0${new Date().getMonth() + 1}`
                              : new Date().getMonth() + 1
                          }-${
                            new Date().getDate() < 10
                              ? `0${new Date().getDate()}`
                              : new Date().getDate()
                          }`}
                          style={{
                            width: "100%",
                            padding: "15px 14px",
                            background: "#eff",
                            height: "40px",
                          }}
                        />
                      </Grid>
                      <Grid item md={1}>
                        <p>Age*</p>
                        <TextField
                          className="input"
                          name="customerAge"
                          type="number"
                          placeholder="Enter Age"
                          value={x.customerAge}
                          disabled={x.dateOfBirth !== ""}
                          variant="outlined"
                          style={{ width: "100%", margin: "0" }}
                          required={true}
                          inputProps={{ min: 0, max: 100 }}
                          onChange={(e) => {
                            let ageValue = parseInt(e.target.value);
                            if (ageValue > 120) ageValue = 120;
                            if (ageValue < 0) ageValue = 0;
                            e.target.value = ageValue.toString();
                            handleInputChange(e, index);
                          }}
                        />
                        {(Number(x.customerAge) < 5 ||
                          x.customerAge?.length === 0) && (
                          <sub style={{ color: "red" }}>
                            Age cannot be less than 5 years.
                          </sub>
                        )}
                      </Grid>
                      <Grid item md={2}>
                        <p>Gender*</p>
                        <TextField
                          className="input"
                          name="customerGender"
                          variant="outlined"
                          placeholder="Select Gender"
                          value={
                            x.customerGender === "male" ||
                            x.customerGender === "Male"
                              ? "Male"
                              : x.customerGender === "female" ||
                                x.customerGender === "Female"
                              ? "Female"
                              : x.customerGender === "other" ||
                                x.customerGender === "Other"
                              ? "Male"
                              : ""
                          }
                          style={{ width: "100%", margin: "0", height: "40px" }}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled={true}
                          required={true}
                        />
                        <p style={{ color: "red" }}>
                          {inputList[0]?.customerGender === null
                            ? "Please select gender"
                            : ""}
                        </p>
                      </Grid>
                      <Grid item md={3}>
                        <p>Package Name*</p>
                        <Autocomplete
                          id="customerPackage"
                          key={packageKey}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              let packs: any = [];

                              newValue.map((val: any) => {
                                let obj = JSON.parse(
                                  JSON.stringify(val, null, " ")
                                );
                                packs.push(obj);
                              });
                              let len = packs.length;
                              let arr = removeAddnTest(packs);
                              handlePackage(index, arr, -1);
                              fetchAllergyQn(
                                packs.length > 0 &&
                                  packs.map((val: any) => val.id),
                                index + 1
                              );
                              fetchClinicalQn(
                                packs.length > 0 &&
                                  packs.map((val: any) => val.id),
                                index + 1
                              );
                            }
                            setRedCashButton(false);
                            setCValue("");
                            setDiscount(0);
                            setCoupon(0);
                            setCouponCode("");
                            setCType("");
                            setReferralPointsDiscount(0);
                            setReferralCouponDiscount(0);
                            setReferralPointsApplied(false);
                          }}
                          multiple
                          disableClearable
                          options={packageList?.results || []}
                          limitTags={0}
                          freeSolo
                          disableCloseOnSelect
                          filterOptions={(options, state) => options}
                          aria-required
                          onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                              getPackage(
                                `city_id=${cityId}&search=${newInputValue}&org_type=homedx${
                                  x?.customerGender
                                    ? `&gender=${x?.customerGender}`
                                    : ""
                                }`
                              );
                            }, 1000);
                          }}
                          value={x?.customerPackage}
                          getOptionLabel={(option: any) => {
                            return option?.name && option?.package_city_prices
                              ? option?.name.toString() +
                                  ", " +
                                  option?.package_city_prices?.tat_time +
                                  ", Rs. " +
                                  option?.package_city_prices?.offer_price +
                                  "/-"
                              : option?.name +
                                  ", " +
                                  option?.tat_time +
                                  ", Rs. " +
                                  option?.offer_price +
                                  "/-";
                          }}
                          getOptionDisabled={(option: any) => {
                            const packID = x?.customerPackage.map(
                              (pack: any) => pack?.id
                            );
                            return packID?.includes(option?.id);
                          }}
                          renderOption={(option, { selected }) => (
                            <>
                              <Checkbox
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={
                                  x?.customerPackage?.length > 0 &&
                                  x?.customerPackage.filter(
                                    (pack: any) => pack?.id === option?.id
                                  ).length === 1
                                    ? true
                                    : false
                                }
                              />
                              <div style={{ display: "flex" }}>
                                {option?.package_city_prices ? (
                                  option?.highlight?.name &&
                                  option?.highlight?.name.length > 0 ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `${String(
                                          option?.highlight?.name
                                        )}, ${
                                          option?.package_city_prices?.tat_time
                                        }, Rs. ${
                                          option?.package_city_prices
                                            ?.offer_price
                                        }/- <br /> ${
                                          option?.package_or_test === "test"
                                            ? option?.also_known_as
                                              ? `Also known as: ${option?.also_known_as}`
                                              : option?.alias?.length > 0
                                              ? `Also known as: ${option?.alias[0].name}`
                                              : ""
                                            : option?.highlight?.["test.name"]
                                            ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                            : ""
                                        }`,
                                      }}
                                    ></div>
                                  ) : (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `${option?.name.toString()}, ${
                                          option?.package_city_prices?.tat_time
                                        }, Rs. ${
                                          option?.package_city_prices
                                            ?.offer_price
                                        } <br /> ${
                                          option?.package_or_test === "test"
                                            ? option?.also_known_as
                                              ? `Also known as: ${option?.also_known_as}`
                                              : option?.alias?.length > 0
                                              ? `Also known as: ${option?.alias[0].name}`
                                              : ""
                                            : option?.highlight?.["test.name"]
                                            ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                            : ""
                                        }`,
                                      }}
                                    />
                                  )
                                ) : option?.highlight?.name &&
                                  option?.highlight?.name.length > 0 ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: `${String(
                                        option?.highlight?.name
                                      )}, ${
                                        option?.tat_time
                                          ? `${option?.tat_time},`
                                          : ""
                                      } ${
                                        "Rs. " + option?.offer_price + "/-" ||
                                        ""
                                      } <br /> ${
                                        option?.package_or_test === "test"
                                          ? option?.also_known_as
                                            ? `Also known as: ${option?.also_known_as}`
                                            : option?.alias?.length > 0
                                            ? `Also known as: ${option?.alias[0].name}`
                                            : ""
                                          : option?.highlight?.["test.name"]
                                          ? `Includes: ${option?.highlight?.["test.name"][0]}`
                                          : ""
                                      }`,
                                    }}
                                  ></div>
                                ) : (
                                  option?.name.toString() +
                                  ", " +
                                  option?.tat_time +
                                  ", Rs." +
                                  option?.offer_price +
                                  "/-"
                                )}
                              </div>
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              className="input"
                              {...params}
                              placeholder="Package"
                              variant="outlined"
                              required={
                                x?.customerPackage?.length === 0 ? true : false
                              }
                              style={{
                                width: "100%",
                                margin: "0",
                                padding: "0",
                              }}
                              inputProps={{
                                ...params.inputProps,
                                onKeyDown: (e) => {
                                  if (e.key === "Enter") {
                                    e.stopPropagation();
                                  }
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <p>Customer Email</p>
                        <TextField
                          className="input"
                          name="customer_email"
                          type="email"
                          placeholder="Enter customer Email id"
                          value={x.customer_email}
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                          disabled={deniedEmailCheck}
                          required={!deniedEmailCheck}
                          // onBlur={() => verifyEmail()}
                          onFocus={() => {
                            setEmailValidation(true);
                          }}
                          // onChange={(e) =>
                          //   setCustomer_email(e.target.value as String)
                          // }
                          onChange={(e) => handleInputChange(e, index)}
                        />
                        <sub style={{ color: "red", fontSize: "16px" }}>
                          {!emailValidation
                            ? "Please enter valid e-mail id"
                            : ""}
                        </sub>
                      </Grid>
                      <Grid item md={2}>
                        <p>Patient Phone no *</p>
                        <TextField
                          className="input"
                          name="patient_phone_no"
                          type="text"
                          placeholder="Enter Patient Phone no"
                          value={x?.patient_phone_no || ""}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // required={
                          //   x?.unique_customer_number === false
                          //   || x?.unique_customer_number === null
                          //   || booking?.booking_status !== "cancelled"
                          //   || booking?.pickup_status !== "cancelled"
                          //   || x?.isPickupStatusPrevious !== "cancelled"
                          //   || x?.isBookingStatusPrevious !== "cancelled"
                          // }

                          style={{ width: "100%", margin: "0" }}
                          onInput={(e: any) => {
                            e.target.value = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 10);
                          }}
                          inputProps={
                            x?.unique_customer_number !== false
                              ? undefined
                              : {
                                  pattern: "^[6-9]\\d{9}$",
                                  title:
                                    "Please enter a valid 10-digit phone number starting with a digit from 6 to 9.",
                                }
                          }
                          required
                          disabled={
                            x?.isPickupStatusPrevious === "cancelled" ||
                            x?.isBookingStatusPrevious === "cancelled" ||
                            booking.report_status === "consolidate" ||
                            x?.unique_customer_number === true ||
                            booking?.booking_status === "cancelled" ||
                            booking?.pickup_status === "cancelled"
                          }
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <p>Passport Number</p>
                        <TextField
                          className="input"
                          name="passport"
                          type="text"
                          placeholder="Enter Passport Number"
                          value={x.passport || ""}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%", margin: "0" }}
                          onInput={(e: any) => {
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 10);
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <p>Aadhaar Number</p>
                        <TextField
                          className="input"
                          name="aadhar"
                          type="number"
                          onWheel={(e: any) => e.target.blur()}
                          onInput={(e: any) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 12);
                          }}
                          placeholder="Enter Aadhaar Number"
                          value={x.aadhar || ""}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%", margin: "0" }}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <p>Booking For*</p>
                        <Select
                          className="input"
                          name="bookingFor"
                          variant="outlined"
                          value={x.bookingFor}
                          style={{ width: "100%", margin: "0", height: "40px" }}
                          required
                          displayEmpty={true}
                          renderValue={
                            x.bookingFor !== ""
                              ? undefined
                              : () => "Select Booking For"
                          }
                          onChange={(e) => handleInputChange(e, index)}
                        >
                          <MenuItem value="self" disabled>
                            Self
                          </MenuItem>
                          <MenuItem value="father" disabled={showFemale}>
                            Father
                          </MenuItem>
                          <MenuItem
                            value="mother"
                            disabled={showFemale === false}
                          >
                            Mother
                          </MenuItem>
                          <MenuItem value="son" disabled={showFemale}>
                            Son
                          </MenuItem>
                          <MenuItem
                            value="daughter"
                            disabled={showFemale === false}
                          >
                            Daughter
                          </MenuItem>
                          <MenuItem value="spouse">Spouse</MenuItem>
                          <MenuItem value="grandparents">Grandparents</MenuItem>
                          <MenuItem value="siblings">Siblings</MenuItem>
                          <MenuItem value="in-laws">In-laws</MenuItem>
                          <MenuItem value="other">Others</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={2}>
                        <p>Is Prescribed</p>
                        <Select
                          className="input"
                          name="is_prescribed"
                          variant="outlined"
                          value={x.is_prescribed}
                          displayEmpty={true}
                          style={{ width: "100%", margin: "0", height: "40px" }}
                          onChange={(e) => handleInputChange(e, index)}
                          renderValue={
                            x.is_prescribed !== ""
                              ? undefined
                              : () => "Select Prescribed"
                          }
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      </Grid>
                      {/* <Grid item md={2}>
                          <p >Requested For Hard Copy</p>
                          <Select
                            className="input"
                            name="requested_for_hard_copy"
                            variant="outlined"
                            value={requestedForHardCopy}
                            style={{ width: "100%", margin: "0", height: "40px" }}
                            onChange={(e) => setRequestedForhHardCopy(e.target.value)}
                          >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                          </Select>
                        </Grid> */}
                      <Grid item md={2}>
                        <p style={{ fontWeight: "bolder" }}>Free Test:</p>
                        <Button
                          variant="contained"
                          className="primary"
                          disabled={
                            totalPriceForBooking <= 100 ||
                            x?.freeGlucoseFastingTestDisabled ||
                            x?.customerPackage?.length === 0 ||
                            idExists
                          }
                          style={{
                            width: "100%",
                            margin: "0",
                            height: "40px",
                            fontSize: "12px",
                          }}
                          fullWidth
                          onClick={() => {
                            handleAddFreeGlucoseFastingTest(x, index);
                          }}
                        >
                          Free Glucose Test
                        </Button>
                      </Grid>
                      <Grid item md={2}>
                        <p style={{ fontWeight: "bolder" }}>Free HbA1c:</p>
                        <Button
                          variant="contained"
                          className="primary"
                          disabled={
                            totalPriceForBooking < 1299 ||
                            booking.additional_members.length <= 0
                          }
                          style={{
                            width: "100%",
                            margin: "0",
                            height: "40px",
                            fontSize: "12px",
                            backgroundColor: "#827717",
                          }}
                          fullWidth
                          onClick={() => {
                            handleAddFreeHbA1cTest(x, index);
                          }}
                        >
                          Free HbA1c test
                        </Button>
                      </Grid>
                      <Grid
                        item
                        md={2}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ fontWeight: "bolder", margin: "0" }}>
                          Addon Tests:
                        </p>
                        <Button
                          variant="contained"
                          className="input"
                          fullWidth
                          disabled={
                            x?.customerPackage?.length === 0 ||
                            (x?.customerPackage &&
                              x?.customerPackage?.length > 0 &&
                              !x?.customerPackage?.some(
                                (item: any) =>
                                  item?.package_or_test === "package"
                              ))
                          }
                          onClick={() => {
                            handleAddonTest(x?.customerPackage, index);
                          }}
                        >
                          Addon Tests
                        </Button>
                      </Grid>
                      {x?.customerId === "" && (
                        <Grid
                          item
                          md={1}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            disabled={
                              x?.customerAge?.length === 0 ||
                              x?.designation?.length === 0 ||
                              x?.customerName?.length === 0 ||
                              x?.bookingFor?.length === 0 ||
                              Number(x?.customerAge) < 5
                            }
                            style={{
                              width: "100%",
                              margin: "0",
                              height: "40px",
                              fontSize: "12px",
                              backgroundColor:
                                x?.customerAge?.length > 0 &&
                                x?.designation?.length > 0 &&
                                x?.customerName?.length > 0 &&
                                x?.bookingFor?.length > 0 &&
                                Number(x?.customerAge) >= 5
                                  ? "green"
                                  : "gray",
                            }}
                            fullWidth
                            onClick={() => {
                              const body = {
                                lead_id: booking?.lead,
                                first_name: x?.customerName,
                                customer_gender: x?.customerGender,
                                customer_age: x?.customerAge,
                                dob: x.dateOfBirth,
                                relation: x?.bookingFor,
                                designation: x?.designation,
                              };
                              if (x.dateOfBirth === "") {
                                delete body.dob;
                              }
                              addUniqueCustomer(body);
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      )}
                      {/* <Grid item md={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                          <Autocomplete
                            id="customerAddOnPackage"
                            className="custom-autocomplete"
                            key={packageKey}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                let packs: any = [];
                                newValue.map((val: any) => {
                                  let obj = JSON.parse(
                                    JSON.stringify(val, null, " ")
                                  );
                                  let arr = x?.customerPackage?.filter((item: any) => item?.id === obj?.id);
                                  if (arr?.length === 0) {
                                    handlePackage(index, [...x?.customerPackage, obj], -1);
                                  }
                                });
                              }
                              setCValue("")
                              setDiscount(0);
                              setCoupon(0);
                              setCouponCode("");
                            }}
                            multiple
                            options={addOnPackagesList?.results || []}
                            limitTags={0}
                            freeSolo
                            filterOptions={(options, state) => options}
                            aria-required
                            onInputChange={(event, newInputValue) => {
                              clearTimeout(timer.current);
                              timer.current = setTimeout(() => {
                                getAddOnPackage(
                                  `city_id=${cityId}&search=${newInputValue}&org_type=homedx&is_addon=true`
                                );
                              }, 1000);
                            }}
                            disabled={x?.customerPackage?.length === 0}
                            value={x?.customerAddOnPackage}
                            getOptionLabel={(option: any) => {
                              return option?.name +
                                ", " +
                                (option?.package_city_prices ? option?.package_city_prices?.tat_time : option?.tat_time) +
                                ", Rs. " +
                                option?.addon_price +
                                "/-"
                            }}
                            getOptionDisabled={(option: any) => {
                              const packID = x?.customerAddOnPackage.map(
                                (pack: any) => pack?.id
                              );
                              return packID?.includes(option?.id);
                            }}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                                  style={{ marginRight: 8 }}
                                  checked={x?.customerAddOnPackage?.length > 0 && x?.customerAddOnPackage.filter((pack: any) => pack?.id === option?.id).length === 1 ? true : false}
                                />
                                <div style={{ display: 'flex' }}>
                                  {option?.package_city_prices
                                    ? ((option?.highlight?.name && option?.highlight?.name.length > 0) ? <div dangerouslySetInnerHTML={{ __html: `${String(option?.highlight?.name)}, ${option?.package_city_prices?.tat_time}, Rs. ${option?.addon_price}/- <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }}></div> : <div dangerouslySetInnerHTML={{ __html: `${option?.name.toString()}, ${option?.package_city_prices?.tat_time}, Rs. ${option?.addon_price} <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }} />) : (option?.highlight?.name && option?.highlight?.name.length > 0) ? <div dangerouslySetInnerHTML={{ __html: `${String(option?.highlight?.name)}, ${option?.tat_time ? `${option?.tat_time},` : ""} ${"Rs. " + option?.addon_price + "/-" || ""} <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }}></div> : option?.name.toString() +
                                      ", " +
                                      option?.tat_time +
                                      ", Rs." +
                                      option?.addon_price +
                                      "/-"}
                                </div>
                              </React.Fragment>
                            )}
                            disableCloseOnSelect
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                className="input"
                                {...params}
                                placeholder="Add On Package List"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                                inputProps={{
                                  ...params.inputProps,
                                  onKeyDown: (e) => {
                                    if (e.key === "Enter") {
                                      e.stopPropagation();
                                    }
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid> */}
                      {currAllergyQn && currAllergyQn.length > 0 ? (
                        <Grid
                          item
                          md={12}
                          style={{
                            backgroundColor: "#edd6d6",
                            marginTop: "10px",
                            border: "1px dashed",
                            borderRadius: "2px",
                            margin: "10px",
                          }}
                        >
                          <h6 id="form-dialog-title">
                            Question For Allergy packages
                          </h6>

                          <Grid
                            container
                            spacing={2}
                            style={{
                              margin: "0px 10px 10px 10px",
                              minWidth: "500px",
                              width: "70%",
                            }}
                          >
                            {currAllergyQn.map((data: any, index2: any) => {
                              return (
                                <>
                                  <Grid item xs={12} md={12}>
                                    <p>
                                      {index2 + 1}:{data.question_text} (If yes,
                                      please specify)*
                                    </p>
                                    {data.question_type === "radio" ? (
                                      <>
                                        <RadioGroup
                                          aria-labelledby="demo-controlled-radio-buttons-group"
                                          name="controlled-radio-buttons-group"
                                          value={data?.checked}
                                          onChange={(e) =>
                                            allergyAnsHandler(
                                              e.target.value,
                                              index2,
                                              index + 1,
                                              "checked"
                                            )
                                          }
                                          row
                                        >
                                          <FormControlLabel
                                            value="yes"
                                            control={<Radio required />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value="no"
                                            control={<Radio required />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {(data?.checked === "yes" &&
                                      data.question_type === "radio") ||
                                    data.question_type !== "radio" ? (
                                      <TextField
                                        className="input"
                                        name="allergy_question"
                                        type={
                                          data.question_type === "number"
                                            ? "number"
                                            : "text"
                                        }
                                        value={data?.answer}
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0" }}
                                        required
                                        inputProps={{ maxLength: 100 }}
                                        onChange={(e) => {
                                          allergyAnsHandler(
                                            data.question_type !== "number"
                                              ? e.target.value
                                              : data.question_type ===
                                                  "number" &&
                                                Number(e.target.value) > 0 &&
                                                Number(e.target.value) < 101
                                              ? e.target.value
                                              : "",
                                            index2,
                                            index + 1,
                                            "answer"
                                          );
                                        }}
                                        placeholder="Type Here..."
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </>
                              );
                            })}
                            <p style={{ fontSize: "17px", marginTop: "10px" }}>
                              * This information will be sent to the lab as Well
                              as Customer
                            </p>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {currClinicalQn && currClinicalQn.length > 0 && (
                        <Grid
                          item
                          md={12}
                          style={{
                            backgroundColor: "#edd6d6",
                            marginTop: "10px",
                            border: "1px dashed",
                            borderRadius: "2px",
                            margin: "10px",
                          }}
                        >
                          <h6
                            style={{
                              fontWeight: "bolder",
                              marginBottom: "10px",
                              marginTop: "10px",
                              marginLeft: "43%",
                            }}
                          >
                            {" "}
                            CLINICAL HISTORY FORM
                          </h6>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              margin: "0px 10px 10px 10px",
                              minWidth: "500px",
                              width: "70%",
                            }}
                          >
                            {currClinicalQn.map((data: any, index2: any) => {
                              return (
                                <>
                                  <Grid item md={6}>
                                    <p
                                      style={{
                                        fontWeight: "600",
                                        color: "#131313",
                                      }}
                                    >
                                      Q{index2 + 1}:{data.question_text}
                                      {data.is_required === true && (
                                        <span style={{ color: "red" }}>*</span>
                                      )}
                                    </p>
                                    <TextField
                                      name="ClinicalAnswer"
                                      type="text"
                                      placeholder="Enter Write Your Answer"
                                      value={data?.answer}
                                      className="input"
                                      variant="outlined"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      style={{ width: "100%", margin: "0" }}
                                      onChange={(e) => {
                                        ClinicalAnsHandler(
                                          e.target.value,
                                          index2,
                                          index + 1,
                                          "answer"
                                        );
                                      }}
                                      required
                                    />
                                  </Grid>
                                  <Grid item md={6}>
                                    <sup
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bolder",
                                        color: "#131313",
                                        letterSpacing: "0.00938em",
                                      }}
                                    >
                                      Upload USG/Doctor’s Prescription
                                    </sup>
                                    <input
                                      className="input"
                                      style={{
                                        width: "100%",
                                        marginBottom: "10px",
                                        padding: "14px 16px",
                                        border: "5px solid rgba(0, 0, 0, 0.23)",
                                        borderRadius: "5px",
                                      }}
                                      accept=".pdf,.doc,.jpg,.jpeg,.png"
                                      type="file"
                                      name="file"
                                      id="file"
                                      multiple
                                      onChange={async (e) => {
                                        let result =
                                          await handleClinicalPrescription(e);
                                        ClinicalAnsHandler(
                                          result.base64,
                                          index2,
                                          index,
                                          "doctor_prescription"
                                        );
                                      }}
                                    />
                                  </Grid>
                                </>
                              );
                            })}
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "17px",
                                marginTop: "10px",
                              }}
                            >
                              * This information will be sent to the lab as Well
                              as Customer
                            </p>
                          </Grid>
                        </Grid>
                      )}
                      {x?.previousBookedPackages &&
                        x?.previousBookedPackages?.length > 0 && (
                          <Grid container md={12}>
                            {x.previousBookedPackages?.map(
                              (item: any, ind: any) => {
                                return (
                                  <div
                                    style={{
                                      paddingLeft: "12px",
                                      marginTop: "12px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {ind === 0 && (
                                      <p
                                        style={{
                                          margin: "0",
                                          paddingBottom: "8px",
                                        }}
                                      >
                                        Previously booked tests/packages -
                                      </p>
                                    )}
                                    <Button
                                      variant="contained"
                                      className="input"
                                      style={{
                                        borderRadius: "50px",
                                        backgroundColor:
                                          inputList[
                                            index
                                          ]?.customerPackage?.filter(
                                            (pack: any) =>
                                              pack?.code === item?.code
                                          )?.length === 0
                                            ? "#fef6f1"
                                            : "#b0aaaa87",
                                        color: "#f3a66f",
                                      }}
                                      fullWidth
                                      onClick={() => {
                                        const checkPackage = inputList[
                                          index
                                        ]?.customerPackage?.filter(
                                          (pack: any) =>
                                            pack?.code === item?.code
                                        );
                                        if (checkPackage?.length === 0)
                                          handlePackage(
                                            index,
                                            [
                                              ...inputList[index]
                                                ?.customerPackage,
                                              item,
                                            ],
                                            -1
                                          );
                                        else {
                                          const filterPackage = inputList[
                                            index
                                          ]?.customerPackage?.filter(
                                            (pack: any) =>
                                              pack?.code !== item?.code
                                          );
                                          handlePackage(
                                            index,
                                            [...filterPackage],
                                            -1
                                          );
                                        }
                                        let arr =
                                          inputList[index]?.customerPackage
                                            ?.length > 0
                                            ? inputList[
                                                index
                                              ]?.customerPackage.map(
                                                (val: any) => val.id
                                              )
                                            : [];
                                        fetchAllergyQn(
                                          [...arr, item.id],
                                          index + 1
                                        );
                                        fetchClinicalQn(
                                          [...arr, item.id],
                                          index + 1
                                        );
                                      }}
                                    >
                                      {inputList[
                                        index
                                      ]?.customerPackage?.filter(
                                        (pack: any) => pack?.code === item?.code
                                      )?.length === 0 ? (
                                        <AddCircleRoundedIcon />
                                      ) : (
                                        <CheckCircleRoundedIcon />
                                      )}{" "}
                                      {item?.name}
                                    </Button>
                                  </div>
                                );
                              }
                            )}
                          </Grid>
                        )}
                      {+inputList[0]?.customerAge < 5 &&
                        inputList[0]?.customerAge !== "" && (
                          <p
                            style={{
                              textAlign: "center",
                              width: "100%",
                              color: "red",
                            }}
                          >
                            Age cannot be less than 5 years.
                          </p>
                        )}
                      {x?.customerPackage &&
                        x?.customerPackage?.length > 0 &&
                        x?.customerPackage?.filter(
                          (item: any) => item?.is_hiv_form === true
                        )?.length > 0 && (
                          <Grid
                            container
                            md={12}
                            style={{
                              paddingLeft: "10px",
                              backgroundColor: "#bdd8ef94",
                            }}
                          >
                            <h6
                              style={{
                                fontWeight: 600,
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "10px",
                                marginTop: "10px",
                              }}
                            >
                              HIV CONSENT FORM
                            </h6>
                            <Grid
                              item
                              md={12}
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginBottom: "12px",
                              }}
                            >
                              <div
                                className="checkList"
                                style={{ display: "flex" }}
                              >
                                <div style={{ fontWeight: "bolder" }}>
                                  Ref By:
                                </div>
                                <div style={{ display: "flex" }}>
                                  <div style={{ paddingLeft: "20px" }}>
                                    <input
                                      value={"self"}
                                      type="checkbox"
                                      checked={
                                        x?.hivform?.ref_by_doc_or_self ===
                                        "self"
                                      }
                                      onChange={(e) => {
                                        const { checked, value } = e.target;
                                        if (checked) {
                                          handleCheckBoxReferredBy(
                                            "self",
                                            index
                                          );
                                        } else {
                                          handleCheckBoxReferredBy(
                                            "doctor",
                                            index
                                          );
                                        }
                                      }}
                                      required={
                                        x?.hivform?.ref_by_doc_or_self?.length >
                                        0
                                          ? false
                                          : true
                                      }
                                    />
                                    <span style={{ paddingLeft: "4px" }}>
                                      Self
                                    </span>
                                  </div>
                                  <div style={{ paddingLeft: "20px" }}>
                                    <input
                                      value={"doctor"}
                                      type="checkbox"
                                      checked={
                                        x?.hivform?.ref_by_doc_or_self ===
                                        "doctor"
                                      }
                                      onChange={(e) => {
                                        const { checked, value } = e.target;
                                        if (checked) {
                                          handleCheckBoxReferredBy(
                                            "doctor",
                                            index
                                          );
                                        } else {
                                          handleCheckBoxReferredBy(
                                            "self",
                                            index
                                          );
                                        }
                                      }}
                                      required={
                                        x?.hivform?.ref_by_doc_or_self?.length >
                                        0
                                          ? false
                                          : true
                                      }
                                    />
                                    <span style={{ paddingLeft: "4px" }}>
                                      Doctor
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md={3}>
                                <p
                                  style={{
                                    fontWeight: "600",
                                    color: "#131313",
                                  }}
                                >
                                  Ref Doctor Name
                                  {x?.hivform?.ref_by_doc_or_self ===
                                    "doctor" && "*"}
                                </p>
                                <TextField
                                  name="hivform.ref_doctor_name"
                                  type="text"
                                  placeholder="Enter Name"
                                  value={x?.hivform?.ref_doctor_name}
                                  className="input"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{ width: "100%", margin: "0" }}
                                  onChange={(e) => handleInputChange(e, index)}
                                  required={
                                    x?.hivform?.ref_by_doc_or_self === "doctor"
                                  }
                                  disabled={
                                    x?.hivform?.ref_by_doc_or_self === "self"
                                  }
                                />
                              </Grid>
                              <Grid item md={3}>
                                <p
                                  style={{
                                    fontWeight: "600",
                                    color: "#131313",
                                  }}
                                >
                                  Ref Doc Mobile Number
                                  {x?.hivform?.ref_by_doc_or_self ===
                                    "doctor" && "*"}
                                </p>
                                <TextField
                                  className="input"
                                  name="hivform.ref_doctor_phone"
                                  type="text"
                                  value={x?.hivform?.ref_doctor_phone}
                                  variant="outlined"
                                  onWheel={(e: any) => e.target.blur()}
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 10);
                                  }}
                                  placeholder="Ref Mobile Number"
                                  onChange={(e) => handleInputChange(e, index)}
                                  style={{ width: "100%" }}
                                  required
                                  inputProps={{
                                    pattern: "^[6-9]\\d{9}$",
                                    title:
                                      "Please enter a valid 10-digit phone number starting with a digit from 6 to 9.",
                                  }}
                                  disabled={
                                    x?.hivform?.ref_by_doc_or_self === "self"
                                  }
                                />
                                {x?.hivform?.ref_by_doc_or_self !== "self" && (
                                  <sub
                                    style={{
                                      color: "#f44336",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {x?.hivform?.ref_doctor_phone?.length !==
                                      0 &&
                                      (parseInt(
                                        x?.hivform?.ref_doctor_phone &&
                                          x?.hivform?.ref_doctor_phone[0]
                                      ) > 5 &&
                                      x?.hivform?.ref_doctor_phone?.length ===
                                        10
                                        ? ""
                                        : "Please enter valid mobile number")}
                                  </sub>
                                )}
                              </Grid>
                              <Grid item md={3}>
                                <p
                                  style={{
                                    fontWeight: "600",
                                    color: "#131313",
                                  }}
                                >
                                  Counsellor Name
                                  {x?.hivform?.ref_by_doc_or_self ===
                                    "doctor" && "*"}
                                </p>
                                <TextField
                                  name="hivform.counsellor_name"
                                  type="text"
                                  placeholder="Enter Counsellor Name"
                                  value={x?.hivform?.counsellor_name}
                                  className="input"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{ width: "100%", margin: "0" }}
                                  onChange={(e) => handleInputChange(e, index)}
                                  required={
                                    x?.hivform?.ref_by_doc_or_self === "doctor"
                                  }
                                  disabled={
                                    x?.hivform?.ref_by_doc_or_self === "self"
                                  }
                                />
                              </Grid>
                              <Grid item md={3} id="custom__helper">
                                <p
                                  style={{
                                    fontWeight: "600",
                                    color: "#131313",
                                  }}
                                >
                                  Aadhaar/Passport Number*
                                </p>
                                <TextField
                                  className="input"
                                  name="hivform.adhaar_number"
                                  placeholder="Enter Aadhaar/Passport Number"
                                  value={x?.hivform?.adhaar_number}
                                  variant="outlined"
                                  type="text"
                                  onWheel={(e: any) => e.target.blur()}
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value
                                      .replace(/[^a-zA-Z0-9]/g, "")
                                      .slice(0, 12);
                                  }}
                                  style={{ width: "100%", margin: "0" }}
                                  onChange={(e) => handleInputChange(e, index)}
                                  required
                                  inputProps={{
                                    pattern: "^[a-zA-Z0-9]{7,12}$",
                                    title:
                                      "Please enter a valid 7 to 12 alphanumeric Aadhaar/Passport number with only alphanumeric characters.",
                                  }}
                                />
                                <sub
                                  style={{ color: "#f44336", fontSize: "12px" }}
                                >
                                  {x?.hivform?.adhaar_number?.length === 0 ||
                                  x?.hivform?.adhaar_number?.length >= 7
                                    ? ""
                                    : "Please enter a valid Aadhaar/Passport number"}
                                </sub>
                              </Grid>
                              <Grid item md={3}>
                                <p
                                  style={{
                                    fontWeight: "600",
                                    color: "#131313",
                                  }}
                                >
                                  Counselling Date
                                  {x?.hivform?.ref_by_doc_or_self ===
                                    "doctor" && "*"}
                                </p>
                                <input
                                  className="input"
                                  id="noTyping"
                                  name="hivform.counselling_date"
                                  type="date"
                                  value={x?.hivform?.counselling_date}
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                    padding: "18.5px 14px",
                                    height: "40px",
                                  }}
                                  min={moment(new Date()).format("YYYY-MM-DD")}
                                  onChange={(e) => handleInputChange(e, index)}
                                  required={
                                    x?.hivform?.ref_by_doc_or_self === "doctor"
                                  }
                                  disabled={
                                    x?.hivform?.ref_by_doc_or_self === "self"
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                md={3}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <sup
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#131313",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Upload Aadhaar Card*
                                </sup>
                                <input
                                  className="input"
                                  style={{
                                    width: "100%",
                                    marginTop: "17px",
                                    padding: "6px 14px",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderRadius: "5px",
                                  }}
                                  accept=".jpg,.jpeg,.png"
                                  type="file"
                                  name="file"
                                  id="file"
                                  required
                                  onChange={(e) => handleFileSelected(e, index)}
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  marginBottom: "12px",
                                }}
                              >
                                <div
                                  className="checkList"
                                  style={{ display: "flex" }}
                                >
                                  <div style={{ fontWeight: "bolder" }}>
                                    Report Can Be Seen By*:
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <div style={{ paddingLeft: "20px" }}>
                                      <input
                                        value={"self"}
                                        type="checkbox"
                                        checked={x?.hivform?.report_can_be_seen_by?.includes(
                                          "self"
                                        )}
                                        onChange={(e) => {
                                          const { checked, value } = e.target;
                                          if (checked) {
                                            let list = [
                                              ...x?.hivform
                                                ?.report_can_be_seen_by,
                                              value,
                                            ];
                                            handleCheckBox(list, index);
                                          } else {
                                            let list =
                                              x?.hivform?.report_can_be_seen_by.filter(
                                                (item: string) => item !== value
                                              );
                                            handleCheckBox(list, index);
                                          }
                                        }}
                                        required={
                                          x?.hivform?.report_can_be_seen_by
                                            ?.length > 0
                                            ? false
                                            : true
                                        }
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        Self
                                      </span>
                                    </div>
                                    <div style={{ paddingLeft: "20px" }}>
                                      <input
                                        value={"refdoctor"}
                                        type="checkbox"
                                        checked={x?.hivform?.report_can_be_seen_by?.includes(
                                          "refdoctor"
                                        )}
                                        onChange={(e) => {
                                          const { checked, value } = e.target;
                                          if (checked) {
                                            let list = [
                                              ...x?.hivform
                                                ?.report_can_be_seen_by,
                                              value,
                                            ];
                                            handleCheckBox(list, index);
                                          } else {
                                            let list =
                                              x?.hivform?.report_can_be_seen_by.filter(
                                                (item: string) => item !== value
                                              );
                                            handleCheckBox(list, index);
                                          }
                                        }}
                                        required={
                                          x?.hivform?.report_can_be_seen_by
                                            ?.length > 0
                                            ? false
                                            : true
                                        }
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        Ref. Doctor
                                      </span>
                                    </div>
                                    <div style={{ paddingLeft: "20px" }}>
                                      <input
                                        value={"refagency"}
                                        type="checkbox"
                                        checked={x?.hivform?.report_can_be_seen_by?.includes(
                                          "refagency"
                                        )}
                                        onChange={(e) => {
                                          const { checked, value } = e.target;
                                          if (checked) {
                                            let list = [
                                              ...x?.hivform
                                                ?.report_can_be_seen_by,
                                              value,
                                            ];
                                            handleCheckBox(list, index);
                                          } else {
                                            let list =
                                              x?.hivform?.report_can_be_seen_by.filter(
                                                (item: string) => item !== value
                                              );
                                            handleCheckBox(list, index);
                                          }
                                        }}
                                        required={
                                          x?.hivform?.report_can_be_seen_by
                                            ?.length > 0
                                            ? false
                                            : true
                                        }
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        Ref. Agency
                                      </span>
                                    </div>
                                    <div style={{ paddingLeft: "20px" }}>
                                      <input
                                        value={"relative"}
                                        type="checkbox"
                                        checked={x?.hivform?.report_can_be_seen_by?.includes(
                                          "relative"
                                        )}
                                        onChange={(e) => {
                                          const { checked, value } = e.target;
                                          if (checked) {
                                            let list = [
                                              ...x?.hivform
                                                ?.report_can_be_seen_by,
                                              value,
                                            ];
                                            handleCheckBox(list, index);
                                          } else {
                                            let list =
                                              x?.hivform?.report_can_be_seen_by.filter(
                                                (item: string) => item !== value
                                              );
                                            handleCheckBox(list, index);
                                          }
                                        }}
                                        required={
                                          x?.hivform?.report_can_be_seen_by
                                            ?.length > 0
                                            ? false
                                            : true
                                        }
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        Relative
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  </>
                );
              }
            })}

            {booking && booking?.source_type !== "labwalkins" && (
              <>
                {" "}
                {normalMember +
                  inputList?.filter((x: any) => x?.isSelected === true).length <
                  104 &&
                  !(
                    booking?.report_status === "consolidate" ||
                    booking?.booking_status === "cancelled" ||
                    booking?.pickup_status === "cancelled" ||
                    booking?.booking_status === "oldresampling" ||
                    booking?.booking_status === "resampling" ||
                    booking?.org_type === "imaging"
                  ) && (
                    <Grid container direction="row">
                      <Grid
                        item
                        xs={1}
                        md={1}
                        style={{
                          display: "flex",
                          marginTop: "20px",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <AddCircleIcon
                          style={{
                            marginBottom: "1rem",
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                          }}
                          onClick={handleAddClick}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={
                          inputList?.filter((x: any) => x?.isSelected === true)
                            .length === 0
                            ? 11
                            : 11
                        }
                        md={
                          inputList?.filter((x: any) => x?.isSelected === true)
                            .length === 0
                            ? 11
                            : 11
                        }
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          paddingTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            fontSize: "18px",
                            width: "5%",
                          }}
                        >
                          OR
                        </div>
                        {
                          <div
                            id="customSelect"
                            style={{ display: "flex", width: "95%" }}
                          >
                            <Select
                              className="input"
                              name="designation"
                              variant="outlined"
                              value={
                                inputList?.filter(
                                  (x: any) =>
                                    x?.isSelected === true &&
                                    x?.customerName?.length > 0
                                )?.length > 0
                                  ? inputList?.filter(
                                      (x: any) =>
                                        x?.isSelected === true &&
                                        x?.customerName?.length > 0
                                    )
                                  : ["hello"]
                              }
                              style={{ width: "100%", margin: "0" }}
                              onChange={(e) => e.stopPropagation()}
                              open={open}
                              onClose={handleClose}
                              onOpen={handleOpen}
                              multiple
                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  label="Chip"
                                />
                              }
                              renderValue={(selected: any) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected?.map(
                                    (value: any, index: any) =>
                                      index === 0 && (
                                        <Chip
                                          key={value?.customerName}
                                          label={"Select Old Member"}
                                        />
                                      )
                                  )}
                                </div>
                              )}
                              MenuProps={MenuProps}
                              disabled={
                                allUHIDCustomer && allUHIDCustomer?.length === 0
                              }
                            >
                              {allUHIDCustomer &&
                                allUHIDCustomer.length > 0 &&
                                allUHIDCustomer.map((data: any, index: any) => {
                                  return (
                                    <MenuItem
                                      value={data?.customerId}
                                      onClick={handleOpen}
                                      style={{
                                        backgroundColor: data?.isSelected
                                          ? "#aeababa8"
                                          : "",
                                        borderBottom: "1px solid #d0cccc",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                        }}
                                        onClick={() => {
                                          if (data?.isSelected)
                                            handleUHIDRemoveCustomer(
                                              data,
                                              index
                                            );
                                          else {
                                            if (
                                              normalMember +
                                                inputList?.filter(
                                                  (x: any) =>
                                                    x?.isSelected === true
                                                ).length <
                                              104
                                            )
                                              handleUHIDAddCustomer(
                                                data,
                                                index
                                              );
                                          }
                                        }}
                                      >
                                        {
                                          <h6
                                            style={{
                                              minWidth: "30px",
                                              fontWeight: "300",
                                            }}
                                          >
                                            {data?.customerDesignation}
                                          </h6>
                                        }{" "}
                                        {
                                          <h5
                                            style={{
                                              minWidth: "60%",
                                              display: "flex",
                                            }}
                                          >
                                            <h6 style={{ paddingRight: "6px" }}>
                                              {data?.customerName}
                                            </h6>
                                            <p
                                              style={{
                                                color: "red",
                                                margin: "0",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {data?.lastBookingDate !== "NA" &&
                                                ` (${data?.lastBookingDate})`}
                                            </p>
                                          </h5>
                                        }{" "}
                                        {data?.customerGender?.toLowerCase() ===
                                        "male" ? (
                                          <h6
                                            style={{
                                              minWidth: "80px",
                                              fontWeight: "300",
                                            }}
                                          >
                                            Male
                                          </h6>
                                        ) : data?.customerGender?.toLowerCase() ===
                                          "female" ? (
                                          <h6
                                            style={{
                                              minWidth: "80px",
                                              fontWeight: "300",
                                            }}
                                          >
                                            Female
                                          </h6>
                                        ) : (
                                          <h6
                                            style={{
                                              minWidth: "80px",
                                              fontWeight: "300",
                                            }}
                                          >
                                            Other
                                          </h6>
                                        )}{" "}
                                        {data?.isSelected ? (
                                          <Button
                                            style={{
                                              backgroundColor: "red",
                                              color: "white",
                                              minWidth: "90px",
                                            }}
                                          >
                                            REMOVE
                                          </Button>
                                        ) : (
                                          <Button
                                            disabled={
                                              normalMember +
                                                inputList?.filter(
                                                  (x: any) =>
                                                    x?.isSelected === true
                                                ).length ===
                                              104
                                            }
                                            style={{
                                              backgroundColor:
                                                normalMember +
                                                  inputList?.filter(
                                                    (x: any) =>
                                                      x?.isSelected === true
                                                  ).length ===
                                                104
                                                  ? "gray"
                                                  : "green",
                                              color: "white",
                                              minWidth: "90px",
                                            }}
                                          >
                                            ADD
                                          </Button>
                                        )}
                                      </div>
                                      <div
                                        id="hello"
                                        style={{
                                          width: "30%",
                                          display: "flex",
                                          paddingLeft: "5px",
                                          borderLeft: "1px solid",
                                        }}
                                      >
                                        {editBookingForIndex === index ? (
                                          <Select
                                            className="input"
                                            name="bookingForText"
                                            variant="outlined"
                                            value={bookingForText}
                                            style={{
                                              width: "140px",
                                              margin: "0",
                                              height: "40px",
                                            }}
                                            onChange={(e) =>
                                              setBookingForText(
                                                e.target.value as string
                                              )
                                            }
                                          >
                                            <MenuItem
                                              value="self"
                                              disabled={selfExistsInLead}
                                            >
                                              Self
                                            </MenuItem>
                                            <MenuItem value="fathe">
                                              Father
                                            </MenuItem>
                                            <MenuItem value="mother">
                                              Mother
                                            </MenuItem>
                                            <MenuItem value="son">Son</MenuItem>
                                            <MenuItem value="daughter">
                                              Daughter
                                            </MenuItem>
                                            <MenuItem value="spouse">
                                              Spouse
                                            </MenuItem>
                                            <MenuItem value="grandparents">
                                              Grandparents
                                            </MenuItem>
                                            <MenuItem value="siblings">
                                              Siblings
                                            </MenuItem>
                                            <MenuItem value="in-laws">
                                              In-laws
                                            </MenuItem>
                                            <MenuItem value="other">
                                              Others
                                            </MenuItem>
                                          </Select>
                                        ) : (
                                          <h6 style={{ minWidth: "140px" }}>
                                            {data?.oldBookingFor
                                              ?.split("_")
                                              ?.map(
                                                (word: string) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              ?.join(" ")}
                                          </h6>
                                        )}{" "}
                                        {editBookingForIndex === index ? (
                                          <Button
                                            style={{
                                              width: "40px",
                                              backgroundColor: "purple",
                                              marginLeft: "20px",
                                              color: "white",
                                            }}
                                            onClick={() => {
                                              setEditBookingForIndex(-1);
                                              setBookingForText("");
                                              if (bookingForText !== "") {
                                                if (
                                                  bookingForText ===
                                                    "daughter" ||
                                                  bookingForText === "mother"
                                                )
                                                  putFamilyMemberBookingForUpdate(
                                                    {
                                                      booking_for:
                                                        bookingForText,
                                                      gender: "female",
                                                      designation: "Ms",
                                                    },
                                                    data?.customerId
                                                  );
                                                else if (
                                                  bookingForText === "son" ||
                                                  bookingForText === "father"
                                                )
                                                  putFamilyMemberBookingForUpdate(
                                                    {
                                                      booking_for:
                                                        bookingForText,
                                                      gender: "male",
                                                      designation: "Mr",
                                                    },
                                                    data?.customerId
                                                  );
                                                else
                                                  putFamilyMemberBookingForUpdate(
                                                    {
                                                      booking_for:
                                                        bookingForText,
                                                    },
                                                    data?.customerId
                                                  );
                                              }
                                            }}
                                          >
                                            <CheckCircleRoundedIcon />
                                          </Button>
                                        ) : (
                                          <Button
                                            style={{
                                              width: "40px",
                                              backgroundColor: "#0777be",
                                              marginLeft: "20px",
                                              color: "white",
                                            }}
                                            onClick={() => {
                                              setEditBookingForIndex(index);
                                              setBookingForText(
                                                data?.oldBookingFor
                                              );
                                            }}
                                          >
                                            <EditRoundedIcon />
                                          </Button>
                                        )}
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </div>
                        }
                      </Grid>
                    </Grid>
                  )}
              </>
            )}

            <Grid
              container
              direction="row"
              spacing={3}
              style={{
                margin: "1rem auto",
                paddingBottom: "10px",
              }}
            >
              <Grid item xs={12} md={6}>
                <h6 style={{ fontWeight: "bold" }}>Phlebo Remarks</h6>
                <textarea
                  className="input"
                  name="phlebo_remarks"
                  placeholder="Enter Phlebo Remarks"
                  value={phleboRemarks}
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "6px",
                    fontFamily: "monospace",
                  }}
                  onChange={(e: any) => setPhleboRemarks(e.target.value)}
                  disabled={booking.report_status === "consolidate"}
                />
              </Grid>
              {inputList &&
                inputList.some(
                  (x: any, index: any) =>
                    x?.customerName !== "" &&
                    x.isBookingStatus !== "cancelled" &&
                    x.isPickupStatus !== "cancelled"
                ) && (
                  <Grid item xs={12} md={4}>
                    <p style={{ fontWeight: "bold" }}>Invoice Choice *</p>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={invoiceChoice}
                      onChange={(e) => {
                        setInvoiceChoice(e.target.value);
                        setInvoiceChoiceCheck(true);
                      }}
                      row
                    >
                      <FormControlLabel
                        value="single-invoice"
                        control={<Radio required={booking?.bill_id === null} />}
                        label="Single Invoice"
                        // disabled={booking?.bill_id !== null}
                      />
                      <FormControlLabel
                        value="split-multiple-invoice"
                        control={<Radio required={booking?.bill_id === null} />}
                        label="Split Multiple Invoice"
                        // disabled={booking?.bill_id !== null}
                      />
                    </RadioGroup>
                  </Grid>
                )}
            </Grid>
            {/* {
              Number(checkedContainerDeliverySlot) === 1 &&
                <Grid style={{ display: "flex" }}>
                  <Grid item xs={12} md={3}>
                    <p>Need a Container For Booking</p>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div className="checkList" style={{ display: "flex" }}>
                      {
                        [{ id: 1, name: "Yes" }].map((row: any) => (
                          <div style={{ paddingLeft: "20px" }}>
                            <input value={row?.id} type="checkbox" disabled style={{ color: (checkedContainerDeliverySlot == row?.id ? "blue" : "gray") }} checked={checkedContainerDeliverySlot == row?.id ? true : false} onChange={(e) => {
                              if (!e.target.checked) {
                                setCheckedContainerDeliverySlot("");
                              } else {
                                setCheckedContainerDeliverySlot(e.target.value);
                              }
                            }} />
                            <span style={{ paddingLeft: "10px" }}>
                              <span style={{ color: (checkedContainerDeliverySlot == row?.id ? "blue" : "gray") }}>{row?.name}</span>
                            </span>
                          </div>
                        ))
                      }
                    </div>
                  </Grid>
                </Grid>
            } */}

            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} md={6}>
                <Card
                  className="sampleCollectionTime"
                  style={{
                    minHeight: "470px",
                    overflow: "auto",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    {isMatchSelectedSlot && (
                      <>
                        <span
                          style={{
                            marginBottom: "1rem",
                            color: "white",
                            fontWeight: "800",
                            fontSize: "20px",
                          }}
                        >
                          {" "}
                          Previously Selected Slot
                        </span>
                        <Card className={classes.selectedNotAvailableSlot}>
                          <p
                            style={{
                              textAlign: "center",
                              color: "black",
                              fontSize: "14px",
                            }}
                          >
                            {booking?.collection_slot?.start_time12hr}-
                            {booking?.collection_slot?.end_time12hr}
                          </p>
                          <p style={{ textAlign: "center", color: "green" }}>
                            {" "}
                            Selected Slot
                          </p>
                        </Card>
                      </>
                    )}
                  </div>
                  <h5
                    style={{
                      marginBottom: "1rem",
                      color: "white",
                      fontWeight: "800",
                    }}
                  >
                    Sample Collection Time
                  </h5>
                  <div className={classes.slotContainer}>
                    {booking?.org_type !== "imaging"
                      ? (packagesCode?.length > 0 && packagesCode[0]) ===
                        SAMPLE_1H_INTERVAL_3TIMES
                        ? SAMPLE_1H_INTERVAL_3TIMES_SLOTS &&
                          SAMPLE_1H_INTERVAL_3TIMES_SLOTS.length > 0 &&
                          SAMPLE_1H_INTERVAL_3TIMES_SLOTS.map((slot: any) => {
                            return slot.available_slots > 0 ? (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  setSelectedSlot(slot.id as Number)
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            ) : (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  alert("No Available Slot for this Area")
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            );
                          })
                        : (packagesCode?.length > 0 && packagesCode[0]) ===
                          SAMPLE_1H_INTERVAL_2TIMES
                        ? SAMPLE_1H_INTERVAL_2TIMES_SLOTS &&
                          SAMPLE_1H_INTERVAL_2TIMES_SLOTS.length > 0 &&
                          SAMPLE_1H_INTERVAL_2TIMES_SLOTS.map((slot: any) => {
                            return slot.available_slots > 0 ? (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  setSelectedSlot(slot.id as Number)
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            ) : (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  alert("No Available Slot for this Area")
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            );
                          })
                        : (packagesCode?.length > 0 && packagesCode[0]) ===
                          SAMPLE_2H_INTERVAL_2TIMES
                        ? SAMPLE_2H_INTERVAL_2TIMES_SLOTS &&
                          SAMPLE_2H_INTERVAL_2TIMES_SLOTS.length > 0 &&
                          SAMPLE_2H_INTERVAL_2TIMES_SLOTS.map((slot: any) => {
                            return slot.available_slots > 0 ? (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  setSelectedSlot(slot.id as Number)
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            ) : (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  alert("No Available Slot for this Area")
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            );
                          })
                        : timeSlots &&
                          timeSlots.length > 0 &&
                          timeSlots.map((slot: any) => {
                            return slot.available_slots > 0 ? (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() => {
                                  const currentTime =
                                    moment().format("HH:mm:ss");
                                  const newTime = moment(
                                    currentTime,
                                    "HH:mm:ss"
                                  )
                                    .add(90, "minutes")
                                    .format("HH:mm:ss");

                                  if (
                                    slot?.start_time < newTime &&
                                    collection_date ===
                                      moment().format("YYYY-MM-DD")
                                  ) {
                                    setPhleboCostForPremiumSlot(150);
                                  } else {
                                    setPhleboCostForPremiumSlot(0);
                                  }
                                  setSelectedSlot(slot.id as Number);
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            ) : (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  alert("No Available Slot for this Area")
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Available slots: {slot.available_slots}
                                </p>
                              </Card>
                            );
                          })
                      : timeslots &&
                        timeslots?.results &&
                        timeslots.results.length > 0 &&
                        timeslots.results.map((slot: any) => {
                          return (
                            <Card
                              key={slot.id}
                              className={
                                slot.id === selectedSlot
                                  ? classes.selectedSlot
                                  : classes.slot
                              }
                              onClick={() => setSelectedSlot(slot.id as Number)}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "14px",
                                }}
                              >
                                {slot.start_time}-{slot.end_time}
                              </p>
                              {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                            </Card>
                          );
                        })}
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
                <div className="priceDetails" style={{ padding: "1rem" }}>
                  <table style={{ width: "100%", margin: "0" }}>
                    <thead>
                      <tr>
                        <td className="cell">Name</td>
                        <td className="cell">Packages</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          color:
                            pickup_status !== "cancelled" ? "white" : "red",
                        }}
                      >
                        <td className="cell">{customer_name}</td>
                        <td className="cell">
                          <ul>
                            {packageDetails &&
                              packageDetails?.length &&
                              packageDetails.map((pack: any, index: any) => {
                                const isFreeTest =
                                  booking.free_test &&
                                  booking.free_test.free_test.id === pack.id;
                                return (
                                  <li key={index}>
                                    {pack.name}
                                    {isFreeTest && (
                                      <span
                                        style={{
                                          color: "#fff",
                                          backgroundColor: "green",
                                          padding: "0.2rem 0.5rem",
                                          borderRadius: "0.25rem",
                                          marginLeft: "0.5rem",
                                          boxShadow:
                                            "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                          fontWeight: "bold",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        FREE
                                      </span>
                                    )}
                                    <br />
                                    DESCRIPTION : {pack.description}
                                    <br />
                                    Report time:{" "}
                                    {pack.package_city_prices
                                      ? pack.package_city_prices.tat_time
                                      : pack.tat_time}
                                    <br />
                                    {pack?.smart_report_available === true && (
                                      <>
                                        Smart Report Available:{" "}
                                        {pack?.smart_report_available === false
                                          ? "No"
                                          : "Yes"}
                                      </>
                                    )}
                                    <br />
                                    Sample Type:{" "}
                                    {pack &&
                                      pack?.sample_types &&
                                      pack?.sample_types.length > 0 &&
                                      pack?.sample_types?.map(
                                        (data: any) => data?.display_name + ","
                                      )}
                                    <br />
                                    Price:{" "}
                                    {pack.package_city_prices
                                      ? pack.package_city_prices.offer_price
                                      : pack.offer_price}
                                  </li>
                                );
                              })}
                          </ul>
                        </td>
                      </tr>
                      {booking?.additional_members &&
                        (booking?.additional_members?.length > 0 ||
                          inputList?.length > 0) &&
                        inputList.map((member: any, index: any) => {
                          if (
                            member?.isSelected === true ||
                            member?.isSelected === false
                          ) {
                            return (
                              <tr
                                key={index}
                                style={{
                                  color:
                                    member?.isPickupStatus !== "cancelled" &&
                                    member?.isBookingStatus !== "cancelled"
                                      ? "white"
                                      : "red",
                                }}
                              >
                                <td className="cell">{member.customerName}</td>
                                <td className="cell">
                                  <ul>
                                    {member.customerPackage &&
                                      member.customerPackage?.length > 0 &&
                                      member.customerPackage.map(
                                        (pack: any, ind: any) => {
                                          const isFreeTest =
                                            member?.free_test &&
                                            member?.free_test?.free_test?.id ===
                                              pack.id;
                                          return (
                                            <li key={ind}>
                                              {pack.name}
                                              {isFreeTest && (
                                                <span
                                                  style={{
                                                    color: "#fff",
                                                    backgroundColor: "green",
                                                    padding: "0.2rem 0.5rem",
                                                    borderRadius: "0.25rem",
                                                    marginLeft: "0.5rem",
                                                    boxShadow:
                                                      "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                    fontWeight: "bold",
                                                    fontSize: "0.875rem",
                                                  }}
                                                >
                                                  FREE
                                                </span>
                                              )}
                                              <br />
                                              DESCRIPTION : {pack.description}
                                              <br />
                                              Report time:{" "}
                                              {pack.package_city_prices
                                                ? pack.package_city_prices
                                                    .tat_time
                                                : pack.tat_time}
                                              <br />
                                              {pack?.smart_report_available ===
                                                true && (
                                                <>
                                                  Smart Report Available:{" "}
                                                  {pack?.smart_report_available ===
                                                  false
                                                    ? "No"
                                                    : "Yes"}
                                                </>
                                              )}
                                              <br />
                                              Sample Type:{" "}
                                              {pack?.sample_types &&
                                                pack?.sample_types.length > 0 &&
                                                pack?.sample_types?.map(
                                                  (data: any) =>
                                                    data?.display_name + ","
                                                )}
                                              <br />
                                              Price:{" "}
                                              {pack.package_city_prices
                                                ? pack.package_city_prices
                                                    .offer_price
                                                : pack.offer_price}
                                            </li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </div>
                <div
                  className="priceDetails"
                  style={{ padding: "1rem 1rem 0 1rem" }}
                >
                  <h6 className="totalPrice">
                    Total Package Price: ₹ {Math.floor(totalPriceForBooking)}
                  </h6>
                  <h6 className="totalPrice">
                    RedCash Discount: ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                  </h6>
                  {booking?.discounted_price?.coupon_type === "referral" && (
                    <h6 className="totalPrice">
                      Referral Coupon Discount: ₹{" "}
                      {Math.floor(referralCouponDiscount)}
                    </h6>
                  )}
                  {booking?.discounted_price?.coupon_type ===
                    "redeem points" && (
                    <h6 className="totalPrice">
                      Referral Points Discount: ₹{" "}
                      {booking?.discounted_price?.referral_coupon_discount}
                    </h6>
                  )}
                  <h6 className="totalPrice">Discount: ₹ {discount}</h6>
                  <h6 className="totalPrice">
                    Express slot Charges : ₹{" "}
                    {thyroidPhleboCost > 0
                      ? thyroidPhleboCost
                      : Math.max(phleboCostForPremiumSlot, phleboCost)}
                  </h6>

                  <h6 className="totalPrice">
                    <p>
                      Diagnostic Fee
                      <Tooltip
                        title={TooltipReportAndDietConsultationContent()}
                        classes={{ tooltip: classes.customTooltip }}
                      >
                        <InfoRoundedIcon
                          style={{
                            color: "#9142a8",
                            marginLeft: "6px",
                          }}
                        />
                      </Tooltip>
                      :{" "}
                      <strong>
                        {" "}
                        {booking?.discounted_price &&
                          booking?.discounted_price.diagnostic_cost &&
                          Math.floor(booking?.discounted_price.diagnostic_cost)}
                        /-
                      </strong>
                    </p>
                  </h6>

                  <h6 className="totalPrice">
                    {booking?.org_type === "homedx" && (
                      <>
                        <p>
                          Report And Diet Consultation : ₹
                          <span style={{ textDecoration: "line-through" }}>
                            299{" "}
                          </span>
                          <span style={{ marginLeft: "7px" }}> FREE</span>
                        </p>
                      </>
                    )}{" "}
                  </h6>
                  <h6 className="totalPrice">
                    Payment via Gift Card: ₹ {giftCardDiscount}
                  </h6>
                  <h6 className="totalPrice">
                    VIP Membership Discount: ₹{" "}
                    {redProMembershipDiscount > 0
                      ? redProMembershipDiscount
                      : 0}
                  </h6>
                  <h6 className="totalPrice">
                    Pitched Price: ₹ {Math.floor(booking?.pitched_price)}
                  </h6>

                  {proMembershipPrice > 0 && (
                    <h6 className="totalPrice">
                      VIP Membership Price: ₹ {Math.floor(proMembershipPrice)}
                    </h6>
                  )}
                  {(requestedForHardCopy === "true" ||
                    requestedForHardCopy === true) && (
                    <h6 className="totalPrice">
                      Hard Copy Cost: ₹ {hardCopyPrice}
                    </h6>
                  )}
                  {Math.floor(booking?.discounted_price?.final_total_price) !==
                    Math.floor(booking?.pitched_price) &&
                    booking?.pitched_price !== null &&
                    booking?.pitched_price !== 0 && (
                      <>
                        <h6 className="totalPrice">
                          Pitched Price: ₹ {Math.floor(booking?.pitched_price)}
                        </h6>
                      </>
                    )}

                  <h6 className="totalPrice">
                    Final Amount: ₹{" "}
                    {totalPriceForBooking -
                      (booking?.discounted_price?.coupon_type ===
                      "redeem points"
                        ? Math.floor(
                            booking?.discounted_price?.referral_coupon_discount
                          )
                        : Math.floor(referralCouponDiscount)) -
                      Math.floor(redProMembershipDiscount) +
                      hardCopyPrice +
                      diagnosticFee +
                      Math.max(
                        thyroidPhleboCost,
                        phleboCost,
                        phleboCostForPremiumSlot
                      ) -
                      Math.floor(redDisc) -
                      Math.floor(discount) +
                      proMembershipPrice >
                    0
                      ? totalPriceForBooking -
                        (booking?.discounted_price?.coupon_type ===
                        "redeem points"
                          ? Math.floor(
                              booking?.discounted_price
                                ?.referral_coupon_discount
                            )
                          : Math.floor(referralCouponDiscount)) -
                        Math.floor(redProMembershipDiscount) +
                        hardCopyPrice +
                        diagnosticFee +
                        Math.max(
                          thyroidPhleboCost,
                          phleboCost,
                          phleboCostForPremiumSlot
                        ) -
                        Math.floor(redDisc) -
                        Math.floor(discount) +
                        proMembershipPrice
                      : proMembershipPrice}
                  </h6>

                  {alreadyARedPro &&
                  booking?.discounted_price?.subscription_amount === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "8px",
                      }}
                      onMouseEnter={() =>
                        setIsHitLeadSubscriptionsDataAPI(true)
                      }
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{
                          fontSize: "16px",
                          backgroundColor: "green",
                          color: "white",
                        }}
                        onClick={() => setOpenLoyaltyModal(true)}
                        disabled
                      >
                        Already a VIP Member
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "8px",
                      }}
                      onMouseEnter={() =>
                        setIsHitLeadSubscriptionsDataAPI(true)
                      }
                    >
                      {IsLoyaltyCheker || checkedWay === "" ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          style={{ fontSize: "16px" }}
                          onClick={() => setOpenLoyaltyModal(true)}
                          disabled={
                            Object.keys(newLeadDetails).length === 0 ||
                            newLeadDetails?.id === "undefined" ||
                            newLeadDetails?.id === undefined ||
                            booking.report_status === "consolidate"
                              ? true
                              : false
                          }
                        >
                          Add VIP Membership
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            setOpenLoyaltyModal(true);
                            setIsLoyaltyCheker(true);
                          }}
                          disabled={
                            Object.keys(newLeadDetails).length === 0 ||
                            newLeadDetails?.id === "undefined" ||
                            newLeadDetails?.id === undefined ||
                            booking.report_status === "consolidate"
                              ? true
                              : false
                          }
                        >
                          Cancel VIP Membership
                        </Button>
                      )}
                    </div>
                  )}

                  <p
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      margin: "0",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {redCoin?.coins?.length > 0 &&
                      (totalRedCash > 0
                        ? `${
                            totalRedCash - Math.floor(redDisc)
                          }${redCoinMessage}`
                        : redCoinMessage)}
                    {redCoin && redCoin?.coins && redCoin?.coins >= 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={redCashButton}
                            onChange={handleRedCheck}
                            name="Redeem Redcash"
                            style={{ margin: "10px 0 0 10px" }}
                            disabled={
                              totalRedCash <= 0 ||
                              booking.report_status === "consolidate" ||
                              giftCardDiscount - giftCardTotalDiscount <= 0
                            }
                          />
                        }
                        label=""
                      />
                    )}
                  </p>
                  <div>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        margin: "0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Member Discount
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={memberDiscountCheck}
                            onChange={(e) =>
                              handleMemberDiscountCheck(e.target.checked)
                            }
                            name="Member discount"
                            disabled={
                              inputList.filter(
                                (data: any) =>
                                  data.isSelected !== "start" &&
                                  data.isBookingStatus !== "cancelled" &&
                                  data.isPickupStatus !== "cancelled"
                              ).length === 0 ||
                              Math.floor(totalPriceForBooking) < 798
                            }
                            style={{
                              margin: "0",
                              marginLeft: "10px",
                            }}
                          />
                        }
                        style={{ paddingTop: "7px" }}
                        label=""
                      />
                    </p>
                    <p
                      style={{
                        color: "#990699",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "600",
                        alignItems: "center",
                      }}
                    >
                      (Minimum cart value should be Rs 798)
                    </p>
                  </div>
                  <>
                    {couponAutoApplied &&
                      couponAutoApplied?.coupon_codes &&
                      couponAutoApplied?.coupon_codes?.length > 0 &&
                      totalPriceForBooking > checkCartValue && (
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            margin: "0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={applyCouponCheker}
                                onChange={handleAutoApplyCoupon}
                                name="Auto Apply "
                                style={{
                                  margin: "0",
                                  marginLeft: "10px",
                                }}
                              />
                            }
                            style={{ paddingTop: "7px" }}
                            label="Auto Apply Coupon"
                          />
                        </p>
                      )}
                  </>

                  {!redCashButton &&
                  booking?.discounted_price?.coupon_type !== "redeem points" ? (
                    <>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ margin: "1rem auto" }}
                      >
                        {booking?.discounted_price?.coupon_type ===
                          "referral" &&
                        booking?.discounted_price?.referral_coupon_discount >
                          0 ? (
                          <p>You have already applied Referral Coupon Code.</p>
                        ) : booking.report_status === "consolidate" ? (
                          ""
                        ) : (
                          <Grid container spacing={1} direction="row">
                            <Grid item xs={12} id="customTextField">
                              <h6 style={{ fontWeight: "600" }}>
                                Manage Coupon
                              </h6>
                              <Select
                                className="input"
                                name="cType"
                                variant="outlined"
                                value={cType}
                                style={{ width: "100%", margin: "0" }}
                                onChange={(e) => {
                                  setCValue("");
                                  setCoupon(0);
                                  setCouponCode("");
                                  setDiscount(0);
                                  setCType(e.target.value);
                                  handleMemberDiscountCheck(false);
                                }}
                              >
                                <MenuItem disabled value={""}>
                                  Discount Type
                                </MenuItem>
                                <MenuItem value={"code"}>Code</MenuItem>
                                <MenuItem value={"percentage"}>
                                  Percentage
                                </MenuItem>
                                <MenuItem value={"amount"}>Amount</MenuItem>
                              </Select>
                            </Grid>
                            {cType === "percentage" ? (
                              <>
                                <Grid
                                  container
                                  spacing={1}
                                  direction="row"
                                  style={{
                                    margin: "12px 4px 0 4px",
                                    alignItems: "end",
                                  }}
                                >
                                  <Grid item xs={8} id="customSelectField">
                                    <h6 style={{ fontWeight: "400" }}>
                                      Select Percentage
                                    </h6>
                                    <TextField
                                      name="coupon_percentage"
                                      value={cValue}
                                      className="input"
                                      variant="outlined"
                                      label="Percentage"
                                      onChange={(e) =>
                                        setCValue(e.target.value)
                                      }
                                      fullWidth
                                      size="small"
                                      select
                                      disabled={totalPriceForBooking <= 0}
                                    >
                                      <MenuItem value={""}>None</MenuItem>
                                      {Array.from(Array(100).keys()).map(
                                        (val: any) => {
                                          return (
                                            <MenuItem value={val + 1}>
                                              {val + 1}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Button
                                      variant="contained"
                                      fullWidth
                                      color="primary"
                                      disabled={cValue === ""}
                                      onClick={() => applyCouponPerc()}
                                    >
                                      Apply
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            ) : cType === "amount" ? (
                              <>
                                <Grid
                                  container
                                  spacing={1}
                                  direction="row"
                                  style={{
                                    margin: "12px 4px 0 4px",
                                    alignItems: "end",
                                  }}
                                >
                                  <Grid item xs={8} id="customSelectField">
                                    <h6 style={{ fontWeight: "400" }}>
                                      Enter Amount
                                    </h6>
                                    <TextField
                                      name="coupon_amount"
                                      value={cValueForNum}
                                      className="input"
                                      variant="outlined"
                                      label=""
                                      placeholder="please enter Amount"
                                      type="number"
                                      // inputProps={{
                                      //   min: 0,
                                      //   max: Math.floor(totalPriceForBooking)
                                      // }}
                                      // onChange={(e) =>
                                      //   setCValueForNum(e.target.value)
                                      // }
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        const numericValue = Number(newValue);
                                        const maxValue = Math.floor(totalPriceForBooking);
                                    
                                        if (newValue === "" || (numericValue >= 0 && numericValue <= maxValue)) {
                                          setCValueForNum(newValue);
                                        }
                                      }}
                                      fullWidth
                                      size="small"
                                      disabled={totalPriceForBooking <= 0}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Button
                                      variant="contained"
                                      fullWidth
                                      color="primary"
                                      disabled={cValueForNum === 0}
                                      onClick={() => applyCouponNum()}
                                    >
                                      Apply
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              cType === "code" && (
                                <>
                                  <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    style={{
                                      margin: "12px 4px 0 4px",
                                      alignItems: "end",
                                    }}
                                    onMouseEnter={() => setIsHitCouponAPI(true)}
                                  >
                                    <Grid item xs={8}>
                                      <h6 style={{ fontWeight: "400" }}>
                                        Enter Coupon
                                      </h6>
                                      <Autocomplete
                                        id="coupon"
                                        key={couponKey}
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            let obj = JSON.parse(
                                              JSON.stringify(
                                                newValue,
                                                null,
                                                " "
                                              )
                                            );
                                            setCoupon(obj?.id as Number);
                                            setCouponCode(obj?.code as string);
                                          }
                                        }}
                                        inputValue={
                                          couponCode &&
                                          couponCode?.startsWith(
                                            "WELCOMEBACK"
                                          ) === true
                                            ? "XXXXXXXX"
                                            : couponCode
                                        }
                                        options={(
                                          couponList?.results || []
                                        )?.filter(
                                          (option: any) =>
                                            !option?.should_autoapply
                                        )}
                                        freeSolo
                                        blurOnSelect
                                        onInputChange={(
                                          event,
                                          newInputValue
                                        ) => {
                                          clearTimeout(timer.current);
                                          setCouponCode(newInputValue);
                                          timer.current = setTimeout(() => {
                                            if (event !== null) {
                                              getCoupons(
                                                `?code=${newInputValue}&org_type=homedx`
                                              );
                                            }
                                          }, 1000);
                                          if (
                                            newInputValue === "" &&
                                            event !== null
                                          ) {
                                            setCoupon(0);
                                            setDiscount(0);
                                            setIsClearCoupon(true);
                                          }
                                        }}
                                        getOptionLabel={(option: any) =>
                                          option?.code
                                        }
                                        getOptionDisabled={(option: any) =>
                                          option?.minimum_price >
                                          totalPriceForBooking
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            className="input"
                                            {...params}
                                            placeholder="Enter Coupon"
                                            variant="outlined"
                                            inputProps={{
                                              ...params.inputProps,
                                              onKeyDown: (e) => {
                                                if (e.key === "Enter") {
                                                  e.stopPropagation();
                                                }
                                              },
                                            }}
                                            style={{
                                              width: "100%",
                                              margin: "0",
                                              padding: "0",
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        disabled={coupon === 0}
                                        onClick={applyCoupon}
                                      >
                                        Apply
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </>
                              )
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ margin: "1rem auto" }}
                      >
                        <Grid container spacing={1} direction="row">
                          <p
                            className="totalPrice"
                            style={{
                              color: "#ff4569",
                              fontWeight: "15px",
                              alignItems: "center",
                            }}
                          >
                            You Have Already Apply Red Cash/Referral
                            Point/Coupon. So You Can't Apply Other Coupons.
                          </p>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {Object.keys(newLeadDetails).length === 0 ? (
                    ""
                  ) : newLeadDetails?.is_first_booking === true ||
                    Number(bookingId) === newLeadDetails?.is_first_booking ? (
                    <ul>
                      <li
                        style={{
                          fontWeight: "bolder",
                          marginLeft: "20px",
                          fontFamily: "inherit",
                          color: "#212121",
                        }}
                      >
                        <p>
                          Since this is their first booking, they’ll get 100%
                          Redcash on this booking.
                        </p>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li
                        style={{
                          fontWeight: "bolder",
                          marginLeft: "20px",
                          fontFamily: "inherit",
                          color: "#212121",
                        }}
                      >
                        <p>
                          Since the customer has already done a booking (through
                          there primary/alternate/WhatsApp) no., they will get
                          5% Redcash on this booking.
                        </p>
                      </li>
                      <li
                        style={{
                          fontWeight: "bolder",
                          marginLeft: "20px",
                          fontFamily: "inherit",
                          color: "#212121",
                        }}
                      >
                        <p>
                          Since Your Total Price is zero via applying Gift Card
                          then you can't apply any additional discount.
                        </p>
                      </li>
                    </ul>
                  )}
                </div>
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={
                  loading ||
                  !selectedSlot ||
                  packages.length === 0 ||
                  !mobileValidation.customer_alternatenumber ||
                  !mobileValidation.customer_phonenumber ||
                  !mobileValidation.customer_whatsapppnumber ||
                  +customer_age < 5 ||
                  bookingFor === "" ||
                  bookingUpdateDisabledWithoutSaveCount > 0 ||
                  disableUpdate ||
                  disableUpdateButton
                }
              >
                Update booking
              </Button>
            </div>
          </form>
        </Paper>
      )}
      <Dialog
        open={openAddonTest}
        onClose={() => setOpenAddonTest(false)}
        aria-labelledby="form-dialog-title"
      >
        <div style={{ display: "flex", padding: "10px" }}>
          <h5 style={{ width: "80%", textAlign: "left" }}>Addon tests</h5>
          <div style={{ width: "20%", textAlign: "right" }}>
            <IconButton onClick={() => setOpenAddonTest(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <DialogContent>
          <TableContainer className={classes.table}>
            <Table aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Code</StyledTableCell>
                  <StyledTableCell align="center">Price</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {testList &&
                  testList?.length > 0 &&
                  testList?.map((data: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {data.name || ""}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.code || ""}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.offer_price || ""}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={
                              currPkgs &&
                              currPkgs?.length > 0 &&
                              currPkgs?.some(
                                (item: any) => item?.code === data.code
                              )
                            }
                            onClick={() => handleAddnPkg(data, true)}
                          >
                            Add
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openLoyaltyModal}
        // onClose={() => setOpenLoyaltyModal(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Add VIP Membership</div>
            <div>
              <CloseIcon
                onClick={() => {
                  // setCheckedWay("");
                  // setRedProMembershipDiscountPercentage(0);
                  // setProMembershipPrice(0);
                  setOpenLoyaltyModal(false);
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="checkList" style={{ display: "flex" }}>
                <div style={{}}>
                  {leadSubscriptionsData?.count > 0 ? (
                    leadSubscriptionsData?.results.map((row: any) => (
                      <div style={{ paddingLeft: "20px" }}>
                        <input
                          value={row?.id}
                          type="checkbox"
                          checked={checkedWay == row?.id ? true : false}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setCheckedWay("");
                              setRedProMembershipDiscountPercentage(0);
                              setProMembershipPrice(0);
                              setDiscount(0);
                            } else {
                              setCheckedWay(e.target.value);
                              setRedProMembershipDiscountPercentage(10);
                              setProMembershipPrice(row?.subscription_amount);
                              setDiscount(0);
                            }
                          }}
                        />
                        <span style={{ paddingLeft: "10px" }}>
                          <span
                            style={{
                              textDecoration: "line-through",
                              fontSize: "20px",
                            }}
                          >
                            {row?.name?.slice(0, 6)}
                          </span>
                          <span style={{ color: "gray" }}>
                            {row?.name?.slice(6, 17)}
                          </span>
                          <span style={{ fontSize: "20px" }}>
                            {row?.name?.slice(17, 35)}
                          </span>
                        </span>
                      </div>
                    ))
                  ) : (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        padding: "10px",
                      }}
                    >
                      Oops! As of now no subscriptions available.
                    </p>
                  )}
                </div>
              </div>
            </Grid>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ margin: "1rem" }}>
                <div
                  style={{
                    alignItems: "left",
                    display: "inline-block",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <span>Benefits:</span>
                  <small>
                    <ul style={{ paddingLeft: "15px" }}>
                      <li>
                        Users will get an additional 15% off on first booking
                        and an additional 10% off on all subsequent bookings.
                      </li>
                      <li>No convenience fee for Premium slots.</li>
                      <li>
                        Free report consultation from our doctors on Priority.
                      </li>
                      <li>
                        100% cash back as Red cash on 1st booking and 5%
                        cashback on all subsequent bookings. (Which can be used
                        on all future bookings)
                      </li>
                      <li>24/7 Priority customer support.</li>
                      <li>Free personalised Diet chart - over WhatsApp.</li>
                    </ul>
                  </small>
                </div>
              </div>
            </DialogContentText>
          </Grid>
        </DialogContent>
        <DialogActions style={{ margin: "0px 10px 10px 0px" }}>
          <Button
            variant="contained"
            style={{
              marginTop: "20px",
            }}
            color="primary"
            onClick={handleAddLoyalty}
            disabled={checkedWay === ""}
          >
            ADD Membership
          </Button>
          <Button
            variant="contained"
            style={{
              marginTop: "20px",
              marginLeft: "10px",
            }}
            color="secondary"
            onClick={handleCanel}
            disabled={checkedWay === ""}
          >
            Cancel Membership
          </Button>
        </DialogActions>
      </Dialog>

      {viewImgAadhaar && (
        <ImageModal
          src={imageAddhaarViewURL}
          open={viewImgAadhaar}
          setOpen={setViewImgAadhaar}
          heading={false}
        />
      )}
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  packageList: state.TechSupportReducer.packageList,
  couponList: state.TechSupportReducer.couponList,
  booking: state.TechSupportReducer.booking,
  booking_slots: state.TechSupportReducer.booking_slots,
  loading: state.TechSupportReducer.loading,
  geoFencesMapMyIndia: state.TechSupportReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.TechSupportReducer.mapmyindiaAccessToken,
  timeslots: state.TechSupportReducer.timeslots,
  userDetails: state.TechSupportReducer.userDetails,
  newLeadDetails: state.TechSupportReducer.newLeadDetails,
  refDoctor: state.TechSupportReducer.refDoctor,
  postRefReducer: state.TechSupportReducer.postRefReducer,
  redCoin: state.TechSupportReducer.redCoin,
  leadSubscriptionsData: state.TechSupportReducer.leadSubscriptionsData,
  containerSlots: state.TechSupportReducer.containerSlots,
  bookingListLoader: state.TechSupportReducer.bookingListLoader,
  familyMemberUpdateData: state.TechSupportReducer.familyMemberUpdateData,
  uniqueCustomerData: state.TechSupportReducer.uniqueCustomerData,
  packageQuestion: state.TechSupportReducer.packageQuestion,
  couponAutoApplied: state.TechSupportReducer.couponAutoApplied,
  vipActivationStatus: state.TechSupportReducer.vipActivationStatus,
});

export default connect(mapStateToProps, {
  getPackage,
  getNewPackage,
  getCoupons,
  getBookingById,
  getAvailableSlots,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  getTimeslots,
  SendLocationRequest,
  getNewLeadDetails,
  getRefDoctor,
  postRefDoctor,
  getRedCoing,
  getLeadSubscriptionsData,
  postRemoveSubscription,
  getContainerSlots,
  editBookingInSales,
  putFamilyMemberBookingForUpdate,
  addUniqueCustomer,
  postPackageAnswer,
  getPackageQuestion,
  checkGeoFenchMapMyIndiaPilot,
  getCouponAutoApply,
  getVIPActivationStatus,
})(EditBookingForm);