import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
    Paper,
    Table,
    withStyles,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
} from '@material-ui/core';
import Loader from '../Loader2/index';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
    getReportStatusLive,
  } from "../../../actions/loginActions";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
        // maxHeight:"80%",
        marginTop: "50px",
        height: "80%",
        position: "sticky",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
        position: "sticky",
    },
    table: {
        maxWidth: "100%",
        margin: "auto",
        overflow: 'scroll',
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
        overflow: 'scroll',
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    listStyle: {
        marginLeft: "20px",
    }
}));
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14, fontWeight: 600
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {

}
type ModalProps = {
    loading: Boolean;
    openReportModal: boolean;
    setReportModal: Function;
    reportStatusLiveData: any;
    booking: any;
    getReportStatusLive: any;
    bookingMainId: any;
    openFromPhlebo: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    openReportModal,
    setReportModal,
    reportStatusLiveData,
    loading,
    booking,
    getReportStatusLive,
    bookingMainId,
    openFromPhlebo,
}) => {

    const classes = useStyles();

    const [personId, setPersonId] = React.useState("none");

    useEffect(() => {
        if (openFromPhlebo === true) handleFirstCall(booking?.pk, booking);
    }, [])

    const handleFirstCall = (id: any, data: any) => {
        if (data?.bill_id !== null && data?.bill_id !== undefined) {
            setPersonId(id);
            getReportStatusLive(`${data?.bill_id}`);
        } else {
            setReportModal(false);
            alert("Bill Id is Not Generated");
        }
    }

    const handleChange = (event: any, data: any) => {
        if (event.target.value == bookingMainId) {
        //   setBillId(data?.bill_id);
          if (data?.bill_id !== null && data?.bill_id !== undefined) {
            getReportStatusLive(`${data?.bill_id}`);
          } else {
            alert("Bill Id is Not Generated");
          }
        } else {
          const additionDetails = data?.additional_members.find(
            (bId: any) => bId.id === event.target.value
          );
        //   setAdditionalBillId(additionDetails?.bill_id);
          if (additionDetails?.bill_id !== null && additionDetails?.bill_id !== undefined) {
            getReportStatusLive(`${additionDetails?.bill_id}`);
          } else {
            alert("Family Member Bill Id is Not Generated");
          }
        }
        setPersonId(event.target.value);
      };

    const handleClose = () => {
        setReportModal(false);
    };
    
    return (
        <div>
            <TableContainer className={classes.tableContainer} component={Paper} >
                <Table stickyHeader aria-label="simple table">
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openReportModal}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={openReportModal}>
                            <div className={classes.paper} style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                marginTop: "20px",
                                height: '100%',
                                position: "sticky",

                            }}>
                                <Paper elevation={15} className={classes.paper}>
                                    <TableContainer className={classes.table} component={Paper}>
                                        <div className={classes.head}>
                                            <h4
                                                style={{
                                                    marginBottom: "1rem",
                                                }}
                                                id="transition-modal-title"
                                            >
                                                REPORT STATUS
                                            </h4>
                                            <CloseIcon onClick={() => {
                                                setReportModal(false)
                                            }} />
                                        </div>
                                        { openFromPhlebo === true ? 
                                            <div style={{ display: "flex" }}>
                                                <h6 style={{ paddingTop: "10px", fontSize: "20px" }}>Select Patient: </h6>
                                                <Select
                                                    className="input"
                                                    name="priority"
                                                    variant="outlined"
                                                    style={{
                                                        width: "270px",
                                                        marginLeft: "1rem",
                                                        marginBottom: "1rem",
                                                        alignItems: "flex-start",
                                                        height: "45px",
                                                    }}
                                                    value={personId}
                                                    onChange={(e: any) => handleChange(e, booking)}
                                                    >
                                                    <MenuItem disabled selected value={"none"}>
                                                        Customer Name
                                                    </MenuItem>
                                                    <MenuItem value={booking?.pk}>{booking?.customer_name}</MenuItem>
                                                    {booking.additional_members &&
                                                        booking.additional_members.length > 0 &&
                                                        booking.additional_members.map((data: any) => {
                                                        return (
                                                            <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>
                                                        );
                                                        })}
                                                </Select>
                                            </div>
                                            : ""
                                        }
                                        {loading ? <Loader /> : (
                                            <Table aria-label="simple table">

                                                <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell align="center">Customer Name </StyledTableCell>
                                                        <StyledTableCell align="center">Test Name</StyledTableCell>
                                                        <StyledTableCell align="center">Booking Centre</StyledTableCell>
                                                        <StyledTableCell align="center">Processing Centre</StyledTableCell>
                                                        <StyledTableCell align="center">Status Time</StyledTableCell>
                                                        <StyledTableCell align="center">Status</StyledTableCell>
                                                        <StyledTableCell align="center">Estimated TAT</StyledTableCell>
                                                        <StyledTableCell align="center">Expected lab tat</StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        reportStatusLiveData && reportStatusLiveData?.length > 0 && reportStatusLiveData?.map((data: any, index: any) => {
                                                                return (
                                                                    <StyledTableRow key={index}>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.NAME}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.TestName}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.BookingCentre}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.ProcessingCentre}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{new Date(data?.StatusDateTime).toLocaleString()}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.Status}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{!!data.tat?data.tat:"NA"}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.lab_sync_time}</StyledTableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </TableBody>
                                            </Table>
                                        )}
                                    </TableContainer>
                                </Paper>
                            </div>
                        </Fade>
                    </Modal>
                </Table>
            </TableContainer>
        </div >
    );
};

const mapStateToProps = (state: any) => ({
    // area: state.ProductManagerReducer.area,
    reportStatusLiveData: state.loginReducer.reportStatusLiveData,
    booking: state.TechSupportReducer.booking,
});

export default connect(mapStateToProps, {
    getReportStatusLive,
})(CreatePhlebo);