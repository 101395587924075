import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@mui/material/Typography";
import Fade from "@material-ui/core/Fade";
import Button from '@mui/material/Button';
import {  TextField, Grid} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { updatePhleboLocation,
  checkGeoFenceArea,
   checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken
 } from "../../actions/TechSupportActions";
 import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import Loader from "../loader";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { GoogleMapsAPI } from "../../../helpers/client-config";
import { genrateManagePhlebos } from "../../../helpers/generateUrl";
import axios from "axios";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "800px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  openEditPhlebo: boolean;
  editPhleboUser: any;
  updatePhleboLocation: any;
  setOpenEditPhlebo: Function;
  loading: Boolean;
  getPhlebos:any;
  checkGeoFenchMapMyIndia:any;
  geoFencesMapMyIndia:any;
  mapmyindiaAccessToken:any;
  getMapMyIndiaAccessToken:any
  phlebo: any;
  zoneName: any;
  baseCity: any;
};

const EditPheleboLocation: React.FC<ModalProps> = ({
  openEditPhlebo,
  setOpenEditPhlebo,
  editPhleboUser,
  updatePhleboLocation,
  getPhlebos,
  loading,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  mapmyindiaAccessToken,
  getMapMyIndiaAccessToken,
  phlebo,
  zoneName,
  baseCity
}) => {
  const history = useHistory();
  const classes = useStyles();

  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [location, setLocation] = useState<any>("");
  const [currentLongitude, setCurrentLongitude] = useState<any>("");
  const [currentLatitude, setCurrentLatitude] = useState<any>("");
  const [isZoneValid, setIsZoneValid] = useState(false);
  const [zoneIds, setZoneIds] = useState<any[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [checkingZone, setCheckingZone] = useState(false);
  const [hasDataArrived, setHasDataArrived] = useState(false);
  const [unserviceableError, setUnserviceableError] = useState(false);
  useEffect(()=>{
    getMapMyIndiaAccessToken();
  },[])

  const handleClose = () => {
    setOpenEditPhlebo(false);
  };

  useEffect(() => {
    if (editPhleboUser?.zones) {
      setZoneIds(editPhleboUser?.zones);
    }
  }, [editPhleboUser]);

  const editPheleboLocation = async () => {
    const body: any = {
      longitude: currentLongitude,
      latitude: currentLatitude,
      id: editPhleboUser?.id,
      location: location,
    };
    await updatePhleboLocation(body);
    if (phlebo?.length > 0 || zoneName > 1 || baseCity > 0) {
      const body: any = {
        phlebo: phlebo,
        zone: zoneName,
        base_city: baseCity,
      };
      const url = genrateManagePhlebos(body).substring(2);
      // phlebo !== "" ? getPhlebos(`${url}`) : getZone1(`${url}`);
      getPhlebos(`?${url}`);
    } else {
      getPhlebos();
    }
    setOpenEditPhlebo(false);
  };

  useEffect(() => {
    setCurrentLocation(currentLocation ? currentLocation?.label : "");
    if (currentLocation?.label) {
      setLocation(currentLocation?.label)
      geocodeByAddress(currentLocation?.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          lat && setCurrentLatitude(lat);
          lng && setCurrentLongitude(lng);
        });
    }
  }, [currentLocation]);

  //mapmyindia
  useEffect(() => {
    if (editPhleboUser?.zones) {
      setZoneIds(editPhleboUser?.zones);
    }
  }, [editPhleboUser]);

  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");

  useEffect(() => {
    if(geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude){
      setCurrentLatitude(geoFencesMapMyIndia?.latitude)
      setCurrentLongitude(geoFencesMapMyIndia?.longitude)
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if(mapmyindiaAccessToken?.accesstoken){
      setAccessToken(mapmyindiaAccessToken?.accesstoken)
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      setLocation(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA")
    }
  }, [mapAddressObject]);

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations
          if (suggestedData.length > 0){
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    } 
  };
  //mapmyindia
  useEffect(() => {
    const fetchZoneId = async () => {
      try {
        if (mapAddressObject?.eLoc !== undefined) {
          setCheckingZone(true);
          setButtonDisabled(true);
          setHasDataArrived(false);
          setUnserviceableError(false);
          const { data } = await axios.get(
            `https://api.redcliffelabs.com/api/v1/booking/checking-geofence-area-mapmyindia_2/?eloc=${mapAddressObject?.eLoc}`
          );

          setCheckingZone(false);
          setHasDataArrived(true);


          for (let i = 0; i < zoneIds.length; i++) {
            if (zoneIds[i]?.id === data.customer_zone) {
              setIsZoneValid(true);
              setButtonDisabled(false);
            } else {
              setIsZoneValid(false);
              setButtonDisabled(true);
            }
          }
        }
      } catch (err: any) {
        setCheckingZone(false);
        setUnserviceableError(true);
      }
    };
    fetchZoneId();
  }, [mapAddressObject]);

  useEffect(() => {
    const fetchZoneId = async () => {
      try {
        if (mapAddressObject?.eLoc !== undefined) {
          setCheckingZone(true);
          setButtonDisabled(true);
          setHasDataArrived(false);
          setUnserviceableError(false);
          const { data } = await axios.get(
            `https://api.redcliffelabs.com/api/v1/booking/checking-geofence-area-mapmyindia_2/?eloc=${mapAddressObject?.eLoc}`
          );

          setCheckingZone(false);
          setHasDataArrived(true);

      

          for (let i = 0; i < zoneIds.length; i++) {
            if (zoneIds[i]?.id === data.customer_zone) {
              setIsZoneValid(true);
              setButtonDisabled(false);
            } else {
              setIsZoneValid(false);
              setButtonDisabled(true);
            }
          }
        }
      } catch (err: any) {
        setCheckingZone(false);
        setUnserviceableError(true);
      }
    };
    fetchZoneId();
  }, [mapAddressObject]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEditPhlebo}
        onClose={handleClose}
        closeAfterTransition
       
      >
        {loading ? (
          <Loader />
        ) : (
          <Fade in={openEditPhlebo}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Set Phlebo Home Location
                </h4>
                <CloseIcon onClick={() => handleClose()} />
              </div>

              <Grid
                container
                direction="row"
                alignItems="flex-start"
                
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <h6>Name</h6>
                  <TextField
                    name="name"
                    type="text"
                    value={editPhleboUser?.user?.name}
                    className="input"
                    variant="outlined"
                    placeholder="Phlebo Name"
                    disabled={true}
                    style={{
                      width: "100%",
                      minWidth: "200px",
                      background: "#ccc",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <h6>Select Home Location</h6>
                  {/* <GooglePlacesAutocomplete
                    apiKey={GoogleMapsAPI}
                    debounce={500}
                    selectProps={{
                      currentLocation,
                      onChange: setCurrentLocation,
                    }}
                  /> */}
                   <Autocomplete
                    id="colony-area-sector"
                    onChange={(event, newValue) => {
                       if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                       } else  {
                        setMapAddressObject({});
                       }
                    }}
                    defaultValue={{
                      placeName: editPhleboUser?.location || "",
                      placeAddress: "",
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => `${option?.placeName} ${option?.placeAddress}`}
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      locationAutoCompleteSearch(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Address"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                  e.stopPropagation();
                                }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                {checkingZone && (
                  <div style={{ width: "100%" }}>
                    <Typography color="green" align="center">
                      Checking zone id...
                    </Typography>
                  </div>
                )}
                {!isZoneValid && hasDataArrived && (
                  <div style={{ width: "100%" }}>
                    <Typography color="red" align="center">
                      Please assign phlebo in the same zone only
                    </Typography>
                  </div>
                )}
                {unserviceableError && (
                  <div style={{ width: "100%" }}>
                    <Typography color="red" align="center">
                      Currently, The selected area is not serviceable
                    </Typography>
                  </div>
                )}
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={editPheleboLocation}
                    disabled={
                      Object?.keys(mapAddressObject)?.length === 0 ||
                      !isZoneValid ||
                      buttonDisabled
                        ? true
                        : false
                    }
                    style={{
                      height: "50px",
                      backgroundColor: `${
                        Object?.keys(mapAddressObject)?.length === 0 ||
                        !isZoneValid ||
                        buttonDisabled
                          ? "grey"
                          : "blue"
                      }`,
                      color: "white",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  phleboPhone: state.TechSupportReducer.phleboPhone,
  loading: state.TechSupportReducer.loading,
  geoFencesMapMyIndia: state.TechSupportReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.TechSupportReducer.mapmyindiaAccessToken,
  checkGeoFenceStatus: state.TechSupportReducer.checkGeoFenceStatus,
});

export default connect(mapStateToProps, {
  updatePhleboLocation,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken
})(EditPheleboLocation);
