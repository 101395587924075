import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import { TextField, Grid} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import {
  getCityAlias,
  createCityAlias,
  getCityComments,
  getCities,
} from "../../actions/TechSupportActions";
import Autocomplete from "@material-ui/lab/Autocomplete";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  loading: boolean;
  getCityAlias: any;
  cityAlias: any;
  getCities: any;
  cities: any;
  createCityAlias: any;
  getCityComments: any;
  cityComments: any;
  setOpen: any;
  open: boolean;
};

const CommentsModal2: React.FC<ModalProps> = ({
  loading,
  getCityAlias,
  cityAlias,
  cities,
  getCities,
  createCityAlias,
  getCityComments,
  cityComments,
  setOpen,
  open,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [reason, setReason] = React.useState<any>("none");
  const [phoneNumber, setPhoneNumber] = useState<String>("");
  const [city, setCity] = useState<any>([]);
  const [alias, setAlias] = useState<any>("");
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };

  const updateAlias = async () => {
    const body: any = {
      city: city,
      name: alias,
    };
    await createCityAlias(body);
    getCityAlias("");
    history.push("/dashboard/ts/CityAlias-Master");
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Add City Alias
              </h4>
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              
              spacing={1}
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCity(obj.id);
              }
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="City *"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="alias"
            type="text"
            value={alias}
            className="input"
            label="ENTER ALIAS"
            variant="outlined"
            onChange={(e) => setAlias(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={updateAlias}
            disabled={alias === "" && city === 0}
          >
            Submit
          </Button>
        </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  cityAlias: state.TechSupportReducer.cityAlias,
  cities: state.TechSupportReducer.cities,
});

export default connect(mapStateToProps, {
  getCityAlias,
  getCities,
  createCityAlias,
  getCityComments,
})(CommentsModal2);
