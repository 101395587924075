import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./index.sass";
import {
  getPositiveCallsComments,
  addPositiveCallComment,
} from "../../actions/TechSupportActions";

const useStyles = makeStyles({
  root: {
    flexGrow: 0,
    background: "#F7F7F7",
    margin: "10px 0",
  },
});

interface Props {
  getPositiveCallsComments: Function;
  addPositiveCallComment: Function;
  todays_positive_calls: any;
  loading: boolean;
}

// custom hook for query string
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CommentHistory: React.FC<Props> = ({
  getPositiveCallsComments,
  addPositiveCallComment,
  todays_positive_calls,
  loading,
}) => {
  const classes = useStyles();
  let query = useQuery();
  const [toggleForm, setToggleForm] = React.useState<boolean>(true);
  const [comment, setComment] = React.useState<string>("");
  const [id, setId] = React.useState<any>();

  useEffect(() => {
    let lead = query.get("id");
    setId(lead);
    if (lead !== undefined && lead !== null) {
      getPositiveCallsComments(lead);
    }
    // getPositiveCallsComments(lead);
   
  }, []);

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (comment === "") {
      return;
    } else {
      let body = {
        comment,
        callId: id,
      };
      await addPositiveCallComment(body);
      setComment("");
    }
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <div
          className="header-collapse"
          onClick={() => setToggleForm(!toggleForm)}
        >
          Comment History{" "}
          {!toggleForm ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </div>
        <div className="comment-history">
          {loading
            ? "Please Wait"
            : Object.keys(todays_positive_calls).length > 0
            ? todays_positive_calls.map((comment: any) => (
                <p>
                  {comment.created_at.substring(0, 10)} :{" "}
                  {comment.created_at.substring(11, 19)} : {comment.by_user} :{" "}
                  {comment.comment}:{" "}
                </p>
              ))
            : ""}
        </div>
        <form className="modal-form chat-form" onSubmit={submitForm}>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Add Comment.."
            value={comment}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              setComment(e.target.value as string)
            }
            style={{ width: "100%", marginRight: "10px" }}
          />
          <button style={{ width: "20%" }}>
            <p>
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} size={24} />
              ) : (
                "Add Comment"
              )}
            </p>
          </button>
        </form>
      </CardContent>
      <CardActions
        style={{ paddingLeft: "16px", display: !toggleForm ? "none" : "block" }}
      ></CardActions>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  // profile: state.loginReducer.profile,
  todays_positive_calls: state.TechSupportReducer.todays_positive_calls,
  // read_call_details: state.TechSupportReducer.read_call_details,
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  getPositiveCallsComments,
  addPositiveCallComment,
})(CommentHistory);
