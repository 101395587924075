import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/index";
import {
  getBlackListedList,
  updatedBlacklistNumber,
} from "../actions/TechSupportActions";
import CreateBlacklistModal from "../components/Comments/addBlackListedModal";
import { genrateBlacklistFilterNumber } from "../../helpers/generateUrl";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
     
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  blacklistedNumber: any;
  getBlackListedList: any;
  updatedBlacklistNumber: any;
  data: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  blacklistedNumber,
  getBlackListedList,
  updatedBlacklistNumber,
  data,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [reason, setReason] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<String>("");
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState<any>(data?.is_status);
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });
  const [image, setImage] = useState<any>("");
  const [viewImg, setViewImg] = useState<boolean>(false);

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        blacklistedNumber.links && blacklistedNumber.links.next.split("?")[1];
      getBlackListedList(url ? url : "");
    } else if (newPage < page) {
      let url =
        blacklistedNumber.links &&
        blacklistedNumber.links.previous.split("?")[1];
      getBlackListedList(url ? url : "");
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getBlackListedList();
  }, []);

  const filterBlacklistedNumber = () => {
    const body: any = {
      phoneNumber,
    };
    const url = genrateBlacklistFilterNumber(body).substring(2);
    getBlackListedList(`${url}`);
    setPage(0);
  };
  const handleViewImg = (val: any) => {
    setImage(val);
    setViewImg(true);
  };
  const handleCloseImg = () => {
    setViewImg(false);
    setImage("");
  };
  
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="reset-user-password">
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={10}>
              <h2>Outgoing Blacklisted Numbers</h2>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "1rem" }}
                onClick={() => setOpen(true)}
              >
                Add Numbers
              </Button>
            </Grid>
          </Grid>
          <div className={classes.contentTable}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="customer_phonenumber"
                  type="number"
                  value={phoneNumber}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  required
                  placeholder="Enter Mobile Number"
                  label="Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", padding: "1px" }}
                  onChange={(e) => setPhoneNumber(e.target.value as String)}
                  onBlur={() => verifyMobile("mobile")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      phoneNumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.phoneNumber && "Incorrect Phone Number"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading || String(phoneNumber).length !== 10}
                  fullWidth
                  onClick={filterBlacklistedNumber}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push("/dashboard/ts/blacklistednumbers")
                  }
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Numbers</StyledTableCell>
                <StyledTableCell align="center">Reason</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Created By</StyledTableCell>
                <StyledTableCell align="center">Updated At</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Document</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {blacklistedNumber && blacklistedNumber?.results && blacklistedNumber?.results?.map((data: any, index: any) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {data?.number}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.reason}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.created_at}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.created_by}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.updated_at}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Switch
                        // defaultChecked={data?.status}
                        // checked={isActive}
                        checked={data?.is_status}
                        onChange={(e) => setIsActive(e.target.checked)}
                        name="checkedA"
                        color="primary"
                        inputProps={{
                          "aria-label": "secondary checkbox",
                        }}

                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">

                      {data?.document?.includes("pdf") ? <a href={data?.document} target="_blank" rel="noreferrer">View</a> : (
                          <InsertPhotoIcon
                            onClick={() => handleViewImg(data?.document)}
                          />
                      )}

                      {/* {data?.document ? (
                        <InsertPhotoIcon
                          onClick={() => handleViewImg(data?.document)}
                        />
                      ) : (
                        ""
                      )} */}

                      {/* {data?.document ?
                        <a href={data?.document} target="_blank" rel="noreferrer">View</a>
                        : "N/A"} */}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={blacklistedNumber?.count || 0}
                  rowsPerPage={blacklistedNumber.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <Dialog
        onClose={handleCloseImg}
        aria-labelledby="customized-dialog-title"
        open={viewImg}
      >
        <DialogTitle id="customized-dialog-title">Document Image</DialogTitle>
        <DialogContent dividers>
          <img src={image} width="800" height="500" />
        </DialogContent>
      </Dialog>
      <CreateBlacklistModal
        open={open}
        setOpen={setOpen}
        createZone={undefined}
        getCities={undefined}
        getPhlebos={undefined}
        getTimeslots={undefined}
        cities={undefined}
        phleboList={undefined}
        timeslotsList={undefined}
        area={undefined}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  blacklistedNumber: state.TechSupportReducer.blacklistedNumber,
});

export default connect(mapStateToProps, {
  getBlackListedList,
  updatedBlacklistNumber,
})(CustomUploader);
