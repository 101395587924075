import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import { TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import { updatedBlacklistNumber } from "../../actions/TechSupportActions";
import FileUploadIcon from "@mui/icons-material/FileUpload";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
}));

type ModalProps = {
  createZone: any;
  area: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  phleboList: any;
  getTimeslots: any;
  timeslotsList: any;
  open: boolean;
  setOpen: Function;
  updatedBlacklistNumber: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  setOpen,
  updatedBlacklistNumber,
  createZone,
  area,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getTimeslots,
  timeslotsList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [reason, setReason] = React.useState<any>("none");
  const [phoneNumber, setPhoneNumber] = useState<String>("");
  const [customer_phonenumber, setCustomer_phonenumber] = useState<any>("");
  const [mobileValidation, setMobileValidation] = useState({
    customer_phonenumber: true,
  });
  const [imgSrc, setImgSrc] = useState<any>("");
  const [file1, setFile1] = React.useState<any>("");

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const CreateBlacklistNumber = async () => {
    const body: any = {
      number: customer_phonenumber,
      reason: reason,
      document: file1,
    };

    // if (body["document"] === "") {
    //   delete body["document"];
    //   await updatedBlacklistNumber(body);
    //   history.push("/dashboard/ts/blacklistednumbers");
    // }else{
    //   await updatedBlacklistNumber(body);
    //   history.push("/dashboard/ts/blacklistednumbers");
    // }

    await updatedBlacklistNumber(body);
    history.push("/dashboard/ts/blacklistednumbers");
    setCustomer_phonenumber("");
    setReason("");
    setFile1("");
  };

  const fileSelectedHandler1 = async (e: React.ChangeEvent<{ files: any }>) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]));
    const base64 = await convertBase64(e.target.files[0]);
    setFile1(base64);
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Add BlackListed Number
              </h4>
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="customer_phonenumber"
                  type="number"
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onWheel={(e: any) => e.target.blur()}
                  value={customer_phonenumber || ""}
                  placeholder="Enter Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_phonenumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("mobile")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_phonenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_phonenumber &&
                    "Incorrect Phone Number"
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Select
                  className="input"
                  name="reasons"
                  variant="outlined"
                  value={reason}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  required
                  onChange={(e: any) => setReason(e.target.value)}
                >
                  <MenuItem disabled value={"none"}>
                    Reasons
                  </MenuItem>
                  <MenuItem value="Request_from_Marketing_Team">
                    {" "}
                    Request from Marketing Team
                  </MenuItem>
                  <MenuItem value="Request_from_Noida_Sales_Team">
                    Request from Noida Sales Team
                  </MenuItem>
                  <MenuItem value="Request_from_Gurugram_Sales_Team">
                    Request from Gurugram Sales Team
                  </MenuItem>
                  <MenuItem value="Request_from_Support_Team">
                    Request from Support Team
                  </MenuItem>
                  <MenuItem value="Request_from_Lab_Team">
                    Request from Lab Team
                  </MenuItem>
                  <MenuItem value="Request_from_Quality_Team">
                    Request from Quality Team
                  </MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <input
                  className="input"
                  style={{
                    width: "100%",
                    marginTop: "-2px",
                    marginBottom: "10px",
                    padding: "5px 5px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "5px",
                  }}
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  onChange={fileSelectedHandler1}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={CreateBlacklistNumber}
                  disabled={
                    !mobileValidation.customer_phonenumber || reason === "" || customer_phonenumber === "" || file1 === ""
                  }
                >
                  ADD
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push("/dashboard/ts/blacklistednumbers")
                  }
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  updatedBlacklistNumber,
  useStyles,
})(CommentsModal2);
