import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLeadSource, createDialer } from "../../actions/TechSupportActions";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    paper: {
      padding: "1rem",
      width: "100%",
      borderRadius: "10px",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "1rem",
      textAlign: "center",
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.2rem",
      margin: "0",
    },
    table: {
      maxHeight: "400px",
      margin: "auto",
      overflow: "auto",
    },
    image: {
      width: "100px",
      height: "auto",
    },
    imageSM: {
      width: "50px",
      height: "auto",
    },
    imgContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);
interface Props {
  loading: any;
  getLeadSource: any;
  lead_source: any;
  createDialer: any;
}

const BlogsPage: React.FC<Props> = ({
  loading,
  getLeadSource,
  lead_source,
  createDialer,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [did, setDid] = useState("");
  const [campignName, setCampignName] = useState<String>("");
  const [campignType, setCampignType] = useState<any>("");
  const [provider, setProvider] = useState<String>("None");
  const [skills, setSkills] = useState<String>("");
  const [marketingNumber, setMarketingNumber] = useState<String>("");
  const [didNumber, setDidNumber] = useState<String>("");
  const [purpose, setPurpose] = useState<String>("");
  const [leadSource, setLeadSource] = React.useState<number>(0);
  const [gclId, setGclId] = useState("");
  const [url, setUrl] = useState<String>("");
  const [isactive, setIsActive] = useState<boolean>(false);
  const timer = useRef<any>(0);

  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-0987654321`".includes(e.key) &&
      e.preventDefault()
    );
  };

  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(didNumber && didNumber[0]) < 6 ||
        (didNumber && didNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };

  useEffect(() => {
    getLeadSource();
  }, []);

  const handleCreateuser = async (e: any) => {
    e.preventDefault();
    const data: any = {
      did: didNumber,
      campaign_name: campignName,
      campaign_type: campignType,
      provider: provider,
      skills: [skills],
      marketing_number: marketingNumber,
      purpose: purpose,
      lead_source: leadSource,
      gcl_id: gclId,
      promotional_url: url,
      is_active: isactive,
    };

    if (marketingNumber === "" || marketingNumber === "none") {
      delete data.marketing_number;
    }
    if (gclId === "" || gclId === "none") {
      delete data.gcl_id;
    }
    if (purpose === "" || purpose === "none") {
      delete data.purpose;
    }

    if(skills.length === 0){
      delete data.skills;
    }


    await createDialer(data);
    history.push("/dashboard/ts/campaigns");
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={10}>
            <h2>Create Dialer</h2>
          </Grid>
        </Grid>
        <br />
        <Paper elevation={5} className={classes.paper}>
          <div className={classes.contentTable}>
            <form onSubmit={handleCreateuser}>
              <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                <Grid item xs={12} md={3}>
                  <h6>
                    DID<sup>*</sup>{" "}
                  </h6>
                  <TextField
                    className="input"
                    name="did_number"
                    type="number"
                    value={didNumber}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 12);
                    }}
                    required
                    placeholder="Enter DID Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setDidNumber(e.target.value as String)}
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        phoneNumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.phoneNumber && "Incorrect DID Number"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    Campaign Name <sup>*</sup>{" "}
                  </h6>
                  <TextField
                    fullWidth
                    className="input"
                    name="Campaign Name"
                    type="text"
                    placeholder="Enter Campaign Name"
                    value={campignName}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setCampignName(e.target.value as String)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    Campaign Type<sup>*</sup>{" "}
                  </h6>
                  <Select
                    className="input"
                    name="usertype"
                    variant="outlined"
                    value={campignType}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setCampignType(e.target.value as String)}
                    required
                  >
                    <MenuItem disabled value={"none"}>
                      Campaign Type
                    </MenuItem>
                    <MenuItem value={"online"}>Online</MenuItem>
                    <MenuItem value={"offline"}>Offline</MenuItem>
                    <MenuItem value={"onlinesystem"}>
                      Online System Calling
                    </MenuItem>
                    <MenuItem value={"offlinesystem"}>
                      Offline System Calling
                    </MenuItem>
                    <MenuItem value={"facebook"}>Facebook</MenuItem>
                    <MenuItem value={"google"}>Google</MenuItem>
                    <MenuItem value={"website"}>Website</MenuItem>
                    <MenuItem value={"app"}>App</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </Grid>

               
                <Grid item xs={12} md={3}>
                  <h6>
                  Provider <sup>*</sup>{" "}
                  </h6>
                  <Select
                    className="input"
                    name="provider"
                    variant="outlined"
                    value={provider}
                    style={{
                      width: "100%",
                      margin: "0",
                      height: "40px",
                      padding: "1px",
                    }}
                    onChange={(e) => setProvider(e.target.value as String)}
                  >
                    <MenuItem value={"ELISION_BML"}>ELISION_BML</MenuItem>
                    <MenuItem value={"ELISION_GSM"}>ELISION_GSM</MenuItem>
                    <MenuItem value={"AMEYOCALL"}>AMEYOCALL</MenuItem>
                    <MenuItem value={"TATATELEOFFLINE"}>
                      TATATELE OFFLINE
                    </MenuItem>
                    <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                  </Select>
                </Grid>
                
                <Grid item xs={12} md={3}>
                  <h6>Skills</h6>
                  <TextField
                    className="input"
                    name="title"
                    type="text"
                    placeholder="Enter Skills"
                    value={skills}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setSkills(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Marketing Number</h6>
                  <TextField
                    className="input"
                    name="marketing_phonenumber"
                    type="text"
                    placeholder="Enter Marketing Number"
                    value={marketingNumber}
                    variant="outlined"
                    onChange={(e) => setMarketingNumber(e.target.value as String)}
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>
                    Purpose
                  </h6>
                  <TextField
                    className="input"
                    name="employee_id"
                    type="text"
                    placeholder="Enter Purpose"
                    value={purpose}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setPurpose(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <h6>
                    Lead Source <sup>*</sup>{" "}
                  </h6>
                  <Autocomplete
                    id="leadsource"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setLeadSource(obj.name);
                      }
                    }}
                    options={lead_source || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => option && option?.name}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getLeadSource(`?code=${newInputValue}`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setLeadSource(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Lead Source"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <h6>GCL ID</h6>
                  <TextField
                    className="input"
                    name="title"
                    type="text"
                    placeholder="Enter GCL ID"
                    value={gclId}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setGclId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <h6>Promotional URL</h6>
                  <TextField
                    className="input"
                    name="title"
                    type="text"
                    placeholder="Enter URL"
                    value={url}
                    variant="outlined"
                    style={{ margin: "0", width: "100%", padding: "1px" }}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h6>Is Active</h6>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={isactive}
                    label="IsActive"
                    onChange={(e) => setIsActive(!isactive)}
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonContainer}>
                <Button color="primary" type="submit" variant="contained">
                  Create Dialer
                </Button>
              </div>
            </form>
          </div>
        </Paper>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  lead_source: state.TechSupportReducer.lead_source,
});

export default connect(mapStateToProps, {
  getLeadSource,
  createDialer,
})(BlogsPage);
