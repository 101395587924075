//Authentication
import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Pusher from "pusher-js";
import { Link } from "react-router-dom";

import Modal from "../components/Modal";
import "./index.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";
import MainLayout from "../Hoc/MainLayout";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import SecondModal from "../components/SecondModal";
import Dash from "../containers/dash";
import { useEffect } from "react";
import { checkUser } from "../actions/loginActions";
import { messageBox } from "../actions/notificationAction";
import { ACTIONS } from "../interfaces/actionTypes/loginTypes";
import { connect } from "react-redux";

// Tech Support
import TsAgentDash from "../containers/TechSupport-agent/index";
import TsNewBooking from "../TechSupport-Panel/components/Bookings/BookingPage";
import TsLeadAdminSubAdminPage from "../TechSupport-Panel/components/SubAdmin/index";
import TsManageHierarchy from "../TechSupport-Panel/components/SubAdmin/manageHierarchy";
import TsOtherUsers from "../TechSupport-Panel/components/SubAdmin/manageOtherUser";
import TsLeadAdminCreateUser from "../TechSupport-Panel/components/SubAdmin/CreateUser";
import TsBookingExport from "../TechSupport-Panel/pages/exportBooking";
import TsBookingsPage from "../TechSupport-Panel/pages/filter";
import TsViewBooking from "../TechSupport-Panel/components/Bookings/ViewBooking/index";
import TsHelpDesk from "../TechSupport-Panel/pages/HelpDesk";
import TsCallRecordingBackup from "../TechSupport-Panel/tables/callDetails";
import TsManagePhlebo from "../TechSupport-Panel/tables/managePhlebos";
import TsLeadExport from "../TechSupport-Panel/pages/exportLead";
import TsVerifiedBooking from "../TechSupport-Panel/tables/bookingVerificationTable";
import TsLeadsPage from "../TechSupport-Panel/pages/LeadsPage";
import TsTestpackagreView from "../TechSupport-Panel/pages/testPackages";
import TsAuditScore from "../TechSupport-Panel/pages/dispositions";
import TsUserDetails from "../TechSupport-Panel/tables/UserDetails";
import TsLeadEdit from "../TechSupport-Panel/pages/fill-info";
import TsSampleHandoverPage from "../TechSupport-Panel/tables/sampleHandOverTable";
import TsResetUserPassword from "../TechSupport-Panel/pages/UserRestPassword";
import TsdoctorConsultation from "../TechSupport-Panel/pages/doctorConsultationFilter";
import TsManageZones from "../TechSupport-Panel/components/ManageZones/index";
import TsBlacklistedNumber from "../TechSupport-Panel/pages/blacklistedNumbers";
import TsCampDetails from "../TechSupport-Panel/components/DialerSetting/CampDetails";
import TsCreateDialer from "../TechSupport-Panel/components/DialerSetting/createDialer";
import TsCreateCityAlias from "../TechSupport-Panel/components/CityAlias-Master/index";
import TsLeadDetails from "../TechSupport-Panel/tables/leads";
import TsBulkBooking from "../TechSupport-Panel/pages/synBookingWithEs";
import TsCallDetails from "../TechSupport-Panel/tables/callDetails";
import TsEditBookingForm from "../TechSupport-Panel/components/Bookings/EditBookingForm/EditBookingForm";
import TsAddAditionalFamily from "../TechSupport-Panel/components/Bookings/NewBooking/AddFamilyMember";
import TsTRFDownload from "../TechSupport-Panel/components/Bookings/DownloadBooking/Trf";
import TsCreatePartner from "../TechSupport-Panel/components/ManagePartner/CreatePartner";
import TsCreateAlias from "../TechSupport-Panel/components/Alias-Master/index";
import TsSearchResultsMain from "../TechSupport-Panel/components/search/SearchResult";
import TsPartnerIndex from "../TechSupport-Panel/components/ManagePartner/index";
import TsCentersIndex from "../TechSupport-Panel/components/ManageCenters/index";
import TsPanelUserComment from "../TechSupport-Panel/components/panelUserComment/index";
import TsEditPhleboUser from "../TechSupport-Panel/components/CreatePhlebo/editPhleboDetails";
import TsEditUserDetails from "../TechSupport-Panel/components/SubAdmin/editUserDetails";
import TsCreateLab from "../TechSupport-Panel/components/ManageLab";
import TsResetPass from "../TechSupport-Panel/pages/rsetPassword";
import TsImagingEditBookingForm from "../TechSupport-Panel/components/Bookings/EditBookingForm/ImagingEditBooking";
import TsPinCodePage from "../TechSupport-Panel/components/PinCode";
import TsMergeUHID from "../TechSupport-Panel/pages/mergeuhid";
import TsEditPartner from "../TechSupport-Panel/components/ManagePartner/editPartner";
import TsPartnerCreation from "../TechSupport-Panel/pages/Partner-Creation/index";
import FinanceCMS from "../TechSupport-Panel/pages/cms/index";
import FinanceEditCms from "../TechSupport-Panel/pages/cms/editCMS";
import FinanceCMSBatch from "../TechSupport-Panel/pages/cms/batch";
import FinanceAdvPayment from "../TechSupport-Panel/pages/cms/advancePayment";
import CreateLabs from "../TechSupport-Panel/components/SubAdmin/CreateLabs";
import CreateLabMaster from "../TechSupport-Panel/components/SubAdmin/CreateLabMaster";
import TsCenterCreation from "../TechSupport-Panel/pages/Center-Creation/index";
import TsCenterCreationOLC from "../TechSupport-Panel/pages/Center-Creation/index2";
import TsCenterCreationDSA from "../TechSupport-Panel/pages/Center-Creation/CcDsa";
import TsCentreLabOrg from "../TechSupport-Panel/pages/CentreLabOrg/index";
import TsLeadUnblock from "../TechSupport-Panel/pages/Lead-Unblock/index";
import TsSampleCollection from "../TechSupport-Panel/components/Sample-Collection/index";
import TsUserLabRights from "../TechSupport-Panel/pages/UserLabRights/index";
import SampleUnregisterBooking from "../TechSupport-Panel/tables/unregisteredBoking";

var jwt = require("jsonwebtoken");

function Routes(props) {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }

  return (
    <BrowserRouter>
      <NotificationContainer />
      <MainLayout>
        <SnackBar />
        <Switch>
          <Route path="/" exact component={Login} />
          <Route
            component={() => {
              const token = SecureStorage.getItem("token");
              const decoded = jwt.decode(token);
              if (decoded.user_group === "TechSupport") {
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/ts"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <TsAgentDash>
                              <Route
                                exact
                                path="/dashboard/ts"
                                component={TsBookingsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/booking-view/:id"
                                component={TsViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/calls_recording_backup"
                                component={TsCallRecordingBackup}
                              />

                              <Route
                                exact
                                path="/dashboard/ts/booking"
                                component={TsNewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/users"
                                component={TsLeadAdminSubAdminPage}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/manageHierarchy"
                                component={TsManageHierarchy}
                              />
                              <Route
                                path="/dashboard/ts/create_lab_master"
                                exact
                                component={CreateLabs}
                              />
                              <Route
                                path="/dashboard/ts/create_lab_master/:id"
                                exact
                                component={CreateLabMaster}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/manageOtherUsers"
                                component={TsOtherUsers}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/users/create"
                                component={TsLeadAdminCreateUser}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/bookings/export"
                                component={TsBookingExport}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/leads/export"
                                component={TsLeadExport}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/fill-info"
                                component={TsLeadEdit}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/sample_receiving_list"
                                component={TsSampleHandoverPage}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/resetpassword"
                                component={TsResetUserPassword}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/sample-collection"
                                component={TsSampleCollection}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/blacklistednumbers"
                                component={TsBlacklistedNumber}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/verifiedbooking"
                                component={TsVerifiedBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/campaigns"
                                component={TsCampDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/leads"
                                component={TsLeadsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/testPackages"
                                component={TsTestpackagreView}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/auditscore"
                                component={TsAuditScore}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/userdetails"
                                component={TsUserDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/helpdesk"
                                component={TsHelpDesk}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/managephlebos"
                                component={TsManagePhlebo}
                              />
                              <Route
                                path="/dashboard/ts/doctor_consultation"
                                exact
                                component={TsdoctorConsultation}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/zones"
                                component={TsManageZones}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/createDialer"
                                component={TsCreateDialer}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/CityAlias-Master"
                                component={TsCreateCityAlias}
                              />
                              <Route
                                path="/dashboard/ts/Leadtable"
                                exact
                                component={TsLeadDetails}
                              />
                              <Route
                                path="/dashboard/ts/bulk_booking"
                                exact
                                component={TsBulkBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/calls_recording"
                                component={TsCallDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/booking-edit/:id/edit"
                                component={TsEditBookingForm}
                              />
                              <Route
                                path="/dashboard/ts/booking-additionalFamily/:id/add"
                                exact
                                component={TsAddAditionalFamily}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/download/:id/download"
                                component={TsTRFDownload}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/partner/create"
                                component={TsCreatePartner}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/Alias"
                                component={TsCreateAlias}
                              />
                              <Route
                                path="/dashboard/ts/lead/search"
                                exact
                                component={TsSearchResultsMain}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/partner/index"
                                component={TsPartnerIndex}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/centers"
                                component={TsCentersIndex}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/panel-user-comment"
                                component={TsPanelUserComment}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/phlebo/:id"
                                component={TsEditPhleboUser}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/userDetails/:id/:namePath"
                                component={TsEditUserDetails}
                              />
                              <Route
                                path="/dashboard/ts/lab_master"
                                exact
                                component={TsCreateLab}
                              />
                              <Route
                                path="/dashboard/ts/reset_pass"
                                exact
                                component={TsResetPass}
                              />
                              <Route
                                path="/dashboard/ts/booking-imaging-edit/:id/edit"
                                exact
                                component={TsImagingEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/pincodes"
                                component={TsPinCodePage}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/mergeuhid"
                                component={TsMergeUHID}
                              />
                              <Route
                                exact
                                path="/dashboard/ts/partner/edit/:id"
                                component={TsEditPartner}
                              />
                              <Route
                                path="/dashboard/ts/PartnerCreation"
                                exact
                                component={TsPartnerCreation}
                              />
                              <Route
                                path="/dashboard/ts/cms"
                                exact
                                component={FinanceCMS}
                              />
                              <Route
                                path="/dashboard/ts/edit_cms/:id"
                                exact
                                component={FinanceEditCms}
                              />
                              <Route
                                path="/dashboard/ts/batch/:id"
                                exact
                                component={FinanceCMSBatch}
                              />
                              <Route
                                path="/dashboard/ts/batch/:id"
                                exact
                                component={FinanceAdvPayment}
                              />
                              <Route
                                path="/dashboard/ts/centerCreationModal"
                                exact
                                component={TsCenterCreation}
                              />
                              <Route
                                path="/dashboard/ts/centerCreationModalDSA"
                                exact
                                component={TsCenterCreationDSA}
                              />
                              <Route
                                path="/dashboard/ts/centerCreationModalOLC"
                                exact
                                component={TsCenterCreationOLC}
                              />
                              <Route
                                path="/dashboard/ts/centre_lab_org"
                                exact
                                component={TsCentreLabOrg}
                              />
                              <Route
                                path="/dashboard/ts/leadUnblock"
                                exact
                                component={TsLeadUnblock}
                              />
                              <Route
                                path="/dashboard/ts/unregister-booking"
                                exact
                                component={ SampleUnregisterBooking }
                              />

                              <Route
                                path="/dashboard/ts/user_lab_rights"
                                exact
                                component={TsUserLabRights}
                              />
                            </TsAgentDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                );
              } else {
                return <Redirect to="/" />;
              }
            }}
          />
        </Switch>
        {/* <Modal /> */}
        {/* <SecondModal /> */}
      </MainLayout>
    </BrowserRouter>
  );
}

export default connect(null, { checkUser, messageBox })(Routes);
