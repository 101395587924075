const testList = [
    {
      id: 23103,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Iron-Studies--Test",
      name: "Iron Studies  Test",
      parameter: "4",
      description: "Iron, TIBC, UIBC, % Saturation",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 199,
      offer_price: 199,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD012",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Iron-Studies--Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23102,
      category_for_web: "package.CategoryForWeb.None",
      slug: "IgE-Total--Immunoglobulin-IgE--Test",
      name: "IgE Total (Immunoglobulin IgE) Test",
      parameter: "1",
      description: "IgE Total (Immunoglobulin IgE)",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 299,
      offer_price: 299,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD011",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "IgE-Total--Immunoglobulin-IgE--Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23101,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Rheumatoid-Factor-Quantitative--RA-Factor--Test",
      name: "Rheumatoid Factor Quantitative (RA Factor) Test",
      parameter: "1",
      description: "Rheumatoid Factor Quantitative (RA Factor)",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 249,
      offer_price: 249,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD010",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Rheumatoid-Factor-Quantitative--RA-Factor--Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23100,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Vitamin-B12--Test",
      name: "Vitamin B12  Test",
      parameter: "1",
      description: "Vitamin B12",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 249,
      offer_price: 249,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD009",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Vitamin-B12--Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23099,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Vitamin-D-25-Hydroxy-Test",
      name: "Vitamin D 25 Hydroxy Test",
      parameter: "1",
      description: "Vitamin D 25 Hydroxy",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: null,
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 299,
      offer_price: 299,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD008",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Vitamin-D-25-Hydroxy-Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23098,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Ferritin--Test",
      name: "Ferritin  Test",
      parameter: "1",
      description: "Ferritin",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 249,
      offer_price: 249,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD007",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Ferritin--Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23097,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Testosterone-Total--Test",
      name: "Testosterone Total  Test",
      parameter: "1",
      description: "Testosterone Total",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 249,
      offer_price: 249,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD006",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Testosterone-Total--Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23096,
      category_for_web: "package.CategoryForWeb.None",
      slug: "CRP-Test--C-Reactive-Protein-",
      name: "CRP Test (C Reactive Protein)",
      parameter: "1",
      description: "C-Reactive Protein (CRP), Quantitative",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 199,
      offer_price: 199,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD005",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "CRP-Test--C-Reactive-Protein-",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23095,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Blood-Group--Test",
      name: "Blood Group  Test",
      parameter: "2",
      description: "Blood Group",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 99,
      offer_price: 99,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD004",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Blood-Group--Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23094,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Lipid-Profile--Test",
      name: "Lipid Profile  Test",
      parameter: "9",
      description:
        "Total Cholesterol, Triglycerides, LDL Cholesterol (Calculated), HDL Cholesterol, VLDL Cholesterol, Non HDL Cholesterol, Chol/HDL Ratio, HDL/LDL Ratio, LDL/HDL Ratio",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "10-12 Hrs Fasting Required",
      discount: null,
      package_price: 199,
      offer_price: 199,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD003",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Lipid-Profile--Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23093,
      category_for_web: "package.CategoryForWeb.None",
      slug: "HbA1c-Test",
      name: "HbA1c Test",
      parameter: "2",
      description: "HbA1C (Glycosylated Hemoglobin)",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 199,
      offer_price: 199,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD002",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "HbA1c-Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
    {
      id: 23092,
      category_for_web: "package.CategoryForWeb.None",
      slug: "Thyroid-Profile-Total-Test",
      name: "Thyroid Profile Total Test",
      parameter: "3",
      description: "T3, T4, TSH Ultrasensitive",
      details: null,
      faq: null,
      recommended_gender: true,
      recommended_age: "Above 5 Years",
      tat_time: null,
      is_premium: false,
      fasting_time: "No fasting Required",
      discount: null,
      package_price: 199,
      offer_price: 199,
      is_addon: false,
      meta_title: null,
      meta_description: null,
      is_active: false,
      code: "ADD001",
      og_tag: null,
      twitter_card: null,
      addon_price: null,
      lpslug: "Thyroid-Profile-Total-Test",
      meta_keyword: null,
      specimen_instructions: null,
      text_criteria: null,
      home_collection: true,
      best_package_app: false,
      website_display: false,
      scratch_code: null,
      content: null,
      qna: null,
      org_type: "homedx",
      ptype: "CC",
      no_patients: null,
      package_or_test: "test",
      sample_report: "",
      package_city_prices: null,
    },
  ];
  const testListDev = [
    {
      id: 16242,
      name: "Thyroid Profile Total Test",
      code: "ADD001",
      discount: null,
      package_price: 199.0,
      fasting_time: "No fasting Required",
      specimen_instructions: "",
      slug: "thyroid-profile-total-test",
      test: null,
      parameter: 0,
      offer_price: 199.0,
      addon_price: null,
      packagetube: [],
      tat_time: null,
      package_city_prices: null,
      is_addon: false,
      description: "T3, T4, TSH Ultrasensitive",
      tat_time_duration: "2 00:00:00",
      no_patients: null,
      smart_report_available: false,
      sample_report: null,
    },
    {
      id: 16246,
      name: "HbA1c Test",
      code: "ADD002",
      discount: null,
      package_price: 199.0,
      fasting_time: "No fasting Required",
      specimen_instructions: "",
      slug: null,
      test: null,
      parameter: 0,
      offer_price: 199.0,
      addon_price: null,
      packagetube: [],
      tat_time: null,
      package_city_prices: null,
      is_addon: false,
      description: "HbA1C (Glycosylated Hemoglobin)",
      tat_time_duration: "2 00:00:00",
      no_patients: null,
      smart_report_available: false,
      sample_report: null,
    },
  ];
  
  export default process.env.REACT_APP_ENV === "production" ? testList : testListDev;