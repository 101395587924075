import {
  ACTIONS,
  QualityExecutiveTypes,
} from "../interfaces/actionTypes/TechSupportType";

type InitialState = {
  assigneUserList: Array<any>;
  pin_code: Array<any>;
  cities: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  packageList: Array<any>;
  checkGeoFenceStatus: boolean;
  couponList: Array<any>;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  userTableData: Array<any>;
  ozontelskilldata: Array<any>;
  comment: any;
  data: any;
  users: any;
  managehierarchyList: any;
  bookingsComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  all_recordings_backup: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  search_results: object;
  call_status: object;
  all_lead: object;
  call_transfer: object;
  user_panel: object;
  agentBYUserGroup: object;
  callRemark: object;
  loading: boolean;
  arealoading: boolean;
  breakData: Array<any>;
  verificationBookingDetails: Array<any>;
  coupon: Object;
  unmaskedNumber: Array<any>;
  additional_booking: object;
  mapmyindiaAccessToken: Array<any>;
  leadStatus: Array<any>;
  prescription: Array<any>;
  all_lead_tatataly: object;
  addressType: Array<any>;
  notificationMessage: Array<any>;
  userDetails: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  qualityScoringReport: Array<any>;
  get_calls_data: Array<any>;
  post_quality_score: Array<any>;
  get_quality_scoring_report_for_backup: Array<any>;
  centerInfo: Array<any>;
  zone: Object;
  timeslotsList: Object;
  updatedPanelUser: Object;
  failedReport: Array<any>;
  blogList: Object;
  blog: Object;
  blogBySlug: Object;
  blogCategories: Object;
  blogTags: Object;
  blogImages: Object;
  pack: Object;
  cityPriceList: Object;
  attendenceList: Object;
  autoAssignStatus: boolean;
  createBooking: Object;
  rcFreshLead: Array<any>;
  packageTags: Array<any>;
  incorrectNumberLeads: Array<any>;
  createPanelUser: object;
  labCredential: Array<any>;
  updateLabCredential: Array<any>;
  dispositions: object;
  dispositionData: Array<any>;
  zoneComment: any;
  sameNumberBooking: boolean;
  paymentSendLink: Array<any>;
  paymentReSendLink: Array<any>;
  paymentCancelLink: Array<any>;
  bookingListWithTatTime: Array<any>;
  additionalBookingListWithTatTime: Array<any>;
  smsReportSend: Array<any>;
  todayCallDetails: object;
  updateUserStatus: object;
  topRecord: object;
  phleboCurrentLocation: Array<any>;
  smsDetails: Array<any>;
  manualreportsend: Array<any>;
  emailDetails: Array<any>;
  reportStatus: Array<any>;
  labDetails: Array<any>;
  reportDownload: Array<any>;
  panelUserList: Array<any>;
  language: Array<any>;
  dialer: Array<any>;
  orgTypesList: Array<any>;
  chatSkillsList: Array<any>;
  usersList: Array<any>;
  otherUsersList: Array<any>;
  mapBooking: Array<any>;
  userListData: Array<any>;
  panelComments: Array<any>;
  assignedPanel: object;
  allBookings: object;
  bookingJourney: Array<any>;
  agentdata: Array<any>;
  todayMissedCallsList: Array<any>;
  individualWhatsappChat: Array<any>;
  currentBooking: Array<any>;
  cityWisePhlebos: Array<any>;
  logistictatdata: Array<any>;
  verifiedbylist: Array<any>;
  additionMember: Array<any>;
  qrcode: Array<any>;
  signature: Array<any>;
  packageCategory: Array<any>;
  sampleHandOverData: Array<any>;
  sampleJourneyComments: Object;
  collectionSlot: Array<any>;
  redCoin: Array<any>;
  subCenter: Array<any>;
  reportData: Array<any>;
  healthTestParameter: Array<any>;
  parameterCount: Array<any>;
  imagingTimeSlots: Array<any>;
  createIMGBooking: object;
  multiplePackage: Array<any>;
  displayCities: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  bookingCall: Array<any>;
  salesLeadList: Array<any>;
  teamLeadList: Array<any>;
  phlebo_rider_due: Array<any>;
  imagingTicket: Array<any>;
  imagingTicketcount: Array<any>;
  topRcFreshLeads: object;
  imaginraiseTicket: Array<any>;
  paymentRefundList: Array<any>;
  performanceData: Array<any>;
  dashboardOverview: Object;
  batchbookingamount: Array<any>;
  batchPayment: Object;
  userloading: boolean;
  updatePhlebos: Object;
  phleboComment: any;
  zoneList: Object;
  createPhlebo: Object;
  phleboPhone: Object;
  paymentInformation: Array<any>;
  riderList: Array<any>;
  disposition: Array<any>;
  bookingComplaints: Array<any>;
  ticketLoader: boolean;
  ticketCommentsForDocuments: Array<any>;
  ticketComments: Array<any>;
  userData: Array<any>;
  dispositionList: Array<any>;
  blacklistedNumber: Array<any>;
  bookingListLoader: boolean;
  campaingsDetails: object;
  assignedloader: boolean;
  packageComments: Array<any>;
  cityAlias: Array<any>;
  cityComments: Array<any>;
  phleboList: Array<any>;
  phleboListAuto: Array<any>;
  phleboData: Array<any>;
  ltvLead: Object;
  whatsappTemplate: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  geoCheckError: string;
  geoLoading: boolean;
  centreData: Object;
  usersData: Object;
  packageAlias: Array<any>;
  bookinSubDisposition: Array<any>;
  panelUserComment: Array<any>;
  phleboTimeline: Array<any>;
  locationVerified: Array<any>;
  addressDetailsLatLong: Array<any>;
  leadSourceData: Array<any>;
  agentsListnewdata: Array<any>;
  userEditData: Array<any>;
  partnerList: Array<any>;
  partnerData: Array<any>;
  labLogs: Array<any>;
  getCenterData: Array<any>;
  paginationComment: Array<any>;
  Phlebosloader: boolean;
  otherUserList: Array<any>;
  userTypesList: Array<any>;
  area: Object;
  areaComments: Array<any>;
  partnerLoading: boolean;
  panelInLab: Array<any>;
  syncData: Object;
  syncLoading: boolean;
  get_booking_uhid: Array<any>;
  unScannedTube: Array<any>;
  tubeByBooking: Array<any>;
  receivedPackages: Array<any>;
  validBarcode: Object;
  getLabBarcode: object;
  barcodeStatus: Object;
  get_cms_list_II: Array<any>;
  cmsWalletData: Array<any>;
  fetchCmsList: Array<any>;
  fetchbatchCms: Array<any>;
  fetchCmsAdvPayment: Array<any>;
  manualSettlePayment: Array<any>;
  bookingListLoading: boolean;
  createCenter: object;
  bankName: Array<any>;
  salesExecutive: Array<any>;
  salesManager: Array<any>;
  identityProofData: Array<any>;
  centre_cities: Array<any>;
  paymentModeList: Array<any>;
  rateListFilter: Array<any>;
  tagProccessingLab: Array<any>;
  centreCode: Object;
  uploadCentresData: Object;
  centers: Array<any>;
  get_business_location: Array<any>;
  get_cc_consumption: Array<any>;
  cc_to_inv: Array<any>;
  ifscData: Object;
  get_mrr_sales: Array<any>;
  mmr_sales_loading: boolean;
  territoryData: Array<any>;
  center_loading: boolean;
  centerComment: Array<any>;
  stateList: Array<any>;
  labOrgCode: Object;
  centreLabOrg: Array<any>;
  blockUser: Array<any>;
  bookingsByCentre: object;
  labUser: Array<any>;
  unregisterBooking: Array<any>;
  unregisterBookingStatus: Array<any>;
  biomarkerScreening: Array<any>;
  biomarkerLoader: boolean;
  doctorConsultationBooking: Array<any>;
  bookingIdLoader: boolean;
  reportLoading: boolean;
  newLeadDetails: Array<any>;
  ticketTimeline: Array<any>;
  customerSpecificCouponData: Array<any>;
  resamplingBooking: object;
  hivpackageList: Array<any>;
  bookingOtp: object;
  phleboSelfie: Array<any>;
  invoiceData: Object;
  bookingDocumentList: Array<any>;
  sampleImage: Array<any>;
  viewPackageAnswer: Array<any>;
  viewPackageAnswerAllergy: Array<any>;
  bookingInvoie: Array<any>;
  phleboRedocrding: Array<any>;
  genomicsFormForGet: Array<any>;
  vipActivationStatus: Array<any>;
  couponAutoApplied: Array<any>;
  uniqueCustomerData: Array<any>;
  packageQuestion: Array<any>;
  containerSlots: Array<any>;
  postRefReducer: Array<any>;
  timeslots: Array<any>;
  refDoctor: Array<any>;
  leadSubscriptionsData: Array<any>;
  digitalPrescriptionListWithBooking:Array<any>;
  sendDigitalPrescriptionToCustomer:Array<any>;
  failedReportById:object;
  whatsappStatusMessageId: Array<any>;
 whatsAppDetailsLoader: boolean;
whatsAppComunicationDetails: Array<any>;
  get_labs: Array<any>;
  get_show_all_store_locations: Array<any>;
  create_user: Array<any>,
  get_inventory_users: Array<any>;
  set_location_rights_thru_user: Array<any>;
  get_locations_opt: Array<any>;
  get_api_logs_for_passed_mango: Array<any>;
  post_or_update_store_location: Array<any>;
  get_store_location_or_filter_opt: Array<any>;
  GET_STORE_LOCATION_OR_FILTER: Array<any>;
  get_store_location_opt: Array<any>;
  get_full_store_location: Array<any>;
  get_lab_and_hlm: Array<any>;
  get_store_location_filter: Array<any>;
  get_single_centre: Array<any>;
  get_location_II: Array<any>;
  get_item_master_for_map_items:Array<any>;
  get_item_type:Array<any>;
  get_machine:Array<any>;
  get_full_item_master:Array<any>;
  get_all_tagged_items_to_location:Array<any>;
  get_store_permitted_items_opt:Array<any>;
  userInfo:object;
  get_store_location:Array<any>;
  get_item_master:Array<any>;
  get_vendor_master:Array<any>;
  post_po: Array<any>;
  add_items_to_po: Array<any>;
  get_po_or_filter: Array<any>;
  get_pi_indents:Array<any>;
  get_quotation_for_creating_po:Array<any>;
  get_quotation_items_thru_vendor_and_reciept_id:Array<any>;
  get_full_po:Array<any>;
  post_or_update_po:Array<any>;
  get_currency_master:Array<any>;
  get_vendors_thru_item:Array<any>;
  post_po_against_pi:Array<any>;
  get_inv_super_admin_users:Array<any>;
  get_api_logs_for_passed_model:Array<any>;
  get_company_master:Array<any>;
  get_quotation_for_currency_validation:Array<any>;
  get_items_through_location:Array<any>;
  get_new_item_master_or_filter: Array<any>;
  post_raise_indent: Array<any>;
  get_indents: Array<any>;
  get_indent_items: Array<any>;
  post_or_update_indent_status_for_raise_indent: Array<any>;
  get_indent_issue_or_filter_for_raise_indent:Array<any>;
  get_manufacture:Array<any>;
  get_all_locations:Array<any>;
  get_company:Array<any>;
  get_grn_or_filter: Array<any>;
  get_full_grn: Array<any>;
  get_item_master_or_filter: Array<any>;
};

const initialState: InitialState = {
  users: {},
  batchbookingamount: [],
  updatedPanelUser: {},
  imaginraiseTicket: [],
  assignedPanel: {},
  topRcFreshLeads: {},
  prescription: [],
  areaList: [],
  area: {},
  zone: {},
  timeslotsList: {},
  allBookings: {},
  blogList: {},
  blog: {},
  blogBySlug: {},
  blogCategories: {},
  blogTags: {},
  blogImages: {},
  coupon: {},
  pack: {},
  cityPriceList: {},
  additional_booking: {},
  attendenceList: {},
  autoAssignStatus: false,
  loading: false,
  arealoading: false,
  pin_code: [],
  packageTags: [],
  currentBooking: [],
  cities: [],
  agentsList: [],
  rcFreshLead: [],
  packageList: [],
  couponList: [],
  qrcode: [],
  createBooking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  leads: [],
  bookingList: [],
  comment: {},
  data: {},
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  complaint: {},
  complaints: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  updateUserStatus: {},
  call_status: {},
  all_lead: {},
  mapBooking: [],
  all_lead_tatataly: {},
  call_transfer: {},
  agentBYUserGroup: {},
  user_panel: {},
  updateLabCredential: [],
  dispositions: {},
  zoneComment: {},
  incorrectNumberLeads: [],
  sameNumberBooking: false,
  failedReport: [],
  dispositionData: [],
  cityWisePhlebos: [],
  paymentSendLink: [],
  paymentReSendLink: [],
  paymentCancelLink: [],
  smsReportSend: [],
  breakData: [],
  assigneUserList: [],
  todayCallDetails: {},
  topRecord: {},
  verificationBookingDetails: [],
  smsDetails: [],
  emailDetails: [],
  reportStatus: [],
  centerInfo: [],
  labDetails: [],
  notificationMessage: [],
  userDetails: [],
  reportDownload: [],
  phleboCurrentLocation: [],
  panelUserList: [],
  language: [],
  dialer: [],
  orgTypesList: [],
  chatSkillsList: [],
  usersList: [],
  otherUsersList: [],
  createPanelUser: {},
  userListData: [],
  areaComments: [],
  panelComments: [],
  userTableData: [],
  ozontelskilldata: [],
  collectionSlot: [],
  unmaskedNumber: [],
  agentdata: [],
  manualreportsend: [],
  bookingJourney: [],
  individualWhatsappChat: [],
  todayMissedCallsList: [],
  bookingListWithTatTime: [],
  additionalBookingListWithTatTime: [],
  checkGeoFenceStatus: false,
  verifiedbylist: [],
  additionMember: [],
  signature: [],
  packageCategory: [],
  sampleHandOverData: [],
  sampleJourneyComments: {},
  redCoin: [],
  subCenter: [],
  reportData: [],
  healthTestParameter: [],
  parameterCount: [],
  leadStatus: [],
  imagingTimeSlots: [],
  createIMGBooking: {},
  multiplePackage: [],
  displayCities: [],
  mapmyindiaAccessToken: [],
  geoFencesMapMyIndia: [],
  addressType: [],
  bookingCall: [],
  salesLeadList: [],
  teamLeadList: [],
  phlebo_rider_due: [],
  imagingTicket: [],
  imagingTicketcount: [],
  paymentRefundList: [],
  performanceData: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  dashboardOverview: {},
  logistictatdata: [],
  qualityScoringReport: [],
  get_quality_scoring_report_for_backup: [],
  batchPayment: [],
  callRemark: {},
  all_recordings_backup: {},
  get_calls_data: [],
  post_quality_score: [],
  userloading: false,
  updatePhlebos: {},
  phleboComment: {},
  zoneList: {},
  createPhlebo: {},
  phleboPhone: {},
  paymentInformation: [],
  riderList: [],
  disposition: [],
  bookingComplaints: [],
  ticketLoader: false,
  ticketCommentsForDocuments: [],
  ticketComments: [],
  userData: [],
  dispositionList: [],
  blacklistedNumber: [],
  bookingListLoader: false,
  bookingListLoading: false,
  campaingsDetails: {},
  assignedloader: false,
  packageComments: [],
  cityComments: [],
  cityAlias: [],
  phleboList: [],
  phleboListAuto: [],
  phleboData: [],
  ltvLead: {},
  whatsappTemplate: [],
  sendLocationOnWhatsapp: [],
  geoCheckError: "",
  geoLoading: false,
  centreData: {},
  usersData: {},
  packageAlias: [],
  bookinSubDisposition: [],
  panelUserComment: [],
  phleboTimeline: [],
  locationVerified: [],
  addressDetailsLatLong: [],
  leadSourceData: [],
  agentsListnewdata: [],
  userEditData: [],
  labCredential: [],
  labLogs: [],
  getCenterData: [],
  paginationComment: [],
  managehierarchyList: [],
  Phlebosloader: false,
  otherUserList: [],
  userTypesList: [],
  partnerList: [],
  partnerData: [],
  partnerLoading: false,
  panelInLab: [],
  syncData: [],
  syncLoading: false,
  get_booking_uhid: [],
  unScannedTube: [],
  tubeByBooking: [],
  receivedPackages: [],
  validBarcode: {},
  getLabBarcode: {},
  barcodeStatus: {},
  get_cms_list_II: [],
  cmsWalletData: [],
  fetchCmsList: [],
  fetchbatchCms: [],
  fetchCmsAdvPayment: [],
  manualSettlePayment: [],
  createCenter: {},
  bankName: [],
  salesExecutive: [],
  salesManager: [],
  identityProofData: [],
  centre_cities: [],
  paymentModeList: [],
  rateListFilter: [],
  tagProccessingLab: [],
  centreCode: {},
  uploadCentresData: {},
  centers: [],
  get_business_location: [],
  get_cc_consumption: [],
  cc_to_inv: [],
  ifscData: {},
  get_mrr_sales: [],
  mmr_sales_loading: false,
  territoryData: [],
  center_loading: false,
  centerComment: [],
  stateList: [],
  labOrgCode: {},
  centreLabOrg: [],
  blockUser: [],
  bookingsByCentre: {},
  labUser: [],
  unregisterBooking: [],
  unregisterBookingStatus: [],
  userInfo: {},
  biomarkerScreening: [],
  biomarkerLoader: false,
  doctorConsultationBooking: [],
  bookingIdLoader: false,
  reportLoading: false,
  newLeadDetails: [],
  ticketTimeline: [],
  customerSpecificCouponData: [],
  resamplingBooking: {},
  hivpackageList: [],
  bookingOtp: {},
  phleboSelfie: [],
  invoiceData: {},
  bookingDocumentList: [],
  sampleImage: [],
  viewPackageAnswer: [],
  viewPackageAnswerAllergy: [],
  bookingInvoie: [],
  phleboRedocrding: [],
  genomicsFormForGet: [],
  vipActivationStatus: [],
  couponAutoApplied: [],
  uniqueCustomerData: [],
  packageQuestion: [],
  containerSlots: [],
  postRefReducer: [],
  timeslots: [],
  refDoctor: [],
  leadSubscriptionsData: [],
  digitalPrescriptionListWithBooking:[],
  sendDigitalPrescriptionToCustomer:[],
  failedReportById:{},
  whatsappStatusMessageId: [],
 whatsAppDetailsLoader: false,
whatsAppComunicationDetails: [],
  get_labs: [],
  get_show_all_store_locations: [],
  create_user: [],
  get_inventory_users: [],
  set_location_rights_thru_user: [],
  get_locations_opt: [],
  get_api_logs_for_passed_mango: [],
  post_or_update_store_location: [],
  get_store_location_or_filter_opt: [],
  GET_STORE_LOCATION_OR_FILTER: [],
  get_store_location_opt: [],
  get_full_store_location: [],
  get_lab_and_hlm: [],
  get_store_location_filter: [],
  get_single_centre: [],
  get_location_II: [],
  get_item_master_for_map_items:[],
  get_item_type:[],
  get_machine:[],
  get_full_item_master:[],
  get_all_tagged_items_to_location:[],
  get_store_permitted_items_opt:[],
  get_store_location:[],
  get_item_master:[],
  get_vendor_master:[],
  post_po: [],
  add_items_to_po: [],
  get_po_or_filter: [],
  get_pi_indents:[],
  get_quotation_for_creating_po:[],
  get_quotation_items_thru_vendor_and_reciept_id:[],
  get_full_po:[],
  post_or_update_po:[],
  get_currency_master:[],
  get_vendors_thru_item:[],
  post_po_against_pi:[],
  get_inv_super_admin_users:[],
  get_api_logs_for_passed_model:[],
  get_company_master:[],
  get_quotation_for_currency_validation:[],
  get_items_through_location:[],
  get_new_item_master_or_filter:[],
  post_raise_indent:[],
  get_indents: [],
  get_indent_items: [],
  post_or_update_indent_status_for_raise_indent: [],
  get_indent_issue_or_filter_for_raise_indent:[],
  get_manufacture:[],
  get_all_locations:[],
  get_company:[],
  get_grn_or_filter: [],
  get_full_grn: [],
  get_item_master_or_filter: [],
};

const TechSupportReducer = (
  state = initialState,
  action: QualityExecutiveTypes
) => {
  switch (action.type) {
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_EDIT_DATA:
      return {
        ...state,
        userEditData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RECORDINGS_BACKUP:
      return {
        ...state,
        all_recordings_backup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_USERGROUP:
      return {
        ...state,
        assigneUserList: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_REMARK_ON_CALL:
      return {
        ...state,
        callRemark: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS_DATA:
      return {
        ...state,
        phleboData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_TYPE_SEATING:
      return {
        ...state,
        addressType: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        tubeByBooking: [],
        receivedPackages: [],
        leadById: {},
        lead_details: {},
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        partnerLoading: false,
      };
    case ACTIONS.GET_PARTNERS_AUTO:
      return {
        ...state,
        partnerData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LIST_LOADING:
      return {
        ...state,
        bookingListLoading: action.payload,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_IN_LAB:
      return {
        ...state,
        panelInLab: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SYNC_DATA:
      return {
        ...state,
        syncData: action.payload,
        syncLoading: false,
      };
    case ACTIONS.SET_SYNC_LOADING:
      return {
        ...state,
        syncLoading: action.payload,
      };
    case ACTIONS.GET_BOOKING_UHID:
      return {
        ...state,
        get_booking_uhid: action.payload,
        loading: false,
      };

    case ACTIONS.RESET_BOOKING_UHID:
      return {
        ...state,
        get_booking_uhid: {},
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_ALL_TUBE:
      return {
        ...state,
        unScannedTube: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TUBE_BY_BOOKING:
      return {
        ...state,
        tubeByBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RECEIVED_PACKAGES:
      return {
        ...state,
        receivedPackages: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VALID_BARCODE:
      return {
        ...state,
        validBarcode: action.payload,
        loading: false,
      };
    case ACTIONS.POST_BARCODE:
      return {
        ...state,
        getLabBarcode: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BARCODE:
      return {
        ...state,
        barcodeStatus: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_AREA_LOADING:
      return {
        ...state,
        arealoading: action.payload,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        loading: false,
        syncLoading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BREAK_DATA:
      return {
        ...state,
        breakData: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_TO_TATATALE:
      return {
        ...state,
        all_lead_tatataly: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT:
      return {
        ...state,
        qualityScoringReport: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALLS_DATA:
      return {
        ...state,
        get_calls_data: action.payload,
        loading: false,
      };
    case ACTIONS.POST_QUALITY_SCORE:
      return {
        ...state,
        post_quality_score: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
      return {
        ...state,
        get_quality_scoring_report_for_backup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        center_loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION_PENDING:
      return {
        ...state,
        center_loading: true,
      };
    case ACTIONS.GET_USER_LIST_DATA:
      return {
        ...state,
        userTableData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_OZONTEL_SKILL_DATA:
      return {
        ...state,
        ozontelskilldata: action.payload,
        loading: false,
      };
    case ACTIONS.SET_USER_LOADING:
      return {
        ...state,
        Userloading: action.payload,
      };
    case ACTIONS.UPDATE_PHLEBOS:
      return {
        ...state,
        updatePhlebos: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_COMMENTS:
      return {
        ...state,
        phleboComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_NEW_PHLEBO:
      return {
        ...state,
        createPhlebo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_BY_PHONE:
      return {
        ...state,
        phleboPhone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_LIST:
      return {
        ...state,
        riderList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_HANDOVER_DATA:
      return {
        ...state,
        sampleHandOverData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS:
      return {
        ...state,
        sampleJourneyComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_BOOKING_AMOUNT:
      return {
        ...state,
        batchbookingamount: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_DISPOSITION:
      return {
        ...state,
        disposition: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        ticketLoader: false,
        loading: false,
      };
    case ACTIONS.SET_NEW_TICKET_LOADING:
      return {
        ...state,
        ticketLoader: action.payload,
      };
    case ACTIONS.GET_TICKET_COMMENTS_FOR_DOCUMENTS:
      return {
        ...state,
        ticketCommentsForDocuments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD:
      return {
        ...state,
        teamLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA:
      return {
        ...state,
        panelUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DIALER:
      return {
        ...state,
        dialer: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ORG_TYPES:
      return {
        ...state,
        orgTypesList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CHAT_SKILLS_LISTS:
      return {
        ...state,
        chatSkillsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_USERS:
      return {
        ...state,
        usersList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_OTHER_USERS:
      return {
        ...state,
        otherUsersList: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATED_PANEL_USER:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case ACTIONS.GET_CAMPAINGS_DETAILS:
      return {
        ...state,
        campaingsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLACKLISTED_NUMBER:
      return {
        ...state,
        blacklistedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DISPOSITION_LIST:
      return {
        ...state,
        dispositionList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslotsList: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING:
      return {
        ...state,
        allBookings: action.payload,
        assignedloader: false,
      };
    case ACTIONS.SET_ASSIGNED_LOADING:
      return {
        ...state,
        assignedloader: action.payload,
      };
    case ACTIONS.GET_MAP_BOOKING_LIST:
      return {
        ...state,
        mapBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_CURRENT_LOCATION:
      return {
        ...state,
        phleboCurrentLocation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_ALIAS:
      return {
        ...state,
        cityAlias: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_COMMENTS:
      return {
        ...state,
        cityComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_COMMENTS:
      return {
        ...state,
        packageComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LTV_LEAD:
      return {
        ...state,
        ltvLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RC_FRESH_LEADS:
      return {
        ...state,
        topRcFreshLeads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TICKET:
      return {
        ...state,
        imagingTicket: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_TEMPLATE:
      return {
        ...state,
        whatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.SET_GEO_CHECK_ERROR:
      return {
        ...state,
        geoCheckError: action.payload,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.GET_CENTER:
      return {
        ...state,
        centreData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DATA:
      return {
        ...state,
        usersData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_ALIAS:
      return {
        ...state,
        packageAlias: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_SUB_DISPOSITION:
      return {
        ...state,
        bookinSubDisposition: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_COMMENT:
      return {
        ...state,
        panelUserComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFIED_BY_LIST:
      return {
        ...state,
        verifiedbyList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_TIMELINE:
      return {
        ...state,
        phleboTimeline: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_CALLS:
      return {
        ...state,
        bookingCall: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_NEW_DATA:
      return {
        ...state,
        agentsListnewdata: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD:
      return {
        ...state,
        salesLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_COMMENT:
      return {
        ...state,
        panelComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_LOG:
      return {
        ...state,
        labLogs: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_LOGS:
      return {
        ...state,
        labLogs: [],
        packageLogs: [],
      };
    case ACTIONS.GET_CENTER_DATA:
      return {
        ...state,
        getCenterData: [],
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TIMESLOTS:
      return {
        ...state,
        imagingTimeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MULTIPLE_PACKAGES:
      return {
        ...state,
        multiplePackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MANAGEHIERARCHY_LIST:
      return {
        ...state,
        managehierarchyList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_OTHERUSER_LIST:
      return {
        ...state,
        otherUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_ORG_CODE:
      return {
        ...state,
        labOrgCode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTRE_LAB_ORG:
      return {
        ...state,
        centreLabOrg: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOCK_USER_FROM_CREATE_BOOKING:
      return {
        ...state,
        blockUser: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_BY_CENTRE:
      return {
        ...state,
        bookingsByCentre: action.payload,
      };
    case ACTIONS.GET_USER_TYPES:
      return {
        ...state,
        userTypesList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA_COMMENTS:
      return {
        ...state,
        areaComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS_AUTO:
      return {
        ...state,
        phleboListAuto: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAGINATION_COMMENT:
      return {
        ...state,
        paginationComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MANUAL_SETTLE_PAYMENT:
      return {
        ...state,
        manualSettlePayment: action.payload,
        loading: false,
      };
    // case ACTIONS.SET_PARTNER_LOADING:
    //   return {
    //     ...state,
    //     partnerLoading: action.payload,
    //   };
    case ACTIONS.GET_CMS_LIST_II:
      return {
        ...state,
        get_cms_list_II: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CMS_WALLET:
      return {
        ...state,
        cmsWalletData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CMS_LIST:
      return {
        ...state,
        fetchCmsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_FOR_CMS:
      return {
        ...state,
        fetchbatchCms: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CMS_ADV_PAYMENT:
      return {
        ...state,
        fetchCmsAdvPayment: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_NEW_CENTER:
      return {
        ...state,
        createCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BANK_NAME:
      return {
        ...state,
        bankName: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SALES_EXECUTIVE:
      return {
        ...state,
        salesExecutive: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SALES_MANAGER:
      return {
        ...state,
        salesManager: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IENTITY_PROOF_DATA:
      return {
        ...state,
        identityProofData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTRE_CITIES:
      return {
        ...state,
        centre_cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_MODE_LIST:
      return {
        ...state,
        paymentModeList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RATELIST_FILTER:
      return {
        ...state,
        rateListFilter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TAG_PROCCESSING_LAB:
      return {
        ...state,
        tagProccessingLab: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTRE_CODE:
      return {
        ...state,
        centreCode: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_CENTRES:
      return {
        ...state,
        uploadCentresData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTERS:
      return {
        ...state,
        centers: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BUSINESS_LOCATION:
      return {
        ...state,
        get_business_location: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CC_CONSUMPTION_ITEMS:
      return {
        ...state,
        get_cc_consumption: action.payload,
        loading: false,
      };
    case ACTIONS.CC_TO_INV:
      return {
        ...state,
        cc_to_inv: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_CC_TO_INVENTORY_RES:
      return {
        ...state,
        cc_to_inv: {},
        get_cc_consumption: {},
      };
    case ACTIONS.GET_IFSC_DATA:
      return {
        ...state,
        ifscData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MRR_SALES:
      return {
        ...state,
        get_mrr_sales: action.payload,
        mmr_sales_loading: false,
      };
    case ACTIONS.SET_MMR_LOADING:
      return {
        ...state,
        mmr_sales_loading: action.payload,
      };
    case ACTIONS.GET_TERRITORY_DATA:
      return {
        ...state,
        territoryData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_COMMENTS:
      return {
        ...state,
        centerComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_STATE_LIST:
      return {
        ...state,
        stateList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_USER:
      return {
        ...state,
        labUser: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNREGISTERD_BOOKING:
      return {
        ...state,
        unregisterBooking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_UNREGISTERD_BOOKING_STATUS:
      return {
        ...state,
        unregisterBookingStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SHOW_ALL_STORE_LOCATIONS:
      return {
        ...state,
        get_show_all_store_locations: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB:
      return {
        ...state,
        get_labs: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_USER:
      return {
        ...state,
        create_user: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INVENTORY_USERS:
      return {
        ...state,
        get_inventory_users: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOCATION_RIGHTS_THRU_USER:
      return {
        ...state,
        set_location_rights_thru_user: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LOCATIONS_OPT:
      return {
        ...state,
        get_locations_opt: action.payload,
        loading: false,
      };
    case ACTIONS.GET_API_LOGS_FOR_PASSED_MODEL_MANGO:
      return {
        ...state,
        get_api_logs_for_passed_mango: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_API_LOGS_MANGO:
      return {
        ...state,
        get_api_logs_for_passed_mango: {},
        loading: false,
      };
    case ACTIONS.POST_OR_UPDATE_STORE_LOCATION:
      return {
        ...state,
        post_or_update_store_location: action.payload,
        loading: false,
      };
    case ACTIONS.GET_STORE_LOCATION_OR_FILTER_OPT:
      return {
        ...state,
        get_store_location_or_filter_opt: action.payload,
        loading: false,
      };
    case ACTIONS.GET_STORE_LOCATION_OR_FILTER:
      return {
        ...state,
        get_store_location_or_filter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_STORE_LOCATION_OPT:
      return {
        ...state,
        get_store_location_opt: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FULL_STORE_LOCATION:
      return {
        ...state,
        get_full_store_location: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_AND_HLM:
      return {
        ...state,
        get_lab_and_hlm: action.payload,
      };
    case ACTIONS.GET_STORE_LOCATION_FILTER:
      return {
        ...state,
        get_store_location_filter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SINGLE_CENTRE:
      return {
        ...state,
        get_single_centre: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LOCATION_II:
      return {
        ...state,
        get_location_II: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_LOCATION_MAPPING:
      return {
        ...state,
        get_show_all_store_locations: {},
        get_location_II: {},
        post_or_update_store_location: {},
        loading: false,
      };
    case ACTIONS.GET_ITEM_MASTER_FOR_MAP_ITEMS:
      return {
        ...state,
        get_item_master_for_map_items: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ITEM_TYPE:
      return {
        ...state,
        get_item_type: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MACHINE:
      return {
        ...state,
        get_machine: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FULL_ITEM_MASTER:
      return {
        ...state,
        get_full_item_master: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_TAGGED_ITEMS_TO_LOCATION:
      return {
        ...state,
        get_all_tagged_items_to_location: action.payload,
        loading: false,
      };
    case ACTIONS.GET_STORE_PERMITTED_ITEMS_OPT:
      return {
        ...state,
        get_store_permitted_items_opt: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        reportLoading: false,
      };
    case ACTIONS.GET_BIOMARKER_SCREENING:
      return {
        ...state,
        biomarkerScreening: action.payload,
        biomarkerLoader: false,
      };
    case ACTIONS.SET_BIOMARKER_LOADER:
      return {
        ...state,
        biomarkerLoader: action.payload,
      };
      // case ACTIONS.GET_DOCTOR_CONSULTATION_BOOKING:
      return {
        ...state,
        doctorConsultationBooking: action.payload,
        doctorTableLoader: false,
      };
      // case ACTIONS.SET_BOOKING_ID_LOADER:
      return {
        ...state,
        bookingIdLoader: action.payload,
      };
    case ACTIONS.SET_REPORT_LOADING:
      return {
        ...state,
        reportLoading: action.payload,
      };
    case ACTIONS.GET_NEW_LEAD_DETAILS:
      return {
        ...state,
        newLeadDetails: action.payload,
        loading: false,
      };

    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    // case ACTIONS.GET_TICKET_TIMELINE:
    //   return {
    //     ...state,
    //     ticketTimeline: action.payload,
    //     loading: false,
    //   };
    case ACTIONS.GET_CUSTOMER_SPECIFIC_COUPON:
      return {
        ...state,
        customerSpecificCouponData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RESAMPLING_BOOKING_BY_ID:
      return {
        ...state,
        resamplingBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_HIV_PACKAGES:
      return {
        ...state,
        hivpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_VERIFICATION_OTP:
      return {
        ...state,
        bookingOtp: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_BOOKING_SELFIE:
      return {
        ...state,
        phleboSelfie: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INVOICE_BY_BOOKING_ID:
      return {
        ...state,
        invoiceData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_DOCUMENT:
      return {
        ...state,
        bookingDocumentList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_SAMPLE_IMAGE:
      return {
        ...state,
        sampleImage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VIEW_PACKAGE_ANSWER:
      return {
        ...state,
        viewPackageAnswer: action.payload,
      };
    case ACTIONS.GET_VIEW_PACKAGE_ANSWER_ALLERGY:
      return {
        ...state,
        viewPackageAnswerAllergy: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_INVOICE:
      return {
        ...state,
        bookingInvoie: action.payload,
        bookingInvoiceLoading: false,
      };
    case ACTIONS.GET_PHLEBO_RECORDING:
      return {
        ...state,
        phleboRedocrding: action.payload,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA_BY_BOOKINGID:
      return {
        ...state,
        genomicsFormForGet: action.payload,
      };
    case ACTIONS.GET_VIP_ACTIVATION_STATUS:
      return {
        ...state,
        vipActivationStatus: action.payload,
      };
    case ACTIONS.GET_COUPON_AUTO_APPLY:
      return {
        ...state,
        couponAutoApplied: action.payload,
      };
    case ACTIONS.ADD_UNIQUE_CUSTOMER:
      return {
        ...state,
        uniqueCustomerData: action.payload,
      };
    case ACTIONS.GET_PACKAGE_QUESTION:
      return {
        ...state,
        packageQuestion: action.payload,
        packageQuestionLoader: false,
      };
    case ACTIONS.GET_CONTAINER_SLOTS:
      return {
        ...state,
        containerSlots: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.POST_REF_DOCTOR:
      return {
        ...state,
        postRefReducer: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REF_DOCTOR:
      return {
        ...state,
        refDoctor: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA:
      return {
        ...state,
        leadSubscriptionsData: action.payload,
        loading: false,
      };
      case ACTIONS.GET_DIGITAL_PRESCRIPTION_LIST:
      case ACTIONS.GET_STORE_LOCATION:
        return {
          ...state,
          get_store_location: action.payload,
          loading: false,
        };
        case ACTIONS.GET_GRN_OR_FILTER:
        return {
          ...state,
          get_grn_or_filter: action.payload,
          loading: false,
        };
           case ACTIONS.GET_FULL_GRN:
        return {
          ...state,
          get_full_grn: action.payload,
          loading: false,
        };

        case ACTIONS.GET_ITEM_MASTER_OR_FILTER:
          return {
            ...state,
            get_item_master_or_filter: action.payload,
            loading: false,
          };
          case ACTIONS.GET_API_LOGS_FOR_PASSED_MODEL:
            return {
              ...state,
              get_api_logs_for_passed_model: action.payload,
              loading: false,
            };
      
          case ACTIONS.RESET_API_LOGS:
            return {
              ...state,
              get_api_logs_for_passed_model: {},
              loading: false,
            };
      case ACTIONS.GET_USER_INFO:
        return {
          ...state,
          digitalPrescriptionListWithBooking: action.payload,
          loading: false,
        };
        case ACTIONS.SEND_DIGITAL_PRESCRIPTION:
          return {
            ...state,
            sendDigitalPrescriptionToCustomer: action.payload,
          };
          case ACTIONS.GET_FAILED_REPORT_DATA_BY_ID:
            return {
              ...state,
              failedReportById: action.payload,
              loading: false,
            };
            case ACTIONS.SET_WHATSAPP_DETAILS_LOADING:
              return {
                ...state,
                whatsAppDetailsLoader: action.payload,
              };
        
        
            case ACTIONS.WHATSAPP_STATUS_MESSAGE_ID:
              return {
                ...state,
                whatsappStatusMessageId: action.payload,
              };
            case ACTIONS.GET_WHATSAPP_COMMUNICATION_DETAILS:
              return {
                ...state,
                whatsAppComunicationDetails: action.payload,
                whatsAppDetailsLoader: false,
              };
        
      case ACTIONS.GET_STORE_LOCATION:
        return {
          ...state,
          get_store_location: action.payload,
          loading: false,
        };
    case ACTIONS.GET_ITEM_MASTER:
      return {
        ...state,
        get_item_master: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VENDOR_MASTER:
      return {
        ...state,
        get_vendor_master: action.payload,
        loading: false,
      };
    case ACTIONS.POST_PO:
      return {
        ...state,
        post_po: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PO_OR_FILTER:
      return {
        ...state,
        get_po_or_filter: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_ITEMS_TO_PO:
      return {
        ...state,
        add_items_to_po: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PI_INDENTS:
      return {
        ...state,
        get_pi_indents: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUOTATION_FOR_CREATING_PO:
      return {
        ...state,
        get_quotation_for_creating_po: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUOTATION_ITEMS_THRU_VENDOR_AND_RECIEPT_ID:
      return {
        ...state,
        get_quotation_items_thru_vendor_and_reciept_id: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FULL_PO:
      return {
        ...state,
        get_full_po: action.payload,
        loading: false,
      };
    case ACTIONS.POST_OR_UPDATE_PO:
      return {
        ...state,
        post_or_update_po: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CURRENCY_MASTER:
      return {
        ...state,
        get_currency_master: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VENDORS_THRU_ITEM:
      return {
        ...state,
        get_vendors_thru_item: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_VENDORS_THRU_ITEM:
      return {
        ...state,
        get_vendors_thru_item: {},
        loading: false,
      };
    case ACTIONS.POST_PO_AGAINST_PI:
      return {
        ...state,
        post_po_against_pi: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_PO_AGAINST_PI:
      return {
        ...state,
        post_po_against_pi: {},
        get_quotation_for_currency_validation:{},
        loading: false,
      };
    case ACTIONS.GET_INV_SUPER_ADMIN_USERS:
      return {
        ...state,
        get_inv_super_admin_users: action.payload,
        loading: false,
      };
    case ACTIONS.GET_API_LOGS_FOR_PASSED_MODEL:
      return {
        ...state,
        get_api_logs_for_passed_model: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_API_LOGS:
      return {
        ...state,
        get_api_logs_for_passed_model: {},
        loading: false,
      };
    case ACTIONS.GET_COMPANY_MASTER:
      return {
        ...state,
        get_company_master: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUOTATION_FOR_CURRENCY_VALIDATION:
      return {
        ...state,
        get_quotation_for_currency_validation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ITEMS_THROUGH_LOCATION:
      return {
        ...state,
        get_items_through_location: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NEW_ITEM_MASTER_OR_FILTER:
      return {
        ...state,
        get_new_item_master_or_filter: action.payload,
        loading: false,
      };

    case ACTIONS.POST_RAISE_INDENT:
      return {
        ...state,
        post_raise_indent: action.payload,
        loading: false,
      };

    case ACTIONS.RESET_NEW_ITEM_MASTER_OR_FILTER_:
      return {
        ...state,
        get_new_item_master_or_filter: {},
        loading: false,
      };
    case ACTIONS.POST_OR_UPDATE_INDENT_STATUS_FOR_RAISE_INDENT:
      return {
        ...state,
        post_or_update_indent_status_for_raise_indent: action.payload,
        loading: false,
      };
      case ACTIONS.RESET_INDENT_ITEMS:
      return {
        ...state,
        get_indent_items: {},
        loading: false,
      };

    case ACTIONS.RESET_INDENT_STATUS_API:
      return {
        ...state,
        post_or_update_indent_status_for_raise_indent: {},
        loading: false,
      };

    case ACTIONS.RESET_INDENT_VIEW:
      return {
        ...state,
        get_indents: {},
        get_indent_items: {},
        loading: false,
      };

    case ACTIONS.GET_INDENTS:
      return {
        ...state,
        get_indents: action.payload,
        loading: false,
      };

    case ACTIONS.GET_INDENT_ITEMS:
      return {
        ...state,
        get_indent_items: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INDENT_ISSUE_OR_FILTER_FOR_RAISE_INDENT:
      return {
        ...state,
        get_indent_issue_or_filter_for_raise_indent: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MANUFACTURE:
      return {
        ...state,
        get_manufacture: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LOCATIONS:
      return {
        ...state,
        get_all_locations: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPANY:
      return {
        ...state,
        get_company: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default TechSupportReducer;