import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Chip,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getLeadSource,
  createPanelUser,
  getPanelUserListTeamLead,
  getLanguage,
  getAllUsers,
  getOzontelSkillsData,
  getDialer,
  getOrgTypes,
  getCentre,
  getUserTypes,
} from "../../actions/TechSupportActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    paper: {
      padding: "1rem",
      width: "100%",
      borderRadius: "10px",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "1rem",
      textAlign: "center",
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.2rem",
      margin: "0",
    },
    table: {
      maxHeight: "400px",
      margin: "auto",
      overflow: "auto",
    },
    image: {
      width: "100px",
      height: "auto",
    },
    imageSM: {
      width: "50px",
      height: "auto",
    },
    imgContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    },
  })
);
interface Props {
  loading: any;
  getLeadSource: any;
  lead_source: any;
  createPanelUser: any;
  teamLeadList: any;
  usersList: any;
  language: any;
  orgTypesList: any;
  dialer: any;
  getPanelUserListTeamLead: any;
  getLanguage: any;
  getOzontelSkillsData: any;
  getDialer: any;
  getAllUsers: any;
  getOrgTypes: any;
  ozontelskilldata: any;
  centreData: any;
  getCentre: any;
  getUserTypes: any;
  userTypesList: any;
}

const BlogsPage: React.FC<Props> = ({
  loading,
  getLeadSource,
  lead_source,
  createPanelUser,
  teamLeadList,
  usersList,
  language,
  orgTypesList,
  dialer,
  getPanelUserListTeamLead,
  getLanguage,
  getOzontelSkillsData,
  ozontelskilldata,
  getDialer,
  getAllUsers,
  getOrgTypes,
  centreData,
  getCentre,
  getUserTypes,
  userTypesList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [employeeId, setEmployeeId] = useState<String>("");
  const [password, setPassword] = useState("");
  const [dialers, setDialers] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<String>("");
  const [email, setEmail] = useState<String>("");
  const [userGroup, setUserGroup] = useState<any>("");
  const [orgType, setOrgType] = useState<any>("none");
  const [userTypes, setUserTypes] = useState<any>("none");
  const [reportingLocation, setReportingLocation] = useState<any>("none");
  const [userType, setUserType] = useState<any>("");
  const [centre, setCentre] = useState<any>("");
  const [clicktoCall, setClickToCall] = useState<any>("OZONETEL");
  const [centreDef, setCentreDef] = useState<any>("");
  const [ameyoUsername, setAmeyoUsername] = useState<any>("");
  const [ameyoPassword, setAmeyoPassword] = useState<any>("");
  const [isactive, setIsActive] = useState<boolean>(false);
  const [teamLead, setTeamLead] = useState<any>("");
  const [executiveType, setExecutiveType] = useState("");
  const [languages, setLanguages] = useState<any>([]);
  const [ozontelName, setozontel] = useState<any>([]);
  const [ozontelId, setOzontelId] = useState<any>([]);
  const timer = useRef<any>(0);

  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-0987654321`".includes(e.key) &&
      e.preventDefault()
    );
  };

  const inputAlphaNumeric = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^ a-zA-Z_ ]+/gi, "")
      : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }
  };

  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
  });

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
  };

  useEffect(() => {
    getLeadSource();
    getPanelUserListTeamLead(`?usergroup=CustomerLead`);
    // getLanguage();
    // getOzontelSkillsData();
    // getDialer();
    getAllUsers(`?page_size=1000`);
    // getOrgTypes();
    getUserTypes();
    // getCentre();
  }, []);

  const handleCreateuser = async (e: any) => {
    e.preventDefault();
    const data: any = {
      username: username,
      email,
      fullname: name,
      phonenumber: phoneNumber,
      password: password,
      usergroup: userGroup,
      ctc_from: clicktoCall,
      dailer: dialers,
      asigned: [teamLead],
      usertype: userType,
      org_types: orgType,
      languages: languages,
      reporting_location: reportingLocation,
      executive_type: executiveType ? executiveType : null,
      ozontel_skills: ozontelId,
      is_active: isactive,
      empid: employeeId,
      ameyo_user: ameyoUsername,
      ameyo_password: ameyoPassword,
      center: centre,
      user_types: userTypes,
    };

    if (teamLead === "" || teamLead === "none") {
      delete data.asigned;
    }
    if (languages === "" || languages === "none") {
      delete data.languages;
    }
    if (executiveType === "" || executiveType === "none") {
      delete data.executive_type;
    }
    if (ozontelId?.length === 0) {
      delete data.ozontel_skills;
    }
    if (ameyoUsername === "" || ameyoUsername === "none") {
      delete data.ameyo_user;
    }
    if (ameyoPassword === "" || ameyoPassword === "none") {
      delete data.ameyo_password;
    }
    if (
      userGroup === "CustomerLead" ||
      "ServiceLead" ||
      "SalesLead" ||
      "Logistics" ||
      "Phlebo" ||
      "RouteManager"
    ) {
      await createPanelUser(data);
      history.push("/dashboard/ts/manageHierarchy");
    } else {
      await createPanelUser(data);
      history.push("/dashboard/ts/users");
    }
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={10}>
            <h2>Add User</h2>
          </Grid>
        </Grid>
        <br />
        <Paper elevation={5} className={classes.paper}>
          <div className={classes.contentTable}>
            <form onSubmit={handleCreateuser}>
              <Grid container spacing={4} style={{ margin: "1rem auto" }}>
                <h4> Login Information :-</h4>
                <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Username <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      fullWidth
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter Username"
                      value={username}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Email <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      fullWidth
                      className="input"
                      name="customer_email"
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setEmail(e.target.value as String)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      User Group<sup>*</sup>{" "}
                    </h6>
                    <Autocomplete
                      id="users_list"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setUserGroup(obj.name);
                        }
                      }}
                      options={usersList?.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) =>
                        option?.id && option?.name
                      }
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getAllUsers(`?name=${newInputValue}`);
                        }, 1000);
                        if (newInputValue.length === 0) {
                          setUserGroup("");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Enter User Group"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Password <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter Password"
                      value={password}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Grid>
                </Grid>
                <h4>Employee Information -</h4>
                <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Full Name <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Enter Full Name"
                      value={name}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Phone Number <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      className="input"
                      name="customer_phonenumber"
                      type="number"
                      value={phoneNumber}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      required
                      placeholder="Enter Mobile Number"
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setPhoneNumber(e.target.value as String)}
                      onBlur={() => verifyMobile("mobile")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          phoneNumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.phoneNumber &&
                        "Incorrect Phone Number"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Employee Id <sup>*</sup>{" "}
                    </h6>
                    <TextField
                      className="input"
                      name="employee_id"
                      type="text"
                      placeholder="Enter Employee Id"
                      value={employeeId}
                      variant="outlined"
                      style={{ margin: "0", width: "100%", padding: "1px" }}
                      onChange={(e) => setEmployeeId(e.target.value)}
                      required
                    />
                  </Grid>
                </Grid>
                <h4>Panel User Information -</h4>
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  <Grid item xs={12} md={3}>
                    <h6>User Types </h6>
                    <Autocomplete
                      id="user_Types"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setUserTypes(obj?.id);
                        }
                      }}
                      options={userTypesList?.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) => String(option?.name)}
                      onInputChange={(event, newInputValue) => {
                        setUserTypes(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="User Types"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          // required={userTypes.length > 0 ? false : true}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                          onKeyDown={(e) => {
                            blockCharInAutocompleteField(e);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      User Type <sup>*</sup>{" "}
                    </h6>
                    <Select
                      className="input"
                      name="usertype"
                      variant="outlined"
                      value={userType}
                      style={{
                        width: "100%",
                        margin: "0",
                        height: "40px",
                        padding: "1px",
                      }}
                      onChange={(e) => setUserType(e.target.value as String)}
                      required
                    >
                      <MenuItem value={"b2c"}>B2C</MenuItem>
                      <MenuItem value={"b2b"}>B2B</MenuItem>
                      <MenuItem value={"both"}>BOTH</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Org Type<sup>*</sup>{" "}
                    </h6>
                    <Autocomplete
                      id="Org_Type"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          // sourceIds = obj.map((item: any) => item.id);
                          let storeData: any = [];
                          obj?.map((list: any) => {
                            storeData.push(list?.id);
                          });
                          setOrgType(storeData);
                        }
                      }}
                      options={orgTypesList?.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      limitTags={1}
                      multiple
                      // onKeyDown={onKeyDown}
                      getOptionLabel={(option: any) => {
                        return option?.name;
                      }}
                      getOptionDisabled={(option: any) => {
                        return orgType.includes(option?.id);
                      }}
                      disableClearable
                      disableCloseOnSelect
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getOrgTypes(newInputValue);
                        }, 1000);
                        if (newInputValue?.length === 0) {
                          setOrgType([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Org Types"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          // required={languages.length === 0 ? true : false}
                          required={orgType.length > 0 ? false : true}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                          onKeyDown={(e) => {
                            blockCharInAutocompleteField(e);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>Assigned</h6>
                    <Autocomplete
                      id="team_lead"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setTeamLead(obj.id);
                        }
                      }}
                      freeSolo
                      blurOnSelect
                      limitTags={1}
                      options={teamLeadList?.results || []}
                      disableClearable
                      disableCloseOnSelect
                      getOptionLabel={(option: any) =>
                        option?.id && option?.user?.username
                      }
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPanelUserListTeamLead(
                            `?code=${newInputValue}&usergroup=TechSupport`
                          );
                        }, 1000);
                        if (newInputValue.length === 0) {
                          setTeamLead("");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Team Lead"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          required={
                            userGroup === "CustomerExecutive" ? true : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>Center</h6>
                    <Autocomplete
                      id="center"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setCentre(obj.id);
                        }
                      }}
                      freeSolo
                      blurOnSelect
                      options={centreData?.results || []}
                      inputValue={centreDef}
                      disableCloseOnSelect
                      getOptionLabel={(option: any) =>
                        (option?.display_name === null
                          ? option?.name
                          : option?.display_name) +
                        (option?.center_code
                          ? "(" + option?.center_code + ")"
                          : "")
                      }
                      onInputChange={(event, newInputValue) => {
                        setCentreDef(newInputValue);
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getCentre(`?name_or_code=${newInputValue}`);
                        }, 1000);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Center"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Reporting Location<sup>*</sup>{" "}
                    </h6>
                    <Select
                      className="input"
                      name="ReportingLocation"
                      variant="outlined"
                      value={reportingLocation}
                      style={{
                        width: "100%",
                        margin: "0",
                        height: "40px",
                        padding: "1px",
                      }}
                      required={
                        userGroup === "Doctor" ||
                        userGroup === "CustomerExecutive" ||
                        userGroup === "CustomerLead" ||
                        userGroup === "Phlebos" ||
                        userGroup === "RouteManager" ||
                        userGroup === "SupportExecutive"
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        setReportingLocation(e.target.value as String)
                      }
                    >
                      <MenuItem value={"Gurugram"}>Gurugram</MenuItem>
                      <MenuItem value={"Noida"}>Noida</MenuItem>
                      <MenuItem value={"GGN2"}>GGN2</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>Executive Type</h6>
                    <Autocomplete
                      id="ExecutiveTypeFilter"
                      onChange={(event, newValue) => {
                        let sourceIds: any = [];
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setExecutiveType(obj.value);
                        }
                      }}
                      options={[
                        {
                          text: "RCA",
                          value: "RCA",
                        },
                        {
                          text: "Resampling",
                          value: "Resampling",
                        },
                        {
                          text: "Report",
                          value: "Report",
                        },
                        {
                          text: "Refund",
                          value: "Refund",
                        },
                        {
                          text: "Admin",
                          value: "Admin",
                        },
                        {
                          text: "Discount",
                          value: "Discount",
                        },
                        {
                          text: "Ticketing",
                          value: "Ticketing",
                        },
                        {
                          text: "Consultation",
                          value: "Consultation",
                        },
                        {
                          text: "North Zone",
                          value: "North Zone",
                        },
                        {
                          text: "South Zone",
                          value: "South Zone",
                        },
                        {
                          text: "West Zone",
                          value: "West Zone",
                        },
                        {
                          text: "East Zone",
                          value: "East Zone",
                        },
                        {
                          text: "CEGenomics",
                          value: "CEGenomics",
                        },
                        {
                          text: "Inbound",
                          value: "Inbound",
                        },
                        {
                          text: "Outbound",
                          value: "Outbound",
                        },
                        {
                          text: "LTV",
                          value: "LTV",
                        },
                        {
                          text: "CoCal",
                          value: "CoCal",
                        },
                        {
                          text: "Dialer",
                          value: "Dialer",
                        },
                        {
                          text: "OBH1",
                          value: "OBH1",
                        },
                        {
                          text: "OBH2",
                          value: "OBH2",
                        },
                        {
                          text: "Lab",
                          value: "Lab",
                        },
                        {
                          text: "CentralLab",
                          value: "CentralLab",
                        },
                        {
                          text: "Escalation",
                          value: "Escalation",
                        },
                        {
                          text: "ChatAndEmail",
                          value: "ChatAndEmail",
                        },
                        {
                          text: "CASH",
                          value: "CASH",
                        },
                        {
                          text: "NONCASH",
                          value: "NONCASH",
                        },
                        {
                          text: "ABM/ASM",
                          value: "ABM/ASM",
                        },
                        {
                          text: "SE/TM",
                          value: "SE/TM",
                        },
                        {
                          text: "RBM/RSM",
                          value: "RBM/RSM",
                        },
                        {
                          text: "ZSM",
                          value: "ZSM",
                        },
                        {
                          text: "AGM SALES",
                          value: "AGM SALES",
                        },
                        {
                          text: "GM SALES",
                          value: "GM SALES",
                        },
                        {
                          text: "UpgradedCE",
                          value: "UpgradedCE",
                        },
                        {
                          text: "B2B_CX",
                          value: "B2B CX",
                        },
                        {
                          text: "InvLabDr",
                          value: "Inventory Lab Dr.",
                        },
                        {
                          text: "InvLabService",
                          value: "Inventory Lab Service",
                        },
                        {
                          text: "InvLabHead",
                          value: "Inventory Lab Head",
                        },
                        {
                          text: "SuperAdmin",
                          value: "SuperAdmin",
                        },
                        {
                          text: "BML",
                          value: "BML",
                        },
                        {
                          text: "standard",
                          value: "Standard",
                        },
                        {
                          text: "corporate_app",
                          value: "Corporate App",
                        },
                        {
                          text: "Finance",
                          value: "FINANCE",
                        },
                        {
                          text: "OLCP",
                          value: "Olc Partner",
                        },
                        {
                          text: "olc",
                          value: "olc",
                        },
                        {
                          text: "DSA",
                          value: "DSA",
                        },
                        {
                          text: "Manager",
                          value: "Manager",
                        },
                        {
                          text: "AreaManager",
                          value: "AreaManager",
                        },
                        {
                          text: "PPMC",
                          value: "PPMC",
                        },
                        {
                          text: "DRM",
                          value: "DRM",
                        },
                        {
                          text: "Contract",
                          value: "Contract",
                        },
                        {
                          text: "Auditor",
                          value: "Auditor",
                        },
                        {
                          text: "Genomics",
                          value: "Genomics",
                        },
                        {
                          text: "GenomicsAdmin",
                          value: "GenomicsAdmin",
                        },
                        {
                          text: "Routine",
                          value: "Routine",
                        },
                        {
                          text: "RoutineAdmin",
                          value: "RoutineAdmin",
                        },
                        {

                          text: "Billing",
                          value: "billing",
                        },
                        {
                          text: "phlebos",
                          value: "phlebos",
                        },
                        {
                          text: "SampleAccessioning",
                          value: "SampleAccessioning",
                        },
                        {
                          text: "Others",
                          value: "Others",
                        },
                      ]}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) => option.text}
                      disableClearable
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Executive Type"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>Ozontel Skill</h6>
                    <Autocomplete
                      id="skill"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let userARR: any = [];
                          let sourceId: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            userARR.push(obj);
                            sourceId.push(obj.id);
                          });
                          setozontel(userARR);
                          setOzontelId(sourceId);
                        }
                      }}
                      value={ozontelName}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getOzontelSkillsData(`?search=${newInputValue}`);
                        }, 500);
                      }}
                      multiple
                      disableClearable
                      disableCloseOnSelect
                      limitTags={1}
                      options={ozontelskilldata?.results || []}
                      freeSolo
                      blurOnSelect
                      getOptionDisabled={(option: any) => {
                        return ozontelId.includes(option.id);
                      }}
                      getOptionLabel={(option: any) =>
                        String(option?.id) && String(option?.name)
                      }
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option: any, index: number) => (
                          <Chip
                            key={index}
                            variant="default"
                            color="primary"
                            label={String(option?.id) && String(option?.name)}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Ozontel Skill"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          className="input"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>Dialer</h6>
                    <Autocomplete
                      id="dialer"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setDialers(obj.id);
                        }
                      }}
                      options={dialer?.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      getOptionLabel={(option: any) =>
                        option?.did && option?.campaign_name
                      }
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getDialer(`?campaign_name=${newInputValue}`);
                        }, 1000);
                        if (newInputValue.length === 0) {
                          setDialers("");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Dialer"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          required={
                            userGroup === "Doctor" ||
                            userGroup === "CustomerExecutive" ||
                            userGroup === "CustomerLead" ||
                            userGroup === "Phlebos" ||
                            userGroup === "RouteManager" ||
                            userGroup === "SupportExecutive"
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>
                      Click To Call<sup>*</sup>{" "}
                    </h6>
                    <Select
                      className="input"
                      name="sampleregisterd"
                      variant="outlined"
                      value={clicktoCall}
                      style={{
                        width: "100%",
                        margin: "0",
                        height: "40px",
                        padding: "1px",
                      }}
                      onChange={(e) => setClickToCall(e.target.value as String)}
                      required={
                        userGroup === "Doctor" ||
                        userGroup === "CustomerExecutive" ||
                        userGroup === "CustomerLead" ||
                        userGroup === "Phlebos" ||
                        userGroup === "RouteManager" ||
                        userGroup === "SupportExecutive"
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={"ELISION_BML"}>ELISION_BML</MenuItem>
                      <MenuItem value={"ELISION_GSM"}>ELISION_GSM</MenuItem>
                      <MenuItem value={"AMEYOCALL"}>AMEYOCALL</MenuItem>
                      <MenuItem value={"TATATELEOFFLINE"}>
                        TATATELE OFFLINE
                      </MenuItem>
                      <MenuItem value={"OZONETEL"}>OZONETEL</MenuItem>
                    </Select>
                  </Grid>
                  {clicktoCall === "AMEYOCALL" ? (
                    <>
                      <Grid item xs={12} md={3}>
                        <h6>Ameyo Username</h6>
                        <TextField
                          className="input"
                          name="title"
                          type="text"
                          placeholder="Enter AMEYOCALL"
                          value={ameyoUsername}
                          variant="outlined"
                          inputProps={{ maxLength: 35 }}
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "1px",
                          }}
                          onChange={(e) => {
                            setAmeyoUsername(e.target.value);
                            inputAlphaNumeric(e);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <h6>
                          Password <sup>*</sup>{" "}
                        </h6>
                        <TextField
                          className="input"
                          name="title"
                          type="text"
                          placeholder="Enter Password"
                          value={ameyoPassword}
                          variant="outlined"
                          inputProps={{ maxLength: 35 }}
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "1px",
                          }}
                          onChange={(e) => {
                            setAmeyoPassword(e.target.value);
                          }}
                          required
                        />
                      </Grid>
                    </>
                  ) : clicktoCall == "ELISION_BML" ||
                    clicktoCall === "ELISION_GSM" ? (
                    <Grid item xs={12} md={3}>
                      <h6>Ameyo Username</h6>
                      <TextField
                        className="input"
                        name="title"
                        type="text"
                        placeholder="Enter AMEYOCALL"
                        value={ameyoUsername}
                        variant="outlined"
                        inputProps={{ maxLength: 35 }}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "1px",
                        }}
                        onChange={(e) => {
                          setAmeyoUsername(e.target.value);
                          inputAlphaNumeric(e);
                        }}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} md={3}>
                    <h6>Language</h6>
                    <Autocomplete
                      id="language"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          let storeData: any = [];
                          obj?.map((list: any) => {
                            storeData.push(list?.iso_639_1);
                          });
                          setLanguages(storeData);
                        }
                      }}
                      options={language?.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      limitTags={1}
                      multiple
                      getOptionLabel={(option: any) => {
                        return option?.name_en;
                      }}
                      getOptionDisabled={(option: any) => {
                        return languages.includes(option?.iso_639_1);
                      }}
                      disableClearable
                      disableCloseOnSelect
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getLanguage(newInputValue);
                        }, 1000);
                        if (newInputValue?.length === 0) {
                          setLanguages([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Language"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "1px" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                          onKeyDown={(e) => {
                            blockCharInAutocompleteField(e);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <h6>Is Active</h6>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={isactive}
                      label="IsActive"
                      onChange={(e) => setIsActive(!isactive)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "20px", alignItems: "center" }}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    disabled={loading || !mobileValidation.phoneNumber}
                    type="submit"
                  >
                    Create User{loading}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  lead_source: state.TechSupportReducer.lead_source,
  teamLeadList: state.TechSupportReducer.teamLeadList,
  usersList: state.TechSupportReducer.usersList,
  language: state.TechSupportReducer.language,
  orgTypesList: state.TechSupportReducer.orgTypesList,
  dialer: state.TechSupportReducer.dialer,
  ozontelskilldata: state.TechSupportReducer.ozontelskilldata,
  centreData: state.TechSupportReducer.centreData,
  userTypesList: state.TechSupportReducer.userTypesList,
});

export default connect(mapStateToProps, {
  getLeadSource,
  createPanelUser,
  getPanelUserListTeamLead,
  getLanguage,
  getOzontelSkillsData,
  getDialer,
  getAllUsers,
  getOrgTypes,
  getCentre,
  getUserTypes,
})(BlogsPage);