import React, { useState, useRef, useEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  updateUsersPassword,
  getUserData,
  getUserList,
  updatePanelUser,
} from "../actions/TechSupportActions";
import { Grid, Paper } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: 600,
    },
    body: {
      fontSize: 12,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      // marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
  })
);
interface Props {
  loading: boolean;
  getUserData: any;
  userTableData: any;
  updateUsersPassword: any;
  getUserList: any;
  users: any;
  updatePanelUser: any;
}

const UserResetPassword: React.FC<Props> = ({
  loading,
  getUserData,
  userTableData,
  updateUsersPassword,
  getUserList,
  users,
  updatePanelUser,
}) => {
  const history = useHistory();
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [userName, setUserName] = useState<any>("");
  const [updatePassword, setUpdatePassword] = useState<any>([]);
  const [userId, setUserid] = useState<any>([]);
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
  const submitFile = async (id: any) => {
    setUserid(id);
    const body: any = {
      password: updatePassword,
      username: userName,
    };
    if (updatePassword != confirmPassword) {
      alert("Passwords did not match");
      return false;
    } else {
      await updateUsersPassword(body, userName);
      history.push(`/dashboard/ts/resetpassword`);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader} style={{ textAlign: "center" }}>
        <h2 style={{ fontWeight: "bold", marginTop: "10px" }}>
          Change Password
        </h2>
      </div>
      <Paper
        style={{
          margin: "70px auto",
          width: "70%",
          background: "#fff",
          marginTop: -10,
        }}
      >
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              id="username"
              onChange={(event, newValue) => {
                let sourceusername: any;
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceusername = obj?.username;
                }
                setUserName(sourceusername);
                setUserid(sourceusername);
              }}
              options={userTableData?.results || []}
              blurOnSelect
              aria-required
              limitTags={1}
              getOptionLabel={(option: any) => option.username}
              getOptionDisabled={(option: any) => {
                return userName.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getUserData(`?code=${newInputValue}`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Username"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className="input"
              name="password"
              label="Password"
              value={updatePassword}
              variant="outlined"
              onChange={(e) => setUpdatePassword(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className="input"
              name="confirm_password"
              label="Confirm Password"
              value={confirmPassword}
              variant="outlined"
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid xs={12} style={{ textAlign: "center", padding: "20px" }}>
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              disabled={
                loading ||
                confirmPassword === "" ||
                updatePassword === "" ||
                userName === ""
              }
              fullWidth
              className={classes.submit}
              onClick={submitFile}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  userTableData: state.TechSupportReducer.userTableData,
  users: state.TechSupportReducer.users,
});

export default connect(mapStateToProps, {
  getUserData,
  updateUsersPassword,
  getUserList,
  updatePanelUser,
})(UserResetPassword);
