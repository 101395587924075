import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { TextField, Switch } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../loader";
import { Button } from "@material-ui/core";
import { updatedCityAlias } from "../../actions/TechSupportActions";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
     
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  loading: boolean;
  getCityAlias: any;
  cityAlias: any;
  updatedCityAlias: any;
  setopenModal: any;
  setCityId: any;
}

const LeadsTable: React.FC<Props> = ({
  loading,
  getCityAlias,
  cityAlias,
  updatedCityAlias,
  setopenModal,
  setCityId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [editPackage, setEditPackage] = useState<any>({});
  const [aliasName, setAliasName] = useState<any>("");
  const [packageId, setpackageId] = useState<any>("");
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = cityAlias.links && cityAlias.links.next.split("?")[1];
      getCityAlias(url ? `?${url}` : " ");
    } else if (newPage < page) {
      let url = cityAlias.links && cityAlias.links.previous.split("?")[1];
      getCityAlias(url ? `?${url}` : " ");
    }
    setPage(newPage as number);
  };

  const handleEditPackage = (city: any) => {
    setAliasName(city?.name);
    setpackageId(city.id);
    setIsActive(city?.is_active);
    setEditPackage(city);
  };

  const handleUser = async () => {
    const body: any = {
      name: aliasName,
      is_active: isActive,
    };
    await updatedCityAlias(packageId, body);
    setEditPackage({});
    getCityAlias("");
  };
  const handleModal = (id: any) => {
    setopenModal(true);
    setCityId(id);
  };

  return (
    <>
      <main className={classes.content}>
        <div className="data-table" style={{ width: "100%" }}>
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "560px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">City Name</StyledTableCell>
                    <StyledTableCell align="center">Alias</StyledTableCell>
                    <StyledTableCell align="center">
                      Active/In Active
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {cityAlias?.results &&
                    cityAlias?.results?.length > 0 &&
                    cityAlias?.results.map((city: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {city?.city?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editPackage?.id === city?.id ? (
                              <TextField
                                name="subject"
                                type="text"
                                value={aliasName}
                                className="input"
                                variant="outlined"
                                label="Alias"
                                placeholder="Alias"
                                onChange={(e) =>
                                  setAliasName(e.target.value as string)
                                }
                                style={{ width: "100%" }}
                                required
                              />
                            ) : (
                              city?.alias_list
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editPackage?.id === city?.id ? (
                              <Switch
                                defaultChecked={city?.is_active}
                                checked={isActive}
                                onChange={(e) => setIsActive(e.target.checked)}
                                name="checkedA"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            ) : (
                              <DefaultSwitch
                                checked={city?.is_active}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editPackage?.id === city?.id ? (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleUser()}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleEditPackage(city)}
                              >
                                Edit
                              </Button>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    colSpan={2}
                    count={cityAlias.count || 0}
                    rowsPerPageOptions={[]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              </Table>
            )}
          </TableContainer>

          {cityAlias?.results && cityAlias?.results?.length === 0 && (
            <h6 style={{ textAlign: "center" }}>No Data Found </h6>
          )}
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  updatedCityAlias,
})(LeadsTable);
