import React, { useEffect } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import QualityRecordingTable from "../tables/recordings";
import {
  getAgentList,
  getAllRecordings,
  getLeadSource,
  getPanelUserList,
} from "../actions/TechSupportActions";
import TextField from "@material-ui/core/TextField";
import { generateRecordingUrl } from "../../helpers/generateUrl";
import { Container, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
     
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    tableIcon: {
      display: "center",
      justifyContent: "center",
    },
  })
);
interface Props {
  getAllRecordings: Function;
  panel_prop: any;
  agentsList: any;
  lead_source: any;
  getAgentList: any;
  all_recordings: any;
  getLeadSource: any;
  loading: any;
  panelUserList: any;
  getPanelUserList: any;
}

const QualityRecordingFilter: React.FC<Props> = ({
  getAllRecordings,
  getAgentList,
  agentsList,
  lead_source,
  all_recordings,
  getLeadSource,
  loading,
  panelUserList,
  getPanelUserList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [agent, setAgent] = React.useState<any>([]);
  const [leadSource, setLeadSource] = React.useState<number>(0);
  const [start_date, setStartDate] = React.useState<string>("");
  const [end_date, setEndDate] = React.useState<string>("");
  const [direction, setDirection] = React.useState<string>("none");
  const [status, setStatus] = React.useState<string>("none");
  const [disableFilterBtn, setDisableFilterBtn] =
    React.useState<boolean>(false);
  const [category, setCategory] = React.useState<any>(0);
  const [booking_id, setBookingid] = React.useState<any>("");
  const [lead, setLead] = React.useState<any>("");
  const [phonenumber, setPhonenumber] = React.useState<any>("");
  const [usergroup, setUsergroup] = React.useState<any>("none");
  const [teamLead, setTeamLead] = React.useState<any>("");
  const [sourceType, setSourceType] = React.useState<any>("");

  const USERGROUP: any = [
    "CustomerExecutive",
    "CustomerSupport",
    "SupportExecutive",
    "VerificationExecutive",
    "PhleboAdmin",
    "CustomerLead",
    "Phlebos",
    "channelpartner",
    "Finance",
    "Doctor",
    "CollectionCenter",
    "Admin",
    "QVC",
    "SampleAccessioning",
  ];
  const CATEGORY: any = [
    {
      id: 1,
      text: "Interested/Follow up",
      city: true,
      leadId: false,
    },
    {
      id: 2,
      text: "Not Interested",
      city: true,
      query: false,
    },
    {
      id: 3,
      text: "Order Booked",
      city: true,
      query: false,
    },
    {
      id: 4,
      text: "Ringing",
      city: false,
      query: false,
    },
    {
      id: 5,
      text: "Number Doesn't Exist",
      city: false,
      query: false,
    },
    {
      id: 6,
      text: "By Mistake & Wrong Number",
      city: false,
      query: false,
    },
    {
      id: 7,
      text: "Call Back",
      city: true,
      query: false,
    },
    {
      id: 8,
      text: "Business Realated Call",
      city: true,
      query: false,
    },
    {
      id: 9,
      text: "Spam Call",
      city: false,
      query: false,
    },
    {
      id: 10,
      text: "Enquiry Call",
      city: true,
      query: false,
    },
    {
      id: 11,
      text: "CFresh",
      city: false,
      query: false,
    },
    {
      id: 12,
      text: "Test Not Available",
      city: true,
      query: false,
    },
    {
      id: 13,
      text: "Subscribed to Competitor",
      city: true,
      query: false,
    },
    {
      id: 14,
      text: "Slot Issue",
      city: true,
      query: false,
    },
    {
      id: 15,
      text: "Same Day Slot",
      city: true,
      query: false,
    },
    {
      id: 16,
      text: "Already Booked",
      city: false,
      query: false,
    },
    {
      id: 17,
      text: "Not Contactable",
      city: false,
      query: false,
    },
    {
      id: 18,
      text: "Out of Station",
      city: true,
      query: false,
    },
    {
      id: 19,
      text: "Out of Service Area",
      city: true,
      query: false,
    },
    {
      id: 20,
      text: "Need Time to Think",
      city: true,
      query: false,
    },
    {
      id: 21,
      text: "Email/Whatsapp Package",
      city: true,
      leadId: false,
    },
    {
      id: 22,
      text: "Discount/Price Enquiry",
      city: true,
      leadId: false,
    },
    {
      id: 23,
      text: "CS issue",
      city: false,
      leadId: true,
    },
    {
      id: 24,
      text: "Call Not Reachable",
      city: false,
      leadId: false,
    },
    {
      id: 25,
      text: "Call Later",
      city: true,
      leadId: false,
    },
    {
      id: 26,
      text: "Call drop",
      city: true,
      leadId: false,
    },
    {
      id: 27,
      text: "Call Disconnected",
      city: true,
      leadId: false,
    },
    {
      id: 28,
      text: "Below <5 year patient",
      city: true,
      leadId: false,
    },
    {
      id: 29,
      text: "DND",
      city: false,
      leadId: false,
    },
    {
      id: 30,
      text: "Phlebo's Issue",
      city: false,
      leadId: true,
    },
    {
      id: 31,
      text: "Reports Regarding Issue",
      city: false,
      leadId: true,
    },
    {
      id: 32,
      text: "Health Advice",
      city: false,
      leadId: true,
    },
    {
      id: 33,
      text: "Health Consultation",
      city: false,
      leadId: true,
    },
    {
      id: 34,
      text: "Resampling",
      city: false,
      leadId: false,
    },
    {
      id: 35,
      text: "Language Barrier",
      city: false,
      leadId: false,
    },
    {
      id: 36,
      text: "RCFresh",
      city: false,
      leadId: false,
    },
  ];

  const timer = useRef<any>(0);

  const handleStartDateChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setStartDate(event.target.value as string);
  };

  const handleEndDateChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setEndDate(event.target.value as string);
  };

  const submitForm = () => {
    setDisableFilterBtn(true);
    let body = {
      panel_user: agent,
      usergroup,
      start_date,
      end_date,
      direction,
      status,
      category,
      booking_id,
      lead_source: leadSource,
      lead,
      phonenumber,
      tl: teamLead,
    };

    let url = generateRecordingUrl(body).substring(2);
    getAllRecordings(`?${url}`);
  };

  useEffect(() => {
    getAgentList("?usergroup=CustomerExecutive");
    // getAllRecordings();
    // getLeadSource();
    // getPanelUserList();
  }, []);

  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000);
    }
  }, [disableFilterBtn]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2 style={{marginLeft: "30px" }}>Calls Recording</h2>
        <Box>
          <Container maxWidth={false}>
            <Grid container spacing={1} direction="row" style={{ margin: "0" }}>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="booking"
                  type="number"
                  placeholder="Booking Id"
                  value={booking_id}
                  variant="outlined"
                  onChange={(e) => setBookingid(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="lead"
                  type="number"
                  placeholder="Lead Id"
                  value={lead}
                  variant="outlined"
                  onChange={(e) => setLead(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="customernumber"
                  type="number"
                  placeholder="Phone Number"
                  value={phonenumber}
                  variant="outlined"
                  onChange={(e) => setPhonenumber(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="agent"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds = obj.map((item: any) => item.id);
                    }
                    setAgent(sourceIds);
                  }}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  multiple
                  options={agentsList?.results || []}
                  getOptionDisabled={(option: any) => {
                    return agent.includes(option.id);
                  }}
                  disableClearable
                  disableCloseOnSelect
                  getOptionLabel={(option: any) =>
                    option?.id && option?.username
                  }
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getAgentList(
                        `?code=${newInputValue.replace(
                          " ",
                          ""
                        )}&usergroup=CustomerExecutive`
                      );
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setAgent([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Agent"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="leadsource"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setLeadSource(obj.id);
                    }
                  }}
                  options={lead_source || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option && option?.name}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getLeadSource(`?code=${newInputValue}`);
                    }, 1000);
                    if (newInputValue?.length === 0) {
                      setLeadSource(0);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Lead Source"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="start_date"
                  type="date"
                  label="Start Date"
                  value={start_date}
                  variant="outlined"
                  onChange={handleStartDateChange}
                  style={{ width: "100%" }}
                  // disabled={booking_date !== ""}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  name="end_date"
                  type="date"
                  value={end_date}
                  className="input"
                  label="End Date"
                  variant="outlined"
                  onChange={handleEndDateChange}
                  style={{ width: "100%" }}
                  // disabled={booking_date !== ""}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  name="direction"
                  variant="outlined"
                  value={direction}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setDirection(e.target.value as string)}
                >
                  <MenuItem value={"none"} disabled>
                    Direction
                  </MenuItem>
                  <MenuItem value={"incoming"}>Incoming</MenuItem>
                  <MenuItem value={"outbound-api"}>Outgoing</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  name="status"
                  variant="outlined"
                  value={status}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setStatus(e.target.value as string)}
                >
                  <MenuItem value={"none"} disabled>
                    Call Picked
                  </MenuItem>
                  <MenuItem value={"true"}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  name="category"
                  variant="outlined"
                  value={category}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <MenuItem value={0} disabled>
                    Status
                  </MenuItem>
                  {CATEGORY.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={`${item.id}`}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  name="usergroup"
                  variant="outlined"
                  value={usergroup}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setUsergroup(e.target.value as string)}
                >
                  <MenuItem value={"none"} disabled>
                    User Group
                  </MenuItem>
                  {USERGROUP.map((item: any) => {
                    return (
                      <MenuItem key={item} value={`${item}`}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="Team Lead"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds = obj.map((item: any) => item.id);
                    }
                    setTeamLead(sourceIds);
                  }}
                  freeSolo
                  blurOnSelect
                  limitTags={1}
                  multiple
                  options={panelUserList?.results || []}
                  getOptionDisabled={(option: any) => {
                    return teamLead.includes(option?.id);
                  }}
                  disableClearable
                  disableCloseOnSelect
                  getOptionLabel={(option: any) =>
                    option?.id && option?.user?.username
                  }
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPanelUserList(`${newInputValue.replace(" ", "")}`);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setTeamLead([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Team Lead"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="SourceType"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setSourceType(obj.value);
                    }
                  }}
                  options={[
                    {
                      text: "CoCall",
                      value: "cocall",
                    },
                    {
                      text: "Hotcall",
                      value: "hotcall",
                    },
                    {
                      text: "Ltv",
                      value: "ltv",
                    },
                  ]}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option.text}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Source Type"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={disableFilterBtn}
                  fullWidth
                  onClick={submitForm}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ts/calls_recording")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            <QualityRecordingTable
              all_recordings={all_recordings}
              getAllRecordings={getAllRecordings}
              loading={loading}
            />
          </Container>
        </Box>
      </main>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  agentsList: state.TechSupportReducer.agentsList,
  lead_source: state.TechSupportReducer.lead_source,
  all_recordings: state.TechSupportReducer.all_recordings,
  panelUserList: state.TechSupportReducer.panelUserList,
});

export default connect(mapStateToProps, {
  getAllRecordings,
  getAgentList,
  getLeadSource,
  getPanelUserList,
})(QualityRecordingFilter);
