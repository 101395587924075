import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/index";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getPanelUserCommentList,
  getAssignAgentList
} from "../../actions/TechSupportActions";
import { genratePanelUserCommentFilter } from "../../../helpers/generateUrl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
     
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  blacklistedNumber: any;
  data: any;
  getPanelUserCommentList: any;
  assigneUserList: any;
  getAssignAgentList: any;
  panelUserComment: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  data,
  getPanelUserCommentList,
  assigneUserList,
  getAssignAgentList,
  panelUserComment,
}) => {
  const history = useHistory();
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [user_name, setUser_name] = useState<any>([]);
  const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        panelUserComment.links && panelUserComment.links.next.split("?")[1];
      getPanelUserCommentList(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        panelUserComment.links && panelUserComment.links.previous.split("?")[1];
        getPanelUserCommentList(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };

  /*This function is used for disable filter button */
  useEffect(() => {
    if (disableFilterBtn) {
      setTimeout(() => {
        setDisableFilterBtn(false);
      }, 5000)
    }
  }, [disableFilterBtn])

  useEffect(() => {
    getAssignAgentList("?usergroup=CustomerExecutive");
    getPanelUserCommentList();
  }, []);

  const filterPanelUser = () => {
    setDisableFilterBtn(true);  
    const body: any = {
       panelData:user_name,
    };
    const url = genratePanelUserCommentFilter(body).substring(2);
    getPanelUserCommentList(`?${url}`);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="reset-user-password">
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={10}>
              <h2>Panel User Comment</h2>
            </Grid>
          </Grid>
          <div className={classes.contentTable}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  id="username_filter"
                  onChange={(event, newValue) => {
                    let sourceusername: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceusername = obj.map((item: any) => item?.id);
                    }
                    setUser_name(sourceusername);
                  }}
                  options={assigneUserList?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  multiple
                  getOptionLabel={(option: any) => option?.id && option?.username}
                  getOptionDisabled={(option: any) => {
                    return user_name?.includes(option?.id);
                  }}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getAssignAgentList(
                        `?code=${newInputValue}&data_required=all`
                      );
                    }, 1000);
                    if (newInputValue?.length === 0) {
                      setUser_name([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="User Name"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  fullWidth
                  onClick={filterPanelUser}
                >
                  Filter
                </Button>
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push("/dashboard/ts/panel-user-comment")
                  }
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">By User</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">Panel User</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {panelUserComment &&
                panelUserComment?.results &&
                panelUserComment?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {data?.by_user}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.comment}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.panel_user}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.created_at}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={2}
                  count={panelUserComment?.count || 0}
                  rowsPerPage={panelUserComment.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.TechSupportReducer.loading,
  panelUserComment: state.TechSupportReducer.panelUserComment,
  assigneUserList: state.TechSupportReducer.assigneUserList
});

export default connect(mapStateToProps, {
  getPanelUserCommentList,
  getAssignAgentList,
})(CustomUploader);
