import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getAgentList, getAllBookings } from "../actions/TechSupportActions";
import LeadsTable from "../tables/leads";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
     
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  bookingList: any;
  partnerList: any;
  complaints: any;
  getAgentList: any;
  getAllBookings: any;
  loading: boolean;
}

const LeadsPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getAllBookings,
  complaints,
  bookingList,
  loading,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getAgentList();
    getAllBookings();
  }, []);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/* <LeadsTable complaints={complaints} /> */}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.TechSupportReducer.agentsList,
  partnerList: state.TechSupportReducer.partnerList,
  bookingList: state.TechSupportReducer.bookingList,
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  getAgentList,
  getAllBookings,
})(LeadsPage);
