import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import { TextField, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  getLeadById,
  getBookingDispostion,
  getBookingSubDisposition,
  updateBooking,
} from "../../actions/TechSupportActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

type ModalProps = {
  open: boolean;
  setOpen: Function;
  updateBooking: any;
  getBookingDispostion: any;
  dispositionList: any;
  bookinSubDisposition: any;
  getBookingSubDisposition: any;
  loading: boolean;
  bookingId: number;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  setOpen,
  updateBooking,
  getBookingDispostion,
  bookinSubDisposition,
  getBookingSubDisposition,
  dispositionList,
  loading,
  bookingId,
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState<string>("");
  const [categoryList, setCategoryList] = useState<any>("");
  const [subCategoryName, setSubCategoryName] = useState<any>("");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getBookingDispostion("");
  }, []);
  const submitForm = (e: any) => {
    e.preventDefault();

    let body: any = {
      disposition: subCategoryName,
      comment,
    };
    updateBooking(body, bookingId);
    setComment("");
    setCategoryList("");
    setSubCategoryName("");
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <p
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Select an options
              </p>
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <form onSubmit={submitForm}>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="disposition"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCategoryList(obj?.id);
                        setSubCategoryName("");
                        setComment("");
                        if (obj.id) {
                          getBookingSubDisposition(`?category=${obj?.id}`);
                        }
                      }
                    }}
                    blurOnSelect
                    aria-required
                    options={dispositionList}
                    getOptionDisabled={(option: any) => {
                      return dispositionList.includes(option.id);
                    }}
                    loading={loading}
                    disableClearable
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Disposition Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="sub_disposition"
                    key={categoryList}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setSubCategoryName(obj.id);
                        //  setCategoryList(obj?.category_id);
                      }
                    }}
                    blurOnSelect
                    aria-required
                    loading={loading}
                    options={bookinSubDisposition}
                    getOptionDisabled={(option: any) => {
                      return bookinSubDisposition.includes(option.id);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Sub Disposition Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <TextField
                name="comment"
                type="text"
                multiline
                rows={4}
                value={comment}
                className="input"
                label="Comment"
                variant="outlined"
                placeholder="Comment"
                onChange={(e) => setComment(e.target.value as string)}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
                required
              />
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                style={{ height: "50px" }}
                type="submit"
                disabled={categoryList === "" || subCategoryName === ""}
              >
                Submit
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  dispositionList: state.TechSupportReducer.dispositionList,
  bookinSubDisposition: state.TechSupportReducer.bookinSubDisposition,
  loading: state.TechSupportReducer.loading,
});

export default connect(mapStateToProps, {
  updateBooking,
  getLeadById,
  getBookingDispostion,
  getBookingSubDisposition,
})(CommentsModal2);
