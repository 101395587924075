import { ACTIONS, LoginActionTypes } from '../interfaces/actionTypes/loginTypes';
import SecureStorage from "../config/SecureStorage";

type LoginInitialState = {
  user: object;
  status: boolean;
  success: boolean;
  otp: any;
  userDetails: Array<any>;
  passwordStatus: boolean;
  loading: boolean;
  userProfile: Array<any>;
  whatsappDataFromPusher: Array<any>;
  locationDataFromPusher: Array<any>;
  agentsNotesData: Array<any>;
  cronLeadChatUpdate: Array<any>;
  uhidsList: Array<any>;
  mergeUniqueCustomer: Array<any>;
  callRecordingsList: Array<any>;
  reportStatusLiveData: Array<any>;
  search_results: object;
}

const initialState: LoginInitialState = {
  user: {
    username: "",
    email: "",
    age: 0
  },
  status: false,
  success: false,
  otp: false,
  userDetails:[],
  passwordStatus:true,
  loading: false,
  userProfile: [],
  whatsappDataFromPusher: [],
  locationDataFromPusher: [],
  agentsNotesData: [],
  cronLeadChatUpdate: [],
  uhidsList: [],
  mergeUniqueCustomer: [],
  callRecordingsList: [],
  reportStatusLiveData: [],
  search_results: {},
};

const userReducer = (state = initialState, action: LoginActionTypes) => {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: action.payload,
        status: true
      };
    case ACTIONS.WHATSAPP_DATA_FROM_PUSHER:
      return {
        ...state,
        whatsappDataFromPusher: action.payload,
      };
    case ACTIONS.GET_USER:
      return {
        ...state,
        passwordStatus: action.payload,
        status: true
      };
    case ACTIONS.RESET_PASSWORD:
      return {
        ...state,
        success: action.payload,
      };
    case ACTIONS.SEND_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case ACTIONS.LOGOUT:
      SecureStorage.removeItem("token");
      window.location.href = '/';
      return {
        ...state,
        ...initialState
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
          ...state,
          userDetails: action.payload,
          loading: false,
      };
    case ACTIONS.GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
        status: true
      };
    case ACTIONS.LOCATION_DATA_FROM_PUSHER:
      return {
        ...state,
        locationDataFromPusher: action.payload,
      };
    case ACTIONS.GET_AGENTS_NOTES_DATA:
      return {
        ...state,
        agentsNotesData: action.payload,
      };
    case ACTIONS.CRON_LEAD_CHAT_UPDATE:
      return {
        ...state,
        cronLeadChatUpdate: action.payload,
      };
    case ACTIONS.GET_UHIDS_LIST:
      return {
        ...state,
        uhidsList: action.payload,
        loading: false,
      };
    case ACTIONS.MERGE_UNIQUE_CUSTOMER:
      return {
        ...state,
        mergeUniqueCustomer: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_RECORDINGS_LIST:
      return {
        ...state,
        callRecordingsList: action.payload,
        loading: false,
      };
      case ACTIONS.GET_REPORT_STATUS_LIVE:
        return {
          ...state,
          reportStatusLiveData: action.payload,
        };
        case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
